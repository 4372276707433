import axios from 'axios';
import { handleError, handleResponse, getConfig } from '../utils/utility';

// Get third party Jira configuration list.
export const getPrivateJiraConfigList = (url, callback) => (dispatch) => {
  const finalUrl = `${url}Jira/getthirdpartyjiraconfigurationlist`;

  axios
    .get(finalUrl, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) =>
      handleError(error, ' Failed to get third part JIRA configuration list.')
    );
};

// Get Private Jira task list
export const getPrivateJiraTaskList = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}Jira/getjirataskslist`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Failed to get Jira List'));
};

// Get Private Jira Project List
export const getPrivateJiraProjectList = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}jira/getjiraprojectslist`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Failed to get Jira Project List'));
};

// Get Private Jira Users List
export const getPrivateJiraUsersList = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}jira/getjiraassignableuserslist`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => callback({error : true}));
};

// Get Private Jira Issue Details
export const getPrivateJiraIssueDetails = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}jira/getjirataskdetails`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Failed to get Jira Issue Details.'));
};

// Create Private Jira Task in Jira.
export const createPrivateJiraTask = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}jira/createjiraissue`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Failed to create Jira Task.'));
};

// Get Jira Issue Type.
export const getPrivateJiraIssueType = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}jira/getJiratransitionslist`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Failed to get Jira issue type.'));
};

// Change Jira Assignee for a task
export const changeAssigneeforPrivateJira = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}jira/changejiraissueassignee`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) =>
      handleError(error, ' Failed to assignee Jira task to the user.')
    );
};

//  Add Comment to Private Jira Issue
export const addCommentforPrivateJiraIssue = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}jira/addcommentonjiraissue`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) =>
      handleError(error, ' Failed to add comment to Jira Issue.')
    );
};

// Set Private Jira Issue Status
export const changeStatusOfPrivateJiraIssue = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}jira/changejiraissuestatus`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Failed to set Jira issue status.'));
};

//Save summary and description for Private Jira Issue.
export const saveSummaryAndDescriptionPrivateJiraIssue = (
  url,
  requestBody,
  callback
) => (dispatch) => {
  const finalUrl = `${url}jira/changejiraissuedetails`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) =>
      handleError(error, ' Failed to save Summary/Description for Jira Issue.')
    );
};
