import axios from "axios";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

export const importChannelList = (url, callback) => (dispatch) => {
    const finalUrl = `${url}slack/importchannellist`;
    axios.post(finalUrl, {}, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};

export const importUsersList = (url, callback) => (dispatch) => {
    const finalUrl = `${url}slack/importuserslist`;
    axios.post(finalUrl, {}, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};

export const importChannelUserList = (url,callback) => (dispatch) => {
    const finalUrl = `${url}slack/importchanneluserlist`;
    axios.post(finalUrl, {}, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};

export const getChannelUsersListCount = (url, callback) => (dispatch) => {
    const finalUrl = `${url}ChannelUser/getchanneluserslistcount`;
  
    axios.post(finalUrl, {}, getConfig())
      .then(response =>
        handleResponse(response)
      )
      .then(result => {
        if (!!callback) {
          callback(result)
        }
      })
      .catch(error =>
        handleError(error, 'Cannot update user  slack token')
      );
  }