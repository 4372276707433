import axios from "axios";
import { types as T } from "./action";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";
import { trackPromise } from "react-promise-tracker";

// Create Meeting 
export const saveMeeting = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserMeeting/saveusermeeting`;
    // return trackPromise(
    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to save meeting')
        )
    // );
}

// Delete Meeting
export const deleteMeeting = (url, meetingId, callback) => (dispatch) => {
    const finalUrl = `${url}usermeeting/deleteusermeeting/` + meetingId;
    //return trackPromise(
    axios.post(finalUrl, {}, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to Delete meeting')
        )
    //)
}

export const receiveMeeting = (meetingResponse) => ({
    type: T.RECEIVE_MEETING_LIST,
    meetingResponse,
});

// Get Meeting By User
export const getUserMeetingbyId = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}usermeeting/getusermeetinglistbetweendate`;
    //return trackPromise(
    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            dispatch(receiveMeeting(result));
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to get meeting list')
        )
    // );
}

// User Meeting Attend Or Not
export const meetingAttendOrNot = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}usermeeting/setmeeting_status`;
    return trackPromise(
        axios.post(finalUrl, requestBody, getConfig())
            .then(response => {
                return handleResponse(response)
            }
            )
            .then(result => {
                if (!!callback) {
                    callback(result)
                }
            })
            .catch(error =>
                handleError(error, 'Failed to change status')
            )
    );
}

// update user google refresh token
export const updateusergooglerefreshtoken = (url, refreshToken, callback) => (dispatch) => {
    const finalUrl = `${url}usertenant/updateusergooglerefreshtoken`;

    const requestBody = {
        token: refreshToken
    };
    return trackPromise(
        axios.post(finalUrl, requestBody, getConfig())
            .then(response => {
                return handleResponse(response)
            }
            )
            .then(result => {
                if (!!callback) {
                    callback(result)
                }
            })
            .catch(error =>
                handleError(error, 'Failed to update user google refresh token')
            )
    );
}


export const setmeeting_statuswithid_event = (url, requestBody, callback) => (dispatch) => {

    const finalUrl = `${url}usermeeting/setmeeting_statuswithid_event`;
    return trackPromise(
        axios.post(finalUrl, requestBody, getConfig())
            .then(response => {
                return handleResponse(response)
            }
            )
            .then(result => {
                if (!!callback) {
                    callback(result)
                }
            })
            .catch(error =>
                handleError(error, 'Failed to set meeting status with event id')
            )
    );
}

export const deleteMeetingInPrecisWithEventId = (url, eventId, callback) => (dispatch) => {

    const finalUrl = `${url}usermeeting/DeleteUserMeetingWithId_Event/${eventId}`;
    //return trackPromise(
    axios.post(finalUrl, {}, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to delete meeting with event Id')
        )
    //);
}

export const checkUserAvailabilityForMeeting = (url, requestBody, callback) => (dispatch) => {

    const finalUrl = `${url}UserMeeting/CheckUserAvailibility`;
    //return trackPromise(
    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to check user Availability for meeting')
        )
    //);
}


// saending mail for Meeting 
export const sendMailfromPrecis = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserMeeting/sendmeetinginvitation`;
    // return trackPromise(
    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to send mail for meeting')
        )
    // );
}


// Get Attendes Users Meeting 
export const getAttendesUserMeetings = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}GoogleAccount/getusersmeeting`;

    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (result) {
                callback(result)
            }
        })
        .catch(error => {
            callback({ error: 'error' });
            //handleError(error, 'Failed to get attendes users meeting list');            
        })
}

export const checkUseravilAbilityInGoogle = (finalUrl, requestBody, callback) => (dispatch) => {
    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (result) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to check user Availability for meeting in Google')
        )
}

// Get Google Configuration
export const getGoogleConfig = (url, callback) => (dispatch) => {
    const finalUrl = `${url}GoogleAccount/getgoogleconfigurationlist`;
    // return trackPromise(
    axios.get(finalUrl, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to get google config')
        )
    // );
}

// Save Google Configuration
export const saveGoogleConfig = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}GoogleAccount/savegoogleconfiguration`;
    // return trackPromise(
    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to save google config')
        )
    // );
}