import { types as T } from '../actions/action';

const initialState = {
  slackUserDetailsResponse: null,
  channelWiseSlackUserDetailsResponse: []
}

export default (state = initialState, action) => {
    switch (action.type) {
     case T.RECEIVE_SLACK_USER_DETAILS:
      return {
        ...state,
        slackUserDetailsResponse: action.slackUserDetailsResponse
      } 

      case T.RECEIVE_CHANNEL_WISE_SLACK_USER_DETAILS:
      return {
        ...state,
        channelWiseSlackUserDetailsResponse: action.channelWiseSlackUserDetailsResponse
      }
      
      
     default:
      return state
    }
}