import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import { handleError, handleResponse, getConfig } from "../utils/utility";

// Get Department Filter Wise
export const receiveDepartmentFilterWise = (
  departmentListFilterWiseResponse
) => ({
  type: T.RECEIVE_DEPARTMENT_FILTERWISE,
  departmentListFilterWiseResponse,
});

/**
 * Get Department Filter Wise
 *
 * @param {url} URL is API host domain.
 * @param {requestBody} is user Id,department Id.
 * @return {array} return cabin detail
 */
export const getDepartmentFilterWise = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}department/getdepartmentlistwithfilter`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        dispatch(receiveDepartmentFilterWise(result));
      })
      .catch((error) => handleError(error, "error"))
  );
};

// Get user Filter Wise
export const receiveUserListDepartmentFilter = (
  userListDepartmentFilterResponse
) => ({
  type: T.RECEIVE_USER_LIST_DEPARTMENT_FILTER,
  userListDepartmentFilterResponse,
});

/**
 * Get user Filter Wise
 *
 * @param {url} URL is API host domain.
 * @param {requestBody} is user Id,department Id.
 * @return {array} return cabin detail
 */
export const getUserListDepartmentFilter = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}usertenant/getuserlistdepartmentwisewithfilter`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        dispatch(receiveUserListDepartmentFilter(result));
      })
      .catch((error) => handleError(error, "error"))
  );
};

export const getUserListDepartmentUpdateOfficeTime =
  (url, requestBody) => (dispatch) => {
    const finalUrl = `${url}usertenant/getuserlistdepartmentwisewithfilter`;
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        dispatch(receiveUserListDepartmentFilter(result));
      })
      .catch((error) => handleError(error, "error"));
  };

export const updateDepartmentOrder =
  (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/updateuserdashboard_departmentorder`;

    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) => handleError(error, "Failed to get department"));
  };

export const doorOpen = (url, userId, callback) => (dispatch) => {
  const finalUrl = `${url}usertenant/dooropen/0`;
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "Failed to Open Door"));
};

export const doorClose = (url, userId, callback) => (dispatch) => {
  const finalUrl = `${url}usertenant/doorclose/0`;
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "Failed to Close Door"));
};

// Edit Dept Name
export const receiveEditDeptName = (editDeptNameResponse) => ({
  type: T.RECEIVE_EDIT_DEPT_NAME,
  editDeptNameResponse,
});

/**
 * Edit Dept Name
 *
 * @param {url} URL is API host domain.
 *
 */
export const editDeptName = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}department/updatedepartmentname`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        dispatch(receiveEditDeptName(result));
      })
      .catch((error) => handleError(error, "Failed to edit Department name"))
  );
};

/**
 /* Get User Knock List Cabin wise
 *
 * @param {url} URL is API host domain.
 * 
*/

export const saveUserKnock = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}UserKnock/saveuserknock`;
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "Failed to knock user"));
};

export const stickyMessageSend = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}Userstickynote/saveuserstickynote`;
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "Failed to send sticky"));
};

// Get User Knock List Cabin wise
export const receiveUserKnockListCabinWise = (
  userKnockListCabinWiseResponse
) => ({
  type: T.RECEIVE_USER_KNOCK_LIST_CABIN_WISE,
  userKnockListCabinWiseResponse,
});

export const userKnockListCabinWise = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}UserKnock/getuserknocklistreceiveruseridcabinidwise`;
  //return trackPromise(
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveUserKnockListCabinWise(result));
    })
    .catch((error) => handleError(error, "error"));
  //);
};

// Count Receiver Sticky
export const receiveStickyCount = (stickyCountResponse) => ({
  type: T.RECEIVE_STICKY_COUNT,
  stickyCountResponse,
});

/* Count Receiver Sticky
 *
 * @param {url} URL is API host domain.
 *
 */
export const stickyCount = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}Userstickynote/getcountuserstickynotelistreceiveruseridcabinidwise`;
  //return trackPromise(
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveStickyCount(result));
    })
    .catch((error) => handleError(error, "error"));
  // );
};

export const GetStickyCount = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}Userstickynote/getcountuserstickynotelistreceiveruseridandtenantidwise`;
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (result) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "error"));
};

/**
 * Set knock Seen
 *
 * @param {url} URL is API host domain.
 *
 */
export const userKnockSeen = (url, callback) => (dispatch) => {
  const finalUrl = `${url}UserKnock/setseenuserknockcabinwise`;
  axios
    .get(finalUrl, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (result) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "Failed to set User Knock as Seen"));
};

/**
 * Set Sticky Seen
 *
 * @param {url} URL is API host domain.
 *
 */
export const userStickySeen = (url, callback) => (dispatch) => {
  const finalUrl = `${url}UserStickyNote/SetSeenStickyNoteCabinWise`;
  axios
    .get(finalUrl, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (result) {
        callback(result);
      }
    })
    .catch((error) =>
      handleError(error, "Failed to set User Sticky Note as Seen")
    );
};

//Get Sticky notes List cabin wise
export const receiveGetCabinUserStickyList = (
  getCabinUserStickyListResponse
) => ({
  type: T.RECEIVE_GET_CABIN_USER_STICKY_LIST,
  getCabinUserStickyListResponse,
});

/**
 * Get User Sticky List cabin wise.
 *
 * @param {url} URL is API host domain.
 */

export const getCabinUserStickyList =
  (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}Userstickynote/getuserstickynotelistreceiveruseridcabinidwise`;
    //return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        dispatch(receiveGetCabinUserStickyList(result));
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) => handleError(error, "error"));
    //);
  };

// Get user Filter Wise with slected hours
export const receiveUserListDepartmentSelectedHrsFilter = (
  userListDepartmenttWithSelectedHrsFilterResponse
) => ({
  type: T.RECEIVE_USER_LIST_BY_DEPARTMENT_WITH_SELECTED_HRS,
  userListDepartmenttWithSelectedHrsFilterResponse,
});

/**
 * Get user Filter Wise
 *
 * @param {url} URL is API host domain.
 * @param {requestBody} is user Id,department Id.
 * @return {array} return cabin detail
 */
export const getUserListDepartmentWithSelectedHrsFilter =
  (url, requestBody) => (dispatch) => {
    const finalUrl = `${url}usertenant/GetUserListDepartmentWiseWithFilterSelectedHrsWise`;
    return trackPromise(
      axios
        .post(finalUrl, requestBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
          dispatch(receiveUserListDepartmentSelectedHrsFilter(result));
        })
        .catch((error) => handleError(error, "error"))
    );
  };

/**
   /* Make selected user Admin
   *
   * @param {url} URL is API host domain.
   * 
  */

export const makeAdmin = (url, userId, callback) => (dispatch) => {
  const finalUrl = `${url}usertenant/setasadmin/${userId}`;
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, " Failed to assign user as Admin"));
};

/**
 * Get user Filter Wise
 *
 * @param {url} URL is API host domain.
 * @param {requestBody} is user status.
 */
export const setUserCustomStatus =
  (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}usertenant/changeuserstatus`;
    //return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) => handleError(error, " Failed to set user Status"));
    //);
  };

// Create Virtual Room
export const saveVirtualRoom = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}VRoom/saveVRoom`;
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, " Failed to save Virtual Room"));
};

//Get Virtual Room Details
// export const getVirtualRoom = (url, callback) => dispatch => {

//     const finalUrl = `${url}VRoom/getVRoomListTenantWise`;
//     axios.get(finalUrl, getConfig())
//         .then(response => {
//             return handleResponse(response)
//         }
//         )
//         .then(result => {
//             if (result) {
//                 callback(result)
//             }
//         })
//         .catch(error =>
//             handleError(error, ' Failed to get Virtual Room Details')
//         )
// }

//Get Virtual Room Details
export const receiveGetVirtualRoom = (virtualRoomResponse) => ({
  type: T.RECEIVE_VIRTUAL_ROOM,
  virtualRoomResponse,
});

/**
 * Get virtual Room
 */
export const getVirtualRoom = (url) => (dispatch) => {
  const finalUrl = `${url}VRoom/getVRoomListTenantWise`;
  axios
    .get(finalUrl, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveGetVirtualRoom(result));
    })
    .catch((error) => handleError(error, " Cannot get virtual room "));
};

//Delete Virtual Room
export const deleteVirtualRoom = (url, vrId, callback) => (dispatch) => {
  const finalUrl = `${url}VRoom/DeleteVRoom/${vrId}`;
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, " Failed to delete Virtual Room"));
};

//Change user Virtual Room Visibility
export const virtualRoomVisibility =
  (url, showVisibility, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/updateUserMeetingVisibleSetting/${showVisibility}`;
    axios
      .post(finalUrl, {}, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) =>
        handleError(error, " Failed to Change user Visibility")
      );
  };
