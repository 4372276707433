
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getRequestListForAddInWorkspace, inviteUserInSlackWorkspace } from "../../../actions/slackUserDetailsActions";
import { getApiHost, socket } from "../../../utils/apiUrls";
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

class InviteUserToSlack extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading : true,
      requestedUserList : []
    };
  }

  componentDidMount() {
    this.getRequestListForAddInWorkspace();
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.getDepartmentsResponse !== this.props.getDepartmentsResponse) {
    //   this.setState({
    //     data: this.props.getDepartmentsResponse
    //   })
    //   if (this.state.addnewMemberDeptId > 0) {
    //     let obj = this.props.getDepartmentsResponse.find(o => o.Id === this.state.addnewMemberDeptId);
    //     this.setState({
    //       currentObject: {
    //         isDepartment: { value: this.state.addnewMemberDeptId, label: obj.Name }
    //       }
    //     })
    //     this.getUsersList(this.state.addnewMemberDeptId);
    //   }
    // }
  }

  // close invite modal
  onCloseModal = () => {
    this.props.toggleInviteToSlack();
  };

  // Get request list for add in workspace.
  getRequestListForAddInWorkspace = () => {
    const { API_HOST, getRequestListForAddInWorkspace } = this.props;
    this.setState({ loading: true })
    getRequestListForAddInWorkspace(API_HOST, (res) => {
      if (res) {
        this.setState({
          loading: false,
          requestedUserList: res
        })
      }
    });
  }

  // Add User in Slack Workspace
  inviteUserInSlackWorkspace = (id) => {
    const { API_HOST, inviteUserInSlackWorkspace } = this.props;
    const requestBody = {
      "Id": id
    };
    this.setState({ loading: true })
    inviteUserInSlackWorkspace(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({
          loading: false
        });
        toast.success("Invitation done successfully!!\n User is now able to authenticate Slack.")
        this.getRequestListForAddInWorkspace();
      }
    });
  }

  // send invitation
  sendInvitation = (inviteId) =>{
    this.inviteUserInSlackWorkspace(inviteId);
  }

  deleteInvitation = () =>{
    toast.info("Functionality to be developed");
  }


  render() {

    const { loading, requestedUserList } = this.state;

    // let departments = [];
    // getDepartmentsResponse && getDepartmentsResponse.map(obj => {
    //   if (obj) {
    //     departments.push({ value: obj.Id, label: obj.Name })
    //   }
    //   return departments;
    // })

    const columns = [

      {
        Header: <strong className="my_custom_class">User Name</strong>,
        accessor: 'Display_Name',
       // minWidth: 180,
      },
      {
        Header: <strong className="my_custom_class">Email</strong>,
        accessor: 'EmailId',
        width: 180,
      },
      {
        Header: <strong className="my_custom_class">Date</strong>,
        accessor: 'CreatedDate',
       // width: 180,
        Cell: (row) => {
          return <>{moment(row.original.CreatedDate).format("DD-MM-YYYY")}</>
        }
      },
      // {
      //   Header: <strong className="my_custom_class">Time</strong>,
      //   accessor: 'CreatedDate',
      //   //width: 180,
      //   Cell: (row) => {
      //     return <>{moment(row.original.CreatedDate).format("HH:mm a")}</>
      //   }
      // },
      {
        Header: <strong className="my_custom_class">Actions</strong>,
        accessor: 'Id',
       // width: 100,
        Cell: row => {
          return (
            <>
              <ReactTooltip id="inviteUser" place="right" type="dark" effect="solid" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
              <i data-for="inviteUser" data-tip="Invite user to Slack" style={{ cursor: 'pointer' }} onClick={() => this.sendInvitation(row.original.Id)} class="fas fa-user-plus dept-edit-button" aria-hidden="true"></i>
              {/* <i style={{ cursor: 'pointer' }} onClick={() => this.deleteInvitation(row.original.Id)} class="fas fa-user-times dept-delete-button" aria-hidden="true"></i> */}

            </>
          )
        }
      }
    ];

    return (
      <React.Fragment>
        <div
          className="model-right-bar to_do_modal rightbar_sm model-left-bar"
          id="userloginmodel"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ width: '800px' }}
        >

          <div className="modal-dialog jira-task-popup" role="document">

            <div className="modal-content">

              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Invite New Users to Slack</h5>
                <div
                  className="close b-left"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.onCloseModal}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-close" />
                </div>
              </div>

              <div className=" modal-body ">

                <ReactTable
                  data={requestedUserList}
                  columns={columns}
                  style={{
                    height: "calc(100vh - 205px)" 
                  }}
                  pageSize={15}
                  showPagination={false}
                  className="-striped -highlight"
                  loading={loading}
                />

              </div>

            </div>

          </div>

        </div>

      </React.Fragment >

    );
  }
}


// Prop types of props.
InviteUserToSlack.propTypes = {
  API_HOST: PropTypes.string,
  getDepartments: PropTypes.func,
  getDepartmentsResponse: PropTypes.array,
  //getUserAvailabilityListResponse: propTypes.array
};

// Set default props.
InviteUserToSlack.defaultProps = {
  updateDepartmentList: () => { },
  getUsersList: () => { },
  getDepartments: () => { },
  getDepartmentsResponse: [],
  // getUserAvailabilityListResponse: []
};


const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  getDepartmentsResponse: state.getDepartmentReducer.getDepartmentsResponse,
})

export default connect(mapStateToProps, { getRequestListForAddInWorkspace, inviteUserInSlackWorkspace })(InviteUserToSlack);