import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { types as T } from "./action";
import {
  handleError,
  handleResponse,
  getConfig,
} from "../utils/utility";
import { socket } from "../utils/apiUrls";

// Login
export const receiveLogin = (loginResponse) => ({
  type: T.RECEIVE_LOGIN,
  loginResponse,
});


/**
 * Login
 *
 * @param {url} URL is API host domain.
 * @param {loginRequest} is request object to login.
 * @return {object} return user details.
 */
export const login = (url, loginRequest) => (dispatch) => { 
  const finalUrl = `${url}masterauth/login`;
  return trackPromise(
    axios
      .post(finalUrl, loginRequest)
      .then((response) => handleResponse(response))
      .then((result) => {
        if (result.SuccessData && result.SuccessData.IsError && result.SuccessData.MessageCode === 0) {
          handleError(result,  "Login");
        } else if(result.SuccessData && result.SuccessData.IsError && result.SuccessData.MessageCode === 5) {
          dispatch(receiveLogin(result.SuccessData));
        } else {
          if (result.TokenData && result.TokenData.Token) {
            localStorage.setItem("userdetail", JSON.stringify(result.UserData));
            sessionStorage.setItem(
              "userToken",
              // JSON.stringify(result.TokenData.Token).replace(/\"/g, "")
              JSON.stringify(result.TokenData.Token).replace(/"/g, "")
            );
            toast.success("Login successful!");
            socket.emit("UserDetail", result.UserData);
            dispatch(receiveLogin(result.UserData));
          }
        }
      })
      .catch((error) =>
        handleError(error, "Login Failed")
      )
  );
};

// Logout
export const requestLogout = () => ({
  type: T.REQUEST_LOGOUT,
});

export const receiveLogout = (logoutResponse) => ({
  type: T.RECEIVE_LOGOUT,
  logoutResponse,
});


/**
 * Logout
 *
 * @param {url} URL is API host domain.
 */
// export const logout = (url) => (dispatch) => {
//   dispatch(requestLogout());
//   const finalUrl = `${url}/api/v1/logout`;
//   return trackPromise(
//     axios
//       .post(finalUrl, null, getConfig())
//       .then((response) => handleResponse(response))
//       .then((result) => {
//         if (checkClientErrorStatus(result)) {
//           handleError(result, "Logout");
//         } else {
//           localStorage.removeItem("userdetail");
//           toast.success(result.message);
//           dispatch(receiveLogout(true));
//         }
//       })
//       .catch((error) =>
//         handleError(error, "Logout")
//       )
//   );
// };



export const logout = (url, callback) => (
  dispatch
) => {
  const finalUrl = `${url}masterauth/logout`;

  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Failed to logout.'));
};