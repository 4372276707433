import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import {
  handleError,
  handleResponse,
  checkClientErrorStatus
} from "../utils/utility";


export const receiveForgotPassword = (forgotPasswordResponse) => ({
  type: T.RECEIVE_FORGOT_PASSWORD,
  forgotPasswordResponse,
});

/**
 * ForgotPassword
 *
 * @param {url} URL is API host domain.
 * @param {ForgotPasswordRequest} is request object to signup.
 * @return {object} return user details.
 */
export const forgotPassword = (url, forgotPasswordRequest, callback) => (dispatch) => {
  const finalUrl = `${url}masterauth/ForgotPassword`;
  return trackPromise(axios.post(finalUrl, forgotPasswordRequest)
    .then((response) => handleResponse(response))
    .then((result) => {
      if (checkClientErrorStatus(result)) {
        handleError(result, 'Failed to Receive Forgot Password')
      }
      else {
        dispatch(receiveForgotPassword(result));
        if (!!callback) {
          callback({ stack: '' })
        }
      }
    })
    .catch((error) => {
      handleError(error, "ReceiveForgotPassword");
      if (!!callback) {
        callback({ stack: 'Error' })
      }
    })
  );
};
