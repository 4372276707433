import React from 'react';
import { ContextMenu, MenuItem } from "react-contextmenu";
//import UIfx from 'uifx';
import doorKnockSound from '../../../assets/images/Door-knock-sound-effect.mp3';
import "./ContextMenuOption.css";
import { toast } from "react-toastify";
import { socket } from "../../../utils/apiUrls";

class ContextMenuOption extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  handleClick = (e, data) => {
    toast.info("Functionality to be developed")
  };

  onClickStickyNotes = () => {
    this.props.onSticktyNoteSelected(this.props.userId);
  }

  onClickKnock = () => {
    // const doorKnock = new UIfx(
    //   doorKnockSound,
    //   {
    //     volume: 1.0, // number between 0.0 ~ 1.0
    //     throttleMs: 100
    //   });

    this.props.onKnockSelected(this.props.userId);
    //doorKnock.play();
    socket.emit("knockDoor", { userId: this.props.userId, doorKnock: doorKnockSound })
  }

  onClickMakeAdmin = () => {
    this.props.onMakeAdminSelected(this.props.userId);
  }


  render() {
    var userData = localStorage.getItem('userdetail');
    var userIsAdmin = JSON.parse(userData).Is_Admin;
    var loggedInUserId = JSON.parse(userData).Id_User;

    return (
      <>
      {!this.props.showScheduleMeetingOption && this.props.Is_DoorOpen && (this.props.userDetails.Is_Admin && userIsAdmin) ? <></> : 
      <ContextMenu className="menu" id={`${this.props.userId}`}>
        {this.props.showScheduleMeetingOption ? 
        <MenuItem
          onClick={() => { this.props.userDetails.Id_User === loggedInUserId ? toast.info("You are already Added") : this.props.getUserFromList(this.props.userDetails.Id_User, this.props.userDetails) }}
          data={{ item: "chat" }}
          className="menuItem"
        >
          Group Chat/Schedule Meeting
          </MenuItem>
          : null}

        {this.props.userDetails.Id_User === this.props.userId && !this.props.userDetails.Is_Admin && userIsAdmin ? (
          <MenuItem
            onClick={() => this.onClickMakeAdmin()}
            data={{ item: "Make Admin" }}
            className="menuItem"
          >
            Make Admin
          </MenuItem>
        ) : ""}
        {!this.props.Is_DoorOpen ? <>
          <MenuItem
            onClick={this.onClickKnock}
            data={{ item: "Knock" }}
            className="menuItem"
          >
            Knock
           </MenuItem>

          <MenuItem
            onClick={() => this.onClickStickyNotes()}
            data={{ item: "Leave Sticky Notes" }}
            className="menuItem"
            onSticktyNoteSelected={this.state.onSticktyNoteSelected}
          >
            Leave Sticky Notes
          </MenuItem>
        </>
          : (this.props.userDetails.UserStartTime_EndTimeVM.Availibility_Status === "OOO" &&
          <MenuItem
            onClick={() => this.onClickStickyNotes()}
            data={{ item: "Leave Sticky Notes" }}
            className="menuItem"
            onSticktyNoteSelected={this.state.onSticktyNoteSelected}
          >
            Leave Sticky Notes
          </MenuItem>
          )
          }
          
      </ContextMenu>
  }
      </>
      //     {/* <MenuItem
      //       onClick={this.handleClick}
      //       data={{ item: "Send Email" }}
      //       className="menuItem"
      //     >
      //       Send Email
      //    </MenuItem>

      //     <MenuItem
      //       onClick={this.handleClick}
      //       data={{ item: "Schedule Meeting" }}
      //       className="menuItem"
      //     >
      //       Schedule Meeting
      //     </MenuItem> */}              

      // {/* Make OOO user an Admin */}


    );

  }

}


export default ContextMenuOption;