
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from "../../../utils/apiUrls";
import { slackconfig } from '../../../actions/slackconfigActions';


class SlackConfigModal extends React.Component {
  state = {
    fields: {
      "accesstoken": "xoxp-89655370407-1077187449557-1105709520675-25ef163bda5dbf17bdf8533da4bcc2eb",
      "appid": "A017KCDHCUE",
      "clientid": "1245264436822.1257421590966",
      "clientsecret": "fbe90225fba6981a4ea3522ab8f28c8b",
      "verificationtoken": "ynBmbJTyl0fkbzup9AtPO5Vx",
      "scope": "calls:read,calls:write,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.customize,chat:write.public,groups:history,im:history,links:write,mpim:history,team:read,usergroups:read,usergroups:write,users.profile:read,users:read,users:write,links:read&user_scope=calls:read,calls:write,channels:history,channels:read,channels:write,chat:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,links:read,links:write,mpim:history,mpim:read,mpim:write,search:read,team:read,usergroups:read,usergroups:write,users.profile:read,users.profile:write,users:read,users:read.email,users:write,reminders:write"
    },
    // fields: {
    //   "accesstoken": "",
    //   "appid": "",
    //   "clientid": "",
    //   "clientsecret": "",
    //    "scope":"",
    //   "verificationtoken": ""
    // },
    errors: {},
  };

  // componentDidMount = () => {
  //   if (this.props.slackConfigListResponse[0] !== null) {
  //     let fields = this.state.fields;
  //     fields = {
  //       "accesstoken": this.props.slackConfigListResponse[0].OAuth_Access_Token,
  //       "appid": this.props.slackConfigListResponse[0].App_ID,
  //       "clientid": this.props.slackConfigListResponse[0].Client_ID,
  //       "clientsecret": this.props.slackConfigListResponse[0].Client_Secret,
  //        "scope":this.props.slackConfigListResponse[0]Scope_Detail
  //     }
  //     this.setState({ fields })
  //   }
  // }

  // componentWillReceiveProps = (nextProps) => {
  //   if (nextProps.slackconfigResponse !== this.props.slackconfigResponse) {
  //     this.props.getSlackConfigurationlist()
  //     this.props.toggle()
  //   }
  // };

  componentDidUpdate(prevProps) {
    if (prevProps.slackconfigResponse !== this.props.slackconfigResponse) {
      this.props.getSlackConfigurationlist()
      this.props.toggle()
    }
  }

  // Function calls while type and  set their values.
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  // Function handles validation and if success then calls slack config method.
  onSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.onSlackConfig();
    }
  };

  // Calls Sign up  API to get logged in.
  onSlackConfig = async () => {
    const { API_HOST, slackconfig, slackConfigListResponse } = this.props;
    let clientId = this.state.fields["clientid"];
    let scope = this.state.fields["scope"];
    let url = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${window.location.protocol}//${window.location.host}/slacktoken`;
    let id = 0;
    if (slackConfigListResponse && slackConfigListResponse[0] !== null) {
      id = slackConfigListResponse[0].Id;
    }
    const slackconfigRequest = {
      Id: id,
      OAuth_Access_Token: this.state.fields["accesstoken"],
      App_ID: this.state.fields["appid"],
      Client_ID: clientId,
      Client_Secret: this.state.fields["clientsecret"],
      Scope_Detail: scope,
      Redirect_Url: url
    };

    await slackconfig(API_HOST, slackconfigRequest);

  };


  // Handles validation for required fields.sdfs
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // accesstoken
    if (!fields["accesstoken"]) {
      formIsValid = false;
      errors["accesstoken"] = "Accesstoken is required";
      errors["accesstokenclass"] = "is-invalid";
    } else {
      formIsValid = true;
      errors["accesstoken"] = "Accesstoken";
      errors["accesstokenclass"] = "is-valid";
    }

    // appid
    if (!fields["appid"]) {
      formIsValid = false;
      errors["appid"] = "Appid is required";
      errors["appidclass"] = "is-invalid";
    } else {
      formIsValid = true;
      errors["appid"] = "Appid";
      errors["appidclass"] = "is-valid";
    }

    // Client Id
    if (!fields["clientid"]) {
      formIsValid = false;
      errors["clientid"] = "Clientid is required";

      errors["clientidclass"] = "is-invalid";
    } else {
      formIsValid = true;
      errors["clientid"] = "Clientid";
      errors["clientidclass"] = "is-valid";
    }


    if (!fields["clientsecret"]) {
      formIsValid = false;
      errors["clientsecret"] = "clientsecret is required";
      errors["clientsecretclass"] = "is-invalid";
    } else {
      formIsValid = true;
      errors["clientsecret"] = "clientsecret";
      errors["clientsecretclass"] = "is-valid";
    }

    // if (!fields["verificationtoken"]) {
    //   formIsValid = false;
    //   errors["verificationtoken"] = "Verificationtoken is required";
    //   errors["userconfirmpasswordclass"] = "is-invalid";
    // } else {
    //   formIsValid = true;
    //   errors["verificationtoken"] = "Verificationtoken";
    //   errors["userconfirmpasswordclass"] = "is-valid";
    // }

    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { isOpen } = this.props;
    let url="../../images/SlackConfig.mp4";

    return (
      <Modal isOpen={isOpen} backdrop={true} className="add-edit-class">
        <ModalHeader toggle={() => this.props.toggle()} className="dept-header">
          Slack Configuration
        </ModalHeader>
        <form method="post" id="form2" onSubmit={this.onSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">

                <div className="form-group">
                  <label htmlFor="userNameInput">AccessToken</label>
                  <input
                    type="text"
                    className={`form-control ${this.state.errors && this.state.errors["accesstokenclass"]
                      ? this.state.errors["accesstokenclass"]
                      : ""
                      }`}
                    id="AccessTokenInput"
                    aria-describedby="NameHelp"
                    //placeholder="Enter AccessToken"
                    value={this.state.fields["accesstoken"] || ""}
                    name="accessToken"
                    onChange={(e) => this.handleChange("accesstoken", e)}
                    required
                  />

                </div>
                <div className="form-group">
                  <label htmlFor="domainNameInput">AppId</label>
                  <input
                    type="text"
                    className={`form-control ${this.state.errors && this.state.errors["appidclass"]
                      ? this.state.errors["appidclass"]
                      : ""
                      }`}
                    id="domainNameInput"
                    value={this.state.fields["appid"] || ""}
                    //placeholder="Enter your AppId"
                    name="appid"
                    onChange={(e) => this.handleChange("appid", e)}
                    required
                  />
                  {/* <span style={{ color: "red" }}>
                      {this.state.errors["domain"]}
                    </span> */}
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">ClientId</label>
                  <input
                    type="text"
                    className={`form-control
                       ${this.state.errors && this.state.errors["clientidclass"]
                        ? this.state.errors["clientidclass"]
                        : ""}
                      `}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    //placeholder="Enter your clientid"
                    value={this.state.fields["clientid"] || ""}

                    name="clientid"
                    onChange={(e) => this.handleChange("clientid", e)}
                    required
                  />
                  {/* <span style={{ color: "red" }}>
                      {this.state.errors["accesstoken"]}
                    </span> */}
                </div>
                <div className="form-group">
                  <label htmlFor="clientsecret">ClientSecret</label>
                  <input
                    type="text"
                    className={`form-control ${this.state.errors &&
                      this.state.errors["clientsecretclass"]
                      ? this.state.errors["clientsecretclass"]
                      : ""
                      }`}
                    id="clientsecret"
                    //placeholder=" Enter ClientSecret"
                    value={this.state.fields["clientsecret"] || ""}
                    name="clientsecret"
                    onChange={(e) => this.handleChange("clientsecret", e)}
                    required
                  />
                  {/* <span style={{ color: "red" }}>
                      {this.state.errors["password"]}
                    </span> */}
                </div>
                <div className="form-group">
                  <label htmlFor="VerificationToken">
                    Scope
                    </label>
                  <input
                    type="text"
                    className="form-control"
                    id="VerificationToken"
                    //placeholder=" Enter Verification Token"
                    value={this.state.fields["scope"] || ""}
                    name="scope"
                    onChange={(e) =>
                      this.handleChange("scope", e)
                    }
                    required
                  />
                </div>
               
                <div className="form-group">
                  <p>Check out the <a href={url} target="_blank">demo</a> for Slack Configuration.</p>
                </div>

                {/* <div className="form-group">
                  <label htmlFor="VerificationToken">
                    Verification Token
                    </label>
                  <input
                    type="text"
                    className={`form-control ${this.state.errors &&
                        this.state.errors["userconfirmpasswordclass"]
                        ? this.state.errors["userconfirmpasswordclass"]
                        : ""
                      }`}
                    id="VerificationToken"
                    //placeholder=" Enter Verification Token"
                    value={this.state.fields["verificationtoken"] || ""}
                    // value="Hbf7wxXA1qI5nGzeXscGml87"
                    name="verificationtoken"
                    onChange={(e) =>
                      this.handleChange("verificationtoken", e)
                    }
                  /> 
                </div> */}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit"  onClick={this.props.highlightImport}>Save</Button>
          </ModalFooter>
        </form>
      </Modal >
    );
  }
}

// Prop types of props.
SlackConfigModal.propTypes = {
  API_HOST: PropTypes.string,
  slackconfig: PropTypes.func,
  slackconfigResponse: PropTypes.object,
  slackConfigListResponse: PropTypes.any
};

// Set default props..
SlackConfigModal.defaultProps = {
  slackconfig: () => { },
  slackConfigListResponse: []
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  slackconfigResponse: state.slackconfigReducer.slackconfigResponse.slackconfigResponse,
  slackConfigListResponse: state.dashboardReducer.slackConfigListResponse.slackConfigListResponse
});

export default connect(mapStateToProps, { slackconfig })(SlackConfigModal);
