import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import Avatar from 'avataaars';
import { onlyNumber, onlyAlphabet } from '../../../utils/utility';
import { AvatarImage } from '../../../components/Avatar/AvatarImage';
import { deleteUserAccount } from '../../../actions/dashboardActions';
import { getApiHost, socket } from '../../../utils/apiUrls';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

class EditMyProfile extends Component {
  state = {
    fields: {},
    error: {},
    userDetails: null,
    src: '',
    multipleEmailResponse: [],
    onDeleteAccount: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userDetails !== this.props.userDetails) {
      this.setState({ userDetails: nextProps.userDetails, src: nextProps.src });
    }

    if (nextProps.multipleEmailResponse !== this.props.multipleEmailResponse) {
      //this.setState({ multipleEmailResponse: nextProps.multipleEmailResponse });

      if (nextProps.multipleEmailResponse.length >= 1) {
        this.props.changeWorkspace(nextProps.multipleEmailResponse[0].Id);
      }

      if (nextProps.multipleEmailResponse.length === 0) {
        this.props.redirectLogin();
        this.props.changeWorkspace(0);
      }
    }
  }

  componentDidMount() {
    this.setState({
      userDetails: this.props.userDetails,
      src: this.props.src,
      multipleEmailResponse: this.props.multipleEmailResponse,
    });
  }

  //Function call when any input field is changing
  handleEditProfileChange = (e) => {
    let userDetails = this.state.userDetails;
    if (e.target.name === 'Id_Department') {
      userDetails[e.target.name] = parseInt(e.target.value);
      userDetails['Department_Name'] =
        e.target.options[e.target.selectedIndex].text;
    } else {
      userDetails[e.target.name] = e.target.value;
    }
    this.setState({
      userDetails,
    });
  };

  // handleChangeTimezone = (tz, label, offset) =>{
  //   let userDetails = this.state.userDetails;
  //   userDetails["TZ"] = tz;
  //   userDetails["TZ_Label"]=label;
  //   userDetails["TZ_Offset"] = offset * 60;
  //   this.setState({ userDetails });

  // }

  deleteUserAccount = async () => {
    let userDetails = JSON.parse(localStorage.getItem('userdetail'));
    userDetails.Is_Active = false;
    socket.emit('UserDetail', userDetails);

    const { API_HOST, deleteUserAccount } = this.props;
    await deleteUserAccount(API_HOST, (res) => {
      if (res) {
        toast.success('Your Precis Account has been successfully deleted!!');
        this.props.fetchMultipleEmail();

        localStorage.removeItem('Token');
        localStorage.removeItem('SlackUserId');
        localStorage.removeItem('isFromSlackSiginIn');
      }
    });
  };

  //open Delete Popup
  onDeleteAccountPopup = () => {
    this.setState({
      onDeleteAccount: true,
    });
  };

  //close Delete Popup
  onCloseDialog = () => {
    this.setState({
      onDeleteAccount: false,
    });
  };

  validateFirstName = (e) => {
    const firstNameText = e.target.value;
    var pattern = /^[a-zA-Z]*$/g;

    if (e.target.name === 'First_Name' && !firstNameText.match(pattern)) {
      toast.error(
        'First Name can have only Alphabets and length upto 20 characters'
      );
    }
  };

  validateLastName = (e) => {
    const lastNameText = e.target.value;
    var pattern = /^[a-zA-Z]*$/g;

    if (e.target.name === 'Last_Name' && !lastNameText.match(pattern)) {
      toast.error(
        'Last Name can have only Alphabets and length upto 20 characters'
      );
    }
  };

  validatePhoneNumber = (e) => {
    const phoneNumber = e.target.value;
    var pattern = /[1-9]{1}[0-9]{9}/;

    if (e.target.name === 'Phone' && !phoneNumber.match(pattern)) {
      toast.error(
        'Phone number can have only numbers and length upto 10 characters'
      );
    }
  };

  validateDisplayName = (e) => {
    const displayName = e.target.value;

    if (e.target.name === 'Real_Name' && displayName === '') {
      toast.error('Display name cannot be blank.');
    }
  };

  render() {
    const { userDetails } = this.state;
    const deleteAccountTip =
      'Click here to delete your account from the current workspace<br /> in Precis. To recreate signin back as you did before.';

    return (
      <>
        <div className="container-fluid pt-4 pb-4">
          <form className="form-horizontal">
            <div className="row justify-content-end">
              <div className="col-sm-8">
                <div className="form-group">
                  <label className=" control-label col-sm-4">First Name</label>
                  <input
                    type="text"
                    className="form-control col-sm-8 inputButton"
                    id="firstName"
                    aria-describedby="firstName"
                    //value={userDetails ? userDetails.First_Name : ""}
                    value={
                      userDetails
                        ? userDetails.First_Name
                          ? userDetails.First_Name
                          : ''
                        : ''
                    }
                    name="First_Name"
                    onChange={this.handleEditProfileChange}
                    maxLength="20"
                    //pattern="/^[a-zA-Z]*$/g"
                    //onKeyPress={onlyAlphabet}
                    onBlur={(e) => this.validateFirstName(e)}
                  />
                </div>
                <div className="form-group">
                  <label className=" control-label col-sm-4"> Last Name</label>
                  <input
                    type="text"
                    className="form-control col-sm-8 inputButton"
                    id="lastName"
                    aria-describedby="lastName"
                    //pattern="/^[a-zA-Z]*$/g"
                    //onKeyPress={onlyAlphabet}
                    maxLength="20"
                    value={userDetails ? userDetails.Last_Name : ''}
                    name="Last_Name"
                    onChange={this.handleEditProfileChange}
                    onBlur={(e) => this.validateLastName(e)}
                  />
                </div>
                <div className="form-group">
                  <label className=" control-label col-sm-4">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control col-sm-8 inputButton"
                    id="phone"
                    aria-describedby="phone"
                    maxLength="10"
                    value={userDetails ? userDetails.Phone : ''}
                    name="Phone"
                    onChange={this.handleEditProfileChange}
                    onBlur={(e) => this.validatePhoneNumber(e)}
                    //onKeyPress={onlyNumber}
                    //pattern="[1-9]{1}[0-9]{9}"
                    //title="Phone number should not start with 0 and sholud be exactly 10 digit number"
                  />
                </div>
                {/* <div className="form-group">
                <label className=" control-label col-sm-4">Skype</label>
                <input
                  type="text"
                  className="form-control col-sm-8 inputButton"
                  id="skype"
                  aria-describedby="skype"
                  value={userDetails ? userDetails.Skype : ""}
                  name="Skype"
                  onChange={this.handleEditProfileChange}
                />
              </div> */}
                {/* <div className="form-group">
                <label className=" control-label col-sm-4">Address</label>
                <input
                  type="text"
                  className="form-control col-sm-8 inputButton"
                  id="address"
                  aria-describedby="address"
                  value={userDetails ? userDetails.Address : ""}
                  name="Address"
                  onChange={this.handleEditProfileChange}
                />
              </div> */}
                {/* <div className="form-group">
                <label className=" control-label col-sm-4">ZipCode</label>
                <input
                  type="text"
                  className="form-control col-sm-8 inputButton"
                  id="zipcode"
                  aria-describedby="zipcode"
                  value={userDetails ? userDetails.ZipCode : ""}
                  name="ZipCode"
                  onChange={this.handleEditProfileChange}
                />
              </div> */}
                {/* <div className="form-group">
              <label className=" control-label col-sm-4">TimeZone</label>
              <SelectTimezone
                      className="col-sm-8 inputButton timezone-edit"
                      value={userDetails ? userDetails.TZ : "Asia/Kolkata"}
                      //value={userDetails.TZ || ''}
                      //value={userDetails ? userDetails.ZipCode : ""}
                      clearable
                      guess
                      placeholder="Select time zone"
                      onChange={value => {
                        const timezoneProps = getTimezoneProps(value)
                        this.handleChangeTimezone(timezoneProps.value, moment.tz(timezoneProps.value).format('zz'), timezoneProps.offset)

                      }}
                    />
              </div> */}
                <div className="form-group">
                  <label className=" control-label col-sm-4">
                    Display Name<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control col-sm-8 inputButton"
                    id="realName"
                    aria-describedby="realName"
                    value={userDetails ? userDetails.Real_Name : ''}
                    name="Real_Name"
                    onChange={this.handleEditProfileChange}
                    required
                    onBlur={(e) => this.validateDisplayName(e)}
                  />
                </div>
                {/* <div className="form-group">
                <label className=" control-label col-sm-4">Display Name</label>
                <input
                  type="text"
                  className="form-control col-sm-8 inputButton"
                  id="displayName"
                  aria-describedby="displayName"
                  value={userDetails ? userDetails.Display_Name : ""}
                  name="Display_Name"
                  onChange={this.handleEditProfileChange}
                />
              </div> */}
                <div className="form-group">
                  <label className=" control-label col-sm-4">Title</label>
                  <input
                    type="text"
                    className="form-control col-sm-8 inputButton"
                    id="title"
                    aria-describedby="title"
                    value={userDetails ? userDetails.Title : ''}
                    name="Title"
                    onChange={this.handleEditProfileChange}
                  />
                </div>
                <div className="form-group">
                  <label className=" control-label col-sm-4">Department</label>
                  <select
                    className="form-control col-sm-8 inputButton"
                    value={userDetails ? '' + userDetails.Id_Department : 0}
                    id="department"
                    name="Id_Department"
                    onChange={(e) => {
                      this.handleEditProfileChange(e);
                    }}
                  >
                    <option value={0}>Select Department</option>
                    {this.props.getDepartmentsResponse &&
                      this.props.getDepartmentsResponse.map((department) => {
                        return (
                          <option value={department.Id} key={department.Id}>
                            {department.Name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <Button
                  color="primary"
                  className="col-sm-4"
                  onClick={(e) => this.props.updateUser(e, userDetails)}
                >
                  Save Changes
                </Button>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <AvatarImage
                    profilePic={this.props.myAvatar}
                    className=""
                    style={{ width: '180px', height: '180px' }}
                  />
                </div>
                <div style={{ fontStyle: 'oblique' }}>
                  Your Email Address :{' '}
                </div>
                <div style={{ wordWrap: 'break-word', fontWeight: '500' }}>
                  {JSON.parse(localStorage.getItem('userdetail')).EmailId}
                </div>
                <ReactTooltip
                  id="deleteAccount"
                  place="right"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                <Button
                  data-for="deleteAccount"
                  data-tip={deleteAccountTip}
                  color="primary"
                  className="mt-4"
                  onClick={this.onDeleteAccountPopup}
                >
                  Delete Account
                </Button>
              </div>
            </div>
          </form>
        </div>

        {/* Delete popup Modal */}
        {this.state.onDeleteAccount ? (
          <Modal
            isOpen={this.state.onDeleteAccount}
            backdrop={true}
            className="dept-class"
          >
            <ModalHeader
              toggle={() => this.onCloseDialog()}
              className="dept-header"
            >
              Delete Account
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete your Precis account for{' '}
              {
                JSON.parse(localStorage.getItem('userdetail'))
                  .Slack_WorkSpace_Name
              }{' '}
              Workspace ?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.deleteUserAccount}>
                Yes
              </Button>
              <Button color="secondary" onClick={this.onCloseDialog}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ''
        )}
      </>
    );
  }
}

EditMyProfile.defaultProps = {
  API_HOST: getApiHost(),
  myAvatar: {},
  onselected: () => {},
  toggle: () => {},
  userDetails: {},
  updateUser: () => {},
};

EditMyProfile.propTypes = {
  API_HOST: PropTypes.string,
  myAvatar: PropTypes.object,
  onselected: PropTypes.func,
  toggle: PropTypes.func,
  userDetails: PropTypes.object,
  updateUser: PropTypes.func,
};

const mapStateToProps = (state) => ({
  getDepartmentsResponse: state.getDepartmentReducer.getDepartmentsResponse,
});

export default connect(mapStateToProps, { deleteUserAccount })(EditMyProfile);
