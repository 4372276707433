export const getSlackTeamReducer = (state) => state.dashboardReducer;

export const getSlackTeamResponse = (state) =>
  getSlackTeamReducer(state).fetchSlackTeam;

export const getSlackMemberResponse = (state) =>
  getSlackTeamReducer(state).fetchSlackMember;

export const slackUserResponse = (state) =>
  getSlackTeamReducer(state).fetchSlackUser;

export const userDetailResponse = (state) =>
  getSlackTeamReducer(state).getUserDetailResponse;

export const getUserListResponse = (state) =>
  getSlackTeamReducer(state).importUserList;

export const tenantDetailResponse = (state) =>
  getSlackTeamReducer(state).getTenantDetail;

export const updateTenantResponse = (state) =>
  getSlackTeamReducer(state).updateTenant;

export const updateUserResponse = (state) =>
  getSlackTeamReducer(state).updateUser;

export const updateUserProfilePicResponse = (state) =>
  getSlackTeamReducer(state).updateUserProfilePic;

export const changePasswordResponse = (state) =>
  getSlackTeamReducer(state).changePassword;

export const getOfficeReducer = (state) => state.officeReducer;
export const fetchOfficeResponse = (state) =>
  getOfficeReducer(state).fetchOfficeResponse;

export const getUserStickyListResponse = (state) =>
  getSlackTeamReducer(state).getUserStickyListResponse;

export const setReadUserStickyResponse = (state) =>
  getSlackTeamReducer(state).setReadUserStickyResponse;

export const addStickyNotesToToDoResponse = (state) =>
  getSlackTeamReducer(state).addStickyNotesToToDoResponse;

export const searchUserResponse = (state) =>
  getSlackTeamReducer(state).searchUserResponse;

export const departmentListByUserResponse = (state) =>
  getSlackTeamReducer(state).departmentListByUserResponse;

export const userListByDepartmentResponse = (state) =>
  getSlackTeamReducer(state).userListByDepartmentResponse;

export const slackConfigListResponse = (state) =>
  getSlackTeamReducer(state).slackConfigListResponse;

export const slackConfigListLoginResponse = (state) =>
  getSlackTeamReducer(state).slackConfigListLoginResponse;

export const getTenantSlackUserAccessTokenResponse = (state) =>
  getSlackTeamReducer(state).getTenantSlackUserAccessTokenResponse;

export const addVirtualChatGroupResponse = (state) =>
  getSlackTeamReducer(state).addVirtualChatGroupResponse;

export const userLeaveDetailResponse = (state) =>
  getSlackTeamReducer(state).userLeaveDetailResponse;

export const userNameByUserIdResponse = (state) =>
  getSlackTeamReducer(state).userNameByUserIdResponse;

export const slackUserDetailsReducer = (state) => state.slackUserDetailsReducer;

export const getslackUserDetailsResponse = (state) =>
  slackUserDetailsReducer(state).slackUserDetailsResponse;

export const mainOfficeReducer = (state) => state.mainOfficeReducer;

export const userListDepartmentFilterResponse = (state) =>
  mainOfficeReducer(state).userListDepartmentFilterResponse;

export const departmentListFilterWiseResponse = (state) =>
  mainOfficeReducer(state).departmentListFilterWiseResponse;

export const multipleEmailResponse = (state) =>
  getSlackTeamReducer(state).fetchMultipleEmail;

export const changeWorkspaceResponse = (state) =>
  getSlackTeamReducer(state).changeWorkspaceResponse;

export const userKnockListCabinWiseResponse = (state) =>
  mainOfficeReducer(state).userKnockListCabinWiseResponse;
