import React from 'react';
//import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { getApiHost } from "../../../utils/apiUrls";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
    getUserStickyList, readUserSticky,
    setReadUserSticky, addStickyNotesToToDo
} from '../../../actions/dashboardActions';
import { getCabinUserStickyList } from "../../../actions/mainOfficeActions";
import { userToDoList } from "../../../actions/todoActions";
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { Typography } from '@material-ui/core'
import LoadingOverlay from 'react-loading-overlay';

class StickyNotesList extends React.Component {

    state = {
        // deltaPosition: {
        //     x: 0, y: 0
        // },
        loading: false,
        stickyId: 0,
        getCabinUserStickyList: []
    };

    componentDidMount = () => {
        if (this.props.isCabin) {
            this.getCabinUserStickyList();
        }
        else {
            this.getReceivedStickyNotes();
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.getUserStickyListResponse !== this.props.getUserStickyListResponse) {
    //         if (!this.props.isCabin) {
    //             this.setState({
    //                 getCabinUserStickyList: nextProps.getUserStickyListResponse
    //             })
    //         }
    //     }
    //     if (nextProps.getCabinUserStickyListResponse !== this.props.getCabinUserStickyListResponse) {
    //         if (this.props.isCabin) {
    //             this.setState({
    //                 getCabinUserStickyList: nextProps.getCabinUserStickyListResponse
    //             })
    //         }
    //     }
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.getUserStickyListResponse !== this.props.getUserStickyListResponse && !this.props.isCabin) {
            this.setState({
                getCabinUserStickyList: nextProps.getUserStickyListResponse
            })
        } else if (nextProps.getCabinUserStickyListResponse !== this.props.getCabinUserStickyListResponse && this.props.isCabin) {
            this.setState({
                getCabinUserStickyList: nextProps.getCabinUserStickyListResponse
            })
        }
    }

    getReceivedStickyNotes = async () => {
        var userData = localStorage.getItem('userdetail');
        const { API_HOST, getUserStickyList } = this.props;
        this.setState({ loading: true })
        await getUserStickyList(API_HOST, JSON.parse(userData).Id_User, (res) => {
            this.setState({ loading: false })
        });
        // this.setState({
        //     getCabinUserStickyList: this.props.getUserStickyListResponse
        // })
    }

    // handleDragSticky = (e, ui) => {
    //     const { x, y } = this.state.deltaPosition;
    //     this.setState({
    //         deltaPosition: {
    //             x: x + ui.deltaX,
    //             y: y + ui.deltaY,
    //         }
    //     });
    //     if (x === 25) {
    //         this.setState({
    //             deltaPosition: {
    //                 x: 0,
    //                 y: 0,
    //             }
    //         })
    //         let selectStickyRemove = this.state.getCabinUserStickyList.filter(sticky => sticky.Id !== this.state.stickyId)
    //         this.setState({ getCabinUserStickyList: selectStickyRemove })
    //         this.addStickyNotesToToDo(this.state.stickyId) //Add to To Do List
    //     }
    //     if (x === -25) {
    //         this.setState({
    //             deltaPosition: {
    //                 x: 0,
    //                 y: 0,
    //             }
    //         })
    //         let selectStickyRemove = this.state.getCabinUserStickyList.filter(sticky => sticky.Id !== this.state.stickyId)
    //         this.setState({ getCabinUserStickyList: selectStickyRemove })
    //         this.setReadUserSticky(this.state.stickyId)
    //     }

    // };

    leftSwipe = () => {
        let selectStickyRemove = this.state.getCabinUserStickyList.filter(sticky => sticky.Id !== this.state.stickyId)
        this.setState({ getCabinUserStickyList: selectStickyRemove });
        this.setReadUserSticky(this.state.stickyId); //Mark as Read
    }

    rightSwipe = () => {
        let selectStickyRemove = this.state.getCabinUserStickyList.filter(sticky => sticky.Id !== this.state.stickyId)
        this.setState({ getCabinUserStickyList: selectStickyRemove });
        this.addStickyNotesToToDo(this.state.stickyId); //Add to To Do List
    }

    //Get Cabin User Sticky List
    getCabinUserStickyList = async () => {
        const { API_HOST, getCabinUserStickyList } = this.props;
        const getCabinUserStickyListRequest = {
            "Id_UserReceiver": 0
        }
        this.setState({ loading: true })
        await getCabinUserStickyList(API_HOST, getCabinUserStickyListRequest, (res) => {
            this.setState({ loading: false })
        })
        // this.setState({
        //     getCabinUserStickyList: this.props.getCabinUserStickyListResponse
        // })
    }

    setStickyId = (id) => {
        this.setState({
            stickyId: id
        })
        //console.log("StickyID: ",this.state.stickyId);
    }


    setReadUserSticky = async (stickyId) => {
        if (stickyId !== 0) {
            const { API_HOST, setReadUserSticky } = this.props;

            await setReadUserSticky(API_HOST, stickyId, (res) => {

            });
            // if (this.props.setReadUserStickyResponse) {
            //     if (this.props.isCabin) {
            //         this.getCabinUserStickyList();
            //         this.props.stickyCount();
            //     }
            //     else {
            //         this.getReceivedStickyNotes();
            //     }
            // }
        }
    }

    readUserSticky = (stickyId) => {
        if (stickyId !== 0) {
            const { API_HOST, readUserSticky } = this.props;
            readUserSticky(API_HOST, stickyId);
        }
    }

    addStickyNotesToToDo = async (stickyId) => {
        if (stickyId !== 0) {
            const { API_HOST, addStickyNotesToToDo } = this.props;
            await addStickyNotesToToDo(API_HOST, stickyId, (res) => {
                if (res) {
                    this.readUserSticky(stickyId)
                    this.userToDoList();
                }
            });
        }
    }

    //Get user To Do List
    userToDoList = async () => {
        var userData = localStorage.getItem('userdetail');
        const { API_HOST, userToDoList } = this.props;

        await userToDoList(API_HOST, JSON.parse(userData).Id_User);
    }

    toggleStickyNotes = () => {
        this.props.toggleStickyNotes(this.state.getCabinUserStickyList);
    }

    render() {
        const { getCabinUserStickyList, loading } = this.state;
        const moment = require('moment-timezone');
        const timezone = JSON.parse(localStorage.getItem('userdetail')).TZ ? JSON.parse(localStorage.getItem('userdetail')).TZ : "Asia/Kolkata";

        //console.log("sticky res",getCabinUserStickyList);
        let classNameBox = "box stickies";
        return (
            <Modal isOpen={!this.props.isStickyModal} style={{ top: '20%' }} >
                <ModalHeader className="sticky-header" toggle={this.toggleStickyNotes}>Sticky Notes <small><i className="fa fa-angle-left"></i>Swipe Left to mark as Read/Right to set as To Do<i className="fa fa-angle-right"></i></small>
                </ModalHeader>
                <LoadingOverlay
                    active={loading}
                    spinner
                //text='Loading...'
                >
                    {getCabinUserStickyList && getCabinUserStickyList[0] !== null && <ModalBody className="sticky-body-swipe" style={{ padding: '0rem' }}>
                        {getCabinUserStickyList.map((sticky, index) => {
                            return (
                                <SwipeableList threshold={0.5}>
                                    {({ className, ...rest }) => (
                                        <div className={classNameBox} style={{ cursor: 'pointer', zIndex: `${index}` }} onMouseOver={() => this.setStickyId(sticky.Id)}>
                                            <SwipeableListItem
                                                swipeLeft={{
                                                    content: <div className={classNameBox} style={{ background: '#EC7063', width: '100%', textAlign: 'right' }}><span>Mark As Read</span><i style={{ marginLeft: '5px' }} class="fa fa-check-circle"></i></div>,
                                                    action: () => this.leftSwipe()
                                                }}
                                                swipeRight={{
                                                    content: <div className={classNameBox} style={{ background: '#58D68D', width: '100%' }}><i class="fa fa-tasks"></i><span style={{ marginLeft: '5px' }}  >Set As To Do</span></div>,
                                                    action: () => this.rightSwipe()
                                                }}
                                            >
                                                <div>
                                                    {/* <Typography variant="body2" component="h5">{sticky ? sticky.description : ""}</Typography> */}
                                                    <span style={{whiteSpace:'pre-line'}}>{sticky ? sticky.description : ""}</span>
                                                    <Typography style={{ fontSize: 'small' }}>{sticky ? sticky.Name_UserSender : ""} {sticky ? moment.utc(sticky.Created_Date).tz(timezone).format('dddd, MMMM D HH:mm') : null}</Typography>
                                                </div>
                                            </SwipeableListItem>
                                        </div>
                                    )}
                                </SwipeableList>
                                // <Draggable
                                //     bounds={{ top: 0, left: -25, right: 25, bottom: 0 }}
                                //     onDrag={this.handleDragSticky}
                                //     handle=".handle"
                                //     defaultPosition={{ x: 0, y: 0 }}
                                //     position={null}
                                //     key={sticky.Id}
                                // // onStart={() => this.setStickyId(sticky.Id)}
                                // >
                                // <div>
                                //     <div className="handle box stickies" style={{ cursor: 'pointer', zIndex: `${index}` }} onMouseOver={() => this.setStickyId(sticky.Id)}>
                                //         <div>
                                //             {sticky ? sticky.description : ""}<br /> <small>{sticky ? sticky.Name_UserSender : ""} {sticky ? moment.utc(sticky.Created_Date).tz(timezone).format('dddd, MMMM D HH:mm') : null}</small>
                                //         </div>
                                //     </div>
                                // </div>
                                // </Draggable>

                            )
                        })}</ ModalBody>}
                    {getCabinUserStickyList && (getCabinUserStickyList.length === 0 || getCabinUserStickyList[0] === null) && < ModalBody className="sticky-class">No sticky messages left</ModalBody>}
                </LoadingOverlay>
            </Modal>
        );
    }
}
const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    getUserStickyListResponse: state.dashboardReducer.getUserStickyListResponse.getUserStickyListResponse,

    setReadUserStickyResponse: state.dashboardReducer.setReadUserStickyResponse.setReadUserStickyResponse,

    getCabinUserStickyListResponse: state.mainOfficeReducer.getCabinUserStickyListResponse,

    addStickyNotesToToDoResponse: state.dashboardReducer.addStickyNotesToToDoResponse.addStickyNotesToToDoResponse

})
export default connect(mapStateToProps, { readUserSticky, userToDoList, getUserStickyList, setReadUserSticky, getCabinUserStickyList, addStickyNotesToToDo })(StickyNotesList)