import React from 'react';
import Avatar from 'avataaars';

export function AvatarImage(Props) {

    const { profilePic, className, style } = Props;

    return (
        <Avatar
            style={style}
            className={className}
            avatarStyle='Transparent'
            topType={profilePic["topType"]}
            accessoriesType={profilePic["accessoriesType"]}
            hairColor={profilePic["hairColor"]}
            facialHairType={profilePic["facialHairType"]}
            clotheType={profilePic["clotheType"]}
            clotheColor={profilePic["clotheColor"]}
            eyeType={profilePic["eyeType"]}
            eyebrowType={profilePic["eyebrowType"]}
            mouthType={profilePic["mouthType"]}
            skinColor={profilePic["skinColor"]}
            graphicType={profilePic["graphicType"]}
            hatColor={profilePic["hatColor"]}
        />
    )

}