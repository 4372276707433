
import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class ZoomJoin extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            zoomUrl: this.props.zoomUrl,
            isZoomModal: this.props.isZoomModal
        }
    }

    toggle = () => {
        this.props.zoomModalJoin();
    }


    render() {
        const { zoomUrl, isZoomModal } = this.state;
        const iframeUrl =
            `<iframe allow="microphone; camera" width='100%' height='100%' frameBorder="0" scrolling='no' src="${zoomUrl}/webhp?igu=1&gws_rd=ssl" sandbox='allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe>`;
        return (
            <Modal isOpen={isZoomModal} toggle={this.toggle} size='lg' backdrop={false} className="joinZoom" >
                <ModalHeader toggle={this.toggle}></ModalHeader>

                <ModalBody>

                    {/* <iframe className="zoom-frame" style={{ width: '100%', height: '100%' }} title="zoom" scrolling='no' src="https://zoom.us/wc/join/97530362673" sandbox="allow-forms allow-scripts allow-same-origin" allow="microphone; camera; fullscreen"></iframe> */}
                    {/* <iframe className="zoom-frame" style={{ width: '100%', height: '100%' }}  title="zoom" scrolling='no' allow="microphone; camera" src={zoomUrl} frameBorder="0" sandbox='allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe> */}

                    <div className="zoom-frame" dangerouslySetInnerHTML={{ __html: iframeUrl ? iframeUrl : "" }} />
                </ModalBody>

            </Modal>
        );
    }
}

export default ZoomJoin;