import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost, socket, slack_socket } from "../../../utils/apiUrls";
import { getTag, checkMessage } from "../../../utils/utility";
import SlackChatUserList from './SlackChatUserList';
import { AvatarImage } from '../../../components/Avatar/AvatarImage';
import $ from 'jquery';
import { slackUserDetails, channelWiseSlackUserDetails, editTitle } from "../../../actions/slackUserDetailsActions";
import { addSearchTag, getSearchTags } from "../../../actions/tagActions";
import KeywordTag from './KeywordTag';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { InputText } from './InputText';
import EditTitle from './EditTitle';
import { toast } from 'react-toastify';
import { propTypes } from 'react-bootstrap/esm/Image';
import { getEncryptedValue, getDecryptedValue } from "../../../utils/utility";
import { getUserDetail } from "../../../actions/dashboardActions";
import AddMember from './AddMember';
import FileViewer from 'react-file-viewer';
import Emoji from "react-emoji-render";
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import AddChatToExistingJira from './AddChatToExistingJira';
import AddNewTask from "../../jira/components/AddNewTask";
import { getJiraDomain, getUserJiraRefreshToken, getJiraConfig, saveUserToDoFromJiraTask, updateUserJiraRefreshToken, getJiraTaskLogDetails} from "../../../actions/jiraActions";
import ReactTooltip from "react-tooltip";
import AddChatToJiraTask from './AddChatToJiraTask';


// import ReactTextFormat from 'react-text-format';
// import Linkify from 'react-linkify';


const axios = require('axios');
const qs = require('qs');

class ChatWindow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            messageList: [],
            conversationHistory: [],
            teamId: this.props.teamId,
            userLoggedInData: JSON.parse(localStorage.getItem('userdetail')),
            channelWiseSlackUserDetailsResponse: [],
            selection: '',
            tags: [],
            isTag: true,
            timeStamp: 0,
            updatedConversationHistory: [],
            isTyping: '',
            titleModal: false,
            title: '',
            teamDetails: this.props.teamDetails,
            isEdit: false,
            isAddToGroup: false,
            isEmoji: false,
            selectedEmoji: "",
            isGroupChatWindow: true,
            openAddTaskPopup: false,
            jiraIssue: {},
            cloudId: "",
            accessToken: "",
            refreshToken: "",
            jiraClientId: "",
            jiraClientSecret: "",
            JiraTaskList: [],
            jiraDomain: [],
            jiraDomainSelected: {},
            idJiraTask: 0,
            jiraCloudId: "",
            taskDescription: "",
            isOpenAddChatTaskPopup: false,
        };
        this.message = React.createRef();
    }

    componentDidMount() {
        
        setTimeout(() => { this.textInput && this.textInput.focus() }, 1);
        $('#chatWindow').addClass('show').css('display', 'block');
        
        this.fetchHistory(this.props.teamDetails.OtherContent, 0);

        // this.slackUserDetails();
        this.channelWiseSlackUserDetails(this.props.teamDetails.OtherContent);
        this.getTags();
        this.getJIRAConfig();
        this.getJiraDomain();

        // slack_socket.on("updatechannel", event => {
        //     console.log("Event called:  ",event);

        //     this.fetchHistory(this.props.teamDetails.OtherContent);
        // });

        slack_socket.on('updateChatHistory', (event) => {
            // console.log(`Client: Received a message event: user ${event.message.user} in channel ${event.channel} says ${event.message.text}`);
            this.fetchHistory(this.props.teamDetails.OtherContent, this.state.timeStamp);
            this.props.markAsReadOnChannelOpen(this.props.teamDetails.OtherContent)

        });

        socket.on("UserDetail", data => {
            if(data.Id_Tenant === JSON.parse(localStorage.getItem('userdetail')).Id_Tenant){
                this.updateLogedUserDetail(data);
                this.updateChatUsersDetail(data);
            }
            else{
                this.getUserDetail(data.Id_User);
            }
        });
        slack_socket.on('display', (data) => {
            this.isTyping(data);
        })

        socket.on('editTitle',data => {
            this.editTitleRTM(data);
            this.props.fetchSlackTeam(false);
        });
    }

    // componentWillUnmount() {
    //     alert("closed Chatwindow");
    //     this.setState({ isGroupChatWindow: false})
    // }

    componentDidUpdate(prevProps) {

        if (prevProps.getUserDetailResponse !== this.props.getUserDetailResponse) {
            this.updateLogedUserDetail(this.props.getUserDetailResponse);
            this.updateChatUsersDetail(this.props.getUserDetailResponse);
        }

        if (prevProps.teamDetails !== this.props.teamDetails) {
            this.setState({
                conversationHistory: [],
                updatedConversationHistory: [],
                timeStamp: 0,
                teamDetails: this.props.teamDetails
            })
            //slack_socket.emit("message",event);
            //console.log("OnDidUpdate: ", this.state.timeStamp);
            // if(this.state.timeStamp === 0){
            this.fetchHistory(this.props.teamDetails.OtherContent, 0);
            // }else{
            //     this.fetchHistory(this.props.teamDetails.OtherContent, this.state.timeStamp);
            // }

            this.channelWiseSlackUserDetails(this.props.teamDetails.OtherContent);
        }
        if (prevProps.channelWiseSlackUserDetailsResponse !== this.props.channelWiseSlackUserDetailsResponse) {
            this.setState({
                channelWiseSlackUserDetailsResponse: this.props.channelWiseSlackUserDetailsResponse
            })
        }

        if (prevProps.memberListResponse !== this.props.memberListResponse) {
            this.setState({
                memberListResponse: this.props.memberListResponse
            })
        }
        

    }

    componentWillReceiveProps() {
        setTimeout(() => { this.textInput && this.textInput.focus() }, 1);
    }

    isTyping = (data) => {
        if (data.channelType === 'group' && data.typing === true && data.channelDetails.OtherContent === this.props.teamDetails.OtherContent) {
            let typing = data.Display_Name + ' typing...'
            this.setState({ isTyping: typing.toLowerCase() })
        } else {
            this.setState({ isTyping: '' })
        }
    }

    updateLogedUserDetail(data) {

        let userData = JSON.parse(localStorage.getItem('userdetail'));
        if (userData.Id_User === data.Id_User) {
            userData.ProfilePic = data.ProfilePic
            userData.Display_Name = data.Display_Name
            userData.Real_Name = data.Real_Name
            localStorage.setItem("userdetail", JSON.stringify(userData));
            console.log("Chat Window set local Storage");
            this.setState({
                userLoggedInData: userData
            })
        }
    }

    updateChatUsersDetail(data) {
        let newData = this.state.channelWiseSlackUserDetailsResponse.map(item => {
            if (item.Id_User === data.Id_User) {
                item.Display_Name = data.Display_Name
                item.ProfilePic = data.ProfilePic
                item.Real_Name = data.Real_Name
                item.Is_Active = data.Is_Active
            }
            return item;
        });

        this.setState(Object.assign({}, { channelWiseSlackUserDetailsResponse: newData }));
    }

    editTitleRTM = (data) => {

        let newTeamData = this.state.teamDetails;
        if(newTeamData.Id === data.Id){
            newTeamData.Name = data.Name;
        }

        this.setState(Object.assign({}, {teamDetails: newTeamData}));
    }

    getUserDetail = async (id) => {
        const { API_HOST, getUserDetail } = this.props;
        await getUserDetail(API_HOST, id);
    
    };

    minimizeChatWindow = () => {
        this.props.minimizeChatWindow();
        this.setState({ isTag: false })
    }

    maximizeChatWindow = () => {
        this.props.maximizeChatWindow();
        this.setState({ isTag: true })
    }

    closePopup = () => {
        this.maximizeChatWindow();
        $('#chatWindow').removeClass('show').css('display', 'none');
        $('#chatWindow').removeClass('minimizeChat').css('display', 'none');
        // $('#teamlist'+ this.props.teamId).removeClass('current');
        $('#teamlistLi' + this.props.teamDetails.OtherContent).removeClass('current');
        this.props.closeChannelChatWindows();
        // slack_socket.disconnect();
    }

    minmizePopup = () => {
        $('#chatWindow').addClass('minimizeChat').css('display', 'block');
        this.minimizeChatWindow();
        setTimeout(() => { this.textInput && this.textInput.focus() }, 1);
    }

    maximizePopup = () => {
        $('#chatWindow').removeClass('minimizeChat');
        this.maximizeChatWindow();
        setTimeout(() => { this.textInput && this.textInput.focus() }, 1);
    }

    // Send Message
    sendMsg = async (msg) => {
        var userData = JSON.parse(localStorage.getItem('userdetail'));
        var userSlackToken = userData.Slack_Access_Token;
        const { WebClient } = require('@slack/web-api');

        const token = getDecryptedValue(userSlackToken); //logged in user's slack token
        const web = new WebClient(token);

        if (msg !== "" && msg.trim() !== "") {

            let message = {
                token: token,
                text: msg,
                channel: this.props.teamDetails.OtherContent,
                as_user: true,
            };
            const result = await axios.post(`https://slack.com/api/chat.postMessage`, qs.stringify(message));

            //console.log("Sent message Response: ", result.data.channel);
            let slack_event = slack_socket.emit("message", result.data);
            this.fetchHistory(result.data.channel, this.state.timeStamp);
            // console.log("Event Message Client: ", slack_event);
            this.props.changeUnreadChannelCounts(this.props.teamDetails.OtherContent)
            let temp = result.data

            this.setState({
                message: "",
                // conversationHistory: [...this.state.conversationHistory, conHistory]
            });

            let targetElement = document.getElementById(`mainchat${this.props.teamDetails.OtherContent}`);
            targetElement.scrollTop = targetElement.scrollHeight;
        }
    }


    // //Send message on press of Enter Key
    // onKeyDownHandler = e => {
    //     if (e.keyCode === 13) {
    //         this.sendMsg();
    //     }
    // };

    // To fetch users chat history
    fetchHistory = async (slackChannelId, updatedTime) => {

        var userData = JSON.parse(localStorage.getItem('userdetail'));
        var userSlackToken = userData.Slack_Access_Token;

        const { WebClient } = require('@slack/web-api');

        const token = getDecryptedValue(userSlackToken);
        const web = new WebClient(token);
        let conversationHistory;
        let updatedConversationHistory;

        try {
            
            let message = {
                token: token,
                channel: slackChannelId,
                //limit: 30,
                inclusive: true,
                // oldest: "1604407209"
                oldest: updatedTime
            };
            
            const result = await axios.post(`https://slack.com/api/conversations.history`, qs.stringify(message));
            //console.log("Fetch result: ", result.data.messages);

            if (this.state.timeStamp === 0) {
                conversationHistory = result.data.messages;
                this.setState({
                    conversationHistory: conversationHistory,
                    timeStamp: result.data.messages[0].ts
                })

                // console.log("NEW TIMESTAMP: ", this.state.timeStamp);
            }
            else {
                updatedConversationHistory = result.data.messages;
                updatedConversationHistory.splice(updatedConversationHistory.length - 1, 1);
                this.setState({
                    updatedConversationHistory: updatedConversationHistory,
                    // timeStamp:result.data.messages[0].ts
                })

                //console.log("UPDATED TIMESTAMP : ", this.state.timeStamp);
            }

            let targetElement = document.getElementById(`mainchat${this.props.teamDetails.OtherContent}`);
            targetElement.scrollTop = targetElement.scrollHeight;
        }
        catch (error) {
            console.error(error);
        }
    }


    //Get Slack User Details
    slackUserDetails = (slackId) => {
        var userData = localStorage.getItem('userdetail');
        const { API_HOST, slackUserDetails } = this.props;

        slackUserDetails(API_HOST, getDecryptedValue(JSON.parse(userData).Id_SlackUser));
    }


    //Get Channel wise Slack User Details
    channelWiseSlackUserDetails = (channelId) => {
        const { API_HOST, channelWiseSlackUserDetails } = this.props;

        channelWiseSlackUserDetails(API_HOST, channelId);
    }
    

    addTag = async() => {
        let text = this.state.selection;
        if (text) {
            const { API_HOST, addSearchTag } = this.props;
            let reqBody = {
                "Tags": getTag(text, 15)
            }
           await addSearchTag(API_HOST, reqBody, (res) => {
                this.getTags();
            });
        }
    }

    handleSelect = async(e) => {
        e.preventDefault();
        //console.log(`Selected text: ${window.getSelection().toString()}`);
        var text = window.getSelection().toString()
        await this.setState({
            selection: text
        })
    }

    getTags = () => {
        const { API_HOST, getSearchTags } = this.props;
        let userData = JSON.parse(localStorage.getItem('userdetail'));
        getSearchTags(API_HOST, userData.Id_User, (res) => {
            let tags = [];
            if (res) {
                res.map(obj => {
                    tags.push({ key: obj.Id, label: obj.Tags })
                    return tags;
                })
            }
            this.setState({
                tags: tags
            })
        });
    }

    editTitle = (title) => {
        this.setState({
            title: title,
            titleModal: !this.state.titleModal
        })
    }

    saveTitle = async (chatTitle) => {
        var title = chatTitle.trim();
        try {
            var userData = JSON.parse(localStorage.getItem('userdetail'));
            var userSlackToken = userData.Slack_Access_Token;
            const { API_HOST, editTitle } = this.props;
            let newData = this.state.teamDetails;
            let message = {
                token: getDecryptedValue(userSlackToken),
                channel: newData.OtherContent,
                name: title.toLowerCase()
            };
            let reqBody = {
                Id_Slack: newData.OtherContent,
                Name: title.toLowerCase()
            }
            if (newData.Name !== title) {
                const result = await axios.post(`https://slack.com/api/conversations.rename`, qs.stringify(message));
                if (result.data.ok === true) {
                    editTitle(API_HOST, reqBody, (res) => {
                        newData.Name = title;
                        this.setState(Object.assign({}, { teamDetails: newData }));
                        socket.emit('editTitle', newData);
                    });
                } else {
                    toast.error(result.data.error)
                }
            }
        } catch (error) {
            throw error;
        }
    }

    hoverTitle = () => {
        this.setState({ isEdit: !this.state.isEdit })
    }

    addMemberToGroup = () =>{
        this.setState({ isAddToGroup: true})
    }

    closeAddMemberPopUp = () => {
        this.setState({ isAddToGroup: false})
    }

    // handleBlurInput = e => {
    //     this.setState({ [e.target.name]: e.target.value });
    // };

    //Send message on press of Enter Key
    // onKeyDownHandler = (e, msg) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault();
    //         this.sendMsg(msg);
    //     }
    // };

    onErrorFile = (e) => {
        console.log(e, "error in file-viewer");
      };

    loadFile = async(url) => {
        alert("CALLED");
        try {
            let finalUrl = url;
        //     let headers = {
        //         "Authorization": "Bearer " + "xoxp-1709916299141-1952839646545-2053776183381-5fda531c4cf5532e161ec03e98a12017",
        //         // "Access-Control-Allow-Origin": "*",
        //         // "Access-Control-Allow-Credentials": "true",
        //         // "Access-Control-Allow-Headers": "content-type",
        //         // "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTIONS",
        //         "Content-Type": "application/x-www-form-urlencoded;charset=utf8"
        //     };
        //    alert(JSON.stringify(headers));
        //    alert(url);

        //     const data = await axios.get(finalUrl, { headers });


            
            fetch(finalUrl, {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + "xoxp-1709916299141-1952839646545-2053776183381-5fda531c4cf5532e161ec03e98a12017",
                    "Content-Type": "image/jpeg",
                    "Accept": "image/jpeg",
                    "Access-Control-Allow-Origin": "*"
                },
                responseType: "arraybuffer",
            }).then(data =>{console.log(data)})


           // console.log("Picture: ", data);
           // return data;
          } catch (error) {
            toast.error(error);
          }
    };

    openEmoji = () => {
        this.setState({ isEmoji: !this.state.isEmoji });
    }

    selectEmoji = (e) => {
        console.log("Emoji:  ",e);
        this.setState({ selectedEmoji: e.colons});
       // this.sendMsg(e.colons);
    }

    openAddTaskPopup = () => {
        this.setState({ isOpenAddTaskPopup: true })
        //this.getJIRAConfig();
    }

    closeAddTaskPopup = () => {
        this.setState({ isOpenAddTaskPopup: false })
    }

    openAddChatTaskPopup = () => {
        this.setState({ isOpenAddChatTaskPopup: true })
    }

    closeAddChatTaskPopup = () => {
        this.setState({ isOpenAddChatTaskPopup: false })
    }

    /********/
    getJIRAConfig = async() => {
        const { API_HOST, getJiraConfig } = this.props;
        await getJiraConfig(API_HOST, res => {
          if (res[0]) {
            this.setState({
              jiraClientId: getDecryptedValue(res[0].Client_ID),
              jiraClientSecret: getDecryptedValue(res[0].Client_Secret)
            });
    
            //this.getJiraDomain();
            this.getRefreshToken();
          } else {
            this.setState({ jiraClientDisable: true });
          }
        });
      };
  
      getJiraDomain = async () => {
        const { API_HOST, getJiraDomain } = this.props;
        getJiraDomain(API_HOST, res => {
          if (res[0]) {
            let newValue = res.map(function(obj) {
              return {
                id: getDecryptedValue(obj.CloudId),
                CloudId: getDecryptedValue(obj.CloudId),
                Domain_Name: obj.Domain_Name,
                key: obj.Domain_Name,
                url: obj.url,
                Jira_Scope_Detail: obj.Jira_Scope_Detail,
                label: obj.Domain_Name,
                value: getDecryptedValue(obj.CloudId)
              };
            });
            this.setState({
              jiraDomain: newValue,
              jiraDomainSelected: newValue[0],
              cloudId: newValue[0].CloudId
            });
          }
        });
      };
  
      getRefreshToken = () => {
        const { API_HOST, getUserJiraRefreshToken } = this.props;
    
        getUserJiraRefreshToken(API_HOST, res => {
          if (res.Jira_Token) {
            this.setState({ refreshToken: res.Jira_Token });
            this.getAccessToken(res.Jira_Token);
            this.setState({ jiraClientDisable: false, isActive: false });
          } else {
            this.setState({ jiraClientDisable: true, isActive: false });
          }
        });
      };
    
      getAccessToken = async refreshToken => {
        try {
          let finalUrl = "https://auth.atlassian.com/oauth/token";
          let resource = {
            grant_type: "refresh_token",
            client_id: this.state.jiraClientId,
            client_secret: this.state.jiraClientSecret,
            refresh_token: getDecryptedValue(refreshToken)
          };
    
          let headers = {
            "Content-Type": "application/json"
          };
          const data = await axios.post(finalUrl, resource, { headers });
          this.setState({
            accessToken: data.data.access_token,
            refreshToken: data.data.refresh_token
          });
    
          await this.updateUserJiraRefreshToken(data.data.refresh_token);
          await this.getJiraYourTaskList(
            this.state.cloudId,
            data.data.access_token
          );
        } catch (error) {
          toast.error(error);
          this.setState({ isActive: false });
        }
      };
    
      updateUserJiraRefreshToken = async refreshToken => {
        const { API_HOST, updateUserJiraRefreshToken } = this.props;
    
        let requestBody = {
          Jira_Token: getEncryptedValue(refreshToken)
        };
    
        await updateUserJiraRefreshToken(API_HOST, requestBody);
      };
    
      getAccessTokenAgain = async () => {
        try {
          let finalUrl = "https://auth.atlassian.com/oauth/token";
          let resource = {
            grant_type: "refresh_token",
            client_id: this.state.jiraClientId,
            client_secret: this.state.jiraClientSecret,
            refresh_token: this.state.refreshToken
          };
    
          let headers = {
            "Content-Type": "application/json"
          };
          const data = await axios.post(finalUrl, resource, { headers });
          console.log("data result::: ", data);
          // this.setState({ accessToken: data.data.access_token })
          return data.data.access_token;
          this.getJiraYourTaskList(this.state.cloudId, data.data.access_token);
        } catch (error) {
          toast.error(error);
          this.setState({ isActive: false });
        }
      };
    
      getJiraYourTaskList = async (cloudId, token) => {
        try {
          const finalUrl = `https://api.atlassian.com/ex/jira/${cloudId}/rest/api/2/search?jql=assignee=currentuser()AND status IN ('To Do','In Progress')&maxResults=500&order%20by%20created&fields=assignee,comment,created,creator,description,issuelinks,duedate,issuetype,parent,priority,progress,project,reporter,status,subtasks,summary`;
    
          let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + token
          };
          const { data } = await axios.get(finalUrl, { headers });
          this.setState({
            JiraTaskList: data.issues,
            selectedPageSize: 10,
            selectedPage: 0
          });
        } catch (error) {
          toast.error(error);
        }
        this.setState({ isActive: false, loading: false });
      };
    /********/


    render() {
        var moment = require('moment-timezone');
        const { memberListResponse, isAddToGroup, isEdit, title, titleModal, teamDetails, message, conversationHistory, userLoggedInData, channelWiseSlackUserDetailsResponse, tags, isTag, updatedConversationHistory, isTyping, isOpenAddTaskPopup, isOpenAddChatTaskPopup } = this.state;
       // const { memberListResponse } = this.props;
        console.log("channelWiseSlackUserDetailsResponse : ",channelWiseSlackUserDetailsResponse)
        console.log("Conversation:  ",conversationHistory);

        return (
            <div class="modal chatWindowModal" id="chatWindow" tabindex="-1" role="dialog" aria-labelledby="chatWindow" aria-hidden="true" >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div className="sub-leftbar myscroll">
                                <SlackChatUserList memberListResponse={memberListResponse} channelWiseSlackUserDetailsResponse={this.state.channelWiseSlackUserDetailsResponse} teamId={this.props.teamId} handleAddChat={this.props.handleAddChat} />
                            </div>

                            <div className="chatBox">
                                <div className="chat-area">
                                    <div className="chat-area-header">
                                        <div className="chat-area-title" style={{ cursor: 'pointer' }} onMouseEnter={this.hoverTitle} onMouseLeave={this.hoverTitle}>
                                            <strong>{teamDetails.Name}</strong>{isEdit && <i class="fas fa-edit" style={{ marginLeft: '5px', color: '#adb5bd' }} onClick={() => this.editTitle(teamDetails.Name)}></i>}<br />
                                            <div style={{ fontSize: '13px', fontWeight: 'normal' }}>{isTyping}</div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            {tags.length > 0 && isTag === true && <div className="d-flex" style={{ marginRight: '1%' }}>
                                                <KeywordTag tags={tags} getTags={this.getTags}></KeywordTag>
                                            </div>}
                                            <div class="chat-area-group">
                                                {memberListResponse && memberListResponse.slice(0, 5).map((slackuser) => (
                                                    <AvatarImage profilePic={JSON.parse(slackuser.ProfilePic)} className='chat-area-profile' />
                                                ))}
                                                {memberListResponse && memberListResponse.length > 5 ?
                                                    <span>{`+${memberListResponse.length - 5}`}</span> : ""}

                                            </div>
                                            <div class="chat-area-actions" >
                                            <ReactTooltip id="addUser" place="bottom" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                <div data-for="addUser" data-tip="Add User" className="add-chat-user" onClick={this.addMemberToGroup}><i class="fas fa-user-plus"></i></div>
                                                {/* <div className="chat-info-btn"><i class="fas fa-info"></i></div> */}
                                                {/* <div className="add-chat-user chat-call-btn" ><i class="fas fa-phone-alt"></i></div> */}
                                                <div className="chat-info-btn chat-closed chat-min-btn" onClick={this.minmizePopup} ><i class="fas fa-minus"></i></div>
                                                <div className=" chat-info-btn chat-closed chat-mxn-btn" onClick={this.maximizePopup} ><i class="fas fa-expand"></i></div>
                                                <div className="chat-info-btn chat-closed"
                                                    data-dismiss="modal" onClick={this.closePopup}
                                                    //style={this.props.teamId === 0 ? {display:'none'} : {display:'block'}} 
                                                    aria-label="Close"><i class="fas fa-times"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-area-main myscroll" id={`mainchat${this.props.teamDetails.OtherContent}`}>

                                        {/* chat-start */}
                                        {conversationHistory && conversationHistory.sort((a, b) => a.ts - b.ts).map((convo) => (

                                            convo.user === getDecryptedValue(userLoggedInData.Id_SlackUser) ? (

                                                <div class="chat-msg owner">
                                                    <div class="chat-msg-profile">
                                                        <AvatarImage profilePic={JSON.parse(userLoggedInData.ProfilePic)} className='chat-msg-img' />
                                                        <div class="chat-msg-date"> {moment.unix(convo.ts).format("LLL")}</div>
                                                    </div>
                                                    <div class="chat-msg-content">
                                                        <ContextMenuTrigger id="selecttag" >
                                                            <div class="chat-msg-text">
                                                            <div></div>
                                                            
                                                            <Emoji onContextMenu={this.handleSelect} text={convo.text && convo.text.includes(`<@${convo.user}>`) ? convo.text.replace(`<@${convo.user}>`, userLoggedInData.Real_Name) : convo.text } />
                                                                {convo.files && convo.files.map((file) => (file.url_private !== "" ? <div>{file.url_private}</div> : ""))}
                                                                {/* <ReactTextFormat><div>{checkMessage(convo.text)}</div></ReactTextFormat> */}
                                                            </div>
                                                        </ContextMenuTrigger>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                {channelWiseSlackUserDetailsResponse && channelWiseSlackUserDetailsResponse.map((slackuser) => (
                                                    getDecryptedValue(slackuser.Id_SlackUser) === convo.user ?
                                                <div class="chat-msg">
                                                    {/* <div class="chat-msg-profile"> */}
                                                   
                                                            <div class={`chat-msg-profile ${slackuser.Is_Active === false ? "in-active" : ""}`}>
                                                                <React.Fragment>
                                                                    <AvatarImage profilePic={JSON.parse(slackuser.ProfilePic)} className='chat-msg-img' />
                                                                    <div class="chat-msg-date"><span>{slackuser.Real_Name}</span> | {moment.unix(convo.ts).format("LLL")}</div>
                                                                </React.Fragment>
                                                            </div>
                                                            

                                                    <div class="chat-msg-content">
                                                        <ContextMenuTrigger id="selecttag" >
                                                            <div class="chat-msg-text">
                                                                <div></div>
                                                                <Emoji onContextMenu={this.handleSelect} text={convo.text && convo.text.includes(`<@${convo.user}>`) ? convo.text.replace(`<@${convo.user}>`, slackuser.Real_Name) : convo.text } />
                                                                    {convo.files && convo.files.map((file) => (file.url_private !== "" ? 
                                                                    <div onClick={(e) => this.loadFile(file.url_private)}>
                                                                              <FileViewer
                                                                              fileType={file.filetype}
                                                                              filePath={file.url_private}
                                                                              //="jpg"
                                                                              //filePath="https://cdn.pixabay.com/photo/2013/07/02/22/20/bouquet-142876_960_720.jpg"
                                                                              //errorComponent={CustomErrorComponent}
                                                                              onError={this.onErrorFile}
                                                                              onMouseEnter={() => this.loadFile(file.url_private)}
                                                                              />
                                                                    </div>
                                                                      
                                                                    : ""))}
                                                                {/* <ReactTextFormat><div>{checkMessage(convo.text)}</div></ReactTextFormat> */}
                                                            </div>
                                                        </ContextMenuTrigger>
                                                    </div>
                                                </div>
                                                : ""))
                                                    }
                                                    </>
                                            )

                                        ))}

                                        {/* chat-end */}


                                        {/* updated chat start */}

                                        {updatedConversationHistory && updatedConversationHistory.sort((a, b) => a.ts - b.ts).map((convo) => (

                                            convo.user === getDecryptedValue(userLoggedInData.Id_SlackUser) ? (

                                                <div class="chat-msg owner">
                                                    <div class="chat-msg-profile">
                                                        <AvatarImage profilePic={JSON.parse(userLoggedInData.ProfilePic)} className='chat-msg-img' />
                                                        <div class="chat-msg-date"> {moment.unix(convo.ts).format("LLL")}</div>
                                                    </div>
                                                    <div class="chat-msg-content">
                                                        <ContextMenuTrigger id="selecttag" >
                                                            <div class="chat-msg-text">
                                                                {/* <div onContextMenu={this.handleSelect} dangerouslySetInnerHTML={{ __html: checkMessage(convo.text) }}></div> */}
                                                                <Emoji onContextMenu={this.handleSelect} text={convo.text && convo.text.includes(`<@${convo.user}>`) ? convo.text.replace(`<@${convo.user}>`, userLoggedInData.Real_Name) : convo.text } />
                                                                {/* <ReactTextFormat><div>{checkMessage(convo.text)}</div></ReactTextFormat> */}
                                                            </div>
                                                        </ContextMenuTrigger>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                {channelWiseSlackUserDetailsResponse && channelWiseSlackUserDetailsResponse.map((slackuser) => (
                                                        getDecryptedValue(slackuser.Id_SlackUser) === convo.user ?
                                                <div class="chat-msg">
                                                    {/* <div class="chat-msg-profile"> */}
                                                            <div class={`chat-msg-profile ${slackuser.Is_Active === false ? "in-active" : ""}`}>
                                                                <React.Fragment>
                                                                    <AvatarImage profilePic={JSON.parse(slackuser.ProfilePic)} className='chat-msg-img' />
                                                                    <div class="chat-msg-date"><span>{slackuser.Real_Name}</span> | {moment.unix(convo.ts).format("LLL")}</div>
                                                                </React.Fragment>
                                                            </div>
                                                            

                                                    {/* </div> */}
                                                    <div class="chat-msg-content">
                                                        <ContextMenuTrigger id="selecttag" >
                                                            <div class="chat-msg-text">
                                                                {/* <div onContextMenu={this.handleSelect} dangerouslySetInnerHTML={{ __html: checkMessage(convo.text) }}></div> */}
                                                                <Emoji onContextMenu={this.handleSelect} text={convo.text && convo.text.includes(`<@${convo.user}>`) ? convo.text.replace(`<@${convo.user}>`, slackuser.Real_Name) : convo.text } />
                                                                {/* <ReactTextFormat><div>{checkMessage(convo.text)}</div></ReactTextFormat> */}
                                                            </div>
                                                        </ContextMenuTrigger>
                                                    </div>
                                                </div>
                                                : ""))
                                            }
                                                </>
                                            )

                                        ))}

                                        <ContextMenu id="selecttag">
                                            <MenuItem onClick={() => this.addTag()}>
                                                Add To Tag
                                            </MenuItem>
                                            <MenuItem onClick={() => this.openAddTaskPopup()}>
                                                Add as Jira Task
                                            </MenuItem>
                                            <MenuItem onClick={() => this.openAddChatTaskPopup()}>
                                                Add to Existing Jira Task
                                            </MenuItem>
                                        </ContextMenu>


                                        {/* updated chat end */}


                                        <div class="chat-area-group">
                                            {memberListResponse && memberListResponse.slice(0, 4).map((slackuser) => (
                                                <AvatarImage profilePic={JSON.parse(slackuser.ProfilePic)} className='chat-area-profile' />
                                            ))}
                                            {memberListResponse && memberListResponse.length > 5 ?
                                                <span>{`+${memberListResponse.length - 4}`}</span> : ""}
                                        </div>

                                    </div>
                                    <InputText isGroupChatWindow={this.state.isGroupChatWindow} selectedEmoji={this.state.selectedEmoji} isEmoji={this.state.isEmoji} selectEmoji={this.selectEmoji} openEmoji={this.openEmoji} sendMsg={this.sendMsg} ref={(textarea) => { this.textInput = textarea; }} channelDetails={teamDetails} name="message" message={message} label="Label Sample" defaultValue={this.state.message} />
                                    {/* <InputText sendMsg={this.sendMsg} jiraBacklogIssue={(newValue) => { this.jiraBacklogIssue(newValue) }} ref={(textarea) => { this.textInput = textarea; }} channelDetails={teamDetails} projectName={projectNameList} projectKey={projectKeyList} backlogIssuesTaskList={BacklogIssuesTaskList} name="message" message={message} label="Label Sample" defaultValue={this.state.message} /> */}
                                    {/* <div class="chat-area-footer">
                                        <div className="chat-control-area">
                                            <div className="inout-group">
                                                <textarea onKeyDown={this.onKeyDownHandler} placeholder="Message" rows="1" ref={(textarea) => { this.textInput = textarea; }} value={message} onChange={(e) => this.setState({ message: e.target.value })} id="message" name='message'></textarea>
                                                <div className="chat-emoji"><i class="far fa-smile"></i></div>
                                            </div>
                                            <button className="chat-send-btn"><i class="far fa-paper-plane" onClick={this.sendMsg}></i></button>
                                        </div>
                                    </div> */}
                                </div>



                                {/* <div className="detail-area">
                                                <div className="detail-area-header">
                                                <img className="msg-profile" src="https://images.unsplash.com/photo-1523049673857-eb18f1d7b578?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2168&amp;q=80" />
                                                <div class="detail-title">CodePen Group</div>
                                                <div class="detail-subtitle">Created by Aysenur, 1 May 2020</div>
                                                <div class="detail-photos">
                                                    <div class="detail-photo-title">                                                
                                                        Shared photos
                                                    </div>
                                                    <div class="detail-photo-grid">
                                                        <img src="https://images.unsplash.com/photo-1523049673857-eb18f1d7b578?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2168&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1516085216930-c93a002a8b01?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2250&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1458819714733-e5ab3d536722?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=933&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1520013817300-1f4c1cb245ef?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2287&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1494438639946-1ebd1d20bf85?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2247&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1559181567-c3190ca9959b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1300&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1560393464-5c69a73c5770?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1301&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2249&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1481349518771-20055b2a7b24?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2309&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1473170611423-22489201d919?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2251&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1579613832111-ac7dfcc7723f?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2250&amp;q=80" />
                                                        <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2189&amp;q=80" />
                                                    </div>
                                                    <div class="view-more">View More</div>
                                                </div>
                                                </div>
                                            </div> */}

                            </div>

                        </div>

                    </div>
                </div>
                {titleModal && <EditTitle titleModal={titleModal} title={title} editTitle={this.editTitle} saveTitle={this.saveTitle}></EditTitle>}
                {isAddToGroup && <AddMember isAddToGroup={isAddToGroup} closeAddMemberPopUp={this.closeAddMemberPopUp} teamId={this.props.teamId} teamDetails={this.state.teamDetails} channelWiseSlackUserDetails={this.channelWiseSlackUserDetails} fetchHistory={this.fetchHistory}></AddMember>}
                {/* {isOpenAddTaskPopup && <AddNewTask jiraDomainSelected={this.state.jiraDomainSelected} isOpenAddTaskPopup={isOpenAddTaskPopup} isToDoDescription={isOpenAddTaskPopup} todoDescription={this.state.selection} closeAddTask={this.closeAddTaskPopup} isAddTask={isOpenAddTaskPopup} cloudId={this.state.cloudId} accessToken={this.state.accessToken} lastTaskProject={this.state.JiraTaskList[0]} getJiraYourTaskList={this.getJiraYourTaskList} getAccessTokenAgain={this.getAccessTokenAgain} refreshToken={this.state.refreshToken} />} */}
                {isOpenAddTaskPopup && <AddChatToJiraTask isOpenAddTaskPopup={isOpenAddTaskPopup} isToDoDescription={isOpenAddTaskPopup} todoDescription={this.state.selection} closeAddTask={this.closeAddTaskPopup} isAddTask={isOpenAddTaskPopup} />}
                {isOpenAddChatTaskPopup && <AddChatToExistingJira isOpenAddChatTaskPopup={isOpenAddChatTaskPopup} closeAddChatTaskPopup={this.closeAddChatTaskPopup} addDescription={this.state.selection} cloudId={this.state.cloudId} accessToken={this.state.accessToken} />}
            </div>
        );
    }

}

// Prop types of props.
ChatWindow.propTypes = {
    API_HOST: PropTypes.string,
    slackUserDetails: PropTypes.func,
    slackUserDetailsResponse: PropTypes.any,
    editTitle: propTypes.func,
    channelWiseSlackUserDetails: PropTypes.string,
    channelWiseSlackUserDetailsResponse: PropTypes.any
};

// Set default props.
ChatWindow.defaultProps = {
    addSearchTag: () => { },
    getSearchTags: () => { },
    slackUserDetails: () => { },
    slackUserDetailsResponse: null,
    editTitle: () => { },
    channelWiseSlackUserDetails: () => { },
    channelWiseSlackUserDetailsResponse: []

};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    slackUserDetailsResponse: state.slackUserDetailsReducer.slackUserDetailsResponse,
    channelWiseSlackUserDetailsResponse: state.slackUserDetailsReducer.channelWiseSlackUserDetailsResponse,
    getUserDetailResponse: state.dashboardReducer.getUserDetailResponse.userDetailResponse
})

// export default connect(mapStateToProps, { addSearchTag, getSearchTags, slackUserDetails, channelWiseSlackUserDetails, getUserJiraRefreshToken })(ChatWindow)
export default connect(mapStateToProps, { getUserDetail, editTitle, addSearchTag, getSearchTags, slackUserDetails, channelWiseSlackUserDetails, getJiraDomain, getUserJiraRefreshToken, getJiraConfig, saveUserToDoFromJiraTask, updateUserJiraRefreshToken, getJiraTaskLogDetails })(ChatWindow)
