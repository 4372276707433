import { types as T } from '../actions/action';

const initialState = {
    tenantLogResponse: [],
    meetingLogResponse:[],
    meetingLogDetailResponse:[],
    feedbackLogResponse:[],
    contactUsLogResponse:[]
}

export default (state = initialState, action) => {
    switch (action.type) {
     case T.GET_TENANT_LOG:
      return {
        ...state,
        tenantLogResponse: action.tenantLogResponse
      }
      case T.GET_MEETING_LOG:
      return {
        ...state,
        meetingLogResponse: action.meetingLogResponse
      }
      case T.GET_MEETING_LOG_DETAIL:
      return {
        ...state,
        meetingLogDetailResponse: action.meetingLogDetailResponse
      }
      case T.GET_FEEDBACK_LOG:
      return {
        ...state,
        feedbackLogResponse: action.feedbackLogResponse
      }  
      case T.GET_CONTACTUS_LOG:
      return {
        ...state,
        contactUsLogResponse: action.contactUsLogResponse
      } 
      case T.GET_JIRA_LOG:
      return {
        ...state,
        jiraLogResponse: action.jiraLogResponse
      }
     default:
      return state
    }
}