import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from "../../../utils/apiUrls";
import $ from 'jquery';
import Avatar from 'avataaars';




class SupportChat extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMinimize: false,
            message: '',
            messageList: [],
            conversationHistory: [],
            teamId: this.props.teamId,
            channelId: "",
            deltaPosition: {
                x: 0, y: 0
            },
        };
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.userDetails !== this.props.userDetails) {  
        //     this.fetchChannel(this.props.userDetails.Id_SlackUser)           
        // }


    }



    closePopup = () => {
        // $(`.support-chat`).removeClass('show').css('display', 'none');
        $(`.support-chat`).css('display', 'none');
        this.props.closeSupportChat();
    }

    onMinimize = () => {
        $(`.support-chat`).toggleClass('onminitoggle');
    }



    handleDrag = (e, ui) => {
        e.preventDefault();
        e.stopPropagation();

        const { x, y } = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        });
        console.log("Drag..!!");
    };



    // handleDrag = (e, ui) => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     const { x, y } = this.state.deltaPosition;
    //     this.setState({
    //         deltaPosition: {
    //             x: x + ui.deltaX,
    //             y: y + ui.deltaY,
    //         }
    //     });
        // if (x === 200) {
        //     this.setState({
        //         deltaPosition: {
        //             x: 0,
        //             y: 0,
        //         }
        //     })
        // }
        // if (x === -200) {
        //     this.setState({
        //         deltaPosition: {
        //             x: 0,
        //             y: 0,
        //         }
        //     })
        // }

    //     console.log("Drag..!!");
    // };


    //Send message on press of Enter Key
    // onKeyDownHandler = e => {
       
    //     if (e.key === 'Enter') {
    //         e.preventDefault();
    //         this.sendMsg();
    //     }
    // };


    render() {

        return (

            <Draggable
                //bounds={{ top: -200, left: -200, right: 200, bottom: 200 }}
                bounds="parent"
                onDrag={this.handleDrag}
                //onStart={this.handleDrag}
                //onStop={this.handleDragOver}
                handle=".handle"
                defaultPosition={{ x: 0, y: 0 }}
                position={null}
                //disabled={false}
            >
        {/* <div> */}
                    <div className="modal chatWindowModal minimizeChat support-chat handle" style={{ cursor: 'move' }} id="chatWindow" tabindex="0" role="dialog" aria-labelledby="chatWindow" aria-hidden="true" >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="chatBox">
                                        <div className="chat-area">
                                            <div className="chat-area-header">
                                                <div className="chat-area-title">
                                                    <strong>Live Support</strong>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="chat-area-actions">
                                                        <div className="chat-info-btn one-chat-min" onClick={this.onMinimize}><i class="fas fa-chevron-down"></i></div>
                                                        <div className="chat-info-btn chat-closed" data-dismiss="modal" onClick={this.closePopup} label="Close"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="chat-area-main myscroll" id="mainchat">

                                                <div className="chat-msg">
                                                    <div className="chat-msg-profile">
                                                        <img className="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%2812%29.png" alt="" />
                                                        <div className="chat-msg-date"><span>Jayesh Watson</span> | 3.16pm</div>
                                                    </div>
                                                    <div className="chat-msg-content">
                                                        <div className="chat-msg-text">How can i help you ?</div>
                                                    </div>
                                                </div>
                                                <div className="chat-msg">
                                                    <div className="chat-msg-profile">
                                                        <img className="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%283%29+%281%29.png" alt="" />
                                                        <div className="chat-msg-date"><span>Jayesh Watson</span> | 3.16pm</div>
                                                    </div>
                                                    <div className="chat-msg-content">
                                                        <div className="chat-msg-text">Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et.</div>
                                                    </div>
                                                </div>
                                                <div className="chat-msg-unread"></div>
                                                <div className="chat-msg owner">
                                                    <div className="chat-msg-profile">
                                                        <img className="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png" alt="" />
                                                        <div className="chat-msg-date">2.50pm</div>
                                                    </div>
                                                    <div className="chat-msg-content">
                                                        <div className="chat-msg-text">Tincidunt arcu non sodales😂</div>
                                                    </div>
                                                </div>
                                                <div className="chat-msg">
                                                    <div className="chat-msg-profile">
                                                        <img className="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%282%29.png" alt="" />
                                                        <div className="chat-msg-date"><span>Jayesh Watson</span> | 3.16pm</div>
                                                    </div>
                                                    <div className="chat-msg-content">
                                                        <div className="chat-msg-text">Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et🥰</div>

                                                        <div className="text typing">
                                                            <div className="wave">
                                                                <span className="dot"></span>
                                                                <span className="dot"></span>
                                                                <span className="dot"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="chat-area-group">
                                                    <img className="chat-area-profile" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%283%29+%281%29.png" alt="" />
                                                    <img className="chat-area-profile" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%282%29.png" alt="" />
                                                    <img className="chat-area-profile" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%2812%29.png" alt="" />
                                                    <span>+4</span>
                                                </div>

                                            </div>

                                            <div className="chat-area-footer">
                                                <div className="chat-control-area">
                                                    <div className="inout-group">
                                                        <textarea onKeyDown={this.onKeyDownHandler} autoFocus placeholder="Message" rows="1" value="" onChange="" id="message" name='message'></textarea>
                                                        <div className="chat-emoji"><i class="far fa-smile"></i></div>
                                                    </div>
                                                    <button className="chat-send-btn" disabled={this.state.message === ""} onClick={this.sendMsg}><i class="far fa-paper-plane"></i></button>
                                                </div>
                                            </div>

                                        </div>



                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
        {/* </div> */}

            </Draggable>

        );
    }

}

// Prop types of props.
SupportChat.propTypes = {
    API_HOST: PropTypes.string,
    memberListResponse: PropTypes.any

};

// Set default props.
SupportChat.defaultProps = {
    memberListResponse: null,
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),

})

export default connect(mapStateToProps, {})(SupportChat)