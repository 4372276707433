import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { TabContent, TabPane, NavLink } from 'reactstrap';
import classnames from 'classnames';
import ChangePassword from '../../resetPassword/component/ChangePassword';
import { connect } from 'react-redux';
import { getApiHost, socket, slack_socket } from "../../../utils/apiUrls";
import { virtualRoomVisibility, getVirtualRoom } from "../../../actions/mainOfficeActions";

class Account extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      error: {},
      userDetails: {},
      src: "",
      activeTab:'1',
      showVisibility: JSON.parse(localStorage.getItem("userdetail")).Is_Meeting_Visible,
    }
  }


  async componentDidMount(){
    //await this.getVirtualRoom();
    //   socket.on("virtualRoomRes", data => {
    //     console.log("174 Virtual");
    //     this.getVirtualRoom();
    //     this.setState({ virtualRoomResponse: data });
        
    // });
     //console.log("175: ",this.props.virtualRoomResponse);
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  changeVisibility =async(event) => {
    const checkVisibility = event.target.checked;
    this.setState({ showVisibility: checkVisibility });
    await this.virtualRoomVisibility(checkVisibility);

  }

  virtualRoomVisibility = async(showVisibility) => {
    const { API_HOST, virtualRoomVisibility } = this.props;

              let userDetails = JSON.parse(localStorage.getItem("userdetail"));
              console.log("111 ",userDetails);
              userDetails.Is_Meeting_Visible = showVisibility;
              localStorage.setItem("userdetail", JSON.stringify(userDetails));
              // this.props.userValuesChanges(userDetails);

       await virtualRoomVisibility(API_HOST, showVisibility, res => {
         if (res) {
            this.getVirtualRoom();
         }
       });
  }

  // getVirtualRoom = async () => {
  //   const { API_HOST, getVirtualRoom } = this.props;
  //   await getVirtualRoom(API_HOST, res => {
  //     if (res) {
  //       //socket.emit("virtualRoomRes", res);
  //       //this.setState({ virtualRoomResponse : res })
  //     }
  //   });
  // };

    //Get Virtual Rooms
    getVirtualRoom = async () => {
      const { API_HOST, getVirtualRoom } = this.props;
      await getVirtualRoom(API_HOST);
  };
  render() {
    return (
      <div className="main-tab main-vertical-tab">
        <div className="d-flex">
          <div className="col-2 col-md-3 px-0">
            <div className="nav flex-column nav-pills pt-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>General Settings</NavLink>
              <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>Reset Password </NavLink>
              
                  {/* <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>Accessories </NavLink>
                    
                  <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>Hair Color </NavLink>
                   
                  <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>Facial Hair </NavLink>
                   <NavLink className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>Facial Hair Color  </span> </NavLink>
                     <NavLink className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggle('7'); }}>Clothes </span></NavLink>
               <NavLink className={classnames({ active: this.state.activeTab === '8' })} onClick={() => { this.toggle('8'); }}>Fabric Color </NavLink>
               <NavLink className={classnames({ active: this.state.activeTab === '9' })} onClick={() => { this.toggle('9'); }}>Graphic </NavLink>
               
              <NavLink className={classnames({ active: this.state.activeTab === '10' })} onClick={() => { this.toggle('10'); }}>Eye </NavLink>
              <NavLink className={classnames({ active: this.state.activeTab === '11' })} onClick={() => { this.toggle('11'); }}>Eyebrow </NavLink>
              <NavLink className={classnames({ active: this.state.activeTab === '12' })} onClick={() => { this.toggle('12'); }}>Mouth </NavLink>
              <NavLink className={classnames({ active: this.state.activeTab === '13' })} onClick={() => { this.toggle('13'); }}>Skin Color </NavLink> */}
            </div>
          </div>
          <div className="col-10 col-md-9 px-0">
            <TabContent activeTab={this.state.activeTab} className="tab-content card" id="v-pills-tabContent">
            <TabPane tabId="1" className="tab-pane" id="v-pills-tab-con8" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                {/* <div style={{fontStyle: "oblique", fontize: "16px", wordWrap: "break-word"}}> Your Email Address : <span style={{fontWeight: "800"}}>{JSON.parse(localStorage.getItem('userdetail')).EmailId}</span> 
                </div> */}
              <form>
              <label> Show my Visibility in Virtual Room: &nbsp; &nbsp; </label>
                <input type="checkbox" name="visible" id="visible" value={this.state.showVisibility} checked={this.state.showVisibility} onChange={this.changeVisibility} style={{ cursor: "pointer" }} />
              </form>


              </TabPane>
              <TabPane tabId="2"  id="v-pills-tab-con1" role="tabpanel" aria-labelledby="v-pills-home-tab">
                           <ChangePassword changePassword={this.props.changePassword} changePasswordResponse={this.props.changePasswordResponse}/>
                              </TabPane>
              <TabPane tabId="3" >
                <div className="d-flex avtar-tabpan">
                 
                </div>
              </TabPane>
              <TabPane tabId="4" className="tab-pane" id="v-pills-tab-con3" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div className="d-flex avtar-tabpan">
                
                </div>
              </TabPane>
              <TabPane tabId="5" className="tab-pane" id="v-pills-tab-con4" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div className="d-flex avtar-tabpan">
                  
                </div>
              </TabPane>
              <TabPane tabId="6" className="tab-pane" id="v-pills-tab-con5" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="d-flex avtar-tabpan">
                  
                </div>
              </TabPane>
              <TabPane tabId="7" className="tab-pane" id="v-pills-tab-con5" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="d-flex avtar-tabpan">
                  
                </div>
              </TabPane>
              <TabPane tabId="8" className="tab-pane" id="v-pills-tab-con6" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div className="d-flex avtar-tabpan">
                 
                </div>
              </TabPane>
              <TabPane tabId="9" className="tab-pane" id="v-pills-tab-con7" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div className="d-flex avtar-tabpan">
                 
                </div>
              </TabPane>
              <TabPane tabId="10" className="tab-pane" id="v-pills-tab-con7" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div className="d-flex avtar-tabpan">
                 
                </div>
              </TabPane>
              <TabPane tabId="11" className="tab-pane" id="v-pills-tab-con8" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="d-flex avtar-tabpan">
                  
                </div>
              </TabPane>
              <TabPane tabId="12" className="tab-pane" id="v-pills-tab-con9" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div className="d-flex avtar-tabpan">
                  
                </div>
              </TabPane>
              <TabPane tabId="13" className="tab-pane" id="v-pills-tab-con10" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div className="d-flex avtar-tabpan">
                  
                </div>
              </TabPane>
              
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}

Account.defaultProps = {
  virtualRoomResponse: null,
};

Account.propTypes = {    
    changePassword:PropTypes.func,
    changePasswordResponse:PropTypes.bool,
    virtualRoomResponse: PropTypes.any
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  virtualRoomResponse: state.virtualRoomReducer.virtualRoomResponse
})

export default connect(mapStateToProps, { virtualRoomVisibility, getVirtualRoom })(Account)