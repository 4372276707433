import { types as T } from '../actions/action';

const initialState = {
    getUserAvailabilityListResponse: []
}

export default (state = initialState, action) => {
    switch (action.type) {
     case T.RECEIVE_USER_AVAILABILITY_LIST:
      return {
        ...state,
        getUserAvailabilityListResponse: action.getUserAvailabilityListResponse
      }   
     default:
      return state
    }
}