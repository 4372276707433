import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getApiHost } from '../../../utils/apiUrls';
import JiraSoftwareIcon from '../../../assets/images/JiraSoftwareIcon.png';
import { getJiraConfig, getJiraDomain } from '../../../actions/jiraActions';
import { getPrivateJiraConfigList } from '../../../actions/privateJiraActions';
import LoadingOverlay from 'react-loading-overlay';
import { getDecryptedValue, getEncryptedValue } from '../../../utils/utility';
//import Select from 'react-select';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

class JiraConfiguration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loading: true,
      fields: {},
      isJiraAllowed: false,
      jiraDomainList: {},
      jiraDomainSelected: {},
      selectedDomain: '',
      isPublic: true,
      isPrivate: false,
      privateJiraConfigList: [],
      selectedPrivateJiraUrl: '',
      jiraDomainId: 0
    };
  }

  componentDidMount() {
    let fields = this.state.fields;
    fields = {
      id: '',
      clientid: '',
      clientsecret: '',
      scope: '',
    };
    this.setState({ fields });
    this.getJIRAConfig();
    this.getJiraDomain();
  }

  getJIRAConfig = async () => {
    const { API_HOST, getJiraConfig } = this.props;
    await getJiraConfig(API_HOST, (res) => {
      console.log('3get res', res);
      if (res[0]) {
        let fields = this.state.fields;
        fields = {
          id: res[0].Id,
          clientid: getDecryptedValue(res[0].Client_ID),
          clientsecret: getDecryptedValue(res[0].Client_Secret),
          scope: res[0].Scope,
        };
        this.setState({ fields: fields, loading: false });
      }
    });
  };

  getJiraDomain = async () => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, (res) => {
      if (res[0]) {
        this.setState({
          isJiraAllowed: true,
          jiraDomainList: res,
          selectedDomain: res[0].CloudId,
          selectedDomainName: res[0].Domain_Name,
          jiraDomainSelected: res[0],
        });
      }
    });
  };

  handleChangeDomain = async (data) => {
    console.log('Data traget: ', data.target.value);
    data.preventDefault();
    if (data.target.value === '0') {
      window.location.href = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${this.state.fields['clientid']}&scope=${this.state.fields['scope']}&redirect_uri=${window.location.protocol}//${window.location.host}/jiratoken&state=12345&response_type=code&prompt=consent`;
    }
    // else{

    //     await this.setState({
    //         selectedDomain: data.target.value,
    //         // selectedDomain:data.target.name
    //        });

    //     window.location.href = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${this.state.fields["clientid"]}&scope=${this.state.fields["scope"]}&redirect_uri=${window.location.protocol}//${window.location.host}/jiratoken&state=12345&response_type=code&prompt=consent`;

    // }
  };

  getPrivateJiraConfigList = () => {
    const { API_HOST, getPrivateJiraConfigList } = this.props;
    getPrivateJiraConfigList(API_HOST, (res) => {
      if (res) {
        this.setState({
          privateJiraConfigList: res,
          selectedPrivateJiraUrl: res[0].Consumer_URL,
          jiraDomainId: res[0].Id
        });
      }
    });
  };

  changeIsPublic = () => {
    this.setState({ isPublic: true, isPrivate: false });
  };

  changeIsPrivate = () => {
    this.setState({ isPrivate: true, isPublic: false });
    this.getPrivateJiraConfigList();
  };

  handleChangePrivateJiraUrl = async (data) => {
    console.log('Data traget2: ', data.target.value);
    data.preventDefault();
    this.redirectToJira(this.state.jiraDomainId);
  };

  redirectToJira = (jiraDomainId) => {
    console.log(' redirectToJira called  ' + process.env.REACT_APP_JIRA_URL);

    const message = [];
    const jira_DomainId = getEncryptedValue(jiraDomainId);

    //`${process.env.REACT_APP_JIRA_URL}/sessions/connect`
    // fetch("https://server3.heliosdemo.com:8008/sessions/connect")  //commit with this values.
    // fetch(`http://localhost:9004/sessions/connect?data=${jira_DomainId}`)  //when running in local.
    try {
      fetch(`https://server3.heliosdemo.com:8008/sessions/connect?data=${jira_DomainId}`)
        .then((response) => response.json())

        .then((data) => {
          window.open(data.message, '_self');
        });
    } catch (err) {
      toast.error(err);
    }

    return <div className="App">{message ? message : 'Loading..'}</div>;
  };

  render() {
    return (
      <React.Fragment>
        <center style={{ marginTop: '50px' }}>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioInline1"
              name="customRadioInline1"
              class="custom-control-input"
              value="option1"
              checked={this.state.isPublic}
              onClick={this.changeIsPublic}
            />
            <label class="custom-control-label" for="customRadioInline1">
              Public Jira
            </label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioInline2"
              name="customRadioInline2"
              class="custom-control-input"
              value="option2"
              checked={this.state.isPrivate}
              onClick={this.changeIsPrivate}
            />
            <label class="custom-control-label" for="customRadioInline2">
              In House Jira
            </label>
          </div>
        </center>

        {this.state.isPublic && (
          <LoadingOverlay active={this.state.loading} spinner text="Loading...">
            <div style={{ marginTop: '30px' }}>
              <center>
                {this.state.fields['clientid'] !== '' ? (
                  <>
                    {this.state.isJiraAllowed === false ? (
                      <>
                        <h1>Welcome!</h1>
                        <h4>You can connect to your Jira Account</h4>
                        {/* <a target="_self" href={`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=PuGIc1NvS7mbD7G7biq7Qrv4GyplACkG&scope=offline_access%20read%3Aconfluence-content.summary%20read%3Aconfluence-space.summary%20write%3Aconfluence-file%20write%3Aconfluence-content%20write%3Aconfluence-space%20read%3Aconfluence-props%20write%3Aconfluence-props%20manage%3Aconfluence-configuration%20read%3Aconfluence-content.all%20search%3Aconfluence%20read%3Aconfluence-groups%20write%3Aconfluence-groups%20read%3Aconfluence-user&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fjiratoken&state=12345&response_type=code&prompt=consent`}> */}
                        <div>Connect to</div>
                        {/* <a target="_self" href={`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${this.state.fields["clientid"]}&scope=offline_access%20${this.state.fields["scope"]}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fjiratoken&state=12345&response_type=code&prompt=consent`}> */}
                        <a
                          target="_self"
                          href={`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${this.state.fields['clientid']}&scope=${this.state.fields['scope']}&redirect_uri=${window.location.protocol}//${window.location.host}/jiratoken&state=12345&response_type=code&prompt=consent`}
                        >
                          <img
                            alt="jira software"
                            height="auto"
                            width="auto"
                            src={JiraSoftwareIcon}
                          />
                        </a>
                      </>
                    ) : (
                      // <FormControl variant="outlined" className="formControl switch_control">
                      // <InputLabel id="demo-simple-select-outlined-label">Jira Domain</InputLabel>
                      <>
                        <div>
                          You have already been signed into this account
                        </div>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.selectedDomain}
                          name={this.state.selectedDomainName}
                          onChange={this.handleChangeDomain}
                          label="Jira Domain"
                        >
                          <MenuItem value="null" disabled>
                            <em>Select Jira Domain</em>
                          </MenuItem>

                          {this.state.jiraDomainList &&
                            this.state.jiraDomainList.map((domain) => {
                              return (
                                <MenuItem
                                  key={domain.CloudId}
                                  value={domain.CloudId}
                                >
                                  {domain.Domain_Name}
                                </MenuItem>
                              );
                            })}

                          <MenuItem value="0" key="0">
                            <em>Select another JIRA Domain Name</em>
                          </MenuItem>
                        </Select>
                        {/* </FormControl> */}
                      </>
                    )}
                  </>
                ) : (
                  <h1>
                    {JSON.parse(localStorage.getItem('userdetail')).Is_Admin
                      ? 'You have to Add Jira client configuration first'
                      : 'Your workspace is not connected with JIRA client.Please inform your admin to connect with JIRA'}
                  </h1>
                )}
              </center>
            </div>
          </LoadingOverlay>
        )}

        {this.state.isPrivate && (
          <div style={{ marginTop: '50px' }}>
            <center>
              <div>Select private Jira url: </div>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                // value={this.state.selectedPrivateJiraUrl}
                name={this.state.selectedPrivateJiraUrl}
                onChange={this.handleChangePrivateJiraUrl}
                label="Private Jira URL"
                style={{ width: '180px' }}
              >
                <MenuItem value="null" disabled>
                  <em>Select Jira URL</em>
                </MenuItem>

                {this.state.privateJiraConfigList &&
                  this.state.privateJiraConfigList.map((privateJira) => {
                    return (
                      <MenuItem
                        key={privateJira.Id}
                        value={privateJira.Consumer_URL}
                      >
                        {privateJira.Consumer_URL}
                      </MenuItem>
                    );
                  })}
              </Select>
            </center>
          </div>
        )}
      </React.Fragment>
    );
  }
}

// Prop types of props.
JiraConfiguration.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
JiraConfiguration.defaultProps = {};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
});

export default connect(mapStateToProps, {
  getJiraConfig,
  getJiraDomain,
  getPrivateJiraConfigList,
})(JiraConfiguration);
