import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Row, Col } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { saveReminder } from "../../../actions/todoActions";
import { connect } from 'react-redux';
import { getApiHost } from "../../../utils/apiUrls";
import PropTypes from "prop-types";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

class Reminder extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            dueDate: new Date(),
            checked: false,
            currentObject: {
                isPriority: { value: 1, label: "High" },
                isMin: { value: 0, label: "Never" }
            },
        };
    }

    saveReminder = (e) => {
        e.preventDefault();
        //this.props.setToDoReminder(true, this.props.taskId);
        const { API_HOST, saveReminder } = this.props;
        const reminderRequest = {
            "Id": this.props.taskId,
            "DueDate": moment(this.state.dueDate).format("YYYY-MM-DDTHH:mm"),
            "Reminder_Status": this.state.currentObject.isPriority.value,
            "Is_Reminder": true,
            "Snooze": this.state.currentObject.isMin.value
        };
        console.log("date format", this.state.dueDate)
        saveReminder(API_HOST, reminderRequest, (res) => {
            this.props.userToDoList();
            this.props.closeReminderPopUp();
        });
    }

    handleChange(value) {
        this.setState({
            currentObject: {
                ...this.state.currentObject,
                isPriority: value
            }
        });
    }

    handleSnooze(value) {
        this.setState({
            currentObject: {
                ...this.state.currentObject,
                isMin: value
            }
        })
    }

    setSnooze = (checked) => {
        this.setState({
            checked: checked,
            currentObject: {
                ...this.state.currentObject,
                isMin: { value: 5, label: '5 minutes' }
            }
        })
    }

    render() {
        const { dueDate, checked } = this.state;
        const priorityOptions = [{
            value: 1,
            label: 'High'
        }, {
            value: 2,
            label: 'Medium'
        }, {
            value: 3,
            label: 'Low'
        }]
        const snoozeOptions = [
            {
                value: 5,
                label: '5 minutes'
            }, {
                value: 10,
                label: '10 minutes'
            }, {
                value: 15,
                label: '15 minutes'
            }, {
                value: 30,
                label: '30 minutes'
            }]
        return (
            <Modal isOpen={this.props.isReminder} backdrop={true} size={'md'} style={{ marginTop: '10%' }}>
                <ModalHeader toggle={this.props.closeReminderPopUp} className="dept-header">
                    Set Reminder
                </ModalHeader>
                <form method="post" id="reminder" onSubmit={this.saveReminder}>
                    <ModalBody>
                        <label>Due Date</label>
                        <FormGroup>
                            <DatePicker
                                selected={dueDate}
                                onChange={date => this.setState({ dueDate: date })}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={1}
                                timeCaption="time"
                                dateFormat="yyyy-MM-d HH:mm aa"
                                className="form-control"
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Priority</label>
                            <Select
                                components={makeAnimated}
                                onChange={newValue => this.handleChange(newValue)}
                                options={priorityOptions}
                                value={this.state.currentObject.isPriority}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <span style={{ marginRight: '10px' }}>Snooze</span>
                                    <span style={{ position: 'absolute' }}>
                                        <Switch onChange={(checked) => this.setSnooze(checked)}
                                            checked={checked}
                                            height={18}
                                            width={40}
                                        />
                                    </span>
                                </Col>
                            </Row>
                        </FormGroup>
                        {checked === true && <FormGroup>
                            <Select
                                components={makeAnimated}
                                onChange={newValue => this.handleSnooze(newValue)}
                                options={snoozeOptions}
                                value={this.state.currentObject.isMin}
                            />
                        </FormGroup>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Set Reminder</Button>
                        <Button color="secondary" onClick={this.props.closeReminderPopUp}>Cancel</Button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}
// Prop types of props.
Reminder.propTypes = {
    API_HOST: PropTypes.string,
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
})

export default connect(mapStateToProps, { saveReminder })(Reminder)
