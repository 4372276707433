//import React from 'react';
import { toast } from "react-toastify";
import moment from "moment";
import { createBrowserHistory } from 'history';

export default createBrowserHistory();
// Get token from local storage.
export const getToken = () => {
  try {
    const userToken = sessionStorage.getItem("userToken");
    //console.log("token",userToken);
    return {
      authorizationHeader: userToken,
    };
  } catch (e) {
    console.error(
      `Could not get Token from localStorage for authorization: ${e}`
    );
  }
};

// Get configuration to set headers.
export const getConfig = () => {
  return {
    method: null,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken().authorizationHeader || null,
    },
  };
};

// Handled response after fetching APIS and throws error while failure.
export const handleResponse = (response) => {
  if (
    response.status === 400 ||
    response.status === 403 ||
    response.status === 404 ||
    response.status === 500
  ) {
    return new Error(response);
  } else if (response.status >= 200 && response.status <= 299) {
    return response.data;
  }
};

// Check and handled client side status which comes from API.
export const checkClientErrorStatus = (response) => {
  if (
    response.statusCode === 401 ||
    response.statusCode === 403 ||
    response.statusCode === 500 ||
    response.statusCode === 400 
    //||
  //  response.statusCode === 502
  ) {
    return true;
  }
  return false;
};


export const getEncryptedValue = (password) => {
  var CryptoJS = require("crypto-js");
  var key = CryptoJS.enc.Utf8.parse('8080808080808080');
  var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
  //  var password = password;
  var encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  return CryptoJS.enc.Base64.stringify(encryptedpassword.ciphertext);
};

export const getDecryptedValue = (value) => {
   var CryptoJS = require("crypto-js");
  var key = CryptoJS.enc.Utf8.parse('8080808080808080');
  var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
  
  var decryptedValue = CryptoJS.AES.decrypt(value, key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
   
  return decryptedValue.toString(CryptoJS.enc.Utf8);
 
};


// Check and handled client side status which comes from API.
export const checkClientErrorMessage = (response) => {
  if (response.IsError === true) {
    return true;
  }
  return false;
};

// Handled and display error while failure. if unauthorized, navigate to login.
export const handleError = (error, messageString) => {
  let message;
  
  const start = `Error! ${messageString}`;
  if (error.SuccessData && error.SuccessData.ErrorMessage) {
    message = `${start}: ${error.SuccessData.ErrorMessage}`;

    //handle error and info messages
    error.SuccessData && error.SuccessData.Key === "info" ? toast.info(message) : toast.error(message);

  } else {
    message = error && error.response ? `${start}: ${error.response.data.Errors[0].Message}`: `${start}`;

    //handle error and info messages
     error && error.response && error.response.data.Errors[0].Key === "info" ? toast.info(error.response.data.Errors[0].Message) : toast.error(message);


        if (error && error.response && error.response.data.Errors[0].Message) {

          if(error.response.data.Errors[0].Message === "Token is expired. You are not authorize to perform this action." || error.response.data.Errors[0].Message === "Requested operation is not allowed"){
            sessionStorage.removeItem("userToken");
            localStorage.removeItem("userdetail");
            localStorage.clear();
          
            const history = createBrowserHistory({forceRefresh:true});
            history.push('/login',  true );
          }
          
        }
        
  }

  //console.error(message);
  //toast.error(message);
};

// To Check Number
export const onlyNumber = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const re = /^[0-9\b]+$/;
  if (!re.test(keyValue)) {
    e.preventDefault();
  }
}

export const onlyAlphabet = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const re = /^[a-zA-Z]*$/g;
  if (!re.test(keyValue)) {
    e.preventDefault();
  }
}

export const editChatGroupTitle = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
 // const re = /^\S*$/; //no space allowed
 const re = /^[a-z0-9._-]*$/;
  if (!re.test(keyValue)) {
    e.preventDefault();
  }
}

// To Validate Email Pattern
export const validateEmail = (inputText) => {
  // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

// Number of length string get
export const getName = (str, num) => {
  var res = str.substring(0, num);
  return res;
}

export const getTag = (str, num) => {
  var res = str.substring(0, num);
  return res.toLowerCase();
}

export const duration = (sTime, eTime) => {
  var startTime = moment(sTime, "HH:mm:ss a");
  var endTime = moment(eTime, "HH:mm:ss a");
  // calculate total duration
  var totalDuration = moment.duration(endTime.diff(startTime));
  // duration in hours
  var hoursDuration = parseInt(totalDuration.asHours());
  // duration in minutes
  var minutesDuration = parseInt(totalDuration.asMinutes()) % 60;
  let duration = hoursDuration * 60 + Number(minutesDuration);
  return duration;
}

export const getStartTimeFormat = (startTime, sDate) => {
  let year = new Date(startTime).getFullYear().toString();
  let date = new Date(sDate).getDate().toString();
  let month = new Date(startTime).getMonth() + 1;
  let hours = new Date(startTime).getHours().toString();
  let minutes = new Date(startTime).getMinutes().toString();
  let seconds = new Date(startTime).getSeconds().toString();
  let startDate = year + '-' + month + '-' + date + 'T' + hours + ':' + minutes + ':' + seconds;
  return startDate;
}

export const endDate = () => {
  let y = new Date().getFullYear();
  let m = new Date().getMonth() + 1;
  var lastday = new Date(y, m, 0).getDate();
  let endDate = y + '-' + m + '-' + lastday;
  return endDate;
}

export const convertLocalTime = (serverdate, offset) => {
  var date = new Date(serverdate);
  // convert to utc time
  var toutc = date.toUTCString();
  //convert to local time
  var locdat = new Date(toutc + " UTC");
  locdat.setMinutes(locdat.getMinutes() + offset);

  return locdat;
}

export const zoomUrlSplit = (url) => {
  let temp = '';
  let metNum = '';
  if (url.includes('http://')) {
    metNum = url.split('http://')[1];
    metNum = metNum.split('/')[1]
    temp = metNum.split('/')[0]
    return temp;
  } else {
    metNum = url.split('https://')[1];
    metNum = metNum.split('/')[1]
    temp = metNum.split('/')[0]
    return temp;
  }
}

export const dateDiff = (sDate, eDate) => {
  let sDay = moment(sDate, 'YYYY-MM-DD');
  let eDay = moment(eDate, 'YYYY-MM-DD');
  let diff = eDay.diff(sDay, "days");
  return diff;
}

export const checkMessage = (msg) => {
  let temp = '';
  if (msg.includes('<mailto:')) {
    temp = msg.split('<mailto:')[1];
    temp = temp.split('|')[0];
  } else if (msg.includes('<https')) {
    temp = msg.split('<')[1];
    temp = temp.split('>')[0];
    return temp;
  } else if (msg.includes('<http')) {
    temp = msg.split('<')[1];
    temp = temp.split('>')[0];
    return temp;
  } 
  // else if (msg.includes('@')) {
  //  // temp = msg.split('@')[1];

  //             // const markup={ __html: temp}
  //             //   return(
  //             // <React.Fragment><div dangerouslySetInnerHTML={markup}></div></React.Fragment>)

  //             temp = msg;
  //             return(
  //               //  <React.Fragment> <a href={`#${temp}`}>{temp}</a> </React.Fragment>
  //               <React.Fragment><a href={`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=PuGIc1NvS7mbD7G7biq7Qrv4GyplACkG&scope=offline_access%20read%3Ajira-user%20write%3Ajira-work%20read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20manage%3Ajira-data-provider&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fjiratoken&state=12345&response_type=code&prompt=consent`}>{temp}</a></React.Fragment>
  //             )
  // //   return temp;
  // } 
  else {
    temp = msg
  }
  return temp;
}

export const makeIdArray = (userAaary) => {
  let idArray = [];
  for (let i = 0; i < userAaary.length; i++) {
    idArray.push(userAaary[i].Id_User)
  }
  return idArray;
}