import React from 'react';
import { connect } from 'react-redux';
import { 
	fetchMultipleEmail,
	fetchMultipleWorkspace
} from '../../../actions/multipleEmailActions';
import { getApiHost } from '../../../utils/apiUrls';
import { 
	multipleEmailResponse,
	multipleWorkspaceResponse
} from '../selector';


const HOC = WrappedComponent => {
	const Decorator = props => <WrappedComponent {...props} />;

	const mapStateToProps = state => ({
		API_HOST: getApiHost(),

		multipleEmailLoading: multipleEmailResponse(state).loading,
		multipleEmailResponse: multipleEmailResponse(state).multipleEmailResponse,
		
		multipleWorkspaceLoading: multipleWorkspaceResponse(state).loading,
		multipleWorkspaceResponse: multipleWorkspaceResponse(state).multipleWorkspaceResponse,
	});

	const mapDispatchToProps = {
		fetchMultipleEmail,
		fetchMultipleWorkspace
	};

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(Decorator);
};

export default HOC;
