import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  unreadSentStickyList,
  sendUnReadSentStickyListAsMail,
} from "../../../actions/unreadSentStickyActions";
import { getApiHost } from "../../../utils/apiUrls";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import moment from "moment";
import { toast } from "react-toastify";
import { setReadUserSticky } from "../../../actions/dashboardActions";

class UnreadSentStickyList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      onInfoSelected: false,
      data: [], //sticky data
      sticky_Id: 0,
      selected: {},
      selectedSticky: [],
      selectedMessage: "",
      isScheduleMeeting: false,
    };
  }
  async componentDidMount() {
    var userData = localStorage.getItem("userdetail");
    var senderId = JSON.parse(userData).Id_User;
    await this.unreadSentStickyList(senderId);
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (
      nextProps.unreadSentStickyListResponse !==
        prevProps.unreadSentStickyListResponse 
        //&&
     // nextProps.unreadSentStickyListResponse[0] !== null
    ) {
      this.setState({
        data: nextProps.unreadSentStickyListResponse,
      });
    }
  }

  onSelectInfo = async (stickyMessage, Id) => {
    //Sticky info Message popup

    this.setState({
      selectedMessage: stickyMessage,
      onInfoSelected: true,
    });
    await this.setReadUserSticky(Id);
  };

  onCloseInfo = () => {
    //Close Sticky Message info popup

    this.setState({
      sticky_Id: 0,
      onInfoSelected: false,
    });
    var userData = localStorage.getItem("userdetail");
    var senderId = JSON.parse(userData).Id_User;
    this.unreadSentStickyList(senderId);
  };

  //Close Sticky List Modal
  onCloseSentSticky = () => {
    this.props.toggleCloseDept();
  };

  toggleChecked = (e) => {
    let selected = this.state.selectedSticky;

    if (e.target.checked) {
      selected.push(parseInt(e.target.value));
    } else {
      const i = selected.indexOf(parseInt(e.target.value));
      if (i > -1) {
        selected.splice(i, 1);
      }
    }

    this.setState({ selectedSticky: selected });
  };

  //Get Departments
  unreadSentStickyList = (loggedInId) => {
    var userData = localStorage.getItem("userdetail");
    loggedInId = JSON.parse(userData).Id_User;
    const { API_HOST, unreadSentStickyList } = this.props;
    unreadSentStickyList(API_HOST, loggedInId);
  };

  //Send UnRead Sent Sticky List as Mail
  sendUnReadSentStickyListAsMail = async () => {
    if (this.state.selectedSticky.length === 0) {
      toast.info("Please select a row to send an Email!");
    } else {
      const { API_HOST, sendUnReadSentStickyListAsMail } = this.props;
      await sendUnReadSentStickyListAsMail(API_HOST, this.state.selectedSticky);

      //Close modal after mail sent
      this.props.toggleUnreadSentSticky();
    }
  };

  onScheduleMeeting = () => {
    toast.info("Functionality to be developed");
  };

  setReadUserSticky = async (stickyId) => {
    if (stickyId !== 0) {
      const { API_HOST, setReadUserSticky } = this.props;

      await setReadUserSticky(API_HOST, stickyId, (res) => {});
    }
  };

  render() {
    const { isUnreadSentStickyModal } = this.props;

    const { data } = this.state;

    const timezone = JSON.parse(localStorage.getItem("userdetail")).TZ
      ? JSON.parse(localStorage.getItem("userdetail")).TZ
      : "Asia/Kolkata";

    const columns = [
      {
        Header: <strong className="my_custom_class">#</strong>,
        accessor: "Id",
        minWidth: 30,
        Cell: (row) => {
          if (row.original.Id) {
            return (
              <input
                type="checkbox"
                className="checkbox-unreadlist"
                //checked={this.state.selected[row.original.Id] === true}
                // checked={this.setState({selected[row.original.Id]: true})}
                value={row.original.Id}
                onChange={(e) => this.toggleChecked(e)}
                style={{ width: "18px", height: "18px" }}
              />
            );
          }
        },
      },
      {
        Header: <strong className="my_custom_class">Date</strong>,
        accessor: "Created_Date",
        Cell: (row) => {
          if (row.original.Created_Date) {
            return (
              <>
                {moment(row.original.Created_Date)
                  .tz(timezone)
                  .format("MM/DD/YYYY")}
              </>
            );
            // return (<>{moment.utc(row.original.created_date).tz(timezone).format('MM/DD/YYYY')}</> )
          }
        },
      },
      {
        Header: <strong className="my_custom_class">Time</strong>,
        accessor: "Created_Date",
        Cell: (row) => {
          if (row.original.Created_Date) {
            //return (<>{moment(row.original.Created_Date).format('hh:mm:ss')} </>)
            return (
              <>
                {moment
                  .utc(row.original.Created_Date)
                  .tz(timezone)
                  .format("hh:mm:ss")}{" "}
              </>
            );
          }
        },
      },
      {
        Header: <strong className="my_custom_class">Sent to</strong>,
        accessor: "Name_UserReceiver",
      },
      {
        Header: <strong className="my_custom_class">Message</strong>,
        accessor: "description",
      },
      {
        Header: <strong className="my_custom_class">Status</strong>,
        accessor: "Is_Read",
        Cell: (row) => {
          if (!row.original.Is_Read) {
            return <>Unread</>;
          }
        },
      },
      {
        Header: <strong className="my_custom_class">Read</strong>,
        accessor: "description",
        minWidth: 50,
        Cell: (row) => {
          return (
            <i
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.onSelectInfo(row.original.description, row.original.Id)
              }
              className="fa fa-eye"
              aria-hidden="true"
            ></i>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        {data && data.length > 0 && (
          <Modal isOpen={isUnreadSentStickyModal} backdrop={true} size={"xl"}>
            <ModalHeader
              toggle={() => this.props.toggleUnreadSentSticky()}
              className="dept-header"
            >
              Hi your stickynotes are unread!
            </ModalHeader>
            <ModalBody className="dept-class">
              <ReactTable
                data={this.props.unreadSentStickyListResponse}
                columns={columns}
                defaultPageSize={5}
                pagination={true}
                className="-striped -highlight"
                pageSizeOptions={[5, 10, 15]}
                defaultSorted={[{ id: "Created_Date", desc: true }]}
              />

              <div>
                <ModalFooter style={{ marginTop: "0px" }}>
                  <Button
                    color="primary"
                    className="col-sm-2"
                    onClick={this.sendUnReadSentStickyListAsMail}
                    style={{ marginLeft: "10px", marginTop: "20px" }}
                  >
                    Send Mail
                  </Button>
                  <Button
                    color="primary"
                    className="col-sm-2"
                    onClick={this.onScheduleMeeting}
                    style={{ marginLeft: "10px", marginTop: "20px" }}
                  >
                    Schedule Meeting
                  </Button>
                </ModalFooter>
              </div>
            </ModalBody>
          </Modal>
        )}

        {this.state.onInfoSelected ? (
          <Modal
            isOpen={this.state.onInfoSelected}
            backdrop={true}
            className="sticky-class"
          >
            
            <ModalHeader
              toggle={() => this.onCloseInfo()}
              className="sticky-class"
            >
              Sticky Notes Sent
            </ModalHeader>
            <ModalBody className="sticky-class">
              {/* <div>{this.props.unreadSentStickyListResponse.Id_Cabin}</div> */}
              <div className="form-group">
                <label>Your message: </label>
                <textarea
                  style={{ whiteSpace: "pre-line" }}
                  type="textarea"
                  className="form-control sticky-class"
                  id="Message"
                  aria-describedby="name"
                  placeholder=""
                  rows="10"
                  cols="30"
                  value={this.state.selectedMessage}
                  name="message"
                  autoFocus
                ></textarea>
              </div>
            </ModalBody>
          </Modal>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

// Prop types of props.
UnreadSentStickyList.propTypes = {
  API_HOST: PropTypes.string,
  unreadSentStickyList: PropTypes.func,
  unreadSentStickyListResponse: PropTypes.array,

  sendUnReadSentStickyListAsMail: PropTypes.func,
  sendUnReadSentStickyListAsMailResponse: PropTypes.any,
};

// Set default props.
UnreadSentStickyList.defaultProps = {
  unreadSentStickyList: () => {},
  unreadSentStickyListResponse: [],

  sendUnReadSentStickyListAsMail: () => {},
  sendUnReadSentStickyListAsMailResponse: false,
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
  unreadSentStickyListResponse:
    state.unreadSentStickyReducer.unreadSentStickyListResponse,
  sendUnReadSentStickyListAsMailResponse:
    state.unreadSentStickyReducer.sendUnReadSentStickyListAsMailResponse,
  setReadUserStickyResponse:
    state.dashboardReducer.setReadUserStickyResponse.setReadUserStickyResponse,
});
export default connect(mapStateToProps, {
  unreadSentStickyList,
  sendUnReadSentStickyListAsMail,
  setReadUserSticky,
})(UnreadSentStickyList);
