import { types as T } from '../actions/action';
import { defaultStore } from '../store';

const forgotPasswordReducer = (state = defaultStore, action) => {
  switch (action.type) {
    
    case T.RECEIVE_FORGOT_PASSWORD:
    	return {...state, 
        forgotPassword: {
          ...state.forgotPasswordResponse,
          loading: false,
          error: false,
          errorMessage: null,
          forgotPasswordResponse: action.forgotPasswordResponse,
        },
      };

  
      default:
        return state
    }
  }

export default forgotPasswordReducer;
