import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from "../../../utils/apiUrls";
import { getDecryptedValue } from "../../../utils/utility";
import { fetchSlackUser, getSlackConfigurationlist, getTenantSlackUserAccessToken } from "../../../actions/dashboardActions";
import { saveUserKnock, stickyMessageSend } from "../../../actions/mainOfficeActions";
import { sendRequestForAddInWorkspace, checkUserRequestForAddInWorkspace } from "../../../actions/slackUserDetailsActions";
import { Button } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { toast } from 'react-toastify';

const axios = require('axios');
const qs = require('qs');

class AddToSlack extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      loading: false,
      //tenantSlackUserAccessToken: ""
      isPresentInSlack: true,
      userNotFoundError: true,
      errorMessage: "",
      isContactAdmin: false,
      showYesButton: false,
      requestDate: ""
    };
  }

  showToken = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      this.setState({
        showPassword: true
      })
    }
    else {
      x.type = "password";
      this.setState({
        showPassword: false
      })
    }
  }

  async componentDidMount() {
     await this.getTenantSlackUserAccessToken();
    if (this.props.slackConfigListResponse.length === 0) {
      this.getSlackConfigurationlist();
    }
     this.checkUserRequestForAddInWorkspace();
  }

  getSlackConfigurationlist = () => {
    const { API_HOST, getSlackConfigurationlist } = this.props;
    this.setState({ loading: true })
    getSlackConfigurationlist(API_HOST, (res) => {
      this.setState({ loading: false })
    });
  }

  getTenantSlackUserAccessToken = async() =>{
    const { API_HOST, getTenantSlackUserAccessToken } = this.props;
    
    await getTenantSlackUserAccessToken(API_HOST, (res) => {
        
        if(res !== null){
         // this.setState({tenantSlackUserAccessToken:res.Slack_User_Access_Token});
          let temp= getDecryptedValue(res.Slack_User_Access_Token) 
          this.lookupByEmail(temp);
        }

    });
    
}

  // Send request to Admin to Add in Slack workspace
  sendRequestForAddInWorkspace = async() =>{
    const { API_HOST, sendRequestForAddInWorkspace } = this.props;

    const requestBody = {
      "Id_User": JSON.parse(localStorage.getItem("userdetail")).Id_User
    };
    await sendRequestForAddInWorkspace(API_HOST, requestBody, (res) => { 
        if(res){
          toast.success("Request to Add in Slack Workspace sent successfully.")
          this.checkUserRequestForAddInWorkspace();
        }
      });
  }

  // Check User request for add in Workspace.
  checkUserRequestForAddInWorkspace = async() =>{
    const { API_HOST, checkUserRequestForAddInWorkspace } = this.props;

    await checkUserRequestForAddInWorkspace(API_HOST, (res) => { 
        if(res){
          this.setState({ showYesButton: false, requestDate : res.CreatedDate});
        }else{
          this.setState({ showYesButton: true });
        }
    });
  }


  lookupByEmail = async (accessToken) => {
    var userEmail = JSON.parse(localStorage.getItem('userdetail')).EmailId;
        try{

              let message = {
                token: accessToken,
                email: userEmail
              };

              this.setState({loading: false});

              const result = await axios.post(`https://slack.com/api/users.lookupByEmail`, qs.stringify(message));
              console.log("ERROR: ", result);
                if(result.data.ok === true){  
                  this.setState({ isPresentInSlack: true });
                }else{
                    if(result.data.error === "users_not_found"){
                      this.setState({ userNotFoundError : true });
                    }else{
                      this.setState({ errorMessage: result.data.error })
                    }
                }
        }
        catch (error) {
          console.error(error);
        }

  }

  contactAdmin = (changeEvent) => {
   // alert(changeEvent.target.value);
    this.setState({ isContactAdmin:true});
    this.sendRequestForAddInWorkspace();
  }

  render() {
    var userData = JSON.parse(localStorage.getItem('userdetail'));
    var userSlackToken = getDecryptedValue(userData.Slack_Access_Token);
    var userEmailId = userData.EmailId;
    var workspace = userData.Slack_WorkSpace_Name;
    
    let { slackConfigListResponse } = this.props;
    const { loading } = this.state;
    let scope = "calls:read,calls:write,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.customize,chat:write.public,groups:history,im:history,links:write,mpim:history,team:read,usergroups:read,usergroups:write,users.profile:read,users:read,users:write,links:read&user_scope=calls:read,calls:write,channels:history,channels:read,channels:write,chat:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,links:read,links:write,mpim:history,mpim:read,mpim:write,search:read,team:read,usergroups:read,usergroups:write,users.profile:read,users.profile:write,users:read,users:read.email,users:write,reminders:write";
    let clientId="";

    if (slackConfigListResponse.length > 0) {
      scope = slackConfigListResponse[0].Scope_Detail;
      clientId = slackConfigListResponse[0].Client_ID;
    }

    return (
      <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
      >
        {this.state.isPresentInSlack ? (
          <>
              {userSlackToken !== "" ? (
                <>
                  <div className="tab-center-content">
      
                    <div className="mx-auto">
                      <h3>Slack Token</h3>
      
                      <input className="form-control mt-4 mb-4" type="password" value={userSlackToken} id="myInput"></input>
      
                      <div className="row">
                        <div className="col-xl-6">
                          <Button className="btn btn-primary w-100" onClick={this.showToken} type="submit">{this.state.showPassword ? "Hide" : "Show"}</Button>
      
                        </div>
                        <div className="col-xl-6">
                          <a className="btn btn-primary w-100" target="_self" href={`https://slack.com/oauth/v2/authorize?client_id=${getDecryptedValue(clientId)}&${scope}&redirect_uri=${window.location.protocol}//${window.location.host}/slacktoken`}>
                            Regenerate
                          </a>
                        </div>
      
                      </div>
      
                    </div>
      
                  </div>
                  <div style={{ minHeight: '200px' }}>
                  </div>
                </>
              ) :

                  (
                    <div style={{ marginTop: '50px' }}>
                      <center>
                        <h1>Welcome!</h1>
                        <h4>You need to create your Slack Token to start a chat with your Slack Groups</h4>
                        <a target="_self" href={`https://slack.com/oauth/v2/authorize?client_id=${getDecryptedValue(clientId)}&${scope}&redirect_uri=${window.location.protocol}//${window.location.host}/slacktoken`}>
                          <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
                        </a>
                      </center>
                    </div>
        
                  )
              }
              </>
        ) 
        
        :
        <>
        { !this.state.userNotFoundError &&

            (<div className="tab-center-content">
              <div className="mx-auto">
                <h4> There is some error..</h4>
                <h5>Error Message:  {this.state.errorMessage} </h5>
              </div>
            </div>)
            }
        </>
        }


        { !this.state.isPresentInSlack && this.state.userNotFoundError &&
            <div className="tab-center-content">
               <div className="mx-auto">
                   <h4>Sorry...user with EmailId : <i>{userEmailId}</i> is not present in Slack Workspace : <i>{workspace}</i>.<br></br>
                    So you will not be able to use the chat functionalities in Precis.</h4><br></br>
                    
                      {this.state.showYesButton === true ? 
                        <>
                        <h4>Please contact your slack/precis admin to add in slack.</h4><br></br>
                        <h5>Want to contact?   <input type="radio" value='true' style={{cursor: 'pointer'}} onChange={this.contactAdmin} /> Yes</h5>
                        </> :
                        <h5 style={{color: "#2c69d1"}}>Request sent by you on {moment(this.state.requestDate).format('DD-MM-YYYY')}.</h5>
                      }
                    
               </div>
            </div>
        }
      </LoadingOverlay>
    );
  }

}

// Prop types of props.
AddToSlack.propTypes = {
  API_HOST: PropTypes.string,
  memberListResponse: PropTypes.any,
  slackConfigListResponse: PropTypes.any,
  getSlackConfigurationlist: PropTypes.func,
  getTenantSlackUserAccessToken: PropTypes.func,
  getTenantSlackUserAccessTokenResponse:PropTypes.any
};

// Set default props.
AddToSlack.defaultProps = {
  memberListResponse: null,
  slackConfigListResponse: [],
  getSlackConfigurationlist: () => { },
  getTenantSlackUserAccessTokenResponse: null,
  getTenantSlackUserAccessToken: ()=>{ }
};


const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  memberListResponse: state.dashboardReducer.fetchSlackUser.slackUserResponse.SourceData,
  slackConfigListResponse: state.dashboardReducer.slackConfigListResponse.slackConfigListResponse,
  getTenantSlackUserAccessTokenResponse:state.dashboardReducer.getTenantSlackUserAccessTokenResponse.getTenantSlackUserAccessTokenResponse
})

export default connect(mapStateToProps, { fetchSlackUser, getSlackConfigurationlist, saveUserKnock, stickyMessageSend, getTenantSlackUserAccessToken, sendRequestForAddInWorkspace, checkUserRequestForAddInWorkspace })(AddToSlack)