import { types as T } from '../actions/action';

const initialState = {
  virtualRoomResponse: []
}

export default (state = initialState, action) => {
    switch (action.type) {
     case T.RECEIVE_VIRTUAL_ROOM:
      return {
        ...state,
        virtualRoomResponse: action.virtualRoomResponse
      }   
     default:
      return state
    }
}