import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from "../../../utils/apiUrls";
import "react-table-6/react-table.css";
import { toast } from "react-toastify";
import { getJiraLog, downloadExcelFile } from "../../../actions/logActions";
import {
  getUserJiraRefreshToken,
  getJiraDomain,
  updateUserJiraRefreshToken,
  getJiraTaskLogDetails,
  getJiraConfig,
} from "../../../actions/jiraActions";
import { getPrivateJiraTaskList } from "../../../actions/privateJiraActions";
import LoadingOverlay from "react-loading-overlay";
import ReactTable from "react-table-6";
import {
  getEncryptedValue,
  getDecryptedValue,
} from "../../../utils/utility";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import ClockLoader from "react-spinners/ClockLoader";
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
} from "reactstrap";
import moment from "moment";
import { saveAs } from "file-saver";
import _ from "lodash";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";

class TaskWiseLogReport extends React.Component {
  state = {
    loading: false,
    enabled: false,
    isActive: false,
    jiraTaskList: [],
    jiraTaskLogDetails: [],
    totalWorkedHours: 0,
    taskSelected: {},
    selectedTasksList: [],
    taskDate: new Date(),
    taskEndDate: new Date(),
    isDayWise: true,
    isMonthWise: false,
    allTasksId: [],
    jiraDomain: [],
    jiraDomainSelected: {},
    cloudId: "",
    epicStatusSelected: [],
    preSlectedstatus: [{ name: "In Progress" }],
  };

  async componentDidMount() {
    this.getJIRAConfig();

    // await this.setState({ jiraTaskList: this.props.JiraTaskList})
    //await  console.log("jiratasklist: ",this.state.jiraTaskList);

    // const result = [];
    // for (var i=0; i < this.state.jiraTaskList.length ; ++i){
    //   result.push(this.state.jiraTaskList[i]["key"]);
    // }

    // this.setState({allTasksId: result});
    // console.log("allTasksId: ",this.state.allTasksId);

    let taskSelected = {
      id: 0,
      key: "",
      value: "",
      summary: "All Tasks",
      label: <div>{"     All Tasks"}</div>,
    };

    await this.setState({ taskSelected: taskSelected });
    //await this.getJiraLogData();
  }

  //Tasks Selection
  onSelectTasks = async (newValue) => {
    this.setState({ taskSelected: newValue });

    newValue &&
      newValue.length > 1 &&
      newValue.map((obj) => {
        if (obj.id === 0) {
          newValue.splice(obj, 1);
        }
        this.setState({ taskSelected: newValue });
      });

    if (newValue !== null) {
      const result = [];
      for (var i = 0; i < newValue.length; ++i) {
        result.push(newValue[i]["key"]);
      }

      //console.log("RESULT: ",result);

      await this.setState({ selectedTasksList: result });
      //console.log("selectedTasksList: ",this.state.selectedTasksList);
      //await this.getJiraLogData();
    }
  };

  getJiraLogData = async () => {
    const { API_HOST, getJiraLog } = this.props;

    if (this.state.selectedTasksList.includes("")) {
      const index = this.state.selectedTasksList.indexOf("");
      this.state.selectedTasksList.splice(index, 1);
      //console.log("after remove '' :: ", this.state.selectedTasksList);
    }

    this.setState({ loading: true });
    let selectedEpicStatus;

    if (this.state.epicStatusSelected.length === 0) {
      selectedEpicStatus = this.state.preSlectedstatus.map(
        (label) => label.name
      );
    } else {
      selectedEpicStatus = this.state.epicStatusSelected.map(
        (label) => label.name
      );
    }
    if (selectedEpicStatus.length !== 0) {
      let reqObj = {
        Id_User: [],
        Jira_Task_Keys:
          this.state.selectedTasksList.length === 0
            ? this.state.allTasksId
            : this.state.selectedTasksList,
        StartLogDateTime: this.state.taskDate,
        EndLogDateTime: this.state.taskEndDate,
        TaskStatusList: selectedEpicStatus,
      };

      await getJiraLog(API_HOST, reqObj, (res) => {
        if (res[0] !== null) {
          this.setState({
            jiraTaskLogDetails: res.splice(0, res.length - 1),
            loading: false,
            totalWorkedHours: res[res.length - 1].WorkedHours,
          });
        } else {
          this.setState({ jiraTaskLogDetails: [], loading: false });
        }
      });
    }

    await this.setState({ loading: false });
  };

  //download excel file
  downloadExcelFile = async () => {
    const { API_HOST, downloadExcelFile } = this.props;
    const finalUrl = `${API_HOST}Jira/JiraTaskLogReportExportToExcel`;
    this.setState({ enabled: true });
    let selectedEpicStatus;

    if (this.state.epicStatusSelected.length === 0) {
      selectedEpicStatus = this.state.preSlectedstatus.map(
        (label) => label.name
      );
    } else {
      selectedEpicStatus = this.state.epicStatusSelected.map(
        (label) => label.name
      );
    }
    if (selectedEpicStatus.length !== 0) {
      let reqObj = {
        Id_User: [],
        Jira_Task_Keys: this.state.selectedTasksList,
        StartLogDateTime: this.state.taskDate,
        EndLogDateTime: this.state.taskEndDate,
        TaskStatusList: selectedEpicStatus,
      };

      await downloadExcelFile(finalUrl, reqObj, (res) => {
        this.downloadFile(res.Data.FileContent, res.Data.FileName);
        this.setState({ enabled: false });
      });
    }
  };

  // Method to convert byte array to Uint8Array which can be further used to create blob
  base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  // Method to save byte array as file using FileSaver library
  downloadFile = (byteArray, fileNameWithExtension) => {
    let result = this.base64ToArrayBuffer(byteArray);
    let blob = new Blob([result], { type: "application/octet-stream" });
    saveAs(blob, fileNameWithExtension);
  };

  //Set Date
  setDate = (date) => {
    this.setState({ taskDate: date });
  };

  setEndDate = (date) => {
    this.setState({ taskEndDate: date });
  };

  changeReportDayWise = () => {
    this.setState({
      isDayWise: true,
      isMonthWise: false,
      taskDate: new Date(),
      taskEndDate: new Date(),
      jiraTaskLogDetails: [],
    });
  };

  changeReportMonthWise = () => {
    this.setState({
      isMonthWise: true,
      isDayWise: false,
      taskDate: moment().startOf("month").toDate(),
      taskEndDate: new Date(),
      jiraTaskLogDetails: [],
    });
  };

  getJIRAConfig = () => {
    const { API_HOST, getJiraConfig } = this.props;
    getJiraConfig(API_HOST, (res) => {
      if (res[0]) {
        this.setState({
          jiraClientId: getDecryptedValue(res[0].Client_ID),
          jiraClientSecret: getDecryptedValue(res[0].Client_Secret),
        });

        //this.getJiraDomain();
        this.getRefreshToken();
      } else {
        this.setState({ jiraClientDisable: true });
      }
    });
  };

  getJiraDomain = async () => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, (res) => {
      if (res[0]) {
        let newValue = res.map(function (obj) {
          return {
            id: getDecryptedValue(obj.CloudId),
            CloudId: getDecryptedValue(obj.CloudId),
            Is_Private: obj.Is_Private,
            Domain_Name: obj.Domain_Name,
            key: obj.Domain_Name,
            url: obj.url,
            Jira_Scope_Detail: obj.Jira_Scope_Detail,
            label: obj.Domain_Name,
            value: getDecryptedValue(obj.CloudId),
          };
        });
        this.setState({
          jiraDomain: newValue,
          jiraDomainSelected: newValue[0],
          cloudId: newValue[0].CloudId,
        });
        this.getPrivateJiraMyTaskList(newValue[0].Domain_Name);
      }
    });
  };

  getRefreshToken = () => {
    const { API_HOST, getUserJiraRefreshToken } = this.props;

    getUserJiraRefreshToken(API_HOST, (res) => {
      if (res) {
        this.setState({ refreshToken: res.Jira_Token });
        this.getJiraDomain();
        this.getAccessToken(res.Jira_Token);
      }
    });
  };

  getAccessToken = async (refreshToken) => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: getDecryptedValue(refreshToken),
      };

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });
      this.setState({
        accessToken: data.data.access_token,
        refreshToken: data.data.refresh_token,
      });
      // this.setState({ accessToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik16bERNemsxTVRoRlFVRTJRa0ZGT0VGRk9URkJOREJDTVRRek5EZzJSRVpDT1VKRFJrVXdNZyJ9.eyJodHRwczovL2F0bGFzc2lhbi5jb20vb2F1dGhDbGllbnRJZCI6IlB1R0ljMU52UzdtYkQ3RzdiaXE3UXJ2NEd5cGxBQ2tHIiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL2VtYWlsRG9tYWluIjoiaGVsaW9zc29sdXRpb25zLmNvIiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL3N5c3RlbUFjY291bnRJZCI6IjVmY2RlOGM0YTIzN2Y3MDA2ZmU0YTdhYyIsImh0dHBzOi8vYXRsYXNzaWFuLmNvbS9zeXN0ZW1BY2NvdW50RW1haWxEb21haW4iOiJjb25uZWN0LmF0bGFzc2lhbi5jb20iLCJodHRwczovL2F0bGFzc2lhbi5jb20vdmVyaWZpZWQiOnRydWUsImh0dHBzOi8vYXRsYXNzaWFuLmNvbS9maXJzdFBhcnR5IjpmYWxzZSwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tLzNsbyI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9hdGxhc3NpYW4tYWNjb3VudC1wcm9kLnB1czIuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDVlY2I2NmNiN2QwYzI1MGMyMzRjZDkxNiIsImF1ZCI6ImFwaS5hdGxhc3NpYW4uY29tIiwiaWF0IjoxNjEwNDQ2Mjc3LCJleHAiOjE2MTA0NDk4NzcsImF6cCI6IlB1R0ljMU52UzdtYkQ3RzdiaXE3UXJ2NEd5cGxBQ2tHIiwic2NvcGUiOiJtYW5hZ2U6amlyYS1jb25maWd1cmF0aW9uIG1hbmFnZTpqaXJhLXByb2plY3Qgd3JpdGU6amlyYS13b3JrIHJlYWQ6amlyYS13b3JrIHJlYWQ6amlyYS11c2VyIG1hbmFnZTpqaXJhLWRhdGEtcHJvdmlkZXIgb2ZmbGluZV9hY2Nlc3MifQ.uDkf7LEoCmApZLaVqksmGObfCyiYl1x_pZ7Mjzep2zQktv94CLDnu1-vii8Pc6tu6nU5Slm1SVLSgrtYsrUYjzMtr4LzfpXnwOy6S4_Mpnp9LTSDWg8GhNnJ_pa7eq3r7Db95w9pIcQmqlvqjksv3ySZfWU6kyS-IBhGrFzmkBQ8jYwxJZI8KNdGYeO9bHkfwQxEqvF7GRY2xrgirpumaa37ZgIllmvJRmRluzyUe4omNsMnLSO_10sc0IU518ZHDzTzcmNaiyeX7WXfICpiETsPz1cGu7R0oeUyO7nKEV8HNxG3uNl7PvU70hoPpk_cOFku3CH79LuJYtrqqn3dtg" })

      await this.updateUserJiraRefreshToken(data.data.refresh_token);
    } catch (error) {
      toast.error(error);
      this.setState({ isActive: false });
    }
  };

  updateUserJiraRefreshToken = async (refreshToken) => {
    const { API_HOST, updateUserJiraRefreshToken } = this.props;

    let requestBody = {
      Jira_Token: getEncryptedValue(refreshToken),
    };

    await updateUserJiraRefreshToken(API_HOST, requestBody);
  };

  onSelectDomain = (newValue) => {
    if (newValue.Is_Private) {
      this.getPrivateJiraMyTaskList(newValue.Domain_Name);
      this.setState({
        isPrivateJiraSelected: true,
        jiraDomainSelected: newValue,
        jiraTaskLogDetails: [],
      });
    } else {
      this.getJiraYourTaskList(newValue.CloudId, this.state.accessToken);
      this.setState({
        isPrivateJiraSelected: false,
        jiraDomainSelected: newValue,
        cloudId: newValue.CloudId,
        jiraTaskLogDetails: [],
      });
    }
  };

  getPrivateJiraMyTaskList = async (domainName) => {
    const { API_HOST, getPrivateJiraTaskList } = this.props;
    this.setState({ loading: true });

    let requestBody = {
      IsCurrentUser: true,
      DomainName: domainName,
    };

    await getPrivateJiraTaskList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({
          jiraTaskList: res,
          selectedPageSize: 10,
          selectedPage: 0,
          loading: false,
        });

        const result = [];
        for (var i = 0; i < this.state.jiraTaskList.length; ++i) {
          result.push(this.state.jiraTaskList[i]["key"]);
        }

        this.setState({ allTasksId: result });
      }
    });
  };

  getJiraYourTaskList = async (cloudId, token) => {
    this.setState({ loading: true });

    try {
      const finalUrl = `https://api.atlassian.com/ex/jira/${cloudId}/rest/api/2/search?jql=assignee=currentuser()AND status IN ('To Do','In Progress')&maxResults=500&order%20by%20created&fields=assignee,comment,created,creator,description,issuelinks,duedate,issuetype,parent,priority,progress,project,reporter,status,subtasks,summary`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({
        jiraTaskList: data.issues,
        selectedPageSize: 10,
        selectedPage: 0,
      });

      const result = [];
      for (var i = 0; i < this.state.jiraTaskList.length; ++i) {
        result.push(this.state.jiraTaskList[i]["key"]);
      }

      this.setState({ allTasksId: result });
      //console.log("allTasksId: ",this.state.allTasksId);
    } catch (error) {
      toast.error(error);
    }
    this.setState({ isActive: false, loading: false });
  };

  onSelect = async (selectedList, selectedItem) => {
    this.setState({
      epicStatusSelected: selectedList,
    });
  };

  onRemove = async (selectedList, removedItem) => {
    this.setState({
      epicStatusSelected: selectedList,
    });
    if (this.state.epicStatusSelected.length === 0) {
      await this.setState({
        preSlectedstatus: [],
      });
    }
  };

  render() {
    const { jiraTaskLogDetails, totalWorkedHours } = this.state;

    let jiraTasksOptions = [];

    jiraTasksOptions.push({
      id: 0,
      key: "",
      value: "",
      summary: "All Tasks",
      label: <div>{"     All Tasks"}</div>,
    });

    this.state.jiraTaskList[0] &&
      this.state.jiraTaskList.map((obj) => {
        jiraTasksOptions.push({
          id: obj.id,
          key: obj.key,
          value: `${obj.key} : ${obj.fields.summary}`,
          summary: obj.fields.summary,
          label: `${obj.key} : ${obj.fields.summary}`,
        });
        return jiraTasksOptions;
      });

    const timezone = JSON.parse(localStorage.getItem("userdetail")).TZ
      ? JSON.parse(localStorage.getItem("userdetail")).TZ
      : "Asia/Kolkata";
    //var totalWorkedHours = jiraTaskLogDetails && jiraTaskLogDetails.length > 0 ? jiraTaskLogDetails[jiraTaskLogDetails.length - 1].WorkedHours : 0;
    var h = Math.floor(totalWorkedHours / 60);
    var m = totalWorkedHours % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    var totalWorkedHoursDisplay = h + " : " + m;
    const columns = [
      //   {
      //     Header: <strong className="my_custom_class">Task Key</strong>,
      //     accessor: 'Jira_Task_Key',
      //     width: 150,
      //    // groupedByPivotKey: 'Jira_Task_Key',
      //   //  filterable: true,
      //     Cell: (row) => {
      //       return <div onClick="">{row.original.Jira_Task_Key}</div>
      //     }
      // },
      {
        Header: <strong className="my_custom_class">Name</strong>,
        accessor: "User_RealName",
        width: 150,
        // groupedByPivotKey: 'Jira_Task_Key',
        Cell: (row) => {
          return <div onClick="">{row.original.User_RealName}</div>;
        },
      },
      {
        Header: <strong className="my_custom_class">Summary</strong>,
        accessor: "Jira_Task_Summary",
        // width: 250,
        Cell: (row) => {
          return <div onClick="">{row.original.Jira_Task_Summary}</div>;
        },
      },
      {
        Header: <strong className="my_custom_class">Comments</strong>,
        accessor: "Description",
        // width: 180,
        Cell: (row) => {
          return <div onClick="">{row.original.Description}</div>;
        },
      },
      {
        Header: <strong className="my_custom_class">Date</strong>,
        accessor: "LogDateTime",
        Footer: <div>Total Hours</div>,
        width: 100,
        Cell: (row) => {
          return (
            <div className="d-block text-right" onClick="">
              {moment(row.original.LogDateTime)
                .tz(timezone)
                .format("MM/DD/YYYY")}
            </div>
          );
        },
      },
      {
        Header: (
          <strong style={{ alignContent: "right" }} className="my_custom_class">
            Hrs:Mins
          </strong>
        ),
        accessor: "DisplayWorkedHours",
        Footer: <div>{totalWorkedHoursDisplay}</div>,
        width: 95,
        Cell: (row) => {
          // var h = Math.floor(row.original.WorkedHours / 60);
          // var m = row.original.WorkedHours % 60;
          // h = h < 10 ? '0' + h : h;
          // m = m < 10 ? '0' + m : m;

          return (
            // <div className="d-block text-right" onClick="">{h}:{m}</div>
            <div className="d-block text-right" onClick="">
              {row.original.DisplayWorkedHours}
            </div>
          );
        },
      },
    ];

    const statusOptions = [
      { name: "To Do" },
      { name: "In Progress" },
      { name: "Done" },
    ];

    return (
      <>
        <Modal
          isOpen={this.props.isTaskWiseLogReport}
          backdrop={true}
          size={"xl"}
        >
          {/* <ModalHeader toggle={() => this.props.closeTaskWiseLogReport()}>{JSON.parse(userData).Is_Admin === true ? "All Tasks Log Report" : "My Tasks Log Report"}</ModalHeader> */}
          <ModalHeader toggle={() => this.props.closeTaskWiseLogReport()}>
            Tasks Log Report
          </ModalHeader>
          <LoadingOverlay
            active={this.state.isActive}
            spinner
            text="Loading..."
          >
            <ModalBody className="">
              <FormGroup>
                <Row style={{ justifyContent: "space-between" }}>
                  <h6>Domain: </h6>
                  <Col xs="">
                    <Select
                      components={makeAnimated}
                      onChange={(newValue) => {
                        this.onSelectDomain(newValue);
                      }}
                      options={this.state.jiraDomain}
                      value={this.state.jiraDomainSelected}
                    />
                  </Col>

                  <h6>Tasks :</h6>
                  <Col xs="4">
                    <Select
                      components={makeAnimated}
                      // onChange={(newValue) => { this.getJiraTaskLogDetails(newValue) }}
                      onChange={(newValue) => {
                        this.onSelectTasks(newValue);
                      }}
                      options={jiraTasksOptions}
                      value={this.state.taskSelected}
                      isMulti
                      // style={{ width : '650px'}}
                    />
                  </Col>
                  <h6>Task Status</h6>
                  <Col xs="4">
                    <Multiselect
                      options={statusOptions} // Options to display in the dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      showCheckbox
                      selectedValues={this.state.preSlectedstatus}
                      hidePlaceholder
                      avoidHighlightFirstOption
                    />
                  </Col>
                </Row>

                <Row
                  className="mt-3 align-items-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <Col xs="4">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline1"
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="option1"
                        checked={this.state.isDayWise}
                        onClick={this.changeReportDayWise}
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline1"
                      >
                        Daywise Report
                      </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline2"
                        name="customRadioInline2"
                        class="custom-control-input"
                        value="option2"
                        checked={this.state.isMonthWise}
                        onClick={this.changeReportMonthWise}
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline2"
                      >
                        Monthwise Report
                      </label>
                    </div>
                  </Col>

                  <Col xs="3">
                    <DatePicker
                      className="form-control  mr-4"
                      selected={this.state.taskDate}
                      onChange={(date) => this.setDate(date)}
                      id="taskdate"
                      label="Date"
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col xs="3">
                    {this.state.isMonthWise ? (
                      <DatePicker
                        className="form-control"
                        selected={this.state.taskEndDate}
                        onChange={(date) => this.setEndDate(date)}
                        id="taskEnddate"
                        label="Date"
                        placeholderText="Select Date"
                      />
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col xs="2">
                    <div className="d-flex">
                      <Button
                        type="button"
                        className="btn-icon btn-2 btn-filter-icon btn btn-primary"
                        color="primary"
                        onClick={this.getJiraLogData}
                      >
                        <i
                          className="fa fa-search"
                          style={{ fontSize: "18px", color: "white" }}
                        ></i>
                      </Button>
                      <Button
                        type="button"
                        className="btn-icon btn-2 btn-filter-icon btn btn-secondary ml-3"
                        color="secondary"
                        onClick={this.downloadExcelFile}
                      >
                        {this.state.enabled === false ? (
                          <i
                            className="fa fa-download"
                            style={{ fontSize: "18px", color: "white" }}
                          ></i>
                        ) : (
                          <ClockLoader
                            size={20}
                            color={"#64B988"}
                            loading={this.state.enabled}
                          />
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col>
                    <ReactTable
                      manual
                      data={this.state.jiraTaskLogDetails}
                      columns={columns}
                      showPageSizeOptions={false}
                      showPagination={false}
                      className="-striped -highlight"
                      loading={this.state.loading}
                      style={{ height: "400px" }}
                      pivotBy={["Jira_Task_Summary", "User_RealName"]}
                      // filterable
                      //pivotBy={this.state.jiraTaskLogDetails.Jira_Task_Key}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </ModalBody>
          </LoadingOverlay>
        </Modal>
      </>
    );
  }
}

// Prop types of props
TaskWiseLogReport.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
TaskWiseLogReport.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
  };
};
export default connect(mapStateToProps, {
  downloadExcelFile,
  getJiraLog,
  getJiraTaskLogDetails,
  getJiraDomain,
  getPrivateJiraTaskList,
  getUserJiraRefreshToken,
  updateUserJiraRefreshToken,
  getJiraConfig,
})(TaskWiseLogReport);
