import React from 'react';
import { Modal, ModalBody, ModalHeader, FormGroup, Col, Button, Row, ModalFooter } from 'reactstrap';
import { Card, CardContent, Typography } from '@material-ui/core'
//import { saveReminder } from "../../../actions/todoActions";
import { connect } from 'react-redux';
import { getApiHost } from "../../../utils/apiUrls";
import { getDecryptedValue } from "../../../utils/utility";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Avatar from 'avataaars';
import { Scrollbars } from 'react-custom-scrollbars';
import LoadingOverlay from 'react-loading-overlay';
import Pagination from "react-js-pagination";
import Autocomplete from '@material-ui/lab/Autocomplete';

const axios = require('axios');
const qs = require('qs');

class SlackSearchBox extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isSearch: this.props.isSearch,
            searchList: [],
            searchKey: this.props.searchKey,
            isActive: false,
            activePage: 1,
            pagination: {},
            page: 1,
            tags: this.props.tags
        };
    }


    componentDidMount() {
        this.searchChat();
        let { tags } = this.state;
        let tagsTitle = [];
        tags.map(obj => {
            tagsTitle.push({ title: obj.label })
            return tagsTitle;
        })
        this.setState({
            tags: tagsTitle
        })
    }

    searchChat = async () => {
        if (this.state.searchKey !== '') {
            this.setState({ isActive: true })
            var userData = JSON.parse(localStorage.getItem('userdetail'));
            var userSlackToken = userData.Slack_Access_Token;
            let token = getDecryptedValue(userSlackToken);
            //let token = "xoxp-1245264436822-1402639477958-1461646276661-9095b26e9116af59d268844ea6ae3fb8"
            let messageSearch = {
                token: token,
                query: this.state.searchKey,
                pretty: 1,
                page: this.state.page
            };
            const result = await axios.post(`https://slack.com/api/search.messages`, qs.stringify(messageSearch));

            if (result) {
                const { scrollbar } = this.refs;
                this.setState({
                    searchList: result.data.messages.matches,
                    pagination: result.data.messages.pagination,
                    isActive: false
                }, () => {
                    scrollbar.scrollTop();
                })
            }
        } else {
            this.setState({
                searchList: [],
                page: 1
            })
        }
    }

    closeSearch = () => {
        this.props.toggleSlackSearch()
    }

    keyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.setState({ page: 1 }, () => {
                this.searchChat();
            })
        }
    }

    clearKeyword = () => {
        this.setState({
            searchList: [],
            searchKey: '',
            page: 1
        })
    }

    handlePageChange = (pageNumber) => {
        //console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber, page: pageNumber }, () => {
            this.searchChat();
        });
    }

    textOnchange = (e, val) => {
        this.setState({
            searchKey: val,
            page: 1
        })
    }

    onSelectOption = (e, val) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        } else {
            this.setState({ searchKey: val, page: 1 }, () => {
                if (val !== null) {
                    this.searchChat();
                }
                if (val === null) {
                    this.clearKeyword()
                }
            })
        }
    }

    render() {
        const { isSearch, searchList, searchKey, isActive, activePage, pagination, tags } = this.state;

        const flatProps = {
            options: tags.map((option) => option.title),
        };

        return (
            <Modal isOpen={isSearch} backdrop={false} size={'lg'} style={{ marginTop: '5%', }}>
                <ModalHeader toggle={this.closeSearch}>
                    <Row>
                        <Col xs="7" style={{ marginLeft: '15px' }}>
                            <Autocomplete
                                {...flatProps}
                                id="flat-demo"
                                value={searchKey}
                                renderInput={(params) => <TextField {...params} label="search" margin="normal" />}
                                onChange={(event, newValue) => {
                                    this.onSelectOption(event, newValue)
                                }}
                                autoSelect={true}
                                onInputChange={(event, newValue) => {
                                    this.textOnchange(event, newValue)
                                }}
                                style={{ width: '200px' }}
                                onKeyDown={this.keyPress}
                            />
                        </Col>
                        <Col xs="2" style={{ marginTop: '8%' }}><Button type="button" style={{ borderRadius: '25px', minHeight: '20px', height: '30px', backgroundImage: 'linear-gradient( 109.6deg,  rgba(22,155,215,1) 11.2%, rgba(34,45,101,1) 99.8% )' }} onClick={this.searchChat}>Search</Button></Col>
                        {/* <Col xs="2" style={{ marginTop: '4%' }}><Button type="button" style={{ borderRadius: '25px', minHeight: '20px', height: '30px' }} onClick={this.clearKeyword}>Clear</Button></Col> */}
                    </Row>
                </ModalHeader>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Loading...'
                >
                    <ModalBody>
                        <Scrollbars style={{ height: '500px' }} autoHide ref={'scrollbar'}>
                            {searchList && searchList.map(search => {
                                return (
                                    <FormGroup>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="body2" component="h5"><strong>{search.channel.name}</strong> ,  {moment.unix(search.ts).format("MMM Do")}</Typography>
                                                <Row>
                                                    <Col xs="1">
                                                        {this.props.channelWiseSlackUserDetailsResponse && this.props.channelWiseSlackUserDetailsResponse.map((slackuser) => {
                                                            if (getDecryptedValue(slackuser.Id_SlackUser) === search.user) {
                                                                return (
                                                                    <Avatar
                                                                        style={{ height: '40px', width: '40px' }}
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(slackuser.ProfilePic)["topType"]}
                                                                        accessoriesType={JSON.parse(slackuser.ProfilePic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(slackuser.ProfilePic)["hairColor"]}
                                                                        facialHairType={JSON.parse(slackuser.ProfilePic)["facialHairType"]}
                                                                        clotheType={JSON.parse(slackuser.ProfilePic)["clotheType"]}
                                                                        clotheColor={JSON.parse(slackuser.ProfilePic)["clotheColor"]}
                                                                        eyeType={JSON.parse(slackuser.ProfilePic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(slackuser.ProfilePic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(slackuser.ProfilePic)["mouthType"]}
                                                                        skinColor={JSON.parse(slackuser.ProfilePic)["skinColor"]}
                                                                        graphicType={JSON.parse(slackuser.ProfilePic)["graphicType"]}
                                                                        hatColor={JSON.parse(slackuser.ProfilePic)["hatColor"]}
                                                                    />
                                                                )
                                                            }
                                                            return ''
                                                        })}
                                                    </Col>
                                                    <Col xs="10">
                                                        <Typography variant="body2" component="h5">
                                                            <strong>{search.username}</strong>  {moment.unix(search.ts).format("HH:MM a")}<br /><div dangerouslySetInnerHTML={{ __html: search.text }}></div>
                                                        </Typography>
                                                    </Col>
                                                </Row>

                                            </CardContent>
                                        </Card>
                                    </FormGroup>
                                )
                            })}
                            {searchList.length === 0 &&
                                <FormGroup style={{ textAlign: 'center', marginTop: '25%' }}>
                                    <Typography variant="body2" component="h5">Sorry! No Data</Typography>
                                </FormGroup>
                            }
                        </Scrollbars>
                    </ModalBody>
                </LoadingOverlay>
                {searchList.length > 0 && <ModalFooter>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pagination.per_page}
                        totalItemsCount={pagination.total_count}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="prev"
                        nextPageText="next"
                    />
                </ModalFooter>}
            </Modal>
        );
    }
}
// Prop types of props.
SlackSearchBox.propTypes = {
    API_HOST: PropTypes.string,
    channelWiseSlackUserDetailsResponse: PropTypes.any
};

// Set default props.
SlackSearchBox.defaultProps = {
    channelWiseSlackUserDetailsResponse: []
};

const mapStateToProps = state => ({
    API_HOST: getApiHost(),
   // channelWiseSlackUserDetailsResponse: state.mainOfficeReducer.userListDepartmentFilterResponse
   //new
   channelWiseSlackUserDetailsResponse: state.slackUserDetailsReducer.channelWiseSlackUserDetailsResponse,
})

export default connect(mapStateToProps, {})(SlackSearchBox)
