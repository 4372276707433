import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from "../../../utils/apiUrls";
import { FormGroup, Row, Col } from "reactstrap";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { toast } from "react-toastify";
import axios from "axios";
import UnassignedUser from "../../../assets/images/UnassignedUser.png";
import Select from "react-select";
import JiraIssue from "./JiraIssue";
import makeAnimated from "react-select/animated";
import { getJiraTaskLogDetails } from "../../../actions/jiraActions";
import {
  getPrivateJiraTaskList,
  getPrivateJiraProjectList,
  getPrivateJiraUsersList,
} from "../../../actions/privateJiraActions";
import PrivateJiraIssue from "./PrivateJiraIssue";
import Multiselect from "multiselect-react-dropdown";

class BacklogIssues extends React.Component {
  state = {
    loading: true,
    isActive: false,
    BacklogIssuesTaskList: [],
    projectList: [],
    projectSelected: null,
    BacklogIssuesInfo: null,
    isJiraIssue: false,
    isPrivateJiraIssue: false,
    jiraIssue: null,
    jiraApiResults: [],
    projectUsersList: [],
    userSelected: {},
    searchValue: "",
    accessToken: "",
    showAllIssues: false,
    pageSizeOptions: [5, 10, 20, 25, 50, 100],
    selectedPageSize: 10,
    selectedPage: 0,
    jiraTaskLogDetailsBacklog: [],
    isPrivateJiraSelected: this.props.isPrivateJiraSelected,
    showUserMessage: false,
    epicStatusSelected: [],
    preSlectedstatus: [{ name: "To Do" }],
  };

  async componentDidMount() {
    if (this.props.isPrivateJiraSelected) {
      // let projectSelected = {
      //   id: this.props.privateJiraTaskList.fields.project.id,
      //   avatarUrls: this.props.privateJiraTaskList.fields.project.avatarUrls,
      //   name: this.props.privateJiraTaskList.fields.project.name,
      //   key: this.props.privateJiraTaskList.fields.project.key,
      //   self: this.props.privateJiraTaskList.fields.project.self,
      //   projectTypeKey: this.props.privateJiraTaskList.fields.project.projectTypeKey,
      //   label: this.props.privateJiraTaskList.fields.project.name + "(" + this.props.privateJiraTaskList.fields.project.key + ")",
      //   value: this.props.privateJiraTaskList.fields.project.name
      // }

      // let projectSelected = {
      //   id: 0,
      //   avatarUrls: "",
      //   name: "select Project",
      //   key: "",
      //   self: "",
      //   projectTypeKey: "",
      //   label: <div>{"     Select Project"}</div>,
      //   value: "0"
      // }

      let userselected = {
        accountId: "0",
        emailAddress: "0",
        displayName: "All Users",
        label: <div>{"     All Users"}</div>,
        value: "0",
      };

      this.setState({
        //projectSelected: projectSelected,
        userSelected: userselected,
      });
      // this.getPrivateJiraBacklogIssue(this.props.privateJiraTaskList.fields.project.key);
      this.getPrivateJiraProjectListOnPageLoad();
      this.getPrivateJiraProjectList();
    } else {
      if (this.props.lastTaskProject) {
        let projectSelected = {
          id: this.props.lastTaskProject.fields.project.id,
          avatarUrls: this.props.lastTaskProject.fields.project.avatarUrls,
          name: this.props.lastTaskProject.fields.project.name,
          key: this.props.lastTaskProject.fields.project.key,
          self: this.props.lastTaskProject.fields.project.self,
          projectTypeKey:
            this.props.lastTaskProject.fields.project.projectTypeKey,
          label:
            this.props.lastTaskProject.fields.project.name +
            "(" +
            this.props.lastTaskProject.fields.project.key +
            ")",
          value: this.props.lastTaskProject.fields.project.name,
        };

        let userselected = {
          accountId: "0",
          emailAddress: "0",
          displayName: "All Users",
          label: <div>{"     All Users"}</div>,
          value: "0",
        };

        await this.setState({
          projectSelected: projectSelected,
          userSelected: userselected,
          accessToken: this.props.accessToken,
        });
        await this.jiraBacklogIssue(this.props.lastTaskProject.fields.project);
        await this.listOfUsersForProject(
          this.props.lastTaskProject.fields.project
        );
      }
      await this.getprojectsList();
    }

  }

  async componentDidUpdate(prevProps) {
    if (prevProps.cloudId !== this.props.cloudId) {
      if (this.props.lastTaskProject) {
        let userselected = {
          accountId: "0",
          emailAddress: "0",
          displayName: "All Users",
          label: <div>{"     All Users"}</div>,
          value: "0",
        };
        await this.setState({
          loading: true,
          BacklogIssuesTaskList: [],
          BacklogIssuesInfo: null,
          jiraApiResults: [],
          projectUsersList: [],
          projectList: [],
          projectSelected: {},
          userSelected: userselected,
          searchValue: "",
        });
        this.jiraBacklogIssue(this.props.lastTaskProject.fields.project);
        this.listOfUsersForProject(this.props.lastTaskProject.fields.project);
      }
      this.getprojectsList();
    }
  }

  jiraBacklogIssue = async (newValue) => {
    this.setState({ loading: true, BacklogIssuesTaskList: [] });
    let selectedEpicStatus;

    if (this.state.epicStatusSelected.length === 0) {
      selectedEpicStatus = this.state.preSlectedstatus.map(
        (label) => label.name
      );
    } else {
      selectedEpicStatus = this.state.epicStatusSelected.map(
        (label) => label.name
      );
    }
    let selectedTaskStatus = '"' + selectedEpicStatus.join('","') + '"';
    if (selectedTaskStatus !== "") {
      try {
        //  const finalUrl = this.props.jiraIssue.self
        const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/search?jql=project='${newValue.key}'AND status IN (${selectedTaskStatus})&fields=assignee,comment,created,creator,description,issuelinks,duedate,issuetype,parent,priority,progress,project,reporter,status,subtasks,summary`;

        let headers = {
          Accept: "application/json",
          Authorization: "Bearer " + this.state.accessToken,
        };
        const { data } = await axios.get(finalUrl, { headers });
        this.setState({
          BacklogIssuesTaskList: data.issues,
          BacklogIssuesInfo: data,
          jiraApiResults: data.issues,
          selectedPageSize: 10,
          selectedPage: 0,
        });
      } catch (error) {
        if (error.response.status === 401) {
          let gettoken = await this.props.getAccessTokenAgain();
          this.setState({ accessToken: gettoken });
          this.jiraBacklogIssue(newValue);
        }
        toast.error(error);
      }
    }

    this.setState({ isActive: false, loading: false });
  };

  getprojectsList = async () => {
    try {
      let finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/project/search?expand=lead%2Curl%2Cfavourite%2Cpermissions&maxResults=50&orderBy=%2BNAME&startAt=0`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ projectList: data.values });
      if (!this.props.lastTaskProject && data.values[0]) {
        let projectSelected = {
          id: data.values[0].id,
          avatarUrls: data.values[0].avatarUrls,
          name: data.values[0].name,
          key: data.values[0].key,
          self: data.values[0].self,
          projectTypeKey: data.values[0].projectTypeKey,
          label: data.values[0].name + "(" + data.values[0].key + ")",
          value: data.values[0].name,
        };
        let userselected = {
          accountId: "0",
          emailAddress: "0",
          displayName: "All Users",
          label: <div>{"     All Users"}</div>,
          value: "0",
        };
        await this.setState({
          projectSelected: projectSelected,
          userSelected: userselected,
          accessToken: this.props.accessToken,
        });
        await this.jiraBacklogIssue(data.values[0]);
        await this.listOfUsersForProject(data.values[0]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken });
        this.getprojectsList();
      }
      toast.error(error);
    }
  };

  onSelectprojects = async (newValue) => {
    await this.setState({ projectSelected: newValue });
    if (this.props.isPrivateJiraSelected) {
      this.getPrivateJiraBacklogIssue(newValue.key);
      //this.getPrivateJiraProjectList();
      this.getPrivateJiraUsersList(newValue.key); //because users list to be filled at page load only as all Projects have the same users. can be committed if you dont want to call api after every selection of project.
    } else {
      this.jiraBacklogIssue(newValue);
      this.listOfUsersForProject(newValue);
    }
  };

  onClickIssue = async (list) => {
    if (this.state.isPrivateJiraSelected) {
      this.setState({ jiraIssue: list, isPrivateJiraIssue: true });
      await this.getJiraTaskLogDetailsBacklog(list.id);
    } else {
      await this.setState({ jiraIssue: list, isJiraIssue: true });
      await this.getJiraTaskLogDetailsBacklog(list.id);
    }
  };

  toggleJiraIssue = () => {
    this.setState({ isJiraIssue: false });
  };

  togglePrivateJiraIssue = () => {
    this.setState({ isPrivateJiraIssue: false });
  };

  search = (searchTerm, data) => {
    let results = [];
    if (data) {
      results = data.filter(function (object) {
        return (
          object.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
          object.fields.summary.toLowerCase().includes(searchTerm.toLowerCase())
          // object.fields.assignee?object.fields.assignee.displayName.toLowerCase().includes(searchTerm.toLowerCase()):null
        );
      });
      return results;
    }
  };

  searchChange = (e) => {
    this.setState({ searchValue: e, BacklogIssuesTaskList: [] });
    let data = this.state.jiraApiResults;
    if (this.state.userSelected.accountId !== "0") {
      if (this.state.userSelected.accountId === "-1") {
        data = data.filter((task) => task.fields.assignee === null);
      } else {
        data = data.filter(
          (task) =>
            task.fields.assignee &&
            task.fields.assignee.accountId === this.state.userSelected.accountId
        );
      }
    }
    let searchResults = this.search(e, data);
    this.setState({ BacklogIssuesTaskList: searchResults });
  };

  listOfUsersForProject = async (newValue) => {
    try {
      let finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/user/assignable/search?project=${newValue.key}`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ projectUsersList: data });
    } catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken });
        this.listOfUsersForProject(newValue);
      }
      toast.error(error);
    }
  };

  onSelectUsers = async (newValue) => {
    //console.log("User sel : ",newValue);
    this.setState({ userSelected: newValue, BacklogIssuesTaskList: [] });
    let backlogIssue = this.state.jiraApiResults;
    let results;
    if (this.props.isPrivateJiraSelected) {
      if (newValue.emailAddress === "-1") {
        results = backlogIssue.filter((task) => task.fields.assignee === null);
      } else if (newValue.emailAddress === "0") {
        results = backlogIssue;
      } else {
        results = backlogIssue.filter(
          (task) =>
            task.fields.assignee &&
            task.fields.assignee.emailAddress === newValue.emailAddress
        );
      }
      if (this.state.searchValue !== "") {
        results = this.search(this.state.searchValue, results);
      }

      this.setState({ BacklogIssuesTaskList: results });
    } else {
      if (newValue.accountId === "-1") {
        results = backlogIssue.filter((task) => task.fields.assignee === null);
      } else if (newValue.accountId === "0") {
        results = backlogIssue;
      } else {
        results = backlogIssue.filter(
          (task) =>
            task.fields.assignee &&
            task.fields.assignee.accountId === newValue.accountId
        );
      }

      if (this.state.searchValue !== "") {
        results = this.search(this.state.searchValue, results);
      }
      this.setState({ BacklogIssuesTaskList: results });
    }
  };

  getPrivateJiraProjectListOnPageLoad = async () => {
    const { API_HOST, getPrivateJiraProjectList } = this.props;
    let requestBody = {
      DomainName: this.props.jiraDomainSelected,
    };

    await getPrivateJiraProjectList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({ projectList: res });

        let projectSelected = {
          id: res[0].id,
          avatarUrls: res[0].avatarUrls,
          name: res[0].name,
          key: res[0].key,
          self: res[0].self,
          projectTypeKey: res[0].projectTypeKey,
          label: res[0].name + "(" + res[0].key + ")",
          value: res[0].name,
        };

        this.setState({ projectSelected: projectSelected });

        this.getPrivateJiraUsersList(this.state.projectList[0].key); //[4]
        this.getPrivateJiraBacklogIssue(this.state.projectList[0].key);
      }
    });
  };

  getJiraTaskLogDetailsBacklog = async (jiraId) => {
    const { API_HOST, getJiraTaskLogDetails } = this.props;
    await getJiraTaskLogDetails(API_HOST, jiraId, (res) => {
      //alert(JSON.stringify(res));
      if (res[0] !== null) {
        this.setState({ jiraTaskLogDetailsBacklog: res });
        // alert(JSON.stringify(this.state.jiraTaskLogDetailsBacklog));
      } else {
        this.setState({ jiraTaskLogDetailsBacklog: [] });
      }
    });
  };

  getPrivateJiraBacklogIssue = (projectKey) => {
    this.setState({ BacklogIssuesTaskList: [] });
    const { API_HOST, getPrivateJiraTaskList } = this.props;
    let selectedEpicStatus;

    if (this.state.epicStatusSelected.length === 0) {
      selectedEpicStatus = this.state.preSlectedstatus.map(
        (label) => label.name
      );
    } else {
      selectedEpicStatus = this.state.epicStatusSelected.map(
        (label) => label.name
      );
    }
    if (selectedEpicStatus.length !== 0) {
      this.setState({ loading: true });
      let requestBody = {
        ProjectKey: projectKey,
        DomainName: this.props.jiraDomainSelected,
        TaskStatusList: selectedEpicStatus,
      };

      getPrivateJiraTaskList(API_HOST, requestBody, (res) => {
        if (res) {
          this.setState({
            BacklogIssuesTaskList: res,
            BacklogIssuesInfo: res,
            jiraApiResults: res,
            selectedPageSize: 10,
            selectedPage: 0,
            loading: false,
          });
        }
      });
    }
  };

  getPrivateJiraProjectList = async () => {
    const { API_HOST, getPrivateJiraProjectList } = this.props;
    let requestBody = {
      DomainName: this.props.jiraDomainSelected,
    };

    await getPrivateJiraProjectList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({ projectList: res });

        let projectSelected = {
          id: res[0].id,
          avatarUrls: res[0].avatarUrls,
          name: res[0].name,
          key: res[0].key,
          self: res[0].self,
          projectTypeKey: res[0].projectTypeKey,
          label: res[0].name + "(" + res[0].key + ")",
          value: res[0].name,
        };

        this.setState({
          projectSelected: projectSelected,
          // userSelected: userselected
        });

        //this.getPrivateJiraUsersList(this.state.projectSelected.key);

        this.getPrivateJiraUsersList(this.state.projectList[0].key); //[4]
      }
    });
  };

  getPrivateJiraUsersList = async (projectKey) => {
    const { API_HOST, getPrivateJiraUsersList } = this.props;

    let requestBody = {
      ProjectKey: projectKey,
      DomainName: this.props.jiraDomainSelected,
    };

    await getPrivateJiraUsersList(API_HOST, requestBody, (res) => {
      console.log("Res: ", res.error);
      if (res) {
        if (res.error === true) {
          this.setState({ showUserMessage: true }); //if userlist not response obtained.
        } else {
          this.setState({ projectUsersList: res, showUserMessage: false }); //if userlist response obtained.
        }
      }
    });
  };

  onSelect = async (selectedList, selectedItem) => {
    await this.setState({ epicStatusSelected: selectedList });

    if (this.state.isPrivateJiraSelected) {
      this.getPrivateJiraBacklogIssue(this.state.projectSelected.key);
      this.getPrivateJiraUsersList(this.state.projectList[0].key);
    } else {
      this.jiraBacklogIssue(this.state.projectSelected);
      this.listOfUsersForProject(this.state.projectSelected);
    }
  };

  onRemove = async (selectedList, removedItem) => {
    await this.setState({ epicStatusSelected: selectedList });
    if (this.state.epicStatusSelected.length === 0) {
      await this.setState({
        preSlectedstatus: [],
      });
    }
    if (this.state.isPrivateJiraSelected) {
      this.getPrivateJiraBacklogIssue(this.state.projectSelected.key);
      this.getPrivateJiraUsersList(this.state.projectList[0].key);
    } else {
      this.jiraBacklogIssue(this.state.projectSelected);
      this.listOfUsersForProject(this.state.projectSelected);
    }
  };

  render() {
    let userOptions = [];
    userOptions.push({
      accountId: "0",
      emailAddress: "0",
      displayName: "All Users",
      label: <div>{"     All Users"}</div>,
      value: "0",
    });
    userOptions.push({
      accountId: "-1",
      emailAddress: "-1",
      displayName: "Unassigned",
      label: (
        <div>
          <img src={UnassignedUser} alt="" height="20px" width="20px" />
          {"     Unassigned"}
        </div>
      ),
      value: "-1",
    });

    console.log("projsel..... : ", this.state.projectList);

    this.state.projectUsersList[0] &&
      this.state.projectUsersList.map((obj) => {
        userOptions.push({
          accountId: obj.accountId,
          accountType: obj.accountType,
          emailAddress: obj.emailAddress,
          active: obj.active,
          displayName: obj.displayName,
          label: (
            <div>
              <img
                src={obj.avatarUrls["16x16"]}
                alt=""
                height="20px"
                width="20px"
              />
              {"     " + obj.displayName}
            </div>
          ),
          value: obj.displayName,
        });
        return userOptions;
      });

    let projectlistOptions = [];
    this.state.projectList[0] &&
      this.state.projectList.map((obj) => {
        projectlistOptions.push({
          id: obj.id,
          avatarUrls: obj.avatarUrls,
          name: obj.name,
          key: obj.key,
          self: obj.self,
          projectTypeKey: obj.projectTypeKey,
          label: obj.name + "(" + obj.key + ")",
          value: obj.name,
        });
        return projectlistOptions;
      });

    const columns = [
      // {
      //     Header: (<input
      //         type="checkbox"
      //         className="checkbox-unreadlist"
      //         style={{ width: '16px', height: '16px' }}
      //         onChange={this.handleChange}
      //         checked={selectAll}
      //     />),
      //     accessor: 'Id_User',
      //     minWidth: 30,
      //     sortable: false,
      //     Cell: row => {
      //         if (row.original.Id_User) {
      //             return (
      //                 <input
      //                     type="checkbox"
      //                     className="checkbox-unreadlist"
      //                     // defaultChecked={this.state.selectedUsers.find((user) => user.Id_User === row.original.Id_User) ? true : false}
      //                     // checked={this.state.selectedUsers.find((user) => user.Id_User === row.original.Id_User) ? true : false}
      //                     //onChange={(e) => this.handleSingleCheckboxChange(e, row.original.Id_User, row.original)}
      //                     defaultChecked={this.state.checked[row.index]}
      //                     checked={this.state.checked[row.index]}
      //                     onChange={(e) => this.handleSingleCheckboxChange(e, row.index, row.original, row.original.Id_User)}
      //                     style={{ width: '16px', height: '16px' }}
      //                 />
      //             )
      //         }
      //     }
      // },
      {
        // Header: <strong className="my_custom_class"></strong>,
        accessor: "key",
        minWidth: 10,
        Cell: (row) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickIssue(row.original);
              }}
            >
              {row.original.key}
            </div>
          );
        },
        //filterable: true,
        //sortable: true,
      },
      {
        // Header: <strong className="my_custom_class">Workgroup Name</strong>,
        accessor: "fields",
        Cell: (row) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickIssue(row.original);
              }}
            >
              {row.original.fields.summary}
            </div>
          );
        },
        //filterable: true,
        //sortable: true,
      },
      {
        // Header: <strong className="my_custom_class"></strong>,
        accessor: "status",
        minWidth: 10,
        Cell: (row) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickIssue(row.original);
              }}
            >
              {row.original.fields.status.name}
            </div>
          );
        },
      },
      {
        // Header: <strong className="my_custom_class"></strong>,
        accessor: "assignee",
        minWidth: 25,
        Cell: (row) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickIssue(row.original);
              }}
            >
              {row.original.fields.assignee ? (
                <img
                  src={row.original.fields.assignee.avatarUrls["16x16"]}
                  alt=""
                  height="25px"
                  width="25px"
                />
              ) : null}
              {row.original.fields.assignee ? (
                "     " + row.original.fields.assignee.displayName
              ) : (
                <>
                  <img src={UnassignedUser} alt="" height="25px" width="25px" />{" "}
                  Unassigned
                </>
              )}
            </div>
          );
        },
      },
    ];
    const statusOptions = [{ name: "To Do" }, { name: "In Progress" }];
    // let pageSize=this.state.BacklogIssuesInfo?this.state.BacklogIssuesInfo.total/10:1;
    return (
      // <Modal isOpen={this.props.isBacklogIssues} size={'xl'} backdrop={true}  >
      //   <ModalHeader toggle={() => this.props.toggleBacklogIssues()}>
      //     Backlogs
      //   </ModalHeader>
      //   <ModalBody>

      <div className="d-flex">
        <div className="jira-task-list">
          <FormGroup>
            <h6>Project: </h6>
            <Row style={{ justifyContent: "space-between" }}>
              <Col xs="3">
                <Select
                  components={makeAnimated}
                  onChange={(newValue) => {
                    this.onSelectprojects(newValue);
                  }}
                  options={projectlistOptions}
                  value={this.state.projectSelected}
                />
              </Col>
              <Col xs="2">
                <Select
                  components={makeAnimated}
                  onChange={(newValue) => {
                    this.onSelectUsers(newValue);
                  }}
                  options={userOptions}
                  value={this.state.userSelected}
                  isDisabled={this.state.showUserMessage}
                />
                {this.state.showUserMessage && (
                  <small style={{ color: "red" }}>
                    You are not Authorized to access users list for this
                    Project.
                  </small>
                )}
              </Col>
              <Col xs="4">
                <Multiselect
                  options={statusOptions} // Options to display in the dropdown
                  onSelect={this.onSelect} // Function will trigger on select event
                  onRemove={this.onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  showCheckbox
                  selectedValues={this.state.preSlectedstatus}
                  hidePlaceholder
                  avoidHighlightFirstOption
                />
              </Col>
              <Col xs="3">
                <div className="wrapper">
                  <div className="search-box">
                    <span className="form-control-feedback">
                      <img src="images/search-icon.png" alt="" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ minWidth: "100px" }}
                      onChange={(e) => {
                        this.searchChange(e.target.value);
                      }}
                      value={this.state.searchValue}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <ReactTable
              data={this.state.BacklogIssuesTaskList}
              columns={columns}
              defaultPageSize={10}
              pageSize={this.state.selectedPageSize}
              showPagination={true}
              className="-striped -highlight"
              loading={this.state.loading}
              showPageSizeOptions={true}
              page={this.state.selectedPage}
              showPageJump={false}
              pageSizeOptions={this.state.pageSizeOptions}
              onPageSizeChange={(pageSize, pageIndex) => {
                this.setState({
                  selectedPageSize: pageSize,
                  selectedPage: pageIndex,
                });
              }}
              onPageChange={(pageIndex) => {
                this.setState({ selectedPage: pageIndex });
              }}
              style={{
                height: "calc(100vh - 235px)", // This will force the table body to overflow and scroll, since there is not enough room
              }}
              // TbodyComponent={CustomTbodyComponent}
            />
          </FormGroup>
          {/* <h5>{this.props.jiraIssue.fields.summary}</h5>
             <h6>Description</h6>
             <div>{this.props.jiraIssue.fields.description}</div> */}
        </div>

        {this.state.isJiraIssue ? (
          <JiraIssue
            getJiraTaskLogDetails={this.getJiraTaskLogDetailsBacklog}
            jiraTaskLogDetails={this.state.jiraTaskLogDetailsBacklog}
            isJiraIssue={this.state.isJiraIssue}
            toggleJiraIssue={this.toggleJiraIssue}
            jiraIssue={this.state.jiraIssue}
            cloudId={this.props.cloudId}
            accessToken={this.state.accessToken}
            getJiraYourTaskList={this.props.getJiraYourTaskList}
            jiraBacklogIssue={this.jiraBacklogIssue}
            isBacklogIssues={this.props.isBacklogIssues}
          />
        ) : null}

        {this.state.isPrivateJiraSelected && this.state.isPrivateJiraIssue ? (
          <PrivateJiraIssue
            showUserMessage={this.state.showUserMessage}
            getJiraTaskLogDetails={this.getJiraTaskLogDetailsBacklog}
            jiraTaskLogDetails={this.state.jiraTaskLogDetailsBacklog}
            jiraDomainSelected={this.props.jiraDomainSelected}
            isPrivateJiraSelected={this.props.isPrivateJiraSelected}
            togglePrivateJiraIssue={this.togglePrivateJiraIssue}
            jiraIssue={this.state.jiraIssue}
          />
        ) : null}
      </div>
      //   {/* </ModalBody>
      // </Modal> */}
    );
  }
}

// Prop types of props.
BacklogIssues.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
BacklogIssues.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
  };
};
export default connect(mapStateToProps, {
  getJiraTaskLogDetails,
  getPrivateJiraTaskList,
  getPrivateJiraProjectList,
  getPrivateJiraUsersList,
})(BacklogIssues);
