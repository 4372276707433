import { types as T } from "../actions/action";
import { defaultStore } from "../store";

const multipleEmailReducer = (state = defaultStore, action) => {
  switch (action.type) {
    
    case T.RECEIVE_MULTIPLE_EMAIL:
      return {...state, 
        fetchMultipleEmail: {
          ...state.fetchMultipleEmail,
          loading: false,
          error: false,
          errorMessage: null,
          multipleEmailResponse: action.multipleEmailResponse,
        },
      };

      //Multiple workspace Login
      
    case T.RECEIVE_MULTI_WORKSPACE_LOGIN:
      return {...state, 
        fetchMultipleWorkspace: {
          ...state.fetchMultipleWorkspace,
          loading: false,
          error: false,
          errorMessage: null,
          multipleWorkspaceResponse: action.multipleWorkspaceResponse,
        },
      };

    default:
      return state
  }
};

export default multipleEmailReducer;
