import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

// Sent Sticky List
export const receiveUnreadSentStickyList = (unreadSentStickyListResponse) => ({
    type: T.RECEIVE_UNREAD_SENT_STICKY_LIST,
    unreadSentStickyListResponse,
});

/**
 * Sent Sticky List
 *
 * @param {url} URL is API host domain.
 * @param {senderId} is cabin id.
 * @return {array} return sent sticky notes detail
 */
 export const unreadSentStickyList = (url, senderId) => (dispatch) => {
    const finalUrl = `${url}Userstickynote/getunreaduserstickynotelistsenderuseridwisetosendmail/0`;
    //return trackPromise(
        axios
            .post(finalUrl, {}, getConfig())
            .then((response) => handleResponse(response))
            .then((result) => {
                dispatch(receiveUnreadSentStickyList(result));
            })
            .catch((error) =>
                handleError(error, "Could not get unread sent sticky notes")
            )
    //);
};

//Send UnRead User Sticky Note List in Mail
export const receiveSendUnReadSentStickyListAsMail = (sendUnReadSentStickyListAsMailResponse) => ({
    type: T.RECEIVE_SEND_UNREAD_SENT_STICKY_LIST_AS_MAIL,
    sendUnReadSentStickyListAsMailResponse,
  
  });
  
  /**
   * //Send UnRead User Sticky Note List in Mail
   *
   * @param {url} URL is API host domain.
   * 
  */
  export const sendUnReadSentStickyListAsMail = (url, requestBody) => dispatch => {
  
    const finalUrl = `${url}Userstickynote/SendUnReadUserStickyNoteListinMail`;
    return trackPromise(axios.post(finalUrl, requestBody, getConfig())
      .then(response =>
        handleResponse(response)
      )
      .then(result => {
        // if (checkClientErrorStatus(result)) {
        //   handleError(result, 'Failed to send mail')
        // }
        // else {
          dispatch(receiveSendUnReadSentStickyListAsMail(result));
        //}
      }
      )
      .catch(error =>
        handleError(error, 'Failed to send Mail')
      ));
  }