import React, {  Component } from 'react';
import { Button, Modal,  ModalBody, ModalHeader,ModalFooter} from 'reactstrap';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';

class CreateDepartmentPopup extends Component {
  
  state = {
    fields: {},   
  };


  render() {
    const {
      isCreateDepartmentPopup
    } = this.props;


    return (
      <Modal isOpen={isCreateDepartmentPopup} backdrop={true}  >
          <ModalHeader toggle={this.onClose}></ModalHeader>
        <ModalBody>
            Do you want to assign users to this new department?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={()=>this.props.closeCreateDepartmentPopup(true)}>Yes</Button>
              <Button color="secondary" onClick={()=>this.props.closeCreateDepartmentPopup(false)}>No</Button>
            </ModalFooter>
      </Modal>
    );
  }
}

CreateDepartmentPopup.defaultProps = {
  isOpen: false,
  toggle: () => {},
};

CreateDepartmentPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func,
};

export default hot(module)(CreateDepartmentPopup);
