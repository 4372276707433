
import React from 'react';
import { Button} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from '../../../utils/apiUrls';
import { getZoomConfig } from '../../../actions/zoomActions';
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';
import axios from 'axios';
import { da } from 'date-fns/locale';

class ZoomConfiguration extends React.Component {
  state = {
    zoomClientId: "",
    zoomClientSecret: "",
    zoomClientScope: "",
    isNewRefreshTokenGenerate: false,
    loading: true,
  };

  componentDidMount = () => {
    this.getZoomConfig();
  }

  getZoomConfig = async() => {
    const { API_HOST, getZoomConfig } = this.props;
    await getZoomConfig(API_HOST, (res) => {
       console.log("ZOOM res",res)
      if (res[0]) {
        this.setState({ 
          zoomClientId: res[0].ZoomClientId,
          zoomClientSecret: res[0].ZoomClientSecretkey, 
         // zoomClientScope: res[0].zoomClientScope, 
          loading: false 
        })
        //////////////  this.getZoomAccessToken();
      }
    }); 
  }


  getZoomAccessToken = async() => {
    try {

      // let finalUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${this.state.zoomClientId}&redirect_uri=${window.location.protocol}//${window.location.host}/zoomimporttoken`;

      ///  window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=${this.state.zoomClientId}&redirect_uri=${window.location.protocol}//${window.location.host}/zoomimporttoken`;

      //  window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=${this.state.zoomClientId}&redirect_uri=${window.location.protocol}//${window.location.host}`;
      
      //window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=S_MZ7bGBSui1GEi6MVtdyg&redirect_uri=${window.location.protocol}//${window.location.host}/zoomimporttoken`;

      window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=hPuyKeUkR_yQt4O2lU3lmg&redirect_uri=http://localhost:3000/zoomimporttoken`;

      //  const data = await axios.post(finalUrl);
      //  console.log("Zoom Data: ",data );
    }
    catch (error) {
      toast.error(error)
      this.setState({ loading: false })
    }
}
  

  render() {
    const { isOpen } = this.props;

    return (
      
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text='Loading...'
      >
        <center>
          { this.state.zoomClientId !== "" ?
          // <Button className=" col-sm-6 btn btn-primary mt-3 btn-lg" onClick={() => { this.setNewRefreshtoken() }} disabled={this.state.isNewRefreshTokenGenerate}>{JSON.parse(localStorage.getItem('userdetail')).Google_Refresh_Token === "" ? "Connect To google":"Regenerate Your Google Token"}</Button>
          <Button className=" col-sm-6 btn btn-primary mt-3 btn-lg" onClick={() => { this.getZoomAccessToken() }} disabled={this.state.isNewRefreshTokenGenerate}>Connect to Zoom</Button>
          // <a target="_self" href="https://zoom.us/oauth/authorize?response_type=code&client_id=hPuyKeUkR_yQt4O2lU3lmg&redirect_uri=http://localhost:3000" target="_blank" rel="noopener noreferrer"><img src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png" height="32" alt="Add to ZOOM" /></a>
          : <div><h1>Sorry you are unable to connect with Zoom</h1>
          <h4> {JSON.parse(localStorage.getItem('userdetail')).Is_Admin ? "You have to Add Zoom client configuration first" : "Your workspace is not connected with Zoom.Please inform your admin to connect with Zoom"}
          </h4>
          </div>
          }
      </center>
        {this.state.isNewRefreshTokenGenerate ?
          <div>Regenerate</div>
          : null}
      </LoadingOverlay>


      // <div>Hello</div>
     
    );
  }
}

// Prop types of props.
ZoomConfiguration.propTypes = {
  API_HOST: PropTypes.string,
  slackconfig: PropTypes.func,
  slackconfigResponse: PropTypes.object,
  slackConfigListResponse: PropTypes.any
};

// Set default props..
ZoomConfiguration.defaultProps = {
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),
});


export default connect(mapStateToProps, { getZoomConfig })(ZoomConfiguration);
