export const types = {

    //Dashboard Types
    REQUEST_SLACK_TEAM: 'REQUEST_SLACK_TEAM',
    RECEIVE_SLACK_TEAM: 'RECEIVE_SLACK_TEAM',
    RECEIVE_SLACK_TEAM_FAILURE: 'RECEIVE_SLACK_TEAM_FAILURE',

    REQUEST_SLACK_MEMBER: 'REQUEST_SLACK_MEMBER',
    RECEIVE_SLACK_MEMBER: 'RECEIVE_SLACK_MEMBER',
    RECEIVE_SLACK_MEMBER_FAILURE: 'RECEIVE_SLACK_MEMBER_FAILURE',

    REQUEST_SLACK_USER: 'REQUEST_SLACK_USER',
    RECEIVE_SLACK_USER: 'RECEIVE_SLACK_USER',
    RECEIVE_SLACK_USER_FAILURE: 'RECEIVE_SLACK_USER_FAILURE',

    REQUEST_LOGOUT: 'REQUEST_LOGOUT',
    RECEIVE_LOGOUT: 'RECEIVE_LOGOUT',
    RECEIVE_LOGOUT_FAILURE: 'RECEIVE_LOGOUT_FAILURE',

    REQUEST_GET_USER_DETAIL: 'REQUEST_GET_USER_DETAIL',
    RECEIVE_GET_USER_DETAIL: 'RECEIVE_GET_USER_DETAIL',
    RECEIVE_GET_USER_DETAIL_FAILURE: 'RECEIVE_GET_USER_DETAIL_FAILURE',


    REQUEST_IMPORT_USER_LIST: 'REQUEST_IMPORT_USER_LIST',
    RECEIVE_IMPORT_USER_LIST: 'RECEIVE_IMPORT_USER_LIST',
    RECEIVE_IMPORT_USER_LIST_FAILURE: 'RECEIVE_IMPORT_USER_LIST_FAILURE',

    REQUEST_GET_TENANT_DETAIL: 'REQUEST_GET_TENANT_DETAIL',
    RECEIVE_GET_TENANT_DETAIL: 'RECEIVE_GET_TENANT_DETAIL',
    RECEIVE_GET_TENANT_DETAIL_FAILURE: 'RECEIVE_GET_TENANT_DETAIL_FAILURE',

    REQUEST_UPDATE_TENANT: 'REQUEST_UPDATE_TENANT',
    RECEIVE_UPDATE_TENANT: 'RECEIVE_UPDATE_TENANT',
    RECEIVE_UPDATE_TENANT_FAILURE: 'RECEIVE_UPDATE_TENANT_FAILURE',

    REQUEST_UPDATE_USER: 'REQUEST_UPDATE_USER',
    RECEIVE_UPDATE_USER: 'RECEIVE_UPDATE_USER',
    RECEIVE_UPDATE_USER_FAILURE: 'RECEIVE_UPDATE_USER_FAILURE',

    REQUEST_UPDATE_PROFILE_PIC: 'REQUEST_UPDATE_USER',
    RECEIVE_UPDATE_PROFILE_PIC: 'RECEIVE_UPDATE_USER',
    RECEIVE_UPDATE_PROFILE_PIC_FAILURE: 'RECEIVE_UPDATE_USER_FAILURE',

    //Login Types
    REQUEST_LOGIN: 'REQUEST_LOGIN',
    RECEIVE_LOGIN: 'RECEIVE_LOGIN',
    RECEIVE_LOGIN_FAILURE: 'RECEIVE_LOGIN_FAILURE',

    //Sign Up Types
    REQUEST_SIGNUP: 'REQUEST_SIGNUP',
    RECEIVE_SIGNUP: 'RECEIVE_SIGNUP',
    RECEIVE_SIGNUP_FAILURE: 'RECEIVE_SIGNUP_FAILURE',

    //Multiple Email Types
    REQUEST_MULTIPLE_EMAIL: 'REQUEST_MULTIPLE_EMAIL',
    RECEIVE_MULTIPLE_EMAIL: 'RECEIVE_MULTIPLE_EMAIL',
    RECEIVE_MULTIPLE_EMAIL_FAILURE: 'RECEIVE_MULTIPLE_EMAIL_FAILURE',

    REQUEST_MULTI_WORKSPACE_LOGIN: 'REQUEST_MULTI_WORKSPACE_LOGIN',
    RECEIVE_MULTI_WORKSPACE_LOGIN: 'RECEIVE_MULTI_WORKSPACE_LOGIN',
    RECEIVE_MULTI_WORKSPACE_LOGIN_FAILURE: 'RECEIVE_MULTI_WORKSPACE_LOGIN_FAILURE',

    //Office Types
    REQUEST_CREATE_OFFICE: 'REQUEST_CREATE_OFFICE',
    RECEIVE_CREATE_OFFICE: 'RECEIVE_CREATE_OFFICE',
    RECEIVE_CREATE_OFFICE_FAILURE: 'RECEIVE_CREATE_OFFICE_FAILURE',

    //Fetch Offices
    REQUEST_FETCH_OFFICE: 'REQUEST_FETCH_OFFICE',
    RECEIVE_FETCH_OFFICE: 'RECEIVE_FETCH_OFFICE',
    RECEIVE_FETCH_OFFICE_FAILURE: 'RECEIVE_FETCH_OFFICE_FAILURE',

    //Slack Config Types
    REQUEST_SLACK_CONFIG: 'REQUEST_SLACK_CONFIG',
    RECEIVE_SLACK_CONFIG: 'RECEIVE_SLACK_CONFIG',
    RECEIVE_SLACK_CONFIG_FAILURE: 'RECEIVE_SLACK_CONFIG_FAILURE',

    //Forgot password
    REQUEST_FORGOT_PASSWORD: 'REQUEST_FORGOT_PASSWORD',
    RECEIVE_FORGOT_PASSWORD: 'RECEIVE_FORGOT_PASSWORD',
    RECEIVE_FORGOT_PASSWORD_FAILURE: 'RECEIVE_FORGOT_PASSWORD_FAILURE',

    //Change Password
    REQUEST_CHANGE_PASSWORD: 'REQUEST_CHANGE_PASSWORD',
    RECEIVE_CHANGE_PASSWORD: 'RECEIVE_CHANGE_PASSWORD',
    RECEIVE_CHANGE_PASSWORD_FAILURE: 'RECEIVE_CHANGE_PASSWORD_FAILURE',

    //Reset password
    REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
    RECEIVE_RESET_PASSWORD: 'RECEIVE_RESET_PASSWORD',
    RECEIVE_RESET_PASSWORD_FAILURE: 'RECEIVE_RESET_PASSWORD_FAILURE',

    //Valid Activation key
    REQUEST_VALID_ACTIVATION_KEY: 'REQUEST_VALID_ACTIVATION_KEY',
    RECEIVE_VALID_ACTIVATION_KEY: 'RECEIVE_VALID_ACTIVATION_KEY',
    RECEIVE_VALID_ACTIVATION_KEY_FAILURE: 'RECEIVE_VALID_ACTIVATION_KEY_FAILURE',

    //Get Cabin List
    //REQUEST_GET_CABIN_LIST: 'REQUEST_GET_CABIN_LIST',
    RECEIVE_GET_CABIN_LIST: 'RECEIVE_GET_CABIN_LIST',
    //RECEIVE_GET_CABIN_LIST_FAILURE: 'RECEIVE_GET_CABIN_LIST_FAILURE',


    //Edit Cabin Name
    REQUEST_EDIT_CABIN_NAME: 'REQUEST_EDIT_CABIN_NAME',
    RECEIVE_EDIT_CABIN_NAME: 'RECEIVE_EDIT_CABIN_NAME',
    RECEIVE_EDIT_CABIN_NAME_FAILURE: 'RECEIVE_EDIT_CABIN_NAME_FAILURE',

    //Edit Department Name
    RECEIVE_EDIT_DEPT_NAME: 'RECEIVE_EDIT_DEPT_NAME',

    //Save Cabin User Details
    REQUEST_CABIN_USER_DETAIL: 'REQUEST_CABIN_USER_DETAIL',


    //Get Cabin User Detail
    RECEIVE_GET_CABIN_USER_DETAIL: 'RECEIVE_GET_CABIN_USER_DETAIL',

    //Get Logged in user Cabin \ Detail
    RECEIVE_GET_LOGGED_IN_USER_CABIN_DETAIL: 'RECEIVE_GET_LOGGED_IN_USER_CABIN_DETAIL',

    //Save Knock
    RECEIVE_SAVE_USER_KNOCK: 'RECEIVE_SAVE_USER_KNOCK',

    //Door Open
    RECEIVE_DOOR_OPEN: 'RECEIVE_DOOR_OPEN',

    //Door Close
    RECEIVE_DOOR_CLOSE: 'RECEIVE_DOOR_CLOSE',

    //sticky message send
    RECEIVE_STICKY_MESSAGE_SEND: 'RECEIVE_STICKY_MESSAGE_SEND',

    //Send User Knock list receiver cabin wise
    RECEIVE_USER_KNOCK_LIST_CABIN_WISE: 'RECEIVE_USER_KNOCK_LIST_CABIN_WISE',

    //Sticky Count
    RECEIVE_STICKY_COUNT: 'RECEIVE_STICKY_COUNT',

    //Sticky Notes Received
    RECEIVE_GET_USER_STICKY_LIST: 'RECEIVE_GET_USER_STICKY_LIST',

    //set read user sticky notes
    RECEIVE_SET_READ_USER_STICKY_NOTE: 'RECEIVE_SET_READ_USER_STICKY_NOTE',

    //add sticky notes to TO DO List
    RECEIVE_ADD_STICKY_NOTES_TO_TODO: 'RECEIVE_ADD_STICKY_NOTES_TO_TODO',

    //Get Departments
    RECEIVE_GET_DEPARTMENTS: 'RECEIVE_GET_DEPARTMENTS',

    //Add Department
    RECEIVE_ADD_DEPARTMENT: 'RECEIVE_ADD_DEPARTMENT',

    RECEIVE_SEARCH_USER_OFFICE: 'RECEIVE_SEARCH_USER_OFFICE',

    //User Knock Seen
    RECEIVE_USER_KNOCK_SEEN: 'RECEIVE_USER_KNOCK_SEEN',

    //User Sticky Seen
    RECEIVE_USER_STICKY_SEEN: 'RECEIVE_USER_STICKY_SEEN',

    //Sent Sticky List
    RECEIVE_UNREAD_SENT_STICKY_LIST: 'RECEIVE_UNREAD_SENT_STICKY_LIST',

    //Sticky Notes Received Cabin wise
    RECEIVE_GET_CABIN_USER_STICKY_LIST: 'RECEIVE_GET_CABIN_USER_STICKY_LIST',

    //Received working Hours List
    RECEIVE_USER_AVAILABILITY_LIST: 'RECEIVE_USER_AVAILABILITY_LIST',

    //Send UnRead User Sticky Note List in Mail
    RECEIVE_SEND_UNREAD_SENT_STICKY_LIST_AS_MAIL: 'RECEIVE_SEND_UNREAD_SENT_STICKY_LIST_AS_MAIL',

    //User To Do list
    RECEIVE_USER_TODO_LIST: 'RECEIVE_USER_TODO_LIST',

    //Get Department Filter Wise
    RECEIVE_DEPARTMENT_FILTERWISE: 'RECEIVE_DEPARTMENT_FILTERWISE',

    //Get Department Filter Wise
    RECEIVE_USER_LIST_DEPARTMENT_FILTER: 'RECEIVE_USER_LIST_DEPARTMENT_FILTER',

    // Get Dept List by user
    RECEIVE_DEPARTMENT_LIST_BY_USER: 'RECEIVE_DEPARTMENT_LIST_BY_USER',

    // Get User List by Dept
    RECEIVE_USER_LIST_BY_DEPARTMENT: 'RECEIVE_USER_LIST_BY_DEPARTMENT',

    // Get User List by Dept
    RECEIVE_USER_LIST_BY_DEPARTMENT_WITH_SELECTED_HRS: 'RECEIVE_USER_LIST_BY_DEPARTMENT_WITH_SELECTED_HRS',

    //Get Slack Configuration List
    RECEIVE_SLACK_CONFIG_LIST: 'RECEIVE_SLACK_CONFIG_LIST',

    //Get Slack Configuration List Login
    RECEIVE_SLACK_CONFIG_LIST_LOGIN: 'RECEIVE_SLACK_CONFIG_LIST_LOGIN',

    //Get Meeting List
    RECEIVE_MEETING_LIST: 'RECEIVE_MEETING_LIST',
    //Get User related Slack Details
    RECEIVE_SLACK_USER_DETAILS: 'RECEIVE_SLACK_USER_DETAILS',

    //Get Channel wise Slack Users Details
    RECEIVE_CHANNEL_WISE_SLACK_USER_DETAILS: 'RECEIVE_CHANNEL_WISE_SLACK_USER_DETAILS',

    //Add Virtual Chat Group to Slack Team List
    RECEIVE_ADD_VIRTUAL_CHAT_GROUP: 'RECEIVE_ADD_VIRTUAL_CHAT_GROUP',

    //Get Tenant Log
    GET_TENANT_LOG: 'GET_TENANT_LOG',

    //Get Meeting Log
    GET_MEETING_LOG: 'GET_MEETING_LOG',

    //Get Meeting Log Detail
    GET_MEETING_LOG_DETAIL: 'GET_MEETING_LOG_DETAIL',

    //Get Feedback Log
    GET_FEEDBACK_LOG: 'GET_FEEDBACK_LOG',

    //Get ContactUs Log
    GET_CONTACTUS_LOG: 'GET_CONTACTUS_LOG',

    //Get Jira Log
    GET_JIRA_LOG: 'GET_JIRA_LOG',

    //Get user leave detail for broadcast
    RECEIVE_USER_LEAVE_DETAIL: 'RECEIVE_USER_LEAVE_DETAIL',

    //Get Username by Userid
    RECEIVE_USERNAME_BY_USERID: 'RECEIVE_USERNAME_BY_USERID',

    //Change Workspace
    RECEIVE_CHANGE_WORKSPACE: 'RECEIVE_CHANGE_WORKSPACE',

    //Get tenant Slack user access token
    RECEIVE_TENANT_SLACK_USER_ACCESS_TOKEN: 'RECEIVE_TENANT_SLACK_USER_ACCESS_TOKEN',

    //Get Virtual Rooms
    RECEIVE_VIRTUAL_ROOM: 'RECEIVE_VIRTUAL_ROOM',

}

export default types;