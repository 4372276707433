import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getApiHost } from '../../../utils/apiUrls';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import 'react-table-6/react-table.css';
import { toast } from 'react-toastify';
import ClockLoader from 'react-spinners/ClockLoader';
import UnassignedUser from '../../../assets/images/UnassignedUser.png';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { saveJiraTaskLogDetails } from '../../../actions/jiraActions';
import ReactTable from 'react-table-6';
import ReactTooltip from 'react-tooltip';
import {
  getPrivateJiraIssueDetails,
  getPrivateJiraUsersList,
  getPrivateJiraIssueType,
  changeAssigneeforPrivateJira,
  addCommentforPrivateJiraIssue,
  changeStatusOfPrivateJiraIssue,
  saveSummaryAndDescriptionPrivateJiraIssue,
} from '../../../actions/privateJiraActions';

import ButtonGroup from '@material-ui/core/ButtonGroup';

class PrivateJiraIssue extends React.Component {
  state = {
    loading: true,
    isActive: true,
    assignUsersOption: [],
    userSelected: null,
    statusCategory: [],
    statusSelected: null,
    description: '',
    isDescriptionEdit: false,
    disabled: false,
    editDescription: null,
    isSummaryEdit: false,
    editSummary: '',
    isSummaryDisabled: false,
    summary: '',
    comments: '',
    isCommentsDisabled: false,
    getAllComments: [],
    avatarUrls: '',
    assigneeAvatarUrls: '',
    reporterName: '',
    assigneeName: '',
    project: null,
    issueTypeIcon: '',
    taskDate: new Date(),
    jiraIssue: this.props.jiraIssue,
    taskDescription: '',
    taskDate: new Date(),
    taskComment: '',
    workedHours: 0,
    workedMinutes: 0,
    isSubmitDisabled: false,
    taskKey: '',
    isWorkingHour: false,
    jiraTaskLogDetails: this.props.jiraTaskLogDetails,
    totalWorkedHours: this.props.jiraTaskLogDetails[
      this.props.jiraTaskLogDetails.length - 1
    ],
  };

  async componentDidMount() {
    await this.getPrivateJiraIssueDetails(this.props.jiraIssue.key);
    this.getPrivateJiraUsersList(this.state.jiraIssue.fields.project.key);
    this.getPrivateJiraIssueType(this.props.jiraIssue.key);
    this.setState({ isActive: false });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jiraIssue !== this.props.jiraIssue) {
      this.getPrivateJiraIssueDetails(nextProps.jiraIssue.key);
      this.getPrivateJiraUsersList(nextProps.jiraIssue.fields.project.key);
      this.getPrivateJiraIssueType(nextProps.jiraIssue.key);
      this.setState({ jiraIssue: nextProps.jiraIssue });
    }

    if (nextProps.jiraTaskLogDetails !== this.props.jiraTaskLogDetails) {
      console.log('In will receive2: ', nextProps.jiraTaskLogDetails);
      if (nextProps.jiraTaskLogDetails.length > 0) {
        this.setState({ jiraTaskLogDetails: nextProps.jiraTaskLogDetails });
        this.setState({
          totalWorkedHours:
            nextProps.jiraTaskLogDetails[
              nextProps.jiraTaskLogDetails.length - 1
            ].WorkedHours,
        });
      } else {
        this.setState({ jiraTaskLogDetails: [] });
        this.setState({ totalWorkedHours: 0 });
      }
    }
  }

  // Get Private Jira Issue Details.
  getPrivateJiraIssueDetails = (jiraIssueKey) => {
    const { API_HOST, getPrivateJiraIssueDetails } = this.props;
    let requestBody = {
      Jira_Task_Key: jiraIssueKey,
      DomainName: this.props.jiraDomainSelected,
    };

    getPrivateJiraIssueDetails(API_HOST, requestBody, (res) => {
      if (res) {
        let userselected = null;
        if (res.fields.assignee) {
          userselected = {
            accountId: res.fields.assignee.accountId,
            accountType: res.fields.assignee.accountType,
            accountName: res.fields.assignee.key,
            active: res.fields.assignee.active,
            displayName: res.fields.assignee.displayName,
            //label: <div><img src={res.fields.assignee.avatarUrls["16x16"]} alt="" height="20px" width="20px" />{"     " + res.fields.assignee.displayName}</div>,
            value: res.fields.assignee.displayName,
          };
        } else {
          userselected = {
            accountId: '-1',
            displayName: 'Unassigned',
            label: (
              <div>
                <img src={UnassignedUser} alt="" height="20px" width="20px" />
                {'     Unassigned'}
              </div>
            ),
            value: '-1',
          };
        }

        let statusSelected = {
          id: res.fields.status.id,
          name: res.fields.status.name,
          value: res.fields.status.name,
          self: res.fields.status.self,
          statusCategory: res.fields.status.statusCategory,
          label: res.fields.status.name,
        };
        this.setState({
          userSelected: userselected,
          statusSelected: statusSelected,
          description: res.fields.description,
          summary: res.fields.summary,
          getAllComments: res.fields.comment.comments,
          avatarUrls: res.fields.reporter.avatarUrls['16x16'],
          assigneeAvatarUrls: res.fields.assignee
            ? res.fields.assignee.avatarUrls['16x16']
            : '',
          reporterName: res.fields.reporter.displayName,
          assigneeName: res.fields.assignee
            ? res.fields.assignee.displayName
            : '',
          project: res.fields.project,
          issueTypeIcon: res.fields.issuetype.iconUrl,
        });
      }
    });
  };

  // Get Private Jira Users List
  getPrivateJiraUsersList = async (projectKey) => {
    const { API_HOST, getPrivateJiraUsersList } = this.props;

    let requestBody = {
      ProjectKey: projectKey,
      DomainName: this.props.jiraDomainSelected,
    };

    await getPrivateJiraUsersList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({ assignUsersOption: res });
      }
    });
  };

  //Get Private Jira Issue Types.
  getPrivateJiraIssueType = async (jiraIssueKey) => {
    const { API_HOST, getPrivateJiraIssueType } = this.props;

    let requestBody = {
      Jira_Task_Key: jiraIssueKey,
      DomainName: this.props.jiraDomainSelected,
    };

    await getPrivateJiraIssueType(API_HOST, requestBody, (res) => {
      if (res) {
        console.log('status res: ', res);
        this.setState({ statusCategory: res.transitions });
      }
    });
  };

  // Selact user to assign issue
  userAssignChange = (newValue) => {
    this.setState({ userSelected: newValue });
    this.changeAssigneeforPrivateJira(newValue);
  };

  //Change Assignee for Private Jira Task
  changeAssigneeforPrivateJira = async (user) => {
    const { API_HOST, changeAssigneeforPrivateJira } = this.props;

    let requestBody = {
      Jira_Task_Key: this.props.jiraIssue.key,
      DomainName: this.props.jiraDomainSelected,
      AssigneeName: user.accountName,
    };

    await changeAssigneeforPrivateJira(API_HOST, requestBody, (res) => {
      if (res) {
        //this.setState({ statusCategory: res.projects[0].issuetypes });
      }
    });
  };

  // Add Comment for Private Jira Issue
  addCommentforPrivateJiraIssue = async () => {
    this.setState({ isCommentsDisabled: true });
    const { API_HOST, addCommentforPrivateJiraIssue } = this.props;

    let requestBody = {
      Jira_Task_Key: this.state.jiraIssue.key,
      DomainName: this.props.jiraDomainSelected,
      CommentBody: this.state.comments,
    };

    await addCommentforPrivateJiraIssue(API_HOST, requestBody, (res) => {
      if (res) {
        this.getPrivateJiraIssueDetails(this.state.jiraIssue.key);
        this.setState({ isCommentsDisabled: false, comments: '' });
      }
    });
  };

  // Change Status for Private Jira Issue.
  changeStatusOfPrivateJiraIssue = async (newValue) => {
    console.log('status : ', newValue);
    this.setState({ statusSelected: newValue });
    const { API_HOST, changeStatusOfPrivateJiraIssue } = this.props;

    let requestBody = {
      Jira_Task_Key: this.state.jiraIssue.key,
      DomainName: this.props.jiraDomainSelected,
      ID_Transition: newValue.id,
    };

    await changeStatusOfPrivateJiraIssue(API_HOST, requestBody, (res) => {
      if (res) {
        // this.getPrivateJiraIssueDetails(this.state.jiraIssue.key);
      }
    });
  };

  onSaveDescription = async () => {
    await this.setState({ disabled: true });
    if (this.state.description !== this.state.editDescription) {
      await this.saveSummaryAndDescriptionPrivateJiraIssue();
    }
    await this.setState({ isDescriptionEdit: false, disabled: false });
  };

  // Save summary and description fir Jira issue.
  saveSummaryAndDescriptionPrivateJiraIssue = async () => {
    const { API_HOST, saveSummaryAndDescriptionPrivateJiraIssue } = this.props;

    let requestBody = {
      Jira_Task_Key: this.state.jiraIssue.key,
      DomainName: this.props.jiraDomainSelected,
      Summary: this.state.editSummary,
      Description: this.state.editDescription,
    };

    await saveSummaryAndDescriptionPrivateJiraIssue(
      API_HOST,
      requestBody,
      (res) => {
        if (res) {
          this.setState({
            description:
              this.state.editDescription.trim() === ''
                ? null
                : this.state.editDescription,
            summary: this.state.editSummary,
          });
          this.getPrivateJiraIssueDetails(this.state.jiraIssue.key);
        }
      }
    );
  };

  onSaveSummary = async () => {
    await this.setState({ isSummaryDisabled: true });
    if (
      this.state.summary !== this.state.editSummary &&
      this.state.editSummary.trim() !== ''
    ) {
      await this.saveSummaryAndDescriptionPrivateJiraIssue();
    }
    await this.setState({ isSummaryEdit: false, isSummaryDisabled: false });
  };

  setDate = async (date) => {
    await this.setState({ taskDate: date });
  };

  setWorkedHours = (hours) => {
    this.setState({ workedHours: hours });
  };

  setWorkedMinutes = (minutes) => {
    this.setState({ workedMinutes: minutes });
  };

  openWorkingHourPopUp = async () => {
    await this.setState({
      isWorkingHour: true,
    });
  };

  closeWorkingHourPopUp = () => {
    this.setState({ isWorkingHour: false, workedHours: 0, workedMinutes: 0 });
  };

  saveJiraTaskLogDetails = () => {
    const { API_HOST, saveJiraTaskLogDetails } = this.props;

    this.setState({ isSubmitDisabled: true });
    var userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;
    let requestBody = {
      Id: 0,
      Id_Jira_Task: this.state.jiraIssue.id,
      Id_User: userId,
      LogDateTime: this.state.taskDate,
      WorkedHours:
        parseInt(this.state.workedHours * 60) +
        parseInt(this.state.workedMinutes),
      Jira_Task_Key: this.state.jiraIssue.key,
      Jira_Task_Summary: this.state.jiraIssue.fields.summary,
      Description: this.state.taskComment,
      Jira_Cloud_Id: null,
      Id_User_Jira_Detail: this.props.jiraDomainSelectedId,
    };

    if (
      (this.state.workedHours === 0 && this.state.workedMinutes === 0) ||
      (this.state.workedHours <= 0 && this.state.workedMinutes <= 0)
    ) {
      toast.info('Please enter correct value for Hours');
      this.setState({ isSubmitDisabled: false });
    } else {
      saveJiraTaskLogDetails(API_HOST, requestBody, (res) => {
        if (res) {
          toast.success('Task Working Details saved successfully');
          this.props.getJiraTaskLogDetails(this.state.jiraIssue.id);
          this.setState({
            isSubmitDisabled: false,
            isWorkingHour: false,
            workedHours: 0,
            workedMinutes: 0,
          });
        }
      });
    }
  };

  handleHourIncrement = () => {
    if (this.state.workedHours <= 11) {
      this.setState({ workedHours: this.state.workedHours + 1 });
    }
  };

  handleHourDecrement = () => {
    if (this.state.workedHours >= 1)
      this.setState({ workedHours: this.state.workedHours - 1 });
  };

  checkWorkedHour = (workedHours) => {
    if (workedHours < 0 || workedHours > 12) {
      toast.error('Please enter hours value between 1-12');
    }
  };

  handleMinutesIncrement = () => {
    if (this.state.workedMinutes <= 55) {
      this.setState({ workedMinutes: this.state.workedMinutes + 5 });
    }
  };

  handleMinutesDecrement = () => {
    if (this.state.workedMinutes >= 5)
      this.setState({ workedMinutes: this.state.workedMinutes - 5 });
  };

  checkWorkedMinutes = (workedMinutes) => {
    if (workedMinutes < 0 || workedMinutes > 59) {
      toast.error('Please enter minutes value between 1-60');
    }
  };

  render() {
    const { totalWorkedHours, jiraTaskLogDetails } = this.state;

    const hours = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
    ];
    const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    // var totalWorkedHours = jiraTaskLogDetails && jiraTaskLogDetails.length > 0 ? jiraTaskLogDetails[jiraTaskLogDetails.length - 1].WorkedHours : 0;
    var h = Math.floor(totalWorkedHours / 60);
    var m = totalWorkedHours % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    var totalWorkedHoursDisplay = h + ' : ' + m;

    const timezone = JSON.parse(localStorage.getItem('userdetail')).TZ
      ? JSON.parse(localStorage.getItem('userdetail')).TZ
      : 'Asia/Kolkata';
    const columns = [
      {
        Header: (
          <strong style={{ fontSize: '12.5px' }} className="my_custom_class">
            Name
          </strong>
        ),
        accessor: 'Id',
        //minWidth: 0.3,
        Cell: (row) => {
          return <div onClick="">{row.original.User_RealName}</div>;
        },
      },

      {
        Header: (
          <strong style={{ fontSize: '12.5px' }} className="my_custom_class">
            Comments
          </strong>
        ),
        accessor: 'Description',
        //minWidth: 0.3,
        Cell: (row) => {
          return <div onClick=""> {row.original.Description}</div>;
        },
      },

      {
        Header: (
          <strong style={{ fontSize: '12.5px' }} className="my_custom_class">
            Date
          </strong>
        ),
        accessor: 'LogDateTime',
        Footer: <div>Total Hours</div>,
        //minWidth: 0.2,
        width: 95,
        Cell: (row) => {
          if (row.original.LogDateTime === '0001-01-01T00:00:00') {
            return <div> Total Hours</div>;
          } else {
            return (
              <div className="d-block text-right" onClick="">
                {moment(row.original.LogDateTime)
                  .tz(timezone)
                  .format('MM/DD/YYYY')}
              </div>
            );
          }
        },
      },

      {
        Header: (
          <strong style={{ fontSize: '12.5px' }} className="my_custom_class">
            Hrs:Mins
          </strong>
        ),
        accessor: 'WorkedHours',
        Footer: <div>{totalWorkedHoursDisplay}</div>,
        // minWidth: 0.2,
        width: 75,
        Cell: (row) => {
          var h = Math.floor(row.original.WorkedHours / 60);
          var m = row.original.WorkedHours % 60;
          h = h < 10 ? '0' + h : h;
          m = m < 10 ? '0' + m : m;

          return (
            <div className="d-block text-right" onClick="">
              {h}:{m}
            </div>
          );
        },
      },
    ];

    let options = [];
    options.push({
      accountId: '-1',
      displayName: 'Unassigned',
      label: (
        <div>
          <img src={UnassignedUser} alt="" height="20px" width="20px" />
          {'     Unassigned'}
        </div>
      ),
      value: '-1',
    });
    this.state.assignUsersOption[0] &&
      this.state.assignUsersOption.map((obj) => {
        options.push({
          accountId: obj.accountId,
          accountType: obj.accountType,
          accountName: obj.key,
          active: obj.active,
          displayName: obj.displayName,
          label: (
            <div>
              <img
                src={obj.avatarUrls['16x16']}
                alt=""
                height="20px"
                width="20px"
              />
              {'     ' + obj.displayName}
            </div>
          ),
          value: obj.displayName,
        });
        return options;
      });

    let statusCategoryOptions = [];
    this.state.statusCategory[0] &&
      this.state.statusCategory.map((obj) => {
        statusCategoryOptions.push({
          description: obj.to.description,
          id: obj.id,
          name: obj.name,
          value: obj.id,
          self: obj.self,
          statusCategory: obj.statusCategory,
          label: obj.name,
        });
        return statusCategoryOptions;
      });
    return (
      <div className="jira-task-desc">
        <div className="jira-list-id">
          <img
            src={this.state.issueTypeIcon}
            alt=""
            height="25px"
            width="25px"
          />
          {'    ' + this.props.jiraIssue.key}
          <i
            className="fa fa-close"
            onClick={() => this.props.togglePrivateJiraIssue()}
          ></i>
        </div>
        {!this.state.isSummaryEdit ? (
          <h5
            className="mb-3"
            onClick={() => {
              this.setState({
                isSummaryEdit: true,
                editSummary: this.state.summary,
              });
            }}
          >
            {this.state.summary}
          </h5>
        ) : (
          <>
            <textarea
              className="form-control"
              rows="1"
              placeholder="Add a description..."
              onChange={(e) => this.setState({ editSummary: e.target.value })}
              value={this.state.editSummary}
            ></textarea>
            {this.state.summary !== this.state.editSummary ? (
              <div className="d-flex justify-content-end mt-4">
                <div classname="col-sm-5">
                  <Button
                    color="primary"
                    disabled={this.state.isSummaryDisabled}
                    onClick={this.onSaveSummary}
                  >
                    <span>Save</span>
                    {this.state.isSummaryDisabled === true && (
                      <span style={{ marginLeft: '5px' }}>
                        <ClockLoader
                          size={16}
                          color={'#fff'}
                          loading={this.state.isSummaryDisabled}
                        />
                      </span>
                    )}
                  </Button>
                </div>
                <div classname="col-sm-5">
                  <Button
                    color="secondary"
                    classname="col-sm-5"
                    onClick={() => {
                      this.setState({ isSummaryEdit: false });
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <br />
            )}
          </>
        )}
        <Row>
          <Col xs="5">
            <Select
              components={makeAnimated}
              onChange={(newValue) => {
                this.changeStatusOfPrivateJiraIssue(newValue);
              }}
              options={statusCategoryOptions}
              value={this.state.statusSelected}
            />
          </Col>
        </Row>
        <br />
        <h6>Description: </h6>
        {!this.state.isDescriptionEdit &&
        this.state.description &&
        this.state.description.trim() ? (
          <div
            onClick={() => {
              this.setState({
                isDescriptionEdit: true,
                editDescription: this.state.description,
              });
            }}
          >
            <pre style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.description}
            </pre>
          </div>
        ) : (
          <>
            <textarea
              className="form-control"
              rows="3"
              placeholder="Add a description..."
              onChange={(e) =>
                this.setState({
                  editDescription: e.target.value,
                  isDescriptionEdit: true,
                })
              }
              value={this.state.editDescription}
            ></textarea>
            {this.state.description !== this.state.editDescription &&
            this.state.isDescriptionEdit ? (
              <div className="d-flex justify-content-end mt-4">
                <div classname="col-sm-5">
                  <Button
                    color="primary"
                    disabled={this.state.disabled}
                    onClick={this.onSaveDescription}
                  >
                    <span>Save</span>
                    {this.state.disabled === true && (
                      <span style={{ marginLeft: '5px' }}>
                        <ClockLoader
                          size={16}
                          color={'#fff'}
                          loading={this.state.disabled}
                        />
                      </span>
                    )}
                  </Button>
                </div>
                <div classname="col-sm-5">
                  <Button
                    color="secondary"
                    classname="col-sm-5"
                    onClick={() => {
                      this.setState({ isDescriptionEdit: false });
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        )}
        <br />

        <h6>Date: </h6>
        <DatePicker
          selected={this.state.taskDate}
          onChange={(date) => this.setState({ taskDate: date })}
          //timeFormat="HH:mm"
          //timeIntervals={1}
          //timeCaption="time"
          dateFormat="dd-MM-yyyy"
          className="form-control"
        />
        <br />
        <br />

        <h6>Assignee: </h6>
        {this.props.showUserMessage && this.state.assigneeName !== '' ? (
          <div>
            <img
              src={this.state.assigneeAvatarUrls}
              alt=""
              height="20px"
              width="20px"
            />
            {'     ' + this.state.assigneeName}
          </div>
        ) : (
          <Select
            components={makeAnimated}
            onChange={(newValue) => {
              this.userAssignChange(newValue);
            }}
            options={options}
            value={this.state.userSelected}
          />
        )}

        <br />
        <h6>Reporter: </h6>
        <div>
          <img src={this.state.avatarUrls} alt="" height="20px" width="20px" />
          {'     ' + this.state.reporterName}
        </div>
        <div></div>
        <br />

        <ReactTooltip
          id="worlClock"
          place="right"
          type="dark"
          effect="solid"
          multiline={true}
          backgroundColor="#2c69d1"
          textColor="white"
          border="true"
        />
        <h6>
          <span
            style={{ cursor: 'pointer' }}
            onClick={this.openWorkingHourPopUp}
            data-for="workClock"
            data-tip="Set Task worked hours"
          >
            Worked Hours <i class="far fa-clock" style={{}}></i>
          </span>
        </h6>
        <div></div>
        <br />

        {jiraTaskLogDetails.length > 0 ? (
          <ReactTable
            // data={jiraTaskLogDetails.splice(0, jiraTaskLogDetails.length - 1)}
            data={jiraTaskLogDetails}
            columns={columns}
            // defaultPageSize={5}
            showPagination={false}
            showPageSizeOptions={false}
            className="-striped -highlight"
            //loading={this.state.loading};
            style={{ height: '250px' }}
          />
        ) : (
          <div>No Worked Hours details available for this task.</div>
        )}

        <br />
        <h6>Comments</h6>
        <div className="comment-div my-scroll">
          {this.state.getAllComments[0] ? (
            <>
              {' '}
              {this.state.getAllComments &&
                this.state.getAllComments.map((issue) => (
                  <>
                    {' '}
                    <div className="row  align-items-center">
                      <div className="col-sm-1">
                        <img
                          src={issue.author && issue.author.avatarUrls['16x16']}
                          alt=""
                          height="30px"
                          width="30px"
                        />
                      </div>
                      <div className="col-sm-11">
                        <span>
                          {issue.author && issue.author.displayName}{' '}
                          <span
                            style={{
                              left: 'calc(100% + 12px)',
                              top: '0',
                              fontSize: '12px',
                              color: '#111111',
                              whiteSpace: 'nowrap',
                              color: '#6c757d',
                            }}
                          >
                            {moment(issue.created).format('LLL')}
                          </span>
                          <br />
                          {issue.body}
                        </span>
                      </div>
                    </div>
                    <br />
                  </>
                ))}
            </>
          ) : null}
        </div>
        <textarea
          className="form-control"
          rows="1"
          placeholder="Add comments..."
          onChange={(e) => this.setState({ comments: e.target.value })}
          value={this.state.comments}
        ></textarea>
        {this.state.comments !== '' ? (
          <div className="d-flex justify-content-end mt-4">
            <div classname="col-sm-5">
              <Button
                color="primary"
                disabled={this.state.isCommentsDisabled}
                onClick={this.addCommentforPrivateJiraIssue}
              >
                <span>Save</span>
                {this.state.isCommentsDisabled === true && (
                  <span style={{ marginLeft: '5px' }}>
                    <ClockLoader
                      size={16}
                      color={'#fff'}
                      loading={this.state.isCommentsDisabled}
                    />
                  </span>
                )}
              </Button>
            </div>
          </div>
        ) : null}

        {this.state.isWorkingHour ? (
          <>
            <Modal isOpen={this.state.isWorkingHour} backdrop={true}>
              <ModalHeader toggle={this.closeWorkingHourPopUp}>
                {this.state.jiraIssue.key} :
              </ModalHeader>

              <ModalBody className="d-flex">
                <div style={{ width: '100%' }}>
                  <span>
                    <b>Task: </b> {this.state.jiraIssue.fields.summary}
                  </span>

                  <div className="row mt-3 mb-3">
                    <div className="col-6">
                      <h6>Date: </h6>
                      <DatePicker
                        className="form-control"
                        selected={this.state.taskDate}
                        onChange={(date) => this.setDate(date)}
                        //selectsEnd
                        //startDate={new Date()}
                        //endDate={this.state.endDate}
                        //minDate={new Date()}
                        // inline
                        // fixedHeight
                        id="enddate"
                      />
                    </div>
                    <div className="col-6">
                      <h6>Worked Hours: Minutes: </h6>
                      <div className="text-center d-flex align-items-center timings_todo">
                        {/* <select
                          style={{ width: '100%' }}
                          className="form-control"
                          value={this.state.workedHours}
                          onChange={(newValue) =>
                            this.setWorkedHours(newValue.target.value)
                          }
                        >
                          {hours.map((h) => {
                            return <option value={h}>{h}</option>;
                          })}
                        </select>
                        <span style={{ minWidth: '20px' }}>:</span>
                        <select
                          style={{ width: '100%' }}
                          className="form-control"
                          value={this.state.workedMinutes}
                          onChange={(newValue) =>
                            this.setWorkedMinutes(newValue.target.value)
                          }
                        >
                          {minutes.map((m) => {
                            return <option value={m}>{m}</option>;
                          })}
                        </select> */}

                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                        >
                          <Button onClick={this.handleHourIncrement}>+</Button>
                          {/* <Button disabled>{this.state.workedHours}</Button> */}
                          <input
                            type="text"
                            value={this.state.workedHours}
                            onChange={(e) => {
                              this.setState({ workedHours: e.target.value });
                              this.checkWorkedHour(e.target.value);
                            }}
                          />
                          <Button onClick={this.handleHourDecrement}>-</Button>
                        </ButtonGroup>
                        <span className="ml-1 mr-1">:</span>
                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                        >
                          <Button onClick={this.handleMinutesIncrement}>
                            +
                          </Button>
                          {/* <Button disabled>{this.state.workedHours}</Button> */}
                          <input
                            type="text"
                            value={this.state.workedMinutes}
                            onChange={(e) => {
                              this.setState({ workedMinutes: e.target.value });
                              this.checkWorkedMinutes(e.target.value);
                            }}
                          />
                          <Button onClick={this.handleMinutesDecrement}>
                            -
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>

                  <h6>Description</h6>
                  <div>
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Description..."
                      onChange={(e) =>
                        this.setState({ taskComment: e.target.value })
                      }
                    ></textarea>
                  </div>

                  <br />

                  <Button
                    type="submit"
                    color="primary"
                    disabled={this.state.isSubmitDisabled}
                    onClick={this.saveJiraTaskLogDetails}
                  >
                    <span>save</span>
                    {this.state.isSubmitDisabled === true && (
                      <span style={{ marginLeft: '5px' }}>
                        <ClockLoader
                          size={16}
                          color={'#fff'}
                          loading={this.state.isSubmitDisabled}
                        />
                      </span>
                    )}
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}

// Prop types of props.
PrivateJiraIssue.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
PrivateJiraIssue.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
  };
};
export default connect(mapStateToProps, {
  saveJiraTaskLogDetails,
  getPrivateJiraIssueDetails,
  getPrivateJiraUsersList,
  getPrivateJiraIssueType,
  changeAssigneeforPrivateJira,
  addCommentforPrivateJiraIssue,
  changeStatusOfPrivateJiraIssue,
  saveSummaryAndDescriptionPrivateJiraIssue,
})(PrivateJiraIssue);
