
import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { stickyMessageSend } from '../../../actions/mainOfficeActions';
import { getApiHost } from "../../../utils/apiUrls";
import ClockLoader from "react-spinners/ClockLoader";


class StickyNotes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stickyNotesMessage: '',
      fields: {},
      error: {},
      tenantDetails: {},
      src: "",
      disabled: false
    }
    this.message = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => { this.textInput && this.textInput.focus() }, 1);
  }

  componentWillReceiveProps() {
    setTimeout(() => { this.textInput && this.textInput.focus() }, 1);
  }

  onCloseSticky = () => {
    this.props.toggleSticky();
  };

  stickyMessageSend = (e) => {
    e.preventDefault();
    this.setState({
      disabled: true
    })
    const { API_HOST, stickyMessageSend, personId } = this.props;
    const stickyMessageSendRequest = {
      "Id_UserReceiver": personId,
      "Id_UserSender": 0,
      "Description": this.state.stickyNotesMessage
    }
    stickyMessageSend(API_HOST, stickyMessageSendRequest, (res) => {
      if (res) {
        this.setState({
          stickyNotesMessage: '',
          disabled: false
        })
        this.onCloseSticky();
      }
    });
  }

  stickyNotesMessageSend = (e) => {
    this.setState({ stickyNotesMessage: e.target.value });
  }

  render() {
    const { stickyNoteIsOpen } = this.props;
    const { disabled } = this.state;
    return (
      <Modal isOpen={stickyNoteIsOpen} backdrop={true} toggleSticky={this.onCloseSticky} className="sticky-class">
        <form method='post' onSubmit={(e) => { this.stickyMessageSend(e) }} id="stickyMessage">
          <ModalBody className="sticky-class">
            <div className="form-group">
              <label>Leave a message...</label>
              <textarea
                type="textarea"
                className="form-control"
                id="Message"
                aria-describedby="name"
                placeholder=""
                rows="10"
                cols="30"
                ref={(textarea) => { this.textInput = textarea; }}
                value={this.props.stickyNotesMessage}
                name="message"
                autoFocus
                required
                onChange={(e) => this.stickyNotesMessageSend(e)}
              >
              </textarea>
            </div>
          </ModalBody>
          <ModalFooter className="sticky-class" style={{ marginTop: '-5%' }}>
            <Button color="primary" className="col-sm-2" type="submit" disabled={disabled}><span>Save</span>
              <span style={{marginLeft:'5px'}}><ClockLoader
                size={16}
                color={"#fff"}
                loading={disabled}
              /></span>
            </Button>
            <Button color="secondary" className="col-sm-2" onClick={this.onCloseSticky}>Cancel</Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

// Prop types of props.
StickyNotes.propTypes = {
  API_HOST: PropTypes.string,
};
const mapStateToProps = state => ({
  API_HOST: getApiHost()
})
export default connect(mapStateToProps, { stickyMessageSend })(StickyNotes)
