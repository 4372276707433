import { types as T } from '../actions/action';
import { defaultStore } from '../store';

const officeReducer = (state = defaultStore, action) => {
	switch (action.type) {
		
		case T.RECEIVE_CREATE_OFFICE:
			return {...state, 
				officeResponse: {
					...state.officeReducer,
					loading: false,
					officeError: false,
					officeErrorMessage: null,
					officeResponse: action.officeResponse.data
				},
			};

			case T.RECEIVE_FETCH_OFFICE:
			return {...state, 
				fetchOfficeResponse: {
					...state.officeReducer,
					fetchOfficeLoading: false,
					fetchOfficeError: false,
					fetchOfficeErrorMessage: null,
					fetchOfficeResponse: action.fetchOfficeResponse
				},
			};

		default:
			return state
	}
}

export default officeReducer;