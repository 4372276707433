
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from "../../utils/apiUrls";
import { getAttendesUserMeetings, getUserMeetingbyId, deleteMeeting, meetingAttendOrNot, updateusergooglerefreshtoken, setmeeting_statuswithid_event, deleteMeetingInPrecisWithEventId } from "../../actions/googleCalendarMeetingActions";
import { doorClose, setUserCustomStatus } from "../../actions/mainOfficeActions";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars';
import LoadingOverlay from 'react-loading-overlay';
import { FormGroup } from 'reactstrap';
//import Select from 'react-select';
//import makeAnimated from 'react-select/animated';

let events = [];
let localizer = momentLocalizer(moment)
let date = new Date()

function ToolTipFun({ event }) {
    const attendees = event.attendees;
    let listItems = [];
    if (attendees) {
        listItems = attendees.map((obj) =>
            <li>{obj.Name_User ? obj.Name_User : obj.email}</li>
        );
    }

    let popoverClickRootClose = (

        <Popover id="popover-trigger-click-root-close" style={{ zIndex: 9999, overflow: 'hidden', height: '280px' }}>
            <Scrollbars style={{ width: '400px', height: '280px' }} autoHide>
                <Popover.Title as="h3">{event.title}</Popover.Title>
                <Popover.Content>
                    <i class="fas fa-user" style={{ fontSize: '16px', color: '#809fff' }}></i>&nbsp;&nbsp;&nbsp;{event.creatorName}
                </Popover.Content>
                <Popover.Content>
                    <i class="fa fa-clock-o" style={{ fontSize: '16px', color: '#00b300' }} aria-hidden="true"></i> &nbsp;{moment(event.start).format('LT') + ' - ' + moment(event.end).format('LT')}
                </Popover.Content>
                {attendees && <Popover.Content>
                    <i class="fa fa-users" style={{ fontSize: '16px', color: '#ff8080' }} aria-hidden="true"></i>&nbsp;&nbsp;<b>Guests</b><ul>{listItems}</ul>
                </Popover.Content>}
                <Popover.Content>
                    <div><i class="fa fa-sticky-note-o" style={{ fontSize: '16px', color: '#839192' }} aria-hidden="true"></i>&nbsp;&nbsp;<b>Description</b><br /><span dangerouslySetInnerHTML={{ __html: event.description ? event.description.replace(/\n/g, "<br />") : event.description }} /></div>
                </Popover.Content>
            </Scrollbars>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" id="help" rootClose container={this} placement="bottom" overlay={popoverClickRootClose} >
            <div>{event.title}</div>
        </OverlayTrigger>
    );
}

class UserCalendar extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isModal: false,
            iscal: this.props.iscal,
            sheduleModal: false,
            eventCard: [],
            x: 0,
            y: 0,
            startDate: new Date(date.getFullYear(), date.getMonth(), 1),
            endDate: new Date(date.getFullYear(), date.getMonth() + 1, 1),
            meetingList: null,
            currentInstance: this,
            title: '',
            selectedOption: this.props.selectedOption,
            MeetingId: 0,
            token: '',
            eventsList: [],
            googleId: "",
            calendarView: 'month',
            isNewToken: false,
            isNewRefreshTokenGenerate: false,
            googleEventsList: [],
            eventsOfPrecis: [],
            loading: false,
            isCustomStatus: false,
            reminderEvent: [],
            userDetails: JSON.parse(localStorage.getItem('userdetail'))
        }
    }

    async componentDidMount() {
        // await this.getUserMeetingbyId(this.state.startDate, this.state.endDate);
        await this.getGoogleEvents(this.state.startDate, this.state.endDate);
    }

    // async componentDidUpdate(prevState) {
    //     if (this.props.meetingListResponse !== prevState.meetingListResponse) {
    //         await this.setPrecisCalendarSchedule(this.props.meetingListResponse)
    //     }
    // }


    getGoogleEvents = async (startDate, endDate) => {
        try {
            const st = (this.state.startDate.getHours()) * 60 + (this.state.startDate.getMinutes());
            const et = (this.state.endDate.getHours()) * 60 + (this.state.endDate.getMinutes())
            var attendes = [];
            let data = this.props.slcOptions;
            if (data !== null) {
                for (let i = 0; i < data.length; i++) {
                    attendes.push({ "Id_User": data[i].value, "email": data[i].email })
                }
            }
            let reqBody = {
                "Start_Date": moment(startDate).format('YYYY-MM-DD'),
                "End_Date": moment(endDate).format('YYYY-MM-DD'),
                "Start_Time": st,
                "End_Time": et,
                "UserMeetingDetailList": attendes.length > 0 ? attendes : []
            }
            const { API_HOST, getAttendesUserMeetings } = this.props;
            this.setState({ loading: true })
            await getAttendesUserMeetings(API_HOST, reqBody, (res) => {
                if (res && res.error !== 'error') {
                    this.setCalendarSchedule(res);
                } else {
                    this.setState({
                        eventsList: []
                    });
                    toast.info("Sorry, record not found.");
                }
                this.setState({ loading: false })
            })
        } catch (error) {
            this.setState({ loading: false })
            toast.error(error);
        }
    }

    setCalendarSchedule = (data) => {
        events = [];
        //var data = [mData];
        if (data && data.length > 0) {
            for (let j = 0; j < data.length; j++) {
                let temp = data[j].items;
                if (temp !== null) {
                    for (let i = 0; i < temp.length; i++) {
                        events.push({
                            meetingId: temp[i].id,
                            meetingDelId: temp[i].id,
                            title: temp[i].summary,
                            start: new Date(moment(temp[i].start.dateTime).tz(this.state.userDetails.TZ).format("MMMM DD, YYYY HH:mm:ss")),
                            end: new Date(moment(temp[i].end.dateTime).tz(this.state.userDetails.TZ).format("MMMM DD, YYYY HH:mm:ss")),
                            creatorName: temp[i].creator.email,
                            creatorId: temp[i].creator.email,
                            is_host: temp[i].creator.self ? temp[i].creator.self : false,
                            meeting_status: temp[i].status,
                            eventId: temp[i].id,
                            attendees: temp[i].attendees ? temp[i].attendees : null,
                            description: temp[i].description,
                            extendedProperties: temp[i].extendedProperties ? temp[i].extendedProperties : "",
                            location: temp[i].location ? temp[i].location : "",
                            resourceId: data[j].summary
                        })
                    }

                    let newEventsList = events.concat(this.state.meetingList)
                    this.setState({ eventsList: newEventsList, googleEventsList: events })
                }
            }
        }
    }


    // getUserMeetingbyId = async (startDate, endDate) => {
    //     const { API_HOST, getUserMeetingbyId } = this.props;
    //     let userId = [];
    //     let data = this.state.selectedOption;
    //     if (data !== null) {
    //         for (let i = 0; i < data.length; i++) {
    //             userId.push({ "Id_User": data[i].value });
    //         }
    //     }
    //     this.setState({ startDate: startDate, endDate: endDate })
    //     //const userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;
    //     const reqbody = {
    //         "Id_User": userId.length > 0 ? userId[0].Id_User : [],
    //         "Start_Date": moment(startDate).format('YYYY-MM-DD'),
    //         "End_Date": moment(endDate).format('YYYY-MM-DD')
    //     }
    //     await getUserMeetingbyId(API_HOST, reqbody, (res) => {
    //         if (res) {
    //             this.setPrecisCalendarSchedule(res)
    //         }
    //     });
    // }

    // setPrecisCalendarSchedule = (data) => {
    //     events = [];
    //     for (let i = 0; i < data.length; i++) {
    //         let temp = data[i].UserMeetingDetailList;
    //         for (let j = 0; j < temp.length; j++) {
    //             const sd = moment(temp[j].Start_Date).tz(this.state.userDetails.TZ).toDate();
    //             const ed = moment(temp[j].End_Date).tz(this.state.userDetails.TZ).toDate();
    //             if (this.state.selectedOption.filter((user) => user.Id_User === temp[j].Id_User)) {
    //                 events.push({
    //                     meetingId: temp[j].Id,
    //                     meetingDelId: temp[j].Id_UserMeeting,
    //                     title: temp[j].Title,
    //                     start: new Date(sd.getFullYear(), sd.getMonth(), sd.getDate(), 0, parseInt(temp[j].Start_Time), 0, 0),
    //                     end: new Date(ed.getFullYear(), ed.getMonth(), ed.getDate(), 0, parseInt(temp[j].End_Time), 0, 0),
    //                     creatorName: temp[j].Name_HostUser,
    //                     creatorId: temp[j].Id_HostUser,
    //                     is_host: temp[j].Is_Host,
    //                     meeting_status: temp[j].Meeting_Status,
    //                     eventId: temp[j].Id_Event,
    //                     attendees: temp,
    //                     description: temp[j].Description,
    //                     extendedProperties: data[i].extendedProperties ? data[i].extendedProperties : "",
    //                     location: data[i].MeetingNumber ? process.env.REACT_APP_ZOOM_URL + '/' + window.btoa(data[i].MeetingNumber) + '/' + window.btoa(data[i].MeetingPassword) : ""
    //                 })
    //             }
    //         }
    //     }

    //     let eventsListWithoutEventId = events.filter((event) => event.eventId === null || event.eventId === "" || event.meeting_status === 2);
    //     let newEventsList = eventsListWithoutEventId.concat(this.state.googleEventsList)
    //     //newEventsList = newEventsList.concat(this.state.reminderEvent)
    //     this.setState({ meetingList: eventsListWithoutEventId, eventsList: newEventsList, eventsOfPrecis: events })
    // }


    toggle = () => {
        this.setState({
            isModal: false
        })
    }


    tooltipAccessor = (event) => {
        return null
    }


    eventStyleGetter = (event, start, end, isSelected) => {
        var style;
        if (event.meeting_status === 2) {
            style = {
                backgroundColor: '#FF0000',
                backgroundImage: 'linear-gradient(270deg, rgb(243 50 50) 0%, rgb(214 8 8) 74%)',
                borderRadius: '5px',
                opacity: 0.8,
                display: 'block'
            };
        }

        if (event.isReminder) {
            style = {
                backgroundColor: '#10b759',
                backgroundImage: ' linear-gradient(270deg, #4ad888eb 0%, #1e864c 74%)',
                borderRadius: '5px',
                opacity: 0.8,
                display: 'block'
            };
        }
        return {
            style: style
        };

    }

    toggleUserCal = () => {
        this.props.toggleUserCal()
    }

    // handleChange = (data) => {
    //     let selectedOption = [data];
    //     if (selectedOption !== null && (selectedOption && selectedOption.length !== 0)) {
    //         this.setState({ selectedOption }, () => {
    //             this.getGoogleEvents(this.state.startDate, this.state.endDate);
    //             this.getUserMeetingbyId(this.state.startDate, this.state.endDate);
    //         });
    //     } else {
    //         this.setState({ eventsList: [], selectedOption })
    //     }
    // }


    render() {
        events = this.state.eventsList;
        const { loading, iscal } = this.state;
        const { getUserAvailabilityListResponse, slcOptions } = this.props;
        let options = [];
        let resourceMap = [];

        getUserAvailabilityListResponse && getUserAvailabilityListResponse.map(obj => {
            if (obj.EmailId !== '' && obj.Real_Name !== '') {
                options.push({ value: obj.Id_User, email: obj.EmailId, label: obj.Real_Name, color: '#64B988' });
            }
            return options;
        })

        slcOptions && slcOptions.map(obj => {
            resourceMap.push({ resourceId: obj.email, resourceTitle: obj.label });
        })


        return (
            <>
                {/* <Modal isOpen={iscal} backdrop={true} size={'lg'} >
                    <ModalHeader toggle={() => this.toggleUserCal()}>
                        Calendar
                   </ModalHeader>
                    <ModalBody> 
                        {/* <FormGroup>
                            <Row>
                                <Col xs="8"></Col>
                                <Col xs="4">
                                    <Select
                                        components={makeAnimated}
                                        styles={customStyles}
                                        onChange={this.handleChange}
                                        options={options}
                                        value={this.state.selectedOption}
                                    />
                                </Col>
                            </Row>
                        </FormGroup> */}
                        <FormGroup>
                            <LoadingOverlay
                                active={loading}
                                spinner
                                text='Loading...'
                            >
                                <Calendar
                                    localizer={localizer}
                                    events={this.state.eventsList}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 360, marginTop: '2%' }}
                                    selectable={true}
                                    defaultDate={moment().toDate()}
                                    tooltipAccessor={this.tooltipAccessor}
                                    components={{
                                        event: ToolTipFun
                                    }}
                                    defaultView="day"
                                    views={['day', 'work_week']}
                                    resources={resourceMap}
                                    resourceIdAccessor="resourceId"
                                    resourceTitleAccessor="resourceTitle"
                                    eventPropGetter={(this.eventStyleGetter)}
                                />
                            </LoadingOverlay>
                        </FormGroup>
                    {/* </ModalBody>
                </Modal> */}
            </>
        );
    }
}


UserCalendar.defaultProps = {
    API_HOST: PropTypes.string,
    getUserMeetingbyId: PropTypes.func,
    deleteMeeting: PropTypes.func,
    meetingListResponse: PropTypes.array,
    getUserAvailabilityListResponse: PropTypes.array,
    getAttendesUserMeetings: PropTypes.func
};

// Set default props.
UserCalendar.defaultProps = {
    getUserMeetingbyId: () => { },
    deleteMeeting: () => { },
    meetingAttendOrNot: () => { },
    getAttendesUserMeetings: () => { },
    setmeeting_statuswithid_event: () => { },
    meetingListResponse: [],
    getUserAvailabilityListResponse: []
};

const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    meetingListResponse: state.meetingReducer.meetingResponse,
    getUserAvailabilityListResponse: state.mainOfficeReducer.userListDepartmentFilterResponse
})

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999
    }),
}

export default connect(mapStateToProps, { getAttendesUserMeetings, getUserMeetingbyId, deleteMeeting, meetingAttendOrNot, updateusergooglerefreshtoken, setmeeting_statuswithid_event, deleteMeetingInPrecisWithEventId, doorClose, setUserCustomStatus })(UserCalendar)