import axios from "axios";
//import { trackPromise } from "react-promise-tracker";
import { handleError, handleResponse, getConfig } from "../utils/utility";

/**
 * jira Config
 *
 * @param {url} URL is API host domain.
 * @param {requestBody} is request object to jira update refresh token.
 * @return {object} return slack
 */
export const updateUserJiraRefreshToken = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}usertenant/updateuserjirarefreshtoken`;

  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {})
    .catch((error) => handleError(error, "Failed to update refresh token"));
};

/**
 * jira Config
 *
 * @param {url} URL is API host domain.
 * @param {requestBody} is request object to jira update access token.
 * @return {object} return slack
 */
export const updateUserJiraAccessToken = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}Jira/saveuserjiradetails`;
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {})
    .catch((error) => handleError(error, "Failed to save jira details"));
};

export const getUserJiraRefreshToken = (url, callback) => (dispatch) => {
  const finalUrl = `${url}usertenant/getuserjirarefreshtoken`;
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (result) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "Cannot get Refresh Token"));
};

// Get JIRA Configuration
export const getJiraConfig = (url, callback) => (dispatch) => {
  const finalUrl = `${url}Jira/getjiraconfigurationlist`;
  // return trackPromise(
  axios
    .get(finalUrl, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "Failed to get JIRA config"));
  // );
};

// Save JIRA Configuration
export const saveJiraConfig = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}Jira/savejiraconfiguration`;
  // return trackPromise(
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, "Failed to save JIRA config"));
  // );
};

// Save User ToDo From Jira Task  1
// export const saveUserToDoFromJiraTask = (url, reqBody, callback) => (dispatch) => {
//   const finalUrl = `${url}UserToDo/SaveUserToDoFromJiraTask`;
//   axios.post(finalUrl, reqBody, getConfig())
//     .then((response) => handleResponse(response))
//     .then((result) => {
//       if (result) {
//         callback(result)
//       }
//     })
//     .catch((error) =>
//       handleError(error, " Cannot save User ToDo from Jira Task")
//     )
// };

// Save User ToDo From Jira Task  2
export const saveUserToDoFromJiraTask =
  (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserToDo/SaveUserToDoFromJiraTask`;
    // return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) =>
        handleError(error, " Cannot save User ToDo from Jira Task")
      );
    // );
  };

// gete all your jira domain
export const getJiraDomain = (url, callback) => (dispatch) => {
  const finalUrl = `${url}Jira/getuserjiradetails`;
  // return trackPromise(
  axios
    .get(finalUrl, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, " Cannot get jira details"));
  // );
};

//Save Jira task Log Details
export const saveJiraTaskLogDetails =
  (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}jira/savejiratasklogdetails`;
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) =>
        handleError(error, " Cannot save User Jira task Log Details")
      );
  };

//Get Jira Task Log Details
export const getJiraTaskLogDetails = (url, jiraId, callback) => (dispatch) => {
  const finalUrl = `${url}jira/getJiraTaskLogDetailsByJiraTaskId/${jiraId}`;

  axios
    .get(finalUrl, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, " Cannot get Jira Task Log Details"));
};

//Get Jira Task Report EpicWise
export const getJiraEpicWiseReport =
  (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}jira/GetJiraTaskReportEpicWise`;
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) => {
        callback({ error: true });
        handleError(error, "Not Authorised for this project");
      });
  };

//Get Jira Task Report EpicWise Report Download
export const jiraEpicWiseReportDownload =
  (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}jira/JiraTaskReportEpicWiseExportToExcel`;
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) => {
        callback({ error: true });
        handleError(error, "Not Authorised for this project");
      });
  };

// Update Jira Domain Access
export const updateJiraDomainAccess =
  (url, domainId, callback) => (dispatch) => {
    const finalUrl = `${url}Jira/updatejiradomainaccess/${domainId}`;
    axios
      .post(finalUrl, {}, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) =>
        handleError(error, " Failed to update Jira Domain access.")
      );
  };
