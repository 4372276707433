import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from "../../../utils/apiUrls";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import UnassignedUser from "../../../assets/images/UnassignedUser.png";
import ClockLoader from "react-spinners/ClockLoader";
import { updateUserToDoToJira } from "../../../actions/todoActions";
import {
  getJiraDomain,
  getUserJiraRefreshToken,
  getJiraConfig,
  updateUserJiraRefreshToken,
  updateJiraDomainAccess,
} from "../../../actions/jiraActions";
import {
  getEncryptedValue,
  getDecryptedValue,
} from "../../../utils/utility";
import {
  getPrivateJiraProjectList,
  getPrivateJiraUsersList,
  createPrivateJiraTask,
} from "../../../actions/privateJiraActions";

class AddChatToJiraTask extends React.Component {
  state = {
    isActive: false,
    projectList: [],
    projectSelected: null,
    issueTypeList: [],
    issueTypeSelected: null,
    assignUsersOption: [],
    userSelected: null,
    summary: "",
    description: "",
    reporterSelected: [],
    accessToken: "",
    isCreateDisabled: false,
    cloudId: "",
    selectTaskIssueType: true,
    jiraDomainSelected: {},
    jiraDomain: [],
    isPrivateJiraSelected: this.props.isPrivateJiraSelected,
    showPrivateJiraAddTask: false,
  };

  async componentDidMount() {
    await this.setState({
      accessToken: this.props.accessToken,
      cloudId: this.props.cloudId,
    });

    if (this.props.todoId > 0 || this.props.isOpenAddTaskPopup) {
      this.getJiraDomain();
      this.getJiraConfig();
    }
  }

  getJiraConfig = () => {
    const { API_HOST, getJiraConfig } = this.props;
    getJiraConfig(API_HOST, (res) => {
      if (res[0]) {
        this.setState({
          jiraClientId: getDecryptedValue(res[0].Client_ID),
          jiraClientSecret: getDecryptedValue(res[0].Client_Secret),
        });

        this.getRefreshToken();
      } else {
        this.setState({ jiraClientDisable: true });
      }
    });
  };

  getRefreshToken = () => {
    const { API_HOST, getUserJiraRefreshToken } = this.props;

    getUserJiraRefreshToken(API_HOST, (res) => {
      if (res.Jira_Token) {
        this.setState({ refreshToken: res.Jira_Token });
        this.getAccessToken(res.Jira_Token);
        this.setState({ jiraClientDisable: false, isActive: false });
      } else {
        this.setState({ jiraClientDisable: true, isActive: false });
      }
    });
  };

  getAccessToken = async (refreshToken) => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: getDecryptedValue(refreshToken),
      };

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });
      this.setState({
        accessToken: data.data.access_token,
        refreshToken: data.data.refresh_token,
      });

      await this.updateUserJiraRefreshToken(data.data.refresh_token);
      if (!this.state.jiraDomainSelected.Is_Private) {
        await this.getJiraYourTaskList(
          this.state.cloudId,
          data.data.access_token
        );
      }
    } catch (error) {
      toast.error(
        "Refresh token is expired.Please reconnect your application with your JIRA"
      );
      this.setState({ isActive: false });
    }
  };

  updateUserJiraRefreshToken = async (refreshToken) => {
    const { API_HOST, updateUserJiraRefreshToken } = this.props;

    let requestBody = {
      Jira_Token: getEncryptedValue(refreshToken),
    };

    await updateUserJiraRefreshToken(API_HOST, requestBody);
  };

  getprojectsList = async () => {
    await this.setState({ projectList: [] });
    try {
      let finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/project/search?expand=lead%2Curl%2Cfavourite%2Cpermissions&maxResults=100&orderBy=%2BNAME&startAt=0`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ projectList: data.values });
    } catch (error) {
      toast.error(error);
    }
  };

  getIssueType = async (newValue) => {
    try {
      let finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/issue/createmeta?projectKeys=${newValue.key}&expand=projects.issuetypes.fields`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ issueTypeList: data.projects[0].issuetypes });
      console.log("issue type list", data);
    } catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken });
        this.getIssueType(newValue);
      }
      toast.error(error);
    }
  };

  onSelectprojects = async (newValue) => {
    await this.setState({ projectSelected: newValue });
    if (this.state.isPrivateJiraSelected) {
      this.getPrivateJiraUsersList(newValue.key);
    } else {
      //this.getIssueType(newValue)
      this.jiraUsers(newValue);
    }
  };

  setIssueType = (newValue) => {
    this.setState({ issueTypeSelected: newValue });
    console.log("issueTypeSelected: ", this.state.issueTypeSelected);
    if (
      newValue.value === "Epic" ||
      newValue.value === "Story" ||
      newValue.value === "Bug" ||
      newValue.value === "Subtask"
    ) {
      toast.info("Functionality to be Developed for the selected option.");
      this.setState({ selectTaskIssueType: false, isCreateDisabled: true });
    } else {
      this.setState({ selectTaskIssueType: true, isCreateDisabled: false });
    }
  };

  jiraUsers = async (newValue) => {
    await this.setState({ assignUsersOption: [] });
    try {
      const finalUrl =
        `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/user/assignable/search?project=` +
        newValue.key;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ assignUsersOption: data });
    } catch (error) {
      // if (error.response.status === 401) {
      // let gettoken = await this.props.getAccessTokenAgain();
      // this.setState({ accessToken: gettoken })
      // this.jiraUsers(newValue)
      //}
      toast.error(error);
    }
    this.setState({ isActive: false });
  };

  userAssignChange = (newValue) => {
    this.setState({ userSelected: newValue });
  };

  createTask = async () => {
    if (this.state.isPrivateJiraSelected) {
      this.createPrivateJiraTask();
    } else {
      this.createPublicJiraTask();
    }
  };

  createPrivateJiraTask = async () => {
    this.setState({ isCreateDisabled: true });
    const { API_HOST, createPrivateJiraTask } = this.props;

    let requestBody = {
      ProjectKey: this.state.projectSelected.key,
      DomainName: this.state.jiraDomainSelected.Domain_Name,
      Summary: this.state.summary,
      Description: this.props.isToDoDescription
        ? this.props.todoDescription
        : this.state.description,
      AssigneeName: this.state.userSelected.accountName,
      TaskStatus: "10002",
    };

    await createPrivateJiraTask(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({ isCreateDisabled: false });
        this.props.closeAddTask();
      }
    });
  };

  createPublicJiraTask = async () => {
    this.setState({ isCreateDisabled: true });
    try {
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/issue/`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };

      let resource = {};

      resource = {
        fields: {
          project: {
            key: this.state.projectSelected.key,
          },
          summary: this.state.summary,
          description: this.props.isToDoDescription
            ? this.props.todoDescription
            : this.state.description,
          issuetype: {
            // "name": this.state.issueTypeSelected.name
            name: "Task",
          },
        },
      };
      if (this.state.userSelected !== null) {
        resource.fields["assignee"] = {
          accountId: this.state.userSelected.accountId,
        };
      }
      // if(this.state.reporterSelected!==null){
      //   resource.fields["reporter"]= {
      //     "name": this.state.reporterSelected.displayName
      //  }
      // }

      const { data } = await axios.post(finalUrl, resource, { headers });
      this.props.closeAddTask();

      if (data && this.props.todoId > 0) {
        this.updateUserToDoToJira(data.id, data.key);
      }
    } catch (error) {
      toast.error(error);
      // if (error.response.status === 401) {
      //   let gettoken = await this.props.getAccessTokenAgain();
      //   this.setState({ accessToken: gettoken });
      //   this.createTask();
      // }
    }
    this.setState({ isCreateDisabled: false });
  };

  reporterAssignChange = (newValue) => {
    this.setState({ reporterSelected: newValue });
  };

  updateUserToDoToJira = (jiraId, jiraKey) => {
    const { API_HOST, updateUserToDoToJira } = this.props;

    let requestBody = {
      Id: this.props.todoId,
      Jira_Task_Date: "",
      Id_Jira_Task: jiraId,
      Is_Jira_Task: "true",
      Jira_Task_Key: jiraKey,
      Jira_Cloud_Id: getEncryptedValue(this.state.cloudId),
    };

    updateUserToDoToJira(API_HOST, requestBody, (res) => {
      if (res) {
        this.props.userToDoList(0);
      }
    });
  };

  getJiraDomain = async () => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, (res) => {
      if (res[0]) {
        let newValue = res.map(function (obj) {
          return {
            id: getDecryptedValue(obj.CloudId),
            CloudId: getDecryptedValue(obj.CloudId),
            Domain_Name: obj.Domain_Name,
            key: obj.Domain_Name,
            url: obj.url,
            Jira_Scope_Detail: obj.Jira_Scope_Detail,
            Is_Private: obj.Is_Private,
            label: obj.Domain_Name,
            value: getDecryptedValue(obj.CloudId),
          };
        });
        this.setState({
          jiraDomain: newValue,
          jiraDomainSelected: newValue[0],
          cloudId: newValue[0].CloudId,
        });

        if (res[0].Is_Private) {
          this.setState({ isPrivateJiraSelected: true });
          this.getPrivateJiraProjectList();
        } else {
          this.getprojectsList();
        }
      }
    });
  };

  onSelectDomain = async (newValue) => {
    if (newValue.Is_Private) {
      this.setState({
        isPrivateJiraSelected: true,
        jiraDomainSelected: newValue,
        projectSelected: null,
      });
      this.getPrivateJiraProjectList();
    } else {
      this.setState({
        isPrivateJiraSelected: false,
        jiraDomainSelected: newValue,
        projectSelected: null,
        cloudId: newValue.CloudId,
      });
      this.getprojectsList();
    }
  };

  getPrivateJiraProjectList = async () => {
    await this.setState({ projectList: [] });
    const { API_HOST, getPrivateJiraProjectList } = this.props;
    let requestBody = {
      DomainName: this.state.jiraDomainSelected.Domain_Name,
    };

    await getPrivateJiraProjectList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({ projectList: res });
      }
    });
  };

  getPrivateJiraUsersList = async (projectKey) => {
    await this.setState({ assignUsersOption: [] });
    const { API_HOST, getPrivateJiraUsersList } = this.props;

    let requestBody = {
      ProjectKey: projectKey,
      DomainName: this.state.jiraDomainSelected.Domain_Name,
    };

    await getPrivateJiraUsersList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({ assignUsersOption: res });
      }
    });
  };

  render() {
    let options = [];
    options.push({
      accountId: "-1",
      displayName: "Unassigned",
      accountName: "",
      label: (
        <div>
          <img src={UnassignedUser} alt="" height="20px" width="20px" />
          {"     Unassigned"}
        </div>
      ),
      value: "-1",
    });
    this.state.assignUsersOption[0] &&
      this.state.assignUsersOption.map((obj) => {
        options.push({
          accountId: obj.accountId,
          accountName: obj.key,
          accountType: obj.accountType,
          active: obj.active,
          displayName: obj.displayName,
          label: (
            <div>
              <img
                src={obj.avatarUrls["16x16"]}
                alt=""
                height="20px"
                width="20px"
              />
              {"     " + obj.displayName}
            </div>
          ),
          value: obj.displayName,
        });
        return options;
      });
    //     let statusCategoryOptions=[];
    //     this.state.statusCategory[0] && this.state.statusCategory.map(obj => {
    //       statusCategoryOptions.push({ description: obj.description,
    //         id: obj.id,
    //         name: obj.name,
    //         value:obj.id,
    //         self:obj.self,
    //         statusCategory:obj.statusCategory,
    //         label:obj.name
    //       })
    //     return statusCategoryOptions;
    //   })
    let projectlistOptions = [];
    this.state.projectList[0] &&
      this.state.projectList.map((obj) => {
        projectlistOptions.push({
          id: obj.id,
          avatarUrls: obj.avatarUrls,
          name: obj.name,
          key: obj.key,
          self: obj.self,
          projectTypeKey: obj.projectTypeKey,
          label: obj.name + "(" + obj.key + ")",
          value: obj.name,
        });
        return projectlistOptions;
      });
    let issueTypeOptions = [];
    this.state.issueTypeList[0] &&
      this.state.issueTypeList.map((obj) => {
        if (obj !== "Subtask") {
          issueTypeOptions.push({
            id: obj.id,
            fields: obj.fields,
            name: obj.name,
            description: obj.description,
            self: obj.self,
            subtask: obj.subtask,
            label: (
              <div>
                <img src={obj.iconUrl} alt="" height="20px" width="20px" />
                {"     " + obj.name}
              </div>
            ),
            value: obj.name,
          });
        }
        return issueTypeOptions;
      });

    return (
      <React.Fragment>
        <Modal isOpen={this.props.isAddTask} size={"l"} backdrop={true}>
          <ModalHeader toggle={() => this.props.closeAddTask()}>
            Add Jira Task
          </ModalHeader>
          <form method="post" id="createJiraTask">
            <ModalBody>
              <Row>
                {this.props.todoId > 0 ||
                this.props.isOpenAddTaskPopup === true ? (
                  <Col xs="6">
                    <FormGroup>
                      <h6>Jira Domain</h6>
                      <Select
                        components={makeAnimated}
                        onChange={(newValue) => {
                          this.onSelectDomain(newValue);
                        }}
                        options={this.state.jiraDomain}
                        value={this.state.jiraDomainSelected}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}
                <Col xs="6">
                  <FormGroup>
                    <h6>Project</h6>
                    <Select
                      components={makeAnimated}
                      onChange={(newValue) => {
                        this.onSelectprojects(newValue);
                      }}
                      options={projectlistOptions}
                      value={this.state.projectSelected}
                    />
                  </FormGroup>
                </Col>
                {/* <Col xs="6" >
                <FormGroup>
                  <h6>Issue Type</h6>
                  <Select
                    components={makeAnimated}
                    onChange={(newValue) => { this.setIssueType(newValue) }}
                    options={issueTypeOptions}
                    value={this.state.issueTypeSelected}
                  />
                  </FormGroup>
                </Col> */}
              </Row>

              {this.state.selectTaskIssueType && (
                <>
                  <FormGroup>
                    <h6>Summary</h6>
                    <textarea
                      className="form-control"
                      required
                      rows="1"
                      onChange={(e) =>
                        this.setState({ summary: e.target.value })
                      }
                      value={this.state.summary}
                    ></textarea>
                  </FormGroup>

                  <FormGroup>
                    <h6>Description</h6>
                    <textarea
                      className="form-control"
                      rows="4"
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      value={
                        this.props.isToDoDescription
                          ? this.props.todoDescription
                          : this.state.description
                      }
                    ></textarea>
                  </FormGroup>

                  <h6>Assignee</h6>
                  <Row>
                    <Col xs="5">
                      <Select
                        components={makeAnimated}
                        onChange={(newValue) => {
                          this.userAssignChange(newValue);
                        }}
                        options={options}
                        value={this.state.userSelected}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {/* <br/>
             <h6>Reporter</h6>
             <Row>
             <Col xs="5" >
              <Select
                components={makeAnimated}
                onChange={(newValue) => {this.reporterAssignChange(newValue)}}
                options={options}
                value={this.state.reporterSelected}
              />
              </Col>
              </Row> */}
              {/* <br/>
              <h6> Sprint</h6>
             <Row>
                                <Col xs="5" >
             <Select
                components={makeAnimated}
               // onChange={(newValue) => {this.setStatusforJira(newValue)}}
              //  options={statusCategoryOptions}
               // value={this.state.statusSelected}
              />
              </Col>
              </Row> */}
              {/* <textarea ref={} onBlur={e => onBlur(e)} name={name} onKeyDown={e => onKeyHandle(e, value)} placeholder="Message" rows="1" value={value} onChange={e => setValue(e.target.value)} id="message" ></textarea> */}
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                color="primary"
                disabled={this.state.isCreateDisabled}
                onClick={this.createTask}
              >
                <span>Create</span>
                {this.state.isCreateDisabled === true && (
                  <span style={{ marginLeft: "5px" }}>
                    <ClockLoader
                      size={16}
                      color={"#fff"}
                      loading={this.state.isCreateDisabled}
                    />
                  </span>
                )}
              </Button>
              <Button color="secondary" onClick={this.props.closeAddTask}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

// Prop types of props.
AddChatToJiraTask.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
AddChatToJiraTask.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
  };
};
export default connect(mapStateToProps, {
  updateUserToDoToJira,
  getJiraDomain,
  getPrivateJiraProjectList,
  getPrivateJiraUsersList,
  createPrivateJiraTask,
  getUserJiraRefreshToken,
  getJiraDomain,
  updateUserJiraRefreshToken,
  getJiraConfig,
  updateJiraDomainAccess,
})(AddChatToJiraTask);
