import { types as T } from "../actions/action";
import { defaultStore } from "../store";

const loginReducer = (state = defaultStore, action) => {
  switch (action.type) {

    case T.RECEIVE_LOGIN:
      return {...state, 
        loginResponse: {
          ...state.loginResponse,
          loading: false,
          loginError: false,
          loginErrorMessage: null,
          loggedInResponse: action.loginResponse,
        },
      };

    case T.REQUEST_LOGOUT:
      return  {...state,
        logoutResponse: {
          ...state.logoutResponse,
          isLoggedOut: false,
        },
        loginResponse: {
          loggedInResponse: null,
        },
      };

    case T.RECEIVE_LOGOUT:
      return  {...state,
        logoutResponse: {
          ...state.logoutResponse,
          isLoggedOut: action.logoutResponse,
        },
      };

    default:
      return state
  }
};

export default loginReducer;
