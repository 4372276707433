import { types as T } from '../actions/action';
import { defaultStore } from '../store';

const resetPasswordReducer = (state = defaultStore, action) => {
  switch (action.type) {
    
    case T.RECEIVE_RESET_PASSWORD:
      return {...state,
        resetPassword: {
          ...state.resetPasswordResponse,
          loading: false,
          error: false,
          errorMessage: null,
          resetPasswordResponse: action.resetPasswordResponse,
        },
      };
 
      case T.RECEIVE_VALID_ACTIVATION_KEY:
        return {...state,
          validActivationKey: {
            ...state.validActivationKeyResponse,
            loading: false,
            error: false,
            errorMessage: null,
            validActivationKeyResponse: action.validActivationKeyResponse,
          },
        };
  
    default:
      return state
  }
}
export default resetPasswordReducer;
