
import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import { getApiHost } from "../../../utils/apiUrls";
import { doorClose, setUserCustomStatus } from "../../../actions/mainOfficeActions";
import { zoomMeetingJoinLeave } from "../../../actions/zoomActions";

class ZoomMeeting extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            zoomModal: this.props.zoomModal,
            meeting: '',
            password: '',
            currentObject: {
                isParticipant: { value: 0, label: "Participant" }
            },
            redirect: false,
            isModal: false
        }
    }

    componentDidUpdate() {
        if (this.state.zoomModal !== this.props.zoomModal) {
            this.setState({
                zoomModal: this.props.zoomModal
            })
        }
    }

    toggle = () => {
        this.props.zoomModalToggle()
    }

    zoomJoin = async () => {
        let userData = JSON.parse(localStorage.getItem('userdetail'));
        let userName = userData.Display_Name;
        if (userData.Is_DoorOpen === true) {
            await this.doorClosefunc();
        }
        let meetNum = this.state.meeting;
        meetNum = meetNum.replace(/\s+/g, '').trim();
        meetNum = window.btoa(meetNum)
        let password = window.btoa(this.state.password);
        if (meetNum !== '' && password !== '') {
            this.setState({
                redirect: true,
                password: password,
                meeting: meetNum
            });
            await this.zoomMeetingJoinLeave(meetNum);
            this.toggle();
            //let zoomUrl = 'http://localhost:9001/' + meetNum + '/' + password + '/' + this.state.currentObject.isParticipant.value + '/' + userName;
            //let zoomUrl = process.env.REACT_APP_ZOOM_URL+'/' + meetNum + '/' + password + '/' + this.state.currentObject.isParticipant.value + '/' + userName;          
            setTimeout(() => {
                window.location.href = process.env.REACT_APP_ZOOM_URL + '/' + meetNum + '/' + password + '/' + this.state.currentObject.isParticipant.value + '/' + userName;
                //window.location.href = 'http://localhost:9001/' + meetNum + '/' + password + '/' + this.state.currentObject.isParticipant.value + '/' + userName;
            }, 500);
            //this.props.zoomModalJoin(zoomUrl);
        } else {
            toast.error("All the fields are required")
        }
    }

    zoomMeetingJoinLeave = (value) => {
        const { API_HOST, zoomMeetingJoinLeave } = this.props;
        let requestBody = {
            Meeting_Number: window.atob(value),
            IsJoining: true
        }
        zoomMeetingJoinLeave(API_HOST, requestBody, (res) => {

        });
    }


    doorClosefunc = async () => {
        const { API_HOST, doorClose } = this.props;

        let userDetails = JSON.parse(localStorage.getItem('userdetail'));
        userDetails["Is_DoorOpen"] = false;
        userDetails["UserStatus"] = "In a Meeting";
        this.props.userValuesChanges(userDetails);

        await doorClose(API_HOST, JSON.parse(localStorage.getItem('userdetail')).Id_User, (res) => {
            //if (res) {
                // //this.getUserListDepartmentFilter();
                // let userDetails = JSON.parse(localStorage.getItem('userdetail'));
                // userDetails["Is_DoorOpen"] = false;
                // userDetails["UserStatus"] = "In a Meeting";
                // this.props.userValuesChanges(userDetails);
                //$('.door-action').removeClass("d-closed");
            //}
        });

        await this.setUserCustomStatus("In a Meeting")
    }

    setUserCustomStatus = async (value) => {
        const { API_HOST, setUserCustomStatus } = this.props;
        let requestBody = {
            UserStatus: value
        }
        await setUserCustomStatus(API_HOST, requestBody, (res) => {

        });
    }

    handleChange(value) {
        this.setState({
            currentObject: {
                ...this.state.currentObject,
                isParticipant: value
            }
        });
    }


    render() {
        // if (this.state.redirect) {
        //     return <Redirect push to={'/zoom/' + this.state.meeting + '/' + this.state.password + '/' + this.state.currentObject.isParticipant.value} />;
        // }

        const { zoomModal } = this.state;
        const options = [{
            value: 0,
            label: 'Participant'
        }, {
            value: 1,
            label: 'Host'
        }]

        return (
            <>

            
            <div
                className="model-right-bar to_do_modal rightbar_sm"
                id="userloginmodel"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"  
            >

                <div className="modal-dialog jira-task-popup" role="document">

                    <div className="modal-content">
                        
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Join Zoom Meeting   
                        </h5>
                        <div
                        className="close b-left"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.toggle}
                        style={{ cursor: "pointer" }}
                        >
                        <i className="fa fa-close" />
                        </div>
                    </div>
                    
                    <div className=" modal-body ">
                        <div>
                        <FormGroup>

                            <label>Meeting Number</label>
                            <input type="text" id='meeting' placeholder="Meeting Number" required name='meeting' className='form-control' onChange={(e) => this.setState({ meeting: e.target.value })} value={this.state.meeting}></input>
                        </FormGroup>
                        <FormGroup>
                            <label>Password</label>
                            <input type="text" id='password' placeholder="Meeting Password" required name='password' className='form-control' onChange={(e) => this.setState({ password: e.target.value })} value={this.state.password}></input>
                        </FormGroup>
                        <FormGroup>
                            <label>Role</label>
                            <Select
                                components={makeAnimated}
                                onChange={newValue => this.handleChange(newValue)}
                                options={options}
                                value={this.state.currentObject.isParticipant}
                            />
                        </FormGroup>
                        </div>
                    </div>
                    
                    <div className="modal-footer justify-content-center">
                        <Button type="submit" color="primary" onClick={this.zoomJoin}>Join</Button>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </div>

                    </div>
                    
                </div>


            </div>









            </>
        );
    }
}
const mapStateToProps = state => ({
    API_HOST: getApiHost(),
})

export default connect(mapStateToProps, { zoomMeetingJoinLeave, doorClose, setUserCustomStatus })(ZoomMeeting);