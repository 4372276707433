import React from "react";
import { withRouter } from "react-router-dom";

class ZoomVerify extends React.Component {
  state = {};

  componentDidMount = () => {
    document.title = this.props.title; // Set title of page.
  };

  render() {
    return (
      <>5ccc466bbdad4e58877b1ec1f79ea23a</>
    );
  }
}

export default withRouter(ZoomVerify);
