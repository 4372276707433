import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDepartments } from "../../../actions/departmentActions";
import { getApiHost } from "../../../utils/apiUrls";
import { socket } from "../../../utils/apiUrls";
import { getUserDetail } from "../../../actions/dashboardActions";
import ReactTooltip from "react-tooltip";
import GoogleCalendarMeeting from "../../../components/google_calendar/GoogleCalendarMeeting";
import Insights from "../components/Insights";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //searchValue: "",
      //data: this.props.data,
      category: [{ name: "People" }, { name: "Department" }],
      dept_arr: [],
      user_arr: [],
      searchFocus: false,
      searchText: "",
      isUserArray: false,
      isDeptArray: false,
      showIcon: true,
      deptIdForHrs: 0,
      searchUsers: [],
      searchDepartment: [],
      isSearchEmpty: false,
      isGoogleCalendarMeeting: false
    };
    this.isSearchFocus = React.createRef();
  }

  async componentDidMount() {
    await this.getDepartments();
    await this.props.userListByDepartment(0);
    await this.props.departmentListByUser(0);
    socket.on("UserDetail", data => {
      console.log("asdf: ", data);
      if (
        JSON.parse(localStorage.getItem("userdetail")) && data.Id_Tenant ===
        JSON.parse(localStorage.getItem("userdetail")).Id_Tenant
      ) {
        this.updateUserDetail(data);
      } else {
        this.getUserDetail(data.Id_User);
      }
    });
    socket.on("newUserDetail", data => {
      this.addNewUser(data);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getUserDetailResponse !== this.props.getUserDetailResponse) {
      this.updateUserDetail(this.props.getUserDetailResponse);
    }
  }

  componentWillReceiveProps = (nextProps, prevState) => {
    if (nextProps.dept_data !== prevState.dept_data) {
      this.setState({ dept_arr: nextProps.dept_data });
    }
    if (nextProps.user_data !== prevState.user_data) {
      this.setState({ user_arr: nextProps.user_data });
    }
    if (nextProps.timeFilter !== this.props.timeFilter) {
      if (nextProps.timeFilter > 0) {
        this.distinctDepartmentforHrs(
          this.props.userListDepartmenttWithSelectedHrsFilterResponse
        );
        this.distinctUsersForHrs(
          this.props.userListDepartmenttWithSelectedHrsFilterResponse
        );
      } else {
        this.setState({
          dept_arr: this.props.dept_data,
          user_arr: this.props.user_data
        });
      }
    }

    if (
      nextProps.userListDepartmenttWithSelectedHrsFilterResponse !==
      this.props.userListDepartmenttWithSelectedHrsFilterResponse
    ) {
      this.clearSelection();
      if (this.props.timeFilter > 0) {
        this.distinctDepartmentforHrs(
          nextProps.userListDepartmenttWithSelectedHrsFilterResponse
        );
        this.distinctUsersForHrs(
          nextProps.userListDepartmenttWithSelectedHrsFilterResponse
        );
      }
    }

    if (nextProps.isUserSelected !== this.props.isUserSelected) {
      if (nextProps.isUserSelected === true) {
        this.clearSelection();
      }
    }
  };

  addNewUser = data => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var workspace = userData.Slack_WorkSpace_Name;
    let list = this.state.user_arr;

    if (workspace === data.Slack_WorkSpace_Name) {
      let temp = [];
      temp.push({
        Id: data.Id_User,
        Name: data.Real_Name,
        OtherContent: null,
        IsWaterCooler: false,
        UsageFrequency: null,
        Id_department: data.Id_Department
      });
      let newList = list.concat(temp);
      this.setState({
        user_arr: newList
      });
    }
  };

  getUserDetail = async id => {
    const { API_HOST, getUserDetail } = this.props;
    await getUserDetail(API_HOST, id);
  };

  updateUserDetail(data) {
    let newData = this.state.user_arr.map(item => {
      if (item.Id === data.Id_User) {
        item.Name = data.Real_Name + " (" + data.Department_Name + ")";
      }
      return item;
    });

    this.setState(Object.assign({}, { user_arr: newData }));
  }

  distinctDepartmentforHrs = usersList => {
    const result = [];
    const map = new Map();
    for (const item of usersList) {
      if (!map.has(item.Id_Department)) {
        map.set(item.Id_Department, true); // set any value to Map
        result.push({
          Id: item.Id_Department,
          Name: item.Department_Name
        });
      }
    }
    this.setState({ dept_arr: result, searchDepartment: result });
  };

  distinctUsersForHrs = usersList => {
    let users = usersList.map(function(row) {
      return {
        Id: row.Id_User,
        Name: row.Real_Name,
        Id_Department: row.Id_Department
      };
    });
    this.setState({ user_arr: users, searchUsers: users });
  };

  filterUsersforhrsWithDepartment = (userLists, deptId) => {
    let usersList = userLists.filter(user => user.Id_Department === deptId);
    this.setState({ user_arr: usersList, deptIdForHrs: deptId });
  };
  //Get Departments
  getDepartments = async () => {
    const { API_HOST, getDepartments } = this.props;
    await getDepartments(API_HOST);
  };

  search = (searchTerm, data) => {
    let results = [];
    if (data) {
      results = data.filter(function(object) {
        return object.Name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      return results;
    }
  };

  onSearchChange = e => {
    // this.setState({
    //   searchUsersList: true
    // })

    // this.props.searchResponse(e.target.value, this.props.data);
    if (e.target.value !== "") {
      //this.setState({ searchFocus: false, searchText: `${e.target.value}`, searchUsersList: true })
      if (this.state.isUserArray && !this.state.isSearchEmpty) {
        const text = e.target.value.slice(7);
        const user_res = this.search(text, this.state.searchUsers);
        this.setState({
          user_arr: user_res,
          searchFocus: false,
          searchText: `${e.target.value}`,
          searchUsersList: true
        });
      }
      if (this.state.isDeptArray && !this.state.isSearchEmpty) {
        const text = e.target.value.slice(11);
        const dept_res = this.search(text, this.state.searchDepartment);
        this.setState({
          dept_arr: dept_res,
          searchFocus: false,
          searchText: `${e.target.value}`,
          searchUsersList: true
        });
      }
      if (
        this.state.isSearchEmpty ||
        (!this.state.isUserArray && !this.state.isDeptArray)
      ) {
        if (this.props.timeFilter > 0) {
          this.distinctUsersForHrs(
            this.props.userListDepartmenttWithSelectedHrsFilterResponse
          );
          let user_res = this.search(e.target.value, this.state.searchUsers);
          this.setState({
            isUserArray: true,
            isSearchEmpty: true,
            deptIdForHrs: 0,
            searchFocus: false,
            searchText: `${e.target.value}`,
            user_arr: user_res,
            searchUsersList: true
          });
          //this.setState({ user_arr: user_res })
        } else {
          let user_res = this.search(e.target.value, this.props.user_data);
          this.setState({
            isUserArray: true,
            isSearchEmpty: true,
            deptIdForHrs: 0,
            searchFocus: false,
            searchText: `${e.target.value}`,
            user_arr: user_res,
            searchUsersList: true
          });
          //this.setState({ user_arr: user_res })
        }
      }
    } else {
      // this.props.departmentListByUser(0);
      // this.props.userListByDepartment(0);
      this.setState({
        searchFocus: true,
        searchText: `${e.target.value}`,
        isUserArray: false,
        isDeptArray: false,
        deptIdForHrs: 0,
        isSearchEmpty: false,
        searchUsersList: true
      });
    }
  };

  onTouchStart = () => {
    if (this.state.searchText === "") {
      this.setState({
        searchFocus: true,
        showIcon: false
      });
    }
  };

  onTouchEnd = () => {
    this.setState({ searchFocus: false });
  };

  selectedCategory = category => {
    this.isSearchFocus.current.focus();
    this.setState({
      searchFocus: false,
      // searchText: `${this.state.searchText}${category}:`,
      searchText: `${category}:`
    });

    if (category === "People") {
      if (this.props.timeFilter > 0) {
        if (this.state.deptIdForHrs === 0) {
          this.distinctUsersForHrs(
            this.props.userListDepartmenttWithSelectedHrsFilterResponse
          );
        }
        this.setState({
          isUserArray: true,
          isDeptArray: false,
          deptIdForHrs: 0,
          isSearchEmpty: false
        });
      } else {
        this.setState({
          user_arr: this.props.user_data,
          isUserArray: true,
          isDeptArray: false,
          searchUsers: this.props.user_data,
          isSearchEmpty: false
        });
      }
    }
    if (category === "Department") {
      if (this.props.timeFilter > 0) {
        this.distinctDepartmentforHrs(
          this.props.userListDepartmenttWithSelectedHrsFilterResponse
        );
        this.setState({
          isDeptArray: true,
          isUserArray: false,
          isSearchEmpty: false
        });
      } else {
        this.setState({
          dept_arr: this.props.dept_data,
          isDeptArray: true,
          isUserArray: false,
          searchDepartment: this.props.dept_data,
          isSearchEmpty: false
        });
      }
    }
  };

  selectedDepartment = (deptName, deptId) => {
    this.setState({
      isDeptArray: false,
      searchFocus: true,
      //searchText:`${this.state.searchText} ${deptName}:`
      searchText: `${deptName}:`,
      isSearchEmpty: false
    });
    if (this.props.timeFilter > 0) {
      this.filterUsersforhrsWithDepartment(this.state.user_arr, deptId);
    } else {
      this.props.userListByDepartment(deptId);
    }
    this.props.getDepartmentId(deptId);
  };

  selectedUser = (userName, userId, dptId) => {
    this.setState({
      isUserArray: false,
      //searchFocus: true,
      // searchText:`${this.state.searchText} ${userName}:`
      searchText: `${userName}`,
      isSearchEmpty: false
    });
    this.props.departmentListByUser(userId);
    this.props.getDepartmentId(dptId); // Remain to add department key in API
    this.props.getSearcbarUser(userId);
  };

  clearSelection = () => {
    if (this.props.timeFilter > 0) {
      this.distinctDepartmentforHrs(
        this.props.userListDepartmenttWithSelectedHrsFilterResponse
      );
      this.distinctUsersForHrs(
        this.props.userListDepartmenttWithSelectedHrsFilterResponse
      );
      this.props.getSearcbarUser(0);
    } else {
      this.props.departmentListByUser(0);
      this.props.userListByDepartment(0);
      this.props.getSearcbarUser(0);
    }
    this.setState({
      //searchFocus: true,
      searchFocus: false,
      searchText: "",
      isUserArray: false,
      isDeptArray: false,
      showIcon: true,
      deptIdForHrs: 0,
      isSearchEmpty: false,
      isInsights: false
    });
  };

  // onBlurSearch = () => {
  //   this.setState({
  //     //searchFocus: true,
  //     searchFocus: false,
  //     isUserArray: false,
  //     isDeptArray: false,
  //     isSearchEmpty: false
  //   })
  // }

  onClickGoogleCalendarMeeting = () =>{
    this.setState({ isGoogleCalendarMeeting: !this.state.isGoogleCalendarMeeting});
  }

  onInsights = () => {
    this.setState({ isInsights: true });
  }
  
  closeInsights = () => {
    this.setState({ isInsights: false });
  }

  render() {
    let placeholderText = "Search...";
    if (this.props.placeHolderText)
      placeholderText = this.props.placeHolderText;

    return (
      <>
        <div className="topBarBox">
          <div>
            <i class="fas fa-home" onClick={e => this.props.onTimeSelected(e, 0)}></i>
            <span onClick={e => this.props.onTimeSelected(e, 0)}>Home</span>
          </div>
          {/* <div>
            <i class="fas fa-user"></i>
            <span>Profile </span>
          </div> */}
          <div>
          <i class="fas fa-cog" onClick={this.props.toggleMoreInfo}></i>
            <span onClick={this.props.toggleMoreInfo}>Settings</span>
          </div>
          <div>
          <i class="far fa-calendar-alt" onClick={this.onClickGoogleCalendarMeeting}></i>
            <span onClick={this.onClickGoogleCalendarMeeting}>Calendar</span>
          </div>
          <div>
          <i class="fas fa-chart-line" onClick={this.onInsights}></i>
            <span onClick={this.onInsights}>Insights</span>
          </div>
        </div>

        <div
          data-for="serachBar"
          data-tip="Search People, Workgroups and any discussion tagged."
          className="form-group search-box main-search-box"
        >
          <ReactTooltip
            id="serachBar"
            place="left"
            type="dark"
            effect="solid"
            multiline={true}
            backgroundColor="#2c69d1"
            textColor="white"
            border="true"
          />
          {this.state.showIcon ? (
            <span className="form-control-feedback">
              <img src="images/search-icon.png" alt="" />
            </span>
          ) : (
            <i
              class="fa fa-times"
              aria-hidden="true"
              style={{ cursor: "pointer" }}
              onClick={this.clearSelection}
            ></i>
          )}

          {/* <i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={this.clearSelection}></i> */}
          <input
            type="text"
            ref={this.isSearchFocus}
            onClick={this.onTouchStart}
            className={`${
              this.state.isUserArray ||
              this.state.isDeptArray ||
              this.props.isMainOffice ||
              this.state.searchFocus
                ? "form-control search-results-bar"
                : "form-control"
            } `}
            value={this.state.searchText}
            placeholder={placeholderText}
            onChange={e => this.onSearchChange(e)}
          />

          {this.state.isUserArray ? (
            <div className="search-results myscroll">
              {this.state.user_arr.map(user => {
                return (
                  <div
                    className="options"
                    onClick={() => {
                      this.selectedUser(user.Name, user.Id, user.Id_department);
                    }}
                  >
                    {user.Name}
                  </div>
                );
              })}
            </div>
          ) : null}

          {this.state.isDeptArray ? (
            <div className="search-results myscroll">
              {this.state.dept_arr.map(dept => {
                return (
                  <div
                    className="options"
                    onClick={() => {
                      this.selectedDepartment(dept.Name, dept.Id);
                    }}
                  >
                    {dept.Name}
                  </div>
                );
              })}
            </div>
          ) : null}

          {this.state.searchFocus ? (
            <div className="search-results myscroll">
              {this.state.category.map(category => {
                return (
                  <div
                    className="options"
                    onClick={() => {
                      this.selectedCategory(category.name);
                    }}
                  >
                    {category.name}
                  </div>
                );
              })}
            </div>
          ) : null}

          {this.state.isGoogleCalendarMeeting ? (
            <GoogleCalendarMeeting 
                userValuesChanges={this.props.userValuesChanges}
                userDetails={this.props.userDetails}
                onClickGoogleCalendarMeeting={this.onClickGoogleCalendarMeeting}
                isGoogleCalendarMeeting={this.state.isGoogleCalendarMeeting}
            />
          ) : null}

          {this.state.isInsights && <Insights isInsights={this.state.isInsights} closeInsights={this.closeInsights} />}
        </div>
      </>
    );
  }
}

SearchBar.propTypes = {
  onSearchTextChange: PropTypes.func,
  onSearchButtonClick: PropTypes.func,
  placeHolderText: PropTypes.string,
  data: PropTypes.array,
  API_HOST: PropTypes.string,
  getDepartments: PropTypes.func,
  getDepartmentsResponse: PropTypes.array
};

// Set default props.
SearchBar.defaultProps = {
  getDepartments: () => {},
  getDepartmentsResponse: []
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  getDepartmentsResponse: state.getDepartmentReducer.getDepartmentsResponse,
  userListDepartmenttWithSelectedHrsFilterResponse:
    state.mainOfficeReducer.userListDepartmenttWithSelectedHrsFilterResponse,
  getUserDetailResponse:
    state.dashboardReducer.getUserDetailResponse.userDetailResponse
});

export default connect(mapStateToProps, { getUserDetail, getDepartments })(
  SearchBar
);
