import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.scss';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/index';
import Routers from './routers/Routers';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'


const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware
  )
)


function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Router>
          <Routers />
        </Router>
      </Provider>
  </React.Fragment>
  );
}

export default App;
