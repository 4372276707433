import React from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getApiHost, socket } from "../../../utils/apiUrls";
import {
  addOffice,
  getOfficeById,
  fetchOffice,
} from "../../../actions/officeActions";
import { getDepartmentFilterWise } from "../../../actions/mainOfficeActions";
import SelectTimezone, { getTimezoneProps } from "@capaj/react-select-timezone";
import "moment-timezone";
import moment from "moment";
//import Select from 'react-select';
//import DatePicker from 'react-datepicker';
import TimePicker from "rc-time-picker";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";

// const employeeOption = [
//   { value: '1-5', label: '1-5' },
//   { value: '5-15', label: '5-15' },
//   { value: '15-25', label: '15-25' },
//   { value: '25+', label: '25+' },
// ];

// const cabinOption = [
//   { value: '2+', label: '2+' },
//   { value: '5+', label: '5+' },
//   { value: '8+', label: '8+' },
// ]

class AddEditOffice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Address: "",
      Phone: "",
      Zipcode: "",
      startTime: new Date(),
      endTime: new Date(),
      selectedEmployeeOption: { value: "25+", label: "25+" },
      selectedCabinOption: { value: "8+", label: "8+" },
      officeId: this.props.office_Id,
      officeDetails: {},
      loading: true,
    };
  }

  async componentDidMount() {
    // if (this.state.officeId !== 0) {
    //   await this.getOfficeById(this.props.office_Id);
    // }

    var officeId = JSON.parse(
      localStorage.getItem("userdetail")
    ).Id_TenantOffice;
    await this.getOfficeById(officeId);
  }

  //Close Create Office Modal
  onCloseAddOffice = () => {
    this.setState({
      officeId: 0,
      Name: "",
      Address: "",
      Phone: "",
      Zipcode: "",
      officeDetails: {},
      startTime: new Date(),
      endTime: new Date(),
      selectedEmployeeOption: null,
      selectedCabinOption: null,
    });
    this.props.toggleCloseAddOffice();
  };

  getDepartments = () => {
    const { API_HOST, getDepartmentFilterWise } = this.props;
    const requestBody = {
      Id_User: JSON.parse(localStorage.getItem("userdetail")).Id_User,
      Id_Department: 0,
    };
    getDepartmentFilterWise(API_HOST, requestBody);
  };

  //Add Office
  addOffice = () => {
    var apiCall = true;
    const { API_HOST, addOffice } = this.props;
    let startTime =
      new Date(this.state.startTime).getHours() * 60 +
      new Date(this.state.startTime).getMinutes();
    let endTime =
      new Date(this.state.endTime).getHours() * 60 +
      new Date(this.state.endTime).getMinutes();

    if (startTime > endTime) {
      toast.error("Start Time cannot be greater than End Time.");
      apiCall = false;
    }
    if (this.state.Name.length > 20) {
      toast.error("Office name can be upto 10 characters only.");
      apiCall = false;
    }
    if (this.state.Name === "") {
      toast.error("Office name cannot be empty.");
      apiCall = false;
    }
    // if(!this.state.Phone.match(/[1-9]{1}[0-9]{9}/)) {
    //   toast.error("Phone number can have only numbers and length upto 10 characters");
    //   apiCall = false;
    // }
    if (apiCall) {
      const addOfficeRequest = {
        Id: this.state.officeId,
        Name: this.state.Name,
        Address: this.state.Address,
        Phone: this.state.Phone,
        Zipcode: this.state.Zipcode,
        Is_Active: true,
        Is_Deleted: false,
        No_of_Employee: this.state.selectedEmployeeOption.value,
        No_of_Department: this.state.selectedCabinOption.value,
        Start_Time: startTime,
        End_Time: endTime,
        TZ: this.state.officeDetails["TZ"],
        TZ_Label: this.state.officeDetails["TZ_Label"],
        TZ_Offset: this.state.officeDetails["TZ_Offset"],
      };

      addOffice(API_HOST, addOfficeRequest, async (res) => {
        this.props.fetchOffice();
        await this.props.getUserDetail(
          JSON.parse(localStorage.getItem("userdetail")).Id_User
        );
        socket.emit("editOfficeName", this.props.userDetailResponse);

        let userDetails = JSON.parse(localStorage.getItem("userdetail"));
        userDetails.UserStartTime_EndTimeVM.Availibility_Time =
          moment(this.state.startTime).format("HH:mm") +
          " to " +
          moment(this.state.endTime).format("HH:mm");
        localStorage.setItem("userdetail", JSON.stringify(userDetails));
        var office_detail = "UserStartTime_EndTimeVM.Availibility_Time";
        socket.emit("updateOfficeTime", userDetails, office_detail);

        //this.getDepartments();
      });
      this.onCloseAddOffice();
    }
  };

  //Get Office by Id
  getOfficeById = (id) => {
    const { API_HOST, getOfficeById } = this.props;
    getOfficeById(API_HOST, id, (res) => {
      const dt = new Date();
      let officeDetails = this.state.officeDetails;
      officeDetails["TZ"] = res.TZ;
      officeDetails["TZ_Label"] = res.TZ_Label;
      officeDetails["TZ_Offset"] = res.TZ_Offset / 60;
      this.setState({
        Name: res.Name,
        Address: res.Address,
        Phone: res.Phone,
        Zipcode: res.Zipcode,
        officeDetails,
        startTime: new Date(dt.setHours(0, res.Start_Time, 0, 0)),
        endTime: new Date(dt.setHours(0, res.End_Time, 0, 0)),
        selectedEmployeeOption: res.No_of_Employee,
        selectedCabinOption: res.No_of_Department,
        officeId: res.Id,
        loading: false,
      });
    });
  };

  handleAddOffice = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Functions calls when timezone is changing
  handleChangeTimezone = (tz, label, offset) => {
    let officeDetails = {};
    officeDetails["TZ"] = tz;
    officeDetails["TZ_Label"] = label;
    officeDetails["TZ_Offset"] = offset * 60;
    this.setState({ officeDetails: officeDetails });
  };

  //Function calls when Number of Employees selected
  handleEmployeeChange = (selectedEmployeeOption) => {
    this.setState({ selectedEmployeeOption });
  };

  //Function call when Cabin selected
  handleCabinChange = (selectedCabinOption) => {
    this.setState({ selectedCabinOption });
  };

  /// function added for new time picker
  setStartTime = async (date) => {
    if (date === null) {
      toast.error("Start Time cannot be empty");
      date = new Date().setHours(0, 0, 0);
    }
    var sTime = moment(date).toDate();
    await this.setState({ startTime: sTime });
  };

  setEndTime = async (date) => {
    if (date === null) {
      toast.error("End Time cannot be empty");
      date = new Date().setHours(0, 0, 0);
    }
    var eTime = moment(date).toDate();
    await this.setState({ endTime: eTime });
  };

  validateOfficeName = (e) => {
    const officeNameText = e.target.value;
    if (officeNameText.length > 20) {
      toast.error("Office name can be upto 10 characters only.");
    }
    if (officeNameText.length === 0) {
      toast.error("Office name cannot be empty.");
    }
  };

  validatePhoneNumber = (e) => {
    const phoneNumber = e.target.value;
    var pattern = /[1-9]{1}[0-9]{9}/;
    if (e.target.name === "Phone" && !phoneNumber.match(pattern)) {
      toast.error(
        "Phone number can have only numbers and length upto 10 characters"
      );
    }
  };

  render() {
    return (
      <div
        className="model-right-bar to_do_modal rightbar_sm model-left-bar"
        id="userloginmodel"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog jira-task-popup" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.officeId === 0 ? "Create Office" : "Edit Office"}
              </h5>
              <div
                className="close b-left"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.onCloseAddOffice()}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-close" />
              </div>
            </div>

            <LoadingOverlay
              active={this.state.loading}
              spinner
              text="Loading..."
            >
              <div className=" modal-body ">
                {/* <form method="post" id="form_office" onSubmit={this.addOffice}> */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Office Name: </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        //placeholder={this.state.officeId === 0 ? 'Enter office name ' : ''}
                        value={this.state.Name}
                        name="Name"
                        onChange={(e) => this.handleAddOffice(e)}
                        required
                        onBlur={(e) => this.validateOfficeName(e)}
                      />
                    </div>

                    <div className="form-group">
                      <label>Office Address: </label>
                      <textarea
                        rows="3"
                        type="text"
                        className="form-control"
                        id="address"
                        //placeholder={this.state.officeId === 0 ? 'Enter office address ' : ''}
                        value={this.state.Address}
                        name="Address"
                        onChange={(e) => this.handleAddOffice(e)}
                        //required
                      />
                    </div>

                    <div className="form-group">
                      <label>Phone Number: </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        //placeholder={this.state.officeId === 0 ? 'Enter phone number ' : ''}
                        value={this.state.Phone}
                        name="Phone"
                        onChange={(e) => this.handleAddOffice(e)}
                        // pattern="[1-9]{1}[0-9]{9}"
                        // maxLength={10}
                        // title="Phone number should not start with 0 and sholud be exactly 10 digit number"
                        //required
                        onBlur={(e) => this.validatePhoneNumber(e)}
                      />
                    </div>

                    <div className="form-group">
                      <label>Zipcode: </label>
                      <input
                        style={{ width: "180px" }}
                        type="text"
                        className="form-control"
                        id="zipcode"
                        //placeholder={this.state.officeId === 0 ? 'Enter zipcode ' : ''}
                        value={this.state.Zipcode}
                        name="Zipcode"
                        onChange={(e) => this.handleAddOffice(e)}
                        //required
                      />
                    </div>

                    <div className="form-group">
                      <label>Select Time Zone: </label>
                      <div>
                        <SelectTimezone
                          value={this.state.officeDetails["TZ"]}
                          clearable
                          guess
                          //placeholder={this.state.officeId === 0 ? 'Select time zone ' : ''}
                          onChange={(value) => {
                            const timezoneProps = getTimezoneProps(value);
                            this.handleChangeTimezone(
                              timezoneProps.value,
                              moment.tz(timezoneProps.value).format("zz"),
                              timezoneProps.offset
                            );
                          }}
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="form-group">
                  <label>Number of Employees: </label>
                  <Select
                    value={this.state.selectedEmployeeOption}
                    //placeholder={this.state.officeId === 0 ? 'Select number Employees ' : ''}
                    onChange={this.handleEmployeeChange}
                    name="employee_number"
                    options={employeeOption}
                    isDisabled={this.state.officeId === 0 ? false : true}
                  />
                </div>

                <div className="form-group">
                  <label>Cabins required: </label>
                  <Select
                    value={this.state.selectedCabinOption}
                    //placeholder={this.state.officeId === 0 ? 'Select cabins required ' : ''}
                    onChange={this.handleCabinChange}
                    name="cabin_number"
                    options={cabinOption}
                    isDisabled={this.state.officeId === 0 ? false : true}
                  />
                </div> */}

                    <div className=" d-flex justify-content-between ">
                      {/* <div className="date-range-center d-flex w-50 pr-2"> */}
                      {/* <label className="mr-auto">Start Time: </label>
                    <DatePicker
                      selected={this.state.startTime}
                      onChange={date => this.setState({ startTime: date })}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="form-control"
                    />
                  </div>
                  <div className="date-range-center d-flex w-50 pl-2">
                    <label className="mr-auto">End Time: </label>
                    <DatePicker
                      selected={this.state.endTime}
                      onChange={date => this.setState({ endTime: date })}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="form-control"
                    /> */}
                      <div className="date-range-center   w-50 pr-2">
                        <label for="starttime">Start Time</label>
                        <TimePicker
                          onChange={(date) => this.setStartTime(date)}
                          // defaultValue={moment(new Date()).set({hour:this.state.startTime.getHours(),minute:this.state.startTime.getMinutes()})} //working
                          value={moment(
                            `${this.state.startTime.getHours()}:${this.state.startTime.getMinutes()}`,
                            "HH:mm"
                          )}
                          showSecond={false}
                          format="HH:mm"
                          hideDisabledOptions
                          type="time"
                          style={{ width: "80px" }}
                        />
                      </div>
                      <div className="date-range-center  w-50 pr-2">
                        <label for="endtime">End Time</label>

                        <TimePicker
                          onChange={(date) => this.setEndTime(date)}
                          //defaultValue={moment(new Date()).set({hour:19,minute:0})}  //working
                          value={moment(
                            `${this.state.endTime.getHours()}:${this.state.endTime.getMinutes()}`,
                            "HH:mm"
                          )}
                          showSecond={false}
                          format="HH:mm"
                          hideDisabledOptions
                          style={{ width: "80px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="justify-content-center mt-4">
                  <Button
                    color="primary"
                    type="submit"
                    onClick={this.addOffice}
                  >
                    Save
                  </Button>
                </div>
                {/* </form> */}
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    );
  }
}

// Prop types of props.
AddEditOffice.propTypes = {
  API_HOST: PropTypes.string,
  addOffice: PropTypes.func,
  getOfficeById: PropTypes.func,
};

// Set default props.
AddEditOffice.defaultProps = {
  addOffice: () => {},
  getOfficeById: () => {},
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
  //getuserdetailresponse: state.dashboardReducer.userDetailResponse.userDetailResponse
});

export default connect(mapStateToProps, {
  addOffice,
  getOfficeById,
  fetchOffice,
  getDepartmentFilterWise,
})(AddEditOffice);
