import { types as T } from '../actions/action';

const initialState = {
  departmentListFilterWiseResponse: [],
  userListDepartmentFilterResponse: [],
  editDeptNameResponse: [],
  userKnockListCabinWiseResponse: [],
  stickyCountResponse: 0,
  getCabinUserStickyListResponse:[],
  userListDepartmenttWithSelectedHrsFilterResponse:[]
}

export default (state = initialState, action) => {
  switch (action.type) {
    case T.RECEIVE_DEPARTMENT_FILTERWISE:
      return {
        ...state,
        departmentListFilterWiseResponse: action.departmentListFilterWiseResponse.Data.SourceData
      }

    case T.RECEIVE_USER_LIST_DEPARTMENT_FILTER:
      return {
        ...state,
        userListDepartmentFilterResponse: action.userListDepartmentFilterResponse.Data.SourceData
      }

    case T.RECEIVE_EDIT_DEPT_NAME:
      return {
        ...state,
        editDeptNameResponse: action.editDeptNameResponse
      }

    case T.RECEIVE_USER_KNOCK_LIST_CABIN_WISE:
      return {
        ...state,
        userKnockListCabinWiseResponse: action.userKnockListCabinWiseResponse,
      }

    case T.RECEIVE_STICKY_COUNT:
      return {
        ...state,
        stickyCountResponse: action.stickyCountResponse,
      };

    case T.RECEIVE_GET_CABIN_USER_STICKY_LIST:
      return {
        ...state,
          getCabinUserStickyListResponse: action.getCabinUserStickyListResponse,
      };

      case T.RECEIVE_USER_LIST_BY_DEPARTMENT_WITH_SELECTED_HRS:
      return {
        ...state,
          userListDepartmenttWithSelectedHrsFilterResponse: action.userListDepartmenttWithSelectedHrsFilterResponse.Data.SourceData,
      };


    default:
      return state
  }
}