import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from '../../../utils/apiUrls';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FormGroup, Modal, ModalBody, ModalHeader, Row, Col, Button, ModalFooter } from 'reactstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import UnassignedUser from '../../../assets/images/UnassignedUser.png';
import ClockLoader from "react-spinners/ClockLoader";
import {updateUserToDoToJira} from "../../../actions/todoActions";
import { getJiraDomain } from "../../../actions/jiraActions";
import PrivateAddNewTask from "./PrivateAddNewTask";
import { getEncryptedValue, getDecryptedValue } from "../../../utils/utility";

class AddNewTask extends React.Component {
  state = {
    loading: true,
    isActive: false,
    projectList: [],
    projectSelected: null,
    issueTypeList: [],
    issueTypeSelected: null,
    assignUsersOption: [],
    userSelected: null,
    summary: "",
    description: "",
    reporterSelected: [],
    accessToken: "",
    isCreateDisabled: false,
    cloudId: "",
    selectTaskIssueType: true,
    jiraDomainSelected:{},
    jiraDomain:[],
    isPrivateJiraSelected: this.props.isPrivateJiraSelected,
    showPrivateJiraAddTask: false
  };

  async componentDidMount() {
    if(this.state.isPrivateJiraSelected){
        this.setState({ showPrivateJiraAddTask: true })
    }else{
        await this.setState({ accessToken: this.props.accessToken, cloudId: this.props.cloudId })
        //
        if(this.props.todoId > 0 || this.props.isOpenAddTaskPopup){
          this.getJiraDomain();
        }//
        this.getprojectsList();
  
    console.log("last project", this.props.lastTaskProject)
    if (this.props.lastTaskProject) {
      let projectSelected = {
        id: this.props.lastTaskProject.fields.project.id,
        avatarUrls: this.props.lastTaskProject.fields.project.avatarUrls,
        name: this.props.lastTaskProject.fields.project.name,
        key: this.props.lastTaskProject.fields.project.key,
        self: this.props.lastTaskProject.fields.project.self,
        projectTypeKey: this.props.lastTaskProject.fields.project.projectTypeKey,
        label: this.props.lastTaskProject.fields.project.name + "(" + this.props.lastTaskProject.fields.project.key + ")",
        value: this.props.lastTaskProject.fields.project.name
      }

      let reporterSelected = {
        accountId: this.props.lastTaskProject.fields.reporter.accountId,
        accountType: this.props.lastTaskProject.fields.reporter.accountType,
        active: this.props.lastTaskProject.fields.reporter.active,
        displayName: this.props.lastTaskProject.fields.reporter.displayName,
        label: this.props.lastTaskProject.fields.reporter.displayName,
        value: this.props.lastTaskProject.fields.reporter.displayName
      }

      let issueTypeSelected = {
        id: this.props.lastTaskProject.fields.issuetype.id,
        // fields: obj.fields,
        name: this.props.lastTaskProject.fields.issuetype.name,
        description: this.props.lastTaskProject.fields.issuetype.description,
        self: this.props.lastTaskProject.fields.issuetype.self,
        subtask: this.props.lastTaskProject.fields.issuetype.subtask,
        label: <div><img src={this.props.lastTaskProject.fields.issuetype.iconUrl} alt="" height="20px" width="20px" />{"     " + this.props.lastTaskProject.fields.issuetype.name}</div>,
        value: this.props.lastTaskProject.fields.issuetype.name
      }
      let userselected = {
        accountId: "-1",
        displayName: "Unassigned",
        accountName: '',
        label: <div><img src={UnassignedUser} alt="" height="20px" width="20px" />{"     Unassigned"}</div>,
        value: "-1",
      }
      this.setState({
        projectSelected: projectSelected, 
        issueTypeSelected: issueTypeSelected, 
        reporterSelected: reporterSelected,
        userSelected: userselected
      })
      this.getIssueType(this.props.lastTaskProject.fields.project);
      this.jiraUsers(this.props.lastTaskProject.fields.project);
    }
  }
  }

  getprojectsList = async () => {
    try {
      let finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/project/search?expand=lead%2Curl%2Cfavourite%2Cpermissions&maxResults=50&orderBy=%2BNAME&startAt=0`

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ projectList: data.values })
      console.log("projects list", data)
    }
    catch (error) {
      // if (error.response.status === 401) {
      //   let gettoken = await this.props.getAccessTokenAgain();
      //   this.setState({ accessToken: gettoken })
      //   this.getprojectsList()
      // }
      console.log(error);
      toast.error(error)
    }
  }

  getIssueType = async (newValue) => {
    try {
      let finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/issue/createmeta?projectKeys=${newValue.key}&expand=projects.issuetypes.fields`

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ issueTypeList: data.projects[0].issuetypes })
      console.log("issue type list", data)
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.getIssueType(newValue)
      }
      toast.error(error)
    }
  }


  onSelectprojects = async (newValue) => {
    this.setState({ projectSelected: newValue })
    this.getIssueType(newValue)
    this.jiraUsers(newValue);

  }

  setIssueType = (newValue) => {
    this.setState({ issueTypeSelected: newValue })
    console.log("issueTypeSelected: ", this.state.issueTypeSelected);
    if(newValue.value === "Epic" || newValue.value === "Story" || newValue.value === "Bug" || newValue.value === "Subtask"){
      toast.info("Functionality to be Developed for the selected option.");
      this.setState({ selectTaskIssueType: false, isCreateDisabled: true })
    }else{
      this.setState({ selectTaskIssueType: true, isCreateDisabled : false })
    }
  }

  jiraUsers = async (newValue) => {
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/user/assignable/search?project=` + newValue.key

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ assignUsersOption: data })
      console.log("users data", data)
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.jiraUsers(newValue)
      }
      toast.error(error)
    }
    this.setState({ isActive: false })

  }

  userAssignChange = (newValue) => {
    this.setState({ userSelected: newValue });
    // this.changeAssigneefromJira(newValue)
  }

  createTask = async (e) => {
    e.preventDefault();
    this.setState({ isCreateDisabled: true })
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/issue/`

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };

      let resource = {};

      resource = {
        "fields":
        {
          "project":
          {
            "key": this.state.projectSelected.key
          },
          "summary": this.state.summary,
          "description": this.props.isToDoDescription ? this.props.todoDescription : this.state.description,
          "issuetype": {
           // "name": this.state.issueTypeSelected.name
           "name": "Task"
          },
        }
      }
      if (this.state.userSelected !== null) {
        resource.fields["assignee"] = {
          "accountId": this.state.userSelected.accountId

        }
      }
      // if(this.state.reporterSelected!==null){
      //   resource.fields["reporter"]= {
      //     "name": this.state.reporterSelected.displayName
      //  }
      // }

      const { data } = await axios.post(finalUrl, resource, { headers });
      this.props.closeAddTask();
      this.props.getJiraYourTaskList(this.state.cloudId, this.state.accessToken);
      console.log("task created=", data)
      if(data && this.props.todoId > 0){
        this.updateUserToDoToJira(data.id, data.key)
      }
      
    }
    catch (error) {
      toast.error(error)
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.createTask(e)
      }
    }
    this.setState({ isCreateDisabled: false })
  }

  reporterAssignChange = (newValue) => {
    this.setState({ reporterSelected: newValue });
  }

  updateUserToDoToJira = (jiraId, jiraKey) => {
    const { API_HOST, updateUserToDoToJira } = this.props;

    let requestBody = {
      Id: this.props.todoId,
      Jira_Task_Date: "",
      Id_Jira_Task: jiraId,
      Is_Jira_Task: "true",
      Jira_Task_Key: jiraKey,
      Jira_Cloud_Id: getEncryptedValue(this.state.cloudId)
    };

    updateUserToDoToJira(API_HOST, requestBody, res => {
      if (res) {
        this.props.userToDoList(0);
      }
    });
  };

  ////
  getJiraDomain = async() => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, (res) => {
      if(res[0]){
      let newValue=  res.map(function(obj) {
        return { 
          "id": getDecryptedValue(obj.CloudId),
          "CloudId": getDecryptedValue(obj.CloudId),
          "Domain_Name": obj.Domain_Name,
          "key": obj.Domain_Name,
          "url": obj.url,
          "Jira_Scope_Detail": obj.Jira_Scope_Detail,
          "label": obj.Domain_Name,
          "value": getDecryptedValue(obj.CloudId)
     }})
     this.setState({jiraDomain:newValue,jiraDomainSelected: newValue[0], cloudId: newValue[0].CloudId })
    }
    })
}

////
onSelectDomain = async(newValue) =>{
  await this.setState({ jiraDomainSelected: newValue, cloudId: newValue.CloudId })
  await this.getprojectsList();
  
}


  render() {
    let options = [];
    options.push({
      accountId: "-1",
      displayName: "Unassigned",
      accountName: '',
      label: <div><img src={UnassignedUser} alt="" height="20px" width="20px" />{"     Unassigned"}</div>,
      value: "-1",
    })
    this.state.assignUsersOption[0] && this.state.assignUsersOption.map(obj => {
      options.push({
        accountId: obj.accountId,
        accountName: obj.key,
        accountType: obj.accountType,
        active: obj.active,
        displayName: obj.displayName,
        label: <div><img src={obj.avatarUrls["16x16"]} alt="" height="20px" width="20px" />{"     " + obj.displayName}</div>,
        value: obj.displayName
      })
      return options;
    })
    //     let statusCategoryOptions=[];
    //     this.state.statusCategory[0] && this.state.statusCategory.map(obj => {
    //       statusCategoryOptions.push({ description: obj.description,
    //         id: obj.id,
    //         name: obj.name,
    //         value:obj.id,
    //         self:obj.self,
    //         statusCategory:obj.statusCategory,
    //         label:obj.name
    //       })
    //     return statusCategoryOptions;
    //   })
    let projectlistOptions = [];
    this.state.projectList[0] && this.state.projectList.map(obj => {
      projectlistOptions.push({
        id: obj.id,
        avatarUrls: obj.avatarUrls,
        name: obj.name,
        key: obj.key,
        self: obj.self,
        projectTypeKey: obj.projectTypeKey,
        label: obj.name + "(" + obj.key + ")",
        value: obj.name
      })
      return projectlistOptions;
    })
    let issueTypeOptions = [];
    this.state.issueTypeList[0] && this.state.issueTypeList.map(obj => {
      if (obj !== "Subtask") {
        issueTypeOptions.push({
          id: obj.id,
          fields: obj.fields,
          name: obj.name,
          description: obj.description,
          self: obj.self,
          subtask: obj.subtask,
          label: <div><img src={obj.iconUrl} alt="" height="20px" width="20px" />{"     " + obj.name}</div>,
          value: obj.name
        })
      }
      return issueTypeOptions;
    })

    return (
      <React.Fragment>
        {this.state.showPrivateJiraAddTask ? 
         <PrivateAddNewTask getPrivateJiraMyTaskList={this.props.getPrivateJiraMyTaskList} closeAddTask={this.props.closeAddTask} jiraDomainSelected={this.props.jiraDomainSelected}/> 
        : 
      
      <Modal isOpen={this.props.isAddTask} size={'l'} backdrop={true}  >
        <ModalHeader toggle={() => this.props.closeAddTask()}>
          Add Jira Task
                    </ModalHeader>
        <form method="post" onSubmit={(e) => { this.createTask(e) }} id="createJiraTask">
          <ModalBody>

              <Row>
                {this.props.todoId > 0 || this.props.isOpenAddTaskPopup === true ? 
                <Col xs="6">
                  <FormGroup>
                  <h6>Jira Domain</h6>
                  <Select
                      components={makeAnimated}
                      onChange={(newValue) => { this.onSelectDomain(newValue) }}
                      options={this.state.jiraDomain}
                      value={this.state.jiraDomainSelected}
                  />
                  </FormGroup>
                </Col>
                : ""}
                <Col xs="6" >
                <FormGroup>
                  <h6>Project</h6>
                  <Select
                    components={makeAnimated}
                    onChange={(newValue) => { this.onSelectprojects(newValue) }}
                    options={projectlistOptions}
                    value={this.state.projectSelected}
                  />
                  </FormGroup>
                </Col>
                {/* <Col xs="6" >
                <FormGroup>
                  <h6>Issue Type</h6>
                  <Select
                    components={makeAnimated}
                    onChange={(newValue) => { this.setIssueType(newValue) }}
                    options={issueTypeOptions}
                    value={this.state.issueTypeSelected}
                  />
                  </FormGroup>
                </Col> */}
              </Row>
            
            {this.state.selectTaskIssueType && 
            <>
            <FormGroup>
              <h6>Summary</h6>
              <textarea className="form-control" required rows="1" onChange={(e) => this.setState({ summary: e.target.value })} value={this.state.summary}></textarea>
            </FormGroup>

            <FormGroup>
              <h6>Description</h6>
              <textarea className="form-control" rows="4" onChange={(e) => this.setState({ description: e.target.value })} value={this.props.isToDoDescription ? this.props.todoDescription : this.state.description}></textarea>
            </FormGroup>
            {/* <div>{this.props.jiraIssue.fields.description}</div> */}
            
            <h6>Assignee</h6>
            <Row>
              <Col xs="5" >
                <Select
                  components={makeAnimated}
                  onChange={(newValue) => { this.userAssignChange(newValue) }}
                  options={options}
                  value={this.state.userSelected}
                />
              </Col>
            </Row>
            </>}
            {/* <br/>
             <h6>Reporter</h6>
             <Row>
             <Col xs="5" >
              <Select
                components={makeAnimated}
                onChange={(newValue) => {this.reporterAssignChange(newValue)}}
                options={options}
                value={this.state.reporterSelected}
              />
              </Col>
              </Row> */}
            {/* <br/>
              <h6> Sprint</h6>
             <Row>
                                <Col xs="5" >
             <Select
                components={makeAnimated}
               // onChange={(newValue) => {this.setStatusforJira(newValue)}}
              //  options={statusCategoryOptions}
               // value={this.state.statusSelected}
              />
              </Col>
              </Row> */}
            {/* <textarea ref={} onBlur={e => onBlur(e)} name={name} onKeyDown={e => onKeyHandle(e, value)} placeholder="Message" rows="1" value={value} onChange={e => setValue(e.target.value)} id="message" ></textarea> */}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary" disabled={this.state.isCreateDisabled}>
              <span>Create</span>
              {this.state.isCreateDisabled === true &&
                <span style={{ marginLeft: '5px' }}>
                  <ClockLoader
                    size={16}
                    color={"#fff"}
                    loading={this.state.isCreateDisabled}
                  />
                </span>
              }</Button>
            <Button color="secondary" onClick={this.props.closeAddTask}>Cancel</Button>
          </ModalFooter>
        </form>
      </Modal>
      }
      </React.Fragment>

    );
  }
}

// Prop types of props.
AddNewTask.propTypes = {
  API_HOST: PropTypes.string,

};

// Set default props.
AddNewTask.defaultProps = {

};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),

  };
};
export default connect(mapStateToProps, {updateUserToDoToJira, getJiraDomain})(AddNewTask);