
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { downloadExcelFile, getTenantLog } from "../../../actions/logActions";
//import { getUserListDepartmentFilter } from "../../../actions/mainOfficeActions";
import { getApiHost } from "../../../utils/apiUrls";
import { dateDiff } from "../../../utils/utility"
import { FormGroup, Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';
import moment from 'moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';
import ClockLoader from "react-spinners/ClockLoader";


class TenantLog extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            onTenantLog: this.props.onTenantLog,
            data: [],
            startDate: moment(new Date()).format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('YYYY-MM-DD'),
            currentObject: {
                isLogLevel: { value: "Information", label: "Information" },
                isModule: { value: null, label: "General Module " },
                isUser: { value: null, label: "All" }
            },
            page: 0,
            pageSize: 10,
            pageCount: 0,
            loading: false,
            enabled: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tenantLogResponse !== this.props.tenantLogResponse) {
            let totlePage = this.props.tenantLogResponse.Data.PageData.TotalPages;
            this.setState({
                data: this.props.tenantLogResponse.Data.SourceData,
                pageCount: totlePage
            })
        }
    }

    toggleTenantLog = () => {
        this.props.toggleTenantLog()
    }



    searchData = async (page, pageSize, sortArray) => {

        const { API_HOST, getTenantLog } = this.props;
        let sDate = moment(this.state.startDate).format('YYYY-MM-DD');
        let eDate = moment(this.state.endDate).format('YYYY-MM-DD');

        let sortParam = {};

        if (sortArray.length > 0) {
            sortParam = {
                "Direction": sortArray[0].desc === true ? 1 : 0,
                "Property": sortArray[0].id
            }
        } else {
            sortParam = {
                "Direction": 1,
                "Property": "LogDateTime"
            }
        }

        let reqObj = {
            "IsPagging": true,
            "PageNo": page,
            "PageSize": pageSize,
            "SortingParameters": [
                sortParam
            ],
            "SearchCriteria": {
                "UserId": this.state.currentObject.isUser.value,
                "StartDate": moment(this.state.startDate).startOf('day').format("YYYY-MM-DDTHH:mm"),
                "EndDate": moment(this.state.endDate).endOf('day').format("YYYY-MM-DDTHH:mm"),
                "LogLevel": this.state.currentObject.isLogLevel.value,
                "ModuleId": this.state.currentObject.isModule.value
            },
            "ComplexSearchCriteria": {},
        }
        //console.log("reqobj", reqObj)

        if (dateDiff(sDate, eDate) <= 30) {
            await getTenantLog(API_HOST, reqObj, (res) => {
                this.setState({ loading: false });
            });
        } else {
            toast.info("You can only see upto 30 days log.");
            this.setState({ loading: false });
        }
    }

    handleLogLevel(value) {
        this.setState({
            currentObject: {
                ...this.state.currentObject,
                isLogLevel: value
            }
        })
    }

    userChange(value) {
        this.setState({
            currentObject: {
                ...this.state.currentObject,
                isUser: value
            }
        })
    }

    moduleType(value) {
        this.setState({
            currentObject: {
                ...this.state.currentObject,
                isModule: value
            }
        })
    }

    getTenantLogData = () => {
        if (this.state.startDate !== "" && this.state.endDate !== "") {
            this.setState({ loading: true });
            this.searchData(1, 10, [{ "id": "LogDateTime", "desc": true }]);
        }
    }

    onPageChange = (state, instance) => {
        let page = state.page + 1;
        let pageSize = state.pageSize;
        let sort = state.sorted;
        if (this.state.startDate !== "" & this.state.endDate !== "") {
            //console.log("page  pagesize", page, pageSize)
            this.setState({ loading: true });
            this.searchData(page, pageSize, sort);
        }
        //this.setState({ loading: false });
    }

    // Method to convert byte array to Uint8Array which can be further used to create blob
    base64ToArrayBuffer = (base64) => {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    // Method to save byte array as file using FileSaver library
    downloadFile = (byteArray, fileNameWithExtension) => {
        let result = this.base64ToArrayBuffer(byteArray);
        let blob = new Blob([result], { type: 'application/octet-stream' });
        saveAs(blob, fileNameWithExtension);
    }

    downloadExcelFile = async () => {

        const { API_HOST, downloadExcelFile } = this.props;
        const finalUrl = `${API_HOST}AuditLogTenant/ExportToExcel`;
        this.setState({ enabled: true })
        let reqObj = {
            "UserId": this.state.currentObject.isUser.value,
            "StartDate": moment(this.state.startDate).startOf('day').format("YYYY-MM-DDTHH:mm"),
            "EndDate": moment(this.state.endDate).endOf('day').format("YYYY-MM-DDTHH:mm"),
            "LogLevel": this.state.currentObject.isLogLevel.value,
            "ModuleId": this.state.currentObject.isModule.value
        };

        await downloadExcelFile(finalUrl, reqObj, (res) => {
            this.downloadFile(res.Data.FileContent, res.Data.FileName);
            this.setState({ enabled: false })
        });
    }

    // getUserListDepartmentFilter = () => {
    //     const { API_HOST, getUserListDepartmentFilter } = this.props;
    //     const requestBody = {
    //         "IsPagging": true,
    //         "PageNo": 1,
    //         "PageSize": 10,
    //         "SortingParameters": [
    //             {
    //                 "Property": "",
    //                 "Direction": 0
    //             }
    //         ],
    //         "SearchCriteria": {
    //             "Id_User": 0,
    //             "Id_Department": 0
    //         }
    //     }
    //     getUserListDepartmentFilter(API_HOST, requestBody);
    // }

    render() {
        const { onTenantLog, data, startDate, pageSize, pageCount, loading, endDate, enabled } = this.state;
        const { getUserAvailabilityListResponse } = this.props;
        let users = [{ value: null, label: "All" }];
        getUserAvailabilityListResponse && getUserAvailabilityListResponse.map(obj => {
            if (obj.EmailId !== '' && obj.Real_Name !== '') {
                users.push({ value: obj.Id_User, label: obj.Real_Name })
            }
            return users;
        })

        const logLevel = [{
            value: "Information",
            label: "Information"
        }, {
            value: "Error",
            label: "Error"
        }]

        const moduleType = [{
            value: null,
            label: "General Module"
        }, {
            value: 1,
            label: "User Module"
        }, {
            value: 2,
            label: "Role Module"
        }, {
            value: 3,
            label: "Tenant Module"
        }, {
            value: 4,
            label: "User Meeting Module"
        }, {
            value: 5,
            label: "User ToDo Module"
        }]

        const columns = [
            {
                Header: <strong className="my_custom_class">Log DateTime</strong>,
                accessor: 'LogDateTime'
            },
            {
                Header: <strong className="my_custom_class">User</strong>,
                accessor: 'UserName'
            },
            {
                Header: <strong className="my_custom_class">Module Name</strong>,
                accessor: 'ModuleName'
            },
            {
                Header: <strong className="my_custom_class">Description</strong>,
                accessor: 'Description'
            },
            {
                Header: <strong className="my_custom_class">Request Uri</strong>,
                accessor: 'RequestUri'
            },
            {
                Header: <strong className="my_custom_class">Request Object</strong>,
                accessor: 'RequestObject'
            }
        ];


        return (
            <React.Fragment>
                <Modal isOpen={onTenantLog} backdrop={true} size={'xl'} >
                    <ModalHeader toggle={() => this.toggleTenantLog()}>
                        Tenant Activity Log
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Row>

                                {/* <Col xs="1"><label style={{ paddingTop: '10px' }}>Start Date</label></Col> */}
                                <Col xs="2">
                                    <input
                                        className="form-control"
                                        type="date"
                                        onChange={e => this.setState({ startDate: e.target.value })}
                                        value={startDate}
                                    />
                                </Col>
                                {/* <Col xs="1"><label style={{ paddingTop: '10px' }}>End Date</label></Col> */}
                                <Col xs="2">
                                    <input
                                        type="date"
                                        className="form-control"
                                        min={startDate}
                                        onChange={e => this.setState({ endDate: e.target.value })}
                                        value={endDate}
                                    />
                                </Col>
                                <Col xs="2" style={{ marginTop: '5px' }}>
                                    <Select
                                        components={makeAnimated}
                                        onChange={newValue => this.userChange(newValue)}
                                        options={users}
                                        value={this.state.currentObject.isUser}
                                    />
                                </Col>
                                <Col xs="2" style={{ marginTop: '5px' }}>
                                    <Select
                                        components={makeAnimated}
                                        onChange={newValue => this.handleLogLevel(newValue)}
                                        options={logLevel}
                                        value={this.state.currentObject.isLogLevel}
                                    />
                                </Col>
                                <Col xs="2" style={{ marginTop: '5px' }}>
                                    <Select
                                        components={makeAnimated}
                                        onChange={newValue => this.moduleType(newValue)}
                                        options={moduleType}
                                        value={this.state.currentObject.isModule}
                                    />
                                </Col>
                                <Col xs="1">
                                    <Button type="button" className="btn-icon btn-2" style={{ marginLeft: '-15%' }} color="primary" onClick={this.getTenantLogData}><i className="fa fa-search" style={{ fontSize: '18px', color: 'white' }} ></i></Button>
                                </Col>
                                <Col xs="1">
                                    <Button type="button" className="btn-icon btn-2" style={{ marginLeft: '-15%' }} color="warning" onClick={this.downloadExcelFile}>{enabled === false ? <i className="fa fa-download" style={{ fontSize: '18px', color: 'white' }} ></i> :
                                        <ClockLoader
                                            size={20}
                                            color={"#64B988"}
                                            loading={enabled}
                                        />}
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <ReactTable
                                manual
                                pages={pageCount}
                                data={data}
                                columns={columns}
                                defaultPageSize={pageSize}
                                pagination={true}
                                className="-striped -highlight"
                                pageSizeOptions={[10]}
                                onFetchData={this.onPageChange}
                                loading={loading}
                            //onPageChange={(pageIndex) => { this.onPageChange(pageIndex) }}
                            />
                        </FormGroup>
                    </ModalBody>
                </Modal>

            </React.Fragment>
        );
    }
}


// Prop types of props.
TenantLog.propTypes = {
    API_HOST: PropTypes.string,
};

// Set default props.
TenantLog.defaultProps = {
    tenantLogResponse: PropTypes.array,
    getTenantLog: () => { },
    downloadExcelFile: () => { },
    getUserAvailabilityListResponse: []
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    tenantLogResponse: state.LogReducer.tenantLogResponse,
    getUserAvailabilityListResponse: state.mainOfficeReducer.userListDepartmentFilterResponse
})

export default connect(mapStateToProps, { downloadExcelFile, getTenantLog })(TenantLog)