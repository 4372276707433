import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getApiHost } from '../../../utils/apiUrls';
import { CustomLoadingSpiner } from '../../../components/loader/customLoader';
import { updateUserJiraAccessToken } from '../../../actions/jiraActions';
import { getEncryptedValue } from '../../../utils/utility';

class HsJiraToken extends React.Component {
  state = {
    loading: true,
    oauth_token: '',
    oauth_token_secret: '',
    jira_url: '',
  };

  async componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    let oauth_token = params.get('oauth_token');
    let oauth_token_secret = params.get('oauth_token_secret');
    let jira_url = params.get('jira_url');

    await this.setState({
      oauth_token: oauth_token,
      oauth_token_secret: oauth_token_secret,
      jira_url: jira_url,
    });
    await this.savePrivateJiraDetails();
  }

  savePrivateJiraDetails = async () => {
    const { API_HOST, updateUserJiraAccessToken } = this.props;
    let requestBody = [
      {
        Oauth_Access_Token: getEncryptedValue(this.state.oauth_token),
        Oauth_Token_Secret: getEncryptedValue(this.state.oauth_token_secret),
        Is_Private: true,
        Jira_Url: this.state.jira_url,
        Domain_Name: this.state.jira_url,
        CloudId: '',
        Jira_Scope_Detail: '',
      },
    ];

    await updateUserJiraAccessToken(API_HOST, requestBody);
    this.props.history.push('/dashboard');
  };

  render() {
    return (
      // <div>
      //   <div> oauth_token : {this.state.oauth_token}  </div>
      //   <div> oauth_token_secret : {this.state.oauth_token_secret}</div>
      //   <div> jira_url : {this.state.jira_url}</div>
      // </div>
      <CustomLoadingSpiner InProgress={this.state.loading} />
    );
  }
}

// Prop types of props.
HsJiraToken.propTypes = {};

// Set default props.
HsJiraToken.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
  };
};

export default connect(mapStateToProps, { updateUserJiraAccessToken })(
  HsJiraToken
);
