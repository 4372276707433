import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  NavLink
} from "reactstrap";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import DepartmentDetail from "./DepartmentDetail";
import AssignDepartment from "./AssignDepartment";
import classnames from "classnames";


class ManageDepartment extends Component {
  constructor() {
    super();
    this.state = {
      myAvatar: {},
      activeTab: "1"
    };
  }


  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };


  componentWillReceiveProps(nextProps) {
    if (nextProps.myAvatar !== this.props.myAvatar) {
      this.setState({ myAvatar: nextProps.myAvatar });
    }
  }

  componentDidMount() {
    this.setState({ myAvatar: this.props.myAvatar });
    if (this.props.isAddnewMember) {
      this.setState({ activeTab: "2" }); 
    } 
  }

  onHide = () => {
    this.props.toggleManageDept();
  };

  render() {
    const { manageDepartment } = this.props;
    const closeBtn = (
      <button className="close  pull-right" onClick={this.onHide}>
        &times;
      </button>
    );

    return (
      <>



<div
          className="model-right-bar to_do_modal rightbar_sm model-left-bar"
          id="userloginmodel"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"  
          style={{width: '800px'}}
      >

      <div className="modal-dialog jira-task-popup" role="document">

      <div className="modal-content">

        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
            Department   
            </h5>
            <div
            className="close b-left"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onHide}
            style={{ cursor: "pointer" }}
            >
            <i className="fa fa-close" />
            </div>
        </div>

        <div className=" modal-body ">
<div>
<div className="main-tab">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">

                <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Manage Department
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2"
                    })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    Assign Department
                  </NavLink>
                </li>

              </ul>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane
                  tabId="1"
                  className="tab-pane"
                  id="pills-avtartab-con2"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {this.state.activeTab === "1" && (
                    <DepartmentDetail></DepartmentDetail>
                  )}
                </TabPane>


                {this.state.activeTab === "2" && (
                  <TabPane tabId="2">
                    <AssignDepartment addnewMemberDeptId={this.props.addnewMemberDeptId}></AssignDepartment>
                  </TabPane>
                )}

              </TabContent>
            </div>
  </div>
        </div> 

      </div>
        
      </div>

    </div>



        
      </>
    );
  }
}

ManageDepartment.defaultProps = {};

ManageDepartment.propTypes = {
  
};

export default hot(module)(ManageDepartment);
