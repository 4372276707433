import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from "../../../utils/apiUrls";
import { CustomLoadingSpiner } from "../../../components/loader/customLoader";
import {
  slackOauthAccess,
  updateUserSlackAccessToken,
  getSlackConfigurationlist,
} from "../../../actions/dashboardActions";
import { toast } from "react-toastify";
import { getEncryptedValue, getDecryptedValue } from "../../../utils/utility";

const axios = require("axios");
const qs = require("qs");

class SlackToken extends React.Component {
  state = {
    loading: true,
    clientId: "",
    clientSecret: "",
  };

  async componentDidMount() {
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // let code = params.get('code');
    await this.getSlackConfigurationlist();
    //this.slackTokenOAuth(code);
  }

  checkEmailWorkspace = async (
    workspaceName,
    access_token,
    refresh_token,
    slack_id
  ) => {
    let message = {
      token: access_token,
    };
    const result = await axios.post(
      `https://slack.com/api/users.profile.get`,
      qs.stringify(message)
    );
    const usersSlackEmail = result.data.profile.email;
    const userEmailAddress = JSON.parse(
      localStorage.getItem("userdetail")
    ).EmailId;
    const userWorkspace = JSON.parse(
      localStorage.getItem("userdetail")
    ).Slack_WorkSpace_Name;
    const slackId = getEncryptedValue(slack_id);

    console.log("uesr email :  ", usersSlackEmail);
    console.log("slack email address: ", userEmailAddress);
    console.log("user workspace: ", userWorkspace);
    console.log("slack workspace : ", workspaceName);

    if (
      usersSlackEmail.toLowerCase().trim() ===
        userEmailAddress.toLowerCase().trim() &&
      userWorkspace.toLowerCase().trim() === workspaceName.toLowerCase().trim()
    ) {
      console.log("Workspace/Email is same");
      this.slackTokenUpdate(access_token, refresh_token, slackId);
      return true;
    } else {
      console.log("Workspace/Email is NOT same");
      toast.error(
        this.msg(
          userEmailAddress,
          usersSlackEmail,
          workspaceName,
          userWorkspace
        )
      );
      this.props.history.push({
        pathname: "/dashboard",
        isSlackRedirect: true,
      });
      return false;
    }
  };

  slackTokenOAuth = async (code, client_Id, client_Secret) => {
    const { slackOauthAccess } = this.props;
    await slackOauthAccess(code, client_Id, client_Secret, (res) => {
      console.log("auth res", res);
      //debugger;

      if (res.ok === false) {
        console.log("Case: 11111111");
        this.props.history.push({
          pathname: "/dashboard",
          isSlackRedirect: true,
        });
        this.setState({ loading: false });
        toast.error(res.error);
      }
      console.log(
        "auth res123 : ",
        localStorage.getItem("userdetail").Id_SlackUser
      );

      if (
        localStorage.getItem("userdetail").Id_SlackUser === undefined ||
        localStorage.getItem("userdetail").Id_SlackUser === null
      ) {
        this.checkEmailWorkspace(
          res.team.name,
          res.authed_user.access_token,
          res.authed_user.refresh_token,
          res.authed_user.id
        );
      }

      //if (localStorage.getItem('userdetail').Id_SlackUser !== undefined && localStorage.getItem('userdetail').Id_SlackUser !== null && res.authed_user.id === getDecryptedValue(JSON.parse(localStorage.getItem('userdetail')).Id_SlackUser)) {
      else if (
        localStorage.getItem("userdetail").Id_SlackUser !== undefined &&
        localStorage.getItem("userdetail").Id_SlackUser !== null &&
        res.authed_user.id ===
          getDecryptedValue(
            JSON.parse(localStorage.getItem("userdetail")).Id_SlackUser
          )
      ) {
        console.log("Case: 22222222");
        this.slackTokenUpdate(
          res.authed_user.access_token,
          res.authed_user.refresh_token,
          ""
        );
      } else {
        console.log("Case: 333333333");
        this.props.history.push({
          pathname: "/dashboard",
          isSlackRedirect: true,
        });
        this.setState({ loading: false });
        this.getUsersSlackEmail(res.authed_user.access_token, res.team.name);
      }
    });
  };

  getSlackConfigurationlist = async () => {
    const { API_HOST, getSlackConfigurationlist } = this.props;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get("code");
    //await getSlackConfigurationlist(API_HOST);
    await getSlackConfigurationlist(API_HOST, async (res) => {
      if (res) {
        await this.setState({
          clientId: getDecryptedValue(res[0].Client_ID),
          clientSecret: getDecryptedValue(res[0].Client_Secret),
        });
        await this.slackTokenOAuth(
          code,
          this.state.clientId,
          this.state.clientSecret
        );
      }
    });
  };

  slackTokenUpdate = async (token, refreshToken, slackId) => {
    const { API_HOST, updateUserSlackAccessToken, slackConfigListResponse } =
      this.props;
    const slackToken = getEncryptedValue(token);
    const slackRefreshToken = getEncryptedValue(refreshToken);

    await updateUserSlackAccessToken(
      API_HOST,
      slackToken,
      slackConfigListResponse[0].Scope_Detail,
      slackRefreshToken,
      slackId,
      (res) => {
        if (res) {
          let userData = JSON.parse(localStorage.getItem("userdetail"));
          userData.Slack_Access_Token = slackToken;
          userData.Slack_Scope_Detail = slackConfigListResponse[0].Scope_Detail;
          userData.Slack_Refresh_Token = slackRefreshToken;
          localStorage.setItem("userdetail", JSON.stringify(userData));
          this.setState({ loading: false });
        }
        this.props.history.push({ pathname: "/dashboard" });
      }
    );
  };

  msg = (userEmailAddress, usersSlackEmail, workspaceName, userWorkspace) => (
    <div>
      Please use same email address/workspace to login in Slack !!<br></br>
      Email address used in Precis workspace <b>{userWorkspace}</b> :{" "}
      {userEmailAddress} <br></br>
      Email address used in Slack workspace <b>{workspaceName}</b>:{" "}
      {usersSlackEmail}
    </div>
  );

  getUsersSlackEmail = async (accessToken, workspaceName) => {
    try {
      let message = {
        token: accessToken,
      };

      const result = await axios.post(
        `https://slack.com/api/users.profile.get`,
        qs.stringify(message)
      );
      const usersSlackEmail = result.data.profile.email;
      const userEmailAddress = JSON.parse(
        localStorage.getItem("userdetail")
      ).EmailId;
      const userWorkspace = JSON.parse(
        localStorage.getItem("userdetail")
      ).Slack_WorkSpace_Name;

      toast.error(
        this.msg(
          userEmailAddress,
          usersSlackEmail,
          workspaceName,
          userWorkspace
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    // let { topicId } = useParams();

    return <CustomLoadingSpiner InProgress={this.state.loading} />;
  }
}

// Prop types of props.
SlackToken.propTypes = {
  API_HOST: PropTypes.string,
  slackConfigListResponse: PropTypes.any,
  getSlackConfigurationlist: PropTypes.func,
};

// Set default props.
SlackToken.defaultProps = {
  slackConfigListResponse: [],
  getSlackConfigurationlist: () => {},
};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
    slackConfigListResponse:
      state.dashboardReducer.slackConfigListResponse.slackConfigListResponse,
  };
};
export default connect(mapStateToProps, {
  getSlackConfigurationlist,
  slackOauthAccess,
  updateUserSlackAccessToken,
})(SlackToken);
