import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { signup } from '../../../actions/signupActions';
import { getApiHost } from '../../../utils/apiUrls';
import { toast } from 'react-toastify';
import { TenantCreateLoader } from '../../../components/loader/tenantCreateLoader';

class Signup extends React.Component {
  state = {
    fields: {},
    errors: {},
    isLoader: false,
  };

  componentDidMount = () => {
    document.title = this.props.title;
    sessionStorage.removeItem('signUpEmail');
  };

  // componentWillReceiveProps = (nextProps) => {
  //   if (nextProps.signupResponse !== this.props.signupResponse) {

  //     this.props.history.push("/office");
  //   }
  // };

  // Function calls while type user name or password and set their values.

  handleChange = (field, e) => {
    let fields = this.state.fields;

    if (e.target.name === 'userdomain') {
      if (e.target.value.includes('.slack.com')) {
        var a = e.target.value;
        fields[e.target.name] = a.replace('.slack.com', '').trim();
        e.target.value = a.replace('.slack.com', '').trim();
      } else if (e.target.value.includes('.slack')) {
        var a = e.target.value;
        fields[e.target.name] = a.replace('.slack', '').trim();
        e.target.value = a.replace('.slack', '').trim();
      } else if (e.target.value.includes('.com')) {
        var a = e.target.value;
        fields[e.target.name] = a.replace('.com', '').trim();
        e.target.value = a.replace('.com', '').trim();
      } else if (e.target.value.includes('.')) {
        var a = e.target.value;
        fields[e.target.name] = a.replace('.', '').trim();
        e.target.value = a.replace('.', '').trim();
      } else {
        var a = e.target.value;
        fields[e.target.name] = a.trim();
        e.target.value = a.trim();
      }
    }

    fields[field] = e.target.value;
    // console.log("..",e.target.value);
    this.setState({ fields });
  };

  // Function handles validation and if success then calls signup  method.
  onSubmitSignup = async (event) => {
    // event.preventDefault();
    if (this.handleValidation()) {
      await this.onSignup();
      await localStorage.setItem('autoImport', 'false');
    }
  };

  // Calls Sign up  API to get logged in.
  onSignup = async () => {
    // console.log("Domail",this.state.fields["userdomain"]);
    // console.log("Email",this.state.fields["useremail"]);
    // console.log("Password",this.state.fields["userpassword"]);

    if (
      this.state.fields['userdomain'] === undefined ||
      this.state.fields['firstname'] === undefined ||
      this.state.fields['useremail'] === undefined ||
      this.state.fields['userpassword'] === undefined
    ) {
      toast.error('Please fill all the fields.', { autoClose: 2000 });
    } else {
      await this.setState({ isLoader: true });
      const { API_HOST, signup } = this.props;
      var tempDate = new Date();
      var date =
        tempDate.getFullYear() +
        '-' +
        (tempDate.getMonth() + 1) +
        '-' +
        tempDate.getDate() +
        ' ' +
        tempDate.getHours() +
        ':' +
        tempDate.getMinutes() +
        ':' +
        tempDate.getSeconds();
      const signupRequest = {
        id: '0',
        Slack_Workspace_Name: this.state.fields['userdomain'],
        First_Name: this.state.fields['firstname'],
        email: this.state.fields['useremail'],
        password: this.state.fields['userpassword'],
        is_active: true,
        is_deleted: false,
        created_datetime: date,
      };
      await signup(API_HOST, signupRequest, (res) => {
        this.setState({ isLoader: false });
        if (res && res.stack !== 'Error') {
          this.props.history.push('/dashboard');
        } else {
          sessionStorage.setItem('signUpEmail', this.state.fields['useremail']);
          this.props.history.push('/login');
        }
      });
    }

    // if (this.props.signupResponse) {
    //   this.props.history.push("/office");
    // }
  };

  // Handles validation for required fields.sdfs
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (fields['userconfirmpassword'] !== fields['userpassword']) {
      formIsValid = false;
      toast.error('Password and confirm password should be same', {
        autoClose: 2000,
      });
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  validateEmail = (e) => {
    const emailText = e.target.value;
    var pattern =
      '[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})';
    //var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (e.target.name === 'useremail' && !emailText.match(pattern)) {
      toast.error('Please enter a valid email address', { autoClose: 2000 });
    }
  };

  validatePassword = (e) => {
    const passwordText = e.target.value;
    var pattern = /^[a-zA-Z0-9!@#$%^&*]{8,10}$/;
    if (e.target.name === 'userpassword' && !passwordText.match(pattern)) {
      toast.error(
        'Please enter a valid password. It should contain one Capital letter, one Number, one Special Character, and length should be upto 10 characters',
        { autoClose: 2000 }
      );
    }
  };

  keyPress = (e) => {
    if (e.key === 'Enter') {
      alert('Enter pressed');
      e.preventDefault();
      this.onSubmitSignup();
    }
  };

  render() {
    return (
      <>
        {this.state.isLoader && (
          <TenantCreateLoader InProgress={this.state.isLoader} />
        )}
        <div className="wrapper">
          <div className="login-panel">
            <div className="bg-animate">
              <div class="bg"></div>
              <div class="bg bg2"></div>
              <div class="bg bg3"></div>
            </div>

            <div className="login-content">
              <img className="img" alt="logo.png" src="images/logo.png" />
              <div className="login-card card shadow">
                <div className="title">Create account</div>
                {/* <form onSubmit={this.onSubmitSignup} method="post" id="signUp"> */}
                {/* <div className="form-group">
                    <label htmlFor="userNameInput">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="userNameInput"
                      placeholder="First Name"
                      value={this.state.fields["username"] || ""}
                      name="username"
                      onChange={(e) => this.handleChange("username", e)}
                      required
                    />
                  </div> */}

                <div className="form-group">
                  <label htmlFor="domainNameInput">Domain Name</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="domainNameInput"
                      placeholder="Enter Your Domain Name"
                      value={this.state.fields['userdomain'] || ''}
                      name="userdomain"
                      onChange={(e) => this.handleChange('userdomain', e)}
                      // required
                    />
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">
                        .slack.com
                      </span>
                    </div>
                  </div>
                  {/* <span style={{ color: "red" }}>
                    {this.state.errors["domain"]}
                  </span> */}
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputFirstName">Your Name</label>
                  <input
                    type="text"
                    id="exampleInputFirstName"
                    className="form-control"
                    placeholder="Enter Your Name"
                    value={this.state.fields['firstname'] || ''}
                    name="firstname"
                    onChange={(e) => this.handleChange('firstname', e)}
                    required
                    maxLength="320"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    id="exampleInputEmail1"
                    className="form-control"
                    pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
                    placeholder="abc@yahoo.com"
                    value={this.state.fields['useremail'] || ''}
                    name="useremail"
                    onChange={(e) => this.handleChange('useremail', e)}
                    // required
                    maxLength="320"
                    onBlur={(e) => this.validateEmail(e)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    value={this.state.fields['userpassword'] || ''}
                    name="userpassword"
                    onChange={(e) => this.handleChange('userpassword', e)}
                    onBlur={(e) => this.validatePassword(e)}
                    //required
                    // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$"
                    // maxLength="10"
                    // minLength="8"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputPassword2">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword2"
                    placeholder="Confirm Password"
                    value={this.state.fields['userconfirmpassword'] || ''}
                    name="userconfirmpassword"
                    onChange={(e) =>
                      this.handleChange('userconfirmpassword', e)
                    }
                    onKeyDown={this.keyPress}
                    //required
                    //onBlur={(e) => this.validatePassword(e)}
                  />
                  {/* <span style={{ color: "red" }}>
                    {this.state.errors && this.state.errors["userconfirmpassword"]}
                  </span> */}
                </div>
                <button
                  type="submit"
                  onClick={this.onSubmitSignup}
                  className="btn btn-primary"
                >
                  Create Account
                </button>
                {/* </form> */}
                <div className="text-center">
                  <div className="signup-link">
                    <span></span>
                    <Link to={'/login'} className="have-acc">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
              <div className="policy-link">
                <span>By signin up, you agree to our</span>
                <Link to={'/termsandconditions'} className="have-acc">
                  Terms and Conditions
                </Link>
                {/* <a href="/#" className="have-acc">
                          Terms and Conditions
                        </a> */}{' '}
                &{' '}
                <Link to={'/privacypolicy'} className="have-acc">
                  Privacy Policy
                </Link>
                {/* <a href="/#" className="have-acc">
                          Privacy Policy
                        </a> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// Prop types of props.
Signup.propTypes = {
  API_HOST: PropTypes.string,
  signup: PropTypes.func,
  signupResponse: PropTypes.object,
};

// Set default props..
Signup.defaultProps = {
  signup: () => {},
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
  signupResponse: state.signupReducer.signupResponse,
});

export default connect(mapStateToProps, { signup })(Signup);
