import { types as T } from '../actions/action';
import { defaultStore } from '../store';

const officeLayoutReducer = (state = defaultStore, action) => {
	switch (action.type) {

		case T.RECEIVE_GET_CABIN_LIST:
			return {
				...state,
				cabinListResponse: {
					...state.getCabinList,
					loading: false,
					error: false,
					errorMessage: null,
					cabinListResponse: action.cabinListResponse
				},
			};

		case T.RECEIVE_EDIT_CABIN_NAME:
			return {
				...state,
				editCabinRoomNameResponse: {
					...state.editCabinRoomName,
					editCabinNameLoading: false,
					editCabinNameError: false,
					editCabinNameErrorMessage: null,
					editCabinRoomNameResponse: action.editCabinRoomNameResponse
				},
			};

		case T.REQUEST_CABIN_USER_DETAIL:
			return {
				...state,
				cabinUserDetailsResponse: {
					...state.saveCabinUserDetails,
					cabinUserDetailsResponse: action.cabinUserDetailsResponse
				},
			};

		//get CaUser Detail
		case T.RECEIVE_GET_LOGGED_IN_USER_CABIN_DETAIL:
			return {
				...state,
				loggedInUserCabinDetailsResponse: {
					...state.getLoggedInUserCabinDetail,
					getCabinUserDetailLoading: false,
					getCabinUserDetailError: false,
					getCabinUserDetailErrorMessage: null,
					loggedInUserCabinDetailsResponse: action.loggedInUserCabinDetailsResponse,
				},
			};

		case T.RECEIVE_SAVE_USER_KNOCK:
			return {...state, 
				saveUserKnockResponse: {
					...state.saveUserKnock,
					saveUserKnockResponse: action.saveUserKnockResponse,
				},
			};

			case T.RECEIVE_DOOR_OPEN:
				return {
					...state,
					doorOpenResponse: {
						...state.doorOpen,
						loading: false,
						error: false,
						errorMessage: null,
						doorOpenResponse: action.doorOpenResponse
					},
				};

				case T.RECEIVE_DOOR_CLOSE:
				return {
					...state,
					doorCloseResponse: {
						...state.doorClose,
						loading: false,
						error: false,
						errorMessage: null,
						doorCloseResponse: action.doorCloseResponse
					},
				};

				case T.RECEIVE_STICKY_MESSAGE_SEND:
					return {
						...state,
						receiveStickyMessageSendResponse: {
							...state.stickyMessageSend,
							loading: false,
							error: false,
							errorMessage: null,
							receiveStickyMessageSendResponse: action.receiveStickyMessageSendResponse
						},
					};
			
			case T.RECEIVE_USER_KNOCK_LIST_CABIN_WISE:
			return {...state, 
				userKnockListCabinWiseResponse: {
					...state.userKnockListCabinWise,
					userKnockListCabinWiseResponse: action.userKnockListCabinWiseResponse,
				},
			};

			case T.RECEIVE_USER_KNOCK_SEEN:
			return {...state, 
				userKnockSeenResponse: {
					...state.userKnockSeen,
					userKnockSeenResponse: action.userKnockSeenResponse,
				},
			};
			
			case T.RECEIVE_USER_STICKY_SEEN:
			return {...state, 
				userStickySeenResponse: {
					...state.userStickySeen,
					userStickySeenResponse: action.userStickySeenResponse,
				},
			};

			case T.RECEIVE_STICKY_COUNT:
			return {...state, 
				stickyCountResponse: {
					...state.stickyCount,
					stickyCountResponse: action.stickyCountResponse,
				},
			};

			case T.RECEIVE_GET_CABIN_USER_STICKY_LIST:
			return {...state, 
				getCabinUserStickyListResponse: {
					...state.getCabinUserStickyList,
					getCabinUserStickyListResponse: action.getCabinUserStickyListResponse,
				},
			};

		default:
			return state
	}
}
     
    export default officeLayoutReducer;
