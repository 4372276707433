import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { withRouter, Switch, Route, Link } from "react-router-dom";
import { compose } from "redux";
import Moment from "react-moment";
import Rightsidebar from "./Rightsidebar";
import DashboardCTX from "./DashboardCTX";
import ViewProfile from "./ViewProfile";
import EditTenant from "./EditTenant";
import MyProfile from "./MyProfile";
import SearchBar from "./SearchBar";
import {
  getEncryptedValue,
  getDecryptedValue,
} from "../../../utils/utility";
import TimeCalendar from "./TimeCalendar";
import DepartmentDetail from "./DepartmentDetail";
import OfficeDetail from "./OfficeDetail";
import UnreadSentStickyList from "./UnreadSentStickyList";
import OfficeLayout from "../../MainOfficeLayout/components/OfficeLayout";
import ChatWindow from "../../chat/components/ChatWindow";
import $ from "jquery";
import SlackConfigModal from "../../slackconfig/components/SlackConfigModal";
import OneToOneChat from "../../chat/components/OneToOneChat";
import SupportChat from "../../chat/components/SupportChat";
import { socket, slack_socket } from "../../../utils/apiUrls";
import TenantLog from "./TenantLog";
import FeedbackLog from "./FeedbackLog";
import ContactUsLog from "./ContactUsLog";
import MeetingActivityLog from "./MeetingActivityLog";
import NotificationBadge from "react-notification-badge";
import ProgressBar from "./ProgressBar";
import pcIcon from "../../../assets/images/pc-desktop.png";
import watchIcon from "../../../assets/images/watch-bg.png";
import AssignDepartment from "./AssignDepartment";
import InviteUserToSlack from "./InviteUserToSlack";
import { AvatarImage } from "../../../components/Avatar/AvatarImage";
import GoogleConfiguration from "../../../components/google_calendar/GoogleConfiguration";
import JiraClientConfig from "../../jira/components/JiraClientConfig";
import ZoomConfiguration from "./ZoomConfiguration";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MoreInfo from "./MoreInfo";
import EdiText from "react-editext";
import ManageDepartment from "./ManageDepartment";
import ReactTooltip from "react-tooltip";
import SyncProgressBar from "./SyncProgressBar";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap";
import StickyNotesList from "./StickyNotesList";
import KnockStickyCount from "../../MainOfficeLayout/components/KnockStickyCount";

slack_socket.on("connect", () => {
  //console.log("Inside Connect");
});

slack_socket.on("connect_error", () => {
  // console.log("Inside Connect Error");
  setTimeout(() => {
    slack_socket.connect();
  }, 2000);
});

slack_socket.on("disconnect", () => {
  //console.log("Inside Disconnect Function");
});
socket.on("connect", () => {
  //console.log("RTM Connect");
});

socket.on("connect_error", () => {
  //console.log("Inside Socket Connect Error");
  setTimeout(() => {
    socket.connect();
  }, 2000);
});

socket.on("disconnect", () => {
  //console.log("Inside Socket Disconnect Function");
});

const axios = require("axios");
const qs = require("qs");

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      isSelected: true,
      peopleSelected: false,
      userDetails: null,
      src: "",
      activeAccordian: [],
      activeOfficeAccordian: [],
      activeWaterCooler: [0],
      tenantDetails: null,
      editTenantIsOpen: false,
      viewProfileIsOpen: false,
      myProfileIsOpen: false,
      myAvatar: {},
      viewUserProfile: null,
      slackUserResponse: [],
      userResponse: [],
      teamId: 0,
      editDisabled: true,
      roomId: "",
      userId: "",
      isMainOffice: false,
      officeId: 0,
      searchText: "",
      isViewPort: false,
      datePicked: new Date(),
      searchUser: null,
      onDeptSelected: false,
      assignDepartment: false,
      onTenantLog: false,
      meetingActivityLog: false,
      onOfficeSelected: false,
      departmentSelected: "",
      isUnreadSentStickyModal: true,
      leftSideExpand: false,
      departmentId: 0,
      dept_Array: [],
      SearchbarUserId: 0,
      timeFilter: 0,
      isSlackEdit: false,
      isAddToSlack: false,
      isEditWorkingHours: false,
      isConnectToJira: false,
      multipleChatWindow: [],
      team: {},
      isMinimizeChat: false,
      isSupportChat: false,
      loggedInUserDetails: {},
      date: new Date(),
      selectedUsersList: [],
      virtualChannelId: "",
      selectedUsersID: [],
      virtualChannelName: "",
      selectedUserDetail: {},
      Is_Admin: JSON.parse(localStorage.getItem("userdetail")).Is_Admin
        ? JSON.parse(localStorage.getItem("userdetail")).Is_Admin
        : false,
      isUserSelected: false,
      isCustomStatus: false,
      selectedUsersGroupName: [],
      channelsUnreadCounts: [],
      oneToOneUnreadCount: [],
      slackTeamResponse: [],
      isProgressBar: false,
      channelUserCount: 0,
      feedbackLog: false,
      contactUsLog: false,
      isConfigurationOpen: true,
      isLogOpen: false,
      isPersonalChatOpen: true,
      userIdArray: [],
      userNameByUserId: [],
      isGoogleConfig: false,
      isJiraConfig: false,
      isZoomConfig: false,
      selectedWorkspace: JSON.parse(localStorage.getItem("userdetail"))
        .Id_Tenant_Last_Accessed
        ? JSON.parse(localStorage.getItem("userdetail")).Id_Tenant_Last_Accessed
        : "null",
      selectedWorkspaceName: JSON.parse(localStorage.getItem("userdetail"))
        .Slack_WorkSpace_Name
        ? JSON.parse(localStorage.getItem("userdetail")).Slack_WorkSpace_Name
        : "null",
      startImportUsers: false,
      importSlackStep: "null",
      slackConfigListResponse: [{}],
      isRedirectToLogin: false,
      isMoreInfo: false,
      multipleEmailResponse: [],
      showChannels: true,
      manageDepartment: false,
      isAddnewMember: false,
      addnewMemberDeptId: 0,
      isSyncProgressBar: false,
      fetchOfficeResponse: [],
      isJira: false,
      isSyncConfirmation: false,
      inviteUserToSlack: false,
      showChatIcon: false,
      notificationIsOpen: false,
      onReadNote: false,
      isModal: true,
      isStickyModal: true,
      stickyCountResponse: 0,
    };
  }

  async componentDidMount() {
    //Removed unwanted Imports from all files, Date: 05-04-2021, Dev: Tracey D'souza

    this.fetchMultipleEmail();
    let metNumber = this.props.match.params.meetingnum;
    let isJoin = this.props.match.params.isjoining;
    var userData = localStorage.getItem("userdetail");
    var userId = JSON.parse(userData).Id_User;

    this.updateTimeInterval = setInterval(this.updateTime, 5000);
    document.title = "Precis"; //this.props.title; // Set title of page.
    //this.props.fetchOffice();
    await this.getUserDetail(userId);
    this.getSlackConfigurationlist();
    if (this.props.userDetailResponse) {
      const avtar = this.props.userDetailResponse.ProfilePic;
      this.setState({
        userDetails: this.props.userDetailResponse,
        myAvatar: JSON.parse(avtar),
      });
    }

    // this.fetchSlackUser(0);
    // this.fetchSearchUser(0)
    // this.getCabinUserDetail(userId);
    // await this.userListByDepartment(0);
    // await this.departmentListByUser(0);
    // this.fetchSlackTeam(this.state.showChannels);
    //await this.getChannelUsersCount();
    this.setState({
      loggedInUserDetails: JSON.parse(localStorage.getItem("userdetail")),
      officeId: 1,
    });

    //  if(userData.Id_SlackUser === null){
    //this.findAllOneToOneChat();
    //}

    if (this.props.location.isSlackRedirect) {
      this.setState({ myProfileIsOpen: true, isAddToSlack: true });
    }

    if (this.props.location.isModal) {
      this.setState({ isJira: true });
    }

    if (this.props.location.isSlackImportRedirect) {
      this.importUserList();
    }

    if (this.props.location.isRedirectToSlackUrl) {
      this.getSlackConfigurationlist();
      let scope = this.state.slackConfigListResponse[0].Scope_Detail;
      let clientId = this.state.slackConfigListResponse[0].Client_ID;

      window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&${scope}&redirect_uri=${window.location.protocol}//${window.location.host}/importtoken`;
    }

    socket.on("makeAdmin", (data) => {
      if (
        data.tenant_Id ===
        JSON.parse(localStorage.getItem("userdetail")).Id_Tenant
      ) {
        this.makeAdmin(data);
      }
    });
    socket.on("updateOfficeTime", (data) => {
      this.updateOfficeTime(data);
    });

    socket.on("backFromLeave", (data) => {
      this.backFromLeave(data);
    });

    // socket.on("updateChannelList", data => {
    //     this.updateChannelList(data)
    // });

    socket.on("updateSyncWorkspace", (data) => {
      this.setState({ isSyncConfirmation: true, dataSet: data });
    });

    socket.on("editOfficeName", (data) => {
      //this.edit_OfficeName(data);
      this.getUserDetail(data.Id_User);
    });

    socket.on("updateWorkspace", (data) => {
      this.updateWorkspace(data);
    });

    if (slack_socket.connected) {
      console.log("socket.io is connected.");
    } else {
      console.log("socket.io NOT connected.");
    }

    slack_socket.on("updateChannelList", (event) => {
      //console.log("Client side create channel Event: ", event);

      var userData = localStorage.getItem("userdetail");
      var userSlackId = getDecryptedValue(JSON.parse(userData).Id_SlackUser);

      let temp = [];
      for (let i = 0; i < event.members.length; i++) {
        temp.push(event.members[i]);
      }
      if (temp.includes(userSlackId + "")) {
        this.fetchSlackTeam(false);
        toast.info("You've been added to a new group chat.");
      }
    });

    slack_socket.on("updateChatHistory", (event) => {
      this.changeUnreadChannelCounts(event.channel);
      //console.log("Client side Event: ", event);
    });

    slack_socket.on("updateOneToOneChatHistory", (event) => {
      this.changeUnreadOneToOneCounts(event.channel);
      //console.log("Client side Event: ", event);
    });

    if (!!metNumber && !!isJoin) {
      await this.zoomMeetingJoinLeave(metNumber);
      await this.doorOpenfunc(userId);
      this.props.history.push({ pathname: "/dashboard" });
    }

    this.getUserLeaveDetail();

    // if(JSON.parse(localStorage.getItem('userdetail')).Slack_Access_Token && JSON.parse(localStorage.getItem('userdetail')).Slack_Access_Token !== '' && JSON.parse(localStorage.getItem('userdetail')).Id_SlackUser === null)
    // {
    //     this.importSlackStepsTrace();
    // }

    // if(localStorage.getItem('Token') && localStorage.getItem('Token') !== null)
    // {
    //     this.importSlackStepsTrace();
    // }
    await this.importSlackStepsTrace();
    await this.userKnockListCabinWise();
    await this.stickyCount();
  }

  componentWillMount() {
    const loggedInRes = sessionStorage.getItem("userToken");
    if (loggedInRes !== null) {
      socket.connect();
      slack_socket.connect();
      socket.open();
      slack_socket.open();
    }
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.getUserDetailResponse !== this.props.getUserDetailResponse) {
    //     localStorage.setItem("userdetail", JSON.stringify(this.props.getUserDetailResponse));
    //     console.log("Component did update: ",this.props.userDetailResponse)
    // }

    if (prevProps.fetchOfficeResponse !== this.props.fetchOfficeResponse) {
      this.setState({ fetchOfficeResponse: this.props.fetchOfficeResponse });
      //console.log("officeResponse:  ", this.props.fetchOfficeResponse);
    }
  }

  componentWillReceiveProps = (nextProps, prevState) => {
    if (nextProps.userDetailResponse !== this.state.userDetails) {
      if (
        nextProps.userDetailResponse.Id_User ===
        JSON.parse(localStorage.getItem("userdetail")).Id_User
      ) {
        const avtar = nextProps.userDetailResponse.ProfilePic;
        this.setState({
          userDetails: nextProps.userDetailResponse,
          myAvatar: JSON.parse(avtar),
        });
        localStorage.setItem(
          "userdetail",
          JSON.stringify(nextProps.userDetailResponse)
        );
      }
    }
    if (nextProps.slackUserResponse !== prevState.slackUserResponse) {
      this.setState((prevState) => ({
        activeAccordian: prevState.activeAccordian.includes(this.state.teamId)
          ? []
          : [this.state.teamId],
        slackUserResponse: nextProps.slackUserResponse,
      }));
    }

    if (nextProps.departmentListByUserResponse !== prevState.dept_Array) {
      this.setState({
        dept_Array: nextProps.departmentListByUserResponse,
      });
      // if (nextProps.departmentListByUserResponse.length === 1) {
      //     this.setState({
      //         dept_Array: nextProps.departmentListByUserResponse,
      //         departmentId: nextProps.departmentListByUserResponse[0].Id
      //     })
      //     //this.getDepartmentId(nextProps.departmentListByUserResponse[0].Id)
      // } else {
      //     this.setState({
      //         dept_Array: nextProps.departmentListByUserResponse
      //     })
      // }
    }

    if (
      nextProps.slackConfigListResponse !== this.props.slackConfigListResponse
    ) {
      if (nextProps.slackConfigListResponse[0]) {
        localStorage.setItem(
          "SlackClientId",
          getEncryptedValue(nextProps.slackConfigListResponse[0].Client_ID)
        );
        this.setState({
          slackConfigListResponse: nextProps.slackConfigListResponse[0],
        });
      }
    }
    // if (nextProps.slackTeamResponse !== this.props.slackTeamResponse) {
    //   this.setState({ slackTeamResponse: nextProps.slackTeamResponse });
    // }

    if (this.state.fetchOfficeResponse !== this.props.fetchOfficeResponse) {
      this.setState({ fetchOfficeResponse: this.props.fetchOfficeResponse });
      //console.log("officeResponse:  ", this.props.fetchOfficeResponse);
    }
  };

  componentWillUnmount = () => {
    socket.close();
    slack_socket.close();
    socket.disconnect();
    slack_socket.disconnect();
    clearInterval(this.updateTimeInterval);
  };

  //User Sticky Count
  stickyCount = async () => {
    const { API_HOST, GetStickyCount } = this.props;
    const stickyCountRequest = {
      Id_UserReceiver: 0,
    };
    GetStickyCount(API_HOST, stickyCountRequest, (res) => {
      if (res > 0) {
        this.setState({
          notificationIsOpen: true,
          stickyCountResponse: res,
        });
      }
    });
  };

  //User Knock List Cabin Wise
  userKnockListCabinWise = async () => {
    const { API_HOST, userKnockListCabinWise } = this.props;
    const userKnockListCabinWiseRequest = {
      Id_UserReceiver: 0,
    };
    await userKnockListCabinWise(API_HOST, userKnockListCabinWiseRequest);
  };

  toggleCloseNotification = () => {
    if (this.props.userKnockListCabinWiseResponse.length > 0) {
      this.userKnockSeen();
    }

    if (this.state.stickyCountResponse > 0) {
      this.userStickySeen();
    }

    this.setState({
      notificationIsOpen: false,
    });
  };

  onClickReadNote = () => {
    this.setState({ onReadNote: true, notificationIsOpen: false });
    if (this.props.userKnockListCabinWiseResponse.length > 0) {
      this.userKnockSeen();
    }
  };

  //Close Read Notes Clicked
  onCloseReadNotes = (getCabinUserStickyList) => {
    this.setState({
      onReadNote: false,
    });
    this.stickyCount();
    if (getCabinUserStickyList.length > 0) {
      this.userStickySeen();
    }
  };

  //Set User Knock Seen
  userKnockSeen = () => {
    const { API_HOST, userKnockSeen } = this.props;
    userKnockSeen(API_HOST, (res) => {
      if (res) {
        this.userKnockListCabinWise(); //Count Knocks
      }
    });
  };

  //Set User Sticky Seen
  userStickySeen = async () => {
    const { API_HOST, userStickySeen } = this.props;
    await userStickySeen(API_HOST, (res) => {});
  };

  //Mutiple Workspace selection
  fetchMultipleEmail = async () => {
    const { API_HOST, fetchMultipleEmail } = this.props;
    await fetchMultipleEmail(API_HOST);

    this.setState({ multipleEmailResponse: this.props.multipleEmailResponse });
  };

  handleChangeWorkspace = async (data) => {
    data.preventDefault();
    if (data.target.value === "0") {
      let scope = this.state.slackConfigListResponse.Scope_Detail;
      let client_id = getDecryptedValue(
        this.state.slackConfigListResponse.Client_ID
      );
      window.location.href = `https://slack.com/oauth/v2/authorize?${scope}&client_id=${client_id}&redirect_uri=${window.location.protocol}//${window.location.host}/signingwithslacktoken`;
    } else {
      await this.setState({
        selectedWorkspace: data.target.value,
        selectedWorkspaceName: data.target.name,
      });
      await this.changeWorkspace(this.state.selectedWorkspace);
    }
  };

  //Change Workspace
  changeWorkspace = async (tenantId) => {
    const { API_HOST, changeWorkspace } = this.props;
    const changeWorkspaceRequest = {
      Id: tenantId,
    };

    await changeWorkspace(API_HOST, changeWorkspaceRequest);

    if (
      this.props.changeWorkspaceResponse &&
      this.state.isRedirectToLogin === false
    ) {
      // console.log("ccf",this.state.isRedirectToLogin);
      // this.props.history.push("/dashboard");
      window.location.reload();
    }

    if (
      this.props.changeWorkspaceResponse &&
      this.state.isRedirectToLogin === true
    ) {
      // console.log("cct",this.state.isRedirectToLogin);
      // this.props.history.push({ pathname: '/login' })

      await sessionStorage.removeItem("userToken");

      await localStorage.removeItem("Token");
      await localStorage.removeItem("SlackUserId");
      await localStorage.removeItem("isFromSlackSiginIn");

      this.props.history.push({ pathname: "/login" });
      toast.error(this.props.changeWorkspaceResponse.SuccessData.ErrorMessage);
    }
  };

  updateOfficeTime = (data) => {
    let newData = this.state.loggedInUserDetails;
    newData.UserStartTime_EndTimeVM.Availibility_Time =
      data.UserStartTime_EndTimeVM.Availibility_Time;
    //newData.TZ = data.TZ
    this.setState(Object.assign({}, { loggedInUserDetails: newData }));
    this.getUserListDepartmentUpdateOfficeTime();
  };

  getUserListDepartmentUpdateOfficeTime = async () => {
    const { API_HOST, getUserListDepartmentUpdateOfficeTime } = this.props;
    const requestBody = {
      // "Id_User" : 0,
      Id_Department: JSON.parse(localStorage.getItem("userdetail"))
        .Id_Department,
    };

    // const requestBody = {
    //     IsPagging: true,
    //     PageNo: 1,
    //     PageSize: 10,
    //     SortingParameters: [
    //       {
    //         Property: "",
    //         Direction: 0
    //       }
    //     ],
    //     SearchCriteria: {
    //       Id_User: 0,
    //       "Id_Department": JSON.parse(localStorage.getItem('userdetail')).Id_Department
    //     }
    //   };

    await getUserListDepartmentUpdateOfficeTime(API_HOST, requestBody);
  };

  zoomMeetingJoinLeave = async (value) => {
    const { API_HOST, zoomMeetingJoinLeave } = this.props;
    let requestBody = {
      Meeting_Number: window.atob(value),
      IsJoining: false,
    };
    await zoomMeetingJoinLeave(API_HOST, requestBody, (res) => {});
  };

  makeAdmin = (data) => {
    let userData = JSON.parse(localStorage.getItem("userdetail"));
    let newData = this.state.loggedInUserDetails;

    if (userData.Id_User === data.Id_User) {
      userData.Is_Admin = data.Is_Admin;
      newData.Is_Admin = data.Is_Admin;
      localStorage.setItem("userdetail", JSON.stringify(userData));
      //this.fetchSlackTeam();
      toast.info("You are now the Admin!");
      this.setState({
        Is_Admin: data.Is_Admin,
      });
    }
  };

  updateWorkspace = (data) => {
    this.setState({ multipleEmailResponse: data });
  };

  // Fetch all settings list.
  fetchSlackTeam = (showLessChannels) => {
    const { API_HOST, fetchSlackTeam } = this.props;
    fetchSlackTeam(API_HOST, showLessChannels, (res) => {
      if (res.length > 1) {
        // console.log("Slack Team Response: ",res);
        // this.setState({ slackTeamResponse: [] }); // first clear the list
        this.setState({ slackTeamResponse: res }); // refill the list
        this.newMesaagesForChannel(res);
        this.collapseConfiguration();
        // console.log("fetchSlackTeam called: ",this.props.slackTeamResponse);
      }
    });
    //   this.collapseConfiguration();
  };

  importUserList = () => {
    this.setState({ isProgressBar: true });
  };
  configureSlack = () => {
    this.setState({ myProfileIsOpen: true, isAddToSlack: true });
  };

  // Fetch Slack user list.
  fetchSlackUser = async (id_Channel) => {
    console.log("fetchSlackUser called");
    const { API_HOST, fetchSlackUser } = this.props;
    const slackUserRequest = {
      IsPagging: true,
      PageNo: 1,
      PageSize: 100,
      SortingParameters: [
        {
          Property: "",
          Direction: 0,
        },
      ],
      SearchCriteria: {
        SearchText: "",
        Id_Channel: id_Channel,
      },
    };
    await fetchSlackUser(API_HOST, slackUserRequest);
  };

  fetchSearchUser = async (id_Channel) => {
    const { API_HOST, fetchSearchUser } = this.props;
    const searchUserRequest = {
      IsPagging: true,
      PageNo: 1,
      PageSize: 100,
      SortingParameters: [
        {
          Property: "",
          Direction: 0,
        },
      ],
      SearchCriteria: {
        SearchText: "",
        Id_Channel: id_Channel,
      },
    };
    await fetchSearchUser(API_HOST, searchUserRequest);
  };

  //get department list user wise
  departmentListByUser = async (user_id) => {
    const { API_HOST, departmentListByUser } = this.props;
    await departmentListByUser(API_HOST, user_id);
  };

  //get user list department wise
  userListByDepartment = async (dept_id) => {
    const { API_HOST, userListByDepartment } = this.props;
    await userListByDepartment(API_HOST, dept_id);
  };

  //Get Tenant Detail
  getTenantDetail = (id) => {
    const { API_HOST, getTenantDetail } = this.props;
    getTenantDetail(API_HOST, id);
    this.setState({ tenantDetails: this.props.tenantDetailResponse });
  };

  //Update Tenant
  updateTenant = async () => {
    const { API_HOST, updateTenant } = this.props;
    const updateTenantRequest = {
      id: this.state.tenantDetails.Id,
      name: this.state.tenantDetails.Name,
      address: this.state.tenantDetails.Address,
      zipcode: this.state.tenantDetails.Zipcode,
      contact_person_name: this.state.tenantDetails.Contact_Person_Name,
      contact_person_number: this.state.tenantDetails.Contact_Person_Number,
    };
    await updateTenant(API_HOST, updateTenantRequest);

    if (this.props.updateTenantResponse) {
      if (localStorage.getItem("userdetail")) {
        var userData = JSON.parse(localStorage.getItem("userdetail"));
        userData.Tenant_Name = this.props.updateTenantResponse.Name;
        localStorage.setItem("userdetail", JSON.stringify(userData));
        this.userValuesChanges(userData);
        this.setState({ editTenantIsOpen: false });
      }
    }
  };

  // Channel List  User List API call
  toggleChange = async (value, team) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userToken =
      userData.Slack_Access_Token !== ""
        ? getDecryptedValue(userData.Slack_Access_Token)
        : "";
    var scope = userData.Slack_Scope_Detail;
    if (
      userToken !== "" &&
      scope === this.state.slackConfigListResponse.Scope_Detail
    ) {
      //if Slack token is available
      await $("#chatWindow").addClass("show").css("display", "block");

      await $("#teamlistLi" + team.OtherContent).addClass("current");
      //console.log("OtherContent: ", team.OtherContent);

      if (this.state.teamId !== value) {
        $("#teamlistLi" + this.state.team.OtherContent).removeClass("current");
        this.fetchSlackUser(value);
        this.setState({
          teamId: value,
          searchText: "",
          searchUser: null,
          team: team,
        });
      } else {
        this.setState((prevState) => ({
          activeAccordian: prevState.activeAccordian.includes(value)
            ? []
            : [value],
          teamId: value,
          roomId: "",
          searchText: "",
          searchUser: null,
          team: team,
        }));
      }
      if (this.state.channelsUnreadCounts.length > 0) {
        this.markAsReadOnChannelOpen(team.OtherContent);
      }
      //console.log("Office ID: ", this.state.teamId);
    } else {
      this.setState({
        isAddToSlack: true,
        myProfileIsOpen: true,
      });
    }
  };

  //Open Office Which Find  User
  officeExpand = (value) => {
    this.setState((prevState) => ({
      activeOfficeAccordian: prevState.activeOfficeAccordian.includes(value)
        ? [value]
        : [value],
    }));
  };

  //Toggle for tenant Office
  toggleOfficeChange = (value) => {
    $("#chatWindow").removeClass("show").css("display", "none");
    //this.fetchSearchUser(0)
    this.setState((prevState) => ({
      activeOfficeAccordian: prevState.activeOfficeAccordian.includes(value)
        ? []
        : [value],
      officeId: value,
      roomId: "",
      searchText: "",
      searchUser: null,
      teamId: 0,
    }));
  };

  toggleWaterCooler = (value) => {
    this.setState((prevState) => ({
      activeWaterCooler: prevState.activeWaterCooler.includes(value)
        ? []
        : [value],
    }));
  };

  toggleConfiguration = () => {
    this.setState({ isConfigurationOpen: !this.state.isConfigurationOpen });
  };

  toggleLog = () => {
    this.setState({ isLogOpen: !this.state.isLogOpen });
  };

  collapseConfiguration = () => {
    //  if (this.state.slackTeamResponse.length <= 1) {
    // console.log("Team Length is: ",this.state.slackTeamResponse.length);
    this.setState({ isConfigurationOpen: false });
    // }
  };

  // Get User Detail
  getUserDetail = async (id) => {
    const { API_HOST, getUserDetail } = this.props;
    await getUserDetail(API_HOST, id);
    console.log(
      "In function...get user detail: ",
      JSON.stringify(this.props.userDetailResponse)
    );
  };

  //Update User
  updateUser = async (event, userDetailData) => {
    var apiCall = true;
    if (userDetailData.Real_Name === "") {
      toast.error("Display name cannot be blank.");
      apiCall = false;
    }
    if (!userDetailData.First_Name.match(/^[a-zA-Z]*$/g)) {
      toast.error(
        "First Name can have only Alphabets and length upto 20 characters"
      );
      apiCall = false;
    }
    if (!userDetailData.Last_Name.match(/^[a-zA-Z]*$/g)) {
      toast.error(
        "Last Name can have only Alphabets and length upto 20 characters"
      );
      apiCall = false;
    }
    if (!userDetailData.Phone.match(/[1-9]{1}[0-9]{9}/)) {
      toast.error(
        "Phone number can have only numbers and length upto 10 characters"
      );
      apiCall = false;
    }
    if (userDetailData.Id_Department === 0) {
      toast.error("Please select a department.");
      apiCall = false;
    }
    if (apiCall) {
      const { API_HOST, updateUser } = this.props;
      const updateUserRequest = {
        Id: userDetailData.Id_User,
        First_Name: userDetailData.First_Name,
        Last_Name: userDetailData.Last_Name,
        Phone: userDetailData.Phone,
        Skype: userDetailData.Skype,
        Address: userDetailData.Address,
        ZipCode: userDetailData.ZipCode,
        Real_Name: userDetailData.Real_Name,
        Display_Name: userDetailData.Display_Name,
        Title: userDetailData.Title,
        Id_Department: userDetailData.Id_Department,
        Is_LoggedIn: userDetailData.Is_LoggedIn,
        CurrentLoggedInTenant_Name: userDetailData.CurrentLoggedInTenant_Name,
        // "TZ": userDetailData.TZ,
        // "TZ_Label": userDetailData.TZ_Label,
        // "TZ_Offset": userDetailData.TZ_Offset
      };
      await updateUser(API_HOST, updateUserRequest);

      if (this.props.updateUserResponse) {
        if (localStorage.getItem("userdetail")) {
          var userData = JSON.parse(localStorage.getItem("userdetail"));
          userData.Display_Name = userDetailData.Display_Name;
          userData.First_Name = userDetailData.First_Name;
          userData.Last_Name = userDetailData.Last_Name;
          userData.Phone = userDetailData.Phone;
          userData.Skype = userDetailData.Skype;
          userData.Address = userDetailData.Address;
          userData.ZipCode = userDetailData.ZipCode;
          userData.Real_Name = userDetailData.Real_Name;
          userData.Title = userDetailData.Title;
          userData.Id_Department = userDetailData.Id_Department;
          userData.Department_Name = userDetailData.Department_Name;
          userData.Is_LoggedIn = userDetailData.Is_LoggedIn;
          userData.CurrentLoggedInTenant_Name =
            userDetailData.CurrentLoggedInTenant_Name;
          localStorage.setItem("userdetail", JSON.stringify(userData));
          this.userValuesChanges(userData);
        }
        this.fetchSearchUser(0);
        this.getUserListDepartmentFilter();
      }
    }
  };

  getUserModal = (id) => {
    this.getUserDetail(id);
    if (this.props.userDetailResponse) {
      this.setState({ viewUserProfile: this.props.userDetailResponse });
    }
    this.toggleViewProfile();
  };

  getTenantModal = (id) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userAdmin = userData.Is_Admin;
    if (userAdmin) {
      this.setState({ editDisabled: false });
    }
    this.getTenantDetail(id);
    this.toggleEditTenant();
  };

  toggleViewProfile = () => {
    this.setState({
      viewProfileIsOpen: !this.state.viewProfileIsOpen,
    });
  };

  toggleEditTenant = () => {
    this.setState({
      editTenantIsOpen: !this.state.editTenantIsOpen,
    });
  };

  onClickOffice = () => {
    this.setState({
      onOfficeSelected: true,
    });
  };

  onClickCreateOffice = () => {
    this.props.history.push({ pathname: "/office" });
  };

  toggleCloseOffice = () => {
    this.setState({
      onOfficeSelected: !this.state.onOfficeSelected,
    });
  };

  onClickDepartment = () => {
    this.setState({
      onDeptSelected: true,
    });
  };

  onClickAssignDepartment = () => {
    this.setState({
      assignDepartment: true,
    });
  };

  onClickManageDepartment = () => {
    this.setState({
      manageDepartment: true,
    });
  };

  //Add new user to Slack - Admin rights
  onClickInviteNewUserToSlack = () => {
    this.setState({
      inviteUserToSlack: true,
    });
  };

  toggleInviteToSlack = () => {
    this.setState({
      inviteUserToSlack: false,
    });
  };

  toggleTenantLog = () => {
    this.setState({
      onTenantLog: !this.state.onTenantLog,
    });
  };

  toggleMeetingActivityLog = () => {
    this.setState({
      meetingActivityLog: !this.state.meetingActivityLog,
    });
  };

  onClickTenantLog = () => {
    this.setState({
      onTenantLog: true,
    });
  };

  onClickMeetingLog = () => {
    this.setState({
      meetingActivityLog: true,
    });
  };

  toggleFeedbackLog = () => {
    this.setState({
      feedbackLog: !this.state.feedbackLog,
    });
  };

  onClickFeedbackLog = () => {
    this.setState({
      feedbackLog: true,
    });
  };

  toggleContactUsLog = () => {
    this.setState({
      contactUsLog: !this.state.contactUsLog,
    });
  };

  onClickContactUsLog = () => {
    this.setState({
      contactUsLog: true,
    });
  };

  toggleCloseDept = () => {
    this.setState({
      onDeptSelected: !this.state.onDeptSelected,
    });
  };

  toggleCloseAssignDepartment = () => {
    this.setState({
      assignDepartment: !this.state.assignDepartment,
    });
  };

  //Close manage dept modal pop-up
  toggleManageDept = () => {
    this.setState({
      manageDepartment: !this.state.manageDepartment,
      isAddnewMember: false,
      addnewMemberDeptId: 0,
    });
  };

  //Function call when any input field is changing
  handleEditProfileChange = (e) => {
    let userDetails = this.state.userDetails;
    userDetails[e.target.name] = e.target.value;
    this.setState({
      userDetails,
    });
  };

  //Function call when any tenant input field is changing
  handleEditTenantChange = (e) => {
    let tenantDetails = this.state.tenantDetails;
    tenantDetails[e.target.name] = e.target.value;
    this.setState({
      tenantDetails,
    });
  };

  toggleMyProfile = () => {
    this.setState({
      myProfileIsOpen: !this.state.myProfileIsOpen,
      isAddToSlack: false,
      isEditWorkingHours: false,
      isGoogleCalendarMeeting: false,
      isConnectToJira: false,
    });
    this.updateprofilepic();
  };

  selectedAvatar = (field, e) => {
    let myAvatar = this.state.myAvatar;
    myAvatar[field] = e;
    this.setState({ myAvatar });
  };

  //Function call for updating user Profile pic
  updateprofilepic = async () => {
    if (
      this.state.userDetails.ProfilePic &&
      this.state.userDetails.ProfilePic !== JSON.stringify(this.state.myAvatar)
    ) {
      const { API_HOST, updateProfilePic } = this.props;
      const avatarPic = JSON.stringify(this.state.myAvatar);
      const updatePofilePicRequest = {
        ProfilePicValue: avatarPic,
      };
      await updateProfilePic(API_HOST, updatePofilePicRequest);
      let userDetails = this.state.userDetails;
      userDetails["ProfilePic"] = avatarPic;
      this.setState({ userDetails, myAvatar: JSON.parse(avatarPic) });

      var userData = JSON.parse(localStorage.getItem("userdetail"));
      userData.ProfilePic = avatarPic;
      this.userValuesChanges(userData);
    }
  };

  //Search Users
  search = (searchTerm, data) => {
    let results = [];
    if (data) {
      //results = data.filter(object => Object.values(object).join().includes(searchTerm.toLowerCase()));
      results = data.filter(function (object) {
        return object.Name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }
    if (this.state.isMainOffice === true) {
      if (searchTerm.length === 0) {
        this.setState({ roomId: "", searchText: searchTerm, searchUser: null });
      } else {
        this.setState({
          searchUser: results,
          isMainOffice: true,
          searchText: searchTerm,
          peopleSelected: false,
        });
      }
    } else {
      if (searchTerm.length === 0) {
        this.setState({ roomId: "", searchText: searchTerm, searchUser: null });
        //this.officeExpand('')
      } else {
        this.setState({
          slackUserResponse: results,
          peopleSelected: true,
          searchText: searchTerm,
          searchUser: null,
          isMainOffice: false,
        });
      }
      return results;
    }
  };

  searchResponse = (searchTerm, data) => {
    this.search(searchTerm, data);
  };

  //Change password
  changePassword = async (oldPassword, password) => {
    const { API_HOST, changePassword } = this.props;
    const changePasswordRequest = {
      CurrentPassword: getEncryptedValue(oldPassword),
      NewPassword: getEncryptedValue(password),
    };
    await changePassword(API_HOST, changePasswordRequest);
  };

  handleDateChange = (date) => {
    this.setState({
      datePicked: date,
    });
  };

  onTimeSelected = (e, value) => {
    e.stopPropagation();
    this.setState({ timeFilter: value });
  };

  toggleUnreadSentSticky = () => {
    this.setState({
      isUnreadSentStickyModal: !this.state.isUnreadSentStickyModal,
    });
  };

  leftSideExpand = () => {
    this.setState((prevState) => ({
      leftSideExpand: !this.state.leftSideExpand,
      officeId: 1,
      activeOfficeAccordian: prevState.activeOfficeAccordian.includes(
        this.state.officeId
      )
        ? []
        : [1],
    }));
    $("#chatWindow").removeClass("show").css("display", "none");
  };

  getDepartmentId = (id) => {
    this.setState({
      departmentId: id,
    });
  };

  getSearcbarUser = (id) => {
    this.setState({
      SearchbarUserId: id,
    });
  };

  toggleSlackEdit = () => {
    this.setState({
      isSlackEdit: !this.state.isSlackEdit,
    });
  };

  minimizeChatWindow = () => {
    let multipleChatWindow = this.state.multipleChatWindow;
    if (multipleChatWindow.length > 2) {
      multipleChatWindow.splice(0, 1);
    }
    this.setState({ multipleChatWindow });

    this.setState({
      isMinimizeChat: true,
    });
  };

  maximizeChatWindow = () => {
    this.setState({
      isMinimizeChat: false,
    });
  };

  handleAddChat = (userDetails) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userToken = getDecryptedValue(userData.Slack_Access_Token);
    var scope = userData.Slack_Scope_Detail;
    let isSlackUserOpen =
      this.state.multipleChatWindow.length > 0 &&
      this.state.multipleChatWindow.find(
        (team) =>
          getDecryptedValue(team.Id_SlackUser) ===
          getDecryptedValue(userDetails.Id_SlackUser)
      )
        ? true
        : false;
    if (
      userToken !== "" &&
      getDecryptedValue(userData.Id_SlackUser) !== null &&
      scope === this.state.slackConfigListResponse.Scope_Detail
    ) {
      //if Slack token is available //
      let multipleChatWindow = this.state.multipleChatWindow;
      if (!multipleChatWindow.includes(userDetails) && !isSlackUserOpen) {
        if (this.state.isMinimizeChat) {
          if (multipleChatWindow.length > 1) {
            multipleChatWindow.splice(0, 1);
          }
          multipleChatWindow.push(userDetails);
        } else {
          if (multipleChatWindow.length > 2) {
            multipleChatWindow.splice(0, 1);
          }
          multipleChatWindow.push(userDetails);
        }
      } else {
        $(`#chatWindow${getDecryptedValue(userDetails.Id_User)}`).removeClass(
          "onminitoggle"
        );
      }
      this.setState({ multipleChatWindow });
    } else {
      this.setState({
        isAddToSlack: true,
        myProfileIsOpen: true,
      });
    }
  };

  getSlackConfigurationlist = async () => {
    const { API_HOST, getSlackConfigurationlist } = this.props;
    await getSlackConfigurationlist(API_HOST);

    if (this.props.slackConfigListResponse.length > 0) {
      this.setState({
        slackConfigListResponse: this.props.slackConfigListResponse,
      });
    }
    //console.log("cc",this.props.slackConfigListResponse);
  };

  onClickSlackEdit = () => {
    this.setState({ isSlackEdit: true });
  };

  closeChatWindows = (userDetails) => {
    var array = [...this.state.multipleChatWindow]; // make a separate copy of the array
    var index = array.indexOf(userDetails);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ multipleChatWindow: array });
    }
  };

  toggleSupportChat = () => {
    this.setState({
      isSupportChat: true,
    });
  };

  userValuesChanges = (userData) => {
    let loggedInUserDetails = JSON.parse(localStorage.getItem("userdetail"));
    loggedInUserDetails.Address = userData.Address;
    loggedInUserDetails.CompanyName = userData.CompanyName;
    loggedInUserDetails.Department_Name = userData.Department_Name;
    loggedInUserDetails.Department_Size_Type = userData.Department_Size_Type;
    loggedInUserDetails.Display_Name = userData.Display_Name;
    loggedInUserDetails.Id_UserTenantDetail = userData.Id_UserTenantDetail;
    loggedInUserDetails.Id_Tenant = userData.Id_Tenant;
    // loggedInUserDetails.EmailId = userData.EmailId
    loggedInUserDetails.First_Name = userData.First_Name;
    loggedInUserDetails.Id_Department = userData.Id_Department;
    loggedInUserDetails.Id_Role = userData.Id_Role;
    loggedInUserDetails.Is_Admin = userData.Is_Admin;
    loggedInUserDetails.Is_DoorOpen = userData.Is_DoorOpen;
    loggedInUserDetails.Is_DoorOpenDepartment = userData.Is_DoorOpenDepartment;
    loggedInUserDetails.Last_Name = userData.Last_Name;
    loggedInUserDetails.Phone = userData.Phone;
    loggedInUserDetails.Position = userData.Position;
    loggedInUserDetails.ProfilePic = userData.ProfilePic;
    loggedInUserDetails.Real_Name = userData.Real_Name;
    loggedInUserDetails.Skype = userData.Skype;
    loggedInUserDetails.TZ = userData.TZ;
    loggedInUserDetails.TZ_Label = userData.TZ_Label;
    loggedInUserDetails.TZ_Offset = userData.TZ_Offset;
    //loggedInUserDetails.Tenant_Name = userData.Tenant_Name
    loggedInUserDetails.Title = userData.Title;
    loggedInUserDetails.UserStartTime_EndTimeVM =
      userData.UserStartTime_EndTimeVM;
    loggedInUserDetails.ZipCode = userData.ZipCode;
    loggedInUserDetails.UserStatus = userData.UserStatus;
    loggedInUserDetails.Is_Latest_Department_Assigned =
      userData.Is_Latest_Department_Assigned;
    loggedInUserDetails.TenantOffice_Name = userData.TenantOffice_Name;
    loggedInUserDetails.Id_TenantOffice = userData.Id_TenantOffice;
    loggedInUserDetails.Is_LoggedIn = userData.Is_LoggedIn;
    loggedInUserDetails.CurrentLoggedInTenant_Name =
      userData.CurrentLoggedInTenant_Name;

    localStorage.setItem("userdetail", JSON.stringify(loggedInUserDetails));
    // console.log("Dashboard getDepartmentsResponse", getDepartmentsResponse);

    let temp = this.props.departmentListFilterWiseResponse.filter(
      (a) => a.Id == loggedInUserDetails.Id_Department
    );
    console.log(
      "loggedInUserDetails.Id_Department: ",
      loggedInUserDetails.Id_Department
    );
    console.log(
      "this.props.departmentListFilterWiseResponse: ",
      this.props.departmentListFilterWiseResponse
    );
    console.log("TEMP123  : ", temp);
    if (temp.length === 0) {
      console.log("Dashboard temp.length === 0");
      socket.emit("addNewDept", loggedInUserDetails);
    }

    socket.emit("UserDetail", loggedInUserDetails);
    this.setState({ loggedInUserDetails: loggedInUserDetails });
  };

  updateTime = () => {
    let date = new Date();
    this.setState({ date: date });
  };

  closeSupportChat = () => {
    this.setState({
      isSupportChat: false,
    });
  };

  // updateUserTimezone = (event, userDetailData) => {
  //     const { API_HOST, updateUserTimezone } = this.props;
  //     const updateUserRequest = {
  //         "TZ": userDetailData.TZ,
  //         "TZ_Label": userDetailData.TZ_Label,
  //         "TZ_Offset": userDetailData.TZ_Offset
  //     };
  //     updateUserTimezone(API_HOST, updateUserRequest, (res) => {
  //         if (res) {
  //             var userData = JSON.parse(localStorage.getItem('userdetail'));

  //             userData.TZ = userDetailData.TZ;
  //             userData.TZ_Label = userDetailData.TZ_Label;
  //             userData.TZ_Offset = userDetailData.TZ_Offset;
  //             localStorage.setItem('userdetail', JSON.stringify(userData));
  //             this.userValuesChanges(userData);
  //             this.getUserListDepartmentUpdateOfficeTime()
  //             this.fetchSearchUser(0)
  //         }
  //     }
  //     )
  // }

  editUserWorkingHours = () => {
    this.setState({
      myProfileIsOpen: true,
      isEditWorkingHours: true,
    });
  };

  connectToJira = () => {
    this.setState({
     // myProfileIsOpen: true,
      isConnectToJira: true,
    });
  };

  //List of Selected Users' Slack id for chat
  getSelectedUsers = (data, data2) => {
    this.setState({ isUserSelected: false });
    const result = this.state.selectedUsersList;
    const resultUsers = this.state.selectedUsersGroupName;
    const userIdResult = [];
    const map = new Map();
    for (const item of data) {
      if (!map.has(item.Id_SlackUser)) {
        map.set(item.Id_SlackUser, true); // set any value to Map

        if (!result.includes(item.Id_SlackUser)) {
          //Duplicate not allowed
          result.push(item.Id_SlackUser);
          if (item.Id_SlackUser !== null) {
            let indexof =
              item.Real_Name.indexOf(" ") < 5
                ? item.Real_Name.indexOf(" ") !== -1
                  ? item.Real_Name.indexOf(" ")
                  : item.Real_Name.length
                : 5;
            resultUsers.push(item.Real_Name.substr(0, indexof).toLowerCase());
          }
        }
      }
    }
    if (resultUsers !== [] && resultUsers.length < 2) {
      let realName = JSON.parse(localStorage.getItem("userdetail")).Real_Name;
      let indexof =
        realName.indexOf(" ") < 5
          ? realName.indexOf(" ") !== -1
            ? realName.indexOf(" ")
            : realName.length
          : 5;
      resultUsers.push(realName.substr(0, indexof).toLowerCase());
    }

    this.setState({
      selectedUsersList: result,
      selectedUsersGroupName: resultUsers,
    });

    for (const item2 of data2) {
      if (!map.has(item2.Id_User)) {
        map.set(item2.Id_User, true); // set any value to Map

        if (!userIdResult.includes(item2.Id_User)) {
          //Duplicate not allowed
          userIdResult.push({
            Id_User: item2.Id_User,
          });
        }
      }
    }

    this.setState({
      selectedUsersID: userIdResult,
      selectedUserDetail: data2[0],
    });
    //console.log("Selected User ID: ", this.state.selectedUsersID);
    console.log("data2: ", this.state.selectedUserDetail);
    this.setState({ isUserSelected: true });
    if (this.state.selectedUsersID.length >= 2) {
      this.setState({ showChatIcon: true });
    }
  };

  //List of Users Removed from chat selection
  removeSelectedUsers = (slackId, userId, userName, data2) => {
    var userArray = this.state.selectedUsersList;
    var groupName = this.state.selectedUsersGroupName;
    var index = userArray.indexOf(slackId);
    userArray.splice(index, 1);
    //console.log("After removed: ", this.state.selectedUsersList);

    var userIdArray = this.state.selectedUsersID;

    userIdArray.splice(index, 1);
    let indexofUsers =
      userName.indexOf(" ") < 5
        ? userName.indexOf(" ") !== -1
          ? userName.indexOf(" ")
          : userName.length
        : 5;

    var UserIndex = groupName.indexOf(
      userName.substr(0, indexofUsers).toLowerCase()
    );

    groupName.splice(UserIndex, 1);

    this.setState({
      selectedUsersGroupName: groupName,
      selectedUserDetail: data2[0],
    });
    if (this.state.selectedUsersID.length <= 1) {
      this.setState({ showChatIcon: false });
    }
    //console.log("After Removed UserID: ", this.state.selectedUsersID);
  };

  onClickUsersSelectedChat = () => {
    if (this.state.selectedUsersList.length > 0) {
      if (this.state.selectedUsersList.length === 1) {
        //call one to one chat
        this.handleAddChat(this.state.selectedUserDetail);
      }

      if (this.state.selectedUsersList.length > 1) {
        //call function to create a new conversation
        this.checkAlreadyExistChannel();
      }
    } else {
      toast.info("Please select members to start a new group chat");
    }
  };

  //Create virtual chat group
  createVirtualChatGroup = async () => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userSlackToken = userData.Slack_Access_Token;

    const token = getDecryptedValue(userSlackToken);
    var moment = require("moment-timezone");
    let date = moment().format("DDMMYYYY");
    let groupName =
      this.state.selectedUsersGroupName.toString().length < 70
        ? this.state.selectedUsersGroupName.toString() + date
        : this.state.selectedUsersGroupName.toString().substr(0, 70) +
          date +
          "...";
    // groupName = groupName.replace(/,/g, '-');

    groupName = groupName.replace(/[,.]/g, function (x) {
      if (x === ",") return x.replace(",", "-");

      if (x === ".") return x.replace(".", "");
    });
    //console.log(groupName)
    try {
      let group = {
        token: token,
        name: groupName,
        is_private: true,
      };
      const result = await axios.post(
        `https://slack.com/api/conversations.create`,
        qs.stringify(group)
      );

      let options = { autoClose: 20 * 1000 };

      if (result.data.ok === false) {
        if (result.data.error === "not_authed") {
          this.configureSlack();
          toast.info(
            "Please configure your Slack account to create a new chat group.",
            options
          );
        } else {
          toast.error("Error: ", result.data.error);
        }
      } else {
        let group_members = [];
        for (const member of this.state.selectedUsersList) {
          group_members.push(getDecryptedValue(member));
        }
        result.members = group_members;

        await slack_socket.emit("channel_created", result);

        this.setState({
          virtualChannelId: result.data.channel.id,
          virtualChannelName: result.data.channel.name,
        });

        this.addSelectedUsersToVirtualChat(this.state.virtualChannelId);
        this.addVirtualChatGroup(this.state.virtualChannelName);

        // let targetElement = document.getElementById(`mainchat${this.props.teamDetails.OtherContent}`);
        // targetElement.scrollTop = targetElement.scrollHeight;
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
    }
  };

  //Add Selected Users in the virtual chat group.
  addSelectedUsersToVirtualChat = async (channelId) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userSlackToken = userData.Slack_Access_Token;

    const token = getDecryptedValue(userSlackToken);

    let userlist = "";
    let group_members = [];
    for (const member of this.state.selectedUsersList) {
      group_members.push(getDecryptedValue(member));
    }

    //console.log("GROUP MeM: ",group_members);
    userlist = group_members.toString();

    try {
      let members = {
        token: token,
        channel: channelId,
        users: userlist,
      };
      const result = await axios.post(
        `https://slack.com/api/conversations.invite`,
        qs.stringify(members)
      );
      //console.log("convo info", result)

      // let targetElement = document.getElementById(`mainchat${this.props.teamDetails.OtherContent}`);
      // targetElement.scrollTop = targetElement.scrollHeight;
    } catch (error) {
      console.error(error);
    }
  };

  getUserListDepartmentFilter = () => {
    const { API_HOST, getUserListDepartmentFilter } = this.props;
    const requestBody = {
      // "Id_User" : 0,
      Id_Department: JSON.parse(localStorage.getItem("userdetail"))
        .Id_Department,
    };

    // const requestBody = {
    //     "IsPagging" : true,
    //     "PageNo" : 1,
    //     "PageSize" : 10,
    //     "SortingParameters" : [
    //     {
    //     "Property" :"",
    //     "Direction": 0
    //     }
    //     ],
    //     "SearchCriteria" : {
    //     "Id_User" : 0,
    //     "Id_Department": JSON.parse(localStorage.getItem('userdetail')).Id_Department

    //     }
    //     };
    getUserListDepartmentFilter(API_HOST, requestBody);
  };

  // Add Virtual Chat Group to Slack Team
  addVirtualChatGroup = async (channelName) => {
    const { API_HOST, addVirtualChatGroup } = this.props;
    const addVirtualChatGroupRequest = {
      id: 0,
      name: channelName,
      description: "",
      id_slack: this.state.virtualChannelId,
      ChannelUserList: this.state.selectedUsersID,
    };
    await addVirtualChatGroup(API_HOST, addVirtualChatGroupRequest);

    console.log(
      "addVirtualChatGroupResponse",
      this.props.addVirtualChatGroupResponse
    );

    if (
      this.props.addVirtualChatGroupResponse !== null &&
      this.props.addVirtualChatGroupResponse.Id > 0
    ) {
      await this.fetchSlackTeam(false);

      await this.fetchSlackUser(this.props.addVirtualChatGroupResponse.Id);

      let virtualTeamResult = this.state.slackTeamResponse.find(
        (team) => team.Id === this.props.addVirtualChatGroupResponse.Id
      );
      console.log("virtualTemResult: ", virtualTeamResult);

      await this.setState({ team: virtualTeamResult });
      console.log("team* : ", this.state.team);

      console.log("Teamlist: ", this.state.slackTeamResponse);
      await $("#chatWindow").addClass("show").css("display", "block");
      await $("#teamlistLi" + this.state.virtualChannelId).addClass("current");

      // let targetElement = document.getElementById("user_list");
      // targetElement.scrollTop = targetElement.scrollHeight;

      this.setState({
        teamId: this.props.addVirtualChatGroupResponse.Id,
        leftSideExpand: true,
      });

      console.log("teamResponse:   ", this.state.teamId);
    }
  };

  setGoogleCalendarMeeting = () => {
    // also replaced myProfileIsOpen with isMoreInfo
    this.setState({
      isGoogleCalendarMeeting: true,
      isMoreInfo: true,
    });
  };

  addWaterCooler = async (team) => {
    const { API_HOST, addWaterCoolerGroup } = this.props;
    let reqObj = {
      Id_Channel: team.Id,
      Usage_Frequency: team.UsageFrequency,
    };
    await addWaterCoolerGroup(API_HOST, reqObj, (res) => {
      if (res === true) {
        const { slackTeamResponse } = this.state;
        const index = slackTeamResponse
          ? slackTeamResponse.findIndex((obj) => obj.Id === team.Id)
          : -1;
        if (index !== -1) {
          slackTeamResponse[index].UsageFrequency = team.UsageFrequency + 1;

          if (slackTeamResponse[index].UsageFrequency === 3) {
            slackTeamResponse[index].IsWaterCooler = true;
            this.toggleChange(
              slackTeamResponse[index].Id,
              slackTeamResponse[index]
            );
          }
        }
        this.setState({ slackTeamResponse });
        //this.fetchSlackTeam(this.state.showChannels);
      }
    });
  };

  //update user tour completion
  updateUserTour = async () => {
    const { API_HOST, updateUserTour } = this.props;

    await updateUserTour(API_HOST, (res) => {
      if (res) {
        let userData = JSON.parse(localStorage.getItem("userdetail"));
        userData.Is_UserTour_Completed = true;
        localStorage.setItem("userdetail", JSON.stringify(userData));
        this.skipTour();

        //     if(localStorage.getItem("isFromSlackSiginIn") && localStorage.getItem("isFromSlackSiginIn") === "true" && userData.Is_Admin && this.state.importSlackStep < 3)
        //     {
        //         console.log("user tour is ready to import")
        //         let token = getDecryptedValue(localStorage.getItem("Token"));
        //         this.updateTenantSlackAccessToken(token);
        //         // this.importUserList();
        //    }

        if (
          localStorage.getItem("autoImport") &&
          localStorage.getItem("autoImport") === "true" &&
          userData.Is_Admin &&
          this.state.importSlackStep < 3 &&
          userData.Slack_Access_Token != ""
        ) {
          var userToken = userData.Slack_Access_Token;
          var userRefreshToken = userData.Slack_Refresh_Token;

          this.updateTenantSlackAccessToken(userToken, userRefreshToken);
        }
      }
    });
  };

  clearSelectedUserGroupName = () => {
    this.setState({
      selectedUsersList: [],
      selectedUsersGroupName: [],
      showChatIcon: false,
    });
  };

  conversationHistory = async (channelId) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userSlackToken = getDecryptedValue(userData.Slack_Access_Token);

    try {
      let group = {
        token: userSlackToken,
        channel: channelId,
        unreads: 1,
        limit: 1,
      };

      const result = await axios.post(
        `https://slack.com/api/conversations.history`,
        qs.stringify(group)
      );
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  newMesaagesForChannel = async (slackChannel) => {
    const channelsCount = [];
    for (const item of slackChannel) {
      let info = await this.conversationHistory(item.OtherContent);
      channelsCount.push({
        ChannelId: item.OtherContent,
        count: info.data ? info.data.unread_count_display : 0,
        last_read:
          info.data && info.data.messages && info.data.messages[0]
            ? info.data.messages[0].ts
            : 0,
      });
    }
    channelsCount.sort(function (a, b) {
      return b.last_read - a.last_read;
    });
    let sortingArray = channelsCount.map(function (x) {
      return x.ChannelId;
    });
    let slackTeamResponses = [];
    let result = [];
    sortingArray.forEach(function (key) {
      var found = false;
      slackTeamResponses = slackChannel.filter(function (item) {
        if (!found && item.OtherContent == key) {
          result.push(item);
          found = true;
          return false;
        } else return true;
      });
    });
    // slackTeamResponses.sort((a, b) => sortingArray.indexOf(a.OtherContent) - sortingArray.indexOf(b.OtherContent));
    this.setState({
      channelsUnreadCounts: channelsCount,
      slackTeamResponse: result,
    });
  };

  markAsReadOnChannelOpen = async (channelId) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userSlackToken = getDecryptedValue(userData.Slack_Access_Token);
    //var moment = require('moment-timezone');
    //const loggedInUserDetails = this.state.loggedInUserDetails;
    //const user_TZ = loggedInUserDetails.TZ === "" || loggedInUserDetails.TZ === null ? "Asia/Kolkata" : loggedInUserDetails.TZ;
    try {
      let group = {
        token: userSlackToken,
        channel: channelId,
        ts: Date.now() / 1000,
      };

      const result = await axios.post(
        `https://slack.com/api/conversations.mark`,
        qs.stringify(group)
      );

      if (result) {
        this.changeUnreadChannelCounts(channelId);
      }
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  changeUnreadChannelCounts = async (channelId) => {
    if (this.state.channelsUnreadCounts.length > 0) {
      let channelCounts = this.state.channelsUnreadCounts;
      let info = await this.conversationHistory(channelId);

      let index = channelCounts.findIndex(
        (channel) => channel.ChannelId === channelId
      );

      channelCounts[index] = {
        ChannelId: channelId,
        count: info.data ? info.data.unread_count_display : 0,
        last_read:
          info.data && info.data.messages && info.data.messages[0]
            ? info.data.messages[0].ts
            : 0,
      };

      channelCounts.sort(function (a, b) {
        return b.last_read - a.last_read;
      });
      let sortingArray = channelCounts.map(function (x) {
        return x.ChannelId;
      });
      let slackTeamResponses = this.state.slackTeamResponse;
      let result = [];
      sortingArray.forEach(function (key) {
        var found = false;
        slackTeamResponses = slackTeamResponses.filter(function (item) {
          if (!found && item.OtherContent === key) {
            result.push(item);
            found = true;
            return false;
          } else return true;
        });
      });
      // slackTeamResponses.sort((a, b) => sortingArray.indexOf(a.OtherContent) - sortingArray.indexOf(b.OtherContent));
      this.setState({
        channelsUnreadCounts: channelCounts,
        slackTeamResponse: result,
      });
    }
  };

  findChannelDetails = async () => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userSlackToken = getDecryptedValue(userData.Slack_Access_Token);

    try {
      let group = {
        token: userSlackToken,
        types: "im",
        // channel:channelId,
        // include_num_members:true
      };
      const result = await axios.post(
        `https://slack.com/api/conversations.list`,
        qs.stringify(group)
      );

      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  findAllOneToOneChat = async () => {
    let result = await this.findChannelDetails();
    console.log("findChannelDetails Result  :  ", result);
    let oneToOneChannelUnreads = this.state.oneToOneUnreadCount;
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userSlackId =
      userData.Id_SlackUser !== null
        ? getDecryptedValue(userData.Id_SlackUser)
        : "";
    if (result.data && result.data.channels) {
      for (const item of result.data.channels) {
        let info = await this.conversationHistory(item.id);
        console.log("conversationHistory Info  :  ", info);
        if (
          info.data &&
          info.data.unread_count_display &&
          info.data.unread_count_display > 0 &&
          info.data.messages[0] &&
          userSlackId !== info.data.messages[0].user
        ) {
          let usersInfo = await this.conversationsUsersList(
            info.data.messages[0].user
          );
          if (
            usersInfo.data.user.profile.real_name !== "Google Calendar" &&
            usersInfo.data.user.profile.real_name !== "Slackbot"
          ) {
            oneToOneChannelUnreads.push({
              ChannelId: item.id,
              count: info.data.unread_count_display
                ? info.data.unread_count_display
                : 0,
              userName: usersInfo.data.user.profile.real_name,
              last_read: info.data.messages[0].ts,
              slackId: info.data.messages[0].user,
            });
          }
        }

        this.setState({ oneToOneUnreadCount: oneToOneChannelUnreads });
      }
    }
  };

  conversationsUsersList = async (userId) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userSlackToken = getDecryptedValue(userData.Slack_Access_Token);

    try {
      let group = {
        token: userSlackToken,
        user: userId,
      };
      const result = await axios.post(
        `https://slack.com/api/users.info`,
        qs.stringify(group)
      );

      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  toggleOneToOneChat = async (team, slackId) => {
    await this.slackUserDetails(slackId);
    let slackUserDetail = this.props.slackUserDetailsResponse;
    if (
      !this.state.multipleChatWindow.find(
        (team) =>
          getDecryptedValue(team.Id_SlackUser) ===
          getDecryptedValue(slackUserDetail.Id_SlackUser)
      )
    ) {
      this.handleAddChat(this.props.slackUserDetailsResponse);
      await this.markAsReadOnChannelOpen(team.ChannelId);
    }
    let unreadOneToOneChat = this.state.oneToOneUnreadCount;
    let indexof = unreadOneToOneChat
      .map(function (x) {
        return x.ChannelId;
      })
      .indexOf(team.ChannelId);
    unreadOneToOneChat.splice(indexof, 1);
    this.setState({ oneToOneUnreadCount: unreadOneToOneChat });
  };

  //Get Slack User Details
  slackUserDetails = async (slackId) => {
    const { API_HOST, slackUserDetails } = this.props;
    let requestBody = {
      Id_Slack: getEncryptedValue(slackId),
    };

    await slackUserDetails(API_HOST, requestBody);
  };

  changeUnreadOneToOneCounts = async (channelId) => {
    let oneToOneChannelUnreads = this.state.oneToOneUnreadCount;
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userSlackId = getDecryptedValue(userData.Id_SlackUser);
    let info = await this.conversationHistory(channelId);
    let isSlackUserOpen =
      this.state.multipleChatWindow.length > 0 &&
      this.state.multipleChatWindow.find(
        (team) =>
          getDecryptedValue(team.Id_SlackUser) === info.data.messages[0].user
      )
        ? true
        : false;
    if (
      info.data &&
      info.data.unread_count_display &&
      info.data.unread_count_display > 0 &&
      info.data.messages[0] &&
      info.data.messages[0].user !== userSlackId &&
      !isSlackUserOpen
    ) {
      let usersInfo = await this.conversationsUsersList(
        info.data.messages[0].user
      );
      var UserIndex = oneToOneChannelUnreads.findIndex(
        (value) => value.ChannelId === channelId
      );
      if (
        oneToOneChannelUnreads.findIndex(
          (value) => value.ChannelId === channelId
        ) !== -1
      ) {
        oneToOneChannelUnreads.splice(UserIndex, 1);
      }
      oneToOneChannelUnreads.push({
        ChannelId: channelId,
        count: info.data.unread_count_display
          ? info.data.unread_count_display
          : 0,
        userName: usersInfo.data.user.profile.real_name,
        last_read: info.data.messages[0].ts,
        slackId: info.data.messages[0].user,
      });
    }
    this.setState({ oneToOneUnreadCount: oneToOneChannelUnreads });
  };

  closeProgressBar = async (val) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var userId = userData.Id_User;
    await this.getUserDetail(userId);

    this.setState({
      isProgressBar: val,
    });

    await localStorage.setItem(
      "userdetail",
      JSON.stringify(this.props.userDetailResponse)
    );
    await window.location.reload();
  };

  getChannelUsersCount = () => {
    const { API_HOST, getChannelUsersListCount } = this.props;
    getChannelUsersListCount(API_HOST, (res) => {
      this.setState({
        channelUserCount: res,
      });
    });
  };

  highlight2 = () => {
    $("#highlight1").hide();
    $("#highlight2").show();
    $(".dashboard-wrapper").addClass("toggle");
    $(".left-sidebar .user-cabin .card").addClass("main-highlight");
  };

  highlight3 = () => {
    if (
      this.props.userListByDepartmentResponse &&
      JSON.parse(localStorage.getItem("userdetail")).Is_Admin &&
      this.props.userListByDepartmentResponse.length <= 2 &&
      JSON.parse(localStorage.getItem("userdetail")).Slack_Access_Token === ""
    ) {
      $("#highlight2").hide();
      $("#highlight3").show();
      $(".dashboard-wrapper").addClass("toggle");
      $(".left-sidebar .user-cabin .card").removeClass("main-highlight");
      $("#highlight3 + a").addClass("main-highlight");
    } else {
      $("#highlight2").hide();
      $("#highlight4").show();
      $(".dashboard-wrapper").removeClass("toggle");
      $("#highlight2 + a").removeClass("main-highlight");
      $("#highlight4 + div").addClass("cabin-highlight");
    }
  };

  highlightImport = () => {
    if (this.state.channelUserCount <= 0) {
      if (
        this.props.fetchOfficeResponse &&
        this.props.fetchOfficeResponse.length > 0
      ) {
        //if office is already created
        this.importUserList();
      } else {
        toast.info("Please, Create Office.");
        this.props.history.push({ pathname: "/office" });
      }
    }
  };

  highlightDone = () => {
    $("#import_user").removeClass("main-highlight");
    $("#highlightImport").hide();
    $(".trans-bg").hide();
  };

  highlight4 = () => {
    $("#highlight3").hide();
    $("#highlight4").show();
    $(".dashboard-wrapper").removeClass("toggle");
    $("#highlight3 + a").removeClass("main-highlight");
    $("#highlight4 + div").addClass("cabin-highlight");
  };

  skipTour = () => {
    $("#highlight1").hide();
    $("#highlight2").hide();
    $("#highlight3").hide();
    $("#highlight4").hide();
  };

  closeChannelChatWindows = () => {
    //console.log(this.state.teamId + "=0")
    if (this.state.channelsUnreadCounts.length > 0) {
      this.markAsReadOnChannelOpen(this.state.team.OtherContent);
    }
    this.setState({ teamId: 0, team: {} });
  };

  checkAlreadyExistChannel = () => {
    const { API_HOST, checkChannelAlreadyExist } = this.props;
    let selectedUsers = this.state.selectedUsersList;
    //selectedUsers.push(getDecryptedValue(JSON.parse(localStorage.getItem('userdetail')).Id_SlackUser));
    selectedUsers.push(
      JSON.parse(localStorage.getItem("userdetail")).Id_SlackUser
    );
    var uniqueSelectedUsers = selectedUsers.filter(
      this.onlyUniqueSelectedUsers
    );
    checkChannelAlreadyExist(API_HOST, uniqueSelectedUsers, (res) => {
      var index2 = selectedUsers.indexOf(
        JSON.parse(localStorage.getItem("userdetail")).Id_SlackUser
      );
      //list.splice(index2, 1);
      selectedUsers.splice(index2, 1);
      if (res === 0) {
        this.createVirtualChatGroup();
      }
      if (res > 0) {
        this.openAlreadyExistChannel(res);
      }
    });
  };
  onlyUniqueSelectedUsers = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  openAlreadyExistChannel = async (teamId) => {
    var team = this.state.slackTeamResponse.find((team) => team.Id === teamId);
    this.setState({
      leftSideExpand: true,
    });
    this.toggleChange(team.Id, team);
  };

  doorOpenfunc = async (id) => {
    const { API_HOST, doorOpen } = this.props;
    let userDetails = JSON.parse(localStorage.getItem("userdetail"));
    userDetails["Is_DoorOpen"] = true;
    userDetails["UserStatus"] = "Available";
    this.userValuesChanges(userDetails);
    doorOpen(API_HOST, id, (res) => {
      //if (res) {
      // let userDetails = JSON.parse(localStorage.getItem('userdetail'));
      // userDetails["Is_DoorOpen"] = true;
      // userDetails["UserStatus"] = "Available";
      // this.userValuesChanges(userDetails);
      //$('.door-action').removeClass("d-closed");
      //}
    });
    await this.setUserCustomStatus("Available");
  };

  setUserCustomStatus = async (value) => {
    const { API_HOST, setUserCustomStatus } = this.props;
    let requestBody = {
      UserStatus: value,
    };
    await setUserCustomStatus(API_HOST, requestBody, (res) => {});
  };

  togglePersonalChat = () => {
    this.setState({ isPersonalChatOpen: !this.state.isPersonalChatOpen });
  };

  //Get user leave detail for broadcast
  getUserLeaveDetail = async () => {
    const { API_HOST, getUserLeaveDetail } = this.props;

    await getUserLeaveDetail(API_HOST, (res) => {
      if (res) {
        this.state.userIdArray.push(res.Id_User);

        //call get user name function
        this.getUserNameByUserId(this.state.userIdArray, res);
      }
    });
  };

  //Get UserName by UserId
  getUserNameByUserId = async (id, userDetailData) => {
    const { API_HOST, getUserNameByUserId } = this.props;
    await getUserNameByUserId(API_HOST, id, (res) => {
      if (res) {
        let broadcastData = {
          userLeaveDetail: userDetailData,
          userNameByUserId: res[0].Real_Name,
        };
        socket.emit("backFromLeave", broadcastData);
      }
    });
  };

  backFromLeave = (data) => {
    const moment = require("moment-timezone");
    const timezone = JSON.parse(localStorage.getItem("userdetail")).TZ
      ? JSON.parse(localStorage.getItem("userdetail")).TZ
      : "Asia/Kolkata";

    let Start_Date = moment
      .utc(data.userLeaveDetail.Start_Date)
      .tz(timezone)
      .format("DD-MM-YYYY");
    let End_Date = moment
      .utc(data.userLeaveDetail.End_Date)
      .tz(timezone)
      .format("DD-MM-YYYY");

    toast.info(
      data.userNameByUserId +
        " is back from leave/vacation from " +
        Start_Date +
        " to " +
        End_Date
    );
  };

  toggleGoogleConfig = () => {
    this.setState({ isGoogleConfig: false });
  };

  toggleJIRAConfig = () => {
    this.setState({ isJiraConfig: false });
  };

  toggleZoomConfig = () => {
    this.setState({ isZoomConfig: false });
  };

  startImport = () => {
    this.setState({ startImportUsers: true });
  };

  importSlackStepsTrace = async () => {
    const { API_HOST, importSlackStepsTrace } = this.props;

    await importSlackStepsTrace(API_HOST, (res) => {
      this.setState({ importSlackStep: res });
      console.log("SlackStep: ", this.state.importSlackStep);
      if (
        this.state.importSlackStep !== "null" &&
        this.state.importSlackStep < 3
      ) {
        // this.importUserList();
      }

      //
      if (
        this.state.importSlackStep !== "null" &&
        this.state.importSlackStep === 3 &&
        getDecryptedValue(
          JSON.parse(localStorage.getItem("userdetail")).Id_SlackUser
        ) === null
      ) {
        this.changeWorkspace(
          JSON.parse(localStorage.getItem("userdetail")).Id_Tenant
        );
      }
    });
  };

  redirectLogin = () => {
    this.setState({ isRedirectToLogin: true });
  };

  checkToken = () => {
    // if(localStorage.getItem("Token") && localStorage.getItem("Token") !== "" && localStorage.getItem("SlackUserId") && localStorage.getItem("SlackUserId") !== ""){
    //   // start progress
    //   let token = getDecryptedValue(localStorage.getItem("Token"));
    //   this.updateTenantSlackAccessToken(token);
    // //   this.importUserList();
    // }
    // else{
    let scope = this.state.slackConfigListResponse.Scope_Detail;
    let client_id = getDecryptedValue(
      this.state.slackConfigListResponse.Client_ID
    );

    window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${client_id}&${scope}&redirect_uri=${window.location.protocol}//${window.location.host}/importtoken`;
    // }
  };

  updateTenantSlackAccessToken = async (
    userSlackAccesToken,
    userSlackRefreshToken
  ) => {
    const { API_HOST, updateTenantSlackAccessToken } = this.props;

    await updateTenantSlackAccessToken(
      API_HOST,
      userSlackAccesToken,
      userSlackRefreshToken,
      "",
      "",
      (res) => {
        if (res) {
          this.importUserList();
        }
      }
    );
  };

  toggleMoreInfo = () => {
    this.setState({
      isMoreInfo: !this.state.isMoreInfo,
      isAddToSlack: false,
      isEditWorkingHours: false,
      isGoogleCalendarMeeting: false,
      isConnectToJira: false,
    });
  };

  showAllChannels = async () => {
    await this.setState({ showChannels: !this.state.showChannels });
    await this.fetchSlackTeam(this.state.showChannels);
  };

  //edit Office name on right side bar
  editOfficeName = (officeId, OfficeName) => {
    const { API_HOST, editOfficeName } = this.props;
    const editOfficeNameRequest = {
      Id: officeId,
      Name: OfficeName,
    };

    editOfficeName(API_HOST, editOfficeNameRequest, async (res) => {
      if (res !== null) {
        var userId = JSON.parse(localStorage.getItem("userdetail")).Id_User;
        await this.getUserDetail(userId);
        socket.emit("editOfficeName", this.props.userDetailResponse);
      }
    });
  };

  //edit office name socket function
  edit_OfficeName = (data) => {
    // let newData = this.state.fetchOfficeResponse.map(item => {
    //   if (item.Id === data.Id) {
    //     item.Name = data.Name;
    //   }
    //   return item;
    // });
    // this.setState(Object.assign({}, { fetchOfficeResponse: newData }));
  };

  //create chat with department members
  createDepartmentChat = async (deptId) => {
    const { API_HOST, createDepartmentChat } = this.props;

    let userDetails = JSON.parse(localStorage.getItem("userdetail"));

    if (userDetails.Slack_Access_Token === "") {
      this.configureSlack();
      toast.info(
        "Please configure your Slack account to create a new chat group."
      );
    } else {
      await createDepartmentChat(API_HOST, deptId, (res) => {
        if (res) {
          this.fetchSlackTeamUpdate(deptId);
        }
      });
    }
  };

  //add new member in department
  addnewMember = (deptId) => {
    this.setState({
      manageDepartment: true,
      isAddnewMember: true,
      addnewMemberDeptId: deptId,
    });
  };

  fetchSlackTeamUpdate = async (deptId) => {
    const { API_HOST, fetchSlackTeam } = this.props;
    await fetchSlackTeam(API_HOST, false, (res) => {
      if (res) {
        this.setState({ slackTeamResponse: res });
        let deptContent = this.state.slackTeamResponse.find(
          (slackTeam) => slackTeam.Id_department === deptId
        );

        this.toggleChange(deptContent.Id, deptContent);
      }
    });
  };

  onClickSyncWorkspace = async (val) => {
    this.setState({
      isSyncProgressBar: val,
    });
  };

  toggleJira = () => {
    this.setState({ isJira: false });
  };

  onCloseSyncConfirmation = () => {
    this.setState({ isSyncConfirmation: false });
    toast.info(
      "After your current task is over please logout and login once again."
    );
  };

  syncProcess = () => {
    // if(this.state.isYes) {
    if (this.state.dataSet == this.state.selectedWorkspaceName) {
      toast.info("Your workspace is syncing with slack");
      window.location.reload();
    }
    // }
  };

  render() {
    let match = this.props.match;
    var moment = require("moment-timezone");
    if (localStorage.getItem("userdetail")) {
      var userData = localStorage.getItem("userdetail");
      //var userDataTenant = JSON.parse(userData).Id_Tenant;
    }

    const {
      memberListResponse,
      userDetailResponse,
      ///  fetchOfficeResponse,
      //slackConfigListResponse,
      ////////////////   multipleEmailResponse
      //userListDepartmentFilterResponse
    } = this.props;

    const manageDeptTip =
      "Create workgroups/departments and <br /> assign users to their respective <br /> groups by clicking this button.";
    const syncSlackTip =
      "Click this tab to sync any<br/> updates back from Slack<br/> workspace to your current<br/> workspace in Precis.";
    const editOfficeTip =
      "Click this tab to Edit office related<br /> information Timezones and working hours.";
    const editOfficeNameTip =
      "Edit your Workgroup/Office <br /> name by clicking on it. <br /> Use 'Enter' to save and 'Esc' to cancel";
    const userSettingsTip =
      "This is your user settings.<br />Double click to customize your avatar.";
    const addToSlackInfo =
      "Now you can click Add to Slack button to get seamless interactive <br/> communications with your workgroups. Please ensure that your<br/> email " +
      JSON.parse(userData).EmailId +
      " in workspace " +
      JSON.parse(userData).Slack_WorkSpace_Name +
      " is <br/> registered with slack. If not registered then register with slack<br/> and then import user using 'add to Slack button'.";

    const {
      fetchOfficeResponse,
      multipleEmailResponse,
      slackConfigListResponse,
      selectedWorkspace,
      assignDepartment,
      onTenantLog,
      feedbackLog,
      contactUsLog,
      channelUserCount,
      isProgressBar,
      meetingActivityLog,
      editTenantIsOpen,
      loggedInUserDetails,
      myProfileIsOpen,
      viewProfileIsOpen,
      teamId,
      Is_Admin,
      searchText,
      departmentId,
      SearchbarUserId,
      isSlackEdit,
      slackTeamResponse,
    } = this.state;
    const user_TZ =
      loggedInUserDetails.TZ === "" || loggedInUserDetails.TZ === null
        ? "Asia/Kolkata"
        : loggedInUserDetails.TZ;
    let scope = slackConfigListResponse.Scope_Detail;
    // let teamSize = 0;

    ///////  console.log("departmentListFilterWiseResponse",this.props.departmentListFilterWiseResponse);

    return (
      <div className="wrapper">
        <div
          className={`dashboard-wrapper ${
            this.state.leftSideExpand ? `toggle` : ``
          }`}
        >
          <div className="left-sidebar">
            <div class="user-card-list user-cabin">
              <div
                className="highlight-modal"
                id="highlight2"
                style={{ zIndex: "1000" }}
              >
                <div className="h-body">
                  <div className="h-content">
                    <p>This is your user settings.. </p>
                    <p>Double click to customize your avatar..</p>
                  </div>
                  <div className="h-actions">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={this.updateUserTour}
                    >
                      Skip
                    </span>
                    <button
                      className="btn btn-primary"
                      id="nexth1"
                      onClick={this.highlight3}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
              <ReactTooltip
                id="userSettings"
                place="left"
                type="dark"
                effect="solid"
                multiline={true}
                backgroundColor="#2c69d1"
                textColor="white"
                border="true"
              />
              <div
                data-for="userSettings"
                data-tip={userSettingsTip}
                class={`card ${
                  loggedInUserDetails.Is_DoorOpen ? " card-door-open" : ""
                }  ${loggedInUserDetails.Is_Admin ? " card-admin" : ""}`}
              >
                <span
                  className="status"
                  onClick={() => {
                    this.setState({ isCustomStatus: true });
                  }}
                >
                  {loggedInUserDetails.UserStartTime_EndTimeVM
                    ? loggedInUserDetails.UserStartTime_EndTimeVM
                        .Availibility_Status
                    : ""}
                  {loggedInUserDetails.UserStatus &&
                  !(
                    (loggedInUserDetails.Is_DoorOpen === true &&
                      loggedInUserDetails.UserStatus.toLowerCase().trim() ===
                        "available") ||
                    (loggedInUserDetails.Is_DoorOpen === false &&
                      loggedInUserDetails.UserStatus.toLowerCase().trim() ===
                        "busy")
                  ) ? (
                    <span className="meeting-with">
                      {loggedInUserDetails.UserStatus}
                    </span>
                  ) : null}
                </span>
                <span
                  class="ot-time"
                  onClick={this.editUserWorkingHours}
                  style={{ cursor: "pointer" }}
                >
                  <span>Usually</span>{" "}
                  {loggedInUserDetails.UserStartTime_EndTimeVM &&
                  loggedInUserDetails.UserStartTime_EndTimeVM
                    .Availibility_Time !== ""
                    ? moment(
                        loggedInUserDetails.UserStartTime_EndTimeVM.Availibility_Time.substr(
                          0,
                          5
                        ),
                        "HH:mm"
                      )
                        .tz(loggedInUserDetails.TZ)
                        .format("HH:mm") +
                      " - " +
                      moment(
                        loggedInUserDetails.UserStartTime_EndTimeVM.Availibility_Time.substr(
                          loggedInUserDetails.UserStartTime_EndTimeVM
                            .Availibility_Time.length - 5
                        ),
                        "HH:mm"
                      )
                        .tz(loggedInUserDetails.TZ)
                        .format("HH:mm")
                    : ""}
                </span>
                {loggedInUserDetails.UserStartTime_EndTimeVM ? (
                  <span
                    class={`time-zone ${
                      moment(this.state.date)
                        .format("HH:mm")
                        .replace(":", "") <=
                        loggedInUserDetails.UserStartTime_EndTimeVM.Availibility_Time.substr(
                          loggedInUserDetails.UserStartTime_EndTimeVM
                            .Availibility_Time.length - 5
                        ).replace(":", "") &&
                      moment(this.state.date)
                        .format("HH:mm")
                        .replace(":", "") >=
                        loggedInUserDetails.UserStartTime_EndTimeVM.Availibility_Time.substr(
                          0,
                          5
                        ).replace(":", "")
                        ? "day-time"
                        : "night-time"
                    }`}
                  >
                    {" "}
                    <Moment tz={user_TZ} format="HH:mm">
                      {this.state.date}
                    </Moment>{" "}
                    <img src={watchIcon} alt="watch" />
                  </span>
                ) : null}
                <div
                  class="card-img"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.toggleMyProfile();
                  }}
                >
                  <img src={pcIcon} class="img-fluid user-desk-img" alt="PC" />
                  <AvatarImage profilePic={this.state.myAvatar} className="" />
                </div>
                <strong
                  class="name-time"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.toggleMyProfile();
                  }}
                >
                  {JSON.parse(userData).Real_Name}
                </strong>
              </div>
            </div>

            <div className="user-list-panel" id="user_list">
              {/* {Is_Admin === true ? ( */}
              <>
                <ul className="list-unstyled mb-0">
                  <li className="office-list">
                    <ReactTooltip
                      id="collapse"
                      place="top"
                      type="dark"
                      effect="solid"
                      multiline={true}
                      backgroundColor="#2c69d1"
                      textColor="white"
                      border="true"
                    />
                    <a
                      class="btn btn-primary"
                      data-toggle="collapse"
                      href={"#confg-btns"}
                      role="button"
                      onClick={this.toggleConfiguration}
                      aria-expanded={this.state.isConfigurationOpen}
                      aria-controls="multiCollapseGroup"
                      data-for="collapse"
                      data-tip="Manage the workspace /workgroup level configurations settings"
                    >
                      <i class="fa fa-user-cog"></i>
                      <span>Configuration</span>
                    </a>
                    <div
                      className={`multi-collapse collapse ${
                        this.state.isConfigurationOpen && "show"
                      }`}
                      id={"confg-btns"}
                    >
                      <div className="button-style">
                        {/* {(slackConfigListResponse[0] === null || slackConfigListResponse.length < 1) && <button type="submit" className="button default" onClick={this.onClickSlackEdit}>Slack Configuration</button>} */}
                        <div className="highlight-modal" id="highlight3">
                          <div className="h-body">
                            <div className="h-content">
                              <p>
                                {" "}
                                <strong>Slack Configuration</strong>{" "}
                              </p>
                              <p>
                                Click on 'Add to Slack' for Slack Configuration
                              </p>
                              <p>
                                and Precis will make your Slack user experience
                                funfilled and easy.
                              </p>
                            </div>
                            <div className="h-actions">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={this.updateUserTour}
                              >
                                Skip
                              </span>
                              <button
                                className="btn btn-primary"
                                id="nexth1"
                                onClick={this.highlight4}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>

                        {this.state.importSlackStep < 3 && (
                          // <a target="_self" className="button default slack-btn" onClick={this.checkToken} disabled="disabled">
                          //     <img alt="Add to Slack" src="images/add-to-slack.png" />
                          // </a>

                          <>
                            <ReactTooltip
                              id="addToSlackInfo"
                              place="right"
                              type="dark"
                              effect="solid"
                              effect="solid"
                              multiline={true}
                              backgroundColor="#2c69d1"
                              textColor="white"
                              border="true"
                            />
                            <button
                              data-for="addToSlackInfo"
                              data-tip={addToSlackInfo}
                              type="submit"
                              // className="button default slack-btn"
                              className="button default"
                              onClick={this.checkToken}
                              disabled={
                                JSON.parse(localStorage.getItem("userdetail"))
                                  .Is_UserTour_Completed === false
                                  ? "disabled"
                                  : ""
                              }
                            >
                              {/* <img
                              alt="Add to Slack"
                              src="images/add-to-slack.png"
                            /> */}
                              Import Users
                            </button>
                          </>
                        )}

                        {this.state.importSlackStep >= 3 &&
                          JSON.parse(localStorage.getItem("userdetail"))
                            .Slack_Access_Token !== "" && (
                            <>
                              <button
                                data-for="syncSlack"
                                data-tip={syncSlackTip}
                                type="submit"
                                className="button default"
                                onClick={this.onClickSyncWorkspace}
                              >
                                Sync Workspace
                              </button>
                              <ReactTooltip
                                id="syncSlack"
                                place="right"
                                type="dark"
                                effect="solid"
                                multiline={true}
                                backgroundColor="#2c69d1"
                                textColor="white"
                                border="true"
                              />
                            </>
                          )}

                        {/* {channelUserCount <= 0 && (slackConfigListResponse[0] !== null || slackConfigListResponse.length < 1) && <button id="import_user" type="submit" className="button default main-highlight" onClick={this.importUserList}>Import Users from Slack</button>} */}
                        {/* {fetchOfficeResponse && fetchOfficeResponse.length > 0 ? ( */}
                        <>
                          {" "}
                          <ReactTooltip
                            id="editOfficeDetails"
                            place="right"
                            type="dark"
                            effect="solid"
                            multiline={true}
                            backgroundColor="#2c69d1"
                            textColor="white"
                            border="true"
                          />
                          <button
                            data-for="editOfficeDetails"
                            data-tip={editOfficeTip}
                            type="submit"
                            className="button default"
                            onClick={this.onClickOffice}
                          >
                            Edit Office
                          </button>
                        </>
                        {/* ) : (
                            <button
                              type="submit"
                              className="button default"
                              onClick={this.onClickCreateOffice}
                            >
                              Create Office
                            </button>
                          )} */}
                        {this.props.userListByDepartmentResponse &&
                        this.props.userListByDepartmentResponse.length > 2 ? (
                          <>
                            <button
                              data-for="manageDept"
                              data-tip={manageDeptTip}
                              type="submit"
                              className="button default"
                              onClick={this.onClickManageDepartment}
                            >
                              {" "}
                              Department
                            </button>
                            <ReactTooltip
                              id="manageDept"
                              place="top"
                              type="dark"
                              effect="solid"
                              multiline={true}
                              backgroundColor="#2c69d1"
                              textColor="white"
                              border="true"
                            />
                          </>
                        ) : (
                          ""
                        )}

                        {Is_Admin === true ? (
                          <button
                            // data-for="manageDept"
                            // data-tip={manageDeptTip}
                            type="submit"
                            className="button default"
                            onClick={this.onClickInviteNewUserToSlack}
                          >
                            Invite new user
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </>
              {/*  ) : ""} */}

              <ul className="list-unstyled mb-0">
                <li className="office-list">
                  <ReactTooltip
                    id="waterCooler"
                    place="top"
                    type="dark"
                    effect="solid"
                    multiline={true}
                    backgroundColor="#2c69d1"
                    textColor="white"
                    border="true"
                  />
                  <a
                    className="btn btn-primary"
                    data-toggle="collapse"
                    href={"#watercooler"}
                    role="button"
                    onClick={() => this.toggleWaterCooler(0)}
                    aria-expanded={this.state.activeWaterCooler.includes(0)}
                    aria-controls="multiCollapseGroup"
                    data-for="waterCooler"
                    data-tip="Manage your most interactive groups"
                  >
                    {/* <img
                      src={waterCoolerIcon}
                      height="30px"
                      width="30px"
                      style={{ marginRight: "5px" }}
                      alt="water"
                    /> */}
                    <i class="fas fa-splotch"></i>
                    Water Cooler
                  </a>
                  <div
                    className={`multi-collapse collapse ${
                      this.state.activeWaterCooler.includes(0) && "show"
                    }`}
                    id={"watercooler"}
                  >
                    <ul className="list-unstyled user-list">
                      {slackTeamResponse &&
                        slackTeamResponse.map((team) => {
                          if (team && team.IsWaterCooler === true) {
                            return (
                              <li
                                key={team.Id}
                                id={"teamlistLi" + team.OtherContent}
                              >
                                <div className="msg-status">
                                  <NotificationBadge
                                    className="msg-notify"
                                    count={
                                      this.state.channelsUnreadCounts !== [] &&
                                      this.state.channelsUnreadCounts.find(
                                        (x) => x.ChannelId === team.OtherContent
                                      ) &&
                                      this.state.channelsUnreadCounts.find(
                                        (x) => x.ChannelId === team.OtherContent
                                      ).count
                                        ? this.state.channelsUnreadCounts.find(
                                            (x) =>
                                              x.ChannelId === team.OtherContent
                                          ).count
                                        : 0
                                    }
                                  />
                                  <div className="msg-time">
                                    {this.state.channelsUnreadCounts !== [] &&
                                    this.state.channelsUnreadCounts.find(
                                      (x) =>
                                        x.ChannelId === team.OtherContent &&
                                        x.last_read !== 0
                                    ) ? (
                                      moment
                                        .unix(
                                          this.state.channelsUnreadCounts.find(
                                            (x) =>
                                              x.ChannelId === team.OtherContent
                                          ).last_read
                                        )
                                        .format("HH:mm")
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <Link
                                  className="btn btn-primary alignEnd"
                                  id={"teamlist" + team.OtherContent}
                                  onClick={() =>
                                    this.toggleChange(team.Id, team)
                                  }
                                  role="button"
                                >
                                  {team.Name}
                                </Link>
                              </li>
                            );
                          }
                          return "";
                        })}
                    </ul>
                  </div>
                </li>
              </ul>

              <ul className="list-unstyled mb-0">
                {/* {fetchOfficeResponse && fetchOfficeResponse.map(office =>
                    office === null ? (
                      ""
                    ) : ( */}
                {
                  this.props.userDetailResponse && (
                    <React.Fragment>
                      <li className="office-list">
                        {JSON.parse(userData).Is_Admin === true ? (
                          <ReactTooltip
                            id="editOffice"
                            place="bottom"
                            type="dark"
                            effect="solid"
                            multiline={true}
                            backgroundColor="#2c69d1"
                            textColor="white"
                            border="true"
                          />
                        ) : (
                          ""
                        )}
                        <a
                          className="btn btn-primary"
                          data-toggle="collapse"
                          href={"#u-office2"}
                          role="button"
                          onClick={() => {
                            this.toggleOfficeChange(
                              this.props.userDetailResponse.Id_TenantOffice
                            );
                            this.fetchSlackTeam(this.state.showChannels);
                            this.findAllOneToOneChat();
                          }}
                          aria-expanded={this.state.activeOfficeAccordian.includes(
                            this.props.userDetailResponse.Id_TenantOffice
                          )}
                          aria-controls="multiCollapseExample2"
                          data-for="editOffice"
                          data-tip={editOfficeNameTip}
                          html={true}
                        >
                          <i class="fas fa-address-card"></i>
                          {JSON.parse(userData).Is_Admin === true ? (
                            <EdiText
                              hideIcons={true}
                              saveButtonClassName="custom-save-button"
                              editButtonClassName="custom-edit-button"
                              cancelButtonClassName="custom-cancel-button"
                              type="text"
                              //value={office.Name}
                              value={
                                this.props.userDetailResponse &&
                                this.props.userDetailResponse.TenantOffice_Name
                              }
                              onSave={(value) => {
                                this.editOfficeName(
                                  this.props.userDetailResponse.Id_TenantOffice,
                                  value
                                );
                              }}
                              className="edit-office-title"
                              submitOnEnter={(value) => {
                                this.editOfficeName(
                                  this.props.userDetailResponse.Id_TenantOffice,
                                  value
                                );
                              }}
                              editOnViewClick={true}
                              cancelOnEscape={true}
                              startEditingOnFocus={true}
                            />
                          ) : (
                            <>
                              <span>
                                {/* {office.Name */}
                                {this.props.userDetailResponse &&
                                  this.props.userDetailResponse
                                    .TenantOffice_Name}
                              </span>
                            </>
                          )}
                        </a>
                        <div
                          className={`multi-collapse collapse ${
                            this.state.activeOfficeAccordian.includes(
                              this.props.userDetailResponse.Id_TenantOffice
                            ) && "show"
                          }`}
                          id={"u-office2"}
                        >
                          <ul className="list-unstyled user-list">
                            {slackTeamResponse &&
                              slackTeamResponse.map((team) => {
                                if (team && team.IsWaterCooler !== true) {
                                  //teamSize = teamSize + 1;
                                  return (
                                    <li
                                      key={team.Id}
                                      id={"teamlistLi" + team.OtherContent}
                                    >
                                      <div className="msg-status">
                                        <NotificationBadge
                                          className="msg-notify"
                                          count={
                                            this.state.channelsUnreadCounts !==
                                              [] &&
                                            this.state.channelsUnreadCounts.find(
                                              (x) =>
                                                x.ChannelId ===
                                                team.OtherContent
                                            ) &&
                                            this.state.channelsUnreadCounts.find(
                                              (x) =>
                                                x.ChannelId ===
                                                team.OtherContent
                                            ).count
                                              ? this.state.channelsUnreadCounts.find(
                                                  (x) =>
                                                    x.ChannelId ===
                                                    team.OtherContent
                                                ).count
                                              : 0
                                          }
                                        />
                                        <div className="msg-time">
                                          {this.state.channelsUnreadCounts !==
                                            [] &&
                                          this.state.channelsUnreadCounts.find(
                                            (x) =>
                                              x.ChannelId ===
                                                team.OtherContent &&
                                              x.last_read !== 0
                                          ) ? (
                                            moment
                                              .unix(
                                                this.state.channelsUnreadCounts.find(
                                                  (x) =>
                                                    x.ChannelId ===
                                                    team.OtherContent
                                                ).last_read
                                              )
                                              .format("HH:mm")
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                      <Link
                                        className="btn btn-primary alignEnd"
                                        onClick={() => {
                                          this.toggleChange(team.Id, team);
                                          this.addWaterCooler(team);
                                        }}
                                        role="button"
                                        id={"teamlist" + team.OtherContent}
                                      >
                                        {team.Name}
                                      </Link>
                                    </li>
                                  );
                                }
                                return "";
                              })}
                            {/* {teamSize >= 3 ? ( */}
                            <li
                              className="more-channels-btn"
                              style={{ cursor: "pointer" }}
                              onClick={this.showAllChannels}
                            >
                              {this.state.showChannels === true ? (
                                <div>
                                  {" "}
                                  <i class="fas fa-plus"></i>More channels
                                </div>
                              ) : (
                                <div>
                                  <i class="fas fa-minus"></i>Less channels
                                </div>
                              )}
                            </li>
                            {/* ) : ""} */}
                            {/* <li
                                  className="more-channels-btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={this.showAllChannels}
                                >
                                  {this.state.showChannels === true ? (
                                    <div>
                                      {" "}
                                      <i class="fas fa-plus"></i>More channels
                                    </div>
                                  ) : (
                                      <div>
                                        <i class="fas fa-minus"></i>Less channels
                                      </div>
                                    )}
                                </li> */}
                          </ul>
                        </div>
                      </li>
                    </React.Fragment>
                  )
                  //     {/* )
                  //  )} */}
                }
              </ul>

              {this.state.oneToOneUnreadCount &&
              this.state.oneToOneUnreadCount[0] ? (
                <ul className="list-unstyled mb-0">
                  <li className="office-list">
                    <a
                      className="btn btn-primary"
                      data-toggle="collapse"
                      href={"#PersonalChat-btns"}
                      role="button"
                      onClick={this.togglePersonalChat}
                      aria-expanded={this.state.isPersonalChatOpen}
                      aria-controls="multiCollapseGroup"
                    >
                      <i class="fas fa-comment"></i>
                      <span>Personal Chat</span>
                    </a>
                    <div
                      className={`multi-collapse collapse ${
                        this.state.isPersonalChatOpen && "show"
                      }`}
                      id={"PersonalChat-btns"}
                    >
                      <ul className="list-unstyled user-list">
                        {this.state.oneToOneUnreadCount &&
                          this.state.oneToOneUnreadCount
                            .sort((a, b) => b.last_read - a.last_read)
                            .map((team) => {
                              return (
                                <li key={team.Id}>
                                  <div className="msg-status">
                                    <NotificationBadge
                                      className="msg-notify"
                                      count={team.count}
                                    />
                                    {team.last_read && team.last_read !== 0 && (
                                      <div className="msg-time">
                                        {team.last_read ? (
                                          moment
                                            .unix(team.last_read)
                                            .format("HH:mm")
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    )}
                                  </div>

                                  <Link
                                    className="btn btn-primary alignEnd"
                                    id={"teamlist" + team.ChannelId}
                                    onClick={() =>
                                      this.toggleOneToOneChat(
                                        team,
                                        team.slackId
                                      )
                                    }
                                    role="button"
                                  >
                                    {team.userName}
                                  </Link>
                                </li>
                              );
                            })}
                      </ul>
                    </div>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
          <div className="content">
            <Link
              data-for="expandSidebar"
              data-tip="Expand the sidebar menu panel and get access to your Slack channels."
              onClick={this.leftSideExpand}
              className="expanded-link fas fa-angle-right"
            ></Link>

            <div className="content-head">
              <ReactTooltip
                id="expandSidebar"
                place="top"
                type="dark"
                effect="solid"
                multiline={true}
                backgroundColor="#2c69d1"
                textColor="white"
                border="true"
              />

              <div className="workspace-title">
                <div>
                  {" "}
                  Workspace :
                  {/* <span>{this.state.selectedWorkspaceName}</span>  */}
                </div>
                <div>
                  {/* Workspace dropdown */}
                  <ReactTooltip
                    id="workspace"
                    place="top"
                    type="dark"
                    effect="solid"
                    multiline={true}
                    backgroundColor="#2c69d1"
                    textColor="white"
                    border="true"
                  />
                  <FormControl
                    variant="outlined"
                    className="formControl switch_control"
                    data-for="workspace"
                    data-tip="Login to multiple workspaces, and jump view the workgroups simultaneously."
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Workspace
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedWorkspace}
                      name={this.state.selectedWorkspaceName}
                      onChange={this.handleChangeWorkspace}
                      label="Workspace"
                    >
                      <MenuItem value="null" disabled>
                        <em>Select Workspace</em>
                      </MenuItem>

                      {multipleEmailResponse.map((mul_email) => {
                        return (
                          <MenuItem key={mul_email.Id} value={mul_email.Id}>
                            {mul_email.Name}
                          </MenuItem>
                        );
                      })}

                      <MenuItem value="0" key="0">
                        <em>Sign in to another Workspace</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <SearchBar
                placeHolderText={"Search..."}
                //data={this.props.searchUserResponse}
                dept_data={this.props.departmentListByUserResponse}
                user_data={this.props.userListByDepartmentResponse}
                searchText={searchText}
                searchResponse={this.searchResponse}
                //searchUser={this.state.searchUser}
                userListByDepartment={this.userListByDepartment}
                departmentListByUser={this.departmentListByUser}
                isMainOffice={this.state.isMainOffice}
                //getUserPresence={this.getUserPresence}
                onChangeDepartment={this.onChangeDepartment}
                getDepartmentId={this.getDepartmentId}
                getSearcbarUser={this.getSearcbarUser}
                timeFilter={this.state.timeFilter}
                isUserSelected={this.state.isUserSelected}
                onTimeSelected={this.onTimeSelected}
                toggleMoreInfo={this.toggleMoreInfo}
                isMoreInfo={this.state.isMoreInfo}
                userValuesChanges={this.userValuesChanges}
                userDetails={this.state.userDetails}
              />
            </div>

            <div className={this.state.isMinimizeChat ? "oneChatwindow" : ""}>
              {/* {this.state.team !== null && teamId !== 0 && <ChatWindow teamId={this.state.teamId} */}
              {teamId !== 0 && (
                <ChatWindow
                  teamId={this.state.teamId}
                  slackTeamResponse={slackTeamResponse}
                  memberListResponse={memberListResponse}
                  teamDetails={this.state.team}
                  minimizeChatWindow={this.minimizeChatWindow}
                  maximizeChatWindow={this.maximizeChatWindow}
                  handleAddChat={this.handleAddChat}
                  virtualId={this.state.virtualChannelId}
                  closeChannelChatWindows={this.closeChannelChatWindows}
                  changeUnreadChannelCounts={this.changeUnreadChannelCounts}
                  markAsReadOnChannelOpen={this.markAsReadOnChannelOpen}
                  fetchSlackTeam={this.fetchSlackTeam}
                />
              )}

              <div
                className={
                  !this.state.isMinimizeChat ? "oneChatwindow" : "d-flex"
                }
              >
                {/* {this.state.team !== null && this.state.minimizeChatWindow && teamId !== 0 ? */}
                {this.state.minimizeChatWindow && teamId !== 0 ? (
                  <ChatWindow
                    teamId={this.state.teamId}
                    slackTeamResponse={slackTeamResponse}
                    memberListResponse={memberListResponse}
                    teamDetails={this.state.team}
                    minimizeChatWindow={this.minimizeChatWindow}
                    maximizeChatWindow={this.maximizeChatWindow}
                    handleAddChat={this.handleAddChat}
                    virtualId={this.state.virtualChannelId}
                    closeChannelChatWindows={this.closeChannelChatWindows}
                    changeUnreadChannelCounts={this.changeUnreadChannelCounts}
                    markAsReadOnChannelOpen={this.markAsReadOnChannelOpen}
                  />
                ) : (
                  ""
                )}

                {this.state.multipleChatWindow.length > 0 ? (
                  <>
                    {this.state.multipleChatWindow.map((user) => (
                      <OneToOneChat
                        closeChatWindows={this.closeChatWindows}
                        userDetails={user}
                        markAsReadOnChannelOpen={this.markAsReadOnChannelOpen}
                      />
                    ))}
                  </>
                ) : null}
              </div>
            </div>

            <Switch>
              <Route
                exact
                path={match.path}
                render={() => (
                  <OfficeLayout
                    departmentId={departmentId}
                    SearchbarUserId={SearchbarUserId}
                    getSearcbarUser={this.getSearcbarUser}
                    timeFilter={this.state.timeFilter}
                    leftSideExpand={this.state.leftSideExpand}
                    handleAddChat={this.handleAddChat}
                    userValuesChanges={this.userValuesChanges}
                    getSelectedUsers={this.getSelectedUsers}
                    removeSelectedUsers={this.removeSelectedUsers}
                    clearSelectedUserGroupName={this.clearSelectedUserGroupName}
                    getUserListDepartmentUpdateOfficeTime={
                      this.getUserListDepartmentUpdateOfficeTime
                    }
                    onClickUsersSelectedChat={this.onClickUsersSelectedChat}
                    updateUserTour={this.updateUserTour}
                    fetchSlackTeam={this.fetchSlackTeam}
                    slackTeamResponse={this.state.slackTeamResponse}
                    configureSlack={this.configureSlack}
                    createDepartmentChat={this.createDepartmentChat}
                    addnewMember={this.addnewMember}
                    userValuesChanges={this.userValuesChanges}
                  />
                )}
              ></Route>
            </Switch>

            {JSON.parse(localStorage.getItem("userdetail")) && (
              <TimeCalendar
                onTimeSelected={this.onTimeSelected}
                officeResponse={fetchOfficeResponse}
                officeId={this.state.officeId}
                handleDateChange={this.handleDateChange}
                datePicked={this.state.datePicked}
                timeFilter={this.state.timeFilter}
                setGoogleCalendarMeeting={this.setGoogleCalendarMeeting}
                // showLoader={this.showLoader}
              />
            )}
          </div>
          <Rightsidebar
            showChatIcon={this.state.showChatIcon}
            viewPort={this.viewPort}
            getReceivedStickyNotes={this.getReceivedStickyNotes}
            getUserStickyListResponse={this.props.getUserStickyListResponse}
            setReadUserSticky={this.setReadUserSticky}
            handleDragSticky={this.handleDragSticky}
            connectToJira={this.connectToJira}
            setStickyId={this.setStickyId}
            match={match}
            toggleSupportChat={this.toggleSupportChat}
            myAvatar={this.state.myAvatar}
            closeSupportChat={this.closeSupportChat}
            onClickUsersSelectedChat={this.onClickUsersSelectedChat}
            userValuesChanges={this.userValuesChanges}
            toggleCustomStatus={this.toggleCustomStatus}
            isDoorOpen={this.state.loggedInUserDetails.Is_DoorOpen}
            //added myprofile props to avoid error
            isOpen={myProfileIsOpen}
            isAddToSlack={this.state.isAddToSlack}
            isEditWorkingHours={this.state.isEditWorkingHours}
            isConnectToJira={this.state.isConnectToJira}
            toggleModal={this.toggleMyProfile}
            myAvatar={this.state.myAvatar}
            onselected={this.selectedAvatar}
            updateUser={this.updateUser}
            userDetails={this.state.userDetails}
            changePassword={this.changePassword}
            changePasswordResponse={this.props.changePasswordResponse}
            userValuesChanges={this.userValuesChanges}
            //updateUserTimezone={this.updateUserTimezone}
            fetchSearchUser={this.fetchSearchUser}
            isGoogleCalendarMeeting={this.state.isGoogleCalendarMeeting}
            // clientId={this.props.slackConfigListResponse[0].Client_ID}
            changeWorkspace={this.changeWorkspace}
            fetchMultipleEmail={this.fetchMultipleEmail}
            multipleEmailResponse={this.props.multipleEmailResponse}
            redirectLogin={this.redirectLogin}
            //chatOpen={this.chatOpen}
            isJira={this.state.isJira}
            toggleJira={this.toggleJira}
          />
        </div>

        {JSON.parse(localStorage.getItem("userdetail"))
          .Is_UserTour_Completed === false ? (
          // && this.props.userListByDepartmentResponse.length <= 2
          <div>
            <div className="trans-bg"></div>
            <div className="highlight-modal" id="highlight1">
              <div className="h-body">
                <div className="h-content">
                  <img src="images/logo.png" alt="" />
                  Welcome Onboard to your personal <b>Precis</b> Viewport…
                </div>
                <div className="h-actions">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={this.updateUserTour}
                  >
                    Skip
                  </span>
                  <button
                    className="btn btn-primary"
                    id="nexth2"
                    onClick={this.highlight2}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.onDeptSelected ? (
          <DepartmentDetail
            isDeptModalOpen={this.state.onDeptSelected}
            toggleCloseDept={this.toggleCloseDept}
          />
        ) : (
          ""
        )}

        {this.state.onOfficeSelected ? (
          <OfficeDetail
            isOfficeModalOpen={this.state.onOfficeSelected}
            toggleCloseOffice={this.toggleCloseOffice}
            fetchOfficeResponse={this.props.fetchOfficeResponse}
            fetchOffice={this.props.fetchOffice}
            getUserDetail={this.getUserDetail}
            userDetailResponse={this.props.userDetailResponse}
          />
        ) : (
          ""
        )}

        {this.state.manageDepartment ? (
          <ManageDepartment
            manageDepartment={this.state.manageDepartment}
            toggleManageDept={this.toggleManageDept}
            isAddnewMember={this.state.isAddnewMember}
            addnewMemberDeptId={this.state.addnewMemberDeptId}
          ></ManageDepartment>
        ) : (
          ""
        )}

        {this.state.inviteUserToSlack ? (
          <InviteUserToSlack
            inviteUserToSlack={this.state.inviteUserToSlack}
            toggleInviteToSlack={this.toggleInviteToSlack}
          />
        ) : (
          ""
        )}

        {onTenantLog === true && (
          <TenantLog
            onTenantLog={onTenantLog}
            toggleTenantLog={this.toggleTenantLog}
          ></TenantLog>
        )}
        {meetingActivityLog === true && (
          <MeetingActivityLog
            meetingActivityLog={meetingActivityLog}
            toggleMeetingActivityLog={this.toggleMeetingActivityLog}
          ></MeetingActivityLog>
        )}
        {feedbackLog === true && (
          <FeedbackLog
            feedbackLog={feedbackLog}
            toggleFeedbackLog={this.toggleFeedbackLog}
          ></FeedbackLog>
        )}
        {contactUsLog === true && (
          <ContactUsLog
            contactUsLog={contactUsLog}
            toggleContactUsLog={this.toggleContactUsLog}
          ></ContactUsLog>
        )}
        {assignDepartment === true && (
          <AssignDepartment
            assignDepartment={assignDepartment}
            toggleCloseAssignDepartment={this.toggleCloseAssignDepartment}
          ></AssignDepartment>
        )}

        {viewProfileIsOpen ? (
          <ViewProfile
            isOpen={viewProfileIsOpen}
            toggle={this.toggleViewProfile}
            userDetails={userDetailResponse}
            handleEditProfileChange={this.handleEditProfileChange}
            handleChangeTimezone={this.handleChangeTimezone}
          />
        ) : (
          ""
        )}
        {editTenantIsOpen ? (
          <EditTenant
            isOpen={editTenantIsOpen}
            toggle={this.toggleEditTenant}
            tenantDetails={this.state.tenantDetails}
            editDisabled={this.state.editDisabled}
            handleEditTenantChange={this.handleEditTenantChange}
            updateTenant={this.updateTenant}
          />
        ) : (
          ""
        )}
        {myProfileIsOpen ? (
          <MyProfile
            isOpen={myProfileIsOpen}
            isAddToSlack={this.state.isAddToSlack}
            isEditWorkingHours={this.state.isEditWorkingHours}
            isConnectToJira={this.state.isConnectToJira}
            toggleModal={this.toggleMyProfile}
            myAvatar={this.state.myAvatar}
            onselected={this.selectedAvatar}
            updateUser={this.updateUser}
            userDetails={this.state.userDetails}
            changePassword={this.changePassword}
            changePasswordResponse={this.props.changePasswordResponse}
            userValuesChanges={this.userValuesChanges}
            //updateUserTimezone={this.updateUserTimezone}
            fetchSearchUser={this.fetchSearchUser}
            isGoogleCalendarMeeting={this.state.isGoogleCalendarMeeting}
            // clientId={this.props.slackConfigListResponse[0].Client_ID}
            changeWorkspace={this.changeWorkspace}
            fetchMultipleEmail={this.fetchMultipleEmail}
            multipleEmailResponse={this.props.multipleEmailResponse}
            redirectLogin={this.redirectLogin}
          />
        ) : (
          ""
        )}

        {isSlackEdit ? (
          <SlackConfigModal
            isOpen={isSlackEdit}
            toggle={this.toggleSlackEdit}
            slackConfigListResponse={slackConfigListResponse}
            getSlackConfigurationlist={this.getSlackConfigurationlist}
            //  highlightImport={this.highlightImport}
          />
        ) : null}
        {this.state.isGoogleConfig ? (
          <GoogleConfiguration
            isOpen={this.state.isGoogleConfig}
            toggle={this.toggleGoogleConfig}
          />
        ) : null}
        {this.state.isJiraConfig ? (
          <JiraClientConfig
            isOpen={this.state.isJiraConfig}
            toggle={this.toggleJIRAConfig}
          />
        ) : null}
        {this.state.isZoomConfig ? (
          <ZoomConfiguration
            isOpen={this.state.isZoomConfig}
            toggle={this.toggleZoomConfig}
          />
        ) : null}
        {this.state.isSupportChat ? (
          <SupportChat
            isSupportChat={this.state.isSupportChat}
            toggleSupportChat={this.toggleSupportChat}
            closeSupportChat={this.closeSupportChat}
          />
        ) : null}

        {isProgressBar && (
          <ProgressBar
            getUserDetail={this.getUserDetail}
            importSlackStepsTrace={this.importSlackStepsTrace}
            importSlackStep={this.state.importSlackStep}
            isProgressBar={isProgressBar}
            slackTeamResponse={this.props.slackTeamResponse}
            channelUserCount={channelUserCount}
            closeProgressBar={this.closeProgressBar}
            fetchMultipleEmail={this.fetchMultipleEmail}
            multipleEmailResponse={this.state.multipleEmailResponse}
          ></ProgressBar>
        )}
        {this.state.isSyncProgressBar && (
          <SyncProgressBar
            closeSyncProgressBar={this.onClickSyncWorkspace}
            isSyncProgressBar={this.state.isSyncProgressBar}
            workspace={this.state.selectedWorkspaceName}
          ></SyncProgressBar>
        )}
        <UnreadSentStickyList
          isUnreadSentStickyModal={this.state.isUnreadSentStickyModal}
          toggleUnreadSentSticky={this.toggleUnreadSentSticky}
        />

        {this.state.notificationIsOpen &&
        (this.props.userKnockListCabinWiseResponse.length > 0 ||
          this.state.stickyCountResponse > 0) ? (
          <KnockStickyCount
            notificationIsOpen={this.state.notificationIsOpen}
            // cabinId={this.isCabinPresent(12).Id}
            userKnockCount={this.props.userKnockListCabinWiseResponse}
            userStickyCount={this.state.stickyCountResponse}
            toggleCloseNotification={this.toggleCloseNotification}
            onClickReadNote={this.onClickReadNote}
          />
        ) : null}
        {this.state.onReadNote ? (
          <StickyNotesList
            isStickyModal={!this.state.onReadNote}
            toggleStickyNotes={this.onCloseReadNotes}
            isCabin={true}
            stickyCount={this.stickyCount}
          />
        ) : null}

        {/* Added more info modal popup */}
        {this.state.isMoreInfo ? (
          <MoreInfo
            isOpen={this.state.isMoreInfo}
            isAddToSlack={this.state.isAddToSlack}
            isEditWorkingHours={this.state.isEditWorkingHours}
            isConnectToJira={this.state.isConnectToJira}
            toggleModal={this.toggleMoreInfo}
            myAvatar={this.state.myAvatar}
            onselected={this.selectedAvatar}
            updateUser={this.updateUser}
            userDetails={this.state.userDetails}
            changePassword={this.changePassword}
            changePasswordResponse={this.props.changePasswordResponse}
            userValuesChanges={this.userValuesChanges}
            //updateUserTimezone={this.updateUserTimezone}
            fetchSearchUser={this.fetchSearchUser}
            isGoogleCalendarMeeting={this.state.isGoogleCalendarMeeting}
            // clientId={this.props.slackConfigListResponse[0].Client_ID}
            changeWorkspace={this.changeWorkspace}
            fetchMultipleEmail={this.fetchMultipleEmail}
            multipleEmailResponse={this.props.multipleEmailResponse}
            redirectLogin={this.redirectLogin}
          />
        ) : (
          ""
        )}

        {this.state.isSyncConfirmation ? (
          <Modal
            isOpen={this.state.isSyncConfirmation}
            backdrop={true}
            className="dept-class"
          >
            <ModalHeader
              toggle={this.onCloseSyncConfirmation}
              className="dept-header"
            >
              Sync Confirmation
            </ModalHeader>
            <ModalBody>
              Are you sure you want to continue with the Sync Process?
            </ModalBody>
            <ModalFooter>
              {/* <Button color="primary" onClick={() => {this.setState({ isYes: true });this.syncProcess}}>Yes</Button> */}
              <Button color="primary" onClick={this.syncProcess}>
                Yes
              </Button>

              <Button color="secondary" onClick={this.onCloseSyncConfirmation}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
      </div>
    );
  }
}

// Prop types of props.
Dashboard.propTypes = {
  API_HOST: PropTypes.string,

  loading: PropTypes.bool,
  fetchSlackTeam: PropTypes.func,
  slackTeamResponse: PropTypes.array,

  fetchSlackMember: PropTypes.func,
  slackMemberResponse: PropTypes.array,

  slackUserLoading: PropTypes.bool,
  fetchSlackUser: PropTypes.func,
  slackUserResponse: PropTypes.any,
  memberListResponse: PropTypes.any,

  userDetail: PropTypes.bool,
  getUserDetail: PropTypes.func,
  userDetailResponse: PropTypes.object,

  importUserList: PropTypes.func,
  importUserListResponse: PropTypes.bool,

  tenantDetailLoading: PropTypes.bool,
  getTenantDetail: PropTypes.func,
  tenantDetailResponse: PropTypes.any,

  updateTenantLoading: PropTypes.bool,
  updateTenant: PropTypes.func,
  updateTenantResponse: PropTypes.any,

  updateUserLoading: PropTypes.bool,
  updateUser: PropTypes.func,
  updateUserResponse: PropTypes.any,

  updateProfilePic: PropTypes.func,
  updateUserProfilePicLoading: PropTypes.bool,
  updateUserProfilePicResponse: PropTypes.any,

  changePassword: PropTypes.func,
  changePasswordLoading: PropTypes.bool,
  changePasswordResponse: PropTypes.bool,

  fetchOffice: PropTypes.func,
  fetchOfficeLoading: PropTypes.bool,
  fetchOfficeResponse: PropTypes.any,

  multipleEmailLoading: PropTypes.bool,
  fetchMultipleEmail: PropTypes.func,
  multipleEmailResponse: PropTypes.array,

  changeWorkspace: PropTypes.func,
  changeWorkspaceResponse: PropTypes.any,

  getCabinUserDetail: PropTypes.func,

  fetchSearchUser: PropTypes.func,
  searchUserResponse: PropTypes.array,

  getSlackConfigurationlist: PropTypes.func,
  slackConfigListResponse: PropTypes.any,

  updateUserTimezone: PropTypes.func,

  addVirtualChatGroup: PropTypes.func,
  addVirtualChatGroupResponse: PropTypes.any,

  slackUserDetails: PropTypes.func,
  slackUserDetailsResponse: PropTypes.any,

  getUserLeaveDetail: PropTypes.func,
  userLeaveDetailResponse: PropTypes.any,

  getUserNameByUserId: PropTypes.func,
  userNameByUserIdResponse: PropTypes.any,

  importSlackStepsTrace: PropTypes.func,

  departmentListFilterWiseResponse: PropTypes.any,

  createDepartmentChat: PropTypes.func,
  fetchSlackTeamUpdate: PropTypes.func,
};

Dashboard.defaultProps = {
  getUserListDepartmentUpdateOfficeTime: () => {},
  setUserCustomStatus: () => {},
  doorOpen: () => {},
  getChannelUsersListCount: () => {},
  zoomMeetingJoinLeave: () => {},
  fetchSlackTeam: () => {},
  loading: false,
  slackTeamResponse: [],

  fetchSlackMember: () => {},
  slackMemberResponse: [],

  fetchSlackUser: () => {},
  slackUserLoading: false,
  slackUserResponse: null,
  memberListResponse: null,

  getUserDetail: () => {},
  userDetail: false,
  userDetailResponse: {},

  importUserList: () => {},
  importUserListResponse: false,

  getTenantDetail: () => {},
  tenantDetailLoading: false,
  tenantDetailResponse: {},

  updateTenant: () => {},
  updateTenantLoading: false,
  updateTenantResponse: null,

  updateUser: () => {},
  updateUserLoading: false,
  updateUserResponse: null,

  updateProfilePic: () => {},
  updateUserProfilePicLoading: false,
  updateUserProfilePicResponse: null,

  changePassword: () => {},
  changePasswordLoading: false,
  changePasswordResponse: false,

  fetchOffice: () => {},
  fetchOfficeLoading: false,
  fetchOfficeResponse: null,

  fetchMultipleEmail: () => {},
  multipleEmailLoading: false,
  multipleEmailResponse: [],

  fetchMultipleWorkspace: () => {},
  multipleWorkspaceLoading: false,
  multipleWorkspaceResponse: [],

  changeWorkspace: () => {},
  changeWorkspaceResponse: null,

  getCabinUserDetail: () => {},

  fetchSearchUser: () => {},
  searchUserResponse: [],

  getSlackConfigurationlist: () => {},
  slackConfigListResponse: [],

  updateUserTimezone: () => {},

  addVirtualChatGroup: () => {},
  addVirtualChatGroupResponse: null,

  addWaterCoolerGroup: () => {},

  slackUserDetails: () => {},
  slackUserDetailsResponse: null,
  userListDepartmentFilterResponse: [],
  stickyCountResponse: 0,

  getUserLeaveDetail: () => {},
  userLeaveDetailResponse: null,

  getUserNameByUserId: () => {},
  userNameByUserIdResponse: null,

  importSlackStepsTrace: () => {},

  departmentListFilterWiseResponse: [],

  createDepartmentChat: () => {},
  fetchSlackTeamUpdate: () => {},
  syncWorkspace: () => {},
};

export default compose(DashboardCTX, withRouter)(Dashboard);
