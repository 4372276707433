
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost, socket } from "../../../utils/apiUrls";
import { makeIdArray } from "../../../utils/utility";
import { getOtherUsersList, addUserstoChatGroup } from "../../../actions/slackUserDetailsActions";
import { fetchSlackUser } from "../../../actions/dashboardActions";
import { FormGroup, Row, Col, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from "react-toastify";
import classNames from "classnames";
import ClockLoader from "react-spinners/ClockLoader";
import { Scrollbars } from 'react-custom-scrollbars';

const CustomTbodyComponent = props => (
    <div {...props} className={classNames("rt-tbody", props.className || [])}>
        <Scrollbars autoHide>{props.children}</Scrollbars>
    </div>
);

class AddMember extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isAddToGroup: this.props.isAddToGroup,
            data: [], //dept data
            dept_Id: 0,
            checked: [],
            selectAll: false,
            selectedUsers: [],
            userList: [],
            loading: false,
            disabled: false,
            pageSize: 10,
            usersSlackIdList: []
        };
    }
    componentDidMount() {
        this.getOtherUsersList(this.props.teamId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getDepartmentsResponse !== this.props.getDepartmentsResponse) {
            this.setState({
                data: this.props.getDepartmentsResponse
            })
        }
    }


    //Close Add member Modal popup
    onClosePopUp = () => {
        this.props.closeAddMemberPopUp();
    };


    //Get Users List who are not present in the existing chat group
    getOtherUsersList = async(teamId) => {
        const { API_HOST, getOtherUsersList } = this.props;
        this.setState({ loading: true })
        await getOtherUsersList(API_HOST, teamId, (res) => {
            if (res) {
                this.setState({
                    userList: res,
                    loading: false,
                    pageSize: parseInt(res.length)
                })
            }
        });
    }



    //User Department Update
    assignDepartment = async () => {
        this.setState({
            disabled: true
        })
        const { API_HOST, updateDepartmentList } = this.props;
        let reqBody = {
            "UserIds": makeIdArray(this.state.selectedUsers),
            "DepartmentId": this.state.currentObject.isDepartment.value
        }

        if (this.state.currentObject.isDepartment.value !== 0) {
            await updateDepartmentList(API_HOST, reqBody, (res) => {
                if (res === true) {
                    toast.success("Department assigned successfully!.");
                    this.getUsersList();
                    let data = { updatedptData: this.state.selectedUsers, dptId: this.state.currentObject.isDepartment.value }
                    socket.emit("updateUserDepartment", data);
                    this.setState({
                        checked: [],
                        selectedUsers: [],
                        currentObject: {
                            isDepartment: { value: 0, label: "Select Department" }
                        },
                        selectAll: false,
                        disabled: false
                    });
                }
            });
        } else {
            toast.info("Please, select a department.");
        }
    }


    //Add selected users to existing chat group
    addUserstoChatGroup = async() => {
        const { API_HOST, addUserstoChatGroup } = this.props;
        const usersSlackIdList = [];
        const data = this.state.selectedUsers;
        const map = new Map();

        for (const item of data) {
            if (!map.has(item.Id_SlackUser)) {
                map.set(item.Id_SlackUser, true); // set any value to Map
                    if (!usersSlackIdList.includes(item.Id_SlackUser)) {  //Duplicate not allowed
                        usersSlackIdList.push(item.Id_SlackUser);
                    }
            }
        }

        await this.setState({ usersSlackIdList: usersSlackIdList})
        
        const addUserstoChatGroupRequest = {
            "ChannelId": this.props.teamDetails.OtherContent,
            "Users": this.state.usersSlackIdList
        };

        await addUserstoChatGroup(API_HOST, addUserstoChatGroupRequest, (res) => {
            if (res) {
                toast.info("Selected Users added to this Chat Group");
                this.props.closeAddMemberPopUp();
                
                this.fetchSlackUser(this.props.teamId); //this method will update the users list.
                this.props.channelWiseSlackUserDetails(this.props.teamDetails.OtherContent); //this will update the chatlist users.
                this.props.fetchHistory(this.props.teamDetails.OtherContent, 0); // this will update the chat conversation.
            }
        });
    }


    handleChange = () => {
        var selectAll = !this.state.selectAll;
        this.setState({ selectAll: selectAll });
        var checkedCopy = [];
        var selectdUser = [];
        this.state.userList.forEach(function (user, index) {
            checkedCopy.push(selectAll);
            selectdUser.push(user)
        });
        
        this.setState({
            checked: checkedCopy,
        });
        if (selectAll === true && selectdUser.length > 0) {
            this.setState({ selectedUsers: selectdUser });
            selectdUser = [];
        } else {
            this.setState({ selectedUsers: [] });
            selectdUser = [];
        }
    };


    handleSingleCheckboxChange = (e, index, user, id) => {
        if (e.target.checked) {
            let temp = [user];
            var checkUser = this.state.selectedUsers.filter(user => user.Id_User === id)
            if (checkUser.length === 0) {
                this.setState({
                    selectedUsers: [...this.state.selectedUsers, ...temp],
                })
            }
        } else {
            var selectUserRemove = this.state.selectedUsers.filter(user => user.Id_User !== id)
            this.setState({ selectedUsers: selectUserRemove })
        }

        var checkedCopy = this.state.checked;
        checkedCopy[index] = !this.state.checked[index];
        if (checkedCopy[index] === false) {
            this.setState({ selectAll: false });
        }

        this.setState({
            checked: checkedCopy
        });
    };

    // Fetch Slack user list.
    fetchSlackUser = async (id_Channel) => {
        console.log("fetchSlackUser called");
        const { API_HOST, fetchSlackUser } = this.props;
        const slackUserRequest = {
            "IsPagging": true,
            "PageNo": 1,
            "PageSize": 100,
            "SortingParameters": [
                {
                    "Property": "",
                    "Direction": 0
                }
            ],
            "SearchCriteria": {
                "SearchText": "",
                "Id_Channel": id_Channel
            }
        };
        await fetchSlackUser(API_HOST, slackUserRequest);
    }


    render() {
      

        const { isAddToGroup, userList, loading, selectedUsers, selectAll, pageSize, disabled } = this.state;
        const { } = this.props;

        const columns = [
            {
                Header: (<input
                    type="checkbox"
                    className="checkbox-unreadlist"
                    style={{ width: '16px', height: '16px' }}
                    onChange={this.handleChange}
                    checked={selectAll}
                />),
                accessor: 'Id_User',
                minWidth: 30,
                sortable: false,
                Cell: row => {
                    if (row.original.Id_User) {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox-unreadlist"
                                // defaultChecked={this.state.selectedUsers.find((user) => user.Id_User === row.original.Id_User) ? true : false}
                                // checked={this.state.selectedUsers.find((user) => user.Id_User === row.original.Id_User) ? true : false}
                                //onChange={(e) => this.handleSingleCheckboxChange(e, row.original.Id_User, row.original)}
                                defaultChecked={this.state.checked[row.index]}
                                checked={this.state.checked[row.index]}
                                onChange={(e) => this.handleSingleCheckboxChange(e, row.index, row.original, row.original.Id_User)}
                                style={{ width: '16px', height: '16px' }}
                            />
                        )
                    }
                }
            },
            {
                Header: <strong className="my_custom_class">User Name</strong>,
                accessor: 'Real_Name',
            },
            {
                Header: <strong className="my_custom_class">Email</strong>,
                accessor: 'EmailId',
            }
        ];

        return (
            <React.Fragment>
                <Modal isOpen={isAddToGroup} backdrop={true} size={'lg'} >
                    <ModalHeader toggle={() => this.onClosePopUp()} className="dept-header">
                        Add to Group Chat
                    </ModalHeader>
                    <ModalBody className="dept-class">
                        <div>
                            <Button color="primary" style={{width:'200px', marginBottom: '15px'}} type="submit" disabled={selectedUsers.length > 0 ? false : true} onClick={disabled === false && this.addUserstoChatGroup}><span>Add to Group Chat</span>
                                <span style={{ marginLeft: '5px' }}>
                                    <ClockLoader
                                        size={16}
                                        color={"#fff"}
                                        loading={disabled}
                                        />
                                </span>
                            </Button>
                        </div>
                        <div>
                            <ReactTable
                                data={userList}
                                columns={columns}
                                defaultPageSize={10}
                                style={{
                                    height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                                }}
                                pageSize={pageSize}
                                showPagination={false}
                                className="-striped -highlight"
                                loading={loading}
                              loading={false}
                                TbodyComponent={CustomTbodyComponent}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment >

       );
    }
}


// Prop types of props.
AddMember.propTypes = {
    API_HOST: PropTypes.string,
    getDepartments: PropTypes.func,
    getDepartmentsResponse: PropTypes.array,
    //getUserAvailabilityListResponse: propTypes.array
};

// Set default props.
AddMember.defaultProps = {
    updateDepartmentList: () => { },
    getUsersList: () => { },
    getDepartments: () => { },
    getDepartmentsResponse: [],
    // getUserAvailabilityListResponse: []
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    getDepartmentsResponse: state.getDepartmentReducer.getDepartmentsResponse,
    //getUserAvailabilityListResponse: state.mainOfficeReducer.userListDepartmentFilterResponse
})

export default connect(mapStateToProps, { fetchSlackUser, getOtherUsersList, addUserstoChatGroup })(AddMember);