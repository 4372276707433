import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { forgotPassword } from "../../../actions/forgotPasswordActions";
import { getApiHost } from '../../../utils/apiUrls';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
//import ForgotPasswordSuccess from "./ForgotPasswordSuccess";

class ForgotPassword extends React.Component {
  state = {
    fields: {},
    errors: {},
    isSuccess: false
  };

  // toggleClose = () => {
  //   this.setState({ isSuccess: false })
  // }

  // Function calls while type user name or password and set their values.
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  // Function handles validation and if isSuccess then calls login method.
  onSubmitEmail = (event) => {
    event.preventDefault();
    this.onSubmit();
  };

  // Calls Login API to get logged in.
  onSubmit = async () => {
    if(this.state.fields["username"] === undefined){
      toast.error("Please enter your Email address");
    }
  else{

        const { API_HOST, forgotPassword } = this.props;
        const forgotPasswordRequest = {
          "EmailId": this.state.fields["username"],
        };
        await forgotPassword(API_HOST, forgotPasswordRequest, (res) => {
          if (res.stack !== "Error") {
            let fields = this.state.fields;
            fields["username"] = "";
            this.setState({ fields: fields });
            toast.success("Reset password link has been sent on your email Id (which will expire in 2 hours)", { autoClose: 8000 });
            
          }
          this.props.history.push({ pathname: '/login' });
        });
    }
  };



  // validate emailID
  // validateEmail(inputText) {
  //   // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //   var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  //   if (inputText.match(mailformat)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  validateEmail = (e) =>{
    const emailText = e.target.value;
    var pattern= "[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})";
    //var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (e.target.name === "username" && !emailText.match(pattern)) {
        toast.error("Please enter a valid email address");
      }

  }

  // Handles validation for required fields.sdfs
  // handleValidation() {
  //   let fields = this.state.fields;
  //   let errors = {};
  //   let formIsValid = true;

  //   // User name
  //   if (!fields["username"]) {
  //     formIsValid = false;
  //     errors["username"] = "Email is required";
  //     errors["usernameclass"] = "is-invalid";
  //   } else {
  //     let validemail = this.validateEmail(fields["username"]);
  //     if (validemail) {
  //       formIsValid = true;
  //       errors["username"] = "Email id";
  //       errors["usernameclass"] = "is-valid";
  //     } else {
  //       formIsValid = false;
  //       errors["username"] = "Email is not valid";
  //       errors["usernameclass"] = "is-invalid";
  //     }
  //   }

  //   this.setState({ errors: errors });
  //   return formIsValid;
  // }

  render() {
    return (
      <div className="wrapper">
        <div className="login-panel">


          <div className="bg-animate">
            <div class="bg"></div>
            <div class="bg bg2"></div>
            <div class="bg bg3"></div>
          </div>
          <div className="login-content">
            <img className="img" alt="logo.png" src="images/logo.png" />
            <div className="login-card card shadow">
              <div className="title">Forgot Password?</div>
              <form onSubmit={this.onSubmitEmail}>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Enter your Email Address to reset your password</label>
                  <input
                    type="email"
                    // className={`form-control ${this.state.errors && this.state.errors["usernameclass"]
                    //   ? this.state.errors["usernameclass"]
                    //   : ""
                    //   }`}
                    className="form-control"
                    id="exampleInputEmail1"
                    //aria-describedby="emailHelp"
                    placeholder="Enter your email address"
                    value={this.state.fields["username"] || ""}
                    name="username"
                    onChange={(e) => this.handleChange("username", e)}
                    required
                    onBlur={(e) => this.validateEmail(e)}
                  />
                </div>
                <button type="submit" className="btn btn-primary" >
                  Send Link
                </button>

              </form>
              <div className="text-center">
                <div className="signup-link">
                  <span></span>
                  <Link to={"/login"} className="have-acc">
                    Login
              </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ForgotPasswordSuccess isOpen={this.state.isSuccess} toggle={this.toggleClose} /> */}
      </div>
    );
  }
}

// Prop types of props.
ForgotPassword.propTypes = {
  API_HOST: PropTypes.string,
  forgotPassword: PropTypes.func,
  forgotPasswordErrorMessage: PropTypes.string,
  loading: PropTypes.bool,
  forgotPasswordResponse: PropTypes.bool,
  forgotPasswordError: PropTypes.bool,

};

// Set default props.
ForgotPassword.defaultProps = {
  forgotPassword: () => { },
  forgotPasswordErrorMessage: "",
  loading: false,
  forgotPasswordError: false,
};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
    forgotPasswordResponse: state.forgotPasswordReducer.forgotPassword.forgotPasswordResponse,
  };
};

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
