
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost, socket } from "../../../utils/apiUrls";
import { addDepartment, getDeptById } from "../../../actions/departmentActions";

class AddEditDepartment extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deptDetails: {},
      Description: '',
      Name: '',
      deptId: this.props.dept_Id
    };
  }


  componentDidMount() {
    if (this.state.deptId !== 0) {
      this.getDeptById(this.props.dept_Id);
    }
  }

  //Close Add Dept Modal
  onCloseAddDept = () => {
    this.setState({
      Name: '',
      Description: '',
      deptId: 0
    })
    this.props.toggleCloseAddDept();
  };


  //Add Department
  addDepartment = () => {

    const { API_HOST, addDepartment } = this.props;
    const addDepartmentRequest = {
      "id": this.state.deptId,
      "name": this.state.Name,
      "description": this.state.Description
    };

    addDepartment(API_HOST, addDepartmentRequest, (res) => {
      const editDeptNameRequest = {
        "id": this.state.deptId,
        "name": this.state.Name,
      };
      this.props.getDepartments();
      socket.emit("editDepartmentName", editDeptNameRequest);
      if(this.props.fromOfficeLayout){
        this.props.createDepartmentPopup(res.Id);
      }
    });
    this.onCloseAddDept();
  };

  //Get Department by Id
  getDeptById = (id) => {
    const { API_HOST, getDeptById } = this.props;
    getDeptById(API_HOST, id, (res) => {
      this.setState({
        Name: res.Name,
        Description: res.Description,
        deptId: res.Id
      })
    });
  };

  handleAddDept = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };


  render() {
    const {
      isAddDeptModalOpen
    } = this.props;


    return (
      <Modal isOpen={isAddDeptModalOpen} backdrop={true} className="add-edit-class">
        <ModalHeader toggle={() => this.onCloseAddDept()} className="dept-header">
          {this.state.deptId === 0 ? 'Add Department' : 'Edit Department'}
        </ModalHeader>
        <form method="post" id="form1" onSubmit={this.addDepartment}>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Department Name: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder=""
                    value={this.state.Name}
                    name="Name"
                    onChange={(e) => this.handleAddDept(e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Description: </label>
                  <textarea
                    type="textarea"
                    className="form-control"
                    id="description"
                    placeholder=""
                    rows="10"
                    cols="30"
                    value={this.state.Description}
                    name="Description"
                    onChange={(e) => this.handleAddDept(e)}
                    //required
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Save</Button>
          </ModalFooter>
        </form>
      </Modal >
    );
  }
}

// Prop types of props.
AddEditDepartment.propTypes = {
  API_HOST: PropTypes.string,
  addDepartment: PropTypes.func,
  getDeptById: PropTypes.func
};

// Set default props.
AddEditDepartment.defaultProps = {
  addDepartment: () => { },
  getDeptById: () => { }
};

const mapStateToProps = state => ({
  API_HOST: getApiHost()
})


export default connect(mapStateToProps, { addDepartment, getDeptById })(AddEditDepartment)
