import React from "react";
import PropTypes from "prop-types";
import logo from '../../../assets/images/logo.png';
import { Link } from "react-router-dom";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import {getEncryptedValue} from "../../../utils/utility";
import { connect } from "react-redux";
import { resetPassword, validActivationKey } from "../../../actions/resetPasswordActions";
import { getApiHost } from '../../../utils/apiUrls';
import { toast } from "react-toastify";

class ResetPassword extends React.Component {
  state = {
    fields: {},
    errors: {},
    isActivated: false,
    isSuccess: false
  };

  componentDidMount()  {
    this.validActivationKey();
    
      this.setState({ isActivated: this.props.validActivationKeyResponse })

      window.addEventListener('popstate', (event) => {
       // this.setState({ isActivated: false })
        window.location.reload();
      });

  }

  componentWillReceiveProps(nextProps){
    if(nextProps.validActivationKeyResponse!==this.props.validActivationKeyResponse)
    {
        this.setState({ isActivated: nextProps.validActivationKeyResponse })
         
    }
  }

  validActivationKey = async () => {
    const { API_HOST, validActivationKey } = this.props;
    const validActivationKeyRequest = {
      "ActivationKey": this.props.match.params.activationKey
    };
    await validActivationKey(API_HOST, validActivationKeyRequest);
    this.setState({ isActivated: this.props.validActivationKeyResponse })
  }

  // Function handles validation and if success then calls submit method.
  onSubmitPassword = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.onSubmit();
    }
  };

  // Calls reset password API
  onSubmit = async () => {
     const { API_HOST, resetPassword } = this.props;
     const resetPasswordRequest = {
      "ActivationKey": this.props.match.params.activationKey,
      "NewPassword": getEncryptedValue(this.state.fields["userpassword"]),
     };
     await resetPassword(API_HOST,resetPasswordRequest );

    //Open popup of isSuccess
    if (this.props.resetPasswordResponse) {
      this.setState({ isSuccess: true })
    }

  };

  // Handles validation for required fields.sdfs
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // Password
    if (!fields["userpassword"]) {
      formIsValid = false;
      errors["userpassword"] = "Password is required";
      errors["userpasswordclass"] = "is-invalid";
    } else {
      formIsValid = true;
      errors["userpassword"] = "Password";
      errors["userpasswordclass"] = "is-valid";
    }

    //Confirm password
    if (!fields["userconfirmpassword"]) {
      formIsValid = false;
      errors["userconfirmpassword"] = "Password is required";
      errors["userconfirmpasswordclass"] = "is-invalid";
    } else {
      formIsValid = true;
      errors["userconfirmpassword"] = "Password";
      errors["userconfirmpasswordclass"] = "is-valid";
    }

    if (fields["userconfirmpassword"] === fields["userpassword"]) {
      formIsValid = true;
      errors["userconfirmpassword"] = "Password";
      errors["userconfirmpasswordclass"] = "is-valid";
    }
    else {
      formIsValid = false;
      errors["userconfirmpassword"] = "Password does not match";
      errors["userconfirmpasswordclass"] = "is-invalid";
    }

    if (fields["userconfirmpassword"] !== fields["userpassword"]) {
      formIsValid = false;
      toast.error('Password and confirm password should be same');
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  // Function calls while type user name or password and set their values.
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  toggleClose = () => {
    this.setState({ isSuccess: false, fields:{} , errors:{}})
    this.props.history.push({ pathname: '/login' });
  }

  validatePassword = (e) =>{
    const passwordText = e.target.value;
    var pattern= /^[a-zA-Z0-9!@#$%^&*]{8,10}$/;
      if (e.target.name === "userpassword" && !passwordText.match(pattern)) {
        toast.error("Please enter a valid password. It should contain one Capital letter, one Number, one Special Character, and length should be upto 10 characters");
      }
  }

  render() {
    // let { topicId } = useParams();

    return (
      <div className="wrapper">
        <div className="login-panel">
          <div className="login-content">
            <img className="img" alt="logo.png" src={logo} />
            <div className="login-card card shadow">
              <div className="title">Reset Password</div>
              {this.state.isActivated===true ?
                (
                <div>
                <form onSubmit={this.onSubmitPassword} noValidate>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">New Password</label>
                    <input
                      type="password"
                      className={`form-control ${
                        this.state.errors &&
                          this.state.errors["userpasswordclass"]
                          ? this.state.errors["userpasswordclass"]
                          : ""
                        }`}
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={this.state.fields["userpassword"] || ""}
                      name="userpassword"
                      onChange={(e) => this.handleChange("userpassword", e)}
                      required
                      onBlur={(e) => this.validatePassword(e)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputPassword2">
                      Confirm New Password
                  </label>
                    <input
                      type="password"
                      className={`form-control ${
                        this.state.errors &&
                          this.state.errors["userconfirmpasswordclass"]
                          ? this.state.errors["userconfirmpasswordclass"]
                          : ""
                        }`}
                      id="exampleInputPassword2"
                      placeholder="Confirm Password"
                      value={this.state.fields["userconfirmpassword"] || ""}
                      name="userconfirmpassword"
                      onChange={(e) =>
                        this.handleChange("userconfirmpassword", e)
                      }
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary" >
                    RESET MY PASSWORD
                </button>

                </form>
                <div className="text-center">
                <div className="signup-link">
                  <Link to={"/login"} className="have-acc">
                    Login
                </Link>
                </div>
              </div>
              </div>
                )
                :
                (
                  <div>
                    The link has expired, please regenerate a new link by following the same process.
                </div>
                )
              }              
            </div>
            <div className="policy-link">
              <span>By signin up, you agree to our</span>
              <Link to={"/termsandconditions"} className="have-acc">
                Terms and Conditions
              </Link>
              {/* <a href="/#" className="have-acc">
                Terms and Conditions
              </a> */}{" "}
              &{" "}
              <Link to={"/privacypolicy"} className="have-acc">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <ResetPasswordSuccess isOpen={this.state.isSuccess} toggle={this.toggleClose} />
      </div>
    );
  }
}

// Prop types of props.
ResetPassword.propTypes = {
  API_HOST: PropTypes.string,
  resetPassword: PropTypes.func,
  resetPasswordErrorMessage: PropTypes.string,
  loading: PropTypes.bool,
  resetPasswordResponse: PropTypes.bool,
  resetPasswordError: PropTypes.bool,
  key: PropTypes.object,
  validActivationKey: PropTypes.func,
  validActivationKeyResponse: PropTypes.bool,
};

// Set default props.
ResetPassword.defaultProps = {
  resetPassword: () => { },
  resetPasswordErrorMessage: "",
  loading: false,
  resetPasswordError: false,
  validActivationKey: () => { },
  validActivationKeyResponse: false
};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
    resetPasswordResponse: state.resetPasswordReducer.resetPassword.resetPasswordResponse,
    validActivationKeyResponse: state.resetPasswordReducer.validActivationKey.validActivationKeyResponse
  };
};
export default connect(mapStateToProps, {resetPassword,validActivationKey})(ResetPassword);
