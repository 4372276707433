import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//import ItemsCarousel from 'react-items-carousel';
import { AvatarImage } from "../../../components/Avatar/AvatarImage";
import { ContextMenuTrigger } from "react-contextmenu";
import { getApiHost, socket } from "../../../utils/apiUrls";
import {
  doorOpen,
  doorClose,
  saveUserKnock,
  stickyMessageSend,
  userKnockListCabinWise,
  stickyCount,
  userKnockSeen,
  userStickySeen,
  makeAdmin,
  setUserCustomStatus,
} from "../../../actions/mainOfficeActions";
import { getUserListOfDepartmentWithCallback } from "../../../actions/departmentActions";
import LoadingOverlay from "react-loading-overlay";
import ContextMenuOption from "./ContextMenuOption";
import StickyNotes from "./StickyNotes";
import { toast } from "react-toastify";
import pcIcon from "../../../assets/images/pc-desktop.png";
import watchIcon from "../../../assets/images/watch-bg.png";
import { getUserDetail } from "../../../actions/dashboardActions";
import ReactTooltip from "react-tooltip";
import Whirligig from "react-whirligig";
import Moment from "react-moment";
import { fetchOffice } from "../../../actions/officeActions";

//import Slider from 'infinite-react-carousel';

let userIndex = 0;
class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemIndex: 0,
      SearchbarUserId: this.props.SearchbarUserId,
      userRes: [],
      userFind: false,
      userId: 0,
      userDepartmentId: 0,
      knockSelected: false,
      makeAdminSelected: false,
      stickyNoteIsOpen: false,
      //stickyNotesMessage: "",
      personId: 0,
      notificationIsOpen: false,
      onReadNote: false,
      userList: [],
      userStatus: "",
      userDetails: {},
      isCustomStatus: false,
      loading: false,
      getUserDetailResponse: {},
      showScheduleMeetingOption: true,
      date: new Date(),
      officeRes: [],
    };
  }

  async componentDidMount() {
    await this.fetchOffice();
    setInterval(this.updateTime, 5000);
    if (this.props.timeFilter > 0) {
      this.getUserListHrsFilter(this.props.userListWithHrs);
    } else {
      //this.getUserListOfDepartment();
      this.setState({ userList: this.props.userList });
    }

    socket.on("UserDetail", (data) => {
      if (
        data.Id_Tenant ===
        JSON.parse(localStorage.getItem("userdetail")).Id_Tenant
      ) {
        console.log("DATAAAAA  :  ", data);
        this.updateUserDetail(data);
        //  this.getUserListOfDepartment()
      } else {
        console.log("Data.ID: ", data.Id_User);
        this.getUserDetail(data.Id_User);
      }
    });
    socket.on("updateOfficeTime", (data) => {
      this.updateOfficeTime(data);
    });
    socket.on("newUserDetail", (data) => {
      this.addNewUser(data);
    });
    //////////////////////
    // socket.on("userSignedInWithSlack", () => {
    //     this.getUserListOfDepartment();
    // })
  }

  updateTime = () => {
    let date = new Date();
    this.setState({ date: date });
  };
  getUserDetail = async (id) => {
    const { API_HOST, getUserDetail } = this.props;
    await getUserDetail(API_HOST, id);
  };

  // Fetch Tenant Offices
  fetchOffice = async () => {
    const { API_HOST, fetchOffice } = this.props;
    await fetchOffice(API_HOST);
  };

  addNewUser = (data) => {
    var userData = JSON.parse(localStorage.getItem("userdetail"));
    var workspace = userData.Slack_WorkSpace_Name;
    let list = this.state.userList;
    if (workspace === data.Slack_WorkSpace_Name) {
      let newList = list.concat(data);
      this.setState({
        userList: newList,
      });
    }
  };

  setActiveItemIndex = (activeItemIndex) => {
    this.setState({
      activeItemIndex,
    });
    this.getIndex(activeItemIndex);
  };

  updateOfficeTime(data) {
    let newData = this.state.userList.map((item) => {
      item.UserStartTime_EndTimeVM.Availibility_Time =
        data.UserStartTime_EndTimeVM.Availibility_Time;
      //item.TZ = data.TZ
      return item;
    });
    this.setState(Object.assign({}, { userList: newData }));
  }

  updateUserDetail(data) {
    let newData = this.state.userList.map((item) => {
      if (item.Id_User === data.Id_User) {
        item.Address = data.Address;
        item.CompanyName = data.CompanyName;
        item.Department_Name = data.Department_Name;
        item.Department_Size_Type = data.Department_Size_Type;
        item.Display_Name = data.Display_Name;
        item.EmailId = data.EmailId;
        item.First_Name = data.First_Name;
        item.Id_Department = data.Id_Department;
        item.Id_Role = data.Id_Role;
        item.Is_Admin = data.Is_Admin;
        item.Is_Active = data.Is_Active;
        item.Is_DoorOpen = data.Is_DoorOpen;
        item.Is_DoorOpenDepartment = data.Is_DoorOpenDepartment;
        item.Last_Name = data.Last_Name;
        item.Phone = data.Phone;
        item.Position = data.Position;
        item.ProfilePic = data.ProfilePic;
        item.Real_Name = data.Real_Name;
        item.TZ = data.TZ;
        item.TZ_Label = data.TZ_Label;
        item.TZ_Offset = data.TZ_Offset;
        item.Tenant_Name = data.Tenant_Name;
        item.Title = data.Title;
        item.ZipCode = data.ZipCode;
        item.UserStartTime_EndTimeVM = data.UserStartTime_EndTimeVM;
        item.UserStatus = data.UserStatus;
        item.Is_LoggedIn = data.Is_LoggedIn;
        item.CurrentLoggedInTenant_Name = data.CurrentLoggedInTenant_Name;
      }
      return item;
    });

    this.setState(Object.assign({}, { userList: newData }));
  }

  getUserListOfDepartment = async () => {
    const { API_HOST, getUserListOfDepartmentWithCallback } = this.props;
    // const requestBody = {
    //     // "Id_User" : 0,
    //     "Id_Department": this.props.departmentId
    // }

    const requestBody = {
      SearchCriteria: {
        Id_User: 0,
        Id_Department: this.props.departmentId,
      },
    };

    this.setState({ loading: true });
    await getUserListOfDepartmentWithCallback(API_HOST, requestBody, (res) => {
      this.setState({ loading: false });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.getUserDetailResponse !== this.props.getUserDetailResponse) {
      // this.setState({ getUserDetailResponse: this.props.getUserDetailResponse });

      this.updateUserDetail(this.props.getUserDetailResponse);
    }

    if (prevProps.SearchbarUserId !== this.props.SearchbarUserId) {
      this.setState({
        SearchbarUserId: this.props.SearchbarUserId,
      });
    }

    if (prevProps.userListWithHrs !== this.props.userListWithHrs) {
      if (this.props.timeFilter !== 0) {
        this.getUserListHrsFilter(this.props.userListWithHrs);
        let userData = this.props.userListWithHrs.find(
          (user) =>
            user.Id_User ===
            JSON.parse(localStorage.getItem("userdetail")).Id_User
        );
        if (userData) {
          //  this.props.userValuesChanges(userData);
        }
      }
    }
    if (
      prevProps.userListDepartmentFilterResponse !==
      this.props.userListDepartmentFilterResponse
    ) {
      if (this.props.timeFilter === 0) {
        this.setState({
          userList: this.props.userListDepartmentFilterResponse,
        });
        if (
          this.props.departmentId ===
          JSON.parse(localStorage.getItem("userdetail")).Id_Department
        ) {
          let userData = this.props.userListDepartmentFilterResponse.find(
            (user) =>
              user.Id_User ===
              JSON.parse(localStorage.getItem("userdetail")).Id_User
          );
          //this.props.userValuesChanges(userData);
        }
      }
    }
    if (prevProps.timeFilter !== this.props.timeFilter) {
      if (this.props.timeFilter !== 0) {
        this.getUserListHrsFilter(this.props.userListWithHrs);
      } else {
        this.setState({
          userList: this.props.userListDepartmentFilterResponse,
        });
      }
    }
    if (prevProps.getOfficesResponse !== this.props.getOfficesResponse) {
      this.setState({ officeRes: this.props.getOfficesResponse });

      //console.log("abcd 3 : ", this.state.officeRes);
      var endTime = this.props.getOfficesResponse[0].End_Time;
      //console.log("abcd 3 endtime: ", endTime);
      var h = Math.floor(endTime / 60);
      var m = endTime % 60;

      var now = new Date();
      var millisTill10 =
        new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          h,
          m + 2,
          0,
          0
        ) - now;
      if (millisTill10 < 0) {
        millisTill10 += endTime * 60 * 60 + 2000; // it's after 10am, try 10am tomorrow.
      }

      setTimeout(() => {
        //alert("It's Time!");
        this.props.getUserListOfDepartment();
      }, millisTill10);
    }
  }

  getUserListHrsFilter = (userList) => {
    let usersList = userList.filter(
      (user) => user.Id_Department === this.props.departmentId
    );
    this.setState({ userList: usersList });
  };

  getIndex = (indexVal) => {
    userIndex = this.state.userList.findIndex(
      (obj, index) => index === indexVal
    );
    return userIndex;
  };

  // Sticky Note option selected from ContextMenu
  onSticktyNoteSelected = (userId) => {
    this.setState({
      personId: userId,
      sticktyNoteSelected: true,
      stickyNoteIsOpen: true,
    });
  };

  //Knock option selected from ContextMenu
  onKnockSelected = (userId) => {
    this.setState({ knockSelected: true });
    this.saveUserKnock(userId);
  };

  //Make Admin option selected from ContextMenu
  onMakeAdminSelected = (userId) => {
    this.makeAdmin(userId);
    this.setState({ makeAdminSelected: true });
  };

  //Knock option selected from ContextMenu
  onKnockSelected = (personId) => {
    this.setState({ knockSelected: true });
    this.saveUserKnock(personId);
  };

  toggleCloseSticky = () => {
    this.setState({ stickyNoteIsOpen: false });
  };

  // stickyNotesMessageSend = (e) => {
  //     this.setState({ stickyNotesMessage: e.target.value });
  // }

  // stickyMessageSend = (e) => {
  //     e.preventDefault();
  //     const { API_HOST, stickyMessageSend } = this.props;
  //     const stickyMessageSendRequest = {
  //         "Id_UserReceiver": this.state.personId,
  //         "Id_UserSender": 0,
  //         "Description": this.state.stickyNotesMessage
  //     }
  //     stickyMessageSend(API_HOST, stickyMessageSendRequest, (res) => {
  //         if (res) {
  //             this.setState({
  //                 stickyNotesMessage: ''
  //             })
  //             this.toggleCloseSticky();
  //         }
  //     });
  // }

  //save User Knock
  saveUserKnock = async (userId) => {
    const { API_HOST, saveUserKnock } = this.props;
    const saveUserKnockRequest = {
      Id_UserReceiver: userId,
      Id_UserSender: 0,
    };
    await saveUserKnock(API_HOST, saveUserKnockRequest, (res) => {});
  };

  //Make the selected user Admin
  makeAdmin = (userId) => {
    let admin = {
      Id_User: userId,
      Is_Admin: true,
      tenant_Id: JSON.parse(localStorage.getItem("userdetail")).Id_Tenant,
    };
    socket.emit("makeAdmin", admin);
    let newData = this.state.userList.map((item) => {
      if (item.Id_User === userId) {
        item.Is_Admin = true;
        socket.emit("UserDetail", item);
      }
      return item;
    });
    this.setState(Object.assign({}, { userList: newData }));

    const { API_HOST, makeAdmin } = this.props;
    makeAdmin(API_HOST, userId, (res) => {});
    //this.getUserListOfDepartment();
  };

  render() {
    const { loading } = this.state;
    const chevronWidth = 40;
    var userData = localStorage.getItem("userdetail");
    var loggedInUserId = JSON.parse(userData).Id_User;

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    console.log("Slider users : ", this.state.userList);

    let whirligig;
    const next = () => whirligig.next();
    const prev = () => whirligig.prev();

    return (
      <div
        data-for="sliderDept"
        data-tip="Right click on the workgroup to initiate the group level chat conversation."
      >
        <ReactTooltip
          id="sliderDept"
          place="top"
          type="dark"
          effect="solid"
          multiline={true}
          backgroundColor="#2c69d1"
          textColor="white"
          border="true"
        />
        {this.state.userList && this.state.userList.length > 0 ? (
          <>
            {/* <LoadingOverlay
                        active={this.state.userList.length > 0 ? false : true}
                        spinner
                        text='Loading...'
                    > */}
            {/* <ItemsCarousel
                            requestToChangeActive={this.setActiveItemIndex}
                            activeItemIndex={userIndex}
                            numberOfCards={this.props.numberOfCard}
                            gutter={1}
                            leftChevron={
                                <button class="fas fa-chevron-left"></button>}
                            rightChevron={
                                <button class="fas fa-chevron-right"></button>}
                            outsideChevron
                            chevronWidth={chevronWidth}
                        > */}

            {/* <Carousel
    swipeable={false}
    draggable={false}
    showDots={true}
    responsive={responsive}
    // ssr={true} // means to render carousel on server-side.
    infinite={true}
    autoPlay={false}
    autoPlaySpeed={1000}
    keyBoardControl={true}
    customTransition="all .5"
    transitionDuration={500}
    containerClass="carousel-container"
    removeArrowOnDeviceType={["tablet", "mobile"]}
    //deviceType={this.props.deviceType}
    dotListClass="custom-dot-list-style"
    itemClass="carousel-item-padding-40-px"
    > */}

            {this.props.numberOfCard > 2 ? (
              <button className="w_slide w_slide_prev" onClick={prev}>
                <i class="fas fa-chevron-left"></i>
              </button>
            ) : (
              ""
            )}
            <Whirligig
              visibleSlides={this.props.numberOfCard}
              gutter="1em"
              slideTo={userIndex}
              className="whrlgig_slider"
              ref={(_whirligigInstance) => {
                whirligig = _whirligigInstance;
              }}
              slideBy="1"
            >
              {/* <Slider slidesToShow="3" slickGoTo={userIndex}> */}

              {this.state.userList &&
                this.state.userList
                  .filter(
                    (user) =>
                      user.Id_Department === this.props.departmentId &&
                      user.Is_Active === true
                  )
                  .map((user, index) => {
                    const profilePic = JSON.parse(user.ProfilePic);
                    var moment = require("moment-timezone");
                    const time = user.TZ
                      ? moment().tz(user.TZ).format("HH:mm")
                      : "";
                    if (user.Id_User === this.props.SearchbarUserId) {
                      this.getIndex(index);
                    }
                    return (
                      <div
                        className="user-card-list user-cabin"
                        key={user.Id_User}
                        onDoubleClick={() =>
                          user.Id_User !==
                            JSON.parse(localStorage.getItem("userdetail"))
                              .Id_User && user.Is_DoorOpen
                            ? this.props.handleAddChat(user)
                            : null
                        }
                      >
                        <div className="slide" style={{ height: "auto" }}>
                          <ContextMenuTrigger
                            id={`${
                              JSON.parse(localStorage.getItem("userdetail"))
                                .Id_User !== user.Id_User
                                ? user.Id_User
                                : ""
                            }`}
                          >
                            <div
                              data-for={`${
                                user.Is_Latest_Department_Assigned === true
                                  ? "newUserDept"
                                  : "useremail"
                              }`}
                              data-tip={`${
                                user.Is_Latest_Department_Assigned === true
                                  ? "New User added to the department."
                                  : user.EmailId
                              }`}
                              className={`${
                                this.state.SearchbarUserId === user.Id_User
                                  ? "card my-cabin"
                                  : "card"
                              }
                                                     ${
                                                       user.Is_DoorOpen
                                                         ? " card-door-open"
                                                         : ""
                                                     } 
                                                     ${
                                                       user
                                                         .UserStartTime_EndTimeVM
                                                         .Availibility_Status ===
                                                         "OOO" ||
                                                       user
                                                         .UserStartTime_EndTimeVM
                                                         .Availibility_Status ===
                                                         "On leave/vacation"
                                                         ? "out-office"
                                                         : ""
                                                     }
                                                     ${
                                                       user.Is_Latest_Department_Assigned ===
                                                       true
                                                         ? " my-cabin-dept"
                                                         : ""
                                                     } 
                                                     ${
                                                       user.Is_Admin
                                                         ? " card-admin"
                                                         : ""
                                                     }`}
                              onContextMenu={(e) => {
                                e.preventDefault();
                                this.setState({
                                  userId: user.Id_User,
                                  isUserDoorOpen: user.Is_DoorOpen,
                                  userStatus:
                                    user.UserStartTime_EndTimeVM
                                      .Availibility_Status,
                                  userDetails: user,
                                });
                              }}
                            >
                              {user.Is_Latest_Department_Assigned === true ? (
                                <div>
                                  <ReactTooltip
                                    id="newUserDept"
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                    multiline={true}
                                    backgroundColor="#2c69d1"
                                    textColor="white"
                                    border="true"
                                  />
                                </div>
                              ) : (
                                <div>
                                  <ReactTooltip
                                    id="useremail"
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                    multiline={true}
                                    backgroundColor="#2c69d1"
                                    textColor="white"
                                    border="true"
                                  />
                                </div>
                              )}

                              <span
                                className="status"
                                style={{ position: "relative" }}
                              >
                                {
                                  user.UserStartTime_EndTimeVM
                                    .Availibility_Status
                                }
                                {user.UserStatus &&
                                !(
                                  user.UserStatus === "Available" ||
                                  user.UserStatus === "available"
                                ) ? (
                                  <span className="meeting-with">
                                    {" "}
                                    {user.UserStatus}{" "}
                                  </span>
                                ) : null}
                                {user.Is_LoggedIn && (
                                  <i
                                    class="fas fa-circle"
                                    style={{
                                      right: "-3px",
                                      bottom: "-3px",
                                      position: "absolute",
                                      color: "#10b759",
                                      fontSize: "9px",
                                    }}
                                  ></i>
                                )}
                                {user.CurrentLoggedInTenant_Name && (
                                  <span style={{ fontSize: "10px" }}>
                                    ({user.CurrentLoggedInTenant_Name})
                                  </span>
                                )}
                              </span>
                              <span className="ot-time">
                                {" "}
                                <span>Usually</span>{" "}
                                {user.UserStartTime_EndTimeVM &&
                                user.UserStartTime_EndTimeVM
                                  .Availibility_Time !== ""
                                  ? moment(
                                      user.UserStartTime_EndTimeVM.Availibility_Time.substr(
                                        0,
                                        5
                                      ),
                                      "HH:mm"
                                    )
                                      .tz(user.TZ)
                                      .format("HH:mm") +
                                    " - " +
                                    moment(
                                      user.UserStartTime_EndTimeVM.Availibility_Time.substr(
                                        user.UserStartTime_EndTimeVM
                                          .Availibility_Time.length - 5
                                      ),
                                      "HH:mm"
                                    )
                                      .tz(user.TZ)
                                      .format("HH:mm")
                                  : ""}
                              </span>
                              <span
                                className={`time-zone ${
                                  time.slice(0, 5).replace(":", "") <=
                                    user.UserStartTime_EndTimeVM.Availibility_Time.substr(
                                      user.UserStartTime_EndTimeVM
                                        .Availibility_Time.length - 5
                                    ).replace(":", "") &&
                                  time.slice(0, 5).replace(":", "") >=
                                    user.UserStartTime_EndTimeVM.Availibility_Time.substr(
                                      0,
                                      5
                                    ).replace(":", "")
                                    ? "day-time"
                                    : "night-time"
                                }`}
                              >
                                {" "}
                                <Moment tz={user.TZ} format="HH:mm">
                                  {this.state.date}
                                </Moment>{" "}
                                <img src={watchIcon} alt="watch" />
                              </span>
                              <div className="card-img">
                                <img
                                  src={pcIcon}
                                  className="img-fluid user-desk-img"
                                  alt="PC"
                                />

                                <AvatarImage
                                  profilePic={profilePic}
                                  className=""
                                />
                              </div>
                              <strong className="name-time">
                                {user.Real_Name}{" "}
                              </strong>
                            </div>
                          </ContextMenuTrigger>
                        </div>
                      </div>
                    );
                  })}

              {/* </ItemsCarousel> */}
              {/* </Carousel> */}
            </Whirligig>
            {this.props.numberOfCard > 2 ? (
              <button className="w_slide w_slide_next" onClick={next}>
                <i class="fas fa-chevron-right"></i>
              </button>
            ) : (
              ""
            )}

            {/* </Slider> */}

            {/* </LoadingOverlay> */}
          </>
        ) : (
          <LoadingOverlay active={true} spinner text="Loading...">
            <div className="user-card-list user-cabin">
              <div className="slide" style={{ height: "225px" }}>
                <div style={{ marginTop: "6%" }}>
                  <center>No User Present</center>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        )}

        {this.state.userId !==
        JSON.parse(localStorage.getItem("userdetail")).Id_User ? (
          <ContextMenuOption
            userId={this.state.userId}
            Is_DoorOpen={this.state.isUserDoorOpen}
            onSticktyNoteSelected={this.onSticktyNoteSelected}
            onKnockSelected={this.onKnockSelected}
            onMakeAdminSelected={this.onMakeAdminSelected}
            handleAddChat={this.props.handleAddChat}
            userStatus={this.state.userStatus}
            userDetails={this.state.userDetails}
            getUserFromList={this.props.getUserFromList}
            showScheduleMeetingOption={this.state.showScheduleMeetingOption}
          />
        ) : null}
        {this.state.sticktyNoteSelected ? (
          <StickyNotes
            //stickyMessageSend={this.stickyMessageSend}
            //stickyNotesMessage={this.state.stickyNotesMessage}
            //stickyNotesMessageSend={this.stickyNotesMessageSend}
            stickyNoteIsOpen={this.state.stickyNoteIsOpen}
            toggleSticky={this.toggleCloseSticky}
            personId={this.state.personId}
          />
        ) : null}
      </div>
    );
  }
}
// Prop types of props.
Slider.propTypes = {
  API_HOST: PropTypes.string,
};
const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
  userListDepartmentFilterResponse:
    state.mainOfficeReducer.userListDepartmentFilterResponse,
  userKnockListCabinWiseResponse:
    state.mainOfficeReducer.userKnockListCabinWiseResponse,
  stickyCountResponse: state.mainOfficeReducer.stickyCountResponse,
  getUserDetailResponse:
    state.dashboardReducer.getUserDetailResponse.userDetailResponse,
  getOfficesResponse:
    state.officeReducer.fetchOfficeResponse.fetchOfficeResponse,
});
export default connect(mapStateToProps, {
  getUserDetail,
  getUserListOfDepartmentWithCallback,
  doorClose,
  doorOpen,
  saveUserKnock,
  stickyMessageSend,
  userKnockListCabinWise,
  stickyCount,
  userKnockSeen,
  userStickySeen,
  makeAdmin,
  setUserCustomStatus,
  fetchOffice,
})(Slider);
