import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { types as T } from './action';
import {
  handleError,
  handleResponse,
  checkClientErrorStatus,
  getConfig,
} from '../utils/utility';
import { socket } from '../utils/apiUrls';
import { toast } from 'react-toastify';

// Slack Team
export const receiveSlackTeam = (slackTeamResponse) => ({
  type: T.RECEIVE_SLACK_TEAM,
  slackTeamResponse,
});

/**
 * Fetch all sites.
 *
 * @param {url} URL is API host domain.
 * @return {array} return all sites
 */
export const fetchSlackTeam = (url, showChannels, callback) => (dispatch) => {
  // var userData = JSON.parse(localStorage.getItem('userdetail'));
  // var userAdmin = userData.Is_Admin;

  // let finalUrl = ""
  // if (userAdmin) {
  //   finalUrl = `${url}channel/getchannelslist`;
  // }
  // else {
  //   finalUrl = `${url}channel/getchannelslistuserwise`;
  // }

  const finalUrl = `${url}channel/getchannelslistuserwise/${showChannels}`;

  //return trackPromise(
  axios
    .get(finalUrl, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveSlackTeam(result));
      if (result) {
        callback(result);
      }
    })
    .catch((error) => {
      handleError(error, 'Cannot get Slack Team');
    }); //);
};

// Slack Member

export const receiveSlackMember = (slackMemberResponse) => ({
  type: T.RECEIVE_SLACK_MEMBER,
  slackMemberResponse,
});

export const fetchSlackMember = (url, id) => (dispatch) => {
  const finalUrl = `${url}channeluser/getuserlistchannelwise/${id}`;
  return trackPromise(
    axios
      .post(finalUrl, {}, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => dispatch(receiveSlackMember(result)))
      .catch((error) => handleError(error, 'test'))
  );
};

//Get Slack users list

export const receiveSlackUser = (slackUserResponse) => ({
  type: T.RECEIVE_SLACK_USER,
  slackUserResponse,
});

/**
 * Fetch all users.
 *
 * @param {url} URL is API host domain.
 * @return {array} return all sites
 */

export const fetchSlackUser = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}usertenant/getuserlist`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
          handleError(result, 'Failed to show User list');
        } else {
          dispatch(receiveSlackUser(result));
        }
      })
      .catch((error) => handleError(error, 'Failed to show User list'))
  );
};

// userprofile
export const requestGetUserDetail = () => ({
  type: T.REQUEST_GET_USER_DETAIL,
});

export const receiveGetUserDetail = (userDetailResponse) => ({
  type: T.RECEIVE_GET_USER_DETAIL,
  userDetailResponse,
});

/**
 * Get User Details
 *
 * @param {url} URL is API host domain.
 * @param {userId} is userid for fetching user details of this userId.
 * @return {object} return user details.
 */
export const getUserDetail = (url, userId) => (dispatch) => {
  dispatch(requestGetUserDetail());
  const finalUrl = `${url}usertenant/getuserdetailsbyuserid/${userId}`;

  //return trackPromise(
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (result.id !== 0) {
        dispatch(receiveGetUserDetail(result));
      }
    })
    .catch((error) => {
      handleError(error, 'error');
    });
  //);
};

//Update User

export const receiveUpdateUser = (updateUserResponse) => ({
  type: T.RECEIVE_UPDATE_USER,
  updateUserResponse,
});

/**
 * Update User.
 *
 * @param {url} URL is API host domain.
 * @return {array} return all sites
 */

export const updateUser = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}usertenant/updateuser`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
          handleError(result, 'Failed to Update User');
        } else {
          dispatch(receiveUpdateUser(result));
        }
      })
      .catch((error) => handleError(error, 'Failed Update User'))
  );
};

export const receiveImportUser = (importUserListResponse) => ({
  type: T.RECEIVE_IMPORT_USER_LIST,
  importUserListResponse,
});

/**
 * Fetch all sites.
 *
 * @param {url} URL is API host domain.
 * @return {array} return all sites
 */
export const importUserList = (url) => (dispatch) => {
  const finalUrl = `${url}slack/importuserlist`;

  return trackPromise(
    axios
      .post(finalUrl, {}, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => dispatch(receiveImportUser(result)))
      .catch((error) => handleError(error, 'User list not imported '))
  );
};

// get Tenant details

export const receiveGetTenantDetail = (tenantDetailResponse) => ({
  type: T.RECEIVE_GET_TENANT_DETAIL,
  tenantDetailResponse,
});

/**
 * Get Tenant Details
 *
 * @param {url} URL is API host domain.
 * @param {userId} is userid for fetching user details of this userId.
 * @return {object} return user details.
 */
export const getTenantDetail = (url, userId) => (dispatch) => {
  const finalUrl = `${url}tenantmaster/gettenantbyid/${userId}`;
  return trackPromise(
    axios
      .post(finalUrl, {}, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (result.id !== 0) {
          dispatch(receiveGetTenantDetail(result));
        }
      })
      .catch((error) => {
        handleError(error, 'error');
      })
  );
};

//Update Tenant
export const receiveUpdateTenant = (updateTenantResponse) => ({
  type: T.RECEIVE_UPDATE_TENANT,
  updateTenantResponse,
});

/**
 * Update Tenant.
 *
 * @param {url} URL is API host domain.
 * @return {array} return all sites
 */

export const updateTenant = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}tenant/updatetenant`;

  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
          handleError(result, 'Failed to Update');
        } else {
          dispatch(receiveUpdateTenant(result));
        }
      })
      .catch((error) => handleError(error, 'Failed Update'))
  );
};

//Update Tenant
export const receiveUpdateProfilePic = (updateProfilePicResponse) => ({
  type: T.RECEIVE_UPDATE_PROFILE_PIC,
  updateProfilePicResponse,
});

/**
 * Update user profile pic.
 *
 * @param {url} URL is API host domain.
 */

export const updateProfilePic = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}usertenant/updateuserprofile_pic`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
          handleError(result, 'Failed to Update');
        } else {
          dispatch(receiveUpdateTenant(result));
        }
      })
      .catch((error) => handleError(error, 'Failed Update'))
  );
};

export const receiveChangePassword = (changePasswordResponse) => ({
  type: T.RECEIVE_CHANGE_PASSWORD,
  changePasswordResponse,
});

/**
 * Update user password.
 *
 * @param {url} URL is API host domain.
 */

export const changePassword = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}UserTenant/ChangePassword`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
          handleError(result, 'Failed to Change password');
        } else {
          dispatch(receiveChangePassword(result));
        }
      })
      .catch((error) => handleError(error, 'Failed to change password '))
  );
};

export const getCabinUserDetail = (url, id, callback) => (dispatch) => {
  const finalUrl = `${url}cabinuserdetail/getcabinsuserdetailuserwise/${id}`;
  return trackPromise(
    axios
      .post(finalUrl, {}, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!result) {
          if (JSON.parse(localStorage.getItem('userdetail')).Id_User !== id) {
            toast.error('User not available in any Cabin');
          }
          callback(result);
        } else {
          callback(result);
        }
      })
      .catch((error) => handleError(error, 'User cabin details not found'))
  );
};

export const receiveGetUserStickyList = (getUserStickyListResponse) => ({
  type: T.RECEIVE_GET_USER_STICKY_LIST,
  getUserStickyListResponse,
});

/**
 * Get User Sticky List.
 *
 * @param {url} URL is API host domain.
 */

export const getUserStickyList = (url, userId, callback) => (dispatch) => {
  const finalUrl = `${url}Userstickynote/getuserstickynotelistreceiveruseridwise/0`;
  //return trackPromise(
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (checkClientErrorStatus(result)) {
        handleError(result, 'Failed to Change password');
      } else {
        dispatch(receiveGetUserStickyList(result));
        if (!!callback) {
          callback(result);
        }
      }
    })
    .catch((error) => handleError(error, 'Failed to change password ')); //);
};

//set read user sticky note
export const receiveSetReadUserSticky = (setReadUserStickyResponse) => ({
  type: T.RECEIVE_SET_READ_USER_STICKY_NOTE,
  setReadUserStickyResponse,
});

/**
 * Get User Sticky List.
 *
 * @param {url} URL is API host domain.
 */

export const setReadUserSticky = (url, stickyNoteId, callback) => (
  dispatch
) => {
  const finalUrl = `${url}Userstickynote/setreaduserstickynote/${stickyNoteId}`;
  //return trackPromise(
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (checkClientErrorStatus(result)) {
        handleError(result, 'Failed to set read sticky note');
      } else {
        dispatch(receiveSetReadUserSticky(result));
        if (!!callback) {
          callback(result);
        }
      }
    })
    .catch((error) => handleError(error, 'Failed to set read sticky note')); //);
};

export const readUserSticky = (url, stickyNoteId) => (dispatch) => {
  const finalUrl = `${url}Userstickynote/setreaduserstickynote/${stickyNoteId}`;

  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {})
    .catch((error) => handleError(error, 'Failed to set read sticky note'));
};

//Get search users list

export const receiveSearchUser = (searchUserResponse) => ({
  type: T.RECEIVE_SEARCH_USER_OFFICE,
  searchUserResponse,
});

/**
 * Fetch all users.
 *
 * @param {url} URL is API host domain.
 * @return {array} return all users with department
 */

export const fetchSearchUser = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}usertenant/getuserselectionlistwithdepartment`;
  //return trackPromise(
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (checkClientErrorStatus(result)) {
        handleError(result, 'Failed to fetch search Users list');
      } else {
        dispatch(receiveSearchUser(result));
      }
    })
    .catch((error) => {
      handleError(error, 'Failed to fetch search User list');
    });
  //);
};

//Add sticky notes to TO DO List.
export const receiveAddStickyNotesToToDo = (addStickyNotesToToDoResponse) => ({
  type: T.RECEIVE_ADD_STICKY_NOTES_TO_TODO,
  addStickyNotesToToDoResponse,
});

/**
 * Add sticky notes to TO DO List.
 *
 * @param {url} URL is API host domain.
 */

export const addStickyNotesToToDo = (url, stickyNoteId, callback) => (
  dispatch
) => {
  const finalUrl = `${url}Usertodo/saveusertodofromstickynote/${stickyNoteId}`;
  //return trackPromise(
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (checkClientErrorStatus(result)) {
        handleError(result, 'Failed to add sticky note in To Do List');
      } else {
        dispatch(receiveAddStickyNotesToToDo(result));
        if (!!callback) {
          callback(result);
        }
      }
    })
    .catch((error) =>
      handleError(error, 'Failed to add sticky note in To Do List')
    ); //);
};

//Get Department List by user
export const receiveDepartmentListByUser = (departmentListByUserResponse) => ({
  type: T.RECEIVE_DEPARTMENT_LIST_BY_USER,
  departmentListByUserResponse,
});

/**
 * Get Department List by user
 *
 * @param {url} URL is API host domain.
 * @return {array} return cabin detail
 */
export const departmentListByUser = (url, user_id) => (dispatch) => {
  const finalUrl = `${url}department/getdepartmentsellistbyuserid/${user_id}`;
  //return trackPromise(
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveDepartmentListByUser(result));
    })
    .catch((error) => handleError(error, 'error'));
  //);
};

//Get User List by Department
export const receiveUserListByDepartment = (userListByDepartmentResponse) => ({
  type: T.RECEIVE_USER_LIST_BY_DEPARTMENT,
  userListByDepartmentResponse,
});

/**
 * Get User List by Department
 * @param {url} URL is API host domain.
 * @return {array} return cabin detail
 */
export const userListByDepartment = (url, dept_id) => (dispatch) => {
  const finalUrl = `${url}usertenant/getuserselectionlistwithdepartmentwithdepartmentid/${dept_id}`;
  //return trackPromise(
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveUserListByDepartment(result));
    })
    .catch((error) => handleError(error, 'error'));
  //);
};

//Get Slack Configuration List
export const receiveSlackConfigurationlist = (slackConfigListResponse) => ({
  type: T.RECEIVE_SLACK_CONFIG_LIST,
  slackConfigListResponse,
});
/**
 * GET Slack Configuration List
 *
 * @param {url} URL is API host domain.
 *
 */
export const getSlackConfigurationlist = (url, callback) => (dispatch) => {
  const finalUrl = `${url}Slack/Getslackconfigurationlist`;
  //return trackPromise(
  axios
    .get(finalUrl, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveSlackConfigurationlist(result));
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, 'Cannot get slack configurations')); //);
};

//Get Slack Configuration  before Login
export const receiveSlackConfigurationlistLogin = (
  slackConfigListLoginResponse
) => ({
  type: T.RECEIVE_SLACK_CONFIG_LIST_LOGIN,
  slackConfigListLoginResponse,
});
/**
 * GET Slack Configuration List
 *
 * @param {url} URL is API host domain.
 *
 */
export const getSlackConfigurationlistLogin = (url, callback) => (dispatch) => {
  const finalUrl = `${url}masterauth/getslackconfigurationlist`;
  //const finalUrl = `https://server1.heliosdemo.com:6056/api/masterauth/getslackconfigurationlist`;
  //return trackPromise(
  axios
    .get(finalUrl)
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveSlackConfigurationlistLogin(result));
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) =>
      handleError(
        error,
        'Cannot get slack configurations for signing with Slack'
      )
    ); //);
};

export const slackOauthAccess = (code, clientId, clientSecretId, callback) => (
  dispatch
) => {
  const finalUrl = `https://slack.com/api/oauth.v2.access?code=${code}&client_id=${clientId}&client_secret=${clientSecretId}&pretty=1&redirect_uri=${window.location.protocol}//${window.location.host}/slacktoken`;
  axios
    .post(finalUrl)
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, 'Failed to access Slack user token'));
};

export const slackOauthAccessAdmin = (
  code,
  clientId,
  clientSecretId,
  callback
) => (dispatch) => {
  const finalUrl = `https://slack.com/api/oauth.v2.access?code=${code}&client_id=${clientId}&client_secret=${clientSecretId}&pretty=1&redirect_uri=${window.location.protocol}//${window.location.host}/importtoken`;
  axios
    .post(finalUrl)
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, 'Failed to access Slack user token'));
};

export const slackOauthAccessLogin = (
  code,
  clientId,
  clientSecretId,
  callback
) => (dispatch) => {
  const finalUrl = `https://slack.com/api/oauth.v2.access?code=${code}&client_id=${clientId}&client_secret=${clientSecretId}&pretty=1&redirect_uri=${window.location.protocol}//${window.location.host}/signingwithslacktoken`;

  axios
    .post(finalUrl)
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) =>
      handleError(
        error,
        'Failed to access Slack user token for login with Slack'
      )
    );
};

export const loginWithSlack = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}masterauth/loginwithslack`;
  //const finalUrl = `https://server1.heliosdemo.com:6056/api/masterauth/loginwithslack`;

  //return trackPromise(
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, 'Failed to  login with Slack')); //);
};

export const updateUserSlackAccessToken = (
  url,
  slack_access_token,
  scope,
  refreshToken,
  slackId,
  callback
) => (dispatch) => {
  const finalUrl = `${url}UserTenant/updateuserslackaccesstoken`;
  const requestBody = {
    Token: slack_access_token,
    Scope_Detail: scope,
    Refresh_Token: refreshToken,
    Id_Slack: slackId,
  };
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) => handleError(error, 'Cannot update user  slack token'))
  );
};

export const updateTenantSlackAccessToken = (
  url,
  tenant_slack_access_token,
  tenant_refresh_token,
  workspaceName,
  workspaceDomain,
  callback
) => (dispatch) => {
  const finalUrl = `${url}Tenant/updatetenantslackuseraccesstoken`;
  const requestBody = {
    Slack_User_Access_Token: tenant_slack_access_token,
    Slack_User_Refresh_Token: tenant_refresh_token,
    Slack_Workspace_Name: workspaceName,
    Name: workspaceDomain,
  };
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) => handleError(error, ' Cannot update tenant slack token'))
  );
};

export const updateUserTimezone = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}usertenant/updateusertz`;
  //return trackPromise(
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, 'Failed to  update user timezone')); //);
};

//Add Virtual Chat Group to Slack Team List
export const receiveAddVirtualChatGroup = (addVirtualChatGroupResponse) => ({
  type: T.RECEIVE_ADD_VIRTUAL_CHAT_GROUP,
  addVirtualChatGroupResponse,
});

/**
 * Add Virtual Chat Group to Slack Team List
 *
 * @param {url} URL is API host domain.
 *
 */

export const addVirtualChatGroup = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}channel/saveslackvchannel`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
          handleError(
            result,
            ' Failed to add new group to the Slack team list'
          );
        } else {
          dispatch(receiveAddVirtualChatGroup(result));
        }
      })
      .catch((error) =>
        handleError(error, ' Failed to add new group to the Slack team list')
      )
  );
};

export const checkChannelAlreadyExist = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}ChannelUser/validatechannelusersforexistingchannel`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) =>
        handleError(error, 'Cannot check channel already exist or not')
      )
  );
};

//Get user leave detail for broadcast
export const receiveUserLeaveDetail = (userLeaveDetailResponse) => ({
  type: T.RECEIVE_USER_LEAVE_DETAIL,
  userLeaveDetailResponse,
});

/**
 * Get user leave detail for broadcast.
 *
 * @param {url} URL is API host domain.
 * @return {array} return user leave detail.
 */
export const getUserLeaveDetail = (url, callback) => (dispatch) => {
  const finalUrl = `${url}useravailibility/getuserleavedetailforbroadcast`;

  //return trackPromise(
  axios
    .get(finalUrl, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveUserLeaveDetail(result));
      if (result) {
        callback(result);
      }
    })
    .catch((error) => {
      // if (error) {
      //   callback({ error: 'error' })
      // }
      handleError(error, ' Cannot get user leave detail for broadcast');
    });
};

//Get Username by Userid
export const receiveUserNameByUserId = (userNameByUserIdResponse) => ({
  type: T.RECEIVE_USERNAME_BY_USERID,
  userNameByUserIdResponse,
});

/**
 * Get Username by Userid
 *
 * @param {url} URL is API host domain.
 * @param {userId} is userid for fetching user name of this userId.
 * @return {object} return user name.
 */
export const getUserNameByUserId = (url, requestBody, callback) => (
  dispatch
) => {
  const finalUrl = `${url}usertenant/getusernamebyuserids`;
  // return trackPromise(
  axios
    .post(finalUrl, requestBody, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      dispatch(receiveUserNameByUserId(result));
      if (result) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Cannot get user name by user id')); //);
};

/*********************************Multiple Workspace selection********************************************/

export const receiveMultipleEmail = (multipleEmailResponse) => ({
  type: T.RECEIVE_MULTIPLE_EMAIL,
  multipleEmailResponse,
});

/**
 * Email registered for more than one workspace.
 *
 * @param {url} URL is API host domain.
 * @param {email} is email of the user
 *
 */

export const fetchMultipleEmail = (url) => (dispatch) => {
  const finalUrl = `${url}tenantmaster/GetTenantDomainListUserWise`;

  return trackPromise(
    axios
      .get(finalUrl, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
          handleError(result, 'Registered Multiple Emails');
        } else {
          dispatch(receiveMultipleEmail(result));
        }
      })
      .catch((error) => handleError(error, 'Registered Multiple Emails'))
  );
};

//Change Workspace
export const receiveChangeWorkspace = (changeWorkspaceResponse) => ({
  type: T.RECEIVE_CHANGE_WORKSPACE,
  changeWorkspaceResponse,
});

/**
 * Change Workspace
 *
 * @param {url} URL is API host domain.
 * @return {array} selected workspace details
 */

export const changeWorkspace = (url, requestBody) => (dispatch) => {
  const finalUrl = `${url}tenantmaster/changeworkspace`;
  return trackPromise(
    axios
      .post(finalUrl, requestBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
          handleError(result, ' Failed to change workspace');
        } else {
          dispatch(receiveChangeWorkspace(result));
          //
          if (result.TokenData && result.TokenData.Token) {
            localStorage.removeItem('userdetail');
            localStorage.setItem('userdetail', JSON.stringify(result.UserData));
            sessionStorage.setItem(
              'userToken',
              JSON.stringify(result.TokenData.Token).replace(/"/g, '')
            );
            socket.emit('UserDetail', result.UserData);
          }
          //
        }
      })
      .catch((error) => handleError(error, ' Failed to change workspace'))
  );
};

//Import Steps tracing
export const importSlackStepsTrace = (url, callback) => (dispatch) => {
  const finalUrl = `${url}tenantmaster/gettenantimportstatus`;

  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) =>
      // handleError(error, ' Failed to trace import process for Slack. ')
      console.log(error)
    );
};

//Get tenant Slack user access token
export const receiveTenantSlackUserAccessToken = (
  getTenantSlackUserAccessTokenResponse
) => ({
  type: T.RECEIVE_TENANT_SLACK_USER_ACCESS_TOKEN,
  getTenantSlackUserAccessTokenResponse,
});
/**
 * Get tenant Slack user access token
 *
 * @param {url} URL is API host domain.
 *
 */

export const getTenantSlackUserAccessToken = (url, callback) => (dispatch) => {
  const finalUrl = `${url}tenant/gettenantslackuseraccesstoken`;

  axios
    .get(finalUrl, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      dispatch(receiveTenantSlackUserAccessToken(result));
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) =>
      handleError(error, ' Failed to get tenant slack user access token. ')
    );
};

//Delete Precis Account
export const deleteUserAccount = (url, callback) => (dispatch) => {
  const finalUrl = `${url}usertenant/deleteuser`;

  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => {
      return handleResponse(response);
    })
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch((error) => handleError(error, ' Failed to delete Precis account'));
};

export const syncWorkspace = (url, callback) => (dispatch) => {
  const finalUrl = `${url}slack/syncslackdatawithprecis`;

  return trackPromise(
    axios
      .get(finalUrl, getConfig())
      .then((response) => {
        return handleResponse(response);
      })
      .then((result) => {
        if (!!callback) {
          callback(result);
        }
      })
      .catch((error) => handleError(error, ' Failed to Sync Workspace'))
  );
};

//create department wise chat group
export const createDepartmentChat = (url, deptId, callback) => (dispatch) => {
  const finalUrl = `${url}channel/CreateChannelWithDepartmentUsers/${deptId}`;
  //return trackPromise(
  axios
    .post(finalUrl, {}, getConfig())
    .then((response) => handleResponse(response))
    .then((result) => {
      if (!!callback) {
        callback(result);
      }
    })
    .catch(
      (error) => handleError(error, 'Failed to create a department chat group')
      //  toast.info("Channel already created")
    ); //);
};
