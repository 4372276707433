import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  NavLink
} from "reactstrap";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import AvtarProfile from "./AvtarProfile";
import EditMyProfile from "./EditMyProfile";
import ChangeTimeZone from "./ChangeTimeZone";
import Account from "./Account";
//import WorkingHours from './WorkingHours';
import classnames from "classnames";
import GoogleCalendar from "../../../components/google_calendar/GoogleCalendar";
import GoogleCalendarMetting from "../../../components/google_calendar/GoogleCalendarMeeting";
import AddToSlack from "../../chat/components/AddToSlack";
import JiraConfiguration from "../../jira/components/JiraConfiguration";
import GoogleConfiguration from '../../../components/google_calendar/GoogleConfiguration';
import ZoomConfiguration from './ZoomConfiguration';
class MoreInfo extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      error: {},
      userDetails: null,
      src: "",
      myAvatar: {},
      activeTab: "5"
    };
  }
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.myAvatar !== this.props.myAvatar) {
      this.setState({ myAvatar: nextProps.myAvatar });
    }
  }

  componentDidMount() {
    this.setState({ myAvatar: this.props.myAvatar });
    if (this.props.isAddToSlack) {
      this.setState({ activeTab: "8" });
    }

    if (this.props.isEditWorkingHours) {
      this.setState({ activeTab: "1" });
    }
    if (this.props.isGoogleCalendarMeeting) {
      this.setState({ activeTab: "4" });
    }
    if (this.props.isConnectToJira) {
      this.setState({ activeTab: "6" });
    }
  }

  onHide = () => {
    this.props.toggleModal();
  };

  render() {
    const { isOpen } = this.props;
    const closeBtn = (
      <button className="close  pull-right" onClick={this.onHide}>
        &times;
      </button>
    );

    return (
      <>
        <Modal isOpen={isOpen} size={"xl"} id="myprofile">
          <ModalHeader close={closeBtn}>Settings</ModalHeader>
          <ModalBody>
            <div className="main-tab">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                {/* <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Edit Profile
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2"
                    })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    Apperance
                  </NavLink>
                </li> */}
                
                {/* <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "4"
                    })}
                    onClick={() => {
                      this.toggle("4");
                    }}
                  >
                    {" "}
                    Meeting
                  </NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "5"
                    })}
                    onClick={() => {
                      this.toggle("5");
                    }}
                  >
                    Account
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Working Hours
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "6"
                    })}
                    onClick={() => {
                      this.toggle("6");
                    }}
                  >
                    {" "}
                    Jira Configuration
                  </NavLink>
                </li>
                {/* added google configuration as removed */}
                <li className="nav-item">
                  <NavLink className={classnames({ active: this.state.activeTab === '7' })}
                    onClick={() => { this.toggle('7'); }}>Google Configuration
                            </NavLink>
                </li>

                {/* <li className="nav-item">
                  <NavLink className={classnames({ active: this.state.activeTab === '9' })}
                    onClick={() => { this.toggle('9'); }}>Zoom Configuration
                            </NavLink>
                </li> */}
                {/* <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "8"
                    })}
                    onClick={() => {
                      this.toggle("8");
                    }}
                  >
                    Slack Configuration
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "9"
                    })}
                    onClick={() => {
                      this.toggle("9");
                    }}
                  >
                    Change Timezone
                  </NavLink>
                </li> */}
              </ul>
              <TabContent activeTab={this.state.activeTab}>
                {/* <TabPane
                  tabId="1"
                  className="tab-pane"
                  id="pills-avtartab-con2"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {this.state.activeTab === "1" && (
                    <EditMyProfile
                      updateUser={this.props.updateUser}
                      userDetails={this.props.userDetails}
                      handleEditProfileChange={
                        this.props.handleEditProfileChange
                      }
                      myAvatar={this.props.myAvatar}
                      changeWorkspace={this.props.changeWorkspace}
                      fetchMultipleEmail={this.props.fetchMultipleEmail}
                      multipleEmailResponse={this.props.multipleEmailResponse}
                      redirectLogin={this.props.redirectLogin}
                    />
                  )}
                </TabPane>
                {this.state.activeTab === "2" && (
                  <TabPane tabId="2">
                    <AvtarProfile
                      onselected={this.props.onselected}
                      myAvatar={this.props.myAvatar}
                    />
                  </TabPane>
                )} */}

                <TabPane
                  tabId="1"
                  className="tab-pane"
                  id="pills-avtartab-con3"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  {this.state.activeTab === "1" && (
                    <GoogleCalendar
                      isEditWorkingHours={
                        this.props.isEditWorkingHours ? true : false
                      }
                      userDetails={this.props.userDetails}
                      userValuesChanges={this.props.userValuesChanges}
                    ></GoogleCalendar>
                  )}
                </TabPane>
                {/* <TabPane
                  tabId="4"
                  className="tab-pane"
                  id="pills-avtartab-con4"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {this.state.activeTab === "4" && (
                    <GoogleCalendarMetting
                      userValuesChanges={this.props.userValuesChanges}
                      userDetails={this.props.userDetails}
                    ></GoogleCalendarMetting>
                  )}
                </TabPane> */}
                <TabPane
                  tabId="5"
                  className="tab-pane"
                  id="pills-avtartab-con5"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  {this.state.activeTab === "5" && (
                    <Account
                      changePassword={this.props.changePassword}
                      changePasswordResponse={this.props.changePasswordResponse}
                      userValuesChanges={this.props.userValuesChanges}
                    />
                  )}
                </TabPane>
                <TabPane
                  tabId="6"
                  className="tab-pane"
                  id="pills-avtartab-con6"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {this.state.activeTab === "6" && <JiraConfiguration isModal={this.props.isModal} />}
                </TabPane>
                <TabPane
                  tabId="7"
                  className="tab-pane"
                  id="pills-avtartab-con6"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {this.state.activeTab === "7" && <GoogleConfiguration
                    isOpen={this.state.isGoogleConfig}
                    toggle={this.toggleGoogleConfig}
                  />}
                </TabPane>

                <TabPane
                  tabId="9"
                  className="tab-pane"
                  id="pills-avtartab-con9"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {this.state.activeTab === "9" && <ZoomConfiguration
                    isOpen={this.state.isZoomConfig}
                    toggle={this.toggleZoomConfig}
                  />}
                </TabPane>

                {/* <TabPane
                  tabId="7"
                  className="tab-pane"
                  id="pills-avtartab-con7"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  furure div content here7
                </TabPane>
                <TabPane
                  tabId="8"
                  className="tab-pane"
                  id="pills-avtartab-con8"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {this.state.activeTab === "8" && <AddToSlack />}
                </TabPane>
                <TabPane
                  tabId="9"
                  className="tab-pane"
                  id="pills-avtartab-con9"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {this.state.activeTab === "9" && (
                    <ChangeTimeZone
                      userDetails={this.props.userDetails}
                      //updateUserTimezone={this.props.updateUserTimezone}
                      userValuesChanges={this.props.userValuesChanges}
                      fetchSearchUser={this.props.fetchSearchUser}
                    />
                  )}
                </TabPane> */}
              </TabContent>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

MoreInfo.defaultProps = {};

MoreInfo.propTypes = {
  hairs: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  myAvatar: PropTypes.object,
  onselected: PropTypes.func,
  updateUser: PropTypes.func,
  userDetails: PropTypes.object,
  changePassword: PropTypes.func,
  changePasswordResponse: PropTypes.bool
};

export default hot(module)(MoreInfo);
