import axios from "axios";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";


export const zoomMeetingJoinLeave = (url, reqBody, callback) => (dispatch) => {

    const finalUrl = `${url}UserMeeting/setmeeting_actualtime?Meeting_Number=${reqBody.Meeting_Number}&IsJoining=${reqBody.IsJoining}`;
  
    axios.post(finalUrl, {}, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to Join & Leave Zoom Meeting')
        )
  }

// Get Zoom Configuration
export const getZoomConfig = (url, callback) => (dispatch) => {
    const finalUrl = `${url}ZoomAccount/getzoomconfigurationlist`;
    // return trackPromise(
    axios.get(finalUrl, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to get Zoom configuration list')
        )
    // );
}


// Get User Zoom Details, if token expired or not
export const getUserZoomTokenDetails = (url, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/getuserzoomtokendetails`;
    // return trackPromise(
    axios.get(finalUrl, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to get user Zoom token details')
        )
    // );
}


// Update Zoom Token Details
export const updateUserZoomTokenDetails = (url, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/updateuserzoomtokendetails`;
    // return trackPromise(
    axios.post(finalUrl, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to update Zoom token details')
        )
    // );
}