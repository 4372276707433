import axios from "axios";
import { types as T } from "./action";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

export const saveWorkingHours = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}useravailibility/saveuseravailibility`;
    
    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to save working hours')
        )
}

// Get Departments
export const receiveUserAvailabilityList = (getUserAvailabilityListResponse) => ({
    type: T.RECEIVE_USER_AVAILABILITY_LIST,
    getUserAvailabilityListResponse,
});

/**
 * Get Working Hours Availability
 *
 * @param {url} URL is API host domain.
 * @return {array} return working hours availability
 */
export const getUserAvailabilityList = (url,callback) => (dispatch) => {
    const finalUrl = `${url}useravailibility/getuseravailibilitiesuseridwise/0`;
    axios
        .post(finalUrl, {},getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            dispatch(receiveUserAvailabilityList(result));
            if(!!callback){
                callback(result)
            }
        })
        .catch((error) =>
            handleError(error, "Cannot get Working Hours Availablity")
        )
};

export const deleteUserAvailabilityList = (url,workingHoursId,callback) => (dispatch) => {
    const finalUrl = `${url}useravailibility/deleteuseravailibility/${workingHoursId}`;
    
    axios.post(finalUrl, {}, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to delete working hours')
        )
}

export const getUserAvailabilityBetweenDates = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}useravailibility/getuseravailibilitiesbetweendate`;
    
    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to get working hours between given dates')
        )
}
