import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getApiHost } from "../../../utils/apiUrls";
import {
  FormGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from "reactstrap";
import "react-table-6/react-table.css";
import { toast } from "react-toastify";
import {
  getEncryptedValue,
  getDecryptedValue,
} from "../../../utils/utility";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ClockLoader from "react-spinners/ClockLoader";
import {
  getJiraDomain,
  getUserJiraRefreshToken,
  getJiraConfig,
  updateUserJiraRefreshToken,
} from "../../../actions/jiraActions";
import {
  getPrivateJiraProjectList,
  getPrivateJiraTaskList,
  addCommentforPrivateJiraIssue,
} from "../../../actions/privateJiraActions";

const axios = require("axios");

class AddChatToExistingJira extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cloudId: "",
      accessToken: "",
      refreshToken: "",
      jiraClientId: "",
      jiraClientSecret: "",
      JiraTaskList: [],
      jiraDomain: [],
      jiraDomainSelected: {},
      isCreateDisabled: false,
      description: "",
      projectList: [],
      projectSelected: null,
      backlogIssuesTaskList: [],
      taskSelected: {},
      isPrivateJiraSelected: false,
    };
  }

  async componentDidMount() {
    await this.getJIRAConfig();
    this.getJiraDomain();

    let taskSelected = {
      id: 0,
      key: "",
      value: "",
      summary: "Select Tasks",
      label: <div>{"     Select Tasks"}</div>,
    };

    await this.setState({ taskSelected: taskSelected });
  }

  getJIRAConfig = () => {
    const { API_HOST, getJiraConfig } = this.props;
    getJiraConfig(API_HOST, (res) => {
      if (res[0]) {
        this.setState({
          jiraClientId: getDecryptedValue(res[0].Client_ID),
          jiraClientSecret: getDecryptedValue(res[0].Client_Secret),
        });

        this.getRefreshToken();
      } else {
        this.setState({ jiraClientDisable: true });
      }
    });
  };

  getJiraDomain = async () => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, (res) => {
      if (res[0]) {
        let newValue = res.map(function (obj) {
          return {
            id: getDecryptedValue(obj.CloudId),
            CloudId: getDecryptedValue(obj.CloudId),
            Domain_Name: obj.Domain_Name,
            key: obj.Domain_Name,
            url: obj.url,
            Jira_Scope_Detail: obj.Jira_Scope_Detail,
            Is_Private: obj.Is_Private,
            label: obj.Domain_Name,
            value: getDecryptedValue(obj.CloudId),
          };
        });
        this.setState({
          jiraDomain: newValue,
          jiraDomainSelected: newValue[0],
          cloudId: newValue[0].CloudId,
        });

        if (res[0].Is_Private) {
          this.setState({ isPrivateJiraSelected: true });
          this.getPrivateJiraProjectList();
        } else {
          this.getprojectsList();
        }
      }
    });
  };

  onSelectDomain = (newValue) => {
    if (newValue.Is_Private) {
      this.setState({
        isPrivateJiraSelected: true,
        jiraDomainSelected: newValue,
        projectSelected: null,
      });
      this.getPrivateJiraProjectList();
    } else {
      this.setState({
        isPrivateJiraSelected: false,
        jiraDomainSelected: newValue,
        cloudId: newValue.CloudId,
        projectSelected: null,
      });
      this.getprojectsList();
    }
  };

  onSelectprojects = async (newValue) => {
    await this.setState({ projectSelected: newValue });

    if (this.state.isPrivateJiraSelected) {
      this.getPrivateJiraBacklogIssue(newValue.key);
    } else {
      this.jiraBacklogIssue(newValue);
    }
  };

  onSelectTasks = async (newValue) => {
    this.setState({ taskSelected: newValue });
  };

  getprojectsList = async () => {
    await this.setState({ projectList: [] });
    try {
      let finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/project/search?expand=lead%2Curl%2Cfavourite%2Cpermissions&maxResults=50&orderBy=%2BNAME&startAt=0`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ projectList: data.values });
    } catch (error) {
      // if (error.response.status === 401) {
      //   let gettoken = await this.props.getAccessTokenAgain();
      //   this.setState({ accessToken: gettoken })
      //   this.getprojectsList()
      // }
      //console.log(error);
      toast.error(error);
    }
  };

  jiraBacklogIssue = async (newValue) => {
    this.setState({ loading: true });
    try {
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/search?jql=project='${newValue.key}'AND status IN ('To Do','In Progress')&fields=assignee,comment,created,creator,description,issuelinks,duedate,issuetype,parent,priority,progress,project,reporter,status,subtasks,summary`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });

      this.setState({ backlogIssuesTaskList: data.issues });
    } catch (error) {
      // if (error.response.status === 401) {
      //   let gettoken = await this.props.getAccessTokenAgain();
      //   this.setState({ accessToken: gettoken });
      //   this.jiraBacklogIssue(newValue);
      // }
      toast.error(error);
    }
    this.setState({ isActive: false, loading: false });
  };

  getRefreshToken = () => {
    const { API_HOST, getUserJiraRefreshToken } = this.props;

    getUserJiraRefreshToken(API_HOST, (res) => {
      if (res.Jira_Token) {
        this.setState({ refreshToken: res.Jira_Token });
        this.getAccessToken(res.Jira_Token);
        this.setState({ jiraClientDisable: false, isActive: false });
      } else {
        this.setState({ jiraClientDisable: true, isActive: false });
      }
    });
  };

  getAccessToken = async (refreshToken) => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: getDecryptedValue(refreshToken),
      };

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });
      this.setState({
        accessToken: data.data.access_token,
        refreshToken: data.data.refresh_token,
      });

      await this.updateUserJiraRefreshToken(data.data.refresh_token);
      await this.getJiraYourTaskList(
        this.state.cloudId,
        data.data.access_token
      );
    } catch (error) {
      toast.error(
        "Refresh token is expired.Please reconnect your application with your JIRA"
      );
      this.setState({ isActive: false });
    }
  };

  updateUserJiraRefreshToken = async (refreshToken) => {
    const { API_HOST, updateUserJiraRefreshToken } = this.props;

    let requestBody = {
      Jira_Token: getEncryptedValue(refreshToken),
    };

    await updateUserJiraRefreshToken(API_HOST, requestBody);
  };

  getAccessTokenAgain = async () => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: this.state.refreshToken,
      };

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });

      // this.setState({ accessToken: data.data.access_token })
      return data.data.access_token;
    } catch (error) {
      toast.error(
        "Refresh token is expired.Please reconnect your application with your JIRA"
      );
      this.setState({ isActive: false });
    }
  };

  getJiraYourTaskList = async (cloudId, token) => {
    try {
      const finalUrl = `https://api.atlassian.com/ex/jira/${cloudId}/rest/api/2/search?jql=assignee=currentuser()AND status IN ('To Do','In Progress')&maxResults=500&order%20by%20created&fields=assignee,comment,created,creator,description,issuelinks,duedate,issuetype,parent,priority,progress,project,reporter,status,subtasks,summary`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({
        JiraTaskList: data.issues,
        selectedPageSize: 10,
        selectedPage: 0,
      });
    } catch (error) {
      toast.error(error);
    }
    this.setState({ isActive: false, loading: false });
  };

  addCommentToJiraTask = () => {
    if (this.state.isPrivateJiraSelected) {
      this.addCommentToPrivateJiraIssue();
    } else {
      this.addCommentToPublicJiraIssue();
    }
  };

  addCommentToPrivateJiraIssue = async () => {
    this.setState({ isCommentsDisabled: true });
    const { API_HOST, addCommentforPrivateJiraIssue } = this.props;

    let requestBody = {
      Jira_Task_Key: this.state.taskSelected.key,
      DomainName: this.state.jiraDomainSelected.Domain_Name,
      CommentBody: this.props.addDescription
        ? this.props.addDescription
        : this.state.description,
    };

    await addCommentforPrivateJiraIssue(API_HOST, requestBody, (res) => {
      if (res) {
        this.props.closeAddChatTaskPopup();
        this.setState({ isCommentsDisabled: false, comments: "" });
      }
    });
  };

  addCommentToPublicJiraIssue = async () => {
    try {
      await this.setState({ isCommentsDisabled: true });

      const finalUrl =
        `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/issue/` +
        this.state.taskSelected.key +
        "/comment";

      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      let resource = {
        body: this.props.addDescription
          ? this.props.addDescription
          : this.state.description,
      };
      const { data } = await axios.post(finalUrl, resource, { headers });
      if (data !== null) {
        this.props.closeAddChatTaskPopup();
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //   let gettoken = await this.props.getAccessTokenAgain();
      //   this.setState({ accessToken: gettoken })
      //   this.addCommentToJiraIssue()
      // }
      toast.error(error);
    }
    await this.setState({ isCommentsDisabled: false, comments: "" });
  };

  getPrivateJiraProjectList = async () => {
    await this.setState({ projectList: [] });

    const { API_HOST, getPrivateJiraProjectList } = this.props;
    let requestBody = {
      DomainName: this.state.jiraDomainSelected.Domain_Name,
    };

    await getPrivateJiraProjectList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({ projectList: res });
      }
    });
  };

  getPrivateJiraBacklogIssue = (projectKey) => {
    this.setState({ backlogIssuesTaskList: [] });
    const { API_HOST, getPrivateJiraTaskList } = this.props;

    let requestBody = {
      ProjectKey: projectKey,
      DomainName: this.state.jiraDomainSelected.Domain_Name,
      TaskStatusList: null,
    };

    getPrivateJiraTaskList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({
          backlogIssuesTaskList: res,
        });
      }
    });
  };

  render() {
    const { isOpenAddChatTaskPopup } = this.props;

    let projectlistOptions = [];
    this.state.projectList[0] &&
      this.state.projectList.map((obj) => {
        projectlistOptions.push({
          id: obj.id,
          avatarUrls: obj.avatarUrls,
          name: obj.name,
          key: obj.key,
          self: obj.self,
          projectTypeKey: obj.projectTypeKey,
          label: obj.name + "(" + obj.key + ")",
          value: obj.name,
        });
        return projectlistOptions;
      });

    let tasklistOptions = [];
    this.state.backlogIssuesTaskList[0] &&
      this.state.backlogIssuesTaskList.map((obj) => {
        tasklistOptions.push({
          id: obj.id,
          key: obj.key,
          value: `${obj.key} : ${obj.fields.summary}`,
          summary: obj.fields.summary,
          label: `${obj.key} : ${obj.fields.summary}`,
        });
        return projectlistOptions;
      });

    return (
      <React.Fragment>
        <Modal isOpen={isOpenAddChatTaskPopup} backdrop={true} size={"lg"}>
          <ModalHeader
            toggle={() => this.props.closeAddChatTaskPopup()}
            className="dept-header"
          >
            Add chat as comment to Jira Task
          </ModalHeader>
          <ModalBody className="dept-class">
            <div>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <h6>Jira Domain</h6>
                    <Select
                      components={makeAnimated}
                      onChange={(newValue) => {
                        this.onSelectDomain(newValue);
                      }}
                      options={this.state.jiraDomain}
                      value={this.state.jiraDomainSelected}
                    />
                  </FormGroup>
                </Col>

                <Col xs="6">
                  <FormGroup>
                    <h6>Project</h6>
                    <Select
                      components={makeAnimated}
                      onChange={(newValue) => {
                        this.onSelectprojects(newValue);
                      }}
                      options={projectlistOptions}
                      value={this.state.projectSelected}
                    />
                  </FormGroup>
                </Col>

                <Col xs="6">
                  <FormGroup>
                    <h6>Tasks</h6>
                    <Select
                      components={makeAnimated}
                      onChange={(newValue) => {
                        this.onSelectTasks(newValue);
                      }}
                      options={tasklistOptions}
                      value={this.state.taskSelected}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <h6>Description</h6>
                <textarea
                  style={{ height: "150px" }}
                  className="form-control"
                  rows="2"
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  value={
                    this.props.addDescription
                      ? this.props.addDescription
                      : this.state.description
                  }
                ></textarea>
              </FormGroup>

              <br />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              disabled={this.state.isCreateDisabled}
              onClick={this.addCommentToJiraTask}
            >
              <span>Add Comment</span>
              {this.state.isCreateDisabled === true && (
                <span style={{ marginLeft: "5px" }}>
                  <ClockLoader
                    size={16}
                    color={"#fff"}
                    loading={this.state.isCreateDisabled}
                  />
                </span>
              )}
            </Button>
            <Button
              color="secondary"
              onClick={() => this.props.closeAddChatTaskPopup()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

// Prop types of props.
AddChatToExistingJira.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
AddChatToExistingJira.defaultProps = {
  updateDepartmentList: () => {},
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
});

export default connect(mapStateToProps, {
  getJiraDomain,
  getJiraConfig,
  getUserJiraRefreshToken,
  updateUserJiraRefreshToken,
  getPrivateJiraProjectList,
  getPrivateJiraTaskList,
  addCommentforPrivateJiraIssue,
})(AddChatToExistingJira);
