import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { slackconfig } from '../../../actions/slackconfigActions';
import { getApiHost } from '../../../utils/apiUrls';

class SlackConfig extends React.Component {
    state = {
      fields: {
        "accesstoken":"xoxp-89655370407-1077187449557-1105709520675-25ef163bda5dbf17bdf8533da4bcc2eb",
         "appid" :"A017KCDHCUE",
        "clientid"  :"1245264436822.1257421590966",
        "clientsecret" :"fbe90225fba6981a4ea3522ab8f28c8b",
        "verificationtoken":"ynBmbJTyl0fkbzup9AtPO5Vx"
      },
      errors: {},
    };
  
    componentDidMount = () => {
      document.title = this.props.title;
    };
  
    componentWillReceiveProps = (nextProps) => {
      if (nextProps.slackconfigResponse !== this.props.slackconfigResponse) {
        
        this.props.history.push("/dashboard");
      }
    };
  
    // Function calls while type and  set their values.
    handleChange = (field, e) => {
      let fields = this.state.fields;
      fields[field] = e.target.value;
      this.setState({ fields });
    };
  
    // Function handles validation and if success then calls slack config method.
    onSubmit = (event) => {
      event.preventDefault();
      
  
      if (this.handleValidation()) {
        this.onSlackConfig();
      }
    };
  
    // Calls Sign up  API to get logged in.
    onSlackConfig = async () => {
      const { API_HOST, slackconfig } = this.props;
    
      const slackconfigRequest = {
        OAuth_Access_Token: this.state.fields["accesstoken"],
        App_ID: this.state.fields["appid"],
        Client_ID: this.state.fields["clientid"],
        Client_Secret: this.state.fields["clientsecret"],
        Verification_Token: this.state.fields["verificationtoken"],
      };
      //console.log("request-------", slackconfigRequest);

      await slackconfig(API_HOST, slackconfigRequest);
      if (this.props.slackconfigResponse) {
      }
    };
  
   

    // Handles validation for required fields.sdfs
    handleValidation() {
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
  
      // accesstoken
      if (!fields["accesstoken"]) {
        formIsValid = false;
        errors["accesstoken"] = "Accesstoken is required";
        errors["accesstokenclass"] = "is-invalid";
      } else {
        formIsValid = true;
        errors["accesstoken"] = "Accesstoken";
        errors["accesstokenclass"] = "is-valid";
      }
  
      // appid
      if (!fields["appid"]) {
        formIsValid = false;
        errors["appid"] = "Appid is required";
        errors["appidclass"] = "is-invalid";
      } else {
        formIsValid = true;
        errors["appid"] = "Appid";
        errors["appidclass"] = "is-valid";
      }
  
      // Client Id
      if (!fields["clientid"]) {
        formIsValid = false;
        errors["clientid"] = "Clientid is required";
        
        errors["clientidclass"] = "is-invalid";
      } else {
        formIsValid = true;
        errors["clientid"] = "Clientid";
        errors["clientidclass"] = "is-valid";
        }
      
  
      if (!fields["clientsecret"]) {
        formIsValid = false;
        errors["clientsecret"] = "clientsecret is required";
        errors["clientsecretclass"] = "is-invalid";
      } else {
        formIsValid = true;
        errors["clientsecret"] = "clientsecret";
        errors["clientsecretclass"] = "is-valid";
      }
  
      if (!fields["verificationtoken"]) {
        formIsValid = false;
        errors["verificationtoken"] = "Verificationtoken is required";
        errors["userconfirmpasswordclass"] = "is-invalid";
      } else {
        formIsValid = true;
        errors["verificationtoken"] = "Verificationtoken";
        errors["userconfirmpasswordclass"] = "is-valid";
      }
  
      this.setState({ errors: errors });
      return formIsValid;
    }
  
    render() {
      return (
        <div className="wrapper">
          <div className="login-panel">
            <div className="login-content">
              <img className="img" alt="" src="images/logo.png" />
              <div className="login-card card shadow">
                <div className="title">Slack Configuration </div>
                <form onSubmit={this.onSubmit} noValidate>
                  <div className="form-group">
                  <div className="admin-txt-info">These are your values for slack, we use these for troubleshooting, you can access them at any time.</div>
                    <label htmlFor="userNameInput">AccessToken</label>
                    <input
                      type="text"
                      className={`form-control ${
                        this.state.errors && this.state.errors["accesstokenclass"]
                          ? this.state.errors["accesstokenclass"]
                          : ""
                      }`}
                      id="AccessTokenInput"
                      aria-describedby="NameHelp"
                      //placeholder="Enter AccessToken"
                      value={this.state.fields["accesstoken"] || ""}
                      name="accessToken"
                      onChange={(e) => this.handleChange("accesstoken", e)}
                      required
                    />
                   
                  </div>
                  <div className="form-group">
                    <label htmlFor="domainNameInput">AppId</label>
                    <input
                      type="text"
                      className={`form-control ${
                        this.state.errors && this.state.errors["appidclass"]
                          ? this.state.errors["appidclass"]
                          : ""
                      }`}
                      id="domainNameInput"
                      value={this.state.fields["appid"] || ""}
                      //placeholder="Enter your AppId"
                      name="appid"
                      onChange={(e) => this.handleChange("appid", e)}
                      required
                    />
                    {/* <span style={{ color: "red" }}>
                      {this.state.errors["domain"]}
                    </span> */}
                  </div>
  
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">ClientId</label>
                    <input
                      type="email"
                      className={`form-control ${
                        this.state.errors && this.state.errors["clientidclass"]
                          ? this.state.errors["clientidclass"]
                          : ""
                      }`}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      //placeholder="Enter your clientid"
                     value={this.state.fields["clientid"] || ""}
                   
                      name="clientid"
                      onChange={(e) => this.handleChange("clientid", e)}
                      required
                    />
                    {/* <span style={{ color: "red" }}>
                      {this.state.errors["accesstoken"]}
                    </span> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="clientsecret">ClientSecret</label>
                    <input
                      type="inputText"
                      className={`form-control ${
                        this.state.errors &&
                        this.state.errors["clientsecretclass"]
                          ? this.state.errors["clientsecretclass"]
                          : ""
                      }`}
                      id="clientsecret"
                      //placeholder=" Enter ClientSecret"
                      value={this.state.fields["clientsecret"] || ""}
                      name="clientsecret"
                      onChange={(e) => this.handleChange("clientsecret", e)}
                      required
                    />
                    {/* <span style={{ color: "red" }}>
                      {this.state.errors["password"]}
                    </span> */}
                  </div>
  
                  <div className="form-group">
                    <label htmlFor="VerificationToken">
                    Verification Token
                    </label>
                    <input
                      type="inputText"
                      className={`form-control ${
                        this.state.errors &&
                        this.state.errors["userconfirmpasswordclass"]
                          ? this.state.errors["userconfirmpasswordclass"]
                          : ""
                      }`}
                      id="VerificationToken"
                      //placeholder=" Enter Verification Token"
                      value={this.state.fields["verificationtoken"] || ""}
                     // value="Hbf7wxXA1qI5nGzeXscGml87"
                      name="verificationtoken"
                      onChange={(e) =>
                        this.handleChange("verificationtoken", e)
                      }
                      required
                    />
                    {/* <span style={{ color: "red" }}>
                      {this.state.errors["confirmpassword"]}
                    </span> */}
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  
  // Prop types of props.
  SlackConfig.propTypes = {
    API_HOST: PropTypes.string,
    slackconfig: PropTypes.func,
    slackconfigResponse: PropTypes.object,
  };
  
  // Set default props..
  SlackConfig.defaultProps = {
    slackconfig: () => {},
  };
  
  const mapStateToProps = state => ({
		API_HOST: getApiHost(),
		slackconfigResponse: state.slackconfigReducer.slackconfigResponse
	});

  export default connect(mapStateToProps, {slackconfig})(SlackConfig);
  