import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getEncryptedValue, getDecryptedValue } from "../../../utils/utility";
import { login } from "../../../actions/loginActions";
import { getSlackConfigurationlistLogin, loginWithSlack } from "../../../actions/dashboardActions";
import { getApiHost } from "../../../utils/apiUrls";
import $ from 'jquery';
import { toast } from "react-toastify";

class Login extends React.Component {

  state = {
    fields: {},
    errors: {},
    isHide: false,
    checked: false,
    scope: "",
    clientId: "",
    showPassword: false
  };

  componentDidMount = () => {
    document.title = this.props.title; // Set title of page.
    if (this.props.loggedInRes && this.props.loggedInRes.token) {
      this.props.history.push("/signup");
    }

    if(this.props.location.state){
      toast.info("Token is expired. Please login again.");
    }
    // if (sessionStorage.getItem("signUpEmail") !== null) {
    //   let fields = this.state.fields;
    //   fields["username"] = sessionStorage.getItem("signUpEmail");
    //   this.setState({ fields: fields, isHide: true });
    // }

    this.getSlackConfigurationlistLogin();

   if(localStorage.getItem("rememberMe") === "true"){
    let fields = this.state.fields;
    fields["username"] = localStorage.getItem("userEmail");
    fields["password"] = getDecryptedValue(localStorage.getItem("password"));
    this.setState({ fields: fields});
    $("#customCheck").prop("checked", true);
   }
  };

  componentWillReceiveProps = (nextProps) => {

    if (nextProps.loggedInResponse !== this.props.loggedInResponse) {
      if (nextProps.loggedInResponse.loggedInResponse.MessageCode === 5) {
        // localStorage.setItem("userEmail", this.state.fields["username"]);
        // localStorage.setItem("password", getEncryptedValue(this.state.fields["password"]))
        this.props.history.push("/multipleemail");
        // localStorage.setItem("userEmail",this.state.fields["username"] );
      } else {
        this.props.history.push("/dashboard");
      }
    }
  };

  // Function calls while type user name or password and set their values.
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  // Function handles validation and if success then calls login method.
  onSubmitLogin = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      sessionStorage.removeItem("signUpEmail");
      this.onLogin();
    }
  };


  // Calls Login API to get logged in.
  onLogin = () => {
    //console.log("Email: ",this.state.fields["username"]);
    //console.log("Pwd: ",this.state.fields["password"]);
    if(this.state.fields["username"] === undefined){
      toast.error("Please enter your Email address");
    }
    if(this.state.fields["password"] === undefined){
      toast.error("Please enter your Password");
    }
    if(this.state.fields["username"] !== undefined && this.state.fields["password"] !== undefined){
      const loginRequest = {
        EmailId: this.state.fields["username"],
        Password: getEncryptedValue(this.state.fields["password"]),
      };
      this.props.login(getApiHost(), loginRequest);
    }
    
  };

  // validate emailID
  // validateEmail(inputText) {
  //   // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //   // var mailformat2 = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;  //case sensitive
  //   //var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; //case insensitive
  //   var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //   if (inputText.match(mailformat)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  validateEmail = (e) =>{
    const emailText = e.target.value;
    var pattern= "[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})";
    //var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (e.target.name === "username" && !emailText.match(pattern)) {
        toast.error("Please enter a valid email address");
      }

  }

  validatePassword = (e) =>{
    const passwordText = e.target.value;
    var pattern= /^[a-zA-Z0-9!@#$%^&*]{8,10}$/;
      if (e.target.name === "password" && !passwordText.match(pattern)) {
        toast.error("Please enter a valid password. It should contain one Capital letter, one Number, one Special Character, and length should be upto 10 characters");
      }
  }

  getSlackConfigurationlistLogin = () => {
    const {  getSlackConfigurationlistLogin } = this.props;
    this.setState({ loading: true });
    const API_HOST = getApiHost();
    getSlackConfigurationlistLogin(API_HOST, (res) => {
      if (res) {
        this.setState({ loading: false, scope: res[0].Scope_Detail, clientId: getDecryptedValue(res[0].Client_ID)})
      }

    });
   
  }

  // Handles validation for required fields.sdfs
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    this.setState({ errors: errors });
    return formIsValid;

    // User name
    // if (!fields["username"]) {
    //   formIsValid = false;
    //   errors["username"] = "Email is required";
    //   console.log(errors);
    //   errors["usernameclass"] = "is-invalid";
    // } else {

    //   let validemail = this.validateEmail(fields["username"]);
    //   if (validemail) {
    //     formIsValid = true;
    //     errors["username"] = "Email id";
    //     errors["usernameclass"] = "is-valid";
    //   } else {
    //     formIsValid = false;
    //     errors["username"] = "Email is not valid";
    //     errors["usernameclass"] = "is-invalid";
    //   }
    // }

    // // Password
    // if (!fields["password"]) {
    //   formIsValid = false;
    //   errors["password"] = "Password is required";
    //   errors["passwordclass"] = "is-invalid";
    // } else {
    //   formIsValid = true;
    //   errors["password"] = "Password";
    //   errors["passwordclass"] = "is-valid";
    // }

    //console.log("VALIDATION__FAILED___");
    this.setState({ errors: errors });
    return formIsValid;
  }

  rememberMe = () => {

      if(this.refs.c.checked && this.state.fields["username"] && this.state.fields["password"] && this.state.fields["username"] !== "" && this.state.fields["password"] !== ""){

        localStorage.setItem("userEmail", this.state.fields["username"]);
        localStorage.setItem("password", getEncryptedValue(this.state.fields["password"]));
        localStorage.setItem("rememberMe", true);

      }

      if(!this.refs.c.checked && this.state.fields["username"] && this.state.fields["password"] && this.state.fields["username"] !== "" && this.state.fields["password"] !== ""){
        
        localStorage.removeItem("userEmail");
        localStorage.removeItem("password");
        localStorage.removeItem("rememberMe");
        
      }
  }

  checkToken = () => {
    
    $('.acc-signin-btn').addClass('disable');
    if(localStorage.getItem("Token") && localStorage.getItem("Token") !== "" 
        && localStorage.getItem("SlackUserId") && localStorage.getItem("SlackUserId") !== "" 
        && localStorage.getItem("SlackRefreshToken") && localStorage.getItem("SlackRefreshToken") !== "") {
      const token = localStorage.getItem("Token");
      const slackUserId = localStorage.getItem("SlackUserId");
      const refreshToken = localStorage.getItem("SlackRefreshToken");
      this.loginWithSlack(token, slackUserId, refreshToken);      
    }
    else{
      window.location.href =  `https://slack.com/oauth/v2/authorize?${this.state.scope}&client_id=${this.state.clientId}&redirect_uri=${window.location.protocol}//${window.location.host}/signingwithslacktoken`
    }
  }

  loginWithSlack = async (access_token, slackUserId, refresh_token) => {

    const {  loginWithSlack} = this.props;
    const API_HOST = getApiHost();
    const requestBody = {
        "Slack_User_Access_Token" : access_token,
        "Slack_User_Id" : slackUserId,
        "Slack_User_Refresh_Token" : refresh_token
  };

    await loginWithSlack(API_HOST, requestBody, (res) => {
      
      if(res !== null) {
        localStorage.setItem("userdetail", JSON.stringify(res.UserData));
        sessionStorage.setItem("userToken",JSON.stringify(res.TokenData.Token).replace(/"/g, ""));
        this.props.history.push("/dashboard");
      }
    });

  }

  showPassword = () => {
    var x = document.getElementById("myPassword");
    if (x.type === "password") {
      x.type = "text";
      this.setState({
        showPassword: true
      })
    }
    else {
      x.type = "password";
      this.setState({
        showPassword: false
      })
    }
  }


  render() {
    const { isHide } = this.state;
    return (
                <div className="wrapper">
                  <div className="login-panel">

                    <div className="bg-animate">
                      <div class="bg"></div>
                      <div class="bg bg2"></div>
                      <div class="bg bg3"></div>
                    </div>

                    <div className="login-content">
                      <img className="img" src="images/logo.png" alt="" />

                      <div className="login-card card">
                        <div className="title">Login</div>

                        <form onSubmit={this.onSubmitLogin} noValidate>
                          {isHide === false && <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email</label>
                            <input
                              type="email"
                              className={`form-control ${this.state.errors && this.state.errors["usernameclass"]
                                ? this.state.errors["usernameclass"]
                                : ""
                                }`}
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter your email address"
                              value={this.state.fields["username"] || ""}
                            // value={localStorage.getItem("rememberMe") === "true" ? localStorage.getItem("userEmail").toString() : this.state.fields["username"]}
                              name="username"
                              onChange={(e) => this.handleChange("username", e)}
                              required
                              pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
                               maxLength="320"
                               title="Please enter valid email address"
                              // onBlur={(e) => this.validateEmail(e)}
                            />
                            {/* <span style={{ color: "red" }}>
                              {this.state.errors["username"]}
                            </span> */}
                          </div>}
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password</label>
                            <div className="position-relative pwd-active">
                            <input
                              type="password"
                              className={`form-control ${this.state.errors && this.state.errors["passwordclass"]
                                ? this.state.errors["passwordclass"]
                                : ""
                                }`}
                              value={this.state.fields["password"] || ""}
                            // value={localStorage.getItem("rememberMe") === "true" ? localStorage.getItem("password").toString() : this.state.fields["password"]}
                              name="password"
                              id="exampleInputPassword1"
                              placeholder="Password"
                              onChange={(e) => this.handleChange("password", e)}
                              required
                             // onBlur={(e) => this.validatePassword(e)}
                              id="myPassword"
                            />
                            {this.state.showPassword ? <i class="fas fa-eye-slash" onClick={this.showPassword} style={{cursor: 'pointer'}}></i> : <i class="fas fa-eye" onClick={this.showPassword} style={{cursor: 'pointer'}}></i>}
                            </div>
                            
                            
                            {/* <span style={{ color: "red" }}>
                              {this.state.errors["password"]}
                            </span> */}
                          </div>
                          <div className="form-group check-forgot-div">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck"
                                name="example1"
                                ref="c"
                                onClick={this.rememberMe}
                              // checked={localStorage.getItem("rememberMe") === "true" ? "true" : "false"}
                                //checked={localStorage.getItem("rememberMe") && localStorage.getItem("rememberMe") === "true" ? 'checked' : ''}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck"
                              >
                                Remember me
                              </label>
                            </div>
                            <Link to={"/forgotPassword"} >Forgot your password? </Link>
                          </div>

                          <button type="submit" className="btn btn-primary">
                            Login
                          </button>

                          <Link to={"/signup"} className="have-acc">
                            Create an account?
                          </Link>
                
                          <div className="acc-signin-btn">

                            {/* <a target="_self"  href={`https://slack.com/oauth/v2/authorize?${this.state.scope}&client_id=${localStorage.getItem("SlackClientId")}&redirect_uri=${window.location.protocol}//${window.location.host}/signingwithslacktoken`}>
                              <img alt="Sign in with Slack" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" />
                            </a> */}

                            <a target="_self"  onClick={this.checkToken}>
                              <img alt="Sign in with Slack" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" />
                            </a>

                            <div className="mt-1">
                              <Link to={"/contactus"} className="have-acc">
                              Contact Us
                              </Link>
                            </div>


                          </div>

                        </form>
                      </div>

                    </div>
                  </div>
                </div>
      
    );
  }
}

// Prop types of props.
Login.propTypes = {
  // API_HOST: PropTypes.string,
  login: PropTypes.func,
  loginErrorMessage: PropTypes.string,
  loading: PropTypes.bool,
  loggedInResponse: PropTypes.object,
  loginError: PropTypes.bool,

  slackConfigListLoginResponse: PropTypes.any,
  getSlackConfigurationlistLogin: PropTypes.func
};

// Set default props.
Login.defaultProps = {
  login: () => { },
  loginErrorMessage: "",
  loading: false,
  loginError: false,

  slackConfigListLoginResponse: [],
  getSlackConfigurationlistLogin: () => { }
};

const mapStateToProps = state => ({
  loggedInResponse: state.loginReducer.loginResponse,
  loginErrorMessage: state.loginReducer.loginErrorMessage,
  loading: state.loginReducer.loading,
  loginError: state.loginReducer.loginError,

//  slackConfigListLoginResponse: state.dashboardReducer.slackConfigListLoginResponse.slackConfigListLoginResponse
})

export default connect(mapStateToProps, { login, getSlackConfigurationlistLogin, loginWithSlack })(Login);
