import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import {
  handleError,
  handleResponse,
  getConfig,
} from "../utils/utility";

// Slack Config
export const receiveSlackConfig  = (slackconfigResponse) => ({
  type: T.RECEIVE_SLACK_CONFIG,
  slackconfigResponse 
});

/**
 * Slack Config
 *
 * @param {url} URL is API host domain.
 * @param {slack config Request} is request object to SalckConfig.
 * @return {object} return slack
 */
export const slackconfig = (url, slackconfigRequest ) => (dispatch) => {
  const finalUrl = `${url}slack/saveslackconfiguration`;
  return trackPromise(
    axios
      .post(finalUrl, slackconfigRequest ,getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
          dispatch(receiveSlackConfig (result));
      })
      .catch((error) =>
        handleError(error, "Slack")
      )
  );
};
