import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import {
  handleError,
  handleResponse,
  getConfig,
} from "../utils/utility";
import { getApiHost } from '../utils/apiUrls';

export const receiveCreateOffice = (officeResponse) => ({
  type: T.RECEIVE_CREATE_OFFICE,
  officeResponse,
});

/**
 * Office
 *
 * @param {url} URL is API host domain.
 * @param {create officeRequest} is request object to office.
 * @return {object} return user details.
 */
export const office = (url, officeRequest) => (dispatch) => {
  const finalUrl = `${url}tenantoffice/savetenantoffice`;
  return trackPromise(
    axios
      .post(finalUrl, officeRequest, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
        if (result.id !== 0) {

          dispatch(receiveCreateOffice(result));
        }
      })
      .catch((error) =>
        handleError(error, "error")
      )
  );
};


//Fetch Office
export const receiveFetchOffice = (fetchOfficeResponse) => ({
  type: T.RECEIVE_FETCH_OFFICE,
  fetchOfficeResponse,
});

/**
 * Fetch Office
 *
 * @param {url} URL is API host domain.
 * 
 */
export const fetchOffice = () => (dispatch) => {
  const finalUrl = `${getApiHost()}tenantoffice/gettenantoffices`;
  //return trackPromise(
    axios.get(finalUrl, getConfig())
      .then(response =>
        handleResponse(response)
      )
      .then(result =>
        dispatch(receiveFetchOffice(result))
      )
      .catch(error =>
        handleError(error, 'Cannot get Tenant Office')
      )//);
}


/**
 * Add Office
 *
 * @param {url} URL is API host domain.
*/
export const addOffice = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}TenantOffice/SaveTenantOffice`;

  axios.post(finalUrl, requestBody, getConfig())
    .then(response => {
      return handleResponse(response)
    }
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, 'Failed to add office')
    )
}


/**
 * Get Office by id
 *
 * @param {url} URL is API host domain.
*/

export const getOfficeById = (url, id, callback) => (dispatch) => {
  const finalUrl = `${url}tenantoffice/gettenantofficebyid/${id}`;

  axios.post(finalUrl, {}, getConfig())
    .then(response => {
      return handleResponse(response)
    }
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, 'Failed to get office')
    )

}

/**
 * Delete office by id
 *
 * @param {url} URL is API host domain.
*/

export const deleteOffice = (url, id, callback) => (dispatch) => {

  const finalUrl = `${url}tenantoffice/deletetenantoffice/${id}`;

  axios.delete(finalUrl, getConfig())
    .then(response => {
      return handleResponse(response)
    }
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, 'Failed to delete office')

    )

}

//Edit Office Name
export const editOfficeName = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}TenantOffice/updateofficename`;

  //return trackPromise(
  axios.post(finalUrl, requestBody, getConfig())
    .then(response =>
      handleResponse(response)
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, ' Failed to edit Office name')
    )//);
}