import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from '../../../utils/apiUrls';
import { CustomLoadingSpiner } from '../../../components/loader/customLoader';
import { updateUserJiraRefreshToken, updateUserJiraAccessToken, getJiraConfig, saveUserToDoFromJiraTask } from "../../../actions/jiraActions";
import { userToDoListFetch } from "../../../actions/todoActions";
import { toast } from "react-toastify";
import axios from 'axios';
import { getEncryptedValue, getDecryptedValue } from "../../../utils/utility";
import Jira from "./Jira";


class JiraToken extends React.Component {
  state = {
    loading: true,
    jiraClientId: "",
    jiraClientSecret: "",
    JiraTaskList: [],
    cloudId:"",

  };

  msg = (userEmailAddress, usersJiraEmail) => (
    <div>
      Please use same email address to login in JIRA !!<br></br> 
      Email address used in Precis: {userEmailAddress} <br></br> 
      Email address used in JIRA: {usersJiraEmail}
    </div>
  )

  async componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('code');
    this.getJIRAConfig(code)
    // await this.getSlackConfigurationlist();
    // await this.slackTokenOAuth(code);
  }

  getJIRAConfig = (code) => {
    const { API_HOST, getJiraConfig } = this.props;
    getJiraConfig(API_HOST, (res) => {
      if (res[0]) {
        this.setState({
          jiraClientId: getDecryptedValue(res[0].Client_ID),
          jiraClientSecret: getDecryptedValue(res[0].Client_Secret)
        })
        this.getJiraToken(code);

      }
    });
  }

  getJiraToken = async (code) => {
    try {
      let finalUrl = 'https://auth.atlassian.com/oauth/token';
      let resource = {
        "grant_type": "authorization_code",
        "client_id": this.state.jiraClientId,
        "client_secret": this.state.jiraClientSecret,
        "code": code,
        "redirect_uri": `${window.location.protocol}//${window.location.host}/jiratoken`   //"http://localhost:3000/jiratoken", https://server1.heliosdemo.com:5055/jiratoken

      }
      // let resource = {
      //     "client_id": "396060105786-qt9n1d57ocqvm468dh86ivr0r5kg4kit.apps.googleusercontent.com",
      //     "code": code,
      //     "grant_type": "authorization_code",
      //     "client_secret": 'N9XFPtRSptFi9_q3opix2-Lm',
      //     "redirect_uri": `${window.location.protocol}//${window.location.host}`,
      // }

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers }); //store in DB
      console.log("In Jira Token :: ", data);
      this.checkUserInfo(data.data.access_token,data.data.refresh_token, data.data.scope)
     }
    catch (error) {
      toast.error(error)
    }

  }

  checkUserInfo =async(accessToken,refreshToken, scope) => {
  try {
    const finalUrl = `https://api.atlassian.com/me`
    let headers = {
      "Accept": "application/json",
      "Authorization": "Bearer " + accessToken
    };
    const { data } = await axios.get(finalUrl, { headers });
     const usersJiraEmail = data.email;
    const userEmailAddress = JSON.parse(localStorage.getItem('userdetail')).EmailId;
   
    if(usersJiraEmail == userEmailAddress)  {
    await this.updateUserJiraRefreshToken(refreshToken)
    await this.getAccessibleResource( accessToken, scope);
    await this.getJiraYourTaskList(accessToken);
    }
    else{
      this.props.history.push('/dashboard');
       toast.error(this.msg(userEmailAddress, usersJiraEmail));
    }
  }
  catch (error) {
    toast.error(error)
  }
  }

  getAccessibleResource = async ( accessToken, scope) => {
    try {
      const finalUrl = `https://api.atlassian.com/oauth/token/accessible-resources`
      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken
      };
      const { data } = await axios.get(finalUrl, { headers });
      await this.updateUserJiraAccessToken(accessToken, data, scope)
      this.setState({cloudId: data[0].id});
      console.log("Cloud dtata:: ",this.state.cloudId);
       // this.props.history.push('/dashboard');
       this.props.history.push({ pathname: '/dashboard', isModal: true })

    }
    catch (error) {
      toast.error(error)
    }
  }

  updateUserJiraRefreshToken = async (refreshToken) => {
    const { API_HOST, updateUserJiraRefreshToken } = this.props;

    let requestBody = {
      "Jira_Token": getEncryptedValue(refreshToken)
    }

    await updateUserJiraRefreshToken(API_HOST, requestBody)

  }

  updateUserJiraAccessToken = async (accessToken, accessibleResourcesArray, scope) => {
    const { API_HOST, updateUserJiraAccessToken } = this.props;
    const newArray = accessibleResourcesArray.map(function(row) {
      return { 
        "CloudId" : getEncryptedValue(row.id),
        "Domain_Name" : row.name,
       // "Jira_Scope_Detail": row.scopes.toString(),
       "Jira_Scope_Detail": scope,
        "Jira_Url":row.url}
   })
    let requestBody = {
      newArray
      // "Jira_Token": getEncryptedValue(accessToken),
      // "Jira_Scope_Detail": scope,
      // "CloudId": getEncryptedValue(cloudId),
      // "Domain_Name": domainName
    }

     await updateUserJiraAccessToken(API_HOST, newArray);
    await this.getJiraYourTaskList(accessToken);

  }

  getJiraYourTaskList = async (token) => {
    try {
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/search?jql=assignee=currentuser()AND status IN ('In Progress','To Do','Sprint Testing')&maxResults=500&order%20by%20created&fields=assignee,comment,created,creator,description,issuelinks,duedate,issuetype,parent,priority,progress,project,reporter,status,subtasks,summary`
      // const finalUrl = `https://smartchrome.atlassian.net/rest/api/3/project`

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + token
      };
      const { data } = await axios.get(finalUrl, { headers });
      console.log("DATA get task list:: ", data);
      this.setState({ JiraTaskList: data.issues })

    //  this.saveUserToDoFromJiraTask(data.issues[0]);

    
      var i;
      for (i = 0; i < 10; i++) {
       // this.saveUserToDoFromJiraTask(this.state.JiraTaskList[i]);
       await this.saveUserToDoFromJiraTask(data.issues[i]);
      }

      
      

    }
    catch (error) {
      toast.error(error)
    }
  //  this.setState({ isActive: false , loading: false})

  }


  saveUserToDoFromJiraTask = (jiraTask) => {
    const { API_HOST, saveUserToDoFromJiraTask } = this.props;
    let requestBody = {
          "Id" : 0,
          "Id_Jira_Task" : jiraTask.id, 
          "Jira_Task_Date" : jiraTask.fields.created,
          "Description" : jiraTask.fields.summary,
          "Jira_Task_Key" : jiraTask.key,
          "Jira_Cloud_Id": getEncryptedValue(this.state.cloudId)
      }

        saveUserToDoFromJiraTask(API_HOST, requestBody, (res) => {
          if (res !== null) {
           // this.userToDoList();
          }
        });
  }

  userToDoList = () => {
    var userData = localStorage.getItem('userdetail');
    const { API_HOST, userToDoListFetch } = this.props;
   // this.setState({ isActive: true })
    userToDoListFetch(API_HOST, JSON.parse(userData).Id_User, (res) => {
      
    });
  }

  render() {
    // let { topicId } = useParams();

    return (
      <CustomLoadingSpiner InProgress={this.state.loading} />
    );
  }
}

// Prop types of props.
JiraToken.propTypes = {
  API_HOST: PropTypes.string,
  slackConfigListResponse: PropTypes.any,
  getSlackConfigurationlist: PropTypes.func,
};

// Set default props.
JiraToken.defaultProps = {
  slackConfigListResponse: [],
  getSlackConfigurationlist: () => { }
};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
    slackConfigListResponse: state.dashboardReducer.slackConfigListResponse.slackConfigListResponse
  };
};
export default connect(mapStateToProps, { updateUserJiraRefreshToken, updateUserJiraAccessToken, getJiraConfig, saveUserToDoFromJiraTask, userToDoListFetch })(JiraToken);
