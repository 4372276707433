import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";


export const LoadingSpinerComponent = (props) => {
const { promiseInProgress } = usePromiseTracker();

  return (
    <>
    {
      (promiseInProgress === true) ?
      <div className="overlay-loader">
        <div className="loader-wrap">
          <div>
            <div class="hm-spinner"></div>
          </div>
          <div className="load-text">Loading, Please wait</div>
        </div>
      </div>
      :
        null
    }
  </>
  )
};