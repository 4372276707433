import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { getApiHost } from "../../utils/apiUrls";
import {
  saveMeeting,
  getUserMeetingbyId,
  updateusergooglerefreshtoken,
  checkUserAvailabilityForMeeting,
  sendMailfromPrecis,
  checkUseravilAbilityInGoogle,
  getGoogleConfig,
} from "../../actions/googleCalendarMeetingActions";
import { getUserListDepartmentFilter } from "../../actions/mainOfficeActions";
//import { CustomLoadingSpiner } from '../loader/customLoader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ModalFooter } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import GoogleLogin from "react-google-login";
import {
  getConfig,
  getStartTimeFormat,
  duration,
  getEncryptedValue,
  getDecryptedValue,
} from "../../utils/utility";
import LoadingOverlay from "react-loading-overlay";
import ClockLoader from "react-spinners/ClockLoader";
import UserCalendar from "./UserCalendar";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

const jwt = require("jsonwebtoken");
const payload = {
  iss: "dIBNepDrQUyhySphO48OrA",
  exp: new Date().getTime() + 5000,
};
const zoomToken = jwt.sign(payload, "fLQ0dQBObpg7rs89FWu2QF3LYSbqIhT4M1ET");

class ScheduleMeeting extends Component {
  state = {
    modal: this.props.sheduleModal,
    startDate: new Date(this.props.startDate),
    endDate: new Date(this.props.endDate),
    startTime: moment(new Date()).add(15, "m").toDate(),
    endTime: moment(new Date()).add(60, "m").toDate(),
    title: this.props.title,
    selectedOption: this.props.selectedOption,
    token: "",
    MeetingId: this.props.MeetingId,
    description: "",
    joinUrl: "",
    connectToGoogle: false,
    isSubmitDisabled: false,
    isLoader: false,
    MeetingPassword: "",
    MeetingNumber: null,
    iscal: false,
    clientId: "",
    clientSecret: "",
    loading: false,
    clientScope: "",
  };

  componentDidMount() {
    const userData = localStorage.getItem("userdetail");
    const userRefreshToken = getDecryptedValue(
      JSON.parse(userData).Google_Refresh_Token
    );
    console.log("Refreh Value :  ", userRefreshToken);

    let user = this.props.getUserAvailabilityListResponse.find(
      (user) =>
        user.Id_User === JSON.parse(localStorage.getItem("userdetail")).Id_User
    );
    let options = [];
    if (this.props.selectedOption) {
      options = this.props.selectedOption;
    }
    options.push({
      value: user.Id_User,
      email: user.EmailId,
      label: user.Real_Name,
    });
    this.setState({ selectedOption: options });
    if (this.props.from === "gcalander") {
      if (this.props.token !== "") {
        this.setState({ token: this.props.token, connectToGoogle: true });
      }
    }
    //this.getUserListDepartmentFilter(); // comment this line by Alpesh_09_12_20
    let user_TZ =
      JSON.parse(localStorage.getItem("userdetail")) === null &&
      (JSON.parse(localStorage.getItem("userdetail")).TZ === "" ||
        JSON.parse(localStorage.getItem("userdetail")).TZ === null)
        ? "Asia/Kolkata"
        : JSON.parse(localStorage.getItem("userdetail")).TZ;
    this.setState({
      startTime: new Date(
        moment().tz(user_TZ).add(15, "m").format("YYYY-MM-DDTHH:mm:ss.sss")
      ),
      endTime: new Date(
        moment().tz(user_TZ).add(60, "m").format("YYYY-MM-DDTHH:mm:ss.sss")
      ),
    });
    this.getGoogleConfig();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sheduleModal !== this.props.sheduleModal) {
      this.setState({
        modal: this.props.sheduleModal,
        startDate: new Date(this.props.startDate),
        endDate: new Date(this.props.endDate),
        startTime: new Date(this.props.startDate),
        endTime: new Date(this.props.endDate),
        title: this.props.title,
        selectedOption: this.props.selectedOption,
        MeetingId: this.props.MeetingId,
      });
      if (prevProps.token !== this.props.token) {
        this.setState({
          token: this.props.token,
        });
      }
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.meetingFlagSet();
  };

  googleCreateEvent = async (sd, ed, st, et, attendes) => {
    let calendarId = "primary";
    let finalUrl =
      "https://www.googleapis.com/calendar/v3/calendars/" +
      calendarId +
      "/events?sendNotifications=true&sendUpdates=all&conferenceDataVersion=1";
    let token = this.state.token;

    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    let name = JSON.parse(localStorage.getItem("userdetail")).Real_Name;
    const timzone = JSON.parse(localStorage.getItem("userdetail")).TZ;
    let minDate = moment().tz(timzone).set({
      year: sd.getFullYear(),
      month: sd.getMonth(),
      date: sd.getDate(),
      hour: 0,
      minute: st,
      second: 0,
    });
    let maxDate = moment().tz(timzone).set({
      year: ed.getFullYear(),
      month: ed.getMonth(),
      date: ed.getDate(),
      hour: 0,
      minute: et,
      second: 0,
    });
    let location = this.state.MeetingNumber
      ? process.env.REACT_APP_ZOOM_URL +
        "/" +
        window.btoa(this.state.MeetingNumber) +
        "/" +
        window.btoa(this.state.MeetingPassword)
      : null;
    let resource = null;
    if (location) {
      resource = {
        summary: this.state.title,
        start: {
          dateTime: minDate,
          timeZone: timzone,
        },
        end: {
          dateTime: maxDate,
          timeZone: timzone,
        },
        attendees: attendes,
        description: this.state.description,
        // +
        // "\n \nJoin Zoom Meeting\n" +
        // location +
        // "\n \nMeeting ID : " +
        // this.state.MeetingNumber +
        // "\nPasscode : " +
        // this.state.MeetingPassword +
        // "\n \n \nRegards,\n" +
        // name,
        location: location, //Made Zoom App Url Of Precis,
        extendedProperties: {
          shared: {
            joinUrl: location, //Made Zoom App Url Of Precis
          },
        },
        reminders: {
          useDefault: false,
          overrides: [
            { method: "popup", minutes: 24 * 60 },
            { method: "popup", minutes: 15 },
            { method: "popup", minutes: 5 },
          ],
        },
        conferenceData: {
          createRequest: {
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
            requestId: "some-random-string-test",
          },
        },
      };
    } else {
      resource = {
        summary: this.state.title,
        start: {
          dateTime: minDate,
          timeZone: timzone,
        },
        end: {
          dateTime: maxDate,
          timeZone: timzone,
        },
        attendees: attendes,
        description: this.state.description.trim()
          ? this.state.description
          : this.state.description,
        reminders: {
          useDefault: false,
          overrides: [
            { method: "popup", minutes: 24 * 60 },
            { method: "popup", minutes: 15 },
            { method: "popup", minutes: 5 },
          ],
        },
        conferenceData: {
          createRequest: {
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
            requestId: "some-random-string-test",
          },
        },
      };
    }
    const { data } = await axios.post(finalUrl, resource, { headers });
    return data;
  };

  saveMeetingEvent = async () => {
    //e.preventDefault();
    //let isUsersAvailable = await this.checkUserAvailabilityForMeeting();
    //if (isUsersAvailable) {
    //this.setState({ isSubmitDisabled: true, isLoader: true })
    var attendes = [];
    var attendesForWeb = [];
    let data = this.state.selectedOption;
    if (data !== null) {
      for (let i = 0; i < data.length; i++) {
        attendes.push({ email: data[i].email });
        attendesForWeb.push({ Id_User: data[i].value, Meeting_Status: 1 });
      }
    }
    const sd = new Date(this.state.startDate);
    const ed = new Date(this.state.endDate);
    const st =
      this.state.startTime.getHours() * 60 + this.state.startTime.getMinutes();
    const et =
      this.state.endTime.getHours() * 60 + this.state.endTime.getMinutes();
    let eventId = null;
    let eventLink = "";
    if (this.state.token !== "") {
      let googleEvent = await this.googleCreateEvent(sd, ed, st, et, attendes);

      eventId = googleEvent.id;
      eventLink = googleEvent.hangoutLink;
    }

    const { API_HOST, saveMeeting } = this.props;

    const userId = JSON.parse(localStorage.getItem("userdetail")).Id_User;
    let name = JSON.parse(localStorage.getItem("userdetail")).Real_Name;
    let description = this.state.description;
    let location = this.state.MeetingNumber
      ? process.env.REACT_APP_ZOOM_URL +
        "/" +
        window.btoa(this.state.MeetingNumber) +
        "/" +
        window.btoa(this.state.MeetingPassword)
      : null;
    if (location) {
      description = description;
      //+
      // "</br>Join Zoom Meeting</br>" +
      //"<a href="+location+" target='_blank'>" + location + "</a>" +
      // "</br> </br> </br>Regards,</br>" +
      // name;
    } else {
      description = description.trim() ? description : description;
    }
    const saveMeetingRequest = {
      Id: this.state.MeetingId,
      Id_Event: eventId,
      Is_SendMail: eventId === null ? true : false,
      title: this.state.title,
      Description: description,
      Id_HostUser: 0,
      Start_Date: moment(sd).format("YYYY-MM-DD"),
      End_Date: moment(ed).format("YYYY-MM-DD"),
      Start_Time: st,
      End_Time: et,
      creator: name,
      UserMeetingDetailList: attendesForWeb,
      MeetingNumber: eventLink,
      MeetingPassword: this.state.MeetingPassword,
    };

    if (st >= et) {
      toast.error("Start Time cannot be greater than or equal to End Time.");
      this.setState({ isSubmitDisabled: false });
    } else {
      await saveMeeting(API_HOST, saveMeetingRequest, (res) => {
        if (res) {
          // if (eventId === null) {
          //   this.sendMailfromPrecis(saveMeetingRequest, res.Id);
          // }
          if (this.props.from === "gcalander") {
            this.props.newEventAdded();
          }
          this.setState(
            { isSubmitDisabled: false, connectToGoogle: false },
            () => {
              this.toggle();
            }
          );
        }
      });
    }

    // }
    //this.setState({ connectToGoogle: false, isLoader: false })
  };

  setDate = async (date) => {
    await this.setState({ endDate: date, startDate: date });
    // await this.checkUserAvailabilityForMeeting();
  };

  handleChange = async (selectedOption) => {
    await this.setState({ selectedOption });
    // await this.checkUserAvailabilityForMeeting();
    // if(isUsersAvailable){
    // this.setState({ selectedOption });
    // }
  };

  getUserListDepartmentFilter = () => {
    const { API_HOST, getUserListDepartmentFilter } = this.props;
    const requestBody = {
      IsPagging: true,
      PageNo: 1,
      PageSize: 10,
      SortingParameters: [
        {
          Property: "",
          Direction: 0,
        },
      ],
      SearchCriteria: {
        Id_User: 0,
        Id_Department: 0,
      },
    };
    getUserListDepartmentFilter(API_HOST, requestBody);
  };

  getZoomMeetingDetail = async () => {
    // let data = DemoData.zoomCreateMeetingRes;
    let user_TZ = JSON.parse(localStorage.getItem("userdetail")).TZ;
    const { API_HOST } = this.props;
    let finalUrl = API_HOST + "Zoom/createmeeting";
    this.setState({ loading: true });

    let reqBody = {
      token: zoomToken,
      zoomdata: {
        topic: this.state.title,
        type: 2,
        start_time: getStartTimeFormat(
          this.state.startTime,
          this.state.startDate
        ),
        duration: duration(this.state.startTime, this.state.endTime),
        password: "12345",
        timezone: user_TZ,
        settings: {
          host_video: true,
          participant_video: true,
          join_before_host: true,
          registrants_email_notification: true,
          approval_type: 0,
        },
      },
    };

    const { data } = await axios.post(finalUrl, reqBody, getConfig());

    if (data) {
      this.setState({ loading: false });
    }

    let location =
      process.env.REACT_APP_ZOOM_URL +
      "/" +
      window.btoa(data.Id) +
      "/" +
      window.btoa(data.Password);
    let name = JSON.parse(localStorage.getItem("userdetail")).Real_Name;

    // + ', Meeting Number : ' + data.Id + ', password : ' + data.Password;
    this.setState({
      MeetingNumber: data.Id,
      MeetingPassword: data.Password,
      // description: this.state.description + "\nJoin Zoom Meeting: \n" + "<a href="+location+" target='_blank'>" + location + "</a>" + "\n \n \n Regards,\n" + name
      description:
        this.state.description +
        "Join Zoom Meeting: </br>\n" +
        "<a href=" +
        location +
        " target='_blank'>" +
        location +
        "</a> ",
      //</br> </br>\n\n Meeting Id: "+ data.Id + "</br>\n Password: "+ data.Password+ "</br> </br>\n\n Regards,</br>\n " + name + "TEST"
    });
  };

  getAccessTokenFromRefreshToken = async (refreshToken) => {
    const userData = localStorage.getItem("userdetail");
    const userRefreshToken = getDecryptedValue(
      JSON.parse(userData).Google_Refresh_Token
    );

    if (this.state.clientId !== "") {
      try {
        let finalUrl = "https://oauth2.googleapis.com/token";
        let resource = {
          client_id: this.state.clientId,
          grant_type: "refresh_token",
          client_secret: this.state.clientSecret,
          refresh_token: getDecryptedValue(refreshToken),
          response_type: "code",
        };

        let headers = {
          "Content-Type": "application/json",
        };
        const data = await axios.post(finalUrl, resource, { headers });
        this.setState({ token: data.data.access_token });
        return data;
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.info(
        "Your workspace is not connected with google calendar.Please inform your admin to connect with google"
      );
    }
  };

  responseGoogleRefresh = (response) => {
    if (response.code !== undefined) {
      this.getAccessToken(response.code);
    }
  };

  getAccessToken = async (code) => {
    if (this.state.clientId !== "") {
      try {
        let finalUrl = "https://accounts.google.com/o/oauth2/token";
        let resource = {
          client_id: this.state.clientId,
          code: code,
          grant_type: "authorization_code",
          client_secret: this.state.clientSecret,
          redirect_uri: `${window.location.protocol}//${window.location.host}`,
        };

        let headers = {
          "Content-Type": "application/json",
        };
        const data = await axios.post(finalUrl, resource, { headers });
        this.updateUserRefreshToken(data.data.refresh_token);
        this.setState({
          token: data.data.access_token,
          isLoader: false,
        });
      } catch (error) {
        this.setState({ isLoader: false });
        toast.error(error);
      }
    } else {
      //divya
      toast.info(
        "Your workspace is not connected with google calendar.Please inform your admin to connect with google"
      );
    }
  };

  updateUserRefreshToken = async (refreshToken) => {
    const { API_HOST, updateusergooglerefreshtoken } = this.props;
    await updateusergooglerefreshtoken(
      API_HOST,
      getEncryptedValue(refreshToken),
      (res) => {
        if (res) {
          let userData = JSON.parse(localStorage.getItem("userdetail"));
          userData.Google_Refresh_Token = getEncryptedValue(refreshToken);
          localStorage.setItem("userdetail", JSON.stringify(userData));
        }
      }
    );
  };

  connectGoogle = async () => {
    if (this.state.clientId !== "") {
      if (this.state.connectToGoogle === false) {
        if (this.props.from === "gcalander" && this.props.token !== "") {
          this.setState({ token: this.props.token });
        }
        if (
          JSON.parse(localStorage.getItem("userdetail"))
            .Google_Refresh_Token !== "" &&
          this.props.token === ""
        ) {
          this.getAccessTokenFromRefreshToken(
            JSON.parse(localStorage.getItem("userdetail")).Google_Refresh_Token
          );
        }
        if (
          this.props.token === "" &&
          JSON.parse(localStorage.getItem("userdetail"))
            .Google_Refresh_Token === ""
        ) {
          await this.setState({ connectToGoogle: !this.state.connectToGoogle });
          toast.info(
            "Your workspace is not connected with google calendar.Please connect with google"
          );
        }
      } else {
        if (
          JSON.parse(localStorage.getItem("userdetail"))
            .Google_Refresh_Token === ""
        ) {
          window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${this.state.clientScope}&access_type=offline&include_granted_scopes=true&response_type=code&state=hello there&redirect_uri=${window.location.protocol}//${window.location.host}/googleconfig&client_id=${this.state.clientId}`;
        }
        this.setState({ token: "" });
      }

      this.setState({ connectToGoogle: !this.state.connectToGoogle });
    } else {
      // await this.setState({ connectToGoogle: !this.state.connectToGoogle })
      //divya
      toast.info(
        "Your workspace is not connected with google calendar.Please inform your admin to connect with google"
      );
      // await this.setState({ connectToGoogle: !this.state.connectToGoogle })
    }
  };

  // checkUserAvailabilityForMeeting = async (e) => {
  //   //let results = false;
  //   e.preventDefault();
  //   this.setState({ isSubmitDisabled: true })
  //   var attendes = [];
  //   var attendesForWeb = [];
  //   let data = this.state.selectedOption;
  //   if (data !== null) {
  //     for (let i = 0; i < data.length; i++) {
  //       attendes.push({ "email": data[i].email })
  //       attendesForWeb.push({ "Id_User": data[i].value, "Meeting_Status": 1 })
  //     }
  //   }
  //   const sd = new Date(this.state.startDate);
  //   const ed = new Date(this.state.endDate);
  //   const st = (this.state.startTime.getHours()) * 60 + (this.state.startTime.getMinutes());
  //   const et = (this.state.endTime.getHours()) * 60 + (this.state.endTime.getMinutes())
  //   let eventId = null;

  //   const userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;
  //   let name = JSON.parse(localStorage.getItem('userdetail')).Real_Name;

  //   const reqBody = {
  //     "Id": this.state.MeetingId,
  //     "Id_Event": eventId,
  //     "title": this.state.title,
  //     "Description": this.state.description,
  //     "Id_HostUser": userId,
  //     "Start_Date": moment(sd).format('YYYY-MM-DD'),
  //     "End_Date": moment(ed).format('YYYY-MM-DD'),
  //     "Start_Time": st,
  //     "End_Time": et,
  //     "creator": name,
  //     "UserMeetingDetailList": attendesForWeb
  //   }

  //   const { API_HOST, checkUserAvailabilityForMeeting } = this.props;
  //   await checkUserAvailabilityForMeeting(API_HOST, reqBody, (res) => {
  //     if (res.length === 0) {
  //       //results = true
  //       this.saveMeetingEvent()
  //     }
  //     else {
  //       toast.error(<div>{res.map((text) => (<div>{text}</div>))}</div>)
  //       this.setState({ isSubmitDisabled: false, isLoader: false })
  //     }
  //   });
  //   // this.setState({ isSubmitDisabled: false, isLoader: false })
  //   // return results;
  // }

  checkUserAvailabilityForMeeting = (e) => {
    //let results = false;
    e.preventDefault();
    this.setState({ isSubmitDisabled: true });
    var attendes = [];
    var attendesForWeb = [];
    let data = this.state.selectedOption;
    if (data !== null) {
      for (let i = 0; i < data.length; i++) {
        attendes.push({ email: data[i].email });
        attendesForWeb.push({ Id_User: data[i].value, Meeting_Status: 1 });
      }
    }
    const sd = new Date(this.state.startDate);
    const ed = new Date(this.state.endDate);
    const st =
      this.state.startTime.getHours() * 60 + this.state.startTime.getMinutes();
    const et =
      this.state.endTime.getHours() * 60 + this.state.endTime.getMinutes();
    let eventId = null;

    const userId = JSON.parse(localStorage.getItem("userdetail")).Id_User;
    let name = JSON.parse(localStorage.getItem("userdetail")).Real_Name;

    const reqBody = {
      Id: this.state.MeetingId,
      Id_Event: eventId,
      title: this.state.title,
      Description: this.state.description,
      Id_HostUser: 0,
      Start_Date: moment(sd).format("YYYY-MM-DD"),
      End_Date: moment(ed).format("YYYY-MM-DD"),
      Start_Time: st,
      End_Time: et,
      creator: name,
      UserMeetingDetailList: attendesForWeb,
    };

    const {
      API_HOST,
      checkUserAvailabilityForMeeting,
      checkUseravilAbilityInGoogle,
    } = this.props;
    checkUserAvailabilityForMeeting(API_HOST, reqBody, (res) => {
      if (res.length === 0) {
        var attendesData = [];
        var userData = this.state.selectedOption;
        for (let i = 0; i < userData.length; i++) {
          attendesData.push({
            Id_User: data[i].value,
            Meeting_Status: 1,
            email: data[i].email,
          });
        }
        const reqBody = {
          Id: this.state.MeetingId,
          Id_Event: eventId,
          title: this.state.title,
          Description: this.state.description,
          Id_HostUser: 0,
          Start_Date: moment(sd).format("YYYY-MM-DD"),
          End_Date: moment(ed).format("YYYY-MM-DD"),
          Start_Time: st,
          End_Time: et,
          creator: name,
          UserMeetingDetailList: attendesData,
        };

        let finalUrl = API_HOST + "GoogleAccount/getaccesstoken";
        checkUseravilAbilityInGoogle(finalUrl, reqBody, (res) => {
          if (res.length === 0) {
            this.saveMeetingEvent();
          } else {
            toast.error(
              <div>
                {res.map((text) => (
                  <div>{text}</div>
                ))}
              </div>
            );
            this.setState({ isSubmitDisabled: false });
          }
        });
      } else {
        toast.error(
          <div>
            {res.map((text) => (
              <div>{text}</div>
            ))}
          </div>
        );
        this.setState({ isSubmitDisabled: false });
      }
    });
  };

  setStartTime = async (date) => {
    if (date === null) {
      toast.error("Start Time cannot be empty");
    }
    var sTime = moment(date).toDate();
    var eTime = moment(date).add(60, "m").toDate();
    await this.setState({ startTime: sTime });
    console.log("Start time:: ", this.state.startTime);
    await this.setEndTime(eTime);
    //  await this.checkUserAvailabilityForMeeting();
  };

  setEndTime = async (date) => {
    if (date === null) {
      toast.error("End Time cannot be empty");
    }
    var eTime = moment(date).toDate();
    await this.setState({ endTime: eTime });
    console.log("End time:: ", this.state.endTime);
    // await this.checkUserAvailabilityForMeeting();
  };

  sendMailfromPrecis = (requestBody, id) => {
    requestBody["Id"] = id;
    const { API_HOST, sendMailfromPrecis } = this.props;
    sendMailfromPrecis(API_HOST, requestBody, (res) => {});
  };

  getUsersCalanderDetail = () => {
    this.setState({
      iscal: true,
    });
  };

  toggleUserCal = () => {
    this.setState({
      iscal: !this.state.iscal,
    });
  };

  getGoogleConfig = async () => {
    const { API_HOST, getGoogleConfig } = this.props;
    await getGoogleConfig(API_HOST, (res) => {
      if (res[0]) {
        this.setState({
          clientId: getDecryptedValue(res[0].GoogleClientId),
          clientSecret: getDecryptedValue(res[0].GoogleClientSecretkey),
          clientScope: res[0].Scope,
        });
      }
    });
  };

  render() {
    const { getUserAvailabilityListResponse } = this.props;
    const { isLoader, isSubmitDisabled, iscal, selectedOption } = this.state;
    let options = [];
    let selOpt = [];
    getUserAvailabilityListResponse &&
      getUserAvailabilityListResponse.map((obj) => {
        if (obj.EmailId !== "" && obj.Real_Name !== "") {
          options.push({
            value: obj.Id_User,
            email: obj.EmailId,
            label: obj.Real_Name,
          });
        }
        return options;
      });
    if (selectedOption) {
      selOpt.push(selectedOption[0]);
    }

    //  console.log("CLIENT ID1 : ", moment(this.state.startTime).toDate());
    //  console.log("CLIENT ID2 : ", moment().toDate());
    //  console.log("CLIENT ID3 : ", moment().format('LT'));  ////////////
    //  console.log("CLIENT ID4 : ", moment().tz("Asia/Kolkata").format('HH:MM'));
    //  console.log("CLIENT ID Main : ",this.state.startTime);
    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggle}
        size="xl"
        backdrop={false}
      >
        {/* {this.state.isLoader && <CustomLoadingSpiner InProgress={this.state.isLoader} />} */}
        <ModalHeader toggle={this.toggle}>Schedule Meeting</ModalHeader>
        <form
          method="post"
          onSubmit={(e) => {
            this.checkUserAvailabilityForMeeting(e);
          }}
          id="shedulemeeting"
        >
          <LoadingOverlay active={isLoader} spinner text="Loading...">
            <ModalBody>
              <div className="row form-mr">
                <div className="col-sm-3">
                  <FormGroup className="row">
                    <label for="title" className="col-sm-3">
                      Title
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        id="title"
                        required
                        name="title"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        value={this.state.title}
                      ></input>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label for="attendees">Attendees</label>
                    <Select
                      components={makeAnimated}
                      isMulti
                      onChange={this.handleChange}
                      options={options}
                      value={this.state.selectedOption}
                      required
                    />
                  </FormGroup>
                  {/* <FormGroup className="row">
                    <label for="MeetingNumber" className="col-sm-6">
                      Zoom Meeting Id
                    </label>
                    <div className="col-sm-6 pl-0">
                      <input
                        type="text"
                        id="MeetingNumber"
                        name="MeetingNumber"
                        className="form-control"
                        onChange={e =>
                          this.setState({ MeetingNumber: e.target.value })
                        }
                        value={this.state.MeetingNumber}
                      ></input>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <label for="MeetingPassword" className="col-sm-6">
                      Zoom Password
                    </label>
                    <div className="col-sm-6 pl-0">
                      <input
                        type="text"
                        id="MeetingPassword"
                        name="MeetingPassword"
                        className="form-control"
                        onChange={e =>
                          this.setState({ MeetingPassword: e.target.value })
                        }
                        value={this.state.MeetingPassword}
                      ></input>
                    </div>
                  </FormGroup> */}
                  <FormGroup>
                    <label for="description">Description</label>
                    <textarea
                      className="form-control"
                      rows="2"
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      value={this.state.description
                        .toString()
                        .replace(/(<([^>]+)>)/gi, "")}
                      style={{ minHeight: "145px" }}
                    ></textarea>
                  </FormGroup>
                </div>
                <div className="col-sm-3">
                  <FormGroup>
                    <label for="enddate">Date:</label>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={(date) => this.setDate(date)}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      minDate={new Date()}
                      inline
                      fixedHeight
                      id="enddate"
                    />
                  </FormGroup>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        {/* <label for="starttime">Start Time</label>
                        <DatePicker
                          isClearable
                          locale="es"
                          selected={this.state.startTime}
                          onChange={date => this.setStartTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control"
                          minTime={
                            new Date(
                              this.state.startTime.getFullYear(),
                              this.state.startTime.getMonth(),
                              this.state.startTime.getDate(),
                              0,
                              0,
                              0,
                              0
                            )
                          }
                          maxTime={
                            new Date(
                              this.state.endTime.getFullYear(),
                              this.state.endTime.getMonth(),
                              this.state.endTime.getDate(),
                              this.state.endTime.getHours(),.add(15, "m")
                          id="startTime"
                        /> */}

                        <label for="starttime">Start Time</label>
                        <TimePicker
                          onChange={(date) => this.setStartTime(date)}
                          defaultValue={moment(new Date()).add(15, "m")}
                          showSecond={false}
                          format="HH:mm"
                          hideDisabledOptions
                          type="time"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        {/* <label for="endtime">End Time</label> 
                         <DatePicker
                          isClearable
                          locale="es"
                          selected={this.state.endTime}
                          onChange={date => this.setEndTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control"
                          id="endTime"
                          maxTime={
                            new Date(
                              this.state.endTime.getFullYear(),
                              this.state.endTime.getMonth(),
                              this.state.endTime.getDate(),
                              23,
                              59,
                              0,
                              0
                            )
                          }
                          minTime={
                            new Date(
                              this.state.startTime.getFullYear(),
                              this.state.startTime.getMonth(),
                              this.state.startTime.getDate(),
                              this.state.startTime.getHours(),
                              this.state.startTime.getMinutes(),
                              0,
                              0
                            )
                          }
                        /> */}

                        <label for="endtime">End Time</label>

                        <TimePicker
                          onChange={(date) => this.setEndTime(date)}
                          defaultValue={moment(new Date()).add(60, "m")}
                          //value={moment(this.state.startTime).add(60, "m")}
                          value={moment(this.state.endTime)}
                          showSecond={false}
                          format="HH:mm"
                          hideDisabledOptions
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitch1"
                          checked={this.state.connectToGoogle}
                          onChange={() => {
                            this.connectGoogle();
                          }}
                        />
                        <label class="custom-control-label" for="customSwitch1">
                          Add to Google Calendar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <label>Attendee Calendar Details</label>
                  <UserCalendar
                    toggleUserCal={this.toggleUserCal}
                    iscal={iscal}
                    selectedOption={selOpt}
                    slcOptions={this.state.selectedOption}
                  ></UserCalendar>
                </div>
              </div>
            </ModalBody>
          </LoadingOverlay>
          <ModalFooter>
            <div class="d-flex justify-content-between w-100">
              {/* <Button
                type="button"
                color="primary"
                disabled={isSubmitDisabled}
                onClick={this.getZoomMeetingDetail}
              >
                <i className="fas fa-video"></i>
                <span style={{ marginLeft: "10px" }}>
                  Add Zoom Meet Video Conferencing
                </span>
                {this.state.loading === true && (
                        <span style={{ marginLeft: "5px" }}>
                          <ClockLoader
                            size={16}
                            color={"#fff"}
                            loading={this.state.loading}
                          />
                        </span>
              )}
              </Button> */}
              <Button type="submit" color="primary" disabled={isSubmitDisabled}>
                <span>Save</span>
                {isSubmitDisabled === true && (
                  <span style={{ marginLeft: "5px" }}>
                    <ClockLoader
                      size={16}
                      color={"#fff"}
                      loading={isSubmitDisabled}
                    />
                  </span>
                )}
              </Button>
            </div>
          </ModalFooter>
        </form>
        {/* {this.state.clientId !== "" && this.state.connectToGoogle && JSON.parse(localStorage.getItem("userdetail")).Google_Refresh_Token === "" ?  
          //   (
          //   <GoogleLogin
          //     // clientId="396060105786-qt9n1d57ocqvm468dh86ivr0r5kg4kit.apps.googleusercontent.com"
          //     // scope="https://www.googleapis.com/auth/calendar"
          //     clientId={this.state.clientId}
          //     scope={
          //       this.state.clientScope !== ""
          //         ? this.state.clientScope
          //         : "https://www.googleapis.com/auth/calendar"
          //     }
          //     // scope='https://www.googleapis.com/auth/calendar.acls'
          //     onSuccess={this.responseGoogleRefresh}
          //     accessType="offline"
          //     responseType="code"
          //     approvalPrompt="force"
          //     prompt="consent"
          //     autoLoad={true}
          //     isSignedIn={true}
          //     cookiePolicy={"single_host_origin"}
          //     render={renderProps => (
          //       <button
          //         style={{ display: "none" }}
          //         onClick={renderProps.onClick}
          //         disabled={renderProps.disabled}
          //       >
          //         Google
          //       </button>
          //     )}
          //     onFailure={() => {
          //       this.setState({ isLoader: false, connectToGoogle: false });
          //       toast.error("Unable to generate Google Token");
          //     }}
          //   />
          // )
        } */}
      </Modal>
    );
  }
}

ScheduleMeeting.defaultProps = {
  API_HOST: PropTypes.string,
  saveMeeting: PropTypes.func,
  getUserMeetingbyId: PropTypes.func,
  getUserAvailabilityListResponse: PropTypes.array,
};

// Set default props.
ScheduleMeeting.defaultProps = {
  saveMeeting: () => {},
  getUserMeetingbyId: () => {},
  getUserAvailabilityListResponse: [],
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
  //getUserAvailabilityListResponse: state.workingHoursReducer.getUserAvailabilityListResponse,
  getUserAvailabilityListResponse:
    state.mainOfficeReducer.userListDepartmentFilterResponse,
});

export default connect(mapStateToProps, {
  checkUseravilAbilityInGoogle,
  saveMeeting,
  getUserMeetingbyId,
  getUserListDepartmentFilter,
  updateusergooglerefreshtoken,
  checkUserAvailabilityForMeeting,
  sendMailfromPrecis,
  getGoogleConfig,
})(ScheduleMeeting);
