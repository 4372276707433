import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from '../../../utils/apiUrls';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import 'react-table-6/react-table.css';
import { toast } from "react-toastify";
import axios from 'axios';
import ClockLoader from "react-spinners/ClockLoader";
import LoadingOverlay from 'react-loading-overlay';
import UnassignedUser from '../../../assets/images/UnassignedUser.png';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveJiraTaskLogDetails } from "../../../actions/jiraActions";
import ReactTable from "react-table-6";
import { getEncryptedValue } from "../../../utils/utility";
import ReactTooltip from "react-tooltip";

//import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

class JiraIssue extends React.Component {
  state = {
    loading: true,
    isActive: true,
    assignUsersOption: [],
    userSelected: null,
    statusCategory: [],
    statusSelected: null,
    description: "",
    isDescriptionEdit: false,
    disabled: false,
    editDescription: null,
    isSummaryEdit: false,
    editSummary: "",
    isSummaryDisabled: false,
    summary: "",
    comments: "",
    isCommentsDisabled: false,
    getAllComments: [],
    avatarUrls: "",
    reporterName: "",
    project: null,
    issueTypeIcon: "",
    accessToken: this.props.accessToken,
    taskDate: new Date(),
    jiraIssue: this.props.jiraIssue,
    taskDescription:"",
    taskDate: new Date(),
    taskComment: "",
    workedHours: 0,
    workedMinutes:0,
    isSubmitDisabled: false,
    taskKey: "",
    isWorkingHour: false,
    jiraTaskLogDetails: this.props.jiraTaskLogDetails,
    totalWorkedHours: this.props.jiraTaskLogDetails[this.props.jiraTaskLogDetails.length - 1]
  };

   componentDidMount() {
     this.setState({ accessToken: this.props.accessToken })
     this.getIssueDetails(this.state.jiraIssue.key);
     this.jiraUsers();
     this.jiraStatusCategory();
     this.setState({ isActive: false })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jiraIssue !== this.props.jiraIssue) {
       this.getIssueDetails(nextProps.jiraIssue.key);
        this.jiraUsers();
        this.jiraStatusCategory();
       this.setState({jiraIssue: nextProps.jiraIssue});
    }

    if ( nextProps.jiraTaskLogDetails !== this.props.jiraTaskLogDetails) {
      console.log("In will reciive2: ",nextProps.jiraTaskLogDetails);
        if(nextProps.jiraTaskLogDetails.length > 0){
          this.setState({ jiraTaskLogDetails: nextProps.jiraTaskLogDetails});
          this.setState({totalWorkedHours: nextProps.jiraTaskLogDetails[nextProps.jiraTaskLogDetails.length - 1].WorkedHours});
        }else{
          this.setState({ jiraTaskLogDetails: []});
          this.setState({totalWorkedHours: 0}); 
        }
    }

}

  getIssueDetails = async (jiraKey) => {
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/${jiraKey}`

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      const { data } = await axios.get(finalUrl, { headers });
      let userselected = null;
      if (data.fields.assignee) {
        userselected = {
          accountId: data.fields.assignee.accountId,
          accountType: data.fields.assignee.accountType,
          active: data.fields.assignee.active,
          displayName: data.fields.assignee.displayName,
          label: <div><img src={data.fields.assignee.avatarUrls["16x16"]} alt="" height="20px" width="20px" />{"     " + data.fields.assignee.displayName}</div>,
          value: data.fields.assignee.displayName,
        }
      }
      else {
        userselected = {
          accountId: "-1",
          displayName: "Unassigned",
          label: <div><img src={UnassignedUser} alt="" height="20px" width="20px" />{"     Unassigned"}</div>,
          value: "-1",
        }
      }

      let statusSelected = {
        id: data.fields.status.id,
        name: data.fields.status.name,
        value: data.fields.status.name,
        self: data.fields.status.self,
        statusCategory: data.fields.status.statusCategory,
        label: data.fields.status.name
      }
      this.setState({
        userSelected: userselected,
        statusSelected: statusSelected,
        description: data.fields.description,
        summary: data.fields.summary,
        getAllComments: data.fields.comment.comments,
        avatarUrls: data.fields.reporter.avatarUrls["16x16"],
        reporterName: data.fields.reporter.displayName,
        project: data.fields.project,
        issueTypeIcon: data.fields.issuetype.iconUrl
      })
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.getIssueDetails(this.state.jiraIssue.key)
      }
      toast.error(error)
    }
  }

  jiraUsers = async () => {
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/user/assignable/search?project=` + this.state.jiraIssue.fields.project.key

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ assignUsersOption: data })
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.jiraUsers()
      }
      toast.error(error)
    }
    this.setState({ isActive: false })

  }

  jiraStatusCategory = async () => {
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/${this.state.jiraIssue.key}/transitions?sortByOpsBarAndStatus=true`


      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ statusCategory: data.transitions })
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.jiraStatusCategory()
      }
      toast.error(error)
    }
  }

  userAssignChange = (newValue) => {
    this.setState({ userSelected: newValue });
    this.changeAssigneefromJira(newValue)
  }

  changeAssigneefromJira = async (user) => {
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/` + this.state.jiraIssue.key

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      let resource = {
        "fields": {
          "assignee": {
            "accountId": user.accountId
          }
        }
      }
      const { data } = await axios.put(finalUrl, resource, { headers });
      // this.setState({assignUsersOption:data})
      this.getIssueDetails(this.state.jiraIssue.key)
      this.props.getJiraYourTaskList(this.props.cloudId, this.state.accessToken)
      if (this.props.isBacklogIssues) {
        this.props.jiraBacklogIssue(this.state.project)
      }
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.changeAssigneefromJira(user)
      }
      toast.error(error)
    }
  }

  addCommentToJiraIssue = async () => {
    try {
      await this.setState({ isCommentsDisabled: true })
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/` + this.state.jiraIssue.key + "/comment";

      let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      let resource = {
        "body": this.state.comments

      }
      const { data } = await axios.post(finalUrl, resource, { headers });
      // this.setState({assignUsersOption:data})
      await this.getIssueDetails(this.state.jiraIssue.key);
      this.props.getJiraYourTaskList(this.props.cloudId, this.state.accessToken);
      if (this.props.isBacklogIssues) {
        this.props.jiraBacklogIssue(this.state.project)
      }
      this.setState({ comments: "" })
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.addCommentToJiraIssue()
      }
      toast.error(error)
    }
    await this.setState({ isCommentsDisabled: false })

  }

  setStatusforJira = async (newValue) => {
    this.setState({ statusSelected: newValue })
    try {

      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/` + this.state.jiraIssue.key + "/transitions";

      let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      let resource = {
        "transition": { "id": newValue.id }

      }
      const { data } = await axios.post(finalUrl, resource, { headers });
      await this.getIssueDetails(this.state.jiraIssue.key);
      this.props.getJiraYourTaskList(this.props.cloudId, this.state.accessToken);
      if (this.props.isBacklogIssues) {
        this.props.jiraBacklogIssue(this.state.project)
      }
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.setStatusforJira(newValue)
      }
      toast.error(error)
    }
  }

  onSaveDescription = async () => {
    await this.setState({ disabled: true })
    if (this.state.description !== this.state.editDescription) {
      await this.onSaveDescriptionWithJira()
    }
    await this.setState({ isDescriptionEdit: false, disabled: false })
  }

  onSaveDescriptionWithJira = async () => {
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/` + this.state.jiraIssue.key

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      let resource = {
        "fields": {
          "description": this.state.editDescription
        }
      }
      const { data } = await axios.put(finalUrl, resource, { headers });
      this.setState({ description: this.state.editDescription.trim() === "" ? null : this.state.editDescription })
      this.getIssueDetails(this.state.jiraIssue.key)
      this.props.getJiraYourTaskList(this.props.cloudId, this.state.accessToken)
      if (this.props.isBacklogIssues) {
        this.props.jiraBacklogIssue(this.state.project)
      }
    }
    catch (error) {
      if (error.response.status && error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.onSaveDescriptionWithJira()
      }
      toast.error(error)
    }
  }

  onSaveSummary = async () => {
    await this.setState({ isSummaryDisabled: true })
    if (this.state.summary !== this.state.editSummary && this.state.editSummary.trim() !== "") {
      await this.onSaveSummaryWithJira()
    }
    await this.setState({ isSummaryEdit: false, isSummaryDisabled: false })
  }

  onSaveSummaryWithJira = async () => {
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/` + this.state.jiraIssue.key

      let headers = {
        "Accept": "application/json",
        "Authorization": "Bearer " + this.state.accessToken
      };
      let resource = {
        "fields": {
          "summary": this.state.editSummary
        }
      }
      const { data } = await axios.put(finalUrl, resource, { headers });
      this.setState({ summary: this.state.editSummary })
      this.getIssueDetails(this.state.jiraIssue.key)
      this.props.getJiraYourTaskList(this.props.cloudId, this.state.accessToken)
      if (this.props.isBacklogIssues) {
        this.props.jiraBacklogIssue(this.state.project)
      }
    }
    catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken })
        this.onSaveSummaryWithJira()
      }
      toast.error(error)
    }
  }

  setDate = async (date) => {
    await this.setState({ taskDate: date });
  };
  
  setWorkedHours = (hours) => {
     this.setState({ workedHours: hours });
  };

  setWorkedMinutes = (minutes) => {
   this.setState({ workedMinutes: minutes });
};

  openWorkingHourPopUp = async() => {
    await this.setState({ 
                    isWorkingHour: true,
              });
  }

  closeWorkingHourPopUp = () =>{
    this.setState({isWorkingHour: false, workedHours: 0, workedMinutes: 0});
  }

  saveJiraTaskLogDetails = () => { 
    const { API_HOST, saveJiraTaskLogDetails } = this.props;

      this.setState({ isSubmitDisabled : true })
      var userId = JSON.parse(localStorage.getItem('userdetail')).Id_User; 
        let requestBody = {
          "Id" : 0,
          "Id_Jira_Task" : this.state.jiraIssue.id,
          "Id_User" : userId,
          "LogDateTime" : this.state.taskDate,
          "WorkedHours" : parseInt(this.state.workedHours * 60) + parseInt(this.state.workedMinutes),
          "Jira_Task_Key" : this.state.jiraIssue.key,
          "Jira_Task_Summary" : this.state.jiraIssue.fields.summary,
          "Description" : this.state.taskComment,
          "Jira_Cloud_Id": getEncryptedValue(this.props.cloudId),
          "Id_User_Jira_Detail": this.props.jiraDomainSelected.Id_User_Jira_Detail
        }


        if((this.state.workedHours === 0 && this.state.workedMinutes === 0) || (this.state.workedHours <= 0 && this.state.workedMinutes <= 0)) {
        
          toast.info("Please enter correct value for Hours");
          this.setState({ isSubmitDisabled:false });
          
        }else{
          saveJiraTaskLogDetails(API_HOST, requestBody, (res) => {
            if (res) {
              toast.success("Task Working Details saved successfully");
              this.props.getJiraTaskLogDetails(this.state.jiraIssue.id);
              this.setState({ isSubmitDisabled : false, isWorkingHour: false, workedHours: 0, workedMinutes: 0});
            }
        });
        }
    
  }

  handleHourIncrement = () => {
    if(this.state.workedHours <= 11) {
      this.setState({ workedHours: this.state.workedHours + 1 });
    }
  };

  handleHourDecrement = () => {
    if(this.state.workedHours >= 1)
    this.setState({ workedHours: this.state.workedHours - 1 });
  };

  checkWorkedHour = (workedHours) => {
    if(workedHours < 0 || workedHours > 12){
      toast.error("Please enter hours value between 1-12");
    }
  }

  handleMinutesIncrement = () => {
    if(this.state.workedMinutes <= 55) {
      this.setState({ workedMinutes: this.state.workedMinutes + 5 });
    }
  };

  handleMinutesDecrement = () => {
    if(this.state.workedMinutes >= 5)
    this.setState({ workedMinutes: this.state.workedMinutes - 5 });
  };

  checkWorkedMinutes = (workedMinutes) => {
    if(workedMinutes < 0 || workedMinutes > 59){
      toast.error("Please enter minutes value between 1-60");
    }
  }

  render() {

    const { totalWorkedHours, jiraTaskLogDetails}= this.state;
    console.log("JiraIssue: ",this.state.jiraIssue);
    console.log("Summary: ",this.state.summary);
    
    
    //console.log("abcd: ",jiraTaskLogDetails.splice(0, jiraTaskLogDetails.length - 1));
    const hours=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
    const minutes= [0,5,10,15,20,25,30,35,40,45,50,55];
   // var totalWorkedHours = jiraTaskLogDetails && jiraTaskLogDetails.length > 0 ? jiraTaskLogDetails[jiraTaskLogDetails.length - 1].WorkedHours : 0;
    var h = Math.floor(totalWorkedHours / 60);
    var m = totalWorkedHours % 60;
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
    var totalWorkedHoursDisplay = h + " : " + m;

    const timezone = JSON.parse(localStorage.getItem('userdetail')).TZ ? JSON.parse(localStorage.getItem('userdetail')).TZ : "Asia/Kolkata";
    const columns = [
      
      {
           Header: <strong style={{fontSize: '12.5px'}} className="my_custom_class">Name</strong>,
          accessor: 'Id',
          //minWidth: 0.3,
          Cell: (row) => {
            return <div onClick="">{row.original.User_RealName}</div>
          }
      },

      {
          Header: <strong style={{fontSize: '12.5px'}} className="my_custom_class">Comments</strong>,
        accessor: 'Description',
        //minWidth: 0.3,
        Cell: (row) => {

            return  (
            <div onClick=""> {row.original.Description}</div>
              )  
          }
      },

      {
        Header: <strong style={{fontSize: '12.5px'}} className="my_custom_class">Date</strong>,
       accessor: 'LogDateTime',
       Footer: <div>Total Hours</div>,
       //minWidth: 0.2,
       width: 95,
       Cell: (row) => {

        if(row.original.LogDateTime === "0001-01-01T00:00:00"){
          return <div> Total Hours</div>
        }else{

          return  <div className="d-block text-right" onClick="">{moment(row.original.LogDateTime).tz(timezone).format('MM/DD/YYYY')}</div>
          }
       }
      },

      {
        Header: <strong style={{fontSize: '12.5px'}} className="my_custom_class">Hrs:Mins</strong>,
        accessor: 'WorkedHours',
        Footer: <div>{totalWorkedHoursDisplay}</div>,
       // minWidth: 0.2,
       width: 75,
        Cell: (row) => {
          var h = Math.floor(row.original.WorkedHours / 60);
          var m = row.original.WorkedHours % 60;
          h = h < 10 ? '0' + h : h;
          m = m < 10 ? '0' + m : m;
          
          return(
          <div className="d-block text-right" onClick="">{h}:{m}</div>
          )
        }  
      },
    ];


    let options = [];  
    options.push({
      accountId: "-1",
      displayName: "Unassigned",
      label: <div><img src={UnassignedUser} alt="" height="20px" width="20px" />{"     Unassigned"}</div>,
      value: "-1",
    })
    this.state.assignUsersOption[0] && this.state.assignUsersOption.map(obj => {
      options.push({
        accountId: obj.accountId,
        accountType: obj.accountType,
        active: obj.active,
        displayName: obj.displayName,
        label: <div><img src={obj.avatarUrls["16x16"]} alt="" height="20px" width="20px" />{"     " + obj.displayName}</div>,
        value: obj.displayName
      })
      return options;
    })

    let statusCategoryOptions = [];
    this.state.statusCategory[0] && this.state.statusCategory.map(obj => {
      statusCategoryOptions.push({
        description: obj.description,
        id: obj.id,
        name: obj.name,
        value: obj.id,
        self: obj.self,
        statusCategory: obj.statusCategory,
        label: obj.name
      })
      return statusCategoryOptions;
    })
    return (
      // <Modal isOpen={this.props.isJiraIssue} size={'l'} backdrop={true}  >
      //   <ModalHeader toggle={() => this.props.toggleJiraIssue()}>
      //     <img src={this.state.issueTypeIcon} alt="" height="25px" width="25px" />
      //     {"    " + this.props.jiraIssue.key}
      //   </ModalHeader>
      //   <ModalBody>
      //     {/* <Scrollbars style={{ height: '800px' }} autoHide > */}
      //     <LoadingOverlay
      //       active={this.state.isActive}
      //       spinner
      //       text='Loading...'
      //     >
      <div className="jira-task-desc">
        <div className="jira-list-id">
          <img src={this.state.issueTypeIcon} alt="" height="25px" width="25px" />
          {"    " + this.props.jiraIssue.key}
          <i className="fa fa-close" onClick={() => this.props.toggleJiraIssue()}></i>
        </div>
            {!this.state.isSummaryEdit ?
              <h5 className="mb-3" onClick={() => { this.setState({ isSummaryEdit: true, editSummary: this.state.summary }) }}>{this.state.summary}</h5>
              : <>
                <textarea className="form-control" rows="1" placeholder="Add a description..." onChange={(e) => this.setState({ editSummary: e.target.value })} value={this.state.editSummary}></textarea>
                {this.state.summary !== this.state.editSummary ?
                  <div className="d-flex justify-content-end mt-4">
                    <div classname="col-sm-5">
                      <Button color="primary" disabled={this.state.isSummaryDisabled} onClick={this.onSaveSummary}>
                        <span>Save</span>
                        {this.state.isSummaryDisabled === true &&
                          <span style={{ marginLeft: '5px' }}>
                            <ClockLoader
                              size={16}
                              color={"#fff"}
                              loading={this.state.isSummaryDisabled}
                            />
                          </span>
                        }
                      </Button>
                    </div>
                    <div classname="col-sm-5">
                      <Button color="secondary" classname="col-sm-5" onClick={() => { this.setState({ isSummaryEdit: false }) }}>Cancel</Button>
                    </div>
                  </div>
                  : <br />}
              </>
            }
            <Row>
              <Col xs="5" >
                <Select
                  components={makeAnimated}
                  onChange={(newValue) => { this.setStatusforJira(newValue) }}
                  options={statusCategoryOptions}
                  value={this.state.statusSelected}
                />
              </Col>
            </Row>
            <br />
            <h6>Description: </h6>
            {!this.state.isDescriptionEdit && this.state.description && this.state.description.trim() ?
              <div onClick={() => { this.setState({ isDescriptionEdit: true, editDescription: this.state.description }) }}><pre style={{ whiteSpace: "pre-wrap" }}>{this.state.description}</pre></div>
              : <>
                <textarea className="form-control" rows="3" placeholder="Add a description..." onChange={(e) => this.setState({ editDescription: e.target.value, isDescriptionEdit: true })} value={this.state.editDescription}></textarea>
                {this.state.description !== this.state.editDescription && this.state.isDescriptionEdit ?
                  <div className="d-flex justify-content-end mt-4">
                    <div classname="col-sm-5">
                      <Button color="primary" disabled={this.state.disabled} onClick={this.onSaveDescription}>
                        <span>Save</span>
                        {this.state.disabled === true &&
                          <span style={{ marginLeft: '5px' }}>
                            <ClockLoader
                              size={16}
                              color={"#fff"}
                              loading={this.state.disabled}
                            />
                          </span>
                        }
                      </Button>
                    </div>
                    <div classname="col-sm-5">
                      <Button color="secondary" classname="col-sm-5" onClick={() => { this.setState({ isDescriptionEdit: false }) }} >Cancel</Button>
                    </div>
                  </div>
                  : null}
              </>
            }
            <br />

            <h6>Date: </h6>
              <DatePicker
                selected={this.state.taskDate}
                onChange={date => this.setState({ taskDate: date })}
                //timeFormat="HH:mm"
                //timeIntervals={1}
                //timeCaption="time"
                dateFormat="dd-MM-yyyy"
                className="form-control"
                  />
            <br />
            <br />

            <h6>Assignee: </h6>
            <Select
              components={makeAnimated}
              onChange={(newValue) => { this.userAssignChange(newValue) }}
              options={options}
              value={this.state.userSelected}
            />
            <br />
            <h6>Reporter: </h6>
            <div><img src={this.state.avatarUrls} alt="" height="20px" width="20px" />{"     " + this.state.reporterName}</div>
            <div></div>
            <br />

            <ReactTooltip id="worlClock" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
            <h6><span style={{'cursor': 'pointer'}} onClick={this.openWorkingHourPopUp} data-for="workClock" data-tip="Set Task worked hours">Worked Hours <i class="far fa-clock" style={{}}></i></span></h6>
            <div></div>
            <br />

            {jiraTaskLogDetails.length > 0 ? 
            <ReactTable
               // data={jiraTaskLogDetails.splice(0, jiraTaskLogDetails.length - 1)}
               data={jiraTaskLogDetails}
                columns={columns}
               // defaultPageSize={5}
                showPagination={false}
                showPageSizeOptions={false}
                className="-striped -highlight"
                //loading={this.state.loading};
                style={{'height': '250px'}}
            />
            : <div>No Worked Hours details available for this task.</div>}
                       
              <br />
              <h6>Comments</h6>
              <div className="comment-div my-scroll">
              {this.state.getAllComments[0] ?
                <> {this.state.getAllComments && this.state.getAllComments.map((issue) => (
                  <> <div className="row  align-items-center">
                    <div className="col-sm-1">
                      <img src={issue.author && issue.author.avatarUrls["16x16"]} alt="" height="30px" width="30px" />
                    </div>
                    <div className="col-sm-11">
                      <span>
                        {issue.author && issue.author.displayName}  <span style={{ left: "calc(100% + 12px)", top: "0", fontSize: "12px", color: "#111111", whiteSpace: "nowrap", color: "#6c757d" }}>{moment(issue.created).format("LLL")}</span>
                        <br />{issue.body}</span>
                    </div>
                  </div>
                    <br /></>
                ))}
                </> : null}
            </div>
            <textarea className="form-control" rows="1" placeholder="Add comments..." onChange={(e) => this.setState({ comments: e.target.value })} value={this.state.comments}></textarea>
            {this.state.comments !== "" ?
              <div className="d-flex justify-content-end mt-4">
                <div classname="col-sm-5">
                  <Button color="primary" disabled={this.state.isCommentsDisabled} onClick={this.addCommentToJiraIssue}>
                    <span>Save</span>
                    {this.state.isCommentsDisabled === true &&
                      <span style={{ marginLeft: '5px' }}>
                        <ClockLoader
                          size={16}
                          color={"#fff"}
                          loading={this.state.isCommentsDisabled}
                        />
                      </span>
                    }
                  </Button>
                </div>
              </div>
              : null}


              {this.state.isWorkingHour ? 
                      <>
                          <Modal isOpen={this.state.isWorkingHour} backdrop={true} >
                            <ModalHeader toggle={this.closeWorkingHourPopUp}>{this.state.jiraIssue.key} :</ModalHeader>

                                <ModalBody className="d-flex">
                                <div style={{width:'100%'}}>

                                  <span><b>Task: </b> {this.state.jiraIssue.fields.summary}</span>
                                   
                                  <div className="row mt-3 mb-3" >
                                    <div className="col-4">
                                    <h6>Date: </h6>
                                  <DatePicker
                                  className="form-control"
                                    selected={this.state.taskDate}
                                    onChange={date => this.setDate(date)}
                                    //selectsEnd
                                    //startDate={new Date()}
                                    //endDate={this.state.endDate}
                                    //minDate={new Date()}
                                    // inline
                                    // fixedHeight
                                    id="enddate"
                                  />
                                    </div>
                                    <div className="col-8">
                                    <h6>Worked Hours: Minutes: </h6>
                                  <div className="text-center d-flex align-items-center timings_todo">
                                  {/* <select style={{width:'100%'}} className="form-control" value={this.state.workedHours} onChange={newValue => this.setWorkedHours(newValue.target.value)}>
                                    {hours.map((h) => {
                                                    return (
                                                    <option value={h}>{h}</option>
                                                    )
                                                })}
                                  </select>
                                  <span style={{minWidth:'20px'}}>:</span>
                                  <select style={{width:'100%'}} className="form-control" value={this.state.workedMinutes} onChange={newValue => this.setWorkedMinutes(newValue.target.value)}>
                                  {minutes.map((m) => {
                                                  return (
                                                  <option value={m}>{m}</option>
                                                  )
                                              })}
                                  </select> */}


      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={this.handleHourIncrement}>+</Button>
        {/* <Button disabled>{this.state.workedHours}</Button> */}
        <input type='text' value={this.state.workedHours} onChange={e => {this.setState({ workedHours: e.target.value }); this.checkWorkedHour(e.target.value);}}/>
        <Button onClick={this.handleHourDecrement}>-</Button>
      </ButtonGroup> 
<span className="ml-1 mr-1">:</span>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={this.handleMinutesIncrement}>+</Button>
        {/* <Button disabled>{this.state.workedHours}</Button> */}
        <input type='text' value={this.state.workedMinutes} onChange={e => {this.setState({ workedMinutes: e.target.value }); this.checkWorkedMinutes(e.target.value);}}/>
        <Button onClick={this.handleMinutesDecrement}>-</Button>
      </ButtonGroup>
      


                                  </div>
                                    </div>
                                  </div>                      
 

                                  <h6>Description</h6>
                                  <div><textarea className="form-control" rows="3" placeholder="Description..." onChange={e =>this.setState({ taskComment: e.target.value })}></textarea></div>

                                  <br/>

                                  <Button type="submit" color="primary" disabled={this.state.isSubmitDisabled} onClick={this.saveJiraTaskLogDetails}>
                                    <span>save</span>
                                    {this.state.isSubmitDisabled === true && (
                                      <span style={{ marginLeft: "5px" }}>
                                        <ClockLoader
                                          size={16}
                                          color={"#fff"}
                                          loading={this.state.isSubmitDisabled}
                                        />
                                      </span>
                                    )}
                                  </Button>

                                    
                                  </div>
                                </ModalBody>
                          </Modal>
                      </>
                    : ""}


              </div>
      //     {/* </LoadingOverlay> */}
      //     {/* </Scrollbars> */}
      //     {/* <textarea></textarea> */}
      //     {/* <textarea ref={} onBlur={e => onBlur(e)} name={name} onKeyDown={e => onKeyHandle(e, value)} placeholder="Message" rows="1" value={value} onChange={e => setValue(e.target.value)} id="message" ></textarea> */}
      // //   </ModalBody>
      // // </Modal>

    );
  }
}

// Prop types of props.
JiraIssue.propTypes = {
  API_HOST: PropTypes.string,

};

// Set default props.
JiraIssue.defaultProps = {

};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),

  };
};
export default connect(mapStateToProps, {saveJiraTaskLogDetails})(JiraIssue);
