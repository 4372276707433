import axios from "axios";
//import { trackPromise } from "react-promise-tracker";
import { types as T } from "../actions/action";
import {
  handleError,
  handleResponse,
  checkClientErrorMessage,
} from "../utils/utility";
import { socket } from "../utils/apiUrls";

// Signup

export const receiveSignup = (signupResponse) => ({
  type: T.RECEIVE_SIGNUP,
  signupResponse,
});


/**
 * Signup
 *
 * @param {url} URL is API host domain.
 * @param {signupRequest} is request object to signup.
 * @return {object} return user details.
 */
export const signup = (url, signupRequest, callback) => (dispatch) => {
  const finalUrl = `${url}tenantmaster/savetenant`;

  // return trackPromise(
    axios.post(finalUrl, signupRequest)
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorMessage(result.SuccessData)) {
          handleError(result, "Signup");
        } else if (result.TokenData && result.TokenData.Token) {
          localStorage.setItem("userdetail", JSON.stringify(result.UserData));
          socket.emit("newUserDetail",result.UserData);
          sessionStorage.setItem(
            "userToken",
            // JSON.stringify(result.TokenData.Token).replace(/\"/g, "")
            JSON.stringify(result.TokenData.Token).replace(/"/g, "")
          );
          dispatch(receiveSignup(result.UserData));
        }
        if (!!callback) {
          callback({ stack: '' })
        }
      }).catch((error) => {
        handleError(error, "Signup");
        if (!!callback) {
          callback({ stack: 'Error' })
        }
      })
  // );
};
