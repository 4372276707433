import React from "react";
import { connect } from "react-redux";
import { getApiHost } from "../../../utils/apiUrls";
import {
  fetchSlackTeam,
  fetchSlackMember,
  fetchSlackUser,
  getUserDetail,
  importUserList,
  getTenantDetail,
  updateTenant,
  updateUser,
  updateProfilePic,
  changePassword,
  getCabinUserDetail,
  getUserStickyList,
  setReadUserSticky,
  addStickyNotesToToDo,
  fetchSearchUser,
  departmentListByUser,
  userListByDepartment,
  getSlackConfigurationlist,
  updateUserTimezone,
  addVirtualChatGroup,
  checkChannelAlreadyExist,
  getUserLeaveDetail,
  getUserNameByUserId,
  fetchMultipleEmail,
  changeWorkspace,
  importSlackStepsTrace,
  getTenantSlackUserAccessToken,
  deleteUserAccount,
  updateTenantSlackAccessToken,
  createDepartmentChat,
  syncWorkspace,
} from "../../../actions/dashboardActions";
import { fetchOffice, editOfficeName } from "../../../actions/officeActions";
import {
  getSlackTeamResponse,
  getSlackMemberResponse,
  slackUserResponse,
  userDetailResponse,
  getUserListResponse,
  tenantDetailResponse,
  updateTenantResponse,
  updateUserResponse,
  updateUserProfilePicResponse,
  changePasswordResponse,
  fetchOfficeResponse,
  getUserStickyListResponse,
  setReadUserStickyResponse,
  addStickyNotesToToDoResponse,
  searchUserResponse,
  departmentListByUserResponse,
  userListByDepartmentResponse,
  slackConfigListResponse,
  addVirtualChatGroupResponse,
  getslackUserDetailsResponse,
  userListDepartmentFilterResponse,
  userLeaveDetailResponse,
  userNameByUserIdResponse,
  multipleEmailResponse,
  changeWorkspaceResponse,
  getTenantSlackUserAccessTokenResponse,
  departmentListFilterWiseResponse,
  userKnockListCabinWiseResponse,
} from "../selector";
import {
  getUserListDepartmentFilter,
  doorOpen,
  setUserCustomStatus,
  getUserListDepartmentUpdateOfficeTime,
  userKnockListCabinWise,
  stickyCount,
  userKnockSeen,
  userStickySeen,
  GetStickyCount,
} from "../../../actions/mainOfficeActions";
import { zoomMeetingJoinLeave } from "../../../actions/zoomActions";
import {
  addWaterCoolerGroup,
  updateUserTour,
} from "../../../actions/waterCoolerActions";
import { slackUserDetails } from "../../../actions/slackUserDetailsActions";
import { getChannelUsersListCount } from "../../../actions/progressBarActions";

const HOC = (WrappedComponent) => {
  const Decorator = (props) => <WrappedComponent {...props} />;

  const mapStateToProps = (state) => ({
    API_HOST: getApiHost(),

    slackTeamResponse: getSlackTeamResponse(state).slackTeamResponse,

    slackMemberResponse: getSlackMemberResponse(state).slackMemberResponse,

    slackUserLoading: slackUserResponse(state).loading,
    slackUserResponse: slackUserResponse(state).slackUserResponse.SourceData,
    memberListResponse: slackUserResponse(state).slackUserResponse.SourceData,

    userDetailLoading: userDetailResponse(state).loading,
    userDetailResponse: userDetailResponse(state).userDetailResponse,

    importUserListResponse: getUserListResponse(state).importUserListResponse,

    tenantDetailLoading: tenantDetailResponse(state).loading,
    tenantDetailResponse: tenantDetailResponse(state).tenantDetailResponse,

    updateTenantLoading: updateTenantResponse(state).loading,
    updateTenantResponse: updateTenantResponse(state).updateTenantResponse,

    updateUserLoading: updateUserResponse(state).loading,
    updateUserResponse: updateUserResponse(state).updateUserResponse,

    updateUserProfilePicLoading: updateUserProfilePicResponse(state).loading,
    updateUserProfilePicResponse:
      updateUserProfilePicResponse(state).updateUserProfilePicResponse.API_HOST,

    changePasswordLoading: changePasswordResponse(state).loading,
    changePasswordResponse:
      changePasswordResponse(state).changePasswordResponse,

    fetchOfficeLoading: fetchOfficeResponse(state).fetchOfficeLoading,
    fetchOfficeResponse: fetchOfficeResponse(state).fetchOfficeResponse,

    multipleEmailLoading: multipleEmailResponse(state).loading,
    multipleEmailResponse: multipleEmailResponse(state).multipleEmailResponse,

    changeWorkspaceResponse:
      changeWorkspaceResponse(state).changeWorkspaceResponse,

    getUserStickyListResponse:
      getUserStickyListResponse(state).getUserStickyListResponse,

    setReadUserStickyResponse:
      setReadUserStickyResponse(state).setReadUserStickyResponse,

    addStickyNotesToToDoResponse:
      addStickyNotesToToDoResponse(state).addStickyNotesToToDoResponse,

    searchUserResponse: searchUserResponse(state).searchUserResponse,

    departmentListByUserResponse:
      departmentListByUserResponse(state).departmentListByUserResponse,

    userListByDepartmentResponse:
      userListByDepartmentResponse(state).userListByDepartmentResponse,

    slackConfigListResponse:
      slackConfigListResponse(state).slackConfigListResponse,

    getTenantSlackUserAccessTokenResponse:
      getTenantSlackUserAccessTokenResponse(state)
        .getTenantSlackUserAccessTokenResponse,

    addVirtualChatGroupResponse:
      addVirtualChatGroupResponse(state).addVirtualChatGroupResponse,

    userLeaveDetailResponse:
      userLeaveDetailResponse(state).userLeaveDetailResponse,

    userNameByUserIdResponse:
      userNameByUserIdResponse(state).userNameByUserIdResponse,

    slackUserDetailsResponse: getslackUserDetailsResponse(state),
    userListDepartmentFilterResponse: userListDepartmentFilterResponse(state),
    departmentListFilterWiseResponse: departmentListFilterWiseResponse(state),
    userKnockListCabinWiseResponse: userKnockListCabinWiseResponse(state),
  });

  const mapDispatchToProps = {
    fetchSlackTeam,
    fetchSlackMember,
    fetchSlackUser,
    getUserDetail,
    importUserList,
    getTenantDetail,
    updateTenant,
    updateUser,
    updateProfilePic,
    changePassword,
    fetchOffice,
    editOfficeName,
    getCabinUserDetail,
    getUserStickyList,
    setReadUserSticky,
    addStickyNotesToToDo,
    fetchSearchUser,
    departmentListByUser,
    userListByDepartment,
    getSlackConfigurationlist,
    updateUserTimezone,
    getUserListDepartmentFilter,
    getUserListDepartmentUpdateOfficeTime,
    doorOpen,
    setUserCustomStatus,
    addVirtualChatGroup,
    zoomMeetingJoinLeave,
    addWaterCoolerGroup,
    updateUserTour,
    slackUserDetails,
    getChannelUsersListCount,
    checkChannelAlreadyExist,
    getUserLeaveDetail,
    getUserNameByUserId,
    fetchMultipleEmail,
    changeWorkspace,
    importSlackStepsTrace,
    getTenantSlackUserAccessToken,
    deleteUserAccount,
    updateTenantSlackAccessToken,
    createDepartmentChat,
    syncWorkspace,
    userKnockListCabinWise,
    stickyCount,
    userKnockSeen,
    userStickySeen,
    GetStickyCount,
  };

  return connect(mapStateToProps, mapDispatchToProps)(Decorator);
};

export default HOC;
