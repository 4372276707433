import socketIOClient from "socket.io-client";
//import pkg from '../../package.json';


export const getSettings = state => state.pkg.settings;

// Get API host link
export const getApiHost = () => {
    //return pkg.settings.API_HOST
    return process.env.REACT_APP_API_HOST
}

// Get Soket Url
export const getSoketUrl = () => {
    //return pkg.settings.SOKET_URL
    return process.env.REACT_APP_SOKET_URL
}

// Get Slack Url
export const getSlackUrl = () => {
    //return pkg.settings.SLACK_URL
    return process.env.REACT_APP_SLACK_URL
}

// RTM Connection
const RTM_ENDPOINT = getSoketUrl();
export const socket = socketIOClient(RTM_ENDPOINT, {
    reconnection: true,             // whether to reconnect automatically
    reconnectionAttempts: 10,       // number of reconnection attempts before giving up
    reconnectionDelay: 1000,        // how long to initially wait before attempting a new reconnection
    reconnectionDelayMax: 5000,     // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
    randomizationFactor: 0.5
});

//Slack Connection
const SLACK_ENDPOINT = getSlackUrl(); // http://localhost:8002
export const slack_socket = socketIOClient(SLACK_ENDPOINT, {
    reconnection: true,             // whether to reconnect automatically
    reconnectionAttempts: 10,       // number of reconnection attempts before giving up
    reconnectionDelay: 1000,        // how long to initially wait before attempting a new reconnection
    reconnectionDelayMax: 5000,     // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
    randomizationFactor: 0.5
});
