import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

class PrivacyPolicy extends React.Component {
  state = {};

  componentDidMount = () => {
    document.title = this.props.title; // Set title of page.
  };
  componentWillReceiveProps = (nextProps) => { };

  render() {
    return (
      <div className="wrapper term-condition">
        <div className="container">
          {/* <Link to={"/login"}> */}
          <img className="img" alt="logo.png" src="images/logo.png" />
          {/* </Link> */}

          <div className="card">
            <div className="card-title"><h4>Privacy Policy</h4></div>
            <p>Your privacy is important to us. It is Precis's policy to respect your privacy regarding any information we may collect from you across our website, precis.com, and other sites we own and operate.</p>
            <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
            <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</p>
            <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
            <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
            <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>

            <div class="policy-content mt-4">
              <div class="card-title"><h4>Precis Restricted Use </h4></div>
              <div>
                <p>We made Precis to improve collaboration and empathy across regional borders. It was built so that someday, if two or more people who live in
                            different parts of the world have a great idea for a company, they’d be able to start that company on the same day. Frictionlessley.</p>
                <p>We are proud of the trust you put in us as your choice of telepresence software and take this trust seriously. However, we also understand that
                                technology can be an amplifier for good or ill, despite the best of intentions. The below are things that we do not support as part of Precis. If you do any of the below, we reserve the right to take action.</p>
              </div>
              <div class="mb-2">
                <strong>Spamming</strong>
                <p>We built Precis to respect your attention. We don’t want it used to spam anyone.</p>
              </div>
              <div class="mb-2">
                <strong>Human exploitation </strong>
                <p> We don’t tolerate activities that enable or create human exploitation, even if it’s legal in your area.</p>
              </div>
              <div class="mb-2">
                <strong>Violence and Organizing for Violence </strong>
                <p> You cannot use Precis to threaten or organize to threaten other people.</p>
              </div>
              <div class="mb-2">
                <strong> Doxxing</strong>
                <p>You’re not allowed to disseminate private information of others in order to harass them. </p>
              </div>
              <div class="mb-2">
                <strong> </strong>
                <p> </p>
              </div>
              <div class="mb-2">
                <strong> Spyware</strong>
                <p> You are not allowed to use or modify Precis to create, install or otherwise enable the surveillance of people without their knowledge and consent.</p>
              </div>
              <div class="mb-2">
                <strong>Malware</strong>
                <p> You’re not allowed to use Precis to distribute malware</p>
              </div>
              <div class="mb-2">
                <strong> Must be a human</strong>
                <p>While some AI has been used in the creation of Precis, we don’t allow bots to have Precis accounts. </p>
              </div>
              <div class="mb-2">
                <strong>How to report abuse </strong>
                <p>We take this stuff seriously. We think that working with others to make something bigger than ourselves is one of the best shared experiences we
                            humans can share. If you know someone who violates any of the above, please send email to abuse@PrecisApp.io . We’ll take care of it </p>
              </div>
            </div>

          </div>
          <div className="text-center">
            <div className="policy-link">
              <span></span>
              <Link to={"/login"} className="have-acc">
                Login
                </Link>
              {"  "}/ {"  "}
              <Link to={"/signup"} className="have-acc">
                Sign Up
                </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withRouter(PrivacyPolicy);
