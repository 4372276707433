import { types as T } from "../actions/action";
import { defaultStore } from "../store";

const signupReducer = (state = defaultStore, action) => {
  switch (action.type) {

    case T.RECEIVE_SIGNUP:
      return {...state, 
        signupResponse: {
          ...state.signupResponse,
          loading: false,
          signupError: false,
          signupErrorMessage: null,
          signupResponse: action.signupResponse,
        },
      };
      
    default:
      return state
  }
};

export default signupReducer;
