// import React, { useState, useRef, useEffect } from 'react';
// import { slack_socket } from "../../../utils/apiUrls";
// import AutocompleteTextField from './AutocompleteTextField';

//  export function InputText(props) {

//     const [value, setValue] = useState("");
//    // const [options, setOptions] = useState(["apple apple apple", "apricot", "banana", "carrot"]);
    
//     const [options, setOptions] = useState(props.projectName);
//     //console.log("*************",props.projectName);
//     const { onBlur = (e) => { console.log(e) }, name = 'message', sendMsg = () => { }, jiraBacklogIssue = () => { }, channelDetails, userDetails, projectName, projectKey, backlogIssuesTaskList } = props
//     //const { name = 'message', sendMsg = () => { }, channelDetails, userDetails } = props
//     console.log("projectName", projectName);
//     console.log("projectKey",projectKey);

//     const myInput = useRef();
//     useEffect(() => myInput.current && myInput.current.focus());

//     var typing = false;
//     var timeout = undefined;
//     var displayName = JSON.parse(localStorage.getItem('userdetail')).Real_Name;
//     var userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;

//     const typingTimeout = () => {
//         typing = false
//         let data = {};
//         if (userDetails) {
//             data = { user: userDetails, typing: false }
//         } else {
//             data = { channelDetails: channelDetails, typing: false }
//         }
//         slack_socket.emit('typing', data)
//     }

//     const onKeyHandle = (e, value) => {
//         if (e.which !== 13) {
//             let temp = displayName.split(" ")[0];
//             let data = {};
//             if (userDetails) {
//                 data = { typing: true, Display_Name: temp, user: userDetails, channelType: 'single', Id_User: userId }
//             } else {
//                 data = { channelDetails: channelDetails, typing: true, Display_Name: temp, channelType: 'group' }
//             }
//             typing = true
//             slack_socket.emit('typing', data)
//             clearTimeout(timeout)
//             timeout = setTimeout(typingTimeout, 2000)
//         }
//         else {
//             clearTimeout(timeout)
//             typingTimeout()
//             sendMsg(value);
//             setValue("");
//         }

//         // if (e.key === 'Enter') {
//         //     e.preventDefault();
//         //     sendMsg(value);
//         //     setValue("");
//         // }
//     }

//     const sendMessage = (value) => {
//         sendMsg(value);
//         setValue("");
//     }

//     const changeValue = (value) => {
//         setValue(value);
//     }

//     const getJiraTasksList = (newValue) => {
//         let temp = '';
//         let val = 0;
//         temp = newValue.split('@Jira/')[1];
//         temp = temp.split('/')[0]
//         val = projectName.indexOf(temp);
//         // index = a.findIndex(x => x.prop2 ==="yutu");
//         console.log("Index: ",val);

//         jiraBacklogIssue(projectKey[val]);

//        // console.log("getJiraTasksList CALLED:",temp);  //getJiraTasksList CALLED @Jira/Apiant Data Mine/
//     }


//     return (
//         <div class="chat-area-footer">
//             <div className="chat-control-area">
            
//                 <div className="inout-group">
//                 {/* <AutocompleteTextField ref={myInput} name={name} onKeyPress={e => onKeyHandle(e, value)} placeholder="Message" rows="1" value={value} onChange={e => setValue(e.target.value)} id="message" options={options} offsetX={15} onBlur={e => onBlur(e)} name={name} trigger="/"/> */}
//                 <AutocompleteTextField maxOptions="50" options={projectName} offsetY={15} onBlur={e => onBlur(e)} name={name} placeholder="Message" rows="1" trigger="@Jira/" spacer="/" onSelect={newValue => getJiraTasksList(newValue)} value={value} onChange={e => changeValue(e)} onKeyDown={e => onKeyHandle(e, value)} backlogIssuesTaskList={backlogIssuesTaskList}/>
//                     {/* <textarea ref={myInput} name={name} onKeyPress={e => onKeyHandle(e, value)} placeholder="Message" rows="1" value={value} onChange={e =>setValue (e.target.value)} id="message" ></textarea> */}
//                     <div className="chat-emoji"><i class="far fa-smile"></i></div>
//                 </div>
//                 <button className="chat-send-btn"><i class="far fa-paper-plane" onClick={e => sendMessage(value)}></i></button>

//             </div>
//         </div>
//     );


    
// }

// //https://stackoverflow.com/questions/33266156/react-redux-input-onchange-is-very-slow-when-typing-in-when-the-input-have-a




import React, { useState, useRef, useEffect } from 'react';
import { slack_socket } from "../../../utils/apiUrls";
import { Picker } from 'emoji-mart';

export function InputText(props) {
    const [value, setValue] = useState("");
    const { name = 'message', isEmoji , isGroupChatWindow, sendMsg = () => { }, channelDetails, openEmoji, selectEmoji, userDetails } = props
    const myInput = useRef();
    useEffect(() => myInput.current && myInput.current.focus());

    var typing = false;
    var timeout = undefined;
    var displayName = JSON.parse(localStorage.getItem('userdetail')).Real_Name;
    var userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;

    const typingTimeout = () => {
        typing = false
        let data = {};
        if (userDetails) {
            data = { user: userDetails, typing: false }
        } else {
            data = { channelDetails: channelDetails, typing: false }
        }
        slack_socket.emit('typing', data)
    }

    const onKeyHandle = (e, value) => {
        if (e.which !== 13) {
            let temp = displayName.split(" ")[0];
            let data = {};
            if (userDetails) {
                data = { typing: true, Display_Name: temp, user: userDetails, channelType: 'single', Id_User: userId }
            } else {
                data = { channelDetails: channelDetails, typing: true, Display_Name: temp, channelType: 'group' }
            }
            typing = true
            slack_socket.emit('typing', data)
            clearTimeout(timeout)
            timeout = setTimeout(typingTimeout, 2000)
        } else {
            clearTimeout(timeout)
            typingTimeout()
            sendMsg(value);
            setValue("");
        }

        // if (e.key === 'Enter') {
        //     e.preventDefault();
        //     sendMsg(value);
        //     setValue("");
        // }
    }

    const sendMessage = (value) => {
        sendMsg(value);
        setValue("");
    }
    console.log("isGroupChatWindow: ",isGroupChatWindow);

    return (
        <>
        <div class="chat-area-footer">
            <div className="chat-control-area">
                <div className="inout-group">
                    <textarea ref={myInput} name={name} onKeyPress={e => onKeyHandle(e, value)} placeholder="Message" rows="1" value={value} onChange={e => setValue(e.target.value)} id="message" ></textarea>
                    <div className="chat-emoji" onClick={openEmoji} style={{cursor: 'pointer'}}>{!isEmoji ? <i class="far fa-smile"></i> : <i class="fas fa-chevron-down"></i>}</div>
                </div>
                <button className="chat-send-btn"><i class="far fa-paper-plane" onClick={e => sendMessage(value)}></i></button>
            </div>
        </div>
        {/* {isEmoji && <Picker style={{ width:`${isGroupChatWindow}` === true ? '1390px' : '350px' }} onSelect={emoji => selectEmoji(emoji)} title='Pick your emoji…' emoji='point_up' />} */}
        {isEmoji && isGroupChatWindow && <Picker showPreview={false} showSkinTones={false} include={["search","recent","smileys","people"]} style={{ width: '1390px' }} onSelect={(emoji) => {selectEmoji(emoji);setValue(emoji.native);}} title='Pick your emoji…' emoji='point_up' />}
        {isEmoji && !isGroupChatWindow && <Picker showPreview={false} showSkinTones={false} include={["search","recent","smileys","people"]} style={{ width: '350px' }} onSelect={(emoji) => {selectEmoji(emoji);setValue(emoji.native);}} title='Pick your emoji…' emoji='point_up' />}
        </>
    );
}
//https://stackoverflow.com/questions/33266156/react-redux-input-onchange-is-very-slow-when-typing-in-when-the-input-have-a
