import React, { useState } from 'react';
import Avatar from 'avataaars'; 
import ItemsCarousel from 'react-items-carousel'; 
var moment = require('moment-timezone');

function SelectedUsers(props) {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    return (
        <div style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={props.numberOfCard}
                gutter={20}
                leftChevron={<button>{'<'}</button>}
                rightChevron={<button>{'>'}</button>}
                outsideChevron
                chevronWidth={chevronWidth}
            >
                {props.selectedUsersForMeeting && props.selectedUsersForMeeting.map((user) => {
                    const profilePic = JSON.parse(user.ProfilePic)
                    const time = user.TZ ? moment().tz(user.TZ).format('HH:mm') : ""
                    return (
                        <div className="user-card-list user-cabin handle" key={user.Id} onDoubleClick={() =>  user.Is_DoorOpen? props.handleAddChat(user):null} >
                            <div className="slide">
                            <div class={`card  ${user.Is_DoorOpen ? " card-door-open" : ""} ${user.UserStartTime_EndTimeVM.Availibility_Status === "OOO" || user.UserStartTime_EndTimeVM.Availibility_Status === "On leave/vacation" ? "out-office" : ""} ${user.Is_Admin ? " card-admin" : ""}`} 
                            onMouseEnter={(e)=>{e.stopPropagation();props.onEnterUserCard(user.Id_User)}} >
                                <span className="status">
                                        {user.UserStartTime_EndTimeVM.Availibility_Status}
                                        {user.UserStatus && !(user.UserStatus === "Available" || user.UserStatus === "available") ?
                                            <span className="meeting-with"> {user.UserStatus} </span>
                                            :
                                            null}
                                    </span>
                                   <span className="ot-time"> <span>Usually</span> {user.UserStartTime_EndTimeVM && user.UserStartTime_EndTimeVM.Availibility_Time !== "" ? moment(user.UserStartTime_EndTimeVM.Availibility_Time.substr(0, 5), 'HH:mm').tz(user.TZ).format('HH:mm') + " - " + moment(user.UserStartTime_EndTimeVM.Availibility_Time.substr(user.UserStartTime_EndTimeVM.Availibility_Time.length - 5), 'HH:mm').tz(user.TZ).format('HH:mm') : ""}</span>
                                    <span className={`time-zone ${time.slice(0, 5).replace(":", "") <= user.UserStartTime_EndTimeVM.Availibility_Time.substr(user.UserStartTime_EndTimeVM.Availibility_Time.length - 5).replace(":", "") && time.slice(0, 5).replace(":", "") >= user.UserStartTime_EndTimeVM.Availibility_Time.substr(0, 5).replace(":", "") ? "day-time" : "night-time"}`}>  {time}  <img src="images/watch-bg.png" alt="" /></span>
                                    <div className="card-img">
                                        <img src="images/pc-desktop.png" className="img-fluid user-desk-img" alt="" />
                                        <Avatar
                                            avatarStyle='Transparent'
                                            topType={profilePic["topType"]}
                                            accessoriesType={profilePic["accessoriesType"]}
                                            hairColor={profilePic["hairColor"]}
                                            facialHairType={profilePic["facialHairType"]}
                                            clotheType={profilePic["clotheType"]}
                                            clotheColor={profilePic["clotheColor"]}
                                            eyeType={profilePic["eyeType"]}
                                            eyebrowType={profilePic["eyebrowType"]}
                                            mouthType={profilePic["mouthType"]}
                                            skinColor={profilePic["skinColor"]}
                                            graphicType={profilePic["graphicType"]}
                                            hatColor={profilePic["hatColor"]}
                                        />
                                    </div>
                                    <strong className="name-time">{user.Real_Name} </strong>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </ItemsCarousel>
        </div>
    );
}
export default React.memo(SelectedUsers)