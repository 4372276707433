// import React from 'react';
// import { connect } from 'react-redux';
// import { getApiHost } from "../../../utils/apiUrls";
// import PropTypes from "prop-types";
// import { setDoneUserToDo, addToDoTask, saveReminder, userToDoListFetch } from "../../../actions/todoActions";
// import moment from 'moment';
// import Tooltip from "@material-ui/core/Tooltip";
// import Switch from "react-switch";
// import Reminder from './Reminder';
// import { FormGroup } from 'reactstrap';
// import { Card, CardContent, Typography } from '@material-ui/core'
// import LoadingOverlay from 'react-loading-overlay';
// import { Scrollbars } from 'react-custom-scrollbars';

// class ToDoList extends React.Component {

//   constructor(props) {
//     super(props)
//     this.state = {
//       todoList: this.props.todoList,
//       onAddTask: false,
//       Description: '',
//       showAddButton: true,
//       isReminder: false,
//       checked: false,
//       taskId: 0,
//       reminderList: this.props.reminderList,
//       isActive: false
//     };
//   }

//   // static getDerivedStateFromProps(nextProps, prevState) {
//   //   if (nextProps.userToDoListResponse !== prevState.todoList) {
//   //     return {
//   //       todoList: nextProps.userToDoListResponse
//   //     }
//   //   }
//   //   return null
//   // }

//   async componentDidMount() {
//     if (this.props.todoList.length === 0) {
//       await this.userToDoList();
//     }
//   }

//   componentDidUpdate(prevProps) {
//     if (prevProps.reminderList !== this.props.reminderList) {
//       this.setState({
//         reminderList: this.props.reminderList
//       })
//     }
//     if (prevProps.todoList !== this.props.todoList) {
//       this.setState({
//         todoList: this.props.todoList
//       })
//     }
//   }

//   //Get user To Do List
//   userToDoList = () => {
//     var userData = localStorage.getItem('userdetail');
//     const { API_HOST, userToDoListFetch } = this.props;
//     this.setState({ isActive: true })
//     userToDoListFetch(API_HOST, JSON.parse(userData).Id_User, (res) => {
//       this.setState({ isActive: false })
//     });
//   }

//   onClickAddTask = () => {
//     this.setState({
//       onAddTask: true,
//       showAddButton: false
//     })
//   };

//   //Set ToDo Task as Done
//   setDoneUserToDo = (todo_Id) => {
//     this.setState(prevState => ({
//       todoList: prevState.todoList.filter(list => list.Id !== todo_Id)
//     }));

//     const { API_HOST, setDoneUserToDo } = this.props;
//     setDoneUserToDo(API_HOST, todo_Id, (res) => {
//       //this.userToDoList();
//     })
//   }

//   // add Task to To Do List
//   addToDoTask = () => {
//     if (this.state.Description.length > 0) {
//       const { API_HOST, addToDoTask } = this.props;
//       const addToDoTaskRequest = {
//         "Description": this.state.Description
//       };
//       this.setState({ isActive: true })
//       addToDoTask(API_HOST, addToDoTaskRequest, (res) => {
//         this.userToDoList();
//       });
//     }

//     this.setState({
//       onAddTask: false,
//       Description: '',
//       showAddButton: true
//     })
//   };

//   handleAddToDoTask = (e) => {
//     this.setState({
//       [e.target.name]: e.target.value
//     })
//   };

//   //On press on Enter Key Add task
//   onKeyDownHandler = e => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       this.addToDoTask();
//     }
//   };

//   setToDoReminder = (checked, id) => {
//     if (checked === true) {
//       let newData = this.state.todoList.map(item => {
//         if (item.Id === id) {
//           item.Is_Reminder = checked
//         }
//         return item
//       });
//       this.setState(Object.assign({}, { todoList: newData, checked: checked, taskId: id, isReminder: !this.state.isReminder }));
//     } else {
//       let newData = this.state.todoList.map(item => {
//         if (item.Id === id) {
//           item.Is_Reminder = checked
//         }
//         return item
//       });
//       this.setState(Object.assign({}, { todoList: newData, checked: checked, taskId: id, isReminder: false }));
//       const { API_HOST, saveReminder } = this.props;
//       const reminderRequest = {
//         "Id": id,
//         "DueDate": '0001-01-01T00:00:00',
//         "Reminder_Status": 0, // 3
//         "Is_Reminder": checked
//       };
//       saveReminder(API_HOST, reminderRequest, (res) => {
//         this.userToDoList()
//       });
//     }
//   }

//   render() {
//     const { isModal, isShow } = this.props;
//     const { todoList, isReminder, checked, taskId, reminderList, isActive } = this.state;

//     return (
//       <>
//         {isShow === '' ? <div
//           className={isModal ? "modal model-right-bar" : "model-right-bar"}
//           id="userloginmodel"
//           role="dialog"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >

//           <div className="modal-dialog" role="document">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title" id="exampleModalLabel" >
//                   To Do List
//                    </h5>
//                 <div className="close b-left" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle} style={{ cursor: 'pointer' }}>
//                   <i className="fa fa-close" />
//                 </div>
//               </div>

//               {this.state.onAddTask ? (
//                 <React.Fragment>
//                   <Tooltip
//                     title="Press Shift+Enter to create a new line"
//                     placement="left"
//                   >
//                     <div>
//                       <textarea
//                         style={{ marginTop: '10px', marginLeft: '8px', width: '94%' }}
//                         type="textarea"
//                         className="form-control"
//                         id="Description"
//                         aria-describedby="Description"
//                         placeholder=""
//                         rows="1"
//                         cols="8"
//                         value={this.state.Description}
//                         name="Description"
//                         autoFocus
//                         //onBlur={this.addToDoTask}
//                         onKeyDown={this.onKeyDownHandler}
//                         onChange={(e) => this.handleAddToDoTask(e)}
//                       >
//                       </textarea>
//                     </div>
//                   </Tooltip>
//                 </React.Fragment>
//               ) : ""}
//               <Scrollbars style={{ height: '800px' }} autoHide >
//                 <LoadingOverlay
//                   active={isActive}
//                   spinner
//                   text='Loading...'
//                 >
//                   <div className="modal-body ">
//                     <div className="task-add">

//                       {this.state.showAddButton ? (
//                         <button type="button" class="btn btn-primary" onClick={this.onClickAddTask}>Add Task</button>
//                       ) : ""}

//                     </div>

//                     <div className="todolist-panel">
//                       {todoList[0] !== null ? (
//                         <div>
//                           {todoList.sort((a, b) => a.Id < b.Id ? 1 : -1).map((list) => (
//                             <>
//                               {/* <Tooltip
//                                 title="Mark Complete"
//                                 placement="left"
//                               > */}
//                               <div onClick={() => this.setDoneUserToDo(list.Id)}>
//                                 <i class="fa fa-circle-thin" aria-hidden="true"></i>
//                                 <i class="fa fa-check" aria-hidden="true"></i>
//                                 <label style={list.Is_After24Hrs ? { color: "red" } : { color: "black" }}>
//                                   <span dangerouslySetInnerHTML={{ __html: list.description }} />
//                                 </label>
//                               </div>
//                               {/* </Tooltip> */}
//                               <div style={{ float: 'right', marginTop: '-10%' }}>
//                                 <Switch
//                                   onChange={(checked) => this.setToDoReminder(checked, list.Id)}
//                                   checked={list.Is_Reminder}
//                                   height={18}
//                                   width={40}
//                                 />
//                               </div>
//                             </>
//                           ))}

//                         </div>
//                       ) : <i>"You do not have any Tasks pending"</i>}
//                     </div>
//                   </div>
//                 </LoadingOverlay>
//               </Scrollbars>
//             </div>
//           </div>

//           {isReminder === true && <Reminder isReminder={isReminder} checked={checked} taskId={taskId} setToDoReminder={this.setToDoReminder} userToDoList={this.userToDoList} />}
//         </div> :
//           <div className={isModal ? "modal model-right-bar" : "model-right-bar"}
//             id="userloginmodel"
//             role="dialog"
//             aria-labelledby="exampleModalLabel"
//             aria-hidden="true"
//           >
//             <div className="modal-dialog" role="document">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title" id="exampleModalLabel" >
//                     Reminders
//                    </h5>
//                   <div className="close b-left" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle} style={{ cursor: 'pointer' }}>
//                     <i className="fa fa-close" />
//                   </div>
//                 </div>
//                 <div className="modal-body myscroll">
//                   <div className="todolist-panel">
//                     {reminderList && reminderList.sort((a, b) => a.Id > b.Id ? 1 : -1)
//                       .map(remind => {
//                         return (
//                           <FormGroup>
//                             <Card>
//                               <CardContent>
//                                 <Typography variant="body2" component="h5">{remind.description}</Typography>
//                                 <Typography style={{ fontSize: 'small' }}>{moment(remind.DueDate).format('MMM DD, HH:mm')}</Typography>
//                               </CardContent>
//                             </Card>
//                           </FormGroup>
//                         )
//                       })}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         }
//       </>
//     );
//   }

// }

// // Prop types of props.
// ToDoList.propTypes = {
//   API_HOST: PropTypes.string,
//   userToDoListFetch: PropTypes.func,
//   //userToDoListResponse: PropTypes.array,
// };

// // Set default props.
// ToDoList.defaultProps = {
//   userToDoListFetch: () => { },
//   //userToDoListResponse: [],
// };

// const mapStateToProps = state => ({
//   API_HOST: getApiHost(),
//   //userToDoListResponse: state.todoReducer.userToDoListResponse
// })
// export default connect(mapStateToProps, { userToDoListFetch, setDoneUserToDo, addToDoTask, saveReminder })(ToDoList)

/******************NEW TO DO Changes*********************/

import React from "react";
import { connect } from "react-redux";
import { getApiHost } from "../../../utils/apiUrls";
import PropTypes from "prop-types";
import {
  setDoneUserToDo,
  addToDoTask,
  saveReminder,
  userToDoListFetch
} from "../../../actions/todoActions";
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button
} from "reactstrap";
import ReactTable from "react-table-6";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "react-switch";
import Reminder from "./Reminder";
//import { Card, CardContent, Typography } from '@material-ui/core'
import LoadingOverlay from "react-loading-overlay";
import { Scrollbars } from "react-custom-scrollbars";
import ToDoDetails from "./ToDoDetails";
import AddNewTask from "../../jira/components/AddNewTask";
import { toast } from "react-toastify";
import {
  getJiraDomain,
  getUserJiraRefreshToken,
  getJiraConfig,
  saveUserToDoFromJiraTask,
  updateUserJiraRefreshToken,
  saveJiraTaskLogDetails,
  getJiraTaskLogDetails
} from "../../../actions/jiraActions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  getEncryptedValue,
  getDecryptedValue
} from "../../../utils/utility";
import TimePicker from "rc-time-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUsersList } from "../../../actions/departmentActions";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClockLoader from "react-spinners/ClockLoader";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ReactTooltip from "react-tooltip";

import ButtonGroup from "@material-ui/core/ButtonGroup";

const axios = require("axios");
const qs = require("qs");

class ToDoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todoList: [],
      pendingTasks: [],
      onAddTask: false,
      Description: "",
      showAddButton: true,
      isReminder: false,
      checked: false,
      taskId: 0,
      reminderList: this.props.reminderList,
      isActive: false,
      toDoTaskDetails: {},
      isTaskDetails: false,
      jiraIssue: {},
      cloudId: "",
      accessToken: "",
      refreshToken: "",
      jiraClientId: "",
      jiraClientSecret: "",
      JiraTaskList: [],
      jiraDomain: [],
      jiraDomainSelected: {},
      idJiraTask: 0,
      jiraCloudId: "",
      taskDescription: "",
      taskDate: new Date(),
      taskComment: "",
      workedHours: 0,
      workedMinutes: 0,
      taskKey: "",
      jiraTaskLogDetails: [],
      showWorkingHours: false,
      loading: false,
      isAccordionExpand: true,
      userList: [],
      selectedUserId: 0,
      isAddToJiraTask: false,
      isToDoDescription: false,
      todoDescription: "",
      todoId: 0,
      selectedUserName: ""
    };
  }

  async componentDidMount() {
    await this.getJIRAConfig();
    await this.userToDoList(0);
    await this.getUsersList(0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reminderList !== this.props.reminderList) {
      this.setState({
        reminderList: this.props.reminderList
      });
    }
    if (prevProps.todoList !== this.props.todoList) {
      this.setState({
        todoList: this.props.todoList
      });
    }
    if (prevProps.toDoTaskDetails != this.props.toDoTaskDetails) {
      this.setState({
        toDoTaskDetails: this.props.toDoTaskDetails
      });
    }
  }

  //Get user To Do List
  userToDoList = userId => {
    console.log("IDDD: ", userId);
    var userData = localStorage.getItem("userdetail");
    const { API_HOST, userToDoListFetch } = this.props;
    this.setState({ isActive: true, loading: true });
    userToDoListFetch(API_HOST, userId, res => {
      var today_Tasks = res.filter(task => task.Is_After24Hrs === false);
      var pending_Tasks = res.filter(task => task.Is_After24Hrs === true);
      //var selectedUserName =res.filter(task => task.Real_Name)

      today_Tasks.map(obj => {
        const hoursState = 'hours' + obj.Id;
        const minutesState = 'minutes' + obj.Id;
        this.setState({ [hoursState]: 0, [minutesState]: 0})
      })

      pending_Tasks.map(obj => {
        const hoursState = 'hours' + obj.Id;
        const minutesState = 'minutes' + obj.Id;
        this.setState({ [hoursState]: 0, [minutesState]: 0})
      })

      this.setState({
        loading: false,
        todoList: today_Tasks,
        pendingTasks: pending_Tasks,
        isActive: false
      });
    });
  };

  onClickAddTask = () => {
    this.setState({
      onAddTask: true,
      showAddButton: false
    });
  };

  //Set ToDo Task as Done
  setDoneUserToDo = todo_Id => {
    this.setState(prevState => ({
      todoList: prevState.todoList.filter(list => list.Id !== todo_Id)
    }));

    const { API_HOST, setDoneUserToDo } = this.props;
    setDoneUserToDo(API_HOST, todo_Id, res => {
      this.userToDoList(0);
    });
  };

  // add Task to To Do List
  addToDoTask = () => {
    if (this.state.Description.length > 0) {
      const { API_HOST, addToDoTask } = this.props;
      const addToDoTaskRequest = {
        Description: this.state.Description
      };
      this.setState({ isActive: true });
      addToDoTask(API_HOST, addToDoTaskRequest, res => {
        this.userToDoList(0);
      });
    }

    this.setState({
      onAddTask: false,
      Description: "",
      showAddButton: true
    });
  };

  handleAddToDoTask = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  //On press on Enter Key Add task
  onKeyDownHandler = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.addToDoTask();
    }
  };

  // setToDoReminder = (checked, id) => {
  //   if (checked === true) {
  //     let newData = this.state.todoList.map(item => {
  //       if (item.Id === id) {
  //         item.Is_Reminder = checked
  //       }
  //       return item
  //     });
  //     this.setState(Object.assign({}, { todoList: newData, checked: checked, taskId: id, isReminder: !this.state.isReminder }));
  //   } else {
  //     let newData = this.state.todoList.map(item => {
  //       if (item.Id === id) {
  //         item.Is_Reminder = checked
  //       }
  //       return item
  //     });
  //     this.setState(Object.assign({}, { todoList: newData, checked: checked, taskId: id, isReminder: false }));
  //     const { API_HOST, saveReminder } = this.props;
  //     const reminderRequest = {
  //       "Id": id,
  //       "DueDate": '0001-01-01T00:00:00',
  //       "Reminder_Status": 0, // 3
  //       "Is_Reminder": checked
  //     };
  //     saveReminder(API_HOST, reminderRequest, (res) => {
  //       this.userToDoList()
  //     });
  //   }
  // }

  // setToDoReminder = (id) => {

  //     const { API_HOST, saveReminder } = this.props;
  //     const reminderRequest = {
  //       "Id": id,
  //       "DueDate": '0001-01-01T00:00:00',
  //       "Reminder_Status": 0, // 3
  //       "Is_Reminder": "true"
  //     };
  //     saveReminder(API_HOST, reminderRequest, (res) => {
  //       this.userToDoList()
  //     });

  // }

  openReminderPopUp = async id => {
    await this.setState({ isReminder: true, taskId: id });
    // await this.setToDoReminder(id);
  };

  closeReminderPopUp = () => {
    this.setState({ isReminder: false });
  };

  openWorkingHourPopUp = async (id, jiraTaskId, description, taskKey) => {
    await this.setState({
      taskId: id,
      idJiraTask: jiraTaskId,
      taskDescription: description,
      taskKey: taskKey
    });
  };

  onClickToDoTask = async taskId => {
    await this.setState({
      toDoTaskDetails: taskId,
      isTaskDetails: true,
      isAccordionExpand: true
    });
    console.log("22: ", this.state.toDoTaskDetails);
    await this.getJiraTaskLogDetails(taskId.Id_Jira_Task);
  };

  onClickToDoHours = (id, jiraTaskId, description, taskKey, jira_Cloud_Id) => {
    // alert(jiraTaskId + " " + description + " " + taskKey);
    const task_showWorkingHours = "showHours" + id;

    this.setState({
      // showWorkingHours: true,
      [task_showWorkingHours]: true,
      taskId: id,
      idJiraTask: jiraTaskId,
      taskDescription: description,
      taskKey: taskKey,
      jiraCloudId: jira_Cloud_Id
    });
  };

  toggleTaskDetails = () => {
    this.setState({ isTaskDetails: false });
  };

  /***************************************************************************************************/

  getRefreshToken = () => {
    const { API_HOST, getUserJiraRefreshToken } = this.props;

    getUserJiraRefreshToken(API_HOST, res => {
      if (res.Jira_Token) {
        this.getJiraDomain();
        this.setState({ refreshToken: res.Jira_Token });
        this.getAccessToken(res.Jira_Token);
        this.setState({ jiraClientDisable: false, isActive: false });
      } else {
        this.setState({ jiraClientDisable: true, isActive: false });
      }
    });
  };

  getAccessToken = async refreshToken => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: getDecryptedValue(refreshToken)
      };

      let headers = {
        "Content-Type": "application/json"
      };
      const data = await axios.post(finalUrl, resource, { headers });
      this.setState({
        accessToken: data.data.access_token,
        refreshToken: data.data.refresh_token
      });
      // this.setState({ accessToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik16bERNemsxTVRoRlFVRTJRa0ZGT0VGRk9URkJOREJDTVRRek5EZzJSRVpDT1VKRFJrVXdNZyJ9.eyJodHRwczovL2F0bGFzc2lhbi5jb20vb2F1dGhDbGllbnRJZCI6IlB1R0ljMU52UzdtYkQ3RzdiaXE3UXJ2NEd5cGxBQ2tHIiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL2VtYWlsRG9tYWluIjoiaGVsaW9zc29sdXRpb25zLmNvIiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL3N5c3RlbUFjY291bnRJZCI6IjVmY2RlOGM0YTIzN2Y3MDA2ZmU0YTdhYyIsImh0dHBzOi8vYXRsYXNzaWFuLmNvbS9zeXN0ZW1BY2NvdW50RW1haWxEb21haW4iOiJjb25uZWN0LmF0bGFzc2lhbi5jb20iLCJodHRwczovL2F0bGFzc2lhbi5jb20vdmVyaWZpZWQiOnRydWUsImh0dHBzOi8vYXRsYXNzaWFuLmNvbS9maXJzdFBhcnR5IjpmYWxzZSwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tLzNsbyI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9hdGxhc3NpYW4tYWNjb3VudC1wcm9kLnB1czIuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDVlY2I2NmNiN2QwYzI1MGMyMzRjZDkxNiIsImF1ZCI6ImFwaS5hdGxhc3NpYW4uY29tIiwiaWF0IjoxNjEwNDQ2Mjc3LCJleHAiOjE2MTA0NDk4NzcsImF6cCI6IlB1R0ljMU52UzdtYkQ3RzdiaXE3UXJ2NEd5cGxBQ2tHIiwic2NvcGUiOiJtYW5hZ2U6amlyYS1jb25maWd1cmF0aW9uIG1hbmFnZTpqaXJhLXByb2plY3Qgd3JpdGU6amlyYS13b3JrIHJlYWQ6amlyYS13b3JrIHJlYWQ6amlyYS11c2VyIG1hbmFnZTpqaXJhLWRhdGEtcHJvdmlkZXIgb2ZmbGluZV9hY2Nlc3MifQ.uDkf7LEoCmApZLaVqksmGObfCyiYl1x_pZ7Mjzep2zQktv94CLDnu1-vii8Pc6tu6nU5Slm1SVLSgrtYsrUYjzMtr4LzfpXnwOy6S4_Mpnp9LTSDWg8GhNnJ_pa7eq3r7Db95w9pIcQmqlvqjksv3ySZfWU6kyS-IBhGrFzmkBQ8jYwxJZI8KNdGYeO9bHkfwQxEqvF7GRY2xrgirpumaa37ZgIllmvJRmRluzyUe4omNsMnLSO_10sc0IU518ZHDzTzcmNaiyeX7WXfICpiETsPz1cGu7R0oeUyO7nKEV8HNxG3uNl7PvU70hoPpk_cOFku3CH79LuJYtrqqn3dtg" })

      await this.updateUserJiraRefreshToken(data.data.refresh_token);
      await this.getJiraYourTaskList(
        this.state.cloudId,
        data.data.access_token
      );
    } catch (error) {
      toast.error(error);
      this.setState({ isActive: false });
    }
  };

  getJiraDomain = async () => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, res => {
      if (res[0]) {
        let newValue = res.map(function(obj) {
          return {
            id: getDecryptedValue(obj.CloudId),
            CloudId: getDecryptedValue(obj.CloudId),
            Domain_Name: obj.Domain_Name,
            key: obj.Domain_Name,
            url: obj.url,
            Jira_Scope_Detail: obj.Jira_Scope_Detail,
            label: obj.Domain_Name,
            value: getDecryptedValue(obj.CloudId)
          };
        });
        this.setState({
          jiraDomain: newValue,
          jiraDomainSelected: newValue[0],
          cloudId: newValue[0].CloudId
        });
      }
    });
  };

  updateUserJiraRefreshToken = async refreshToken => {
    const { API_HOST, updateUserJiraRefreshToken } = this.props;

    let requestBody = {
      Jira_Token: getEncryptedValue(refreshToken)
    };

    await updateUserJiraRefreshToken(API_HOST, requestBody);
  };

  getAccessTokenAgain = async () => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: this.state.refreshToken
      };

      let headers = {
        "Content-Type": "application/json"
      };
      const data = await axios.post(finalUrl, resource, { headers });
      console.log("data result::: ", data);
      // this.setState({ accessToken: data.data.access_token })
      return data.data.access_token;
      this.getJiraYourTaskList(this.state.cloudId, data.data.access_token);
    } catch (error) {
      toast.error(error);
      this.setState({ isActive: false });
    }
  };

  getJiraYourTaskList = async (cloudId, token) => {
    try {
      const finalUrl = `https://api.atlassian.com/ex/jira/${cloudId}/rest/api/2/search?jql=assignee=currentuser()AND status IN ('To Do','In Progress')&maxResults=500&order%20by%20created&fields=assignee,comment,created,creator,description,issuelinks,duedate,issuetype,parent,priority,progress,project,reporter,status,subtasks,summary`;
      // const finalUrl = `https://smartchrome.atlassian.net/rest/api/3/project`

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({
        JiraTaskList: data.issues,
        selectedPageSize: 10,
        selectedPage: 0
      });
    } catch (error) {
      toast.error(error);
    }
    this.setState({ isActive: false, loading: false });
  };

  getJIRAConfig = () => {
    const { API_HOST, getJiraConfig } = this.props;
    getJiraConfig(API_HOST, res => {
      if (res[0]) {
        this.setState({
          jiraClientId: getDecryptedValue(res[0].Client_ID),
          jiraClientSecret: getDecryptedValue(res[0].Client_Secret)
        });

        // this.getJiraDomain();
        this.getRefreshToken();
      } else {
        this.setState({ jiraClientDisable: true });
      }
    });
  };

  //       // var i;Hours =
  //       // for (i = 0; i < 10; i++) {
  //       //   this.saveUserToDoFromJiraTask(this.state.JiraTaskList[i]);
  //       // }

  //     }
  //     catch (error) {
  //       toast.error(error)
  //     }
  //     this.setState({ isActive: false , loading: false})

  // }

  //   getJIRAConfig = () => {
  //     const { API_HOST, getJiraConfig } = this.props;
  //     getJiraConfig(API_HOST, (res) => {
  //       console.log("1get res", res)
  //       if (res[0]) {
  //         this.setState({
  //           jiraClientId: res[0].Client_ID,
  //           jiraClientSecret: res[0].Client_Secret
  //         })
  //         this.getJiraDomain();
  //         this.getRefreshToken();
  //       }
  //       else {
  //         this.setState({ jiraClientDisable: true })
  //       }
  //     });
  //   }

  //   saveUserToDoFromJiraTask = (jiraTask) => {
  //     const { API_HOST, saveUserToDoFromJiraTask } = this.props;
  //     let requestBody = {
  //           "Id" : 0,
  //           "Id_Jira_Task" : jiraTask.id,
  //           "Jira_Task_Date" : jiraTask.fields.created,
  //           "Description" : jiraTask.fields.summary
  //       }

  //         saveUserToDoFromJiraTask(API_HOST, requestBody, (res) => {
  //           console.log("saveUserToDoFromJiraTask", res)
  //           if (res) {
  //            // this.userToDoList();
  //           }
  //         });
  //   }

  /*********************************************************************************************************************/

  setWorkedHours = (hours, id) => {

    const hoursState = "hours" + id;
    const minutes = this.state["minutes" + id] ? this.state["minutes" + id] : 0;
    const totalTimeWorked =
      parseInt(hours.target.value * 60) + parseInt(minutes);
    
    this.setState({
      [hoursState]: hours.target.value,
      workedHours: hours.target.value
    });
    // this.saveJiraTaskLogDetails(totalTimeWorked, id);
  };

  setWorkedMinutes = (minutes, id) => {
    
    const minutesState = "minutes" + id;
    const hours = this.state["hours" + id] ? this.state["hours" + id] : 0;
    const totalTimeWorked =
      parseInt(hours * 60) + parseInt(minutes.target.value);
    
    this.setState({
      [minutesState]: minutes.target.value,
      workedMinutes: minutes.target.value
    });
    // this.setState({showWorkingHours: true});// written to hide the drop down again after minutes input.
    // this.saveJiraTaskLogDetails(totalTimeWorked, id);
  };

  getJiraTaskLogDetails = async jiraId => {
    const { API_HOST, getJiraTaskLogDetails } = this.props;
    await getJiraTaskLogDetails(API_HOST, jiraId, res => {
      //alert(JSON.stringify(res));
      if (res[0] !== null) {
        this.setState({ jiraTaskLogDetails: res });
        //alert(JSON.stringify(this.state.jiraTaskLogDetails));
      } else {
        this.setState({ jiraTaskLogDetails: [] });
      }
    });
  };

  // saveJiraTaskLogDetails = (totalTime, id) => {

  //   const { API_HOST, saveJiraTaskLogDetails } = this.props;

  //     var userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;
  //       let requestBody = {
  //         "Id" : 0,
  //         "Id_Jira_Task" : this.state.idJiraTask,
  //         "Id_User" : userId,
  //         "LogDateTime" : this.state.taskDate,
  //         "WorkedHours" : totalTime,
  //         "Jira_Task_Key" : this.state.taskKey,
  //         "Jira_Task_Summary" : this.state.taskDescription,
  //         "Description" : this.state.taskComment
  //       }

  //     saveJiraTaskLogDetails(API_HOST, requestBody, (res) => {
  //         if (res) {
  //           //toast.success("Task Working Details saved successfully");
  //                 if(this.state.showWorkingHours){            //written to hide the drop down again after minutes input.
  //                     const task_showWorkingHours = "showHours" + id;
  //                     this.setState({
  //                       [task_showWorkingHours]: false
  //                     })

  //                     toast.success("Task Working Hours Details saved successfully");
  //                 }
  //         }
  //     });

  // }

  saveHoursMinutes = id => {
    if((this.state.workedHours === 0 && this.state.workedMinutes === 0) || (this.state.workedHours <= 0 && this.state.workedMinutes <= 0)) {
      toast.info("Please enter correct value for Hours");
    }else{
      const totalTimeWorked =
      parseInt(this.state.workedHours * 60) +
      parseInt(this.state.workedMinutes);
    this.saveJiraTaskLogDetails(totalTimeWorked, id);
    this.setState({ showWorkingHours: true });
    }
  };

  hideWorkedHoursDropdown = id => {
    const task_showWorkingHours = "showHours" + id;
    this.setState({
      [task_showWorkingHours]: false
    });
  };

  saveJiraTaskLogDetails = (totalTime, id) => {
    const { API_HOST, saveJiraTaskLogDetails } = this.props;

    var userId = JSON.parse(localStorage.getItem("userdetail")).Id_User;
    let requestBody = {
      Id: 0,
      Id_Jira_Task: this.state.idJiraTask,
      Id_User: userId,
      LogDateTime: this.state.taskDate,
      WorkedHours: totalTime,
      Jira_Task_Key: this.state.taskKey,
      Jira_Task_Summary: this.state.taskDescription,
      Description: this.state.taskComment,
      Jira_Cloud_Id: this.state.jiraCloudId
    };

    saveJiraTaskLogDetails(API_HOST, requestBody, res => {
      if (res) {
        //toast.success("Task Working Details saved successfully");
        if (this.state.showWorkingHours) {
          //written to hide the drop down again after minutes input.
          const task_showWorkingHours = "showHours" + id;
          this.setState({
            [task_showWorkingHours]: false
          });
          this.setState({ workedHours: 0, workedMinutes: 0 });
          toast.success("Task Working Hours Details saved successfully");
        }
      }
    });
  };

  expandAccordion = () => {
    this.setState({ isAccordionExpand: !this.state.isAccordionExpand });
  };

  // Get userslist
  getUsersList = deptId => {
    const { API_HOST, getUsersList } = this.props;
    getUsersList(API_HOST, deptId, res => {
      if (res) {
        this.setState({
          userList: res
        });
      }
    });
  };

  userChange = id => {
    this.setState({ selectedUserId: id });
  };

  pageChange = pageIndex => {
    if (pageIndex > 0) {
      this.setState({ isPageChange: true });
    } else {
      this.setState({ isPageChange: false });
    }
  };

  onClickAddToJiraTask = jiraTask => {
    this.setState({
      isAddToJiraTask: true,
      isToDoDescription: true,
      todoDescription: jiraTask.description,
      todoId:jiraTask.Id
    });
  };

  closeAddToJiraTask = () => {
    this.setState({ isAddToJiraTask: false });
  };

  handleHourIncrement = (hours, id) => {
    console.log("Hours : " , hours);

   // if(this.state.workedHours <= 11) {
      var hoursState = "hours" + id;
      var minutes = this.state["minutes" + id] ? this.state["minutes" + id] : 0;
      const totalTimeWorked = parseInt(hours.target.value * 60) + parseInt(minutes);
      this.setState((prevState) => {
        return({
        [hoursState]: prevState[hoursState] + 1
        })})
        
      this.setState({
       // [hoursState]: hours.target.value + 1,
        workedHours: hours.target.value + 1
      });
   // }
  };

  handleHourDecrement = (hours, id) => {
    
   // if(this.state.workedHours >= 1)
    var hoursState = "hours" + id;
    var minutes = this.state["minutes" + id] ? this.state["minutes" + id] : 0;
    const totalTimeWorked =
      parseInt(hours.target.value * 60) + parseInt(minutes);
      this.setState((prevState) => {
        return({
        [hoursState]: prevState[hoursState] - 1
        })})
      this.setState({
        //[hoursState]: hours.target.value - 1,
        workedHours: hours.target.value - 1
      });
    //}
  };

  checkWorkedHour = (workedHours) => {
    if(workedHours < 0 || workedHours > 12){
      toast.error("Please enter hours value between 1-12");
    }
  }

  handleMinutesIncrement = (minutes, id) => {
    //if(this.state.workedMinutes <= 55) {
      var minutesState = "minutes" + id;
    var hours = this.state["hours" + id] ? this.state["hours" + id] : 0;
    const totalTimeWorked =
      parseInt(hours * 60) + parseInt(minutes.target.value);
      this.setState((prevState) => {
        return({
        [minutesState]: prevState[minutesState] + 5
        })})
      this.setState({
        //[minutesState]: minutes.target.value + 5,
        workedMinutes: minutes.target.value + 5
      });
    //}
  };

  handleMinutesDecrement = (minutes, id) => {
    //if(this.state.workedMinutes >= 5)
    var minutesState = "minutes" + id;
    var hours = this.state["hours" + id] ? this.state["hours" + id] : 0;
    const totalTimeWorked =
      parseInt(hours * 60) + parseInt(minutes.target.value);
      this.setState((prevState) => {
        return({
        [minutesState]: prevState[minutesState] - 5
        })})
      this.setState({
        //[minutesState]: minutes.target.value - 5,
        workedMinutes: minutes.target.value - 5
      });
    //}
  };

  checkWorkedMinutes = (workedMinutes) => {
    if(workedMinutes < 0 || workedMinutes > 59){
      toast.error("P1ease enter minutes value between 1-60");
    }
  }

  render() {
    const {
      todoList,
      isReminder,
      checked,
      taskId,
      reminderList,
      isActive,
      userList
    } = this.state;

    console.log(".....", userList);

    let users = [];

    // users.push({
    //   Id_User: 0,
    //   Real_Name: "All Users",
    //   label: <div>{"     All Users"}</div>,
    //   value: "",
    //   id: 0,
    //   value: "Select Users",
    //   label: <div>{"     Select Users"}</div>,
    // })

    userList &&
      userList.map(obj => {
        users.push({
          id: obj.Id_User,
          value: obj.Real_Name,
          label: obj.Real_Name
        });
        return users;
      });

    const columns = [
      //   {
      //     Header: <strong className="my_custom_class">Id</strong>,
      //     accessor: 'Id',
      //     minWidth: 0.1,
      //     Cell: (row) => {
      //       return <div style={{ cursor: "pointer" }} onClick={() => { this.onClickToDoTask(row.original) }}>{row.original.Id}</div>
      //     }
      // },
      {
        Header: <strong className="my_custom_class">Description</strong>,
        accessor: "description",
        minWidth: 1,
        Cell: row => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickToDoTask(row.original);
              }}
            >
              {row.original.Description}
            </div>
          );
        }
      },
      {
        Header: <strong className="my_custom_class">Done</strong>,
        accessor: "Id",
        minWidth: 0.1,
        Cell: row => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.setDoneUserToDo(row.original.Id)}
              className="text-center"
            >
              <ReactTooltip
                id="doneInfo"
                place="left"
                type="dark"
                effect="solid"
                multiline={true}
                backgroundColor="#2c69d1"
                textColor="white"
                border="true"
              />
              <i
                class="far fa-check-square"
                aria-hidden="true"
                data-for="doneInfo"
                data-tip="Set Task as Complete"
              ></i>
            </div>
          );
        }
      },
      {
        Header: <strong className="my_custom_class">JIRA</strong>,
        accessor: "Id",
        minWidth: 0.1,
        Cell: row => {
          if (row.original.Is_Jira_Task === false) {
            return (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this.onClickAddToJiraTask(row.original)}
                className="text-center"
              >
                <ReactTooltip
                  id="addJiraInfo"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                <i
                  class="fab fa-jira"
                  aria-hidden="true"
                  data-for="addJiraInfo"
                  data-tip="Add task to JIRA"
                ></i>
              </div>
            );
          } else {
            return <></>;
          }
        }
      },
      {
        Header: <strong className="my_custom_class">Reminder</strong>,
        accessor: "Id",
        minWidth: 0.1,
        Cell: row => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.openReminderPopUp(row.original.Id)}
              className="text-center"
            >
              <ReactTooltip
                id="reminderInfo"
                place="left"
                type="dark"
                effect="solid"
                multiline={true}
                backgroundColor="#2c69d1"
                textColor="white"
                border="true"
              />
              <i
                class="fas fa-stopwatch"
                data-for="reminderInfo"
                data-tip="Set Task Reminder"
              ></i>
            </div>
          );
        }
      },
      {
        Header: <strong className="my_custom_class">Hrs:Mins</strong>,
        accessor: "Id",
        minWidth: 0.5,
        Cell: row => {
          // if (row.original.Is_Jira_Task){
          //     return(
          //       <div onClick={() => this.openWorkingHourPopUp(row.original.Id, row.original.Id_Jira_Task, row.original.description, row.original.Jira_Task_Key)}  className="text-center">
          //         <i class="far fa-clock" onClick={this.onClickToDoHours}></i>
          //       </div>
          //       )
          // }else{
          //   return(
          //     <></>
          //   )
          // }

          if (row.original.Is_Jira_Task) {
            const hours = [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24
            ];
            const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
            return (
              <div className="text-center d-flex align-items-center justify-content-center timings_todo">
                <ReactTooltip
                  id="hoursInfo"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                {!this.state["showHours" + row.original.Id] && (
                  <i
                    class="far fa-clock"
                    onClick={() =>
                      this.onClickToDoHours(
                        row.original.Id,
                        row.original.Id_Jira_Task,
                        row.original.description,
                        row.original.Jira_Task_Key,
                        row.original.Jira_Cloud_Id
                      )
                    }
                    style={{ cursor: "pointer" }}
                    data-for="hoursInfo"
                    data-tip="Set Task worked hours"
                  ></i>
                )}
                {this.state["showHours" + row.original.Id] && (
                  <>
                    {/* <select
                      style={{ width: "40px" }}
                      className="form-control"
                      value={this.state["hours" + row.original.Id]}
                      onChange={newValue =>
                        this.setWorkedHours(newValue, row.original.Id)
                      }
                    >
                      {hours.map(h => {
                        return <option value={h}>{h}</option>;
                      })}
                    </select>
                    <b> :</b>
                    <select
                      style={{ width: "40px" }}
                      className="form-control"
                      value={this.state["minutes" + row.original.Id]}
                      onChange={newValue =>
                        this.setWorkedMinutes(newValue, row.original.Id)
                      }
                    >
                      {minutes.map(m => {
                        return <option value={m}>{m}</option>;
                      })}
                    </select> */}

<ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={newValue => this.handleHourIncrement(newValue, row.original.Id)}>+</Button>
        <input type='text' value={this.state["hours" + row.original.Id]} onChange={newValue => this.setWorkedHours(newValue, row.original.Id)}/>
        <Button onClick={newValue => this.handleHourDecrement(newValue, row.original.Id)}>-</Button>
      </ButtonGroup> 
<span className="ml-1 mr-1">:</span>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={newValue => this.handleMinutesIncrement(newValue, row.original.Id)}>+</Button>
        <input type='text' value={this.state["minutes" + row.original.Id]} onChange={newValue => this.setWorkedHours(newValue, row.original.Id)}/>
        <Button onClick={newValue => this.handleMinutesDecrement(newValue, row.original.Id)}>-</Button>
      </ButtonGroup>

                    <br />
                    <i
                      class="far fa-check-circle"
                      onClick={() => this.saveHoursMinutes(row.original.Id)}
                      style={{ cursor: "pointer", padding: "1px" }}
                    ></i>
                    <i
                      class="far fa-times-circle"
                      onClick={() =>
                        this.hideWorkedHoursDropdown(row.original.Id)
                      }
                      style={{ cursor: "pointer", padding: "1px" }}
                    ></i>
                  </>
                )}
              </div>
            );
          } else {
            return <></>;
          }
        }
      }
    ];

    const pendingTasksColumns = [
      //   {
      //     Header: <strong className="my_custom_class">Id</strong>,
      //     accessor: 'Id',
      //     minWidth: 0.1,
      //     Cell: (row) => {
      //       return <div style={{ cursor: "pointer" }} onClick={() => { this.onClickToDoTask(row.original) }}>{row.original.Id}</div>
      //     }
      // },

      {
        Header: <strong className="my_custom_class">Description</strong>,
        accessor: "description",
        minWidth: 1,
        Cell: row => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickToDoTask(row.original);
              }}
            >
              {row.original.Description}
            </div>
          );
        }
      },
      {
        Header: <strong className="my_custom_class">Done</strong>,
        accessor: "Id",
        minWidth: 0.1,
        Cell: row => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.setDoneUserToDo(row.original.Id)}
              className="text-center"
            >
              <ReactTooltip
                id="p_doneInfo"
                place="left"
                type="dark"
                effect="solid"
                multiline={true}
                backgroundColor="#2c69d1"
                textColor="white"
                border="true"
              />
              <i
                class="far fa-check-square"
                aria-hidden="true"
                data-for="p_doneInfo"
                data-tip="Set Task as Complete"
              ></i>
            </div>
          );
        }
      },
      {
        Header: <strong className="my_custom_class">Reminder</strong>,
        accessor: "Id",
        minWidth: 0.1,
        Cell: row => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.openReminderPopUp(row.original.Id)}
              className="text-center"
            >
              <ReactTooltip
                id="p_reminderInfo"
                place="left"
                type="dark"
                effect="solid"
                multiline={true}
                backgroundColor="#2c69d1"
                textColor="white"
                border="true"
              />
              <i
                class="fas fa-stopwatch"
                data-for="p_reminderInfo"
                data-tip="Set Task Reminder"
              ></i>
            </div>
          );
        }
      },
      {
        Header: <strong className="my_custom_class">Hrs:Mins</strong>,
        accessor: "Id",
        minWidth: 0.5,
        Cell: row => {
          if (row.original.Is_Jira_Task) {
            const hours = [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24
            ];
            const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
            return (
              <div className="text-center d-flex align-items-center justify-content-center timings_todo">
                <ReactTooltip
                  id="p_hoursInfo"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                {!this.state["showHours" + row.original.Id] && (
                  <i
                    class="far fa-clock"
                    onClick={() =>
                      this.onClickToDoHours(
                        row.original.Id,
                        row.original.Id_Jira_Task,
                        row.original.description,
                        row.original.Jira_Task_Key,
                        row.original.Jira_Cloud_Id
                      )
                    }
                    style={{ cursor: "pointer" }}
                    data-for="p_hoursInfo"
                    data-tip="Set Task worked hours"
                  ></i>
                )}
                {this.state["showHours" + row.original.Id] && (
                  <>
                    {/* <select
                      style={{ width: "40px" }}
                      className="form-control"
                      value={this.state["hours" + row.original.Id]}
                      onChange={newValue =>
                        this.setWorkedHours(newValue, row.original.Id)
                      }
                    >
                      {hours.map(h => {
                        return <option value={h}>{h}</option>;
                      })}
                    </select>
                    <b> :</b>
                    <select
                      style={{ width: "40px" }}
                      className="form-control"
                      value={this.state["minutes" + row.original.Id]}
                      onChange={newValue =>
                        this.setWorkedMinutes(newValue, row.original.Id)
                      }
                    >
                      {minutes.map(m => {
                        return <option value={m}>{m}</option>;
                      })}
                    </select> */}


<ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={newValue => this.handleHourIncrement(newValue, row.original.Id)}>+</Button>
        <input type='text' value={this.state["hours" + row.original.Id]} onChange={newValue => this.setWorkedHours(newValue, row.original.Id)}/>
        <Button onClick={newValue => this.handleHourDecrement(newValue, row.original.Id)}>-</Button>
      </ButtonGroup> 
<span className="ml-1 mr-1">:</span>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={newValue => this.handleMinutesIncrement(newValue, row.original.Id)}>+</Button>
        <input type='text' value={this.state["minutes" + row.original.Id]} onChange={newValue => this.setWorkedHours(newValue, row.original.Id)}/>
        <Button onClick={newValue => this.handleMinutesDecrement(newValue, row.original.Id)}>-</Button>
      </ButtonGroup>

                    <br />
                    <i
                      class="far fa-check-circle"
                      onClick={() => this.saveHoursMinutes(row.original.Id)}
                      style={{ cursor: "pointer", padding: "1px" }}
                    ></i>
                    <i
                      class="far fa-times-circle"
                      onClick={() =>
                        this.hideWorkedHoursDropdown(row.original.Id)
                      }
                      style={{ cursor: "pointer", padding: "1px" }}
                    ></i>
                  </>
                )}
              </div>
            );
          } else {
            return <></>;
          }
        }
      }
    ];

    return (
      <>
        <div
          className="model-right-bar to_do_modal"
          id="userloginmodel"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog jira-task-popup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  To Do
                </h5>
                <div
                  className="close b-left"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.openToDoList}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-close" />
                </div>
              </div>
              <div className=" modal-body myscroll">
                <div className="d-flex">
                  <div className="jira-task-list">
                    <FormGroup className="custom-jira-action d-flex justify-content-between align-items-center">
                      <div className="task-add">
                        {this.state.showAddButton ? (
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={this.onClickAddTask}
                          >
                            Add Task
                          </button>
                        ) : (
                          ""
                        )}

                        {this.state.onAddTask ? (
                          <React.Fragment>
                            <Tooltip
                              title="Press Shift+Enter to create a new line"
                              placement="right"
                            >
                              <div style={{width:"500px"}}>
                                <textarea
                                  style={{width:"500px"}}
                                  type="textarea"
                                  className="form-control"
                                  id="Description"
                                  aria-describedby="Description"
                                  placeholder=""
                                  rows="1"
                                  cols="8"
                                  value={this.state.Description}
                                  name="Description"
                                  autoFocus
                                  //onBlur={this.addToDoTask}
                                  onKeyDown={this.onKeyDownHandler}
                                  onChange={e => this.handleAddToDoTask(e)}
                                ></textarea>
                              </div>
                            </Tooltip>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </div>

                      {JSON.parse(localStorage.getItem("userdetail"))
                        .Is_Admin === true ? (
                        <div>
                          <Select
                            components={makeAnimated}
                            // onChange={newValue => this.userChange(newValue)}
                            onChange={newValue => {
                              this.userToDoList(newValue.id);
                            }}
                            options={users}
                            value={users.value}
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <div className="mt-3">
                      <Accordion
                        expanded={
                          this.state.isAccordionExpand &&
                          this.state.isAccordionExpand
                            ? true
                            : false
                        }
                        onChange={this.expandAccordion}
                      >
                        {/* <Accordion> */}
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="">
                            {this.state.isPageChange ? (
                              <>
                                ToDo Tasks{" "}
                                <i style={{ color: "red" }}>(not for Today)</i>
                              </>
                            ) : (
                              "Today's ToDo Tasks"
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {todoList[0] !== null ? (
                            <ReactTable
                              data={this.state.todoList}
                              columns={columns}
                              defaultPageSize={10}
                              pageSize={10}
                              showPagination={true}
                              showPageSizeOptions={false}
                              className="-striped -highlight"
                              loading={this.state.loading}
                              onPageChange={pageIndex =>
                                this.pageChange(pageIndex)
                              }
                            />
                          ) : (
                            <i>"You do not have any Tasks for Today"</i>
                          )}
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className="">Pending Tasks</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {todoList[0] !== null ? (
                            <ReactTable
                              data={this.state.pendingTasks}
                              columns={pendingTasksColumns}
                              defaultPageSize={10}
                              pageSize={10}
                              showPagination={true}
                              className="-striped -highlight"
                              loading={this.state.loading}
                            />
                          ) : (
                            <i>"You do not have any pending tasks"</i>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>

                  {isReminder === true && (
                    <Reminder
                      isReminder={isReminder}
                      closeReminderPopUp={this.closeReminderPopUp}
                      checked={checked}
                      taskId={taskId}
                      userToDoList={this.userToDoList}
                    />
                  )}

                  {this.state.isTaskDetails ? (
                    <ToDoDetails
                      getJiraTaskLogDetails={this.getJiraTaskLogDetails}
                      jiraTaskLogDetails={this.state.jiraTaskLogDetails}
                      isTaskDetails={this.state.isTaskDetails}
                      toDoTaskDetails={this.state.toDoTaskDetails}
                      toggleTaskDetails={this.toggleTaskDetails}
                    />
                  ) : null}
                  
                  {this.state.isAddToJiraTask ?
                    <AddNewTask userToDoList={this.userToDoList} todoId={this.state.todoId} isToDoDescription={this.state.isToDoDescription} todoDescription={this.state.todoDescription} closeAddTask={this.closeAddToJiraTask} isAddTask={this.state.isAddToJiraTask} cloudId={this.state.cloudId} accessToken={this.state.accessToken} lastTaskProject={this.state.JiraTaskList[0]} getJiraYourTaskList={this.getJiraYourTaskList} getAccessTokenAgain={this.getAccessTokenAgain} refreshToken={this.state.refreshToken} />
                    : null}

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// Prop types of props.
ToDoList.propTypes = {
  API_HOST: PropTypes.string,
  userToDoListFetch: PropTypes.func
  //userToDoListResponse: PropTypes.array,
};

// Set default props.
ToDoList.defaultProps = {
  userToDoListFetch: () => {}
  //userToDoListResponse: [],
};

const mapStateToProps = state => ({
  API_HOST: getApiHost()
  //userToDoListResponse: state.todoReducer.userToDoListResponse
});
export default connect(mapStateToProps, {
  getJiraTaskLogDetails,
  saveJiraTaskLogDetails,
  getJiraDomain,
  updateUserJiraRefreshToken,
  userToDoListFetch,
  setDoneUserToDo,
  addToDoTask,
  saveReminder,
  getUserJiraRefreshToken,
  getJiraConfig,
  saveUserToDoFromJiraTask,
  getUsersList
})(ToDoList);
