import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup } from 'reactstrap';
import { onlyNumber } from "../../../utils/utility";
import { saveContact } from "../../../actions/contactFeedbackActions";
import { connect } from 'react-redux';
import { getApiHost } from "../../../utils/apiUrls";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import ClockLoader from "react-spinners/ClockLoader";
import logo from "../../../assets/images/logo.png";

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: '',
            email: '',
            number: '',
            message: '',
            details:'',
            disabled: false
        };
    }

    closeContactUs = () => {
        this.props.toggleContactUs();
    }

    saveContact = (e) => {
        e.preventDefault();
        this.setState({
            disabled: true
        })
        const { API_HOST, saveContact } = this.props;
        const contactUsRequest = {
            "Name": this.state.name,
            "EmailId": this.state.email,
            "ContactNo": this.state.number,
            "Message": this.state.message
        };

        saveContact(API_HOST, contactUsRequest, (res) => {
            toast.success("Your contact information has been saved successfully!");
            this.closeContactUs()
        });
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    render() {
        const { disabled } = this.state;
        return (
            <div style={{ maxWidth: '400px' }} className="container d-flex align-items-center justify-content-center h-100">

            {/* <Modal isOpen={this.props.isContactUs} backdrop={true} size={'md'} className="rating-modal" >
                 <ModalHeader toggle={this.closeContactUs} className="dept-header">
                     Contact Us
                 </ModalHeader>
                 <form method="post" id="contactus" onSubmit={this.saveContact}>
                     <ModalBody className="feedback-class"> */}
                     <div>
                     <div className="logo-wrap text-center"><img src={logo} className="img-fluid mx-auto mb-5" alt="" /></div>
                     <div>
                         {/* <h3 className="text-center mb-3">Contact us</h3> */}
                        <div className="row">
                            <div className="col-sm-6">
                                    <FormGroup>
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fname"
                                            aria-describedby="fname"
                                            placeholder="First Name"
                                            name="fname"
                                            onChange={(e) => this.handleOnChange(e)}
                                            required
                                            value={this.state.fname}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6">
                                    <FormGroup>
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lname"
                                            aria-describedby="lname"
                                            placeholder="Last Name"
                                            name="lname"
                                            onChange={(e) => this.handleOnChange(e)}
                                            required
                                            value={this.state.lname}
                                        />
                                    </FormGroup>
                                </div>
                            </div>

                                <FormGroup>
                                    <label >E-mail Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        aria-describedby="email"
                                        placeholder="abc@yahoo.com"
                                        name="email"
                                        required
                                        value={this.state.email}
                                        onChange={(e) => this.handleOnChange(e)}
                                        pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <label >Contact Number</label>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        id="number"
                                        aria-describedby="number"
                                        placeholder="Contact Number"
                                        name="number"
                                        required
                                        value={this.state.number}
                                        onChange={(e) => this.handleOnChange(e)}
                                        onKeyPress={onlyNumber}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <label >Message</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="message"
                                        aria-describedby="message"
                                        placeholder="Write Message"
                                        name="message"
                                        value={this.state.message}
                                        onChange={(e) => this.handleOnChange(e)}
                                    >
                                    </textarea>
                                </FormGroup>

                                <FormGroup>
                                    <label >Additional Details</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="details"
                                        aria-describedby="message"
                                        placeholder="Additional details"
                                        name="details"
                                        value={this.state.details}
                                        onChange={(e) => this.handleOnChange(e)}
                                    >
                                    </textarea>
                                </FormGroup>

                                <Button color="primary" className="w-100" type="submit" disabled={disabled}><span>Send</span>
                                    <span>
                                        <ClockLoader
                                        size={16}
                                        color={"#fff"}
                                        loading={disabled}
                                        />
                                    </span>
                                </Button>
                     {/* </ModalBody>
                     <ModalFooter> */}
                     {/* </ModalFooter>
                 </form>
             </Modal> */}

</div>

            </div>
            </div>

        );
    }
}

// Prop types of props.
ContactUs.propTypes = {
    API_HOST: PropTypes.string,
};

const mapStateToProps = state => ({
    API_HOST: getApiHost(),
})

export default connect(mapStateToProps, { saveContact })(ContactUs)
