import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getApiHost, socket } from "../../../utils/apiUrls";
import { fetchSlackUser } from "../../../actions/dashboardActions";
import { ContextMenuTrigger } from "react-contextmenu";
import StickyNotes from "../../MainOfficeLayout/components/StickyNotes";
import {
  saveUserKnock,
  stickyMessageSend,
  makeAdmin,
} from "../../../actions/mainOfficeActions";
import ContextMenuOption from "../../MainOfficeLayout/components/ContextMenuOption";
import { AvatarImage } from "../../../components/Avatar/AvatarImage";
import { getUserDetail } from "../../../actions/dashboardActions";
import ReactTooltip from "react-tooltip";

class SlackChatUserList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      myAvatar: {},
      teamId: this.props.teamId,
      stickyNotesMessage: "",
      personId: 0,
      sticktyNoteSelected: false,
      stickyNoteIsOpen: false,
      isUserDoorOpen: false,
      userDetails: {},
      userList: [],
      userStatus: "",
      userId: 0,
      makeAdminSelected: false,
      showScheduleMeetingOption: false,
    };
  }

  componentDidMount() {
    socket.on("UserDetail", (data) => {
      if (
        data.Id_Tenant ===
        JSON.parse(localStorage.getItem("userdetail")).Id_Tenant
      ) {
        this.updateUserDetail(data);
      } else {
        this.getUserDetail(data.Id_User);
      }
    });
    socket.on("updateOfficeTime", (data) => {
      this.updateOfficeTime(data);
    });

    this.setState({ userList: this.props.memberListResponse });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getUserDetailResponse !== this.props.getUserDetailResponse) {
      this.updateUserDetail(this.props.getUserDetailResponse);
    }

    if (prevProps.memberListResponse !== this.props.memberListResponse) {
      this.setState({
        userList: this.props.memberListResponse,
      });
    }
  }

  getUserDetail = async (id) => {
    const { API_HOST, getUserDetail } = this.props;
    await getUserDetail(API_HOST, id);
  };

  updateUserDetail(data) {
    let newData = this.state.userList.map((item) => {
      if (item.Id_User === data.Id_User) {
        item.Department_Name = data.Department_Name;
        item.Department_Size_Type = data.Department_Size_Type;
        item.Display_Name = data.Display_Name;
        item.EmailId = data.EmailId;
        item.First_Name = data.First_Name;
        item.Id_Department = data.Id_Department;
        item.Id_Role = data.Id_Role;
        item.Is_DoorOpen = data.Is_DoorOpen;
        item.Is_DoorOpenDepartment = data.Is_DoorOpenDepartment;
        item.Last_Name = data.Last_Name;
        item.Phone = data.Phone;
        item.ProfilePic = data.ProfilePic;
        item.Real_Name = data.Real_Name;
        item.Tenant_Name = data.Tenant_Name;
        item.Title = data.Title;
        item.TZ = data.TZ;
        item.TZ_Label = data.TZ_Label;
        item.TZ_Offset = data.TZ_Offset;
        item.UserStartTime_EndTimeVM = data.UserStartTime_EndTimeVM;
        item.UserStatus = data.UserStatus;
        item.Is_Active = data.Is_Active;
        item.Is_LoggedIn = data.Is_LoggedIn;
        item.CurrentLoggedInTenant_Name = data.CurrentLoggedInTenant_Name;
      }
      return item;
    });

    this.setState(Object.assign({}, { userList: newData }));
  }

  updateOfficeTime(data) {
    let newData = this.state.userList.map((item) => {
      item.UserStartTime_EndTimeVM.Availibility_Time =
        data.UserStartTime_EndTimeVM.Availibility_Time;
      item.TZ = data.TZ;
      return item;
    });
    this.setState(Object.assign({}, { userList: newData }));
  }

  toggleCloseSticky = () => {
    this.setState({ stickyNoteIsOpen: false });
  };

  // Sticky Note option selected from ContextMenu
  onSticktyNoteSelected = (userId) => {
    this.setState({
      personId: userId,
      sticktyNoteSelected: true,
      stickyNoteIsOpen: true,
    });
  };

  stickyMessageSend = (e) => {
    e.preventDefault();
    const { API_HOST, stickyMessageSend } = this.props;
    const stickyMessageSendRequest = {
      Id_UserReceiver: this.state.personId,
      Id_UserSender: 0,
      Description: this.state.stickyNotesMessage,
    };
    stickyMessageSend(API_HOST, stickyMessageSendRequest, (res) => {
      if (res) {
        this.setState({
          stickyNotesMessage: "",
        });
        this.toggleCloseSticky();
      }
    });
  };

  //save User Knock
  saveUserKnock = async (userId) => {
    const { API_HOST, saveUserKnock } = this.props;
    const saveUserKnockRequest = {
      Id_UserReceiver: userId,
      Id_UserSender: 0,
    };
    await saveUserKnock(API_HOST, saveUserKnockRequest, (res) => {});
  };

  stickyNotesMessageSend = (e) => {
    this.setState({ stickyNotesMessage: e.target.value });
  };

  //Knock option selected from ContextMenu
  onKnockSelected = (userId) => {
    this.setState({ knockSelected: true });
    this.saveUserKnock(userId);
  };

  //Make Admin option selected from ContextMenu
  onMakeAdminSelected = (userId) => {
    this.makeAdmin(userId);
    this.setState({ makeAdminSelected: true });
  };

  //Make the selected user Admin
  makeAdmin = (userId) => {
    let admin = {
      Id_User: userId,
      Is_Admin: true,
      tenant_Id: JSON.parse(localStorage.getItem("userdetail")).Id_Tenant,
    };
    socket.emit("makeAdmin", admin);
    let newData = this.state.userList.map((item) => {
      if (item.Id_User === userId) {
        item.Is_Admin = true;
        socket.emit("UserDetail", item);
      }
      return item;
    });
    this.setState(Object.assign({}, { userList: newData }));

    const { API_HOST, makeAdmin } = this.props;
    makeAdmin(API_HOST, userId, (res) => {});
  };

  render() {
    //const { memberListResponse } = this.props;
    const { userList } = this.state;

    var moment = require("moment-timezone");
    return (
      <div class="user-card-list user-cabin">
        {userList &&
          userList.map((slackuser) => (
            <ContextMenuTrigger
              id={`${
                JSON.parse(localStorage.getItem("userdetail")).Id_User !==
                slackuser.Id_User
                  ? slackuser.Id_User
                  : ""
              }`}
            >
              {slackuser.Is_Active === true ? (
                <div
                  onDoubleClick={() =>
                    slackuser.Id_User !==
                      JSON.parse(localStorage.getItem("userdetail")).Id_User &&
                    slackuser.Is_DoorOpen
                      ? this.props.handleAddChat(slackuser)
                      : null
                  }
                  class={`card  ${
                    slackuser.Is_DoorOpen ? " card-door-open" : ""
                  } ${
                    slackuser.UserStartTime_EndTimeVM.Availibility_Status ===
                      "OOO" ||
                    slackuser.UserStartTime_EndTimeVM.Availibility_Status ===
                      "On leave/vacation"
                      ? "out-office"
                      : ""
                  } ${slackuser.Is_Admin ? " card-admin" : ""}`}
                  onContextMenu={() => {
                    this.setState({
                      userId: slackuser.Id_User,
                      isUserDoorOpen: slackuser.Is_DoorOpen,
                      userStatus:
                        slackuser.UserStartTime_EndTimeVM.Availibility_Status,
                      userDetails: slackuser,
                    });
                  }}
                  data-for="useremail"
                  data-tip={`${slackuser.EmailId}`}
                >
                  <ReactTooltip
                    id="useremail"
                    place="right"
                    type="dark"
                    effect="solid"
                    multiline={true}
                    backgroundColor="#2c69d1"
                    textColor="white"
                    border="true"
                  />

                  <span className="status">
                    {slackuser.UserStartTime_EndTimeVM.Availibility_Status}
                    {slackuser.UserStatus &&
                    !(
                      slackuser.UserStatus === "Available" ||
                      slackuser.UserStatus === "available"
                    ) ? (
                      <span className="meeting-with">
                        {" "}
                        {slackuser.UserStatus}{" "}
                      </span>
                    ) : null}
                  </span>
                  <span className="ot-time">
                    {" "}
                    <span>Usually</span>{" "}
                    {moment(
                      slackuser.UserStartTime_EndTimeVM.Availibility_Time.substr(
                        0,
                        5
                      ),
                      "HH:mm"
                    )
                      .tz(slackuser.TZ)
                      .format("HH:mm")}{" "}
                    -{" "}
                    {moment(
                      slackuser.UserStartTime_EndTimeVM.Availibility_Time.substr(
                        slackuser.UserStartTime_EndTimeVM.Availibility_Time
                          .length - 5
                      ),
                      "HH:mm"
                    )
                      .tz(slackuser.TZ)
                      .format("HH:mm")}
                  </span>
                  <span
                    className={`time-zone ${
                      moment()
                        .tz(slackuser.TZ)
                        .format("HH:mm")
                        .slice(0, 5)
                        .replace(":", "") <=
                        slackuser.UserStartTime_EndTimeVM.Availibility_Time.substr(
                          slackuser.UserStartTime_EndTimeVM.Availibility_Time
                            .length - 5
                        ).replace(":", "") &&
                      moment()
                        .tz(slackuser.TZ)
                        .format("HH:mm")
                        .slice(0, 5)
                        .replace(":", "") >=
                        slackuser.UserStartTime_EndTimeVM.Availibility_Time.substr(
                          0,
                          5
                        ).replace(":", "")
                        ? "day-time"
                        : "night-time"
                    }`}
                  >
                    {" "}
                    {moment().tz(slackuser.TZ).format("HH:mm")}{" "}
                    <img src="images/watch-bg.png" alt="" />
                  </span>
                  <div className="card-img">
                    <img
                      src="images/pc-desktop.png"
                      className="img-fluid user-desk-img"
                      alt=""
                    />
                    <AvatarImage
                      profilePic={JSON.parse(slackuser.ProfilePic)}
                      className=""
                    />
                  </div>
                  <strong className="name-time">{slackuser.Real_Name} </strong>
                </div>
              ) : (
                ""
              )}
            </ContextMenuTrigger>
          ))}

        {this.state.userId !==
        JSON.parse(localStorage.getItem("userdetail")).Id_User ? (
          <ContextMenuOption
            userId={this.state.userId}
            Is_DoorOpen={this.state.isUserDoorOpen}
            onSticktyNoteSelected={this.onSticktyNoteSelected}
            onKnockSelected={this.onKnockSelected}
            handleAddChat={this.props.handleAddChat}
            userStatus={this.state.userStatus}
            userDetails={this.state.userDetails}
            onMakeAdminSelected={this.onMakeAdminSelected}
            showScheduleMeetingOption={this.state.showScheduleMeetingOption}
          />
        ) : null}
        {this.state.sticktyNoteSelected ? (
          <StickyNotes
            //stickyMessageSend={this.stickyMessageSend}
            //stickyNotesMessage={this.state.stickyNotesMessage}
            //stickyNotesMessageSend={this.stickyNotesMessageSend}
            stickyNoteIsOpen={this.state.stickyNoteIsOpen}
            toggleSticky={this.toggleCloseSticky}
            personId={this.state.personId}
          />
        ) : null}
      </div>
    );
  }
}

// Prop types of props.
SlackChatUserList.propTypes = {
  API_HOST: PropTypes.string,
  memberListResponse: PropTypes.any,
};

// Set default props.
SlackChatUserList.defaultProps = {
  memberListResponse: null,
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
  memberListResponse:
    state.dashboardReducer.fetchSlackUser.slackUserResponse.SourceData,
  getUserDetailResponse:
    state.dashboardReducer.getUserDetailResponse.userDetailResponse,
});

export default connect(mapStateToProps, {
  getUserDetail,
  fetchSlackUser,
  saveUserKnock,
  stickyMessageSend,
  makeAdmin,
})(SlackChatUserList);
