
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { downloadExcelFile, getMeetingLog, getMeetingLogDetail } from "../../../actions/logActions";
import { getApiHost } from "../../../utils/apiUrls";
import { dateDiff } from "../../../utils/utility"
import { FormGroup, Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';
import moment from 'moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import ClockLoader from "react-spinners/ClockLoader";

class MeetingActivityLog extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            meetingActivityLog: this.props.meetingActivityLog,
            data: [],
            detailData: [],
            startDate: moment(new Date()).format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('YYYY-MM-DD'),
            currentObject: {
                isUser: { value: 0, label: "All" }
            },
            page: 0,
            pageSize: 10,
            pageCount: 0,
            loading: false,
            loadingTwo: false,
            meetingDetailFlag: false,
            enabled: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.meetingLogResponse !== this.props.meetingLogResponse) {
            let totlePage = this.props.meetingLogResponse.Data.PageData.TotalPages;
            this.setState({
                data: this.props.meetingLogResponse.Data.SourceData,
                pageCount: totlePage
            })
        }
        if (prevProps.meetingLogDetailResponse !== this.props.meetingLogDetailResponse) {
            this.setState({
                detailData: this.props.meetingLogDetailResponse
            })
        }
    }

    toggleMeetingActivityLog = () => {
        this.props.toggleMeetingActivityLog()
    }


    userChange(value) {
        this.setState({
            currentObject: {
                ...this.state.currentObject,
                isUser: value
            }
        })
    }

    getMeetingLogData = (e) => {
        if (this.state.startDate !== "" && this.state.endDate !== "") {
            this.setState({ loading: true });
            this.searchData(1, 10, [{ "id": "Actual_End_Time_UTC", "desc": true }]);
        }
    }

    searchData = async (page, pageSize, sortArray) => {

        const { API_HOST, getMeetingLog } = this.props;
        let sDate = moment(this.state.startDate).format('YYYY-MM-DD');
        let eDate = moment(this.state.endDate).format('YYYY-MM-DD');

        let sortParam = {};

        if (sortArray.length > 0) {
            sortParam = {
                "Direction": sortArray[0].desc === true ? 1 : 0,
                "Property": sortArray[0].id
            }
        } else {
            sortParam = {
                "Direction": 1,
                "Property": "Start_Date_UTC"
            }
        }

        let reqObj = {
            "IsPagging": true,
            "PageNo": page,
            "PageSize": pageSize,
            "SortingParameters": [
                sortParam
            ],
            "SearchCriteria": {
                "UserId": this.state.currentObject.isUser.value,
                "StartDate": moment(this.state.startDate).format(),
                "EndDate": moment(this.state.endDate).format()
            },
            "ComplexSearchCriteria": {},
        }

        if (dateDiff(sDate, eDate) <= 30) {
            await getMeetingLog(API_HOST, reqObj, (res) => {
                this.setState({ loading: false });
            });
        } else {
            toast.info("You can only see upto 30 days log");
            this.setState({ loading: false });
        }
    }

    onPageChange = (state, instance) => {
        let page = state.page + 1;
        let pageSize = state.pageSize;
        let sort = state.sorted;
        if (this.state.startDate !== "" && this.state.endDate !== "") {
            this.setState({ loading: true });
            this.searchData(page, pageSize, sort);
        }
    }

    // Method to convert byte array to Uint8Array which can be further used to create blob
    base64ToArrayBuffer = (base64) => {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    // Method to save byte array as file using FileSaver library
    downloadFile = (byteArray, fileNameWithExtension) => {
        let result = this.base64ToArrayBuffer(byteArray);
        let blob = new Blob([result], { type: 'application/octet-stream' });
        saveAs(blob, fileNameWithExtension);
    }

    downloadExcelFile = async () => {

        const { API_HOST, downloadExcelFile } = this.props;
        const finalUrl = `${API_HOST}UserMeeting/meetingreport_exporttoexcel`;
        this.setState({ enabled: true })
        let reqObj = {
            "UserId": this.state.currentObject.isUser.value,
            "StartDate": moment(this.state.startDate).format(),
            "EndDate": moment(this.state.endDate).format(),
        };
        if (this.state.startDate !== "" && this.state.endDate !== "") {
            await downloadExcelFile(finalUrl, reqObj, (res) => {
                this.downloadFile(res.Data.FileContent, res.Data.FileName)
                this.setState({ enabled: false })
            });
        }
    }

    getMeetingDetail = async (data) => {
        this.setState({ meetingDetailFlag: true, loadingTwo: true })
        const { API_HOST, getMeetingLogDetail } = this.props;
        let reqObj = {
            MeetingId: data.Id_UserMeeting
        }
        await getMeetingLogDetail(API_HOST, reqObj, (res) => {
            this.setState({ loadingTwo: false })
        });
    }

    toggleMeetingActivityDetail = () => {
        this.setState({
            meetingDetailFlag: !this.state.meetingDetailFlag,
            detailData: []
        })
    }

    render() {
        const { meetingActivityLog, data, startDate, pageSize, pageCount, loading, endDate, meetingDetailFlag, detailData, loadingTwo, enabled } = this.state;
        const { getUserAvailabilityListResponse } = this.props;
        let users = [{ value: 0, label: "All" }];
        getUserAvailabilityListResponse && getUserAvailabilityListResponse.map(obj => {
            if (obj.EmailId !== '' && obj.Real_Name !== '') {
                users.push({ value: obj.Id_User, label: obj.Real_Name })
            }
            return users;
        })


        const columns = [
            {
                Header: <strong className="my_custom_class">Meeting Num.</strong>,
                accessor: 'Meeting_Number',
            },
            {
                Header: <strong className="my_custom_class">Title</strong>,
                accessor: 'Title',
            },
            {
                Header: <strong className="my_custom_class">Host Name</strong>,
                accessor: 'Name_HostUser',
            },
            {
                Header: <strong className="my_custom_class">Start Meeting DateTime</strong>,
                accessor: 'Start_Date_UTC',
                Cell: (row) => {
                    if (row.original.Start_Date_UTC) {
                        return <>{moment(row.original.Start_Date_UTC).format("YYYY-MM-DD HH:mm a")}</>
                    }
                }
            },
            {
                Header: <strong className="my_custom_class">End Meeting DateTime</strong>,
                accessor: 'End_Date_UTC',
                Cell: (row) => {
                    if (row.original.End_Date_UTC) {
                        return <>{moment(row.original.End_Date_UTC).format("YYYY-MM-DD HH:mm a")}</>
                    }
                }
            },
            {
                Header: <strong className="my_custom_class">Action</strong>,
                accessor: 'Id',
                Cell: (row) => {
                    return (<><i style={{ cursor: 'pointer', fontSize: '20px', paddingLeft: '25px', color: '#5DADE2' }} onClick={() => this.getMeetingDetail(row.original)} class="fa fa-eye" aria-hidden="true"></i></>)
                }
            }
        ];

        const columnsTwo = [
            {
                Header: <strong className="my_custom_class">Participant</strong>,
                accessor: 'Name_User',
                width: 150
            },
            {
                Header: <strong className="my_custom_class">Join Meeting DateTime</strong>,
                accessor: 'Actual_Start_Time_UTC',
                width: 200,
                Cell: (row) => {
                    if (row.original.Actual_Start_Time_UTC && row.original.Actual_Start_Time_UTC !== null) {
                        return <>{moment(row.original.Actual_Start_Time_UTC).format("YYYY-MM-DD HH:mm a")}</>
                    } else {
                        return <>Sorry! No Data</>
                    }
                }
            },
            {
                Header: <strong className="my_custom_class">Leave Meeting DateTime</strong>,
                accessor: 'Actual_End_Time_UTC',
                width: 200,
                Cell: (row) => {
                    if (row.original.Actual_End_Time_UTC && row.original.Actual_End_Time_UTC !== null) {
                        return <>{moment(row.original.Actual_End_Time_UTC).format("YYYY-MM-DD HH:mm a")}</>
                    } else {
                        return <>Sorry! No Data</>
                    }
                }
            }
        ];

        return (
            <React.Fragment>
                <Modal isOpen={meetingActivityLog} backdrop={true} size={'xl'} >
                    <ModalHeader toggle={() => this.toggleMeetingActivityLog()}>
                        Meeting Log
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Row>
                                <Col xs="4"></Col>
                                <Col xs="2">
                                    <input
                                        className="form-control"
                                        type="date"
                                        onChange={e => this.setState({ startDate: e.target.value })}
                                        value={startDate}
                                    />
                                </Col>
                                <Col xs="2">
                                    <input
                                        type="date"
                                        className="form-control"
                                        min={startDate}
                                        onChange={e => this.setState({ endDate: e.target.value })}
                                        value={endDate}
                                    />
                                </Col>
                                <Col xs="2" style={{ marginTop: '5px' }}>
                                    <Select
                                        components={makeAnimated}
                                        onChange={newValue => this.userChange(newValue)}
                                        options={users}
                                        value={this.state.currentObject.isUser}
                                    />
                                </Col>
                                <Col xs="1">
                                    <Button type="button" className="btn-icon btn-2" style={{ marginLeft: '-15%' }} color="primary" onClick={this.getMeetingLogData}><i className="fa fa-search" style={{ fontSize: '18px', color: 'white' }} ></i></Button>
                                </Col>
                                <Col xs="1">
                                    <Button type="button" className="btn-icon btn-2" style={{ marginLeft: '-15%' }} color="warning" onClick={this.downloadExcelFile}>{enabled === false ? <i className="fa fa-download" style={{ fontSize: '18px', color: 'white' }} ></i> :
                                        <ClockLoader
                                            size={20}
                                            color={"#64B988"}
                                            loading={enabled}
                                        />}
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <ReactTable
                                manual
                                pages={pageCount}
                                data={data}
                                columns={columns}
                                defaultPageSize={pageSize}
                                pagination={true}
                                className="-striped -highlight"
                                pageSizeOptions={[10]}
                                onFetchData={this.onPageChange}
                                loading={loading}
                            />
                        </FormGroup>
                    </ModalBody>
                </Modal>
                <Modal isOpen={meetingDetailFlag} backdrop={true} size={'lg'} >
                    <ModalHeader toggle={() => this.toggleMeetingActivityDetail()}>
                        User Meeting Details
                    </ModalHeader>
                    <ModalBody>
                        <ReactTable
                            data={detailData}
                            columns={columnsTwo}
                            defaultPageSize={5}
                            className="-striped -highlight"
                            pageSizeOptions={[5]}
                            loading={loadingTwo}
                        />
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}


// Prop types of props.
MeetingActivityLog.propTypes = {
    API_HOST: PropTypes.string,
};

// Set default props.
MeetingActivityLog.defaultProps = {
    meetingLogResponse: PropTypes.array,
    meetingLogDetailResponse: PropTypes.array,
    getMeetingLog: () => { },
    downloadExcelFile: () => { },
    getMeetingLogDetail: () => { },
    getUserAvailabilityListResponse: []
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    meetingLogResponse: state.LogReducer.meetingLogResponse,
    getUserAvailabilityListResponse: state.mainOfficeReducer.userListDepartmentFilterResponse,
    meetingLogDetailResponse: state.LogReducer.meetingLogDetailResponse
})

export default connect(mapStateToProps, { getMeetingLogDetail, downloadExcelFile, getMeetingLog })(MeetingActivityLog)