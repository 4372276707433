
import React from 'react';
import { Button} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from "../../utils/apiUrls";
import { getGoogleConfig, updateusergooglerefreshtoken } from '../../actions/googleCalendarMeetingActions';
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';
import { getEncryptedValue, getDecryptedValue } from "../../utils/utility";
import axios from 'axios';

class GoogleConfiguration extends React.Component {
  state = {
    clientId: "",
    clientSecret: "",
    clientScope: "",
    // fields: {
    //   "accesstoken": "",
    //   "appid": "",
    //   "clientid": "",
    //   "clientsecret": "",
    //    "scope":"",
    //   "verificationtoken": ""
    // },
    isNewRefreshTokenGenerate: false,
    loading: true,
  };

   componentDidMount () {
     this.getGoogleConfig();
    // if (this.props.slackConfigListResponse[0] !== null) {
    //   let fields = this.state.fields;
    //   fields = {
    //     "accesstoken": this.props.slackConfigListResponse[0].OAuth_Access_Token,
    //     "appid": this.props.slackConfigListResponse[0].App_ID,
    //     "clientid": this.props.slackConfigListResponse[0].Client_ID,
    //     "clientsecret": this.props.slackConfigListResponse[0].Client_Secret,
    //      "scope":this.props.slackConfigListResponse[0]Scope_Detail
    //   }
    //   this.setState({ fields })
    // }
  }

  // componentWillReceiveProps = (nextProps) => {
  //   if (nextProps.slackconfigResponse !== this.props.slackconfigResponse) {
  //     this.props.getSlackConfigurationlist()
  //     this.props.toggle()
  //   }
  // };

  getGoogleConfig = () => {
    const { API_HOST, getGoogleConfig } = this.props;
    getGoogleConfig(API_HOST, (res) => {
      // console.log("get res",res)
      if (res[0]) {
        this.setState({ clientId: getDecryptedValue(res[0].GoogleClientId), clientSecret: getDecryptedValue(res[0].GoogleClientSecretkey), clientScope: res[0].Scope, loading: false })
      }
    });
  }

  setNewRefreshtoken = async() => {

     window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${this.state.clientScope}&access_type=offline&include_granted_scopes=true&response_type=code&prompt=consent&state=hello there&redirect_uri=${window.location.protocol}//${window.location.host}/googleconfig&client_id=${this.state.clientId}`;
     //await this.responseGoogleRefresh();
  }
  responseGoogleRefresh = () => {

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let code = params.get('code');
      
      //window.location.href = `https://accounts.google.com/o/oauth2/token?code=${code}&client_id=${this.state.clientId}&client_secret=${this.state.clientSecret}&redirect_uri=${window.location.protocol}//${window.location.host}&grant_type=authorization_code`;

      if(code !== undefined){
        this.getAccessToken(code);
      }
  }

  getAccessToken = async (code) => {
      try {
        let finalUrl = 'https://accounts.google.com/o/oauth2/token';
        let resource = {
          "client_id": this.state.clientId,
          "code": code,
          "grant_type": "authorization_code",
          "client_secret": this.state.clientSecret,
          "redirect_uri": `${window.location.protocol}//${window.location.host}`,
        }

        let headers = {
          "Content-Type": "application/json",
        };
        const data = await axios.post(finalUrl, resource, { headers });
        //console.log("DATA@: " , data);
        await this.updateUserRefreshToken(data.data.refresh_token);
      }
      catch (error) {
        toast.error(error)
        this.setState({ loading: false })
      }
  }

  updateUserRefreshToken = async (refreshToken) => {
    const { API_HOST, updateusergooglerefreshtoken } = this.props;
    await updateusergooglerefreshtoken(API_HOST, getEncryptedValue(refreshToken), (res) => {
      
      // console.log("Updated Refresh Token : " , refreshToken);
      // console.log("Encrypted Updated Refresh Token : " + getEncryptedValue(refreshToken));

      if (res) {
        let userData = JSON.parse(localStorage.getItem('userdetail'));
        userData.Google_Refresh_Token = getEncryptedValue(refreshToken);
        localStorage.setItem('userdetail', JSON.stringify(userData));
        toast.success("Your token is successfully generated")
      }
    })
  }

  render() {
    const { } = this.props;
    // let url="../../images/SlackConfig.mp4";

    return (
      //added google configuration to modal popup  as before it was a different modal popup but now it is in same moreinfo tab and commented previous code
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text='Loading...'
      >
        <center>
        { this.state.clientId !== "" ?
          <div style={{ marginTop: '150px' }}>
            <h1>Welcome!</h1>
            <h4>You can connect to your Google Account</h4>
          <Button className="btn btn-primary" onClick={() => { this.setNewRefreshtoken() }} disabled={this.state.isNewRefreshTokenGenerate}>{JSON.parse(localStorage.getItem('userdetail')).Google_Refresh_Token === "" ? "Connect To google":"Regenerate Your Google Token"}</Button>
          </div>
          : <div><h1>Sorry you are unable to connect with Google</h1>
          <h4> {JSON.parse(localStorage.getItem('userdetail')).Is_Admin ? "You have to Add Google client configuration first" : "Your workspace is not connected with google calendar.Please inform your admin to connect with google"}
          </h4>
          </div>
          }
      </center>
      </LoadingOverlay>
     
    );
  }
}

// Prop types of props.
GoogleConfiguration.propTypes = {
  API_HOST: PropTypes.string,
  slackconfig: PropTypes.func,
  slackconfigResponse: PropTypes.object,
  slackConfigListResponse: PropTypes.any
};

// Set default props..
GoogleConfiguration.defaultProps = {

};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),

});

export default connect(mapStateToProps, { getGoogleConfig, updateusergooglerefreshtoken })(GoogleConfiguration);
