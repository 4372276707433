
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from '../../../utils/apiUrls';
import { getJiraConfig, saveJiraConfig } from "../../../actions/jiraActions";
import { getDecryptedValue } from "../../../utils/utility";



// This code has been moved in Admin Panel for ths  admin configuration process.
class JiraClientConfig extends React.Component {
  state = {
    fields: {
      "id": 0,
      "clientid": "cAZNxpJVLR1q9byIPEVgLYt3XvKtKgNq",
      "clientsecret": "QhLlfJBeTNxR4XXFx-aydkRaCE_oETtKmfesIeD_UxP9ejMt6_M_mED_lPKpVJrz",
      "scope": "read%3Ajira-user%20manage%3Ajira-data-provider%20write%3Ajira-work%20manage%3Ajira-configuration%20manage%3Ajira-project%20read%3Ajira-work"
      // "clientid": "PuGIc1NvS7mbD7G7biq7Qrv4GyplACkG",
      // "clientsecret": "XMcA2tzYnizrdUrbRPyqueSI8RML7Cz06z6CcaVcqTr8dvQLfMnTvQC9lPFGsN76",
      // "scope": "offline_access%20read%3Ajira-user%20write%3Ajira-work%20read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20manage%3Ajira-data-provider"
    },
    // fields: {
    //   "accesstoken": "",
    //   "appid": "",
    //   "clientid": "",
    //   "clientsecret": "",
    //    "scope":"",
    //   "verificationtoken": ""
    // },
    errors: {},
  };

  componentDidMount = () => {
    this.getJIRAConfig()
    // if (this.props.slackConfigListResponse[0] !== null) {
    //   let fields = this.state.fields;
    //   fields = {
    //     "accesstoken": this.props.slackConfigListResponse[0].OAuth_Access_Token,
    //     "appid": this.props.slackConfigListResponse[0].App_ID,
    //     "clientid": this.props.slackConfigListResponse[0].Client_ID,
    //     "clientsecret": this.props.slackConfigListResponse[0].Client_Secret,
    //      "scope":this.props.slackConfigListResponse[0]Scope_Detail
    //   }
    //   this.setState({ fields })
    // }
  }

  // componentWillReceiveProps = (nextProps) => {
  //   if (nextProps.slackconfigResponse !== this.props.slackconfigResponse) {
  //     this.props.getSlackConfigurationlist()
  //     this.props.toggle()
  //   }
  // };

  getJIRAConfig = () => {
    const { API_HOST, getJiraConfig } = this.props;
    getJiraConfig(API_HOST, (res) => {
      console.log("2get res", res)
      if (res[0]) {
        let fields = this.state.fields;
        fields = {
          "id": res[0].Id,
          "clientid": getDecryptedValue(res[0].Client_ID),
          "clientsecret": getDecryptedValue(res[0].Client_Secret),
          "scope": res[0].Scope

        }
        this.setState({ fields })
      }
    });
  }

  // Function calls while type and  set their values.
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  // Function handles validation and if success then calls google config method.
  onSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.onJIRAConfigSave();
    }
  };

  // Calls Sign up  API to get logged in.
  onJIRAConfigSave = async () => {
    const { API_HOST, saveJiraConfig } = this.props;
    // let url = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${window.location.protocol}//${window.location.host}/slacktoken`;


    const googleconfigRequest = {
      id: this.state.fields["id"],
      Client_ID: this.state.fields["clientid"],
      Client_Secret: this.state.fields["clientsecret"],
      Scope: this.state.fields["scope"]
      //   Redirect_Url: url
    };
    console.log("request", googleconfigRequest)
    saveJiraConfig(API_HOST, googleconfigRequest, (res) => {
      console.log("res", res)
      if (res) {
        this.props.toggle()
      }
    });

  };


  // Handles validation for required fields.sdfs
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;



    // Client Id
    if (!fields["clientid"]) {
      formIsValid = false;
      errors["clientid"] = "Clientid is required";

      errors["clientidclass"] = "is-invalid";
    } else {
      formIsValid = true;
      errors["clientid"] = "Clientid";
      errors["clientidclass"] = "is-valid";
    }


    if (!fields["clientsecret"]) {
      formIsValid = false;
      errors["clientsecret"] = "clientsecret is required";
      errors["clientsecretclass"] = "is-invalid";
    } else {
      formIsValid = true;
      errors["clientsecret"] = "clientsecret";
      errors["clientsecretclass"] = "is-valid";
    }

    // if (!fields["verificationtoken"]) {
    //   formIsValid = false;
    //   errors["verificationtoken"] = "Verificationtoken is required";
    //   errors["userconfirmpasswordclass"] = "is-invalid";
    // } else {
    //   formIsValid = true;
    //   errors["verificationtoken"] = "Verificationtoken";
    //   errors["userconfirmpasswordclass"] = "is-valid";
    // }

    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { isOpen } = this.props;
    // let url="../../images/SlackConfig.mp4";

    return (
      <Modal isOpen={isOpen} backdrop={true} className="add-edit-class">
        <ModalHeader toggle={() => this.props.toggle()} className="dept-header">
          JIRA Configuration
        </ModalHeader>
        <form method="post" id="form2" onSubmit={this.onSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">ClientId</label>
                  <input
                    type="text"
                    className={`form-control
                       ${this.state.errors && this.state.errors["clientidclass"]
                        ? this.state.errors["clientidclass"]
                        : ""}
                      `}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    //placeholder="Enter your clientid"
                    value={this.state.fields["clientid"] || ""}

                    name="clientid"
                    onChange={(e) => this.handleChange("clientid", e)}
                    required
                  />
                  {/* <span style={{ color: "red" }}>
                      {this.state.errors["accesstoken"]}
                    </span> */}
                </div>
                <div className="form-group">
                  <label htmlFor="clientsecret">ClientSecret</label>
                  <input
                    type="text"
                    className={`form-control ${this.state.errors &&
                      this.state.errors["clientsecretclass"]
                      ? this.state.errors["clientsecretclass"]
                      : ""
                      }`}
                    id="clientsecret"
                    //placeholder=" Enter ClientSecret"
                    value={this.state.fields["clientsecret"] || ""}
                    name="clientsecret"
                    onChange={(e) => this.handleChange("clientsecret", e)}
                    required
                  />
                  {/* <span style={{ color: "red" }}>
                      {this.state.errors["password"]}
                    </span> */}
                </div>
                <div className="form-group">
                  <label >
                    Scope
                    </label>
                  <input
                    type="text"
                    className="form-control"
                    id="VerificationToken"
                    //placeholder=" Enter Verification Token"
                    value={this.state.fields["scope"] || ""}
                    name="scope"
                    onChange={(e) =>
                      this.handleChange("scope", e)
                    }

                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" >Save</Button>
          </ModalFooter>
        </form>
      </Modal >
    );
  }
}

// Prop types of props.
JiraClientConfig.propTypes = {
  API_HOST: PropTypes.string,
  slackconfig: PropTypes.func,
  slackconfigResponse: PropTypes.object,
  slackConfigListResponse: PropTypes.any
};

// Set default props..
JiraClientConfig.defaultProps = {

};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),

});

export default connect(mapStateToProps, { getJiraConfig, saveJiraConfig })(JiraClientConfig);
