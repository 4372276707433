import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getApiHost } from "../../../utils/apiUrls";
import {
  getEncryptedValue,
  getDecryptedValue,
} from "../../../utils/utility";
import {
  FormGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import {
  getJiraDomain,
  getUserJiraRefreshToken,
  getJiraConfig,
  updateUserJiraRefreshToken,
  getJiraEpicWiseReport,
  jiraEpicWiseReportDownload,
} from "../../../actions/jiraActions";
import { getPrivateJiraProjectList } from "../../../actions/privateJiraActions";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import ClockLoader from "react-spinners/ClockLoader";
import { saveAs } from "file-saver";
import Multiselect from "multiselect-react-dropdown";

const axios = require("axios");

class Insights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jiraIssue: {},
      cloudId: "",
      accessToken: "",
      refreshToken: "",
      jiraClientId: "",
      jiraClientSecret: "",
      JiraTaskList: [],
      jiraDomain: [],
      jiraDomainSelected: {
        id: 0,
        CloudId: "",
        Domain_Name: "",
        key: "",
        url: "",
        Jira_Scope_Detail: "",
        label: "Select...",
        value: "",
      },
      idJiraTask: 0,
      jiraCloudId: "",
      taskDescription: "",
      isCreateDisabled: false,
      description: "",
      projectList: [],
      projectSelected: null,
      backlogIssuesTaskList: [],
      taskSelected: {},
      jiraEpicWiseResponse: [],
      loading: false,
      disabled: false,
      optionSelected: null,
      epicStatusSelected: [],
      preSlectedstatus: [{ name: "In Progress" }],
    };
  }
  componentDidMount() {
    this.getJIRAConfig();
    //this.getJiraDomain();
  }

  getJIRAConfig = () => {
    const { API_HOST, getJiraConfig } = this.props;
    getJiraConfig(API_HOST, (res) => {
      if (res[0]) {
        this.setState({
          jiraClientId: getDecryptedValue(res[0].Client_ID),
          jiraClientSecret: getDecryptedValue(res[0].Client_Secret),
        });

        this.getRefreshToken();
      } else {
        this.setState({ jiraClientDisable: true });
      }
      this.getJiraDomain();
    });
  };

  getJiraDomain = async () => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, (res) => {
      if (res[0]) {
        let newValue = res.map(function (obj) {
          return {
            id: getDecryptedValue(obj.CloudId),
            CloudId: getDecryptedValue(obj.CloudId),
            Domain_Name: obj.Domain_Name,
            key: obj.Domain_Name,
            url: obj.url,
            Jira_Scope_Detail: obj.Jira_Scope_Detail,
            label: obj.Domain_Name,
            value: getDecryptedValue(obj.CloudId),
            is_private: obj.Is_Private,
          };
        });
        this.setState({
          jiraDomain: newValue,
          //jiraDomainSelected: newValue[0],
          cloudId: newValue[0].CloudId,
        });
      }
    });
  };

  onSelectDomain = async (newValue) => {
    await this.setState({
      jiraDomainSelected: newValue,
      cloudId: newValue.CloudId,
    });
    if (newValue.is_private) {
      await this.getPrivateJiraProjectList();
    } else {
      await this.getprojectsList();
    }
  };

  onSelectprojects = async (newValue) => {
    await this.setState({ projectSelected: newValue });
  };

  getprojectsList = async () => {
    try {
      let finalUrl = `https://api.atlassian.com/ex/jira/${this.state.cloudId}/rest/api/2/project/search?expand=lead%2Curl%2Cfavourite%2Cpermissions&maxResults=50&orderBy=%2BNAME&startAt=0`;

      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.state.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ projectList: data.values });
      await this.jiraBacklogIssue(data.values[26]);
    } catch (error) {
      toast.error(error);
    }
  };

  getPrivateJiraProjectList = async () => {
    const { API_HOST, getPrivateJiraProjectList } = this.props;

    let requestBody = {
      DomainName: this.state.jiraDomainSelected.Domain_Name,
    };

    await getPrivateJiraProjectList(API_HOST, requestBody, (res) => {
      if (res) {
        this.setState({ projectList: res });
      }
    });
  };

  getRefreshToken = () => {
    const { API_HOST, getUserJiraRefreshToken } = this.props;

    getUserJiraRefreshToken(API_HOST, (res) => {
      if (res.Jira_Token) {
        this.setState({ refreshToken: res.Jira_Token });
        this.getAccessToken(res.Jira_Token);
        this.setState({ jiraClientDisable: false, isActive: false });
      } else {
        toast.info("Please connect your application with your JIRA");
        this.setState({ jiraClientDisable: true, isActive: false });
      }
    });
  };

  getAccessToken = async (refreshToken) => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: getDecryptedValue(refreshToken),
      };

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });
      this.setState({
        accessToken: data.data.access_token,
        refreshToken: data.data.refresh_token,
      });

      await this.updateUserJiraRefreshToken(data.data.refresh_token);
      await this.getJiraYourTaskList(
        this.state.cloudId,
        data.data.access_token
      );
    } catch (error) {
      toast.error(
        "Refresh token is expired.Please reconnect your application with your JIRA"
      );
      toast.error(error);
      this.setState({ isActive: false });
    }
  };

  updateUserJiraRefreshToken = async (refreshToken) => {
    const { API_HOST, updateUserJiraRefreshToken } = this.props;

    let requestBody = {
      Jira_Token: getEncryptedValue(refreshToken),
    };

    await updateUserJiraRefreshToken(API_HOST, requestBody);
  };

  getAccessTokenAgain = async () => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: this.state.refreshToken,
      };

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });
      return data.data.access_token;
    } catch (error) {
      toast.error(
        "Refresh token is expired.Please reconnect your application with your JIRA"
      );
      this.setState({ isActive: false });
    }
  };

  getJiraEpicWiseReport = async () => {
    const { API_HOST, getJiraEpicWiseReport } = this.props;
    this.setState({ jiraEpicWiseResponse: [] });
    let selectedEpicStatus;

    if (this.state.epicStatusSelected.length === 0) {
      selectedEpicStatus = this.state.preSlectedstatus.map(
        (label) => label.name
      );
    } else {
      selectedEpicStatus = this.state.epicStatusSelected.map(
        (label) => label.name
      );
    }
    if (selectedEpicStatus.length !== 0) {
      this.setState({ loading: true });
      let requestBody = {
        ProjectKey: this.state.projectSelected.key,
        DomainName: this.state.jiraDomainSelected.Domain_Name,
        EpicStatus: selectedEpicStatus,
      };

      await getJiraEpicWiseReport(API_HOST, requestBody, (res) => {
        if (res.error === true) {
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false, jiraEpicWiseResponse: res });
        }
      });
    }
  };

  jiraEpicWiseReportDownload = async () => {
    const { API_HOST, jiraEpicWiseReportDownload } = this.props;
    this.setState({ disabled: true });
    let selectedEpicStatus;

    if (this.state.epicStatusSelected.length === 0) {
      selectedEpicStatus = this.state.preSlectedstatus.map(
        (label) => label.name
      );
    } else {
      selectedEpicStatus = this.state.epicStatusSelected.map(
        (label) => label.name
      );
    }
    if (selectedEpicStatus.length !== 0) {
      this.setState({ loading: true });
      let requestBody = {
        ProjectKey: this.state.projectSelected.key,
        DomainName: this.state.jiraDomainSelected.Domain_Name,
        EpicStatus: selectedEpicStatus,
      };

      await jiraEpicWiseReportDownload(API_HOST, requestBody, (res) => {
        if (res.error === true) {
          this.setState({ disabled: false, loading: false });
        } else {
          this.downloadFile(res.Data.FileContent, res.Data.FileName);
          this.setState({ disabled: false, loading: false });
        }
      });
    }
  };

  // Method to save byte array as file using FileSaver library
  downloadFile = (byteArray, fileNameWithExtension) => {
    let result = this.base64ToArrayBuffer(byteArray);
    let blob = new Blob([result], { type: "application/octet-stream" });
    saveAs(blob, fileNameWithExtension);
  };

  // Method to convert byte array to Uint8Array which can be further used to create blob
  base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  onSelect = async (selectedList, selectedItem) => {
    this.setState({
      epicStatusSelected: selectedList,
    });
  };

  onRemove = async (selectedList, removedItem) => {
    this.setState({
      epicStatusSelected: selectedList,
    });
    if (this.state.epicStatusSelected.length === 0) {
      await this.setState({
        preSlectedstatus: [],
      });
    }
  };
  render() {
    const { loading, jiraEpicWiseResponse } = this.state;
    const { isInsights } = this.props;

    let projectlistOptions = [];
    this.state.projectList[0] &&
      this.state.projectList.map((obj) => {
        projectlistOptions.push({
          id: obj.id,
          avatarUrls: obj.avatarUrls,
          name: obj.name,
          key: obj.key,
          self: obj.self,
          projectTypeKey: obj.projectTypeKey,
          label: obj.name + "(" + obj.key + ")",
          value: obj.name,
        });
        return projectlistOptions;
      });

    const columns = [
      {
        Header: <strong className="my_custom_class">Epic Name</strong>,
        accessor: "EpicName",
        minWidth: 200,
        Footer: (
          <>
            <b>Total:</b>
          </>
        ),
      },
      {
        Header: <strong className="my_custom_class">To Do</strong>,
        accessor: "ToDoCount",
        minWidth: 50,
        Footer: (info) => {
          const toDoTotal = this.state.jiraEpicWiseResponse.reduce(
            (accum, item) => accum + item.ToDoCount,
            0
          );

          return (
            <>
              <b>{toDoTotal > 0 ? toDoTotal : ""}</b>
            </>
          );
        },
      },
      {
        Header: <strong className="my_custom_class">In Progress</strong>,
        accessor: "InProgressCount",
        minWidth: 50,
        Footer: (info) => {
          const inProgressTotal = this.state.jiraEpicWiseResponse.reduce(
            (accum, item) => accum + item.InProgressCount,
            0
          );

          return (
            <>
              <b>{inProgressTotal > 0 ? inProgressTotal : ""}</b>
            </>
          );
        },
      },
      {
        Header: <strong className="my_custom_class">Done</strong>,
        accessor: "DoneCount",
        minWidth: 50,
        Footer: (info) => {
          const doneTotal = this.state.jiraEpicWiseResponse.reduce(
            (accum, item) => accum + item.DoneCount,
            0
          );

          return (
            <>
              <b>{doneTotal > 0 ? doneTotal : ""}</b>
            </>
          );
        },
      },
      {
        Header: <strong className="my_custom_class">To Do %</strong>,
        accessor: "ToDoPercentage",
        minWidth: 50,
      },
      {
        Header: <strong className="my_custom_class">In Progress %</strong>,
        accessor: "InProgressPercentage",
        minWidth: 50,
      },
      {
        Header: <strong className="my_custom_class">Done %</strong>,
        accessor: "DonePercentage",
        minWidth: 50,
      },
      {
        Header: <strong className="my_custom_class">Total Tasks</strong>,
        accessor: "TotalTask",
        minWidth: 60,
        Footer: (info) => {
          const total = this.state.jiraEpicWiseResponse.reduce(
            (accum, item) => accum + item.TotalTask,
            0
          );

          return (
            <>
              <b>{total > 0 ? total : ""}</b>
            </>
          );
        },
      },
      {
        Header: <strong className="my_custom_class">Epic Status</strong>,
        accessor: "EpicCurrentStatus",
        minWidth: 60,
      },
    ];

    const statusOptions = [
      { name: "To Do" },
      { name: "In Progress" },
      { name: "Done" },
    ];

    return (
      <React.Fragment>
        <Modal isOpen={isInsights} backdrop={true} size={"xl"}>
          <ModalHeader
            toggle={this.props.closeInsights}
            className="dept-header"
          >
            Insights
          </ModalHeader>
          <ModalBody className="dept-class">
            <div>
              <Row>
                <Col xs="4">
                  <FormGroup>
                    <h6>Jira Domain</h6>
                    <Select
                      hideSelectedOptions={false}
                      components={makeAnimated}
                      onChange={(newValue) => {
                        this.onSelectDomain(newValue);
                      }}
                      options={this.state.jiraDomain}
                      value={this.state.jiraDomainSelected}
                    />
                  </FormGroup>
                </Col>

                <Col xs="3">
                  <FormGroup>
                    <h6>Project</h6>
                    <Select
                      components={makeAnimated}
                      onChange={(newValue) => {
                        this.onSelectprojects(newValue);
                      }}
                      options={projectlistOptions}
                      value={this.state.projectSelected}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <h6>Epic Status</h6>
                    <Multiselect
                      options={statusOptions} // Options to display in the dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      showCheckbox
                      selectedValues={this.state.preSlectedstatus}
                      hidePlaceholder
                      avoidHighlightFirstOption
                    />
                  </FormGroup>
                </Col>
                <Col xs="1">
                  <Button
                    type="button"
                    className="btn-icon btn-2 btn-filter-icon"
                    color="primary"
                    onClick={this.getJiraEpicWiseReport}
                    style={{ top: "3px" }}
                  >
                    <i
                      className="fa fa-search"
                      style={{ fontSize: "18px", color: "white" }}
                    ></i>
                  </Button>
                  <Button
                    type="button"
                    className="btn-icon btn-2 btn-filter-icon"
                    color="secondary"
                    onClick={this.jiraEpicWiseReportDownload}
                    style={{ marginBottom: "25px", top: "10px" }}
                  >
                    {this.state.disabled === false ? (
                      <i
                        className="fa fa-download"
                        style={{ fontSize: "18px", color: "white" }}
                      ></i>
                    ) : (
                      <ClockLoader
                        size={20}
                        color={"#64B988"}
                        loading={this.state.disabled}
                      />
                    )}
                  </Button>
                </Col>
              </Row>
            </div>

            <div>
              <ReactTable
                data={jiraEpicWiseResponse}
                columns={columns}
                defaultPageSize={10}
                style={{
                  height: "400px", // This will force the table body to overflow and scroll, since there is not enough room
                }}
                showPagination={false}
                className="-striped -highlight"
                loading={loading}
              />
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

// Prop types of props.
Insights.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
Insights.defaultProps = {};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
});

export default connect(mapStateToProps, {
  getJiraDomain,
  getJiraConfig,
  getUserJiraRefreshToken,
  updateUserJiraRefreshToken,
  getJiraEpicWiseReport,
  jiraEpicWiseReportDownload,
  getPrivateJiraProjectList,
})(Insights);
