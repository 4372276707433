
import React from 'react';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { importChannelList, importUsersList, importChannelUserList } from "../../../actions/progressBarActions";
import { getApiHost } from "../../../utils/apiUrls";
import { Typography } from '@material-ui/core';
import { socket } from "../../../utils/apiUrls";
import { getUserDetail } from "../../../actions/dashboardActions";

class ProgressBar extends React.Component {

    interval = 0;

    constructor(props) {
        super(props)
        this.state = {
            percentage: 0,
            isProgressBar: this.props.isProgressBar,
            runProcess: '',
            status: 'active',
            slackTeamResponse: this.props.slackTeamResponse
        };
    }

    componentDidMount() {
        this.props.importSlackStepsTrace(); // added later
        this.checkSlackProgress(this.props.importSlackStep);
    }

    checkSlackProgress = (stepId) =>{
        if (stepId === 0) {
            setTimeout(() => {
                this.setState({ percentage: 0, runProcess: 'Importing Channels...' })
                this.importChannelList();
            }, 1000)
        } else if (stepId === 1) {
            this.setState({
                percentage: 21,
                runProcess: 'Importing Users...'
            })
            this.importUsersList();
        } else if (stepId === 2) {
            this.setState({
                percentage: 51,
                runProcess: 'Importing Channel Users.....................Please wait this will take several minutes'
            })
            this.importChannelUserList();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getUserDetailResponse !== this.props.getUserDetailResponse) {
            localStorage.setItem("userdetail", JSON.stringify(this.props.getUserDetailResponse));
        }
        if (prevProps.isProgressBar !== this.props.isProgressBar) {
            this.setState({
                isProgressBar: this.props.isProgressBar
            })
        }

        if(prevProps.importSlackStep !== this.props.importSlackStep){
            if(this.props.importSlackStep !== null)
            {
                this.checkSlackProgress(this.props.importSlackStep);
            }
            
        }
    }

    importChannelList = () => {
        const { API_HOST, importChannelList } = this.props;

        this.runProgress(0,20);
        importChannelList(API_HOST, (res) => {
            if (res === true) {
                clearInterval(this.interval);
                this.setState({
                    percentage: 21,
                    runProcess: 'Importing Users...'
                })
                this.importUsersList();
            } else {
                this.setState({
                    status: 'error'
                })
                this.props.importSlackStepsTrace();
                setTimeout(() => {
                    this.props.closeProgressBar(false);
                }, 1000)
            }
        });
    }

    importUsersList = () => {
        const { API_HOST, importUsersList } = this.props;

        this.runProgress(21,50);
        importUsersList(API_HOST, (res) => {
            if (res === true) {
                clearInterval(this.interval);
                this.setState({
                    percentage: 51,
                    runProcess: 'Importing Channel Users.....................Please wait this will take several minutes'
                })
                this.importChannelUserList();
            } else {
                this.setState({
                    status: 'error'
                })
                this.props.importSlackStepsTrace();
                setTimeout(() => {
                    this.props.closeProgressBar(false);
                }, 1000)
            }
        });
    }

    importChannelUserList = () => {
        const { API_HOST, importChannelUserList } = this.props;

        this.runProgress(51,97);
        importChannelUserList(API_HOST, (res) => {
            if (res === true) {
                var userData = JSON.parse(localStorage.getItem('userdetail'));//////
                clearInterval(this.interval);
                this.setState({
                    percentage: 100,
                    runProcess: 'Data imported successfully',
                    status: "success"
                })
                this.props.importSlackStepsTrace();
                this.getUserDetail(userData.Id_User);//////////
                setTimeout(() => {
                    this.props.closeProgressBar(false);
                }, 1000)
            } else {
                this.setState({
                    status: 'error'
                })
                this.props.importSlackStepsTrace();
                
                setTimeout(() => {
                    this.props.closeProgressBar(false);
                }, 1000)
            }
        });
      //  this.props.fetchMultipleEmail();
        socket.emit("updateWorkspace", this.props.multipleEmailResponse);
    }

    runProgress = async(startVal,endVal) => {

        let start = startVal;
                let count = (count) => {
                    this.setState({percentage:count})
                  }

                    this.interval =  setInterval(function(){
                    count(start++);

                    if(start === endVal){
                        clearInterval(this.interval);
                    }

                  }, 3000);

    }

    /////////////////////
    getUserDetail = async (id) => {
        const { API_HOST, getUserDetail } = this.props;
        await getUserDetail(API_HOST, id);
    
    };

    render() {
        const { isProgressBar, percentage, runProcess, status } = this.state;
        return (
            <React.Fragment>
                <Modal isOpen={isProgressBar} backdrop={true} size={'md'} style={{ marginTop: '20%' }}>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Progress
                                    type="line"
                                    strokeWidth={4}
                                    percent={percentage}
                                    status={status}
                                />
                                <Typography style={{ fontSize: 'small' }}>{runProcess}</Typography>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

// Prop types of props.
ProgressBar.propTypes = {
    API_HOST: PropTypes.string,
};

// Set default props.
ProgressBar.defaultProps = {
    importChannelList: () => { },
    importUsersList: () => { },
    importChannelUserList: () => { }
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    userListDepartmentFilterResponse: state.mainOfficeReducer.userListDepartmentFilterResponse,
    getUserDetailResponse: state.dashboardReducer.getUserDetailResponse.userDetailResponse  //////////
})

export default connect(mapStateToProps, { getUserDetail, importChannelList, importUsersList, importChannelUserList })(ProgressBar)
