import React from "react";
import StickyNotesList from "./StickyNotesList";
import { toast, Flip } from "react-toastify";
import { AvatarImage } from "../../../components/Avatar/AvatarImage";
import ToDoList from "./ToDoList";
import FeedBack from "../../contact_us/components/FeedBack";
import ContactUs from "../../contact_us/components/ContactUs";
import $ from "jquery";
import ZoomMeeting from "./ZoomMeeting";
import ZoomJoin from "./ZoomJoin";
import { Link } from "react-router-dom";
//import { UncontrolledTooltip } from 'reactstrap';
import ReactTooltip from "react-tooltip";
import {
  doorOpen,
  doorClose,
  userKnockListCabinWise,
  stickyCount,
  userKnockSeen,
  userStickySeen,
  setUserCustomStatus
} from "../../../actions/mainOfficeActions";
import { getUserListOfDepartment } from "../../../actions/departmentActions";
import { logout } from '../../../actions/loginActions';
import {
  userToDoList,
  saveReminder,
  cancelReminder,
  getReminderNotification
} from "../../../actions/todoActions";
import KnockStickyCount from "../../MainOfficeLayout/components/KnockStickyCount";
import CustomStatus from "../../MainOfficeLayout/components/CustomStatus";
import { connect } from "react-redux";
import { getApiHost } from "../../../utils/apiUrls";
import PropTypes from "prop-types";
import moment from "moment";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import toDoListIcon from "../../../assets/images/to-list-icon.png";
import stickyNoteIcon from "../../../assets/images/sicky-note-icon.png";
import chatIcon from "../../../assets/images/chat.png";
import zoomIcon from "../../../assets/images/screen-share.png";
import liveIcon from "../../../assets/images/call-us-icon.png";
import Jira from "../../jira/components/Jira";
import JiraIcon from "../../../assets/images/JiraIcon.png";
import MoreInfo from "./MoreInfo";
import { Card, CardContent, Typography } from "@material-ui/core";
import { FormGroup } from "reactstrap";
import Moment from "react-moment";
import "moment-timezone";

class Rightsidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      isModal: true,
      isStickyModal: true,
      isSentStickyModal: true,
      isFeedBack: false,
      isContactUs: false,
      zoomModal: false,
      isZoomModal: false,
      zoomUrl: "",
      currentInstance: this,
      todoList: [],
      count: 0,
      reminderList: [],
      activeItemIndex: 0,
      SearchbarUserId: this.props.SearchbarUserId,
      userRes: [],
      userFind: false,
      userId: 0,
      userDepartmentId: 0,
      knockSelected: false,
      makeAdminSelected: false,
      stickyNoteIsOpen: false,
      stickyNotesMessage: "",
      notificationIsOpen: false,
      onReadNote: false,
      userList: [],
      userStatus: "",
      userDetails: JSON.parse(localStorage.getItem("userdetail")),
      isCustomStatus: false,
      isJira: this.props.isJira,
      myProfileIsOpen: false,
      customStatus: "",
      customStatusClass: "active-status",
      customStatusIcon: "fas fa-circle",
      isToDoList: false,
      openReminder: false,
      reminderTaskList: [],
      date: new Date(),
      isShowTimeCalendar: false,
      showChatIcon: false
    };
  }

  updateTime = () => {
    let date = new Date();
    this.setState({ date: date });
  };

  async componentDidMount() {
    setInterval(this.updateTime, 5000);
    //await this.userToDoList();
    await this.getReminderNotification();
    let userDetails = JSON.parse(localStorage.getItem("userdetail"));
    this.setCustomStatusClass(userDetails.UserStatus);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.userToDoListResponse !== prevState.todoList) {
      setTimeout(() => {
        prevState.currentInstance.reminder(nextProps.userToDoListResponse);
      }, 300);
      return {
        todoList: nextProps.userToDoListResponse,
        isJira: nextProps.isJira
      };
    }

    return null;
  }

  componentWillUnmount() {
    clearInterval(this.remindInterval);
    clearInterval(this.snoozeInterval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reminderList !== this.props.reminderList) {
      this.setState({
        reminderList: this.props.reminderList
      });
    }
    if (prevProps.todoList !== this.props.todoList) {
      this.setState({
        todoList: this.props.todoList
      });
    }
    if (prevProps.reminderTaskList !== this.props.reminderTaskList) {
      this.setState({
        reminderTaskList: this.props.reminderTaskList
      });
    }
    if (prevProps.showChatIcon !== this.props.showChatIcon) {
      this.setState({ showChatIcon: this.props.showChatIcon});
    }
  }

  // Get user To Do List
  userToDoList = () => {
    var userData = localStorage.getItem("userdetail");
    const { API_HOST, userToDoList } = this.props;

    userToDoList(API_HOST, JSON.parse(userData).Id_User);
  };

  setSnooze = (list, then) => {
    var snoozeTime = moment(then)
      .add(list.Snooze, "minutes")
      .format("LLL");
    this.snoozeInterval = setInterval(() => {
      var now = moment(new Date()).format("LLL");
      if (now === snoozeTime) {
        toast.info("Task Reminder : " + list.Description + ". ", options);
      }
    }, 60 * 1000);
    return;
  };

  reminder = data => {
    clearInterval(this.remindInterval);
    if (data.length > 0 && data[0] !== null) {
      this.remindInterval = setInterval(() => {
        data.map(list => {
          if (list.Is_Reminder === true) {
            var now = new Date();
            var then = list.DueDate;
            now = moment(now).format("LLL");
            then = moment(then).format("LLL");

            if (now === then) {
              let temp = [list];
              if(list.Is_Jira_Task){
                toast.info("ETA to complete the task is over please inform respective PM or change the priority of other pending tasks: " + list.Description + ". ", options);
              }else{
                toast.info("Task Reminder : " + list.Description + ". ", options);
              }        
              this.getReminderNotification();
              if (list.Snooze !== 0) {
                this.setSnooze(list, then);
              }
              this.setState({
                reminderList: [...this.state.reminderList, ...temp],
                count: this.state.count + 1
              });
              this.saveReminder(list.Id, list.DueDate);
            }
          }
          return list;
        });
      }, 60 * 1000);
    }
  };

  setCount = async () => {
    await this.setState({
      count: 0,
      isModal: false,
      openReminder: true
    });

    await this.getReminderNotification();
  };

  saveReminder = (id, dueDate) => {
    const { API_HOST, saveReminder } = this.props;
    const reminderRequest = {
      Id: id,
      // DueDate: "0001-01-01T00:00:00",
      DueDate: dueDate,
      Reminder_Status: 0, // 3
      Is_Reminder: false,
      Is_Reminder_Sent: true
    };
    saveReminder(API_HOST, reminderRequest, res => {
      this.userToDoList();
    });
  };

  toggle = () => {
    this.setState({
      isModal: !this.state.isModal,
      openReminder: false
    });
  };

  toggleStickyNotes = () => {
    if (this.state.isModal === false) {
      this.setState({
        isModal: true
      });
    }
    this.setState({
      isStickyModal: !this.state.isStickyModal
    });
  };

  toggleToBeDeveloped = () => {
    toast.info("Functionality to be developed");
  };

  chatOpen = () => {
    // this.props.chatOpen();
    this.props.onClickUsersSelectedChat();
  };

  logOut = () => {
    this.logOutFunc();
    sessionStorage.removeItem("userToken");
    localStorage.removeItem("userdetail");

    // localStorage.removeItem("Token");
    // localStorage.removeItem("SlackUserId");
    // localStorage.removeItem("isFromSlackSiginIn");
  };

  logOutFunc = async() => {

    const { API_HOST, logout } = this.props;

    let userDetails = JSON.parse(localStorage.getItem("userdetail"));
    
    const userStartTime = moment(userDetails.UserStartTime_EndTimeVM.Availibility_Time.substr(0, 5), 'HH:mm').tz(userDetails.TZ).format('HH:mm');
    const userEndTime = moment(userDetails.UserStartTime_EndTimeVM.Availibility_Time.substr(userDetails.UserStartTime_EndTimeVM.Availibility_Time.length - 5), 'HH:mm').tz(userDetails.TZ).format('HH:mm');
    const today = new moment().format("HH:mm");

    if(today > userStartTime && today < userEndTime){
        userDetails.Is_LoggedIn = false;
        userDetails.Is_DoorOpen = false;
        userDetails.UserStartTime_EndTimeVM.Availibility_Status = "Unavailable";

       this.props.userValuesChanges(userDetails);
    }else{
        userDetails.Is_LoggedIn = false;
        userDetails.Is_DoorOpen = false;
        userDetails.UserStartTime_EndTimeVM.Availibility_Status = "OOO";

       this.props.userValuesChanges(userDetails);
    }
        await logout(API_HOST, (res) => {
          if (res) {
            
          }
        });

    };

  openFeedBack = () => {
    this.setState({
      isFeedBack: true
    });
  };

  toggleFeedBack = () => {
    this.setState({
      isFeedBack: false
    });
  };

  openContactUs = () => {
    this.setState({
      isContactUs: true
    });
  };

  toggleContactUs = () => {
    this.setState({
      isContactUs: false
    });
  };

  onSupportChat = () => {
    $(`.support-chat`).css("display", "block");
  };

  toggleChatSupport = () => {
    this.props.toggleSupportChat();
    this.onSupportChat();
  };

  zoomModalToggle = () => {
    this.setState({ zoomModal: !this.state.zoomModal });
  };

  zoomModalJoin = zoomUrl => {
    this.setState({
      isZoomModal: !this.state.isZoomModal,
      zoomUrl: zoomUrl
    });
  };

  //User Knock List Cabin Wise
  userKnockListCabinWise = async () => {
    const { API_HOST, userKnockListCabinWise } = this.props;
    const userKnockListCabinWiseRequest = {
      Id_UserReceiver: 0
      // "Id_Cabin": cabinId
    };
    await userKnockListCabinWise(API_HOST, userKnockListCabinWiseRequest);
  };

  //User Sticky Count
  stickyCount = async () => {
    const { API_HOST, stickyCount } = this.props;
    const stickyCountRequest = {
      Id_UserReceiver: 0
      // "Id_Cabin": cabinId
    };
    await stickyCount(API_HOST, stickyCountRequest);
  };

  toggleCloseNotification = () => {
    if (this.props.userKnockListCabinWiseResponse.length > 0) {
      this.userKnockSeen();
    }

    if (this.props.stickyCountResponse > 0) {
      this.userStickySeen();
    }

    this.setState({
      notificationIsOpen: false
    });
  };

  onClickReadNote = () => {
    this.setState({ onReadNote: true, notificationIsOpen: false });
    if (this.props.userKnockListCabinWiseResponse.length > 0) {
      this.userKnockSeen();
    }
  };

  openToDoList = () => {
    this.setState({
      isToDoList: !this.state.isToDoList
    });
  };

  //Close Read Notes Clicked
  onCloseReadNotes = getCabinUserStickyList => {
    this.setState({
      onReadNote: false
    });
    this.stickyCount();
    // if (this.props.stickyCountResponse > 0) {
    //     this.userStickySeen();
    // }
    if (getCabinUserStickyList.length > 0) {
      this.userStickySeen();
    }
  };

  //Set User Knock Seen
  userKnockSeen = () => {
    const { API_HOST, userKnockSeen } = this.props;
    userKnockSeen(API_HOST, res => {
      if (res) {
        this.userKnockListCabinWise(); //Count Knocks
        //this.stickyCount(cabinId);
      }
    });
  };

  //Set User Sticky Seen
  userStickySeen = async () => {
    const { API_HOST, userStickySeen } = this.props;
    await userStickySeen(API_HOST, res => {
      // if (res) {
      //     this.stickyCount();
      // }
    });
  };

  doorClosefunc = id => {
    const { API_HOST, doorClose } = this.props;

    let userDetails = JSON.parse(localStorage.getItem("userdetail"));
    userDetails.Is_DoorOpen = false;
    userDetails.UserStartTime_EndTimeVM.Availibility_Status = "Busy";
    this.props.userValuesChanges(userDetails);

    doorClose(API_HOST, id, res => {
      if (res) {
        //$('.door-action').addClass("d-closed");
        //this.setState({ isCustomStatus: true });
        // let userDetails = JSON.parse(localStorage.getItem('userdetail'));
        // userDetails.Is_DoorOpen = false;
        // userDetails.UserStartTime_EndTimeVM.Availibility_Status = "Busy";
        // this.props.userValuesChanges(userDetails)
        //this.getUserListOfDepartment();
        this.setCustomStatusClass(userDetails.UserStatus);
      }
    });
  };

  doorOpenfunc = async id => {
    const { API_HOST, doorOpen } = this.props;

    let userDetails = JSON.parse(localStorage.getItem("userdetail"));
    userDetails.Is_DoorOpen = true;
    userDetails.UserStartTime_EndTimeVM.Availibility_Status = "Available";
    this.props.userValuesChanges(userDetails);
    await this.userKnockListCabinWise(); //Count Knocks
    await this.stickyCount();

    doorOpen(API_HOST, id, res => {
      if (res) {
        // this.userKnockListCabinWise(); //Count Knocks
        // this.stickyCount();
        this.setState({
          notificationIsOpen: true
        });
        // $('.door-action').removeClass("d-closed");
        // let userDetails = JSON.parse(localStorage.getItem('userdetail'));
        // userDetails.Is_DoorOpen = true;
        // userDetails.UserStartTime_EndTimeVM.Availibility_Status = "Available";
        // this.props.userValuesChanges(userDetails)
        //this.getUserListOfDepartment();
        this.setCustomStatusClass(userDetails.UserStatus);
      }
    });
  };

  doorOpenClose = () => {
    if (JSON.parse(localStorage.getItem("userdetail")).Is_DoorOpen) {
      this.doorClosefunc(
        JSON.parse(localStorage.getItem("userdetail")).Id_User
      );
    } else {
      this.doorOpenfunc(JSON.parse(localStorage.getItem("userdetail")).Id_User);
    }
  };

  getUserListOfDepartment = () => {
    const { API_HOST, getUserListOfDepartment } = this.props;
    const requestBody = {
      // "Id_User" : 0,
      Id_Department: JSON.parse(localStorage.getItem("userdetail"))
        .Id_Department
    };

    // const requestBody = {
    //   IsPagging: true,
    //   PageNo: 1,
    //   PageSize: 10,
    //   SortingParameters: [
    //     {
    //       Property: "",
    //       Direction: 0
    //     }
    //   ],
    //   SearchCriteria: {
    //     Id_User: 0,
    //     Id_Department: JSON.parse(localStorage.getItem("userdetail")).Id_Department
    //   }
    // };
    getUserListOfDepartment(API_HOST, requestBody);
  };

  setUserCustomStatus = value => {
    const { API_HOST, setUserCustomStatus } = this.props;
    let requestBody = {
      UserStatus: value
    };
    setUserCustomStatus(API_HOST, requestBody, res => {
      let userDetails = JSON.parse(localStorage.getItem("userdetail"));
      userDetails.UserStatus = value;
      this.props.userValuesChanges(userDetails);
      this.toggleCustomStatus();
      this.getUserListOfDepartment();
      this.setCustomStatusClass(value);

      // socket.emit("UserDetail", userDetails)
    });
  };

  toggleJira = () => {
    console.log("In func is Jira: ", this.state.isJira);
    this.props.toggleJira();
    this.setState({ isJira: false });
  };

  toggleMyProfile = () => {
    this.setState({
      myProfileIsOpen: !this.state.myProfileIsOpen,
      isAddToSlack: false,
      isEditWorkingHours: false,
      isGoogleCalendarMeeting: false,
      isConnectToJira: false
    });
    // this.updateprofilepic();
  };

  toggleCustomStatus = () => {
    this.setState({ isCustomStatus: false });
  };

  setCustomStatusClass = value => {
    let customStatusClass = "active-status";
    let customStatusIcon = "fas fa-circle";
    if (value === "Available") {
      customStatusClass = "status-available";
      customStatusIcon = "fas fa-circle";
    } else if (value === "Busy") {
      customStatusClass = "status-busy show";
      customStatusIcon = "fas fa-business-time";
    } else if (value === "Do Not Disturb") {
      customStatusClass = "status-disturb show";
      customStatusIcon = "fas fa-times-circle";
    } else if (value === "On Break") {
      customStatusClass = "status-break show";
      customStatusIcon = "fas fa-utensils";
    } else if (value === "In a Meeting") {
      customStatusClass = "status-meeting show";
      customStatusIcon = "fas fa-users";
    } else if (value === "Other") {
      customStatusClass = "status-other show";
      customStatusIcon = "fas fa-sign-out-alt";
    } else {
      if (JSON.parse(localStorage.getItem("userdetail")).Is_DoorOpen === true) {
        customStatusClass = "active-status";
        customStatusIcon = "fas fa-circle";
      } else {
        customStatusClass = "status-other show";
        customStatusIcon = "fas fa-sign-out-alt";
      }
    }
    this.setState({
      customStatusClass: customStatusClass,
      customStatusIcon: customStatusIcon,
      customStatus: value
    });
  };

  connectToJira = () => {
    this.setState({
      myProfileIsOpen: !this.state.myProfileIsOpen,
      isAddToSlack: false,
      isEditWorkingHours: false,
      isGoogleCalendarMeeting: false,
      isConnectToJira: true
    });
  };

  cancelReminder = reminderId => {
    const { API_HOST, cancelReminder } = this.props;
    const cancelReminderRequest = {
      Id: reminderId
    };
    cancelReminder(API_HOST, cancelReminderRequest, res => {
      if (res) {
        this.getReminderNotification();
      }
    });
  };

  removeReminder = reminderId => {
    this.cancelReminder(reminderId);
  };

  getReminderNotification = () => {
    const { API_HOST, getReminderNotification } = this.props;

    getReminderNotification(API_HOST, res => {
      if (res[0] !== null) {
        this.setState({ reminderTaskList: res });
      } else {
        this.setState({ reminderTaskList: [] });
      }
    });
  };

  showTimeCalendar = () => {
    // this.setState({isShowTimeCalendar: !this.state.isShowTimeCalendar});

    // if(this.state.isShowTimeCalendar){
    //   $('#timeCalendar').removeClass('show-time-calendar');
    // }else{
    //   $('#timeCalendar').addClass('show-time-calendar');
    // }

    $("#timeCalendar").toggleClass("show-time-calendar");
  };



  render() {
    const {
      isZoomModal,
      zoomUrl,
      count,
      reminderList,
      todoList,
      reminderTaskList
    } = this.state;

    
    let user = JSON.parse(localStorage.getItem("userdetail"));
    const user_TZ =
      user.TZ === "" || user.TZ === null ? "Asia/Kolkata" : user.TZ;
    const currentDate = moment()
      .tz(user_TZ)
      .format("ddd, MMMM D");

    return (
      <>
        {this.state.zoomModal === true && (
          <ZoomMeeting
            zoomModal={this.state.zoomModal}
            zoomModalToggle={this.zoomModalToggle}
            zoomModalJoin={this.zoomModalJoin}
            userValuesChanges={this.props.userValuesChanges}
          />
        )}

        {this.state.isZoomModal === true && (
          <ZoomJoin
            zoomUrl={zoomUrl}
            isZoomModal={isZoomModal}
            zoomModalJoin={this.zoomModalJoin}
          />
        )}

        {this.state.isToDoList ? (
          <ToDoList
            isModal={this.state.isModal}
            reminderList={reminderList}
            openToDoList={this.openToDoList}
            isToDoList={this.state.isToDoList}
          />
        ) : null}

        {!this.state.isStickyModal ? (
          <StickyNotesList
            isStickyModal={this.state.isStickyModal}
            toggleStickyNotes={this.toggleStickyNotes}
          />
        ) : null}

        {this.state.isFeedBack ? (
          <FeedBack
            isFeedBack={this.state.isFeedBack}
            toggleFeedBack={this.toggleFeedBack}
          />
        ) : (
          ""
        )}

        {this.state.isContactUs ? (
          <ContactUs
            isContactUs={this.state.isContactUs}
            toggleContactUs={this.toggleContactUs}
          />
        ) : (
          ""
        )}
        {this.state.notificationIsOpen &&
        (this.props.userKnockListCabinWiseResponse.length > 0 ||
          this.props.stickyCountResponse > 0) ? (
          <KnockStickyCount
            notificationIsOpen={this.state.notificationIsOpen}
            // cabinId={this.isCabinPresent(12).Id}
            userKnockCount={this.props.userKnockListCabinWiseResponse}
            userStickyCount={this.props.stickyCountResponse}
            toggleCloseNotification={this.toggleCloseNotification}
            onClickReadNote={this.onClickReadNote}
          />
        ) : null}
        {this.state.onReadNote ? (
          <StickyNotesList
            isStickyModal={!this.state.onReadNote}
            toggleStickyNotes={this.onCloseReadNotes}
            isCabin={true}
            stickyCount={this.stickyCount}
          />
        ) : null}

        {this.state.isCustomStatus ? (
          <CustomStatus
            isCustomStatus={this.state.isCustomStatus}
            toggleCustomStatus={this.toggleCustomStatus}
            setCustomStatus={this.setUserCustomStatus}
            isDoorOpen={this.props.isDoorOpen}
            doorOpenClose={this.doorOpenClose}
          />
        ) : null}

        {this.state.isJira ? (
          <Jira
            toggle={this.toggleJira}
            isJira={this.state.isJira}
            connectToJira={this.connectToJira}
          />
        ) : null}

        <div className="right-sidebar">
          <div className=" position-relative">
            <div class="dropleft">
              <div
                className="action-bar"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-status">
                  <div
                    className="user-avtar"
                    style={{ cursor: "pointer" }}
                    data-for="avtar"
                    data-tip={avtarTip}
                  >
                    <ReactTooltip
                      id="avtar"
                      place="left"
                      type="dark"
                      effect="solid"
                      multiline={true}
                      backgroundColor="#2c69d1"
                      textColor="white"
                      border="true"
                    />
                    <AvatarImage
                      profilePic={this.props.myAvatar}
                      className=""
                    />
                  </div>
                </div>
              </div>
              <div class="dropdown-menu">
                <ReactTooltip
                  id="moreInfo"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                <Link
                  className="dropdown-item more-info"
                  onClick={() => {
                    this.toggleMyProfile();
                  }}
                  data-for="moreInfo"
                  data-tip={moreInfoTip}
                >
                  Settings
                </Link>
                <div class="dropdown-divider"></div>
                <ReactTooltip
                  id="feedBack"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                <Link
                  class="dropdown-item"
                  href="#"
                  onClick={this.openFeedBack}
                  data-for="feedBack"
                  data-tip={feedBackTip}
                >
                  FeedBack
                </Link>
                {/* <div class="dropdown-divider"></div>
              <Link class="dropdown-item" href="#" onClick={this.openContactUs}>
                Contact Us
              </Link> */}
                <div class="dropdown-divider"></div>
                <ReactTooltip
                  id="logOut"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                <Link
                  class="dropdown-item"
                  href="#"
                  onClick={this.logOut}
                  data-for="logOut"
                  data-tip={logOutTip}
                >
                  Logout
                </Link>
              </div>
              <ReactTooltip
                id="statusChange"
                place="left"
                type="dark"
                effect="solid"
                multiline={true}
                backgroundColor="#2c69d1"
                textColor="white"
                border="true"
              />
              <div
                className="status-change"
                data-for="statusChange"
                data-tip={statusChangeTip}
              >
                <Link className={this.state.customStatusClass} href="#">
                  {" "}
                  <i class={this.state.customStatusIcon}></i>
                </Link>
                <div className="status-list">
                  <Link
                    href="#"
                    className="status-available"
                    onClick={() => {
                      this.setUserCustomStatus("Available");
                    }}
                  >
                    {" "}
                    <i class="fas fa-circle"></i> Available
                  </Link>
                  <Link
                    href="#"
                    className="status-busy"
                    onClick={() => {
                      this.setUserCustomStatus("Busy");
                    }}
                  >
                    {" "}
                    <i class="fas fa-business-time"></i> Busy
                  </Link>
                  <Link
                    href="#"
                    className="status-disturb"
                    onClick={() => {
                      this.setUserCustomStatus("Do Not Disturb");
                    }}
                  >
                    {" "}
                    <i class="fas fa-times-circle"></i> Do Not Disturb
                  </Link>
                  <Link
                    href="#"
                    className="status-break"
                    onClick={() => {
                      this.setUserCustomStatus("On Break");
                    }}
                  >
                    {" "}
                    <i class="fas fa-utensils"></i> On Break
                  </Link>
                  <Link
                    href="#"
                    className="status-meeting"
                    onClick={() => {
                      this.setUserCustomStatus("In a Meeting");
                    }}
                  >
                    {" "}
                    <i class="fas fa-users"></i> In a Meeting
                  </Link>
                  <Link
                    href="#"
                    className="status-other"
                    onClick={() => {
                      this.setState({ isCustomStatus: true });
                    }}
                  >
                    {" "}
                    <i class="fas fa-sign-out-alt"></i> Other
                  </Link>
                </div>
              </div>
            </div>
            <div className="door-status">
              <Link
                className={`door-action ${
                  !this.props.isDoorOpen ? "d-closed" : ""
                }`}
                onClick={this.doorOpenClose}
              >
                <span
                  className="door-img"
                  data-for="userDoor"
                  data-tip={doorTip}
                ></span>
                <ReactTooltip
                  id="userDoor"
                  place="top"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                {this.props.isDoorOpen ? (
                  <div style={{ fontSize: "12px" }}>Close Door</div>
                ) : (
                  <div style={{ fontSize: "12px" }}>Open Door</div>
                )}
              </Link>
            </div>
          </div>

          <ul className="list-unstyled mb-0 actions-list myscroll">
            <li>
              <Link
                data-for="todo"
                data-tip={toDo}
                style={{ cursor: "pointer" }}
                data-target="#userloginmodel"
                onClick={() => {
                  this.openToDoList();
                }}
              >
                {/* <img src={toDoListIcon} alt="icon" /> */}
                <i class="fas fa-list-alt"></i>
                <span style={{ fontSize: "12px" }}>To Do</span>
                <ReactTooltip
                  id="todo"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
              </Link>
            </li>
            <li>
              <Link
                id="jira"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // this.toggleJira();
                  this.setState({ isJira: true });
                }}
                data-tip={jiraTip}
                data-for="jira"
              >
                {/* <img
                  src={JiraIcon}
                  style={{ width: "auto", height: "30px" }}
                  alt="zoom"
                /> */}
                <i class="fab fa-jira"></i>
                <span style={{ fontSize: "12px" }}>JIRA</span>
                <ReactTooltip
                  id="jira"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
              </Link>
            </li>
            <li>
              <Link
                data-for="stickynotes"
                data-tip={stickyNotes}
                style={{ cursor: "pointer" }}
                data-target="#userloginmodel1"
                onClick={() => {
                  this.toggleStickyNotes();
                }}
              >
                {/* <img src={stickyNoteIcon} alt="sticky" /> */}
                <i class="fas fa-sticky-note"></i>
                <span style={{ fontSize: "12px" }}>Sticky Notes</span>
                <ReactTooltip
                  id="stickynotes"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
              </Link>
            </li>
          {this.state.showChatIcon &&
            <li>
              <Link
                data-for="chat"
                data-tip={chat}
                style={{ cursor: "pointer" }}
                data-target="#userloginmodel1"
                onClick={this.chatOpen}
              >
                {/* <a href="javacript:void(0)" style={{ cursor: 'pointer' }} data-target="#userloginmodel1" onClick={this.props.onClickUsersSelectedChat()}> */}
                {/* <img src={chatIcon} style={{ width: "auto" }} alt="chat" /> */}
                <i class="fas fa-comments"></i>
                <span style={{ fontSize: "12px" }}>Chat</span>
                <ReactTooltip
                  id="chat"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
              </Link>
            </li>
          }
            {/* <li>
              <Link
                data-for="zoom"
                data-tip={zoomDescription}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.zoomModalToggle();
                }}
              > */}
                {/* <img src={zoomIcon} style={{ width: "auto" }} alt="zoom" />
                <i class="fas fa-video"></i>
                <span style={{ fontSize: "12px" }}>Zoom</span>
                <ReactTooltip
                  id="zoom"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
              </Link>
            </li> */}

            {/* <li>
                            <a href="javascript:void(0)" style={{ cursor: 'pointer' }} data-target="#userloginmodel1" onClick={() => { this.toggleToBeDeveloped() }}>
                                <img src="images/video.png" style={{ width: 'auto' }} alt="" />
                                <span style={{ fontSize: '12px' }}>Video</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" style={{ cursor: 'pointer' }} data-target="#userloginmodel1" onClick={() => { this.toggleToBeDeveloped() }}>
                                <img src="images/Voice.png" style={{ width: 'auto' }} alt="" />
                                <span style={{ fontSize: '12px' }}>Voice</span>
                            </a>
                        </li>*/}

            {/* <li>
              <Link
                data-for="livesupport"
                data-tip={liveSupport}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.toggleChatSupport();
                }}
              >
                <img src={liveIcon} style={{ width: "auto" }} alt="live" />
                <span style={{ fontSize: "12px" }}>Live Support</span>
                <ReactTooltip
                  id="livesupport"
                  place="left"
                  type="dark"
                  effect="solid"
                  multiline={true}
                />
              </Link>
            </li> */}
            <li>
              {this.state.reminderTaskList.length > 0 && (
                <Link id="notification">
                  <i
                    className="fa fa-bell-o" 
                    onClick={this.setCount}
                  >
                    <NotificationBadge
                      count={this.state.reminderTaskList.length}
                      effect={Effect.ROTATE_Y}
                    />
                  </i>
                </Link>
              )}
            </li>
          </ul>

          <div
            className="dt-time-place"
            onClick={this.showTimeCalendar}
            style={{ cursor: "pointer" }}
          >
            <strong>
              {" "}
              <Moment tz={user_TZ} format="HH:mm">
                {this.state.date}
              </Moment>
            </strong>
            <span>{user_TZ}</span>
            <hr />
            <span className="shortText"> {currentDate}</span>
          </div>
        </div>
        {/* added all the props of myprofile to avoid error but need to remove unused */}
        {this.state.myProfileIsOpen ? (
          <MoreInfo
            isOpen={this.state.myProfileIsOpen}
            isAddToSlack={this.state.isAddToSlack}
            isEditWorkingHours={this.state.isEditWorkingHours}
            isConnectToJira={this.state.isConnectToJira}
            toggleModal={this.toggleMyProfile}
            myAvatar={this.state.myAvatar}
            onselected={this.props.selectedAvatar}
            updateUser={this.props.updateUser}
            userDetails={this.props.userDetails}
            changePassword={this.props.changePassword}
            changePasswordResponse={this.props.changePasswordResponse}
            userValuesChanges={this.props.userValuesChanges}
            //updateUserTimezone={this.updateUserTimezone}
            fetchSearchUser={this.props.fetchSearchUser}
            isGoogleCalendarMeeting={this.state.isGoogleCalendarMeeting}
            // clientId={this.props.slackConfigListResponse[0].Client_ID}
            changeWorkspace={this.props.changeWorkspace}
            fetchMultipleEmail={this.props.fetchMultipleEmail}
            multipleEmailResponse={this.props.multipleEmailResponse}
            redirectLogin={this.props.redirectLogin}
          />
        ) : (
          ""
        )}

        {this.state.openReminder ? (
          <div
            className="model-right-bar"
            id="userloginmodel"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Reminders
                  </h5>
                  <div
                    className="close b-left"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.toggle}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-close" />
                  </div>
                </div>
                <div className="modal-body myscroll">
                  {reminderTaskList.length > 0 ? (
                    <div className="todolist-panel">
                      {/* {reminderList && reminderList.sort((a, b) => a.Id > b.Id ? 1 : -1) */}
                      {reminderTaskList &&
                        reminderTaskList
                          .sort((a, b) => (a.Id > b.Id ? 1 : -1))
                          .map(remind => {
                            return (
                              <FormGroup>
                                <Card>
                                  <i
                                    className="fa fa-close"
                                    onClick={id =>
                                      this.cancelReminder(remind.Id)
                                    }
                                  />
                                  {/* <i className="fa fa-close" style={{ left : '220px'}} onClick={() => this.removeReminder(remind.Id)}/> */}
                                  <CardContent>
                                    <Typography variant="body2" component="h5">
                                      {remind.Description}
                                    </Typography>
                                    <Typography>
                                      {moment(remind.DueDate).format(
                                        "MMM DD, HH:mm"
                                      )}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </FormGroup>
                            );
                          })}
                    </div>
                  ) : (
                    "No Reminders.."
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const zoomDescription =
  "Here, you can join zoom<br /> conference by entering<br /> meeting id and password.";
const stickyNotes =
  "Here, you can find your sticky notes.<br />On left swipe the sticky notes are<br /> marked as read and on right swipe<br /> they are added to the ToDo list.";
const toDo =
  "Here, you can create new task and <br /> also see the existing tasks list.";
const liveSupport =
  "Here, you can direct chat with<br /> admin and get help from him.";
const chat = "Here, you can chat with your<br /> colleagues.";
const jiraTip =
  "Click here to connect your JIRA PMS and<br /> manage your task and time efficiently.";
const doorTip =
  "Manage your work by closing the door, and upon clicking <br /> back you can open your door and receive <br /> the Sticky notes which your teammates have left.";
const avtarTip =
  "Manage the userlevel settings and <br /> configurations and update your <br /> status  by clicking here.";
const moreInfoTip =
  "Click here to organize your  Calendar, daily/weekly/monthly schedule, <br />Account setting, JIRA, Google an Zoom configurations";
const feedBackTip =
  "Submit your experience with Precis and<br /> feedback of what more you would like to<br /> have to make your workspace efficient<br /> and interactive.";
const logOutTip = "Click here to logout from your Precis existing session.";
const statusChangeTip =
  "Select your work status from the list or Select others to set your custom status to be seen by others , for your usercard.";

let container = {
  height: "50px",
  width: "50px",
  display: "inline-block",
  margin: "5px",
  cursor: "pointer",
  fontSize: "30px"
};

let options = {
  autoClose: 30 * 1000,
  position: "bottom-right",
  transition: Flip
};
// Prop types of props.
Rightsidebar.propTypes = {
  API_HOST: PropTypes.string,

  userToDoList: PropTypes.func,
  userToDoListResponse: PropTypes.array
};

// Set default props.
Rightsidebar.defaultProps = {
  userToDoList: () => {},
  saveReminder: () => {},
  userToDoListResponse: []
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),

  userToDoListResponse: state.todoReducer.userToDoListResponse,
  userKnockListCabinWiseResponse:
    state.mainOfficeReducer.userKnockListCabinWiseResponse,
  stickyCountResponse: state.mainOfficeReducer.stickyCountResponse
});
export default connect(mapStateToProps, {
  userToDoList,
  saveReminder,
  getUserListOfDepartment,
  doorClose,
  doorOpen,
  userKnockListCabinWise,
  stickyCount,
  userKnockSeen,
  userStickySeen,
  setUserCustomStatus,
  cancelReminder,
  getReminderNotification,
  logout
})(Rightsidebar);
