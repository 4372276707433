import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost,socket } from '../../../utils/apiUrls';
import { getEncryptedValue, getDecryptedValue } from "../../../utils/utility";
import { CustomLoadingSpiner } from '../../../components/loader/customLoader';
import { slackOauthAccessLogin, loginWithSlack, getSlackConfigurationlistLogin } from "../../../actions/dashboardActions";
import { toast } from "react-toastify";


class SigningWithSlackToken extends React.Component {
  state = {
    loading: true,
    clientId: "",
    clientSecret: ""
  };

  async componentDidMount() {

   await this.getSlackConfigurationlistLogin();
  //await this.slackTokenOAuth();
   
  }

  slackTokenOAuth = async (code, client_Id, client_Secret) => {

    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // let code = params.get('code');
    
    const { slackOauthAccessLogin } = this.props;
    await slackOauthAccessLogin(code, client_Id, client_Secret, async(res) => {
      console.log("auth res123", res)
      if (res.ok === false) {
        this.props.history.push("/login");
        this.setState({ loading: false })
        toast.error(res.error)
      }
      if (res.ok === true){



      this.loginWithSlack(res.authed_user.access_token, res.authed_user.id, res.authed_user.refresh_token);

      }
      
    });
  }


  getSlackConfigurationlistLogin = async () => {
    const { API_HOST, getSlackConfigurationlistLogin } = this.props;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('code');
    await getSlackConfigurationlistLogin(API_HOST, async(res) => {
      if (res) {
        await this.setState({ clientId: getDecryptedValue(res[0].Client_ID) , clientSecret: getDecryptedValue(res[0].Client_Secret) });
        await this.slackTokenOAuth(code, this.state.clientId, this.state.clientSecret);
      }
    });
  }


  loginWithSlack = async (access_token, slackUserId, refresh_token) => {
    const { API_HOST, loginWithSlack} = this.props;
  
    localStorage.setItem("Token", getEncryptedValue(access_token));
    localStorage.setItem("SlackRefreshToken", getEncryptedValue(refresh_token));
    localStorage.setItem("SlackUserId", getEncryptedValue(slackUserId));
    localStorage.setItem("isFromSlackSiginIn", "true");
    localStorage.setItem("autoImport", "true");
    console.log("slack_Id",slackUserId);

   //////////// socket.emit("userSignedInWithSlack");

    const requestBody = {
        "Slack_User_Access_Token" : getEncryptedValue(access_token),
        "Slack_User_Id" : getEncryptedValue(slackUserId),
        "Slack_User_Refresh_Token" : getEncryptedValue(refresh_token)
  };

    await loginWithSlack(API_HOST, requestBody, (res) => {
      if(res !== null) {
        localStorage.removeItem("userdetail");
        localStorage.setItem("userdetail", JSON.stringify(res.UserData));
        console.log("signin with slack set local Storage",res.UserData);
        localStorage.setItem("Token", res.UserData.Slack_Access_Token);
        socket.emit("UserDetail",res.UserData);
        socket.emit("newUserDetail",res.UserData);
        sessionStorage.removeItem("userToken");
        sessionStorage.setItem("userToken",JSON.stringify(res.TokenData.Token).replace(/"/g, ""));
        this.props.history.push("/dashboard");
      }
    });

  }


  render() {

    return (
      <CustomLoadingSpiner InProgress={this.state.loading} />
    );
  }
}

// Prop types of props.
SigningWithSlackToken.propTypes = {
  API_HOST: PropTypes.string,
  slackConfigListLoginResponse: PropTypes.any,
  getSlackConfigurationlistLogin: PropTypes.func
};

// Set default props.
SigningWithSlackToken.defaultProps = {
  slackConfigListLoginResponse: [],
  getSlackConfigurationlistLogin: () => { }
};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
    slackConfigListLoginResponse: state.dashboardReducer.slackConfigListLoginResponse.slackConfigListLoginResponse
  };
};
export default connect(mapStateToProps, { getSlackConfigurationlistLogin, slackOauthAccessLogin, loginWithSlack })(SigningWithSlackToken);
