import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';

class KnockStickyCount extends React.PureComponent {

  state = {
    results: [],
    onReadNotes: false,
    knockCount: this.props.userKnockCount,
    stickyCount: this.props.userStickyCount
  };

  componentDidMount() {

    this.senderName(this.state.knockCount);
    // console.log("1",this.state.knockCount)
    // console.log("1",this.state.stickyCount);
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.userKnockCount !== this.props.userKnockCount) {
      this.setState({ knockCount: nextProps.userKnockCount })
      this.senderName(nextProps.userKnockCount);
      // console.log("2",this.state.knockCount)
      // console.log("2",this.state.stickyCount);
    }

    if (nextProps.userStickyCount !== this.props.userStickyCount) {
      this.setState({ stickyCount: nextProps.userStickyCount })
      // console.log("3",this.state.knockCount)
      // console.log("3",this.state.stickyCount);
    }
    // console.log("4",this.state.knockCount)
    // console.log("4",this.state.stickyCount);

  }
  

  onCloseNotification = () => {
    this.props.toggleCloseNotification();
  };

  senderName(data) {
    if (data && data.length > 0) {

      this.setState({
        //results: _.groupBy(this.state.knockCount, "Name_UserSender")
        results: _.groupBy( data, "Name_UserSender")
      })
    }
  };

  //Read Notes Clicked
  onClickReadNotes = () => {

    // this.props.onClickReadNote(this.props.cabinId);
     this.props.onClickReadNote();
    
  };


  render() {

    var moment = require('moment-timezone');
    var timezone = JSON.parse(localStorage.getItem('userdetail')).TZ ? JSON.parse(localStorage.getItem('userdetail')).TZ : "Asia/Kolkata";

    const {
      notificationIsOpen
    } = this.props;

    const {
      results
    } = this.state;


    return (

      <Modal isOpen={notificationIsOpen} backdrop={true} className="sticky-modal">
        <ModalBody >
 
              <div className="form-group sticky-body">
                {this.state.knockCount && this.state.knockCount.length > 0 ?
                <div>
                <h4> You have knocks on your cabin door by: </h4>
                <ul>
                  {
                    results !== null &&
                    Object.keys(results).map(sender => {
                      return (
                        <div key={sender} ><span>{sender} on :</span>
                          {results[sender] && results[sender].sort((a, b) => b.Id - a.Id).map((obj) => {
                            return (
                              <li key={obj.Id}> {obj ? moment.utc(obj.Created_Date).tz(timezone).format('dddd, MMMM D HH:mm:ss') : ""}</li>
                            )
                          })}
                        </div>
                      )
                    })
                  }
                </ul>
                </div>
                :null}

                  {this.state.stickyCount === 0 ? "" : (
                    <div>
                      <h4>There are {this.state.stickyCount} Sticky Notes for you</h4>
                      <div style={{ cursor: 'pointer', color: 'blue' }} onClick={this.onClickReadNotes}>
                          Read notes
                          </div>
                      </div>
                  )}
              </div>

              <div className="btn-group-div">
                <Button color="primary" className="btn btn-primary" onClick={this.onCloseNotification}>OK</Button>
              </div>

        </ModalBody>
      </Modal>

    );
  }
}


export default KnockStickyCount;
