import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import calIcon from "../../../assets/images/cal_img_box.jpg";
import homeIcon from "../../../assets/images/home_img_box.jpg";
import dayIcon from "../../../assets/images/day-dt.png";
import nightIcon from "../../../assets/images/night-dt.png";
import ReactTooltip from "react-tooltip";
import $ from "jquery";

class TimeCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date()
    };
  }
  updateTime = () => {
    let date = new Date();
    this.setState({ date: date });
  };
  componentDidMount() {
    setInterval(this.updateTime, 5000);
  }

  closeTimePicker = e => {
    var popup = $("#timeCalendar");
    if (
      !$(".dt-time-place").is(e.target) &&
      !popup.is(e.target) &&
      popup.has(e.target).length == 0
    ) {
    //   $("#timeCalendar").removeClass("show-time-calendar");
    }
  };

  render() {
    const timeCal = "See who is available across the time zones.";
    const button = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23
    ];
    // const val = this.props.officeResponse.find((officeResponse) => officeResponse.Id === this.props.officeId)
    // var moment = require('moment-timezone');
    // const time = val.Office_Timezone ? moment().tz(val.Office_Timezone.slice(12)) : "Asia/Kolkata";
    let user = JSON.parse(localStorage.getItem("userdetail"));
    var moment = require("moment-timezone");
    // const time = JSON.parse(localStorage.getItem('userdetail')).TZ ? moment().tz( JSON.parse(localStorage.getItem('userdetail')).TZ).format('HH:mm') : ""

    // const time =  JSON.parse(localStorage.getItem('userdetail')).TZ ? moment().tz(JSON.parse(localStorage.getItem('userdetail')).TZ).format('HH:mm'):"";
    const user_TZ =
      user.TZ === "" || user.TZ === null ? "Asia/Kolkata" : user.TZ;
    const currentDate = moment()
      .tz(user_TZ)
      .format("ddd, MMMM D");

    //let startTime = user.UserStartTime_EndTimeVM.Availibility_Time !== "" ? (user.UserStartTime_EndTimeVM.Availibility_Time.substr(0, 2)) : "00";
    let startTime = moment(
      user.UserStartTime_EndTimeVM.Availibility_Time.substr(0, 5),
      "HH:mm"
    )
      .tz(user.TZ)
      .format("HH:mm");
    const Buttons = button.map(number => {
      return (number + parseInt(startTime)) % 24;
    });
    let buttonValue = 25;
    if (this.props.timeFilter !== 0) {
      buttonValue = this.props.timeFilter === 24 ? 0 : this.props.timeFilter;
    }

    return (
      <div className="sticky show-time-calendar" id="timeCalendar" onMouseUp={e => this.closeTimePicker(e)}>
        <div className="dt-picker">
          {/* <div
            className="cal-img-date"
            onClick={() => {
              this.props.setGoogleCalendarMeeting();
            }}
          >
            <img
              className="img-fluid dt-img-home"
              style={{
                cursor: "pointer"
              }}
              src={homeIcon}
              alt="schedule"
              data-for="home"
              data-tip="Go to Home screen"
              onClick={e => {
                this.props.onTimeSelected(e, 0);
              }}
            />

            <img className="img-fluid" src={calIcon} alt="schedule" />
          </div> */}
          <div className="calenderSelection">
            <div className="calenderDateBox">
              {/* <label className="shortText">{currentDate}</label> */}

              <label>
                <b>My Day</b>
              </label>

              <div className="dayNightBox">
                <img
                  className="img-fluid dt-img dt-day-img"
                  src={dayIcon}
                  alt="day"
                />

                <ReactTooltip
                  id="home"
                  place="top"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />

                <ReactTooltip
                  id="timeCal"
                  place="top"
                  type="dark"
                  effect="solid"
                  multiline={true}
                  backgroundColor="#2c69d1"
                  textColor="white"
                  border="true"
                />
                <div
                  className="btn-group dt-dates-selection"
                  data-for="timeCal"
                  data-tip={timeCal}
                >
                  {Buttons.map(button => (
                    <button
                      onClick={e => {
                        button === 0
                          ? this.props.onTimeSelected(e, 24)
                          : this.props.onTimeSelected(e, button);
                      }}
                      className={`button ${
                        button === buttonValue ? "active" : ""
                      } b${button} `}
                      key={button}
                    >
                      {button}
                    </button>
                  ))}
                </div>

                <img
                  className="img-fluid dt-img dt-night-img"
                  src={nightIcon}
                  alt="night"
                />
              </div>
              <span
                data-for="clearFilter"
                data-tip="Clear all filters"
                className="shortText"
                onClick={e => {
                  this.props.onTimeSelected(e, 0);
                }}
              >
                Clear Filter
              </span>
            </div>
          </div>
          <div>
            <ReactTooltip
              id="clearFilter"
              place="top"
              type="dark"
              effect="solid"
              multiline={true}
              backgroundColor="#2c69d1"
              textColor="white"
              border="true"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default TimeCalendar;
