import React from 'react';

export const TenantCreateLoader = (props) => {
    const InProgress = props.InProgress;
  return (
    <>
    {
      (InProgress === true) ?
      <div className="overlay-loader">
        <div className="loader-wrap">
          <div className="loader" id="loader-2"></div>
          {/* Added Text */}
          <div class="hm-spinner"></div>
            <div className="load-text">Creating new Tenant. This may take a while</div>
            <div style={{textAlign: "center"}}>
                Please wait.......</div>
        </div>
      </div>
      :
        null
    }
  </>
  )
};