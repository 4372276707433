
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class CustomStatus extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            error: {},
            tenantDetails: {},
            src: "",
            customStatus: ["Available", "Busy" ,"Do Not Disturb", "On Break", "In a Meeting",  ],
            checked: "",
            otherStatus: "",
            chars_left: 20
        };
    }


    componentDidMount() {

    }


    componentWillReceiveProps() {

    }

    //   onCloseSticky = () => {
    //     this.props.toggleSticky();
    //   };

    handleCheckClick = (value) => {
        this.setState({ checked: value});
    }

    setCustomStatus = () => {
        // if (this.state.checked === "Other") {
            this.props.setCustomStatus(this.state.otherStatus)
        // }
        // else {
        //     this.props.setCustomStatus(this.state.checked)
        // }
    }
    render() {
        const {
            isCustomStatus,
        } = this.props;

        return (
            <Modal isOpen={isCustomStatus} backdrop={true} toggle={this.props.toggleCustomStatus} >
                  <ModalHeader toggle={this.props.toggleCustomStatus}>
                    <div className={`modal-door-status door-action ${!JSON.parse(localStorage.getItem('userdetail')).Is_DoorOpen ? "d-closed" : ""}`} onClick={this.props.doorOpenClose}>
                        <span className="door-img"></span>
                        {this.props.isDoorOpen ?
                                    <div>Close Door</div>
                                    : <div>Open Door</div>}
                    </div>
                  </ModalHeader>
                <ModalBody >
                    {/* <div className="form-group"> */}
                    {/* {this.state.customStatus && this.state.customStatus.map((list) => (

                        <div  onClick={() => this.handleCheckClick(list)}>

                            <input type="checkbox" checked={this.state.checked === list} className="filled-in" id="filled-in-box" />

                            <label style={{marginLeft:"10px"}}>
                                {list}
                            </label>

                        </div>
                    ))} */}
                     {/* <div  onClick={() =>  this.handleCheckClick("Other")}>
                    <input type="checkbox" checked={this.state.checked === "Other"} className="filled-in" id="filled-in-box" />
                    <label style={{marginLeft:"10px"}}>
                        Other
                          </label>
                          </div>  */}
                    <div>
                        <textarea
                            style={{ marginTop: '10px', marginBottom: '5px', width: '100%' }}
                            type="textarea"
                            className="form-control"
                            id="otherStatus"
                            aria-describedby="otherStatus"
                            placeholder=""
                            rows="1"
                            cols="8"
                            value={this.state.otherStatus}
                            name="otherStatus"
                            //onBlur={this.addToDoTask}
                            // disabled={this.state.checked !== "Other"}

                            onChange={(e) => this.setState({ otherStatus: e.target.value ,chars_left: 20 - e.target.value.length })}
                            maxLength="20"
                        >
                        </textarea>
                        <p>Characters Left: {this.state.chars_left} / 20</p>
                    </div>
                    {/* </div>               */}
                </ModalBody>
                <ModalFooter style={{ marginTop: '-5%' }}>
                    <Button color="primary" className="col-sm-2" onClick={this.setCustomStatus}>Save</Button>
                </ModalFooter>
            </Modal>
        );
    }
}


export default CustomStatus;