
import React, { Component } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import Avatar from 'avataaars';

class ViewProfile extends Component {

  state = {
    userDetails: null,
    src: ""
  };

  onHide = () => {
    this.props.toggle();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userDetails !== this.props.userDetails) {
      this.setState({ userDetails: nextProps.userDetails, src: nextProps.src });
    }
  }

  componentDidMount() {
    this.setState({ userDetails: this.props.userDetails, src: this.props.src })
  }

  render() {
    const {
      isOpen
    } = this.props;

    const {
      userDetails,
      src
    } = this.state;

    const profilePic = userDetails ? JSON.parse(userDetails.ProfilePic) : null;
    return (
      <Modal isOpen={isOpen} backdrop={true}  >
        <ModalBody>
          <div className="container fluid">
            <div className="row justify-content-end">
              <Button close onClick={this.onHide} />
            </div>
            <div className="row justify-content-center">
              {profilePic ? <Avatar
                style={{ width: '250px', height: '250px' }}
                avatarStyle='Transparent'
                topType={profilePic["topType"]}
                accessoriesType={profilePic["accessoriesType"]}
                hairColor={profilePic["hairColor"]}
                facialHairType={profilePic["facialHairType"]}
                clotheType={profilePic["clotheType"]}
                clotheColor={profilePic["clotheColor"]}
                eyeType={profilePic["eyeType"]}
                eyebrowType={profilePic["eyebrowType"]}
                mouthType={profilePic["mouthType"]}
                skinColor={profilePic["skinColor"]}
                graphicType={profilePic["graphicType"]}
                hatColor={profilePic["hatColor"]}
              />
                :
                <img className="imgEditProfile" src={src} alt="" width="250" height="250" />
              }
            </div>
            <hr />
            <div className="row ">
              <div className="col-sm-4 offset-md-2">Name:</div>
              <div className="col-sm-6 ">{userDetails ? userDetails.First_Name : ""}   {userDetails ? userDetails.Last_Name : ""}</div>
            </div><br />
            <div className="row">
              <div className="col-sm-4 offset-md-2">Display Name:</div>
              <div className="col-sm-6 ">{userDetails ? userDetails.Display_Name : ""}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-4 offset-md-2">Email:</div>
              <div className="col-sm-6" style={{overflowWrap:'break-word'}}>{userDetails ? userDetails.EmailId : ""}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-4 offset-md-2">Skype Id:</div>
              <div className="col-sm-6" >{userDetails ? userDetails.Skype : ""}</div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-4 offset-md-2">Timezone:</div>
              <div className="col-sm-6 ">{userDetails ? userDetails.TZ : ""}</div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ViewProfile.defaultProps = {
  isOpen: false,
  toggle: () => { },
  userDetails: null,
};

ViewProfile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  userDetails: PropTypes.object,
};

export default hot(module)(ViewProfile);
