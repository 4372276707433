import axios from "axios";
//import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

// Get Tenant Log
export const tenantLog = (tenantLogResponse) => ({
    type: T.GET_TENANT_LOG,
    tenantLogResponse,
});


export const getTenantLog = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}AuditLogTenant/GetDataLog`;
    axios
        .post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            dispatch(tenantLog(result));
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
            if (!!callback) {
                callback('fail')
            }
        }
        )
};

export const downloadExcelFile = (url, reqBody, callback) => (dispatch) => {
    //return trackPromise(
        axios.post(url, reqBody, getConfig())
            .then((response) => handleResponse(response))
            .then((result) => {
                if (!!callback) {
                    callback(result)
                }
            })
            .catch((error) => {
                handleError(error, "error")
            })
    //);
};

export const meetingLog = (meetingLogResponse) => ({
    type: T.GET_MEETING_LOG,
    meetingLogResponse,
});


export const getMeetingLog = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserMeeting/meeting_report`;
    axios
        .post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            dispatch(meetingLog(result));
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
            if (!!callback) {
                callback('fail')
            }
        })
};

export const meetingLogDetail = (meetingLogDetailResponse) => ({
    type: T.GET_MEETING_LOG_DETAIL,
    meetingLogDetailResponse,
});

export const getMeetingLogDetail = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserMeeting/getmeetingdetailforreport`;
    axios
        .post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            dispatch(meetingLogDetail(result));
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};


// Get Feedback Log
export const feedbackLog = (feedbackLogResponse) => ({
    type: T.GET_FEEDBACK_LOG,
    feedbackLogResponse,
});


export const getFeedbackLog = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/getfeedbacklist`;
    axios
        .post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            dispatch(feedbackLog(result));
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
            if (!!callback) {
                callback('fail')
            }
        }
        )
};

// Get ContactUs Log
export const contactUsLog = (contactUsLogResponse) => ({
    type: T.GET_CONTACTUS_LOG,
    contactUsLogResponse,
});


export const getContactUsLog = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/getcontactuslist`;
    axios
        .post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            dispatch(contactUsLog(result));
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
            if (!!callback) {
                callback('fail')
            }
        }
        )
};


// Get JIRA Log
export const jiraLog = (jiraLogResponse) => ({
    type: T.GET_JIRA_LOG,
    jiraLogResponse,
});


export const getJiraLog = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}Jira/getjiratasklogreport`;
    axios
        .post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            dispatch(jiraLog(result));
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "Cannot get Jira log report");
        }
        )
};