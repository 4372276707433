import axios from "axios";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

export const importSyncUserList = (url, callback) => (dispatch) => {
    const finalUrl = `${url}slack/syncslackuserslist`;
    axios.get(finalUrl, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};

export const importSyncChannelUserList = (url,callback) => (dispatch) => {
    const finalUrl = `${url}slack/syncslackchannelandchannelusers`;
    axios.get(finalUrl, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};
