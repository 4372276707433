import { combineReducers } from 'redux';
import loginReducer from '../reducers/loginReducer';
import signupReducer from '../reducers/signupReducer';
import  officeReducer from '../reducers/officeReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import slackconfigReducer from '../reducers/slackconfigReducer';
import multipleEmailReducer from '../reducers/multipleEmailReducer';
import resetPasswordReducer from '../reducers/resetPassworReducer';
import forgotPasswordReducer from '../reducers/forgotPasswordReducer';
import officeLayoutReducer from '../reducers/officeLayoutReducer';
import getDepartmentReducer from './departmentReducer';
import virtualRoomReducer from './virtualRoomReducer';
import workingHoursReducer from './workingHoursReducer';
import unreadSentStickyReducer  from './unreadSentStickyReducer';
import todoReducer from './todoReducer';
import slackUserDetailsReducer from './slackUserDetailsReducer';
import mainOfficeReducer from './mainOfficeReducer';
import meetingReducer from './googleCalendarMeetingReducer';
import LogReducer from './logReducer'

// Combine all reducers.
export default combineReducers({
  loginReducer,
  signupReducer,
  officeReducer,
  dashboardReducer,
  slackconfigReducer,
  multipleEmailReducer,
  resetPasswordReducer,
  forgotPasswordReducer,
  officeLayoutReducer,
  virtualRoomReducer,
  getDepartmentReducer,
  workingHoursReducer,
  unreadSentStickyReducer,
  todoReducer,
  mainOfficeReducer,
  meetingReducer,
  slackUserDetailsReducer,
  LogReducer
})