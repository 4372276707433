import { types as T } from '../actions/action';

const initialState = {
  getDepartmentsResponse: []
}

export default (state = initialState, action) => {
    switch (action.type) {
     case T.RECEIVE_GET_DEPARTMENTS:
      return {
        ...state,
        getDepartmentsResponse: action.getDepartmentsResponse
      }   
     default:
      return state
    }
}