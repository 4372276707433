import React from 'react';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { importSyncUserList, importSyncChannelUserList } from "../../../actions/syncProgressBarActions";
import { getApiHost } from "../../../utils/apiUrls";
import { Typography } from '@material-ui/core';
import { socket } from "../../../utils/apiUrls";
import { toast } from 'react-toastify';

class SyncProgressBar extends React.Component {

    interval = 0;

    constructor(props) {
        super(props)
        this.state = {
            percentage: 0,
            isProgressBar: this.props.isSyncProgressBar,
            runProcess: '',
            status: 'active',
            slackTeamResponse: this.props.slackTeamResponse,
            isImportSyncUserList:false,
            isImportSyncChannelUserList:false,
            isUserListAlreadyUpdate:true,
            isChannelUserListAlreadyUpdate:true
        };
    }

    componentDidMount() {
         this.importSyncUserList();
    }

    omponentDidUpdate(prevProps) {
        if (prevProps.isProgressBar !== this.props.isProgressBar) {
            this.setState({
                isProgressBar: this.props.isProgressBar
            })
        }
    }

    importSyncUserList = () => {
        const { API_HOST, importSyncUserList } = this.props;

        this.runProgress(0,20);
        importSyncUserList(API_HOST, (res) => {
            this.setState({isImportSyncUserList:res})
            clearInterval(this.interval);
                this.setState({
                    percentage: 40,
                    runProcess: 'Importing Channels...',
                    isUserListAlreadyUpdate: res
                })
            this.importSyncChannelUserList();

            // } else {
            //     this.setState({
            //         status: 'error'
            //     })
            //     setTimeout(() => {
            //         this.props.closeSyncProgressBar(false);
            //     }, 1000)
            // }
        });
    }


    importSyncChannelUserList = () => {
        const { API_HOST, importSyncChannelUserList } = this.props;
        this.runProgress(40,45);
        importSyncChannelUserList(API_HOST, (res) => {
            this.setState({isImportSyncChannelUserList:res})
                // var userData = JSON.parse(localStorage.getItem('userdetail'));//////
                clearInterval(this.interval);
                this.setState({
                    percentage: 100,
                    runProcess: 'Workspace Syncing successful',
                    status: "success",
                    isChannelUserListAlreadyUpdate: res
                })
                let msg;
                if(!this.state.isUserListAlreadyUpdate && !this.state.isChannelUserListAlreadyUpdate){  
                toast.info("Workspace is already up-to-date")
                }
                else{
                    socket.emit("updateSyncWorkspace", this.props.workspace);
                    window.location.reload();
                }
                setTimeout(() => {
                    this.props.closeSyncProgressBar(false);
                }, 1000)
            // } else {
            //     this.setState({
            //         status: 'error'
            //     })                
            //     setTimeout(() => {
            //         this.props.closeSyncProgressBar(false);
            //     }, 1000)
            // }
        });
      //  this.props.fetchMultipleEmail();
        // socket.emit("updateWorkspace", this.props.multipleEmailResponse);
    }

    runProgress = async(startVal,endVal) => {

        let start = startVal;
                let count = (count) => {
                    this.setState({percentage:count})
                  }

                    this.interval =  setInterval(function(){
                    count(start++);

                    if(start === endVal){
                        clearInterval(this.interval);
                    }

                  }, 3000);

    }

    render() {
        const { isProgressBar, percentage, runProcess, status } = this.state;
        return (
            <React.Fragment>
                <Modal isOpen={isProgressBar} backdrop={true} size={'md'} style={{ marginTop: '20%' }}>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Progress
                                    type="line"
                                    strokeWidth={4}
                                    percent={percentage}
                                    status={status}
                                />
                                <Typography style={{ fontSize: 'small' }}>{runProcess}</Typography>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

// Prop types of props.
SyncProgressBar.propTypes = {
    API_HOST: PropTypes.string,
};

// Set default props.
SyncProgressBar.defaultProps = {
    importSyncUserList: () => { },
    importSyncChannelUserList: () => { }
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    // userListDepartmentFilterResponse: state.mainOfficeReducer.userListDepartmentFilterResponse,
    // getUserDetailResponse: state.dashboardReducer.getUserDetailResponse.userDetailResponse  //////////
})

export default connect(mapStateToProps, { importSyncUserList, importSyncChannelUserList })(SyncProgressBar)
