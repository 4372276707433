import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { editChatGroupTitle } from "../../../utils/utility";

class EditTitle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            titleModal: this.props.titleModal,
            title: this.props.title,
        }
    }

    componentDidMount(){
        setTimeout(() => { this.nameInput && this.nameInput.focus() }, 1);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.title !== this.props.title) {
            this.setState({ title: this.props.title })
        }
        if (prevProps.titleModal !== this.props.titleModal) {
            this.setState({ titleModal: this.props.titleModal })
        }
    }

    editTitle = () => {
        this.props.editTitle();
    }

    titleChange = (e) => {
        this.setState({
            title: e.target.value
        })
    }

    saveTitle=()=>{
        this.props.saveTitle(this.state.title);
        this.editTitle();
    }

    onKeyDownHandler = e => {
            if (e.keyCode === 13) {
                this.saveTitle();
            }
        };

    render() {
        const { titleModal, title } = this.state;
        return (
            <Modal isOpen={titleModal} size={'md'} centered >
                <ModalHeader toggle={() => this.editTitle()}>
                    <label>Edit Title</label>
               </ModalHeader>
                <ModalBody>
                    <input onKeyPress={editChatGroupTitle} onKeyDown={this.onKeyDownHandler} type="text" ref={(input) => { this.nameInput = input;}} value={title} maxlength="80" className="form-control" id="title" name="title" onChange={(e) => this.titleChange(e)} />
                    <small>Chat Group names can only contain lowercase letters, numbers, hyphens, periods, and underscores, and must be 80 characters or less.</small>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="primary" onClick={() => this.saveTitle()}>Save</Button> */}
                </ModalFooter>
            </Modal>
        );
    }
}
export default EditTitle;