import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFeedbackLog, downloadExcelFile } from '../../../actions/logActions';
import { getApiHost } from '../../../utils/apiUrls';
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
} from 'reactstrap';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import moment from 'moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { saveAs } from 'file-saver';
import ClockLoader from 'react-spinners/ClockLoader';

class FeedbackLog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      feedbackLog: this.props.feedbackLog,
      data: [],
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      currentObject: {
        isUser: { value: 0, label: 'All' },
      },
      page: 0,
      pageSize: 10,
      pageCount: 0,
      loading: false,
      enabled: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.feedbackLogResponse !== this.props.feedbackLogResponse) {
      let totlePage = this.props.feedbackLogResponse.Data.PageData.TotalPages;
      this.setState({
        data: this.props.feedbackLogResponse.Data.SourceData,
        pageCount: totlePage,
      });
    }
  }

  onPageChange = (state, instance) => {
    let page = state.page + 1;
    let pageSize = state.pageSize;
    let sort = state.sorted;
    if (this.state.startDate !== '' && this.state.endDate !== '') {
      this.setState({ loading: true });
      this.fetchData(page, pageSize, sort);
    }
  };

  fetchData = async (page, pageSize, sortArray) => {
    const { API_HOST, getFeedbackLog } = this.props;
    let sortParam = {};

    if (sortArray.length > 0) {
      sortParam = {
        Direction: sortArray[0].desc === true ? 1 : 0,
        Property: sortArray[0].id,
      };
    } else {
      sortParam = {
        Direction: 1,
        Property: 'CreatedDate',
      };
    }

    let reqObj = {
      IsPagging: true,
      PageNo: page,
      PageSize: pageSize,
      SortingParameters: [sortParam],
      SearchCriteria: {
        UserId: this.state.currentObject.isUser.value,
        StartDate: moment(this.state.startDate).format(),
        EndDate: moment(this.state.endDate).format(),
      },
      ComplexSearchCriteria: {},
    };

    await getFeedbackLog(API_HOST, reqObj, (res) => {
      this.setState({ loading: false });
    });
  };

  toggleFeedbackLog = () => {
    this.props.toggleFeedbackLog();
  };

  // Method to convert byte array to Uint8Array which can be further used to create blob
  base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  // Method to save byte array as file using FileSaver library
  downloadFile = (byteArray, fileNameWithExtension) => {
    let result = this.base64ToArrayBuffer(byteArray);
    let blob = new Blob([result], { type: 'application/octet-stream' });
    saveAs(blob, fileNameWithExtension);
  };

  downloadExcelFile = async () => {
    const { API_HOST, downloadExcelFile } = this.props;
    const finalUrl = `${API_HOST}UserTenant/feedbackreport_exporttoexcel`;
    this.setState({ enabled: true });
    let reqObj = {
      UserId: this.state.currentObject.isUser.value,
      StartDate: moment(this.state.startDate).format(),
      EndDate: moment(this.state.endDate).format(),
    };
    if (this.state.startDate !== '' && this.state.endDate !== '') {
      await downloadExcelFile(finalUrl, reqObj, (res) => {
        this.downloadFile(res.Data.FileContent, res.Data.FileName);
        this.setState({ enabled: false });
      });
    }
  };

  userChange(value) {
    this.setState({
      currentObject: {
        ...this.state.currentObject,
        isUser: value,
      },
    });
  }

  getFeedbackLogData = () => {
    this.setState({ loading: true });
    this.fetchData(1, 10, [{ id: 'CreatedDate', desc: true }]);
  };

  render() {
    const {
      feedbackLog,
      data,
      loading,
      pageCount,
      pageSize,
      startDate,
      endDate,
      enabled,
    } = this.state;
    const { getUserAvailabilityListResponse } = this.props;
    let users = [{ value: 0, label: 'All' }];
    getUserAvailabilityListResponse &&
      getUserAvailabilityListResponse.map((obj) => {
        if (obj.EmailId !== '' && obj.Real_Name !== '') {
          users.push({ value: obj.Id_User, label: obj.Real_Name });
        }
        return users;
      });
    const columns = [
      {
        Header: <strong className="my_custom_class">User</strong>,
        accessor: 'Name',
      },
      {
        Header: <strong className="my_custom_class">Remarks</strong>,
        accessor: 'Remarks',
      },
      {
        Header: <strong className="my_custom_class">Is Recommended</strong>,
        accessor: 'IsRecommended',
        width: 200,
        Cell: (row) => {
          if (
            row.original.IsRecommended &&
            row.original.IsRecommended === true
          ) {
            return <>Yes</>;
          } else {
            return <>No</>;
          }
        },
      },
      {
        Header: <strong className="my_custom_class">Date</strong>,
        accessor: 'CreatedDate',
        width: 200,
        Cell: (row) => {
          if (row.original.CreatedDate && row.original.CreatedDate !== null) {
            return (
              <>
                {moment(row.original.CreatedDate).format('YYYY-MM-DD HH:mm a')}
              </>
            );
          } else {
            return <>Sorry! No Data</>;
          }
        },
      },
    ];

    return (
      <Modal isOpen={feedbackLog} backdrop={true} size={'xl'}>
        <ModalHeader toggle={() => this.toggleFeedbackLog()}>
          Feedback Log
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Row>
              <Col xs="4"></Col>
              <Col xs="2">
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => this.setState({ startDate: e.target.value })}
                  value={startDate}
                />
              </Col>
              <Col xs="2">
                <input
                  type="date"
                  className="form-control"
                  min={startDate}
                  onChange={(e) => this.setState({ endDate: e.target.value })}
                  value={endDate}
                />
              </Col>
              <Col xs="2" style={{ marginTop: '5px' }}>
                <Select
                  components={makeAnimated}
                  onChange={(newValue) => this.userChange(newValue)}
                  options={users}
                  value={this.state.currentObject.isUser}
                />
              </Col>

              <Col xs="1">
                <Button
                  type="button"
                  className="btn-icon btn-2"
                  style={{ marginLeft: '-15%' }}
                  color="primary"
                  onClick={this.getFeedbackLogData}
                >
                  <i
                    className="fa fa-search"
                    style={{ fontSize: '18px', color: 'white' }}
                  ></i>
                </Button>
              </Col>
              <Col xs="1">
                <Button
                  type="button"
                  className="btn-icon btn-2"
                  style={{ marginLeft: '-15%' }}
                  color="warning"
                  onClick={this.downloadExcelFile}
                >
                  {enabled === false ? (
                    <i
                      className="fa fa-download"
                      style={{ fontSize: '18px', color: 'white' }}
                    ></i>
                  ) : (
                    <ClockLoader
                      size={20}
                      color={'#64B988'}
                      loading={enabled}
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <ReactTable
              manual
              pages={pageCount}
              data={data}
              columns={columns}
              defaultPageSize={pageSize}
              pagination={true}
              className="-striped -highlight"
              pageSizeOptions={[10]}
              onFetchData={this.onPageChange}
              loading={loading}
            />
          </FormGroup>
        </ModalBody>
      </Modal>
    );
  }
}

// Prop types of props.
FeedbackLog.propTypes = {
  API_HOST: PropTypes.string,
  feedbackLogResponse: PropTypes.array,
};

// Set default props.
FeedbackLog.defaultProps = {
  getFeedbackLog: () => {},
  downloadExcelFile: () => {},
  feedbackLogResponse: [],
  getUserAvailabilityListResponse: [],
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
  feedbackLogResponse: state.LogReducer.feedbackLogResponse,
  getUserAvailabilityListResponse:
    state.mainOfficeReducer.userListDepartmentFilterResponse,
});
export default connect(mapStateToProps, { downloadExcelFile, getFeedbackLog })(
  FeedbackLog
);
