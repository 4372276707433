
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getDepartments, getUsersList, updateDepartmentList } from "../../../actions/departmentActions";
import { getApiHost, socket } from "../../../utils/apiUrls";
import { makeIdArray } from "../../../utils/utility";
import { FormGroup, Row, Col, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from "react-toastify";
import classNames from "classnames";
import ClockLoader from "react-spinners/ClockLoader";
import { Scrollbars } from 'react-custom-scrollbars';

const CustomTbodyComponent = props => (
    <div {...props} className={classNames("rt-tbody", props.className || [])}>
        <Scrollbars autoHide>{props.children}</Scrollbars>
    </div>
);

class AssignDepartment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            assignDepartment: this.props.assignDepartment,
            data: [], //dept data
            dept_Id: 0,
            currentObject: {
                isDepartment: { value: 0, label: "Select Department" }
            },
            checked: [],
            selectAll: false,
            selectedUsers: [],
            userList: [],
            loading: false,
            disabled: false,
            pageSize: 10,
            addnewMemberDeptId: this.props.addnewMemberDeptId
        };
    }
    componentDidMount() {
        this.getDepartments();
        if(this.state.addnewMemberDeptId > 0){
            this.getUsersList(this.state.addnewMemberDeptId);
            // this.setState({
            //     currentObject: {
            //         isDepartment: { value: this.state.addnewMemberDeptId },
            //     }
            //  })
        }else{
            this.getUsersList(0);
        }
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getDepartmentsResponse !== this.props.getDepartmentsResponse) {
            this.setState({
                data: this.props.getDepartmentsResponse
            })
            if(this.state.addnewMemberDeptId > 0){
                let obj = this.props.getDepartmentsResponse.find(o => o.Id === this.state.addnewMemberDeptId);
            this.setState({
                currentObject: {
                    isDepartment: { value: this.state.addnewMemberDeptId, label: obj.Name}
                }
            })
           this.getUsersList(this.state.addnewMemberDeptId);
            }
        }
    }

    //Close Department List Modal
    onCloseDept = () => {
        this.props.toggleCloseAssignDepartment();
    };

    //Get User List
    getUsersList = (deptId) => {
        const { API_HOST, getUsersList } = this.props;
        this.setState({ loading: true })
        getUsersList(API_HOST, deptId, (res) => {
            if (res) {
                this.setState({
                    userList: res,
                    loading: false,
                    pageSize: parseInt(res.length)
                })
            }
        });
    }

    //Get Departments
    getDepartments = async () => {
        const { API_HOST, getDepartments } = this.props;
        await getDepartments(API_HOST);
    };


    // handleSingleCheckboxChange = (e, index, user) => {
    //     if (e.target.checked) {
    //         let temp = [user];
    //         var checkUser = this.state.selectedUsers.filter(user => user.Id_User === index)
    //         if (checkUser.length === 0) {
    //             this.setState({
    //                 selectedUsers: [...this.state.selectedUsers, ...temp],
    //             })
    //         }
    //     } else {
    //         var selectUserRemove = this.state.selectedUsers.filter(user => user.Id_User !== index)
    //         this.setState({ selectedUsers: selectUserRemove })
    //     }
    //     var checkedCopy = [...this.state.checked];
    //     checkedCopy[index] = !this.state.checked[index];
    //     if (checkedCopy[index] === false) {
    //         this.setState({ selectAll: false });
    //     }

    //     this.setState({
    //         checked: checkedCopy
    //     });
    // };

    //User Department Update

    assignDepartment = async () => {
        this.setState({
            disabled: true
        })
        const { API_HOST, updateDepartmentList } = this.props;
        let reqBody = {
            "UserIds": makeIdArray(this.state.selectedUsers),
            "DepartmentId": this.state.currentObject.isDepartment.value
        }

        if (this.state.currentObject.isDepartment.value !== 0) {
            await updateDepartmentList(API_HOST, reqBody, (res) => {
                if (res === true) {
                    toast.success("Department assigned successfully!.",{ autoClose: 1500 });
                    this.getUsersList(0);
                    let data = { updatedptData: this.state.selectedUsers, dptId: this.state.currentObject.isDepartment.value }
                    socket.emit("updateUserDepartment", data);
                    this.setState({
                        checked: [],
                        selectedUsers: [],
                        currentObject: {
                            isDepartment: { value: 0, label: "Select Department" }
                        },
                        selectAll: false,
                        disabled: false
                    });
                }
            });
        } else {
            toast.info("Please, select a department.");
        }
    }

     async userChange(value) {
       await this.setState({
            currentObject: {
                ...this.state.currentObject,
                isDepartment: value
            }
        })
       await this.getUsersList(this.state.currentObject.isDepartment.value);
    }

    handleChange = () => {
        var selectAll = !this.state.selectAll;
        this.setState({ selectAll: selectAll });
        var checkedCopy = [];
        var selectdUser = [];
        this.state.userList.forEach(function (user, index) {
            checkedCopy.push(selectAll);
            selectdUser.push(user)
        });
        //alert(JSON.stringify(selectdUser))
        this.setState({
            checked: checkedCopy,
        });
        if (selectAll === true && selectdUser.length > 0) {
            this.setState({ selectedUsers: selectdUser });
            selectdUser = [];
        } else {
            this.setState({ selectedUsers: [] });
            selectdUser = [];
        }
    };


    handleSingleCheckboxChange = (e, index, user, id) => {
        if (e.target.checked) {
            let temp = [user];
            var checkUser = this.state.selectedUsers.filter(user => user.Id_User === id)
            if (checkUser.length === 0) {
                this.setState({
                    selectedUsers: [...this.state.selectedUsers, ...temp],
                })
            }
        } else {
            var selectUserRemove = this.state.selectedUsers.filter(user => user.Id_User !== id)
            this.setState({ selectedUsers: selectUserRemove })
        }

        var checkedCopy = this.state.checked;
        checkedCopy[index] = !this.state.checked[index];
        if (checkedCopy[index] === false) {
            this.setState({ selectAll: false });
        }

        this.setState({
            checked: checkedCopy
        });
    };


    render() {

        const { assignDepartment, userList, loading, selectedUsers, selectAll, pageSize, disabled } = this.state;
        const { getDepartmentsResponse } = this.props;

        let departments = [];
        getDepartmentsResponse && getDepartmentsResponse.map(obj => {
            if (obj) {
                departments.push({ value: obj.Id, label: obj.Name })
            }
            return departments;
        })
        const columns = [
            {
                Header: (<input
                    type="checkbox"
                    className="checkbox-unreadlist"
                    style={{ width: '16px', height: '16px' }}
                    onChange={this.handleChange}
                    checked={selectAll}
                />),
                accessor: 'Id_User',
                minWidth: 50,
                sortable: false,
                Cell: row => {
                    if (row.original.Id_User) {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox-unreadlist"
                                // defaultChecked={this.state.selectedUsers.find((user) => user.Id_User === row.original.Id_User) ? true : false}
                                // checked={this.state.selectedUsers.find((user) => user.Id_User === row.original.Id_User) ? true : false}
                                //onChange={(e) => this.handleSingleCheckboxChange(e, row.original.Id_User, row.original)}
                                defaultChecked={this.state.checked[row.index]}
                                checked={this.state.checked[row.index]}
                                onChange={(e) => this.handleSingleCheckboxChange(e, row.index, row.original, row.original.Id_User)}
                                style={{ width: '16px', height: '16px' }}
                            />
                        )
                    }
                }
            },
            {
                Header: <strong className="my_custom_class">User Name</strong>,
                accessor: 'Real_Name',
                minWidth: 180,
                //filterable: true,
                //sortable: true,
            },
            {
                Header: <strong className="my_custom_class">Department Name</strong>,
                accessor: 'Department_Name',
                minWidth: 130,
                //filterable: true,
                //sortable: true,
            },
            {
                Header: <strong className="my_custom_class">Email</strong>,
                accessor: 'EmailId',
                width: 300, 
                //filterable: true,
                //sortable: true,
            }
        ];

        return (
            <React.Fragment>
                {/* <Modal isOpen={assignDepartment} backdrop={true} size={'lg'} >
                    <ModalHeader toggle={() => this.onCloseDept()} className="dept-header">
                        Assign Department
                    </ModalHeader>
                    <ModalBody className="dept-class"> */}
                        <FormGroup>
                            <Row className="align-items-center"> 
                                <Col xs="6" >
                                    <Select
                                        components={makeAnimated}
                                        onChange={newValue => this.userChange(newValue)}
                                        options={departments}
                                        value={this.state.currentObject.isDepartment}
                                        style={{minWidth:'100%'}} 
                                    />
                                </Col>
                                <Col xs="6">
                                    <Button color="primary" style={{width:'100px', float:'right'}} type="submit" disabled={selectedUsers.length > 0 ? false : true} onClick={disabled === false && this.assignDepartment}><span>Assign</span>
                                        <span style={{ marginLeft: '5px' }}><ClockLoader
                                            size={16}
                                            color={"#fff"}
                                            loading={disabled}
                                        /></span>
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <ReactTable
                                data={userList}
                                columns={columns}
                                defaultPageSize={10}
                                style={{
                                    height: "calc(100vh - 205px)" // This will force the table body to overflow and scroll, since there is not enough room
                                }}
                                pageSize={pageSize}
                                showPagination={false}
                                className="-striped -highlight"
                                loading={loading}
                                TbodyComponent={CustomTbodyComponent}
                            />
                        </FormGroup>
                    {/* </ModalBody>
                </Modal> */}
            </React.Fragment >

        );
    }
}


// Prop types of props.
AssignDepartment.propTypes = {
    API_HOST: PropTypes.string,
    getDepartments: PropTypes.func,
    getDepartmentsResponse: PropTypes.array,
    //getUserAvailabilityListResponse: propTypes.array
};

// Set default props.
AssignDepartment.defaultProps = {
    updateDepartmentList: () => { },
    getUsersList: () => { },
    getDepartments: () => { },
    getDepartmentsResponse: [],
    // getUserAvailabilityListResponse: []
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    getDepartmentsResponse: state.getDepartmentReducer.getDepartmentsResponse,
    //getUserAvailabilityListResponse: state.mainOfficeReducer.userListDepartmentFilterResponse
})

export default connect(mapStateToProps, { getDepartments, getUsersList, updateDepartmentList })(AssignDepartment)