
import React, {  Component } from 'react';
import { Button, Modal,  ModalBody} from 'reactstrap';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';

class EditTenant extends Component {
  
  state = {
    fields: {},
    error: {},
    tenantDetails: {},
    src:"",
   
  };

  onHide = () => {
    this.props.toggle();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.tenantDetails !== this.props.tenantDetails) {
      this.setState({ tenantDetails: nextProps.tenantDetails  });
    }
  }

  componentDidMount() {
     this.setState({ tenantDetails: this.props.tenantDetails })
  }

  updateTenant = (e) => {
    e.preventDefault();
    this.props.updateTenant();
  } 


  render() {
    const {
      isOpen,
      editDisabled
    } = this.props;

    const {
      tenantDetails,
    } = this.state;

    return (
      <Modal isOpen={isOpen} backdrop={true}  >
        <ModalBody>
          <div className="container fluid">
          <div className="row justify-content-end">            
        <Button close onClick={this.onHide}/>
        </div>
            {/* <form onSubmit={this.props.updateTenant} > */}
            <form>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      aria-describedby="name"
                      placeholder=""
                      value={tenantDetails ? tenantDetails.Name : ""}
                      name="Name"
                      onChange={(e) => this.props.handleEditTenantChange(e)}
                      disabled={editDisabled}
                    />
                  </div>
                  <div className="form-group">
                    <label >Address</label>
                    <input
                      type="textarea"
                      className="form-control"
                      id="address"
                      aria-describedby="address"
                      placeholder=""
                      value={tenantDetails ? tenantDetails.Address : ""}
                      name="Address"
                      onChange={(e) => this.props.handleEditTenantChange(e)}
                      disabled={editDisabled}
                     // required
                    />
                  </div>
                  <div className="form-group">
                    <label >Zipcode</label>
                    <input
                      type="text"
                      className="form-control"
                      id="zipcode"
                      aria-describedby="zipcode"
                      placeholder=""
                      value={tenantDetails ? tenantDetails.Zipcode : ""}
                      name="Zipcode"
                      onChange={(e) => this.props.handleEditTenantChange(e)}
                      disabled={editDisabled}
                      //required
                    />
                  </div>
                  <div className="form-group">
                    <label >Contact Person Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="contact_person_name"
                      aria-describedby="contact_person_name"
                      placeholder=""
                      value={tenantDetails ? tenantDetails.Contact_Person_Name : ""}
                      name="Contact_Person_Name"
                      onChange={(e) => this.props.handleEditTenantChange(e)}
                      disabled={editDisabled}
                    />
                  </div>
                  <div className="form-group">
                    <label >Contact Person Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="contact_person_number"
                      aria-describedby="contact_person_number"
                      placeholder=""
                      value={tenantDetails ? tenantDetails.Contact_Person_Number : ""}
                      name="Contact_Person_Number"
                      onChange={(e) => this.props.handleEditTenantChange(e)}
                      disabled={editDisabled}
                    />
                  </div>
                 </div>

              </div>
              <div className="row justify-content-between">
                {/* <Button color="primary" className="col-sm-4" onClick={this.onHide} disabled={editDisabled}  type="submit">save</Button> */}
                <Button color="primary" className="col-sm-4" disabled={editDisabled} onClick={this.updateTenant} type="submit">Save</Button>
                <Button color="secondary" className="col-sm-4" onClick={this.onHide}>Cancel</Button>
              </div>

            </form>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

EditTenant.defaultProps = {
  isOpen: false,
  toggle: () => {},
  tenantDetails: {},
  editDisabled: true,
  handleEditTenantChange:() => {},
  updateTenant:() => {}
};

EditTenant.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  tenantDetails: PropTypes.object,
  editDisabled: PropTypes.bool.isRequired,
  handleEditTenantChange:PropTypes.func,
  updateTenant:PropTypes.func
  //handleEditTenantChangeTimezone:PropTypes.func
};

export default hot(module)(EditTenant);
