import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from '../../../utils/apiUrls';
import { FormGroup, Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import ReactTable from "react-table-6";
import moment from 'moment';
import ClockLoader from "react-spinners/ClockLoader";
import DatePicker from "react-datepicker";
import 'react-table-6/react-table.css';
import { saveJiraTaskLogDetails } from "../../../actions/jiraActions";
import { toast } from "react-toastify";
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import makeAnimated from 'react-select/animated';
import Jira from '../../jira/components/Jira';
import JiraIssue from "../../jira/components/JiraIssue";


class ToDoDetails extends React.Component {
  state = {

    toDoTaskDetails: this.props.toDoTaskDetails,
    isTaskDetails: this.props.isTaskDetails,
    jiraTaskLogDetails: this.props.jiraTaskLogDetails,
    taskDate: new Date(),
    taskComment: "",
    workedHours: 0,
    workedMinutes: 0,
    isSubmitDisabled: false,
    isWorkingHour: false

  };

  async componentDidMount() {
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.toDoTaskDetails !== this.props.toDoTaskDetails) {
      await this.setState({toDoTaskDetails: nextProps.toDoTaskDetails});
    }

  }

  openWorkingHourPopUp = async() => {
    await this.setState({ isWorkingHour: true});
  }

  closeWorkingHourPopUp = () =>{
    this.setState({isWorkingHour: false, workedHours: 0, workedMinutes: 0});
  }

  setDate = async (date) => {
    await this.setState({ taskDate: date });
  };
  
  setWorkedHours = (hours) => {
    this.setState({ workedHours: hours });
  };

  setWorkedMinutes = (minutes) => {
    this.setState({ workedMinutes: minutes });
  };

  saveJiraTaskLogDetails = () => {
      
    const { API_HOST, saveJiraTaskLogDetails } = this.props;

      this.setState({ isSubmitDisabled : true })
      var userId = JSON.parse(localStorage.getItem('userdetail')).Id_User; 
        let requestBody = {
          "Id" : 0,
          "Id_Jira_Task" : this.state.toDoTaskDetails.Id_Jira_Task,
          "Id_User" : userId,
          "LogDateTime" : this.state.taskDate,
          "WorkedHours" : parseInt(this.state.workedHours * 60) + parseInt(this.state.workedMinutes),
          "Jira_Task_Key" : this.state.toDoTaskDetails.Jira_Task_Key,
          "Jira_Task_Summary" : this.state.toDoTaskDetails.Description,
          "Description" : this.state.taskComment,
          "Jira_Cloud_Id" : this.state.toDoTaskDetails.Jira_Cloud_Id
        }

        if((this.state.workedHours === 0 && this.state.workedMinutes === 0) || (this.state.workedHours <= 0 && this.state.workedMinutes <= 0)) {
          toast.info("Please enter correct value for Hours");
          this.setState({ isSubmitDisabled : false });
        }else{
          saveJiraTaskLogDetails(API_HOST, requestBody, (res) => {
            if (res) {
              toast.success("Task Working Details saved successfully");
              this.props.getJiraTaskLogDetails(this.state.toDoTaskDetails.Id_Jira_Task);
              this.setState({ isSubmitDisabled : false, isWorkingHour: false, workedHours: 0, workedMinutes: 0 });
            }
        });
        }
    
}


  render() {
    const { isTaskDetails } = this.state;
    const { jiraTaskLogDetails}= this.props

    const hours=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
    const minutes= [0,5,10,15,20,25,30,35,40,45,50,55];
    var totalWorkedHours = jiraTaskLogDetails && jiraTaskLogDetails.length > 0 ? jiraTaskLogDetails[jiraTaskLogDetails.length - 1].WorkedHours : 0;
    var h = Math.floor(totalWorkedHours / 60);
    var m = totalWorkedHours % 60;
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
    var totalWorkedHoursDisplay = h + " : " + m;

    const timezone = JSON.parse(localStorage.getItem('userdetail')).TZ ? JSON.parse(localStorage.getItem('userdetail')).TZ : "Asia/Kolkata";
    const columns = [
      
      {
           Header: <strong style={{fontSize: '12.5px'}} className="my_custom_class">Name</strong>,
          accessor: 'Id',
         // minWidth: 0.3,
          Cell: (row) => {
            return <div onClick="">{row.original.User_RealName}</div>
          }
      },

      {
          Header: <strong style={{fontSize: '12.5px'}} className="my_custom_class">Comments</strong>,
        accessor: 'Description',
        //minWidth: 0.5,
        Cell: (row) => {

            return  (
            <div onClick=""> {row.original.Description}</div>
              )  
          }
      },

      {
        Header: <strong style={{fontSize: '12.5px'}} style={{fontSize: '12.5px'}} className="my_custom_class">Date</strong>,
       accessor: 'LogDateTime',
       Footer: <div>Total Hours</div>,
       //minWidth: 0.2,
       Cell: (row) => {

        if(row.original.LogDateTime === "0001-01-01T00:00:00"){
          // return <div> Total Hours</div>
          return <></>
        }else{
          return  <div className="d-block text-right" onClick="">{moment(row.original.LogDateTime).tz(timezone).format('MM/DD/YYYY')}</div>
          }
        }
      },

      {
        Header: <strong style={{fontSize: '12.5px'}} className="my_custom_class">Hrs:Mins</strong>,
        accessor: 'WorkedHours',
        Footer: <div>{totalWorkedHoursDisplay}</div>,
        //minWidth: 0.2,
        width: 75,
        Cell: (row) => {
          var h = Math.floor(row.original.WorkedHours / 60);
          var m = row.original.WorkedHours % 60;
          h = h < 10 ? '0' + h : h;
          m = m < 10 ? '0' + m : m;
          
          return(
          <div className="d-block text-right" onClick="">{h}:{m}</div>
          )
        }  
      },
    ];

    return (
      <>
      {this.state.toDoTaskDetails && this.state.toDoTaskDetails.Is_Jira_Task === true ? (
        // <JiraIssue isJiraIssue={this.state.isJiraIssue} toggleJiraIssue={this.toggleJiraIssue} jiraIssue={this.state.jiraIssue} cloudId={this.state.cloudId} accessToken={this.state.accessToken} getJiraYourTaskList={this.props.getJiraYourTaskList} getAccessTokenAgain={this.props.getAccessTokenAgain} refreshToken={this.state.refreshToken} />
     
        // <div>{this.state.toDoTaskDetails.description}</div>

          <div className="jira-task-desc">
            
            <div className="jira-list-id">
              <h4 className="mb-3">JIRA Task</h4>
              <div className="d-flex">
                {"Task Id: " + this.state.toDoTaskDetails.Id}
                <i className="fa fa-close ml-auto" onClick={() => this.props.toggleTaskDetails()}></i>
              </div>
            </div>
              <div>{this.state.toDoTaskDetails.Description}</div><br/>

              <ReactTooltip id="clock" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
              <div onClick={() => this.openWorkingHourPopUp()} style={{cursor:'pointer'}} data-for="clock" data-tip="Set Task worked hours">
              <i class="far fa-clock"> Worked Hours</i>
              </div><br/>

              {this.props.jiraTaskLogDetails.length > 0 ? 
              <ReactTable
                data={this.props.jiraTaskLogDetails}
                columns={columns}
                defaultPageSize={5}
                pageSize="5"
                 showPagination={false}
                 showPageSizeOptions={false}
                 className="-striped -highlight"
                //loading={this.state.loading};
                //style={{'height': '250px', 'width': '500px'}}
            />
            : <div>No Worked Hours details available for this task.</div>}

            {this.state.isWorkingHour ? 
                      <>
                          <Modal isOpen={this.state.isWorkingHour} backdrop={true} >
                            <ModalHeader toggle={this.closeWorkingHourPopUp}>{this.state.toDoTaskDetails.Jira_Task_Key} :</ModalHeader>

                                <ModalBody className="d-flex">
                                  <div style={{width:'100%'}}>

                                  <span><b>Task:</b> {this.state.toDoTaskDetails.Description}</span>

                                  <div className="row mt-3 mb-3" >
                                    <div className="col-6">
                                    <h6>Date: </h6>
                                    <DatePicker
                                    className="form-control"
                                    selected={this.state.taskDate}
                                    onChange={date => this.setDate(date)}
                                    id="enddate"
                                  />

                                    </div>
                                    <div className="col-6">
                                    <h6>Worked Hours</h6>
                                  <div className="text-center d-flex align-items-center timings_todo">
                                  <select style={{width:'100%'}} className="form-control" value={this.state.workedHours} onChange={newValue => this.setWorkedHours(newValue.target.value)}>
                                    {hours.map((h) => {
                                                    return (
                                                    <option value={h}>{h}</option>
                                                    )
                                                })}
                                  </select>
                                                <span style={{minWidth:'20px'}}>:</span>
                                  <select style={{width:'100%'}} className="form-control" value={this.state.workedMinutes} onChange={newValue => this.setWorkedMinutes(newValue.target.value)}>
                                  {minutes.map((m) => {
                                                  return (
                                                  <option value={m}>{m}</option>
                                                  )
                                              })}
                                  </select>
                                  </div>
                                    </div>

                                  </div>

                                

                                  <h6>Description</h6>
                                  <div><textarea className="form-control" rows="3" placeholder="Description..." onChange={e =>this.setState({ taskComment: e.target.value })}></textarea></div>

                                  <br/>

                                  <Button type="submit" color="primary" disabled={this.state.isSubmitDisabled} onClick={this.saveJiraTaskLogDetails}>
                                    <span>save</span>
                                    {this.state.isSubmitDisabled === true && (
                                      <span style={{ marginLeft: "5px" }}>
                                        <ClockLoader
                                          size={16}
                                          color={"#fff"}
                                          loading={this.state.isSubmitDisabled}
                                        />
                                      </span>
                                    )}
                                  </Button>
 
                                    
                                  </div>
                                </ModalBody>
                          </Modal> 
                      </>
                      : ""}

          </div>
          
      ): (

        <div className="jira-task-desc">
            <div className="jira-list-id">
              {"Task Id: " + this.state.toDoTaskDetails.Id}
              <i className="fa fa-close" onClick={() => this.props.toggleTaskDetails()}></i>
            </div>
              <div>{this.state.toDoTaskDetails.Description}</div>
          </div>
        
      )}
      </>
    );
  }
}

// Prop types of props.
ToDoDetails.propTypes = {
  API_HOST: PropTypes.string,

};

// Set default props.
ToDoDetails.defaultProps = {

};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),

  };
};
export default connect(mapStateToProps, {saveJiraTaskLogDetails})(ToDoDetails);
