import axios from "axios";
import { types as T } from "./action";
import {
    handleError,
    handleResponse,
    getConfig
} from "../utils/utility";

// Get Departments
export const receiveGetDepartments = (getDepartmentsResponse) => ({
    type: T.RECEIVE_GET_DEPARTMENTS,
    getDepartmentsResponse,
});

/**
 * Get Departments
 *
 * @param {url} URL is API host domain.
 * @return {array} return department detail
 */
export const getDepartments = (url) => (dispatch) => {
    const finalUrl = `${url}department/getdepartments`;
    axios
        .get(finalUrl, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            dispatch(receiveGetDepartments(result));
        })
        .catch((error) =>
            handleError(error, "Cannot get department list")
        )
};


/**
 * Add Department
 *
 * @param {url} URL is API host domain.
*/
export const addDepartment = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}department/SaveDepartment`;

    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to add department')
        )
}

/**
 * Get Dept by id
 *
 * @param {url} URL is API host domain.
*/

export const getDeptById = (url, id, callback) => (dispatch) => {
    const finalUrl = `${url}department/GetDepartmentById/${id}`;

    axios.post(finalUrl, {}, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to get department')
        )

}


/**
 * Delete department by id
 *
 * @param {url} URL is API host domain.
*/

export const deleteDepartment = (url, id, callback) => (dispatch) => {

    const finalUrl = `${url}department/deletedepartment/${id}`;

    axios.post(finalUrl,{}, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to delete department')

        )
}

export const getUsersList = (url, deptId, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/getuserlisttosetdepartment/${deptId}`;
    axios.post(finalUrl, {}, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (result) {
                callback(result)
            }
        })
        .catch((error) =>
            handleError(error, "Cannot get user list")
        )
};

export const updateDepartmentList = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/updateuserdepartment`;
    axios.post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (result) {
                callback(result)
            }
        })
        .catch((error) =>
            handleError(error, "Can not update assign department list")
        )
};

// Get Department Filter Wise
export const receiveDepartmentFilterWise = (departmentListFilterWiseResponse) => ({
    type: T.RECEIVE_DEPARTMENT_FILTERWISE,
    departmentListFilterWiseResponse,
});

/**
 * Get Department Filter Wise
 *
 * @param {url} URL is API host domain.
 * @param {requestBody} is user Id,department Id.
 * @return {array} return cabin detail
 */
export const getUpdatedDepartments = (url, requestBody) => (dispatch) => {
    const finalUrl = `${url}department/getdepartmentlistwithfilter`;
    //return trackPromise(
        axios
            .post(finalUrl, requestBody, getConfig())
            .then((response) => handleResponse(response))
            .then((result) => {
                dispatch(receiveDepartmentFilterWise(result));
            })
            .catch((error) =>
                handleError(error, "error")
            )
    //);
};

// Get user Filter Wise
export const receiveUserListDepartmentFilter = (userListDepartmentFilterResponse) => ({
    type: T.RECEIVE_USER_LIST_DEPARTMENT_FILTER,
    userListDepartmentFilterResponse,
});

/**
 * Get user Filter Wise
 *
 * @param {url} URL is API host domain.
 * @param {requestBody} is user Id,department Id.
 * @return {array} return cabin detail
 */
export const getUserListOfDepartment = (url, requestBody) => (dispatch) => {
    const finalUrl = `${url}usertenant/getuserlistdepartmentwisewithfilter`;
    //return trackPromise(
        axios
            .post(finalUrl, requestBody, getConfig())
            .then((response) => handleResponse(response))
            .then((result) => {
                dispatch(receiveUserListDepartmentFilter(result));
            })
            .catch((error) =>
                handleError(error, "error")
            )
    //);
};

export const getUserListOfDepartmentWithCallback = (url, requestBody,callback) => (dispatch) => {
    const finalUrl = `${url}usertenant/getuserlistdepartmentwisewithfilter`;
        axios
            .post(finalUrl, requestBody, getConfig())
            .then((response) => handleResponse(response))
            .then((result) => {
                dispatch(receiveUserListDepartmentFilter(result));
                if(result){
                    callback(result)
                }
            })
            .catch((error) =>
                handleError(error, "error")
            )
};