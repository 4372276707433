import { types as T } from '../actions/action';

const initialState = {
  unreadSentStickyListResponse: [],
  sendUnReadSentStickyListAsMailResponse: []
}

export default (state = initialState, action) => {
    switch (action.type) {
     case T.RECEIVE_UNREAD_SENT_STICKY_LIST:
      return {
        ...state,
        unreadSentStickyListResponse: action.unreadSentStickyListResponse
      } 

      case T.RECEIVE_SEND_UNREAD_SENT_STICKY_LIST_AS_MAIL:
      return {
        ...state,
        sendUnReadSentStickyListAsMailResponse: action.sendUnReadSentStickyListAsMailResponse
      }
      
      
     default:
      return state
    }
}