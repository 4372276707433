import axios from "axios";
import { trackPromise } from "react-promise-tracker";
//import { toast } from "react-toastify";
import { types as T } from "./action";
import {
  handleError,
  handleResponse,
  checkClientErrorStatus
} from "../utils/utility";

// Multiple Email

export const receiveMultipleEmail = (multipleEmailResponse) => ({
  type: T.RECEIVE_MULTIPLE_EMAIL,
  multipleEmailResponse,
});


/**
 * Email registered for more than one workspace.
 *
 * @param {url} URL is API host domain.
 * @param {email} is email of the user
 * 
 */
 
  export const fetchMultipleEmail = (url, email) => (dispatch) => {
  const finalUrl = `${url}tenantmaster/gettenantdomainlistuserwise/${email}`;

   return trackPromise(axios.post(finalUrl)
		.then(response => handleResponse(response))
		.then(result => {
			if (checkClientErrorStatus(result)) {
				handleError(result,  'Registered Multiple Emails')
			}
			else {
					dispatch(receiveMultipleEmail(result));
				}
      })
      .catch(error =>
        handleError(error,  'Registered Multiple Emails')
      ));
  }

 // Multiple Workspace

export const receiveMultiWorkspaceLogin = (multipleWorkspaceResponse) => ({
  type: T.RECEIVE_MULTI_WORKSPACE_LOGIN,
  multipleWorkspaceResponse,
});


/**
 * Multiple Workspace.
 *
 * @param {url} URL is API host domain.
 * @param {multipleWorkspaceRequest} is request object to multiple Emails.
 * 
 */
export const fetchMultipleWorkspace = (url, multipleWorkspaceRequest) => (dispatch) => {
  const finalUrl = `${url}masterauth/loginwithmultiworkspace`;
  return trackPromise(axios.post(finalUrl, multipleWorkspaceRequest)
		.then(response => handleResponse(response))
		.then(result => {
			if (checkClientErrorStatus(result)) {
				handleError(result, 'Failed to Redirect to Dashboard')
			}
			else {
          dispatch(receiveMultiWorkspaceLogin(result));
          if (result.TokenData && result.TokenData.Token) {
            localStorage.setItem("userdetail", JSON.stringify(result.UserData));
            sessionStorage.setItem(
              "userToken",
              // JSON.stringify(result.TokenData.Token).replace(/\"/g, "")
              JSON.stringify(result.TokenData.Token).replace(/"/g, "")
            );
          }
				}
      })
      .catch(error =>{
        handleError(error, 'Failed to Redirect to Dashboard')
      }
      ));
  }

