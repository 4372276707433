import axios from "axios";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

export const saveFeedback = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/savefeedback`;

    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to save feedback')
        )
}

export const saveContact = (url, requestBody, callback) => (dispatch) => {
    const finalUrl = `${url}UserTenant/savecontactus`;

    axios.post(finalUrl, requestBody, getConfig())
        .then(response => {
            return handleResponse(response)
        }
        )
        .then(result => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch(error =>
            handleError(error, 'Failed to save contact')
        )
}