import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from '../../utils/apiUrls';
import { getEncryptedValue, getDecryptedValue } from "../../utils/utility";
import { CustomLoadingSpiner } from '../loader/customLoader';
import { toast } from "react-toastify";
import axios from 'axios';
import { getGoogleConfig, updateusergooglerefreshtoken } from '../../actions/googleCalendarMeetingActions';


class GoogleConfig extends React.Component {
  state = {
    loading: true,
    clientId: "",
    clientSecret: "",
    clientScope: "",
  };


  async componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('code');

    await this.getGoogleConfig(code);
    
  }


  getGoogleConfig = (googleCode) => {
    const { API_HOST, getGoogleConfig } = this.props;
    getGoogleConfig(API_HOST, async(res) => {
      if (res[0]) {
        await this.setState({ clientId: getDecryptedValue(res[0].GoogleClientId), clientSecret: getDecryptedValue(res[0].GoogleClientSecretkey), clientScope: res[0].Scope, loading: false });
        await this.getAccessToken(googleCode);
      }
    });
  }


  getAccessToken = async(code) => {
    try {
      let finalUrl = 'https://accounts.google.com/o/oauth2/token';
      let resource = {
        "client_id": this.state.clientId,
        "code": code,
        "grant_type": "authorization_code",
        "client_secret": this.state.clientSecret,
        "redirect_uri": `${window.location.protocol}//${window.location.host}/googleconfig`,
      }

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });
      console.log("Data@@ final url : ", finalUrl);
      console.log("DATA@@@@: " , data);
      await this.updateUserRefreshToken(data.data.refresh_token, data.data.access_token);
      await this.props.history.push('/dashboard');
    }
    catch (error) {
      toast.error(error)
      this.setState({ loading: false })
    }
}

updateUserRefreshToken = async (refreshToken, accessToken) => {
  const { API_HOST, updateusergooglerefreshtoken } = this.props;
  await updateusergooglerefreshtoken(API_HOST, getEncryptedValue(refreshToken), (res) => {
    if (res) {
      var userData = JSON.parse(localStorage.getItem('userdetail'));
      userData.Google_Refresh_Token = getEncryptedValue(refreshToken);
      userData.Google_Access_Token = getEncryptedValue(accessToken);
      localStorage.setItem('userdetail', JSON.stringify(userData));
      
      toast.success("Your token is successfully generated");
      //this.props.history.push({ pathname: '/dashboard'})
      //this.props.history.push('/dashboard');
    }
  })
}

  render() {
    return (
      <CustomLoadingSpiner InProgress={this.state.loading} />
    );
  }
} 

// Prop types of props.
GoogleConfig.propTypes = {
  API_HOST: PropTypes.string
};

// Set default props.
GoogleConfig.defaultProps = {
  slackConfigListResponse: [],
};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
  };
};
export default connect(mapStateToProps, { getGoogleConfig, updateusergooglerefreshtoken })(GoogleConfig);
