import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { compose } from "redux";
import { ToastContainer } from "react-toastify";
import Login from "../layout/login/components/Login";
import Signup from "../layout/signup/components/Signup";
import Office from "../layout/office/components/Office";
import Dashboard from "../layout/dashboard/components/Dashboard";
import MultipleEmail from "../layout/multiple_email/components/MultipleEmail"
import TermsAndConditions from "../layout/termsandcondition/components/termsandcondition";
import ZoomVerify from "../layout/ZoomVerify/components/zoomverify";
import PrivacyPolicy from "../layout/termsandcondition/components/privacypolicy";
import SlackConfig from "../layout/slackconfig/components/SlackConfig";
import ForgotPassword from "../layout/forgotPassword/component/ForgotPassword";
import ResetPassword from "../layout/resetPassword/component/ResetPassword";
import { LoadingSpinerComponent } from "../components/loader/loader";
import "./Routers.css";
import SlackToken from "../layout/dashboard/components/SlackToken";
import HsJiraToken from "../layout/dashboard/components/HsJiraToken";
import ImportToken from "../layout/dashboard/components/ImportToken";
import GoogleConfig from "../components/google_calendar/GoogleConfig";
//import ZoomImportToken from "../layout/dashboard/components/ZoomImportToken";
import SigningWithSlackToken from "../layout/dashboard/components/SigningWithSlackToken";
import JiraToken from "../layout/jira/components/JiraToken";
import ContactUs from "../layout/contact_us/components/ContactUs";
import Unsubscribe from "../layout/contact_us/components/Unsubscribe";
import Subscribe from "../layout/contact_us/components/Subscribe";

class Routers extends React.Component {

  render() {
    const loggedInRes = sessionStorage.getItem("userToken");

    return (
      <div className="main-wrapper">
        <LoadingSpinerComponent />
        <ToastContainer autoClose={10000} />
        <Switch>
          <Route
            path="/slackconfig"
            render={(props) => (
              <SlackConfig
                {...props}
                component={SlackConfig}
                title="Precis: Slack Configuration"
              />
            )}
          ></Route>
          <Route
            path="/privacypolicy"
            render={(props) => (
              <PrivacyPolicy
                {...props}
                component={PrivacyPolicy}
                title="Precis: Privacy Policy"
              />
            )}
          ></Route>
          <Route
            path="/termsandconditions"
            render={(props) => (
              <TermsAndConditions
                {...props}
                component={TermsAndConditions}
                title="Precis: TermsAndConditions"
              />
            )}
          >
          </Route>
          <Route
            path="/zoomverify"
            render={(props) => (
              <ZoomVerify
                {...props}
                component={ZoomVerify}
                title="Precis: ZoomVerify"
              />
            )}
          >
          </Route>
          <Route
            path="/contactus"
            render={(props) => (
              <ContactUs
                {...props}
                component={ContactUs}
                title="Precis: Contact Us"
              />
            )}
          ></Route>
          <Route
            path="/dashboard"
            render={(props) => {
              if (loggedInRes !== null) {
                return (<Dashboard
                  {...props}
                  component={Dashboard}
                  title="Precis "
                />)
              } else { return (<Redirect to="/" />) }
            }}
          ></Route>
          {loggedInRes !== null && <Route exact path="/:meetingnum/:isjoining" component={Dashboard} {...this.props} />}
          {/* <Route
            path="/dashboard/:meetingnum/:isjoining"
            render={(props) => { if(loggedInRes !== null){
              return (<Dashboard
                {...props}
                component={Dashboard}
                title="Precis "
              />)}else {return ( <Redirect to="/" />)}
            }}
          ></Route> */}
          <Route
            path="/office"
            render={(props) => (
              <Office
                {...props}
                component={Office}
                title="Precis: Create Office"
              />
            )}
          ></Route>
          <Route
            path="/signup"
            render={(props) => (
              <Signup
                {...props}
                component={Signup}
                title="Precis: Sign Up"
              />
            )}
          ></Route>
          <Route
            path="/login"
            render={(props) => {
              if (loggedInRes === null) {
                return (<Login
                  {...props}
                  component={Login}
                  title="Precis: Log in"
                  loggedInRes={loggedInRes}
                />)
              } else { return (<Redirect to="/dashboard" />) }
            }}
          ></Route>

          <Route
            path="/multipleemail"
            render={(props) => (
              <MultipleEmail
                {...props}
                component={MultipleEmail}
                title="Precis: Multiple Workspace"
              />
            )}
          ></Route>
          <Route
            path="/forgotPassword"
            render={(props) => (
              <ForgotPassword
                {...props}
                component={ForgotPassword}
                title="Precis: Forgot Password"
              />
            )}
          ></Route>
          <Route
            path="/reset-password/:activationKey"
            render={props =>
              <ResetPassword
                {...props}
                // key={props.match.params.activationKey}
                component={ResetPassword}
                title="Precis: Reset Password"
              />
            }
          ></Route>
          <Route
            path="/slacktoken"
            render={props =>
              <SlackToken
                {...props}
                // key={props.match.params.activationKey}
                component={SlackToken}
                title="Precis: Slack Token"
              />
            }
          ></Route>
          <Route
            path="/hsjiratoken"
            render={props =>
              <HsJiraToken
                {...props}
                component={HsJiraToken}
                title="Precis: HsJira Token"
              />
            }
          ></Route>
          <Route
             path="/unsubscribe/:emailIdKey"
            render={props =>
              <Unsubscribe
                {...props}
                component={Unsubscribe}
                title="Precis: Unsubscribe"
              />
            }
          ></Route>
          <Route
            path="/subscribe/:emailIdKey"
            render={props =>
              <Subscribe
                {...props}
                component={Subscribe}
                title="Precis: Subscribe"
              />
            }
          ></Route>
          <Route
            path="/importtoken"
            render={props =>
              <ImportToken
                {...props}
                // key={props.match.params.activationKey}
                component={ImportToken}
                title="Precis: Import Slack Users"
              />
            }
          ></Route>
          <Route
            path="/signingwithslacktoken"
            render={props =>
              <SigningWithSlackToken
                {...props}
                // key={props.match.params.activationKey}
                component={SigningWithSlackToken}
                title="Precis: Signing With Slack Token"
              />
            }
          ></Route>
          <Route
            path="/googleconfig"
            render={props =>
              <GoogleConfig
                {...props}
                component={GoogleConfig}
                title="Precis: Google Configuration"
              />
            }
          ></Route>
          {/* <Route
            path="/zoomimporttoken"
            render={props =>
              <ZoomImportToken
                {...props}
                // key={props.match.params.activationKey}
                component={ZoomImportToken}
                title="Precis: Zoom Import Details"
              />
            }
          ></Route> */}
          <Route
            path="/jiratoken"
            render={props =>
              <JiraToken
                {...props}
                // key={props.match.params.activationKey}
                component={JiraToken}
                title="Precis: Jira Token"
              />
            }
          ></Route>
          <Route path="/" render={() => <Redirect to="/login" />}></Route>
        </Switch>
      </div>
    );
  }
}

export default compose(withRouter)(Routers);
