import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from "../../utils/apiUrls";
import { saveWorkingHours, getUserAvailabilityList, deleteUserAvailabilityList, getUserAvailabilityBetweenDates } from "../../actions/WorkingHoursActions";
import { getUserListDepartmentUpdateOfficeTime } from "../../actions/mainOfficeActions";
import { getUserDetail } from "../../actions/dashboardActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';
import moment from 'moment';
import ClockLoader from "react-spinners/ClockLoader";
import TimePicker from 'rc-time-picker';
import { toast } from "react-toastify";

let user_TZ = "";
let availabilityTime = "09:00 to 19:00";
class WorkingHours extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: this.props.workingHourModal,
      startDate: moment(this.props.startDate).toDate(),
      endDate: moment(this.props.endDate).toDate(),
      startTime: new Date(),
      endTime: new Date(),
      isHoliday: false,
      workingHoursList: [],
      workingHoursId: 0,
      onDeleteSelected: false,
      isEditMode: false,
      isDisabled: false,
      loading: false
    }
  };
  componentDidMount() {
    user_TZ = JSON.parse(localStorage.getItem('userdetail')) === null && (JSON.parse(localStorage.getItem('userdetail')).TZ === "" || JSON.parse(localStorage.getItem('userdetail')).TZ === null) ? "Asia/Kolkata" : JSON.parse(localStorage.getItem('userdetail')).TZ;
    availabilityTime = JSON.parse(localStorage.getItem('userdetail')) !== null && JSON.parse(localStorage.getItem('userdetail')) !== null && JSON.parse(localStorage.getItem('userdetail')).UserStartTime_EndTimeVM !== null && JSON.parse(localStorage.getItem('userdetail')).UserStartTime_EndTimeVM.Availibility_Time && JSON.parse(localStorage.getItem('userdetail')).UserStartTime_EndTimeVM.Availibility_Time !== "" ? JSON.parse(localStorage.getItem('userdetail')).UserStartTime_EndTimeVM.Availibility_Time : "09:00 to 19:00";
    this.getUserAvailabilityList();
    const dt = new Date()
    if (this.props.isEditWorkingHours) {
      if (availabilityTime && availabilityTime !== "") {
        this.setState({
          startTime: moment(dt.setHours(0, parseInt((availabilityTime.substr(0, 2) * 60)) + parseInt(availabilityTime.substr(3, 2)), 0, 0)).tz(user_TZ).toDate(),
          endTime: moment(dt.setHours(0, parseInt((availabilityTime.slice(availabilityTime.length - 5, availabilityTime.length - 3) * 60)) + parseInt(availabilityTime.slice(availabilityTime.length - 2, availabilityTime.length)), 0, 0)).tz(user_TZ).toDate(),
        })
      }

    }

  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.getUserAvailabilityListResponse !== prevProps.getUserAvailabilityListResponse) {
      this.setState({
        workingHoursList: nextProps.getUserAvailabilityListResponse
      })
    }

    if (nextProps.getUserDetailResponse !== prevProps.getUserDetailResponse) {
     // localStorage.setItem('userdetail', JSON.stringify(nextProps.getUserDetailResponse));
      this.props.userValuesChanges(nextProps.getUserDetailResponse);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workingHourModal !== this.props.workingHourModal) {
      const dt = new Date()
      this.setState({
        modal: this.props.workingHourModal,
        startDate: moment(this.props.startDate).toDate(),
        endDate: moment(this.props.endDate).toDate(),
        startTime: moment(dt.setHours(0, parseInt((availabilityTime.substr(0, 2) * 60)) + parseInt(availabilityTime.substr(3, 2)), 0, 0)).tz(user_TZ).toDate(),
        endTime: moment(dt.setHours(0, parseInt((availabilityTime.slice(availabilityTime.length - 5, availabilityTime.length - 3) * 60)) + parseInt(availabilityTime.slice(availabilityTime.length - 2, availabilityTime.length)), 0, 0)).tz(user_TZ).toDate(),
      })
    }
  }

  getUserAvailabilityList = async () => {
    const { API_HOST, getUserAvailabilityList } = this.props;
    this.setState({
      loading: true
    })
    await getUserAvailabilityList(API_HOST, (res) => {
      this.setState({
        loading: false
      })
    });
  }

  toggle = () => {

    this.setState({
      modal: !this.state.modal
    });

    this.getUserAvailabilityList();
    this.props.workingHourFlagSet();
  }

  save = async () => {
    this.setState({ isDisabled: true })
    let Is_Holiday = 0;
    const moment = require('moment-timezone');
    let Id = 0;
    const { API_HOST, saveWorkingHours } = this.props;
    if (this.state.isHoliday) {
      Is_Holiday = 1;
    }
    if (this.state.isEditMode) {
      Id = this.state.workingHoursId;
      this.setState({
        isEditMode: false,
        workingHoursId: 0
      })
    }
    //const userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;
    const saveWorkingHoursRequest = {
      "Id": Id,
      "Id_User": 0,
      "Start_Date": moment(this.state.startDate).format('YYYY-MM-DD'),
      "End_Date": moment(this.state.endDate).format('YYYY-MM-DD'),
      "Start_Time": parseInt(moment(this.state.startTime).tz(user_TZ).toDate().getHours()) * 60 + (moment(this.state.startTime).tz(user_TZ).toDate().getMinutes()),
      "End_Time": parseInt(moment(this.state.endTime).tz(user_TZ).toDate().getHours()) * 60 + (moment(this.state.endTime).tz(user_TZ).toDate().getMinutes()),
      "Is_Holiday": Is_Holiday
    }
    await saveWorkingHours(API_HOST, saveWorkingHoursRequest, (res) => {
      const dt = new Date()
      //  if(dt.getTime()>=this.state.startDate.getTime() && dt.getTime()<=this.state.endDate.getTime())
      //  {
      //   const userData = JSON.parse(localStorage.getItem('userdetail'));
      //         userData.UserStartTime_EndTimeVM.Availibility_Time = moment(this.state.startDate).tz(user_TZ).format('YYYY-MM-DD');
      //         localStorage.setItem('userdetail', JSON.stringify(userData));
      //  }
      this.setState({
        startDate: new Date(),
        endDate: new Date(),
        startTime: new Date(moment(dt.setHours(0, parseInt((availabilityTime.substr(0, 2) * 60)) + parseInt(availabilityTime.substr(3, 2)), 0, 0)).tz(user_TZ).format("YYYY-MM-DD HH:mm:ss")),
        endTime: new Date(moment(dt.setHours(0, parseInt((availabilityTime.slice(availabilityTime.length - 5, availabilityTime.length - 3) * 60)) + parseInt(availabilityTime.slice(availabilityTime.length - 2, availabilityTime.length)), 0, 0)).tz(user_TZ).format("YYYY-MM-DD HH:mm:ss")),
        isHoliday: false,
        isDisabled: false
      })
      this.getUserAvailabilityList();
      this.getUserListDepartmentUpdateOfficeTime();
    });
  }

  getUserDetail = async (id) => {
    const { API_HOST, getUserDetail } = this.props;
    await getUserDetail(API_HOST, id);
};

  getUserListDepartmentUpdateOfficeTime = async () => {
    const userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;
    const { API_HOST, getUserListDepartmentUpdateOfficeTime } = this.props;
    const requestBody = {
      // "Id_User" : 0,
      "Id_Department": JSON.parse(localStorage.getItem('userdetail')).Id_Department
    }

    // const requestBody = {
    //   IsPagging: true,
    //   PageNo: 1,
    //   PageSize: 10,
    //   SortingParameters: [
    //     {
    //       Property: "",
    //       Direction: 0
    //     }
    //   ],
    //   SearchCriteria: {
    //     Id_User: 0,
    //     "Id_Department": JSON.parse(localStorage.getItem('userdetail')).Id_Department
    //   }
    // };

    await getUserListDepartmentUpdateOfficeTime(API_HOST, requestBody);
    await this.getUserDetail(userId);
  }

  onDeleteWorkingHoursPopup = (workingHoursId) => {
    this.setState({
      onDeleteSelected: true,
      workingHoursId: workingHoursId
    })
  };

  editworkingHours = (list) => {
    const dt = new Date();
    this.setState({
      startDate: new Date(moment(list.Start_Date)),
      endDate: new Date(moment(list.End_Date)),
      startTime: new Date(moment(dt.setHours(0, list.Start_Time, 0, 0))),
      endTime: new Date(moment(dt.setHours(0, list.End_Time, 0, 0))),
      workingHoursId: list.Id,
      isEditMode: true,
      isHoliday: list.Is_Holiday
    })
  }


  onCloseDelete = () => {
    const dt = new Date()
    this.setState({
      workingHoursId: 0,
      onDeleteSelected: false,
      startTime: new Date(moment(dt.setHours(0, parseInt((availabilityTime.substr(0, 2) * 60)) + parseInt(availabilityTime.substr(3, 2)), 0, 0)).tz(user_TZ).format("YYYY-MM-DD HH:mm:ss")),
      endTime: new Date(moment(dt.setHours(0, parseInt((availabilityTime.slice(availabilityTime.length - 5, availabilityTime.length - 3) * 60)) + parseInt(availabilityTime.slice(availabilityTime.length - 2, availabilityTime.length)), 0, 0)).tz(user_TZ).format("YYYY-MM-DD HH:mm:ss")),

    })
  };

  //Delete UserAvailabilityList
  deleteUserAvailabilityList = () => {
    const { API_HOST, deleteUserAvailabilityList } = this.props;

    deleteUserAvailabilityList(API_HOST, this.state.workingHoursId, (res) => {
      this.getUserAvailabilityList();
      this.getUserListDepartmentUpdateOfficeTime();
    })
    this.onCloseDelete();

  };

  setStartDate = (date) => {
    this.setState({ startDate: date })
    this.getUserAvailabilityBetweenDates(date, this.state.endDate);
  }

  setEndDate = (date) => {
    this.setState({ endDate: date })
    this.getUserAvailabilityBetweenDates(this.state.startDate, date);
  }

  getUserAvailabilityBetweenDates = (startDate, endDate) => {
    const moment = require('moment-timezone');
    const userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;
    const { API_HOST, getUserAvailabilityBetweenDates } = this.props;
    const getUserAvailabilityBetweenDatesRequest = {
      "Id_User": userId,
      "Start_Date": moment(startDate).format('YYYY-MM-DD'),
      "End_Date": moment(endDate).format('YYYY-MM-DD')
    }

    getUserAvailabilityBetweenDates(API_HOST, getUserAvailabilityBetweenDatesRequest, (res) => {
      this.setState({ workingHoursList: res })
    });

  }

  addNew = () => {
    const dt = new Date()
    this.setState({
      isEditMode: false,
      startDate: new Date(),
      endDate: new Date(),
      startTime: moment(dt.setHours(0, parseInt((availabilityTime.substr(0, 2) * 60)) + parseInt(availabilityTime.substr(3, 2)), 0, 0)).tz(user_TZ).toDate(),
      endTime: moment(dt.setHours(0, parseInt((availabilityTime.slice(availabilityTime.length - 5, availabilityTime.length - 3) * 60)) + parseInt(availabilityTime.slice(availabilityTime.length - 2, availabilityTime.length)), 0, 0)).tz(user_TZ).toDate(),
      isHoliday: false
    })
  }

  setLocalZone = (date) => {
    const dateWithoutZone = moment
      .tz(date, user_TZ)
      .format("YYYY-MM-DDTHH:mm:ss.SSS")
    const localZone = moment(dateWithoutZone).format("Z")
    const dateWithLocalZone = [dateWithoutZone, localZone].join("")

    return new Date(dateWithLocalZone)
  }
//// new function added for TimePicker
  setStartTime = async (date) => {
    if(date === null){
      toast.error("Start Time cannot be empty");
    }
    var sTime = moment(date).toDate();
    await this.setState({ startTime: sTime });
  };

  setEndTime = async (date) => {
    if(date === null){
      toast.error("End Time cannot be empty");
    }
    var eTime = moment(date).toDate()
    await this.setState({ endTime: eTime });

  };
  ////


  render() {
    const columns = [
      {
        id: 'Start_Date',
        Header: <strong className="my_custom_class">Start Date</strong>,
        minWidth: 75,
        accessor: d => {
          return moment(d.Start_Date).format('DD-MM-YYYY')
        },
        //filterable: true,
        //sortable: true,
      },
      {
        id: "End_Date",
        Header: <strong className="my_custom_class">End Date</strong>,
        minWidth: 75,
        accessor: d => {
          return moment(d.End_Date).format('DD-MM-YYYY')
        },
        //filterable: true,
        //sortable: true,
      },
      {
        id: 'Start_Time',
        Header: <strong className="my_custom_class">Check In Time</strong>,
        minWidth: 70,
        accessor: d => {
          return moment.utc(moment.duration((d.Start_Time * 60), "seconds").asMilliseconds()).format("hh:mm a")
        },
        //filterable: true,
        //sortable: true,
      },
      {
        id: 'End_Time',
        Header: <strong className="my_custom_class"> Check Out Time</strong>,
        minWidth: 70,
        accessor: d => {
          return moment.utc(moment.duration((d.End_Time * 60), "seconds").asMilliseconds()).format("hh:mm a")
        },

        //filterable: true,
        //sortable: true,
      },
      {
        id: 'Status',
        Header: <strong className="my_custom_class"> Status</strong>,
        minWidth: 70,
        accessor: d => {
          return d.Is_Holiday ? "On leave" : "On Duty"
        },

        //filterable: true,
        //sortable: true,
      },
      {
        Header: <strong className="my_custom_class">Actions</strong>,
        accessor: 'Id',
        minWidth: 100,
        //filterable: true,
        //sortable: true,
        Cell: row => {
          return (
            <>
              <i onClick={() => this.editworkingHours(row.original)} className="fa fa-pencil-square-o dept-edit-button" aria-hidden="true" style={{cursor: "pointer"}}></i>
              <i onClick={() => this.onDeleteWorkingHoursPopup(row.original.Id)} className="fa fa-trash dept-delete-button" aria-hidden="true" style={{cursor: "pointer"}}></i>

            </>
          )
        }
      }
    ];
    const { loading, isDisabled } = this.state;
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="xl" >
          <ModalHeader toggle={this.toggle}>Working Hours</ModalHeader>
          <ModalBody>
            <div className="container ">
              <div className="row">
                <div className="col-sm-6">
                  <div className="custom-control custom-checkbox d-flex justify-content-between mt-3 mb-3">
                    <input className="custom-control-input" type="checkbox" checked={this.state.isHoliday} id="leave" name="leave" onChange={() => { this.setState({ isHoliday: !this.state.isHoliday }) }} />
                    <label className="custom-control-label" for="leave" >On Leave</label>
                    {this.state.isEditMode ?
                      <p style={{ cursor: 'pointer', color: '#2c69d1' }} onClick={this.addNew}>Add New+</p>
                      : null}
                  </div>
                  <div className="d-flex justify-content-between mt-3 mb-3">
                    <div className="date-range-center ">
                      <label>Start Date:</label>
                      <span>{moment(this.state.startDate).format('DD-MM-YYYY')}</span>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={date => this.setStartDate(date)}
                        selectsStart
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        inline
                        fixedHeight
                      />
                    </div>
                    <div className="date-range-center">
                      <label>End Date:</label>
                      <span>{moment(this.state.endDate).format('DD-MM-YYYY')}</span>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={date => this.setEndDate(date)}
                        selectsEnd
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        minDate={this.state.startDate}
                        inline
                        fixedHeight
                      />
                    </div>
                  </div>
                  <div className=" d-flex justify-content-between">
                    <div className="date-range-center  w-50 pr-2">
                      {/* <label className="mr-auto">Start Time</label>
                      <DatePicker
                        selected={this.state.startTime}
                        onChange={date => this.setState({ startTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control"
                        disabled={this.state.isHoliday}
                      /> */}

                      <label for="starttime">Start Time</label>
                        <TimePicker
                          onChange={date => this.setStartTime(date)}
                          defaultValue={moment(new Date())}
                          showSecond={false}
                          format="HH:mm"
                          hideDisabledOptions
                          type="time"
                        />
                    </div>
                    <div className="date-range-center w-50 pl-2">
                      {/* <label className="mr-auto">End Time</label>
                      <DatePicker
                        selected={this.state.endTime}
                        onChange={date => this.setState({ endTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control"
                        disabled={this.state.isHoliday}
                      /> */}

                      <label for="endtime">End Time</label>   
                        <TimePicker
                          onChange={date => this.setEndTime(date)}
                          defaultValue={moment(new Date())}
                          showSecond={false}
                          format="HH:mm"
                          hideDisabledOptions
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <Button type='submit' color="primary" onClick={() => { this.save() }} disabled={isDisabled}><span>save</span>
                      {isDisabled === true && <span style={{ marginLeft: '5px' }}>
                        <ClockLoader
                          size={16}
                          color={"#fff"}
                          loading={isDisabled}
                        />
                      </span>}
                    </Button>
                    {/* <Button type='submit' color="primary" disabled={isDisabled} onClick={() => { this.save() }}>save</Button> */}
                  </div>
                </div>
                <div className="col-sm-6" style={{ marginTop: '1%' }}>
                  <ReactTable
                    data={this.state.workingHoursList}
                    columns={columns}
                    defaultPageSize={5}
                    pagination={true}
                    className="-striped -highlight"
                    // pageSizeOptions={[5, 10, 15]}
                    showPageSizeOptions={false}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {this.state.onDeleteSelected ? (
          <Modal isOpen={this.state.onDeleteSelected} backdrop={true} className="dept-class">
            <ModalHeader toggle={() => this.onCloseDelete()} className="dept-header">
              Delete Department
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete this working hours?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.deleteUserAvailabilityList}>Yes</Button>
              <Button color="secondary" onClick={this.onCloseDelete}>No</Button>
            </ModalFooter>
          </Modal>
        ) : ""}
      </>
    );
  }
}

WorkingHours.defaultProps = {
  API_HOST: PropTypes.string,
  saveWorkingHours: PropTypes.func,
  getUserAvailabilityList: PropTypes.func,
  getUserAvailabilityListResponse: PropTypes.array,
  getUserAvailabilityBetweenDates: PropTypes.func
};

// Set default props.
WorkingHours.defaultProps = {
  saveWorkingHours: () => { },
  getUserAvailabilityList: () => { },
  deleteUserAvailabilityList: () => { },
  getUserAvailabilityListResponse: [],

  getUserAvailabilityBetweenDates: () => { }
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  getUserAvailabilityListResponse: state.workingHoursReducer.getUserAvailabilityListResponse,
  getUserDetailResponse: state.dashboardReducer.getUserDetailResponse.userDetailResponse
})


export default connect(mapStateToProps, { getUserDetail, saveWorkingHours, getUserAvailabilityList, deleteUserAvailabilityList, getUserAvailabilityBetweenDates, getUserListDepartmentUpdateOfficeTime })(WorkingHours)
