import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from "../../../utils/apiUrls";
import "react-table-6/react-table.css";
import { toast } from "react-toastify";
import { addToDoTask, userToDoListFetch } from "../../../actions/todoActions";
import {
  getUserJiraRefreshToken,
  getJiraDomain,
  updateUserJiraRefreshToken,
  getJiraTaskLogDetails,
  saveUserToDoFromJiraTask,
  updateJiraDomainAccess,
} from "../../../actions/jiraActions";
import { getPrivateJiraTaskList } from "../../../actions/privateJiraActions";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import JiraIssue from "./JiraIssue";
import PrivateJiraIssue from "./PrivateJiraIssue";
import BacklogIssues from "./BacklogIssues";
import AddNewTask from "./AddNewTask";
import { getJiraConfig } from "../../../actions/jiraActions";
import ReactTable from "react-table-6";
import { FormGroup, Row, Col } from "reactstrap";
import {
  getEncryptedValue,
  getDecryptedValue,
} from "../../../utils/utility";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JiraLogReport from "./JiraLogReport";
import TaskWiseLogReport from "./TaskWiseLogReport";
import ReactTooltip from "react-tooltip";
import Multiselect from "multiselect-react-dropdown";

class Jira extends React.Component {
  state = {
    loading: true,
    isActive: false,
    JiraTaskList: [],
    isJiraIssue: false,
    jiraIssue: {},
    cloudId: "",
    accessToken: "",
    isBacklog: false,
    isAddTask: false,
    jiraId: null,
    jiraTaskSelected: {},
    jiraClientId: "",
    jiraClientSecret: "",
    jiraClientDisable: true,
    refreshToken: "",
    showAllIssues: this.props.showAllIssues,
    pageSizeOptions: [5, 10, 20, 25, 50, 100],
    selectedPageSize: 10,
    selectedPage: 0,
    jiraDomain: [],
    jiraDomainSelected: {},
    jiraTaskLogDetails: [],
    isJiraLogReport: false,
    isTaskWiseLogReport: false,
    isToDoDescription: false,
    todoDescription: "",
    searchValue: "",
    isPrivateJiraSelected: false,
    privateJiraTaskList: [],
    epicStatusSelected: [],
    preSlectedstatus: [{ name: "To Do" }],
  };

  async componentDidMount() {
    await this.setState({
      isActive: true,
    });
    await this.getJiraDomain();
    await this.getJIRAConfig();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isPrivateJiraSelected !== this.state.isPrivateJiraSelected) {
      this.setState({ isPrivateJiraSelected: nextProps.isPrivateJiraSelected });
    }
  }

  getJiraDomain = async () => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, (res) => {
      if (res[0]) {
        let newValue = res.map(function (obj) {
          return {
            id: getDecryptedValue(obj.CloudId),
            CloudId: getDecryptedValue(obj.CloudId),
            Id_User_Jira_Detail: obj.Id_User_Jira_Detail,
            Is_Private: obj.Is_Private,
            Domain_Name: obj.Domain_Name,
            key: obj.Domain_Name,
            url: obj.url,
            Jira_Scope_Detail: obj.Jira_Scope_Detail,
            label: obj.Domain_Name,
            value: getDecryptedValue(obj.CloudId),
          };
        });
        this.setState({
          jiraDomain: newValue,
          jiraDomainSelected: newValue[0],
          cloudId: newValue[0].CloudId,
        });

        //added so that if private doamin is the first one then it will call for it's issues.
        if (this.state.jiraDomainSelected.Is_Private) {
          this.setState({ isPrivateJiraSelected: true });
          this.getPrivateJiraMyTaskList(
            this.state.jiraDomainSelected.Domain_Name
          );
        }
      }
    });
  };

  getRefreshToken = () => {
    const { API_HOST, getUserJiraRefreshToken } = this.props;

    getUserJiraRefreshToken(API_HOST, (res) => {
      if (res.Jira_Token) {
        this.setState({ refreshToken: res.Jira_Token });
        // this.getJiraDomain();
        this.getAccessToken(res.Jira_Token);
        this.setState({ jiraClientDisable: false, isActive: false });
      } else {
        this.setState({ jiraClientDisable: true, isActive: false });
      }
    });
  };

  getAccessToken = async (refreshToken) => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: getDecryptedValue(refreshToken),
      };

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });
      this.setState({
        accessToken: data.data.access_token,
        refreshToken: data.data.refresh_token,
      });
      // this.setState({ accessToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik16bERNemsxTVRoRlFVRTJRa0ZGT0VGRk9URkJOREJDTVRRek5EZzJSRVpDT1VKRFJrVXdNZyJ9.eyJodHRwczovL2F0bGFzc2lhbi5jb20vb2F1dGhDbGllbnRJZCI6IlB1R0ljMU52UzdtYkQ3RzdiaXE3UXJ2NEd5cGxBQ2tHIiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL2VtYWlsRG9tYWluIjoiaGVsaW9zc29sdXRpb25zLmNvIiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL3N5c3RlbUFjY291bnRJZCI6IjVmY2RlOGM0YTIzN2Y3MDA2ZmU0YTdhYyIsImh0dHBzOi8vYXRsYXNzaWFuLmNvbS9zeXN0ZW1BY2NvdW50RW1haWxEb21haW4iOiJjb25uZWN0LmF0bGFzc2lhbi5jb20iLCJodHRwczovL2F0bGFzc2lhbi5jb20vdmVyaWZpZWQiOnRydWUsImh0dHBzOi8vYXRsYXNzaWFuLmNvbS9maXJzdFBhcnR5IjpmYWxzZSwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tLzNsbyI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9hdGxhc3NpYW4tYWNjb3VudC1wcm9kLnB1czIuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDVlY2I2NmNiN2QwYzI1MGMyMzRjZDkxNiIsImF1ZCI6ImFwaS5hdGxhc3NpYW4uY29tIiwiaWF0IjoxNjEwNDQ2Mjc3LCJleHAiOjE2MTA0NDk4NzcsImF6cCI6IlB1R0ljMU52UzdtYkQ3RzdiaXE3UXJ2NEd5cGxBQ2tHIiwic2NvcGUiOiJtYW5hZ2U6amlyYS1jb25maWd1cmF0aW9uIG1hbmFnZTpqaXJhLXByb2plY3Qgd3JpdGU6amlyYS13b3JrIHJlYWQ6amlyYS13b3JrIHJlYWQ6amlyYS11c2VyIG1hbmFnZTpqaXJhLWRhdGEtcHJvdmlkZXIgb2ZmbGluZV9hY2Nlc3MifQ.uDkf7LEoCmApZLaVqksmGObfCyiYl1x_pZ7Mjzep2zQktv94CLDnu1-vii8Pc6tu6nU5Slm1SVLSgrtYsrUYjzMtr4LzfpXnwOy6S4_Mpnp9LTSDWg8GhNnJ_pa7eq3r7Db95w9pIcQmqlvqjksv3ySZfWU6kyS-IBhGrFzmkBQ8jYwxJZI8KNdGYeO9bHkfwQxEqvF7GRY2xrgirpumaa37ZgIllmvJRmRluzyUe4omNsMnLSO_10sc0IU518ZHDzTzcmNaiyeX7WXfICpiETsPz1cGu7R0oeUyO7nKEV8HNxG3uNl7PvU70hoPpk_cOFku3CH79LuJYtrqqn3dtg" })

      await this.updateUserJiraRefreshToken(data.data.refresh_token);
      if (!this.state.jiraDomainSelected.Is_Private) {
        await this.getJiraYourTaskList(
          this.state.cloudId,
          data.data.access_token
        );
      }
    } catch (error) {
      this.getConnectToJira();
      toast.error(
        "Refresh token is expire.Please reconnect your application with your JIRA"
      );
      this.setState({ isActive: false });
    }
  };

  updateUserJiraRefreshToken = async (refreshToken) => {
    const { API_HOST, updateUserJiraRefreshToken } = this.props;

    let requestBody = {
      Jira_Token: getEncryptedValue(refreshToken),
    };

    await updateUserJiraRefreshToken(API_HOST, requestBody);
  };

  getAccessTokenAgain = async () => {
    try {
      let finalUrl = "https://auth.atlassian.com/oauth/token";
      let resource = {
        grant_type: "refresh_token",
        client_id: this.state.jiraClientId,
        client_secret: this.state.jiraClientSecret,
        refresh_token: this.state.refreshToken,
      };

      let headers = {
        "Content-Type": "application/json",
      };
      const data = await axios.post(finalUrl, resource, { headers });
      console.log("data result::: ", data);
      // this.setState({ accessToken: data.data.access_token })
      return data.data.access_token;
      this.getJiraYourTaskList(this.state.cloudId, data.data.access_token);
    } catch (error) {
      toast.error(
        "Refresh token is expired.Please reconnect your application with your JIRA"
      );
      this.setState({ isActive: false });
    }
  };

  getJiraYourTaskList = async (cloudId, token) => {
    this.setState({ loading: true });
    let selectedEpicStatus;

    if (this.state.epicStatusSelected.length === 0) {
      selectedEpicStatus = this.state.preSlectedstatus.map(
        (label) => label.name
      );
    } else {
      selectedEpicStatus = this.state.epicStatusSelected.map(
        (label) => label.name
      );
    }
    let selectedTaskStatus = '"' + selectedEpicStatus.join('","') + '"';
    console.log(selectedTaskStatus);
    // let statusCategory="(";
    // try {
    //   //  const finalUrl = this.props.jiraIssue.self
    //   const finalUrl = `https://api.atlassian.com/ex/jira/${cloudId}/rest/api/2/status`

    //   let headers = {
    //     "Accept": "application/json",
    //     "Authorization": "Bearer " + token
    //   };
    //   const { data } = await axios.get(finalUrl, { headers });
    //   console.log("status category",data)

    //   statusCategory=data.reduce((statusCategory, currentValue) => {
    //     if(currentValue.key!="undefined"){
    //     return statusCategory + "'"+currentValue.name+"',";
    //   }
    //   else
    //   {
    //     return statusCategory + "";
    //   }
    //   }, "");
    //   statusCategory="("+statusCategory
    //   statusCategory=statusCategory.slice(0, -1) +")"
    //   console.log("status category",statusCategory)
    // }
    // catch (error) {
    //   if (error.response.status === 401) {
    //     let gettoken = await this.getAccessTokenAgain();
    //     await this.setState({ accessToken: gettoken })
    //     this.getJiraYourTaskList(this.state.cloudId,gettoken)
    //   }
    //   toast.error(error)
    // }
    if (selectedTaskStatus !== "") {
      try {
        const finalUrl = `https://api.atlassian.com/ex/jira/${cloudId}/rest/api/2/search?jql=assignee=currentuser()AND status IN (${selectedTaskStatus})&maxResults=500&order%20by%20created&fields=assignee,comment,created,creator,description,issuelinks,duedate,issuetype,parent,priority,progress,project,reporter,status,subtasks,summary`;
        // const finalUrl = `https://smartchrome.atlassian.net/rest/api/3/project`

        let headers = {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        };
        const { data } = await axios.get(finalUrl, { headers });
        this.setState({
          JiraTaskList: data.issues,
          selectedPageSize: 10,
          selectedPage: 0,
        });
      } catch (error) {
        toast.error(error);
      }
    }

    this.setState({ isActive: false, loading: false });
  };

  onClickIssue = (list) => {
    if (this.state.isPrivateJiraSelected) {
      this.setState({ jiraIssue: list, isPrivateJiraIssue: true });
      this.getJiraTaskLogDetails(list.id);
    } else {
      this.setState({ jiraIssue: list, isJiraIssue: true });
      this.getJiraTaskLogDetails(list.id);
    }
  };

  toggleJiraIssue = () => {
    this.setState({ isJiraIssue: false });
  };

  togglePrivateJiraIssue = () => {
    this.setState({ isPrivateJiraIssue: false });
  };

  toggleBacklog = () => {
    this.setState({
      showAllIssues: false,
    });
  };

  onClickAddTask = () => {
    this.setState({ isAddTask: true });
  };

  closeAddTask = () => {
    this.setState({ isAddTask: false });
  };

  // add Task to To Do List
  addToDoTask = () => {
    const { API_HOST, addToDoTask } = this.props;
    const addToDoTaskRequest = {
      Description:
        "<b>" +
        this.state.jiraTaskSelected.key +
        "</b>   " +
        this.state.jiraTaskSelected.fields.summary,
    };

    addToDoTask(API_HOST, addToDoTaskRequest, (res) => {
      this.userToDoList();
    });
  };

  //Get user To Do List
  userToDoList = () => {
    var userData = localStorage.getItem("userdetail");
    const { API_HOST, userToDoListFetch } = this.props;
    this.setState({ isActive: true });
    userToDoListFetch(API_HOST, JSON.parse(userData).Id_User, (res) => {
      this.setState({ isActive: false });
    });
  };

  getJIRAConfig = () => {
    const { API_HOST, getJiraConfig } = this.props;
    getJiraConfig(API_HOST, (res) => {
      if (res[0]) {
        this.setState({
          jiraClientId: getDecryptedValue(res[0].Client_ID),
          jiraClientSecret: getDecryptedValue(res[0].Client_Secret),
        });

        //this.getJiraDomain();
        this.getRefreshToken();
      } else {
        this.setState({ jiraClientDisable: true });
      }
    });
  };

  getConnectToJira = () => {
    this.props.connectToJira();
  };

  onSelectDomain = (newValue) => {
    if (newValue.Is_Private) {
      this.setState({
        isPrivateJiraSelected: true,
        jiraDomainSelected: newValue,
      });
      this.updateJiraDomainAccess(newValue.Id_User_Jira_Detail);
      this.getPrivateJiraMyTaskList(newValue.Domain_Name);
    } else {
      this.getJiraYourTaskList(newValue.CloudId, this.state.accessToken);
      this.updateJiraDomainAccess(newValue.Id_User_Jira_Detail);
      this.setState({
        isPrivateJiraSelected: false,
        jiraDomainSelected: newValue,
        cloudId: newValue.CloudId,
      });
    }
  };

  getJiraTaskLogDetails = async (jiraId) => {
    const { API_HOST, getJiraTaskLogDetails } = this.props;
    await getJiraTaskLogDetails(API_HOST, jiraId, (res) => {
      // alert(JSON.stringify(res));
      //console.log("res from parent: ",res);
      if (res) {
        this.setState({ jiraTaskLogDetails: res });
        //alert(JSON.stringify(this.state.jiraTaskLogDetails));
      } else {
        this.setState({ jiraTaskLogDetails: [] });
      }
    });
  };

  openJiraLogReport = () => {
    this.setState({ isJiraLogReport: true });
  };

  closeJiraLogReport = () => {
    this.setState({ isJiraLogReport: false });
  };

  openTaskWiseLogReport = () => {
    this.setState({ isTaskWiseLogReport: true });
  };

  closeTaskWiseLogReport = () => {
    this.setState({ isTaskWiseLogReport: false });
  };

  //Save Jira task in ToDo task list.
  saveUserToDoFromJiraTask = (jiraTask) => {
    const { API_HOST, saveUserToDoFromJiraTask } = this.props;
    let requestBody = {
      Id: 0,
      Id_Jira_Task: jiraTask.id,
      Jira_Task_Date: jiraTask.fields.created,
      Description: jiraTask.fields.summary,
      Jira_Task_Key: jiraTask.key,
      Jira_Cloud_Id: getEncryptedValue(this.state.cloudId),
    };

    saveUserToDoFromJiraTask(API_HOST, requestBody, (res) => {
      if (res !== null) {
        // this.userToDoList();
        toast.success("Jira task added to ToDo list successfully.");
      }
    });
  };

  searchChange = (e) => {
    if (this.state.isPrivateJiraSelected) {
      if (e === "") {
        this.getPrivateJiraMyTaskList(
          this.state.jiraDomainSelected.Domain_Name
        );
      }
      this.setState({ searchValue: e });
      let data = this.state.privateJiraTaskList;
      let searchResults = this.search(e, data);
      this.setState({ privateJiraTaskList: searchResults });
    } else {
      if (e === "") {
        this.getJiraYourTaskList(this.state.cloudId, this.state.accessToken);
      }
      this.setState({ searchValue: e });
      let data = this.state.JiraTaskList;
      // if (this.state.userSelected.accountId !== "0") {
      //   if (this.state.userSelected.accountId === "-1") {
      //     data = data.filter(task => task.fields.assignee === null);
      //   }
      //   else {
      //     data = data.filter(task => task.fields.assignee && task.fields.assignee.accountId === this.state.userSelected.accountId);
      //   }
      // }
      let searchResults = this.search(e, data);
      this.setState({ JiraTaskList: searchResults });
    }
  };

  search = (searchTerm, data) => {
    let results = [];
    if (data) {
      results = data.filter(function (object) {
        return (
          object.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
          object.fields.summary.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      return results;
    }
  };

  getPrivateJiraMyTaskList = async (domainName) => {
    const { API_HOST, getPrivateJiraTaskList } = this.props;
    this.setState({ privateJiraTaskList: [] });
    let selectedEpicStatus;

    if (this.state.epicStatusSelected.length === 0) {
      selectedEpicStatus = this.state.preSlectedstatus.map(
        (label) => label.name
      );
    } else {
      selectedEpicStatus = this.state.epicStatusSelected.map(
        (label) => label.name
      );
    }
    if (selectedEpicStatus.length !== 0) {
      this.setState({ loading: true });
      let requestBody = {
        IsCurrentUser: true,
        DomainName: domainName,
        TaskStatusList: selectedEpicStatus,
      };

      await getPrivateJiraTaskList(API_HOST, requestBody, (res) => {
        if (res) {
          this.setState({
            privateJiraTaskList: res,
            selectedPageSize: 10,
            selectedPage: 0,
            loading: false,
          });
        }
      });
    }
  };

  updateJiraDomainAccess = async (domainId) => {
    const { API_HOST, updateJiraDomainAccess } = this.props;

    await updateJiraDomainAccess(API_HOST, domainId, (res) => {
      if (res) {
      }
    });
  };

  onSelect = async (selectedList, selectedItem) => {
    console.log(selectedList);
    await this.setState({ epicStatusSelected: selectedList });
    if (this.state.isPrivateJiraSelected) {
      this.getPrivateJiraMyTaskList(this.state.jiraDomainSelected.Domain_Name);
    } else {
      this.getJiraYourTaskList(this.state.cloudId, this.state.accessToken);
    }
  };

  onRemove = async (selectedList, removedItem) => {
    // const preSlectedstatusvalue = [{ name: "To Do" }];
    await this.setState({
      epicStatusSelected: selectedList,
    });
    if (this.state.epicStatusSelected.length === 0) {
      await this.setState({
        preSlectedstatus: [],
      });
    }
    // if (selectedList.length === 0) {
    //   await this.setState({
    //     preSlectedstatus: preSlectedstatusvalue,
    //     epicStatusSelected: preSlectedstatusvalue,
    //   });
    //   console.log(selectedList);
    //   console.log(this.state.preSlectedstatus);
    //   console.log(this.state.epicStatusSelected);
    // }

    if (this.state.isPrivateJiraSelected) {
      this.getPrivateJiraMyTaskList(this.state.jiraDomainSelected.Domain_Name);
    } else {
      this.getJiraYourTaskList(this.state.cloudId, this.state.accessToken);
    }
  };

  render() {
    const columns = [
      // {
      //     Header: (<input
      //         type="checkbox"
      //         className="checkbox-unreadlist"
      //         style={{ width: '16px', height: '16px' }}
      //         onChange={this.handleChange}
      //         checked={selectAll}
      //     />),
      //     accessor: 'Id_User',
      //     minWidth: 30,
      //     sortable: false,
      //     Cell: row => {
      //         if (row.original.Id_User) {
      //             return (
      //                 <input
      //                     type="checkbox"
      //                     className="checkbox-unreadlist"
      //                     // defaultChecked={this.state.selectedUsers.find((user) => user.Id_User === row.original.Id_User) ? true : false}
      //                     // checked={this.state.selectedUsers.find((user) => user.Id_User === row.original.Id_User) ? true : false}
      //                     //onChange={(e) => this.handleSingleCheckboxChange(e, row.original.Id_User, row.original)}
      //                     defaultChecked={this.state.checked[row.index]}
      //                     checked={this.state.checked[row.index]}
      //                     onChange={(e) => this.handleSingleCheckboxChange(e, row.index, row.original, row.original.Id_User)}
      //                     style={{ width: '16px', height: '16px' }}
      //                 />
      //             )
      //         }
      //     }
      // },
      {
        // Header: <strong className="my_custom_class"></strong>,
        accessor: "key",
        minWidth: 10,
        Cell: (row) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickIssue(row.original);
              }}
            >
              {row.original.key}
            </div>
          );
        },
        //filterable: true,
        //sortable: true,
      },
      {
        // Header: <strong className="my_custom_class">Workgroup Name</strong>,
        accessor: "fields",
        Cell: (row) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickIssue(row.original);
              }}
            >
              {row.original.fields.summary}
            </div>
          );
        },
        //filterable: true,
        //sortable: true,
      },
      {
        // Header: <strong className="my_custom_class"></strong>,
        accessor: "status",
        minWidth: 10,
        Cell: (row) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.onClickIssue(row.original);
              }}
            >
              {row.original.fields.status.name}
            </div>
          );
        },
      },
      {
        // Header: <strong className="my_custom_class"></strong>,
        accessor: "key",
        minWidth: 5,
        Cell: (row) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.saveUserToDoFromJiraTask(row.original);
              }}
              className="text-center"
            >
              <ReactTooltip
                id="addTodoInfo"
                place="left"
                type="dark"
                effect="solid"
                multiline={true}
                backgroundColor="#2c69d1"
                textColor="white"
                border="true"
              />
              <i
                class="fas fa-tasks"
                data-for="addTodoInfo"
                data-tip="Add to ToDo List"
              ></i>
            </div>
          );
        },
      },
      // {
      //     Header: <strong className="my_custom_class">Email</strong>,
      //     accessor: 'EmailId',
      //     //filterable: true,
      //     //sortable: true,
      // }
    ];

    var userData = localStorage.getItem("userdetail");
    const statusOptions = [{ name: "To Do" }, { name: "In Progress" }];

    return (
      <>
        {/* <Scrollbars style={{ height: '800px' }} autoHide >  */}

        <div
          className="model-right-bar to_do_modal"
          id="userloginmodel"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog jira-task-popup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {this.state.showAllIssues ? "Backlogs" : "My Tasks"}
                </h5>
                <div
                  className="close b-left"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.toggle}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-close" />
                </div>
              </div>

              <div className=" modal-body ">
                <LoadingOverlay
                  active={this.state.isActive}
                  spinner
                  text="Loading..."
                >
                  <div className="d-flex">
                    {this.state.jiraClientDisable ? (
                      <div
                        style={{ paddingTop: "50px", paddingBottom: "50px" }}
                      >
                        <span
                          onClick={this.getConnectToJira}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          Connect your Application with your JIRA
                        </span>
                      </div>
                    ) : (
                      <>
                        {/* <div className="task-add mb-0">
                      <button type="button" class="btn btn-primary" disabled={this.state.jiraClientDisable} onClick={this.onClickAddTask}>Add Task</button>
                      <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => { this.setState({ showAllIssues: true }) }}>All Issues</span>
                    </div> */}
                        <div className="jira-task-list">
                          <FormGroup>
                            <Row className="align-items-center">
                              <Col>
                                <Row className="align-items-center">
                                  <Col xs="3">
                                    <div className="task-add mb-0">
                                      <button
                                        type="button"
                                        class="btn btn-primary"
                                        disabled={this.state.jiraClientDisable}
                                        onClick={this.onClickAddTask}
                                      >
                                        Add Task
                                      </button>
                                    </div>
                                  </Col>

                                  {JSON.parse(userData).Is_Admin === true ? (
                                    <Col xs="3">
                                      <div className="task-add mb-0">
                                        <span
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                          onClick={this.openJiraLogReport}
                                        >
                                          JIRA Log Report
                                        </span>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {/* {JSON.parse(userData).Is_Admin === true ? (
                              <Col xs="3" >
                              <div className="task-add mb-0">
                              <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={this.openTaskWiseLogReport}>All Task Log Report</span>
                              </div>    
                          </Col>
                            ) : ( */}
                                  <Col xs="3">
                                    <div className="task-add mb-0">
                                      <span
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={this.openTaskWiseLogReport}
                                      >
                                        Tasks Log Report
                                      </span>
                                    </div>
                                  </Col>
                                  {/* )} */}

                                  <Col>
                                    <div className="task-add mb-0">
                                      <span
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            showAllIssues:
                                              !this.state.showAllIssues,
                                          });
                                          this.toggleJiraIssue();
                                        }}
                                      >
                                        {" "}
                                        {this.state.showAllIssues
                                          ? "My Task"
                                          : "All Tasks"}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xs="3">
                                <Select
                                  components={makeAnimated}
                                  onChange={(newValue) => {
                                    this.onSelectDomain(newValue);
                                  }}
                                  options={this.state.jiraDomain}
                                  value={this.state.jiraDomainSelected}
                                />
                              </Col>
                              {/* <Col xs="4" >
                              <div className="wrapper">
                                <div className="search-box">
                                  <span className="form-control-feedback">
                                    <img src="images/search-icon.png" alt="" />
                                  </span>
                                  <input type="text" className="form-control" onChange={(e) => { this.searchChange(e.target.value) }} value={this.state.searchValue} />
                                </div>
                              </div>
                            </Col> */}
                            </Row>
                          </FormGroup>
                          {this.state.showAllIssues ? (
                            <BacklogIssues
                              jiraDomainSelected={
                                this.state.jiraDomainSelected.Domain_Name
                              }
                              isPrivateJiraSelected={
                                this.state.isPrivateJiraSelected
                              }
                              getJiraTaskLogDetails={this.getJiraTaskLogDetails}
                              jiraTaskLogDetails={this.state.jiraTaskLogDetails}
                              isBacklogIssues={this.state.showAllIssues}
                              toggleBacklogIssues={this.toggleBacklog}
                              cloudId={this.state.cloudId}
                              accessToken={this.state.accessToken}
                              privateJiraTaskList={
                                this.state.privateJiraTaskList[0]
                              }
                              lastTaskProject={this.state.JiraTaskList[0]}
                              getJiraYourTaskList={this.getJiraYourTaskList}
                              getAccessTokenAgain={this.getAccessTokenAgain}
                              refreshToken={this.state.refreshToken}
                            />
                          ) : (
                            <FormGroup>
                              <Row style={{ justifyContent: "space-between" }}>
                                <Col xs="4">
                                  <div className="wrapper">
                                    <div className="search-box">
                                      <span className="form-control-feedback">
                                        <img
                                          src="images/search-icon.png"
                                          alt=""
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        style={{ minWidth: "100px" }}
                                        onChange={(e) => {
                                          this.searchChange(e.target.value);
                                        }}
                                        value={this.state.searchValue}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs="4">
                                  <Multiselect
                                    options={statusOptions} // Options to display in the dropdown
                                    onSelect={this.onSelect} // Function will trigger on select event
                                    onRemove={this.onRemove} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    showCheckbox
                                    selectedValues={this.state.preSlectedstatus}
                                    hidePlaceholder
                                    avoidHighlightFirstOption
                                  />
                                </Col>
                              </Row>
                              {this.state.isPrivateJiraSelected ? (
                                <ReactTable
                                  data={this.state.privateJiraTaskList}
                                  columns={columns}
                                  defaultPageSize={10}
                                  pageSize={this.state.selectedPageSize}
                                  showPagination={true}
                                  className="-striped -highlight"
                                  loading={this.state.loading}
                                  showPageSizeOptions={true}
                                  page={this.state.selectedPage}
                                  showPageJump={false}
                                  pageSizeOptions={this.state.pageSizeOptions}
                                  onPageSizeChange={(pageSize, pageIndex) => {
                                    this.setState({
                                      selectedPageSize: pageSize,
                                      selectedPage: pageIndex,
                                    });
                                  }}
                                  onPageChange={(pageIndex) => {
                                    this.setState({ selectedPage: pageIndex });
                                  }}
                                  style={{
                                    height: "calc(100vh - 158px)", // This will force the table body to overflow and scroll, since there is not enough room
                                  }}
                                />
                              ) : (
                                <ReactTable
                                  data={this.state.JiraTaskList}
                                  columns={columns}
                                  defaultPageSize={10}
                                  pageSize={this.state.selectedPageSize}
                                  showPagination={true}
                                  className="-striped -highlight"
                                  loading={this.state.loading}
                                  showPageSizeOptions={true}
                                  page={this.state.selectedPage}
                                  showPageJump={false}
                                  pageSizeOptions={this.state.pageSizeOptions}
                                  onPageSizeChange={(pageSize, pageIndex) => {
                                    this.setState({
                                      selectedPageSize: pageSize,
                                      selectedPage: pageIndex,
                                    });
                                  }}
                                  onPageChange={(pageIndex) => {
                                    this.setState({ selectedPage: pageIndex });
                                  }}
                                  style={{
                                    height: "calc(100vh - 158px)", // This will force the table body to overflow and scroll, since there is not enough room
                                  }}
                                />
                              )}
                            </FormGroup>
                          )}
                        </div>
                      </>
                    )}
                    {this.state.isJiraIssue ? (
                      <JiraIssue
                        jiraDomainSelected={this.state.jiraDomainSelected}
                        getJiraTaskLogDetails={this.getJiraTaskLogDetails}
                        jiraTaskLogDetails={this.state.jiraTaskLogDetails}
                        isJiraIssue={this.state.isJiraIssue}
                        toggleJiraIssue={this.toggleJiraIssue}
                        jiraIssue={this.state.jiraIssue}
                        cloudId={this.state.cloudId}
                        accessToken={this.state.accessToken}
                        getJiraYourTaskList={this.getJiraYourTaskList}
                        getAccessTokenAgain={this.getAccessTokenAgain}
                        refreshToken={this.state.refreshToken}
                      />
                    ) : null}

                    {this.state.isPrivateJiraSelected &&
                    this.state.isPrivateJiraIssue ? (
                      <PrivateJiraIssue
                        getJiraTaskLogDetails={this.getJiraTaskLogDetails}
                        jiraTaskLogDetails={this.state.jiraTaskLogDetails}
                        jiraDomainSelectedId={
                          this.state.jiraDomainSelected.Id_User_Jira_Detail
                        }
                        jiraDomainSelected={
                          this.state.jiraDomainSelected.Domain_Name
                        }
                        isPrivateJiraSelected={this.state.isPrivateJiraSelected}
                        togglePrivateJiraIssue={this.togglePrivateJiraIssue}
                        jiraIssue={this.state.jiraIssue}
                        getPrivateJiraMyTaskList={this.getPrivateJiraMyTaskList}
                      />
                    ) : null}

                    {this.state.isJiraLogReport ? (
                      <JiraLogReport
                        isJiraLogReport={this.state.isJiraLogReport}
                        closeJiraLogReport={this.closeJiraLogReport}
                        accessToken={this.state.accessToken}
                      />
                    ) : null}

                    {this.state.isTaskWiseLogReport ? (
                      <TaskWiseLogReport
                        isTaskWiseLogReport={this.state.isTaskWiseLogReport}
                        closeTaskWiseLogReport={this.closeTaskWiseLogReport}
                      />
                    ) : null}
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>

        {this.state.isAddTask ? (
          <AddNewTask
            getPrivateJiraMyTaskList={this.getPrivateJiraMyTaskList}
            jiraDomainSelected={this.state.jiraDomainSelected}
            isPrivateJiraSelected={this.state.isPrivateJiraSelected}
            isToDoDescription={this.state.isToDoDescription}
            todoDescription={this.state.todoDescription}
            closeAddTask={this.closeAddTask}
            isAddTask={this.state.isAddTask}
            cloudId={this.state.cloudId}
            accessToken={this.state.accessToken}
            lastTaskProject={this.state.JiraTaskList[0]}
            getJiraYourTaskList={this.getJiraYourTaskList}
            getAccessTokenAgain={this.getAccessTokenAgain}
            refreshToken={this.state.refreshToken}
          />
        ) : null}
      </>
    );
  }
}

// Prop types of props
Jira.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
Jira.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
  };
};
export default connect(mapStateToProps, {
  getPrivateJiraTaskList,
  updateUserJiraRefreshToken,
  getUserJiraRefreshToken,
  addToDoTask,
  userToDoListFetch,
  getJiraConfig,
  getJiraDomain,
  getJiraTaskLogDetails,
  saveUserToDoFromJiraTask,
  updateJiraDomainAccess,
})(Jira);
