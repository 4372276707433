import { types as T } from '../actions/action';

const initialState = {
  userToDoListResponse: []
}

export default (state = initialState, action) => {
    switch (action.type) {
     case T.RECEIVE_USER_TODO_LIST:
      return {
        ...state,
        userToDoListResponse: action.userToDoListResponse
      } 
      
      
     default:
      return state
    }
}