import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import {
  handleError,
  handleResponse,
  checkClientErrorStatus
} from "../utils/utility";

// Reset Password
export const receiveResetPassword = (resetPasswordResponse) => ({
  type: T.RECEIVE_RESET_PASSWORD,
  resetPasswordResponse,
});

/**
 * ResetPassword
 *
 * @param {url} URL is API host domain.
 * @param {ResetPasswordRequest} is request object to signup.
 * @return {object} return user details.
 */
export const resetPassword = (url, resetPasswordRequest) => (dispatch) => {
  const finalUrl = `${url}masterauth/setPassword`;
  return trackPromise(axios.post(finalUrl, resetPasswordRequest)
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
            handleError(result,  'Failed to Reset Password')
          }
          else {
            dispatch(receiveResetPassword(result));
          }
      })
      .catch((error) =>
        handleError(error, "ReceiveResetPassword")
      )
  );
};

// Reset password valid activation key
export const receiveValidActivationKey = (validActivationKeyResponse) => ({
  type: T.RECEIVE_VALID_ACTIVATION_KEY,
  validActivationKeyResponse,
  
});

/**
 * ResetPassword valid activation key
 *
 * @param {url} URL is API host domain.
 * @param {validActivationKeyRequest} is request object to valid Activation Key.
 * @return {object} return user details.
 */
export const validActivationKey = (url, validActivationKeyRequest) => (dispatch) => {
  const finalUrl = `${url}masterauth/ValidateUserEmailActivationKey `;
  return trackPromise(axios.post(finalUrl, validActivationKeyRequest)
      .then((response) => handleResponse(response))
      .then((result) => {
        if (checkClientErrorStatus(result)) {
            handleError(result, 'Failed to Valid Activation Key')
          }
          else {
            dispatch(receiveValidActivationKey(result));
          }
      })
      .catch((error) =>
        handleError(error, "Failed to Receive Valid Activation Key")
      )
  );
};
