import { types as T } from '../actions/action';
import { defaultStore } from '../store';

const dashboardReducer = (state = defaultStore, action) => {
	switch (action.type) {
		
		case T.RECEIVE_SLACK_TEAM:
			return {...state, 
				fetchSlackTeam: {
					...state.fetchSlackTeam,
					loading: false,
					error: false,
					ErrorMessage: null,
					slackTeamResponse: action.slackTeamResponse
				},
			};


		case T.RECEIVE_SLACK_MEMBER:
			return {...state, 
				fetchSlackMember: {
					...state.fetchSlackMember,
					loading: false,
					error: false,
					ErrorMessage: null,
					slackMemberResponse: action.slackMemberResponse
				},
			};

		//Slack User List

		case T.RECEIVE_SLACK_USER:
			return {...state, 
				fetchSlackUser: {
					...state.fetchSlackUser,
					loading: false,
					error: false,
					ErrorMessage: null,
					slackUserResponse: action.slackUserResponse.Data,
				},
			};

		case T.REQUEST_GET_USER_DETAIL:
			return{...state, 
				getUserDetailResponse: {
					...state.getUserDetailResponse,
					loading: true,
					error: false,
					errorMessage: null
				},
			};

		case T.RECEIVE_GET_USER_DETAIL:
			return{...state, 
				getUserDetailResponse: {
					...state.getUserDetailResponse,
					loading: false,
					error: false,
					errorMessage: null,
					userDetailResponse: action.userDetailResponse
				},
			};

		case T.RECEIVE_IMPORT_USER_LIST:
			return {...state, 
				importUserList: {
					...state.importUserList,
					loading: false,
					error: false,
					errorMessage: null,
					importUserListResponse: action.importUserListResponse
				},
			};

		//Get Tenant Details
		
		case T.RECEIVE_GET_TENANT_DETAIL:
			return {...state,
				getTenantDetail: {
					...state.getTenantDetail,
					loading: false,
					error: false,
					errorMessage: null,
					tenantDetailResponse: action.tenantDetailResponse
				},
			};

		//Update Tenant		
		case T.RECEIVE_UPDATE_TENANT:
			return {...state,
				updateTenant: {
					...state.updateTenant,
					loading: false,
					error: false,
					ErrorMessage: null,
					updateTenantResponse: action.updateTenantResponse,
				},
			};

		//Update User
		case T.RECEIVE_UPDATE_USER:
			return{...state, 
				updateUser: {
					...state.updateUser,
					loading: false,
					error: false,
					ErrorMessage: null,
					updateUserResponse: action.updateUserResponse,
				},
			};

		case T.RECEIVE_UPDATE_PROFILE_PIC:
			return {...state, 
				updateUserProfilePic: {
					...state.updateUserProfilePic,
					loading: false,
					error: false,
					ErrorMessage: null,
					updateUserResponse: action.updateUserProfilePicResponse,
				},
			};

			//Change Password	
		case T.RECEIVE_CHANGE_PASSWORD:
			return {...state, 
				changePassword: {
					...state.changePassword,
					loading: false,
					error: false,
					ErrorMessage: null,
					changePasswordResponse: action.changePasswordResponse,
				},
			};

				//get Cabin User Detail
		case T.RECEIVE_GET_CABIN_USER_DETAIL:
			return {...state, 
				getCabinUserDetail: {
					...state.getCabinUserDetail,
					getCabinUserDetailLoading: false,
					getCabinUserDetailError: false,
					getCabinUserDetailErrorMessage: null,
					getCabinUserDetailResponse: action.getCabinUserDetailResponse,
				},
			};

			//get Cabin User Detail
		case T.RECEIVE_GET_USER_STICKY_LIST:
			return {...state, 
				getUserStickyListResponse: {
					...state.getUserStickyList,
					getUserStickyListLoading: false,
					getUserStickyListError: false,
					getUserStickyListErrorMessage: null,
					getUserStickyListResponse: action.getUserStickyListResponse,
				},
			};

			case T.RECEIVE_SET_READ_USER_STICKY_NOTE:
			return {...state, 
				setReadUserStickyResponse: {
					...state.setReadUserSticky,
					setReadUserStickyLoading: false,
		setReadUserStickyError: false,
		setReadUserStickyErrorMessage: null,
		setReadUserStickyResponse: action.setReadUserStickyResponse
				},
			};

			case T.RECEIVE_SEARCH_USER_OFFICE:
				return {...state,
					searchUserResponse: {
						...state.fetchSearchUser,
						loading: false,
						error: false,
						errorMessage: null,
						searchUserResponse: action.searchUserResponse
					}
				}

				case T.RECEIVE_ADD_STICKY_NOTES_TO_TODO:
			return {...state, 
				addStickyNotesToToDoResponse: {
					...state.addStickyNotesToToDo,
					addStickyNotesToToDoResponse: action.addStickyNotesToToDoResponse
				},
			};
			
			case T.RECEIVE_DEPARTMENT_LIST_BY_USER:
			return {...state, 
				departmentListByUserResponse: {
					...state.departmentListByUser,
					departmentListByUserResponse: action.departmentListByUserResponse
				},
			};

			case T.RECEIVE_USER_LIST_BY_DEPARTMENT:
			return {...state, 
				userListByDepartmentResponse: {
					...state.userListByDepartment,
					userListByDepartmentResponse: action.userListByDepartmentResponse
				},
			};

			case T.RECEIVE_SLACK_CONFIG_LIST:
				return {...state,
					slackConfigListResponse:{
						...state.slackConfigListResponse,
						slackConfigListResponse:action.slackConfigListResponse
					},
				};

				case T.RECEIVE_SLACK_CONFIG_LIST_LOGIN:
					return {...state,
						slackConfigListLoginResponse:{
							...state.slackConfigListLoginResponse,
							slackConfigListLoginResponse:action.slackConfigListLoginResponse
						},
					};

				//Get tenant Slack user access token
				case T.RECEIVE_TENANT_SLACK_USER_ACCESS_TOKEN:
				return {...state,
					getTenantSlackUserAccessTokenResponse:{
						...state.getTenantSlackUserAccessTokenResponse,
						getTenantSlackUserAccessTokenResponse:action.getTenantSlackUserAccessTokenResponse
					},
				};

				
				//Add Virtual Chat Group to Slack Team List
				case T.RECEIVE_ADD_VIRTUAL_CHAT_GROUP:
					return {...state, 
						addVirtualChatGroupResponse: {
							...state.addVirtualChatGroup,
							addVirtualChatGroupResponse: action.addVirtualChatGroupResponse
				},
			};

				case T.RECEIVE_USER_LEAVE_DETAIL:
				return {...state, 
					userLeaveDetailResponse: {
						...state.getUserLeaveDetail,
						userLeaveDetailResponse: action.userLeaveDetailResponse
					},
				};

				case T.RECEIVE_USERNAME_BY_USERID:
				return {...state, 
					userNameByUserIdResponse: {
						...state.getUserNameByUserId,
						userNameByUserIdResponse: action.userNameByUserIdResponse
					},
				};

				//Multiple Workspace selection
				case T.RECEIVE_MULTIPLE_EMAIL:
      return {...state, 
        fetchMultipleEmail: {
          ...state.fetchMultipleEmail,
          loading: false,
          error: false,
          errorMessage: null,
          multipleEmailResponse: action.multipleEmailResponse,
        },
      };
			
			//Change Workspace
			case T.RECEIVE_CHANGE_WORKSPACE:
      return {...state, 
        changeWorkspaceResponse: {
          ...state.changeWorkspace,
          changeWorkspaceResponse: action.changeWorkspaceResponse,
        },
      };


		default:
			return state
	}
}

export default dashboardReducer;