import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiHost } from '../../../utils/apiUrls';
import { CustomLoadingSpiner } from '../../../components/loader/customLoader';
import { slackOauthAccessAdmin, updateUserSlackAccessToken, getSlackConfigurationlist, updateTenantSlackAccessToken } from "../../../actions/dashboardActions";
import { toast } from "react-toastify";
import { getDecryptedValue, getEncryptedValue } from '../../../utils/utility';

const axios = require('axios');
const qs = require('qs');

class ImportToken extends React.Component {
  state = {
    loading: true,
    clientId: "",
    clientSecret: ""
  };

  async componentDidMount() {

    this.getSlackConfigurationlist();
  }

  slackTokenOAuth = async (code, client_Id, client_Secret) => {
    const { slackOauthAccessAdmin } = this.props;
    await slackOauthAccessAdmin(code, client_Id, client_Secret, (res) => {
      //console.log("auth res", res)
      if (res.ok === false) {
        this.props.history.push("/dashboard");
        this.setState({ loading: false })
        toast.error(res.error)
      }
      if (res.ok === true){
        
        this.getTeamInfo(res.authed_user.access_token, res.authed_user.refresh_token);

      }
      
    });
  }

  getTeamInfo = async (accessToken, refreshToken) => {

        try{

              let message = {
                token: accessToken
              };

              // let header = {
              //   // "Content-Type": "application/json",
              //   'Content-Type': 'application/x-www-form-urlencoded',
              //   "Authorization": "Bearer " + accessToken,
              // };
              //const result = await axios.post(`https://slack.com/api/team.info`, qs.stringify(message), {headers: header});

              const result = await axios.post(`https://slack.com/api/team.info`, qs.stringify(message));

              var tenantName = JSON.parse(localStorage.getItem('userdetail')).Slack_WorkSpace_Name;

              if( tenantName.replace(".slack.com", '').toLowerCase() === result.data.team.domain ){
                
                this.updateTenantSlackAccessToken(accessToken, refreshToken, result.data.team.name, result.data.team.domain);
                this.getUsersProfile(accessToken, refreshToken);
              
              }
              else{

                alert("Please select " + tenantName.replace(".slack.com", '').toLowerCase() + " workspace");
                this.props.history.push({ pathname: '/dashboard', isRedirectToSlackUrl: true })
              
              }
            
        }
        catch (error) {
          console.error(error);
        }

  }

  getSlackConfigurationlist = async () => {
    const { API_HOST, getSlackConfigurationlist } = this.props;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('code');
    await getSlackConfigurationlist(API_HOST, async(res) => {
      if (res) {
        await this.setState({ clientId: getDecryptedValue(res[0].Client_ID) , clientSecret: getDecryptedValue(res[0].Client_Secret) });
        await this.slackTokenOAuth(code, this.state.clientId, this.state.clientSecret);
      }
    });
  }

  slackTokenUpdate = async (token, refreshToken, slackId) => {
    const { API_HOST, updateUserSlackAccessToken, slackConfigListResponse } = this.props;
    //console.log("slackConfigListResponse[0].Scope_Detail",slackConfigListResponse[0].Scope_Detail);
    const slackToken = getEncryptedValue(token);
    const slackRefreshToken = getEncryptedValue(refreshToken);
    
    await updateUserSlackAccessToken(API_HOST, slackToken, slackConfigListResponse[0].Scope_Detail, slackRefreshToken, slackId, (res) => {
      if (res) {
        
        let userData = JSON.parse(localStorage.getItem('userdetail'));
        userData.Slack_Access_Token = slackToken;
        userData.Slack_Scope_Detail = slackConfigListResponse[0].Scope_Detail;
        userData.Slack_Refresh_Token = slackRefreshToken;
        //console.log("slackConfigListResponse[0].Scope_Detail: ",slackConfigListResponse[0].Scope_Detail);

        localStorage.setItem('userdetail', JSON.stringify(userData));
        this.setState({ loading: false })

      }
      this.props.history.push({ pathname: '/dashboard', isSlackImportRedirect: true })


    });
  }

  updateTenantSlackAccessToken = async (tenantSlackAccesToken, tenantSlackRefreshToken, workspaceName, workspaceDomain) => {
    const { API_HOST, updateTenantSlackAccessToken} = this.props;
    const tenantSlackToken = getEncryptedValue(tenantSlackAccesToken);
    const tenantRefreshToken = getEncryptedValue(tenantSlackRefreshToken);

    await updateTenantSlackAccessToken(API_HOST, tenantSlackToken, tenantRefreshToken, workspaceName, workspaceDomain, (res) => {
      if(res) {

        let userData = JSON.parse(localStorage.getItem('userdetail'));
        userData.Slack_WorkSpace_Name = workspaceName;
        localStorage.setItem('userdetail', JSON.stringify(userData));
      }
    });
  }

  getUsersProfile = async (accessToken, refreshToken) => {

        try{

              let message = {
                token: accessToken
              };

              const result = await axios.post(`https://slack.com/api/users.profile.get`, qs.stringify(message));

              var userEmailAddress = JSON.parse(localStorage.getItem('userdetail')).EmailId;

              if( userEmailAddress.toLowerCase().trim() === result.data.profile.email.toLowerCase().trim() ){
                  this.slackTokenUpdate(accessToken, refreshToken, "");
              }
              else{

                this.props.history.push({ pathname: '/dashboard', isSlackImportRedirect: true })
              
              }
            
        }
        catch (error) {
          console.error(error);
        }

  }


  render() {

    return (
      <CustomLoadingSpiner InProgress={this.state.loading} />
    );
  }
}

// Prop types of props.
ImportToken.propTypes = {
  API_HOST: PropTypes.string,
  slackConfigListResponse: PropTypes.any,
  getSlackConfigurationlist: PropTypes.func
};

// Set default props.
ImportToken.defaultProps = {
  slackConfigListResponse: [],
  getSlackConfigurationlist: () => { }
};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
    slackConfigListResponse: state.dashboardReducer.slackConfigListResponse.slackConfigListResponse
  };
};
export default connect(mapStateToProps, { getSlackConfigurationlist, slackOauthAccessAdmin, updateUserSlackAccessToken, updateTenantSlackAccessToken })(ImportToken);
