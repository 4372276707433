import axios from "axios";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

export const addSearchTag = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}tags/savetags`;
    axios.post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (result === true) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};

export const getSearchTags = (url, userId, callback) => (dispatch) => {
    const finalUrl = `${url}tags/gettagsbyid/${userId}`;
    axios.post(finalUrl, {}, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};

export const deleteSearchTag = (url, id, callback) => (dispatch) => {
    const finalUrl = `${url}tags/deletetag/${id}`;
    axios.post(finalUrl, {}, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (result === true) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};