import axios from "axios";
//import { trackPromise } from "react-promise-tracker";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

export const unsubscribeEmail = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}masterauth/unsubscribeemail`;
    axios.post(finalUrl, reqBody, getConfig())
    .then(response =>
      handleResponse(response)
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, ' Failed to unsubscribe')
    )
};

export const subscribeEmail = (url, reqBody, callback) => (dispatch) => {
  const finalUrl = `${url}masterauth/subscribeEmail`;
  axios.post(finalUrl, reqBody, getConfig())
    .then(response =>
      handleResponse(response)
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, 'Failed to subscribe')
    )
};

// Get unsubscribe Email id
export const getUnsubscribeEmailId = (url, emailKey, callback) => (dispatch) => {
  const finalUrl = `${url}masterauth/GetUnsubscribeEmailId/${emailKey}`;
  axios.post(finalUrl, {}, getConfig())
    .then(response =>
      handleResponse(response)
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, ' Failed to get email id')
    )
};