export const defaultStore = {

	// LOGIN RESPONSE
	loginResponse: {
		loading: false,
		loginError: false,
		loginErrorMessage: null,
		loggedInResponse: null
	},

	// SIGNUP RESPONSE
	signupResponse: {
		loading: false,
		signupError: false,
		signupErrorMessage: null,
		signupResponse: null
	},

	// CREATE OFFICE RESPONSE
	officeResponse: {
		loading: false,
		loginError: false,
		loginErrorMessage: null,
		officeResponse: null
	},

	// FETCH OFFICE RESPONSE
	fetchOfficeResponse: {
		fetchOfficeLoading: false,
		fetchOfficeError: false,
		fetchOfficeErrorMessage: null,
		fetchOfficeResponse: []
	},

	// SLACK CONFIG RESPONSE
	slackconfigResponse: {
		loading: false,
		loginError: false,
		loginErrorMessage: null,
		slackconfigResponse: null
	},

	//MULTIPLE EMAILS
	fetchMultipleEmail: {
		loading: false,
		error: false,
		errorMessage: null,
		multipleEmailResponse: []
	},

	//MULTIPLE WORKSPACE
	fetchMultipleWorkspace: {
		loading: false,
		error: false,
		errorMessage: null,
		multipleWorkspaceResponse: []
	},

	//Change Workspace
	changeWorkspaceResponse: {
		changeWorkspaceResponse: null
	},

	//FETCH SLACK TEAM
	fetchSlackTeam: {
		loading: false,
		error: false,
		errorMessage: null,
		slackTeamResponse: []
	},

	//FETCH SLACK MEMBER
	fetchSlackMember: {
		loading: false,
		error: false,
		errorMessage: null,
		slackMemberResponse: []
	},

	//FETCH SLACK USER
	fetchSlackUser: {
		loading: false,
		error: false,
		errorMessage: null,
		slackUserResponse: []
	},

	//FETCH USER DETAIL
	getUserDetailResponse: {
		loading: false,
		error: false,
		errorMessage: null,
		userDetailResponse: null
	},

	//FETCH USER LIST
	importUserList: {
		loading: false,
		error: false,
		errorMessage: null,
		importUserListResponse: false
	},

	//GET TENANT
	getTenantDetail: {
		loading: false,
		error: false,
		errorMessage: null,
		//updateTenantResponse: []
		tenantDetailResponse: []
	},

	//UPDATE TENANT
	updateTenant: {
		loading: false,
		error: false,
		errorMessage: null,
		//tenantDetailResponse: []
		updateTenantResponse: []
	},

	//UPDATE USER
	updateUser: {
		loading: false,
		error: false,
		errorMessage: null,
		updateUserResponse: []
	},

	//UPDATE USER PROFILE PIC
	updateUserProfilePic: {
		loading: false,
		error: false,
		errorMessage: null,
		updateUserProfilePicResponse: []
	},

	//UPDATE Forgot Password
	forgotPassword: {
		loading: false,
		error: false,
		errorMessage: null,
		forgotPasswordResponse:false
	},
	
	//UPDATE Forgot Password
	resetPassword: {
		loading: false,
		error: false,
		errorMessage: null,
		resetPasswordResponse:false
	},

	//Validate Activation Key
	validActivationKey: {
		loading: false,
		error: false,
		errorMessage: null,
		validActivationKeyResponse:false
	},

	//UPDATE Forgot Password
	changePassword: {
		loading: false,
		error: false,
		errorMessage: null,
		changePasswordResponse:false
	},

	//get Cabin list Response
	cabinListResponse:{
		loading: false,
		error: false,
		errorMessage: null,
		cabinListResponse: []
	},

	//edit Cabin Room Name Response
	editCabinRoomNameResponse:{
		editCabinNameLoading: false,
		editCabinNameError: false,
		editCabinNameErrorMessage: null,
		editCabinRoomNameResponse: []
	},

	//save cabin user details
	cabinUserDetailsResponse: {
		cabinUserDetailsResponse: null
	},

	loggedInUserCabinDetailsResponse: {
		loggedInUserCabinDetailsLoading: false,
		loggedInUserCabinDetailsError: false,
		loggedInUserCabinDetailsErrorMessage: null,
		loggedInUserCabinDetailsResponse:null
	},

	//Save User Knock
	saveUserKnockResponse: {
		saveUserKnockResponse: null
	},

	doorOpenResponse:{
		doorOpenLoading: false,
		doorOpenError: false,
		doorOpenErrorMessage: null,
		doorOpenResponse:false
	},

	doorCloseResponse:{
		doorCloseLoading: false,
		doorCloseError: false,
		doorCloseErrorMessage: null,
		doorCloseResponse:false
	},

	receiveStickyMessageSendResponse:{
		receiveStickyMessageSendLoading: false,
		receiveStickyMessageSendError: false,
		receiveStickyMessageSendErrorMessage: null,
		receiveStickyMessageSendResponse:null

	},
	
	//User Knock List Cabin Wise
	userKnockListCabinWiseResponse: {
		userKnockListCabinWiseResponse: []
	},

	//Set User Knock Seen
	userKnockSeenResponse: {
		userKnockSeenResponse: false
	},

	//Set User Knock Seen
	userStickySeenResponse: {
		userStickySeenResponse: false
	},

	//Sticky Count
	stickyCountResponse: {
		stickyCountResponse: []
	},

	//Get Sticky Notes list Cabin wise
	getCabinUserStickyListResponse: {
		getCabinUserStickyListResponse: []
	},

	getUserStickyListResponse: {
		getUserStickyListLoading: false,
		getUserStickyListError: false,
		getUserStickyListErrorMessage: null,
		getUserStickyListResponse: null
	},

	setReadUserStickyResponse:{
		setReadUserStickyLoading: false,
		setReadUserStickyError: false,
		setReadUserStickyErrorMessage: null,
		setReadUserStickyResponse: false
	},

	searchUserResponse:{
		loading: false,
		error: false,
		errorMessage: null,
		searchUserResponse:[]
	},

	//Add Sticky Notes to To Do
	addStickyNotesToToDoResponse:{
		addStickyNotesToToDoResponse: null
	},

	//Get department list by user
	departmentListByUserResponse: {
		departmentListByUserResponse:[]
	},

	//Get user list by department
	userListByDepartmentResponse: {
		userListByDepartmentResponse:[]
	},

	//Get Slack Config List
	slackConfigListResponse:{
		slackConfigListResponse:[]
	},

	//Get Slack Config List Login
	slackConfigListLoginResponse:{
		slackConfigListLoginResponse:[]
	},

	//Get tenant Slack user access token
	getTenantSlackUserAccessTokenResponse:{
		getTenantSlackUserAccessTokenResponse: null
	},

	//Add Virtual Chat Group to Slack Team
	addVirtualChatGroupResponse: {
		addVirtualChatGroupResponse: null
	},

	//Get user leave detail for broadcast
	userLeaveDetailResponse: {
		userLeaveDetailResponse: null
	},

	//Get Username by Userid
	userNameByUserIdResponse: {
		userNameByUserIdResponse: null
	},

}

export default Object.freeze(defaultStore);	