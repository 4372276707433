import { types as T } from '../actions/action';

const initialState = {
    meetingResponse: []
}

export default (state = initialState, action) => {
    switch (action.type) {
     case T.RECEIVE_MEETING_LIST:
      return {
        ...state,
        meetingResponse: action.meetingResponse
      }   
     default:
      return state
    }
}