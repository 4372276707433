import React from 'react';
import { Button, FormGroup } from 'reactstrap';
import {
  unsubscribeEmail,
  getUnsubscribeEmailId,
} from '../../../actions/subscribeActions';
import { connect } from 'react-redux';
import { getApiHost } from '../../../utils/apiUrls';
import PropTypes from 'prop-types';
import {
  getDecryptedValue,
  getEncryptedValue,
} from '../../../utils/utility';
import logo from '../../../assets/images/logo.png';

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: '',
      message: '',
      disabled: false,
      IsUnsubscribe: false,
    };
  }

  async componentDidMount() {
    this.getUnsubscribeEmailId(this.props.match.params.emailIdKey);
  }

  unsubscribeEmail = async () => {
    const { API_HOST, unsubscribeEmail } = this.props;
    let reqBody = {
      EmailId: getEncryptedValue(this.state.emailId),
      Reason: this.state.message,
    };
    await unsubscribeEmail(API_HOST, reqBody, (res) => {
      if (res) {
        this.props.history.push({
          pathname: `/subscribe/${this.props.match.params.emailIdKey}`,
        });
      }
    });
  };

  getUnsubscribeEmailId = async (emailkey) => {
    const { API_HOST, getUnsubscribeEmailId } = this.props;
    await getUnsubscribeEmailId(API_HOST, emailkey, (res) => {
      if (res) {
        this.setState({
          emailId: res.EmailId,
          IsUnsubscribe: res.IsUnsubscribe,
        });
      }
    });
  };

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { disabled, emailId } = this.state;
    //const userEmail = getDecryptedValue(this.props.match.params.emailId);
    return (
      <div className="email-tem-content">
        <div className="logo-wrap">
          <img src={logo} className="img-fluid" alt="" />
        </div>

        {this.state.IsUnsubscribe ? (
          <div className="container">
            <div className="card mx-auto">
              <h2 className="mb-3">You have already unsubscribed..!!</h2>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="card mx-auto">
              <h1 className="mb-3">Unsubscribe</h1>

              {/* <h3>Complete Your ##TenantName## Registration</h3> */}

              <div className="email-content mb-3 mt-3">
                <p>Hey,</p>
                <p>
                  We are sorry to see you go, no hard feelings, hopefully we
                  will see you soon back one day.
                </p>
              </div>

              <FormGroup>
                <label>E-mail Address: </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  aria-describedby="email"
                  name="email"
                  required
                  value={emailId}
                  disabled
                  pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
                />
              </FormGroup>

              <FormGroup>
                <label>Unsubscribe reason: </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="message"
                  aria-describedby="message"
                  placeholder="Write unsubscribe reason"
                  name="message"
                  value={this.state.message}
                  onChange={(e) => this.handleOnChange(e)}
                ></textarea>
              </FormGroup>

              <Button
                onClick={this.unsubscribeEmail}
                color="primary"
                className="btn btn-primary"
                type="submit"
                disabled={disabled}
              >
                <span>Unsubscribe</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// Prop types of props.
Unsubscribe.propTypes = {
  API_HOST: PropTypes.string,
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
});

export default connect(mapStateToProps, {
  unsubscribeEmail,
  getUnsubscribeEmailId,
})(Unsubscribe);
