import axios from "axios";
//import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

//Get User ToDo List
export const receiveUserToDoList = (userToDoListResponse) => ({
  type: T.RECEIVE_USER_TODO_LIST,
  userToDoListResponse,
});

/**
 * Get User To Do List.
 *
 * @param {url} URL is API host domain.
*/

export const userToDoList = (url, userId) => dispatch => {
  const finalUrl = `${url}Usertodo/getusertodolistuseridwise/0`;  
  //return trackPromise(
    axios
      .post(finalUrl, {}, getConfig())
      .then(response => handleResponse(response))
      .then(result => {
        dispatch(receiveUserToDoList(result));
      })
      .catch(error =>
      handleError(error, 'Failed to get ToDo List ')
    )
  //);
}

export const userToDoListFetch = (url, userId, callback) => dispatch => {
  const finalUrl = `${url}Usertodo/getusertodolistuseridwise/${userId}`;  
  //return trackPromise(
    axios
      .post(finalUrl, {}, getConfig())
      .then(response => handleResponse(response))
      .then(result => {
        dispatch(receiveUserToDoList(result));
        if(!!callback){
          callback(result)
        }
      })
      .catch(error =>
      handleError(error, 'Failed to get ToDo List ')
    )
  //);
}

/**
 * Set User task from To Do list as Done
 *
 * @param {url} URL is API host domain.
*/

export const setDoneUserToDo = (url, userToDoId,callback) => dispatch => {
  const finalUrl = `${url}Usertodo/setdoneusertodo/${userToDoId}`;  
  //return trackPromise(
    axios
      .post(finalUrl, {}, getConfig())
      .then(response => handleResponse(response))
      .then(result => {
       if(!!callback){
          callback(result);
       }
      })
      .catch(error =>
      handleError(error, 'Failed to set Task as Done ')
    )
 // );
}


/**
 * Add Task to ToDo
 *
 * @param {url} URL is API host domain.
*/
export const addToDoTask = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}Usertodo/saveusertodo`;

  axios.post(finalUrl, requestBody, getConfig())
      .then(response => {
          return handleResponse(response)
      }
      )
      .then(result => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch(error =>
          handleError(error, 'Failed to add Task to To Do List')
      )
}


export const saveReminder = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}Usertodo/SetReminder`;

  axios.post(finalUrl, requestBody, getConfig())
      .then(response => {
          return handleResponse(response)
      }
      )
      .then(result => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch(error =>
          handleError(error, 'Failed to set reminder')
      )
}

export const getReminderList = (url, requestBody, callback) => (dispatch) => {

  const finalUrl = `${url}Usertodo/GetUserReminderListBetweenDate`;

  axios.post(finalUrl, requestBody, getConfig())
      .then(response => {
          return handleResponse(response)
      }
      )
      .then(result => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch(error =>
          handleError(error, 'Failed to get reminder list')
      )
}


// Cancel Reminder
export const cancelReminder = (url, requestBody, callback) => (dispatch) => {

  const finalUrl = `${url}UserToDo/cancelreminder`;

  axios.post(finalUrl, requestBody, getConfig())
      .then(response => {
          return handleResponse(response)
      }
      )
      .then(result => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch(error =>
          handleError(error, ' Failed to cancel Reminder')
      )
}


//Get Reminder Notification List
export const getReminderNotification = (url, callback) => (dispatch) => {
  const finalUrl = `${url}UserToDo/getusertodonotificationlist/0`;

  axios.post(finalUrl,{}, getConfig())
    .then(response => {
      return handleResponse(response)
    }
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, ' Failed to get Reminder list')
    )
}

//Update user ToDo after adding to the Jira
export const updateUserToDoToJira = (url, requestBody, callback) => (dispatch) => {
  const finalUrl = `${url}UserToDo/UpdateUserToDoToJiraByIdUserToDo`;

  axios.post(finalUrl, requestBody, getConfig())
    .then(response => {
      return handleResponse(response)
    }
    )
    .then(result => {
      if (!!callback) {
        callback(result)
      }
    })
    .catch(error =>
      handleError(error, ' Failed to get Update user todo list')
    )
}