import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getDepartmentFilterWise, updateDepartmentOrder, editDeptName, getUserListDepartmentWithSelectedHrsFilter, saveVirtualRoom, getVirtualRoom, deleteVirtualRoom, doorClose, setUserCustomStatus } from "../../../actions/mainOfficeActions";
import { getUpdatedDepartments, getUserListOfDepartment } from "../../../actions/departmentActions";
import { getUserDetail, fetchSlackTeam } from "../../../actions/dashboardActions";
import { getApiHost, socket, slack_socket } from "../../../utils/apiUrls";
import Slider from './Slider';
import { toast } from "react-toastify";
import SelectedUsers from './SelectedUsers';
import EdiText from 'react-editext';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import ScheduleMeeting from '../../../components/google_calendar/ScheduleMeeting';
import { getDecryptedValue } from "../../../utils/utility";
import ReactTooltip from "react-tooltip";
import AddEditDepartment from './../../dashboard/components/AddEditDepartment';
import $ from 'jquery';
import CreateDepartmentPopup from './CreateDepartmentPopup';
import Avatar from 'avataaars';
import { getUserListOfDepartmentWithCallback } from '../../../actions/departmentActions';

class OfficeLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            departmentId: this.props.departmentId,
            SearchbarUserId: this.props.SearchbarUserId,
            selectedUsers: [],
            departmentOrder: [],
            departmentList: [],
            departmentListwithhours: [],
            scrollPosition: 0,
            leftSideExpand: false,
            sheduleModal: false,
            title: '',
            selectedOption: null,
            MeetingId: 0,
            token: '',
            startDate: new Date(),
            endDate: new Date(),
            userCardToRemove:0,
            divStyle:"",
            slackTeamResponse: this.props.slackTeamResponse,
            onAddSelected:false,
            isCreateDepartmentPopup:false,
            newCreatedDepartmentId:0,
            virtualRoomResponse: [],
            userList: []
        }
    }

    async componentDidMount() {
        var userData = localStorage.getItem('userdetail');
        await this.getDepartments();
        await this.getUserListOfDepartment();
         this.getVirtualRoom();
        

        if (this.props.timeFilter > 0) {
            await this.getUserListDepartmentWithSelectedHrsFilter(this.props.timeFilter);
        }
        socket.on("editDepartmentName", data => {
            this.editDepartmentName(data)
        })
        socket.on("knockDoor", data => {
            this.knockDoor(data)
        });
        socket.on("updateUserDepartment", data => {
            this.updateUserDepartment(data);
        });
        socket.on("addNewDept", data => {
            console.log("Slider socket on");
            this.addNewDeptData(data);
        });
        socket.on("virtualRoom", data => {
            console.log("17 Virtual");
            this.getVirtualRoom();
            this.setState({ virtualRoomResponse: data });
            
        });

        ////////////////////
        slack_socket.on('updateChannelList', (event) => {
            //console.log("Client side create channel Event: ", event);

            var userData = localStorage.getItem('userdetail');
            var userSlackId = getDecryptedValue(JSON.parse(userData).Id_SlackUser);
            
            let temp = [];
            for (let i = 0; i < event.members.length; i++) {
                temp.push(event.members[i]);
                
            }
            if (temp.includes(userSlackId + "")) {
                this.fetchSlackTeam(this.state.showChannels);
                toast.info("You've been added to a new group chat.");
            }
        });
        this.screenUpdate(userData)
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.departmentListFilterWiseResponse !== nextProps.departmentListFilterWiseResponse) {
            if (this.props.timeFilter === 0) {
                if (JSON.parse(localStorage.getItem('userdetail')).Dashboard_DepartmentOrder !== "") {
                    let order_of_array = JSON.parse(localStorage.getItem('userdetail')).Dashboard_DepartmentOrder
                    let ordered_array = this.mapOrder(nextProps.departmentListFilterWiseResponse, JSON.parse("[" + order_of_array + "]"), 'Id')
                    this.setState({ departmentList: ordered_array, departmentOrder: JSON.parse("[" + order_of_array + "]") })

                }
                else {
                    this.setState({ departmentList: nextProps.departmentListFilterWiseResponse })
                    let departmentOrder = this.addItem(nextProps.departmentListFilterWiseResponse)
                    this.setState({ departmentOrder: departmentOrder })
                }
            }
        }

        if (this.props.virtualRoomResponse !== nextProps.virtualRoomResponse) {
            // console.log("this.props:  ", this.props.virtualRoomResponse);
            // console.log("next.props:  ", nextProps.virtualRoomResponse);

            //this.updateVirtualRoomDetails(nextProps.virtualRoomResponse);
            this.setState({ virtualRoomResponse: nextProps.virtualRoomResponse })
        }

    }

    componentDidUpdate(prevProps) {
        if(prevProps.slackTeamResponse !== this.props.slackTeamResponse){
            this.setState({slackTeamResponse: this.props.slackTeamResponse});
        }

        if (prevProps.departmentId !== this.props.departmentId) {
            this.setState({
                departmentId: this.props.departmentId,
            })
            if (this.state.departmentId && this.state.departmentId !== null) {
                this.scrollDepartment(this.state.departmentId)
            }
        }
        if (prevProps.SearchbarUserId !== this.props.SearchbarUserId) {
            this.setState({
                SearchbarUserId: this.props.SearchbarUserId,
            })
        }
        if (prevProps.timeFilter !== this.props.timeFilter) {
            if (this.props.timeFilter > 0) {
                this.setState({ selectedUsers: [] })
                this.getUserListDepartmentWithSelectedHrsFilter(this.props.timeFilter);
            }
            else {
                this.setState({ departmentList: this.props.departmentListFilterWiseResponse })
                this.getDepartments();
            }
        }
        if (prevProps.userListDepartmenttWithSelectedHrsFilterResponse !== this.props.userListDepartmenttWithSelectedHrsFilterResponse) {
            if (this.props.timeFilter > 0) {
                this.distinctDepartmentforHrs(this.props.userListDepartmenttWithSelectedHrsFilterResponse)
                // let departmentList=
                // this.setState({departmentList:this.props.userListDepartmenttWithSelectedHrsFilterResponse})
            }
        }

        if (prevProps.leftSideExpand !== this.props.leftSideExpand) {
            this.setState({ leftSideExpand: this.props.leftSideExpand })
        }
        // if(prevProps.userListDepartmentFilterResponse !== this.props.userListDepartmentFilterResponse){
        //     if(this.props.timeFilter===0){
        //     this.setState({userList:this.props.userListDepartmentFilterResponse})
        //     }
        // }

        if (prevProps.getUserDetailResponse !== this.props.getUserDetailResponse) {
            // this.updateUserDetail(this.props.getUserDetailResponse);   
         }

         if(prevProps.virtualRoomResponse !== this.props.virtualRoomResponse) {
            this.setState({ virtualRoomResponse:this.props.virtualRoomResponse });
            //console.log(".props: ", this.props.virtualRoomResponse);
         }
    }

    screenUpdate(userData) {
        var moment = require('moment')
        var officeStartTime = JSON.parse(userData).UserStartTime_EndTimeVM.Start_DateTime;
        var officeEndTime = JSON.parse(userData).UserStartTime_EndTimeVM.End_DateTime;
        const eventSource = new EventSource(process.env.REACT_APP_SOKET_URL + "/events?officeStartTime=" + officeStartTime + "&officeEndTime=" + officeEndTime);
        eventSource.onmessage = (e) => {
            const messageData = JSON.parse(e.data);
            console.log(messageData)
            if (moment(new Date()).format("hh:mm") === moment(messageData.officeStartTime).format("hh:mm") || moment(new Date()).format("hh:mm") === moment(messageData.officeEndTime).format("hh:mm")) {
                this.props.getUserListDepartmentUpdateOfficeTime();
            }
        };
    }

    getUserListOfDepartment = async () => {
        const { API_HOST, getUserListOfDepartmentWithCallback } = this.props;

        const requestBody = {
            
            SearchCriteria: {
              Id_User: 0,
              "Id_Department": 0
            }
          };

        this.setState({ loading: true })
        await getUserListOfDepartmentWithCallback(API_HOST, requestBody, (res) => {
            if(res){
                this.setState({ userList: res })
            }
            
        });
    }

    distinctDepartmentforHrs = (userListDepartmenttWithSelectedHrs) => {
        const result = [];
        const map = new Map();
        for (const item of userListDepartmenttWithSelectedHrs) {
            if (!map.has(item.Id_Department)) {
                map.set(item.Id_Department, true);    // set any value to Map
                result.push(
                    item
                );
            }
        }
        this.setState({ departmentListwithhours: result, userListWithHrs: userListDepartmenttWithSelectedHrs })
    }

    editDepartmentName(data) {
        let newData = this.state.departmentList.map(item => {
            if (item.Id === data.id) {
                item.Name = data.name
            }
            return item;
        });

        this.setState(Object.assign({}, { departmentList: newData }));
    }

    knockDoor(data) {
        var userData = localStorage.getItem('userdetail');
        var userId = JSON.parse(userData).Id_User;
        if (userId === data.userId) {
            var sound = new Audio(data.doorKnock);
            sound.autoplay = true;
            sound.play();
        }
    }

    //Update User Departments
    updateUserDepartment(data) {
        let temp = data.updatedptData;
        this.getUpdatedDepartments();
        for (let item of temp) {
            this.getUserListOfDepartment(item.Id_Department);
            if (item.Id_User === JSON.parse(localStorage.getItem('userdetail')).Id_User) {
                this.getUserDetail(item.Id_User);
            }
            // let previousDepartment = userList.filter(user => user.Id_Department === this.props.departmentId)
        }
        this.getUserListOfDepartment(data.dptId)
    }

    addNewDeptData = (data) => {
        let temp = this.state.departmentList.filter(a => a.Id == data.Id_Department);
        
        if(temp.length === 0){

            let deptArray =  {
                Id: data.Id_Department,
                Name: data.Department_Name,
                OtherContent: "0|2",
                IsWaterCooler: false,
                UsageFrequency: 0,
                Id_department: 0
    
                }

            let newList = this.state.departmentList.concat(deptArray);
            this.setState({
                departmentList: newList
            })
        }
    }

    getUpdatedDepartments = async () => {
        const { API_HOST, getUpdatedDepartments } = this.props;
        const requestBody = {
            "Id_User": JSON.parse(localStorage.getItem('userdetail')).Id_User,
            "Id_Department": 0
        }
        await getUpdatedDepartments(API_HOST, requestBody);
    }

    getUserListOfDepartment = async (dptId) => {
        const { API_HOST, getUserListOfDepartment } = this.props;
        const requestBody = {
            // "Id_User" : 0,
            "Id_Department": dptId
        }

        // const requestBody = {
        //     IsPagging: true,
        //     PageNo: 1,
        //     PageSize: 10,
        //     SortingParameters: [
        //       {
        //         Property: "",
        //         Direction: 0
        //       }
        //     ],
        //     SearchCriteria: {
        //       Id_User: 0,
        //       Id_Department: dptId
        //     }
        //   };


        await getUserListOfDepartment(API_HOST, requestBody);
    }

    getUserDetail = async (id) => {
        const { API_HOST, getUserDetail } = this.props;
        await getUserDetail(API_HOST, id);
    };

    //Get Departments
    getDepartments = () => {
        const { API_HOST, getDepartmentFilterWise } = this.props;
        const requestBody = {
            "Id_User": JSON.parse(localStorage.getItem('userdetail')).Id_User,
            "Id_Department": 0
        }
        getDepartmentFilterWise(API_HOST, requestBody);
        if (JSON.parse(localStorage.getItem('userdetail')).Dashboard_DepartmentOrder !== "") {
            let order_of_array = JSON.parse(localStorage.getItem('userdetail')).Dashboard_DepartmentOrder
            let ordered_array = this.mapOrder(this.props.departmentListFilterWiseResponse, JSON.parse("[" + order_of_array + "]"), 'Id')
            this.setState({ departmentList: ordered_array, departmentOrder: JSON.parse("[" + order_of_array + "]") })

        }
        else {
            this.setState({ departmentList: this.props.departmentListFilterWiseResponse })
            let departmentOrder = this.addItem(this.props.departmentListFilterWiseResponse)
            this.setState({ departmentOrder: departmentOrder })
        }

    };

    addItem = departmentList => {
        const departmentOrder = []
        departmentList.map(department => (
            departmentOrder.push(department.Id)
        ))
        return departmentOrder;
    }

    scrollDepartment = (id) => {
        let targetElement = document.getElementById('scroll' + id)
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    // This Function Used to Select Users For Meeting
    getUserFromList = async (id, selectUser) => {
        let temp = [selectUser];
        var checkUser = this.state.selectedUsers.filter(user => user.Id_User === id)
        if (checkUser.length === 0) {
            await this.setState({
                selectedUsers: [...this.state.selectedUsers, ...temp],
            })
        }
        this.props.getSearcbarUser(id);
        //////
        this.props.getSelectedUsers(temp, this.state.selectedUsers);
        //console.log("Selected Users in Function: ", this.state.selectedUsers);
    }

    // Used to Remove Selected Users
    selectUserRemove = (id) => {
        var selectUserRemove = this.state.selectedUsers.filter(user => user.Id_User !== id)
        this.setState({ selectedUsers: selectUserRemove })
        //////
        var userRemoved = this.state.selectedUsers.filter(user => user.Id_User === id)
        console.log("userRemoved",userRemoved)
        console.log("after remove selected users",selectUserRemove)
        this.props.removeSelectedUsers(userRemoved[0].Id_SlackUser, userRemoved[0].Id_User, userRemoved[0].Real_Name, selectUserRemove);
        //console.log(":", userRemoved);
    }

    // To Display Message User Busy
    busyMessage = (name) => {
        toast.info("Sorry, " + name + " is not available at the moment.");
    }

    updateDepartmentOrder = (departmentOrder) => {
        const { API_HOST, updateDepartmentOrder } = this.props;
        const requestBody = {
            "dashboard_departmentorder": departmentOrder
        }
        updateDepartmentOrder(API_HOST, requestBody, (res) => {
            if (res) {
                var userData = JSON.parse(localStorage.getItem('userdetail'));
                userData.Dashboard_DepartmentOrder = departmentOrder;
                localStorage.setItem('userdetail', JSON.stringify(userData));
                let order_of_array = JSON.parse("[" + departmentOrder + "]");
                this.setState({ departmentOrder: order_of_array })
            }
        })
    }

    mapOrder = (array, order, key) => {
        array.sort(function (a, b) {
            var A = a[key], B = b[key];
            if (order.indexOf(A) > order.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }
        });
        return array;
    }


    swapBoxes = (fromBox, toBox) => {
        let boxes = this.state.departmentList.slice();
        let fromIndex = -1;
        let toIndex = -1;

        for (let i = 0; i < boxes.length; i++) {
            if (boxes[i].Id === fromBox.id) {
                fromIndex = i;
            }
            if (boxes[i].Id === toBox.id) {
                toIndex = i;
            }
        }


        if (fromIndex !== -1 && toIndex !== -1) {
            let { fromId, ...fromRest } = boxes[fromIndex];
            let { toId, ...toRest } = boxes[toIndex];
            boxes[fromIndex] = { id: fromBox.id, ...toRest };
            boxes[toIndex] = { id: toBox.id, ...fromRest };
            this.setState({ departmentList: boxes });
            let departmentOrder = this.addItem(boxes).join();
            this.updateDepartmentOrder(departmentOrder);
        }
    };

    handleDragStart = data => event => {
        event.stopPropagation();
        let fromBox = JSON.stringify({ id: data.id });
        //event.target.style.opacity = "1";
        //event.target.style.background="rgba(28,63,170, .3)";
        //event.dataTransfer.setData("removeId", event.target.id);
        event.dataTransfer.setData("dragContent", fromBox);
    };

    handleDragOver = data => event => {
        event.preventDefault(); // Necessary. Allows us to drop.
        event.stopPropagation();
        return false;
    };

    handleDrop = data => event => {
        event.preventDefault();
        //var idtoRemoveCss = event.dataTransfer.getData("removeId");
        //document.getElementById(idtoRemoveCss).style.background = "";
        let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
        let toBox = { id: data.id };
        this.swapBoxes(fromBox, toBox);
        return false;
    };

    //Edit Department Name
    editDeptName = (dept_Id, val) => {
        const { API_HOST, editDeptName } = this.props;
        const editDeptNameRequest = {
            "id": dept_Id,
            "name": val,
        };

        editDeptName(API_HOST, editDeptNameRequest);
        socket.emit("editDepartmentName", editDeptNameRequest);
    };

    getUserListDepartmentWithSelectedHrsFilter = (hrs) => {
        const { API_HOST, getUserListDepartmentWithSelectedHrsFilter } = this.props;
        const requestBody = {
            "IsPagging": true,
            "PageNo": 1,
            "PageSize": 10,
            "SortingParameters": [
                {
                    "Property": "",
                    "Direction": 0
                }
            ],
            "SearchCriteria": {
                "Id_User": 0,
                "Id_Department": 0,
                "Selected_Hrs": hrs

            }
        }
        getUserListDepartmentWithSelectedHrsFilter(API_HOST, requestBody);
    }

    // selectedUsersSize = () =>{
    //     if(this.state.selectedUsers.length===1 || this.state.selectedUsers.length===1){
    //         return "col-xs-3"
    //     }
    //     else
    //     if(this.state.selectedUsers.length===3){
    //         return "col-xs-5"
    //     }
    //     else
    //     if(this.state.selectedUsers.length===4){
    //         return "col-xs-8"
    //     }
    //     else{         
    //         return "col-xs-10"
    //     }    
    // }  

    sheduleMeeting = () => {
        let options = [];
        let data = this.state.selectedUsers;
        data.map(obj => {
            if (obj.EmailId !== '' && obj.Real_Name !== '') {
                options.push({ value: obj.Id_User, email: obj.EmailId, label: obj.Real_Name })
            }
            return options;
        })
        this.setState({
            sheduleModal: true,
            selectedOption: options
        })
    }

    meetingFlagSet = () => {
        this.setState({
            sheduleModal: false,
            selectedUsers: []
        })
    }

    clearSelectedUsers = () => {
        this.setState({
            selectedUsers: []
        })
        this.props.clearSelectedUserGroupName();
    }

    highlight5 = () => {
        $('#highlight4').hide();
        $('.trans-bg').hide();
        $('#highlight4 + div').removeClass("cabin-highlight");
        this.props.updateUserTour();
    }

    chatOpen = () => {
        // this.props.chatOpen();
        this.props.onClickUsersSelectedChat();
        console.log("RightSideBar Chat open");
      };

    onEnterUserCard = (userId) => {
        this.setState({userCardToRemove:userId})
    }

    onLeaveUserCard= () =>{
        this.setState({userCardToRemove:0})
    }
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //first code
    // fetchSlackTeam = (deptId) => {
    //     const { API_HOST, fetchSlackTeam } = this.props;
    //       fetchSlackTeam(API_HOST, false, (res) => {
    //          if (res){

    //             this.setState({ slackTeamResponse: res });
    //             let deptContent = this.state.slackTeamResponse.find( slackTeam => slackTeam.Id_department === deptId )
                
    //             $('#chatWindow').addClass('show').css('display', 'block !important');
    //             $('#teamlistLi' + deptContent.OtherContent).addClass('current');
    //          }
    //      });
      
        
    // }

    // createDepartmentChat = async (deptId) => {
       
    //     let userDetails = JSON.parse(localStorage.getItem('userdetail'));
        
    //     if(userDetails.Slack_Access_Token === ""){
    //         this.props.configureSlack();
    //         toast.info("Please configure your Slack account to create a new chat group.");
    //     }
    //     else{
    //         const { API_HOST, createDepartmentChat } = this.props;
    //         await createDepartmentChat(API_HOST, deptId, (res) => {
    //         if (res) {
    //             this.fetchSlackTeam(deptId);
    //         }
    //         });
    //     }
        
    // }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    createDeptChat =(deptId) => {
         this.props.createDepartmentChat(deptId);
    }

    onAddDepartment = () =>{
        this.setState({
            onAddSelected: true
          })
    }

    onAddDepartmentPopupClose = () => {
        this.setState({
          onAddSelected: false
        })
      }

      createDepartmentPopup = (id) => {
        this.setState({
            isCreateDepartmentPopup: true,
            newCreatedDepartmentId:id
          })
    }

    closeCreateDepartmentPopup = (val) =>{
        this.setState({
            isCreateDepartmentPopup: false
          })
          if(val){
             this.props.addnewMember(this.state.newCreatedDepartmentId)
          }
    }

    saveVirtualRoom = async() => {
        const { API_HOST, saveVirtualRoom, getVirtualRoom } = this.props;
        var userData = JSON.parse(localStorage.getItem('userdetail')).Id_User;
        const userIdResult = [{"Id_User":userData}];
        const map = new Map();
        var vrSelectedUsers = this.state.selectedUsers;
        

        for (const item of vrSelectedUsers) {
            if (!map.has(item.Id_User)) {
              map.set(item.Id_User, true);
      
              if (!userIdResult.includes(item.Id_User)) {
                userIdResult.push({
                  Id_User: item.Id_User
                });
              }
            }
          }

        const requestBody = {
                "Id":0,
                "Name":"",
                "VRoomDetailList": userIdResult
            }
        await saveVirtualRoom(API_HOST, requestBody, async(res) => {
            console.log("123:  ",res);
            if (res) {

              //await this.getVirtualRoom();
            await getVirtualRoom(API_HOST);
              socket.emit("virtualRoom", res);
            }
          });
    }


    // getVirtualRoom = async () => {
    //     const { API_HOST, getVirtualRoom } = this.props;
    //     await getVirtualRoom(API_HOST, res => {
    //       if (res) {
    //           console.log("8888: ", res);
    //         //   res.map(item => {
    //         //     for(const details of item.VRoomDetailList){
    //         //         console.log("ITEM:  ", details);
    //         //         this.userValuesChanges(details.Id_User);
    //         //     }
    //         // });
    //         this.setState({ virtualRoomResponse : res })
            
    //         }
    //     });
    //   };


    //Get Virtual Rooms
    getVirtualRoom = async () => {
        const { API_HOST, getVirtualRoom } = this.props;
        await getVirtualRoom(API_HOST);
        console.log(".props in function : ", this.props.virtualRoomResponse);
    };

    updateVirtualRoomDetails = (virtualRoomResponse) =>{

        if (virtualRoomResponse) {
            console.log("8888: ", virtualRoomResponse);
            virtualRoomResponse.map(item => {
                if(item.VRoomDetailList){
                    item.VRoomDetailList.map(obj => {
                        console.log("ITEM:  ", obj);
                        this.getUserDetail(obj.Id_User);
                        socket.emit("UserDetail", this.props.getUserDetailResponse);
                    })
                }
            });
        }

            // if (virtualRoomResponse) {
            //     if(virtualRoomResponse[virtualRoomResponse.length - 1].VRoomDetailList){
            //         virtualRoomResponse[virtualRoomResponse.length - 1].VRoomDetailList.map(obj => {
            //             console.log("ITEM:  ", obj);
            //             this.getUserDetail(obj.Id_User);
            //             socket.emit("UserDetail", this.props.getUserDetailResponse);
            //         })
            //     }
            // }

    }


    deleteVirtualRoom = async (virtualRoomId) => {
       const { API_HOST, deleteVirtualRoom, getVirtualRoom } = this.props;
       await deleteVirtualRoom(API_HOST, virtualRoomId, async(res) => {
         if (res) {
            await getVirtualRoom(API_HOST);
            socket.emit("virtualRoom", res);
         }
       });
    };


    render() {
        var userData = localStorage.getItem('userdetail');
        var userId = JSON.parse(userData).Id_User;
        const { SearchbarUserId, departmentList, selectedUsers, departmentListwithhours, sheduleModal, token, MeetingId, selectedOption, title, startDate, endDate } = this.state;
        const editDeptTip = "Edit your department name by clicking<br /> the text field in the white tab";
        

        //  console.log("Selected Users: ", selectedUsers);
        return (
            <>
            <ContextMenuTrigger id="createDepartment"  >
            <div className="main-panel"  style={{ overflowX: 'hidden' }}>

            {this.state.virtualRoomResponse && this.state.virtualRoomResponse.length > 0 ? 
                <div className="o-auto-content virtual_wrapper">
                    <div className="col-item-8">
                        <div className="common-slider office-cabins">
                            <div className="cabin-main-head">
                                <h3 className="cabin-title">Virtual Meeting</h3>
                            </div>
                            
                            <div className="virtual_room">


                            {this.state.virtualRoomResponse && this.state.virtualRoomResponse.map(virtualRoom => {

                                if (virtualRoom && virtualRoom.VRoomDetailList.length <=5 ) {
                                    return (
                                
                                            <div className="user-card-list user-cabin ">
                                                { userId === virtualRoom.Created_By ? <i class="fas fa-times vr_close" onClick={()=>{this.deleteVirtualRoom(virtualRoom.Id)}}></i> : ""}
                                                <div className="card virtual_users">  

                                                    <div class="room-things common-desk-room">                                                
                                                        <div class="d-flex desk-row desk-row-up">
                                                            <div> 
                                                                { virtualRoom.VRoomDetailList[0] ?
                                                                <div>
                                                                {virtualRoom.VRoomDetailList[0].Is_Visible_For_OtherUsers ? <div data-for="vrMember0" data-tip={virtualRoom.VRoomDetailList[0].Name_User}><ReactTooltip id="vrMember0" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                                </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div> 
                                                            <div> 
                                                                { virtualRoom.VRoomDetailList[2] ? 
                                                                <div>
                                                                {virtualRoom.VRoomDetailList[2].Is_Visible_For_OtherUsers ?<div data-for="vrMember2" data-tip={virtualRoom.VRoomDetailList[2].Name_User}><ReactTooltip id="vrMember2" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/> 
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                                </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div> 
                                                        </div>
                                                        <img class="desk-tbl" src="images/four-desk.png" alt="" />
                                                        <div class="d-flex desk-row desk-down"> 
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[1] ? 
                                                            <div>
                                                            {virtualRoom.VRoomDetailList[1].Is_Visible_For_OtherUsers ? <div data-for="vrMember1" data-tip={virtualRoom.VRoomDetailList[1].Name_User}><ReactTooltip id="vrMember1" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                            </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div>   
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[3] ? 
                                                            <div>
                                                            {virtualRoom.VRoomDetailList[3].Is_Visible_For_OtherUsers ? <div data-for="vrMember3" data-tip={virtualRoom.VRoomDetailList[3].Name_User}><ReactTooltip id="vrMember3" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                            </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div> 
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[4] ? 
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[4].Is_Visible_For_OtherUsers ? <div data-for="vrMember4" data-tip={virtualRoom.VRoomDetailList[4].Name_User}><ReactTooltip id="vrMember4" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                            </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div> 
                                                        </div> 
                                                    </div> 
                
                                                </div>            
                                            </div> 
                                            )}

                                if (virtualRoom && virtualRoom.VRoomDetailList.length > 5 ) {
                                    return (
                                            <div className="user-card-list user-cabin vr_user_md">
                                            { userId === virtualRoom.Created_By ? <i class="fas fa-times vr_close" onClick={()=>{this.deleteVirtualRoom(virtualRoom.Id)}}></i> : ""}
                                                <div className="card virtual_users">  

                                                    <div class="room-things common-desk-room">                                                
                                                        <div class="d-flex desk-row desk-row-up">
                                                        <div> 
                                                                { virtualRoom.VRoomDetailList[0] ?
                                                                <div>
                                                                { virtualRoom.VRoomDetailList[0].Is_Visible_For_OtherUsers ? <div data-for="vrMember0" data-tip={virtualRoom.VRoomDetailList[0].Name_User}><ReactTooltip id="vrMember0" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[0].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                                </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div> 
                                                            <div> 
                                                                { virtualRoom.VRoomDetailList[2] ? 
                                                                <div>
                                                                {virtualRoom.VRoomDetailList[2].Is_Visible_For_OtherUsers ? <div data-for="vrMember2" data-tip={virtualRoom.VRoomDetailList[2].Name_User}><ReactTooltip id="vrMember2" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[2].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                                </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div> 
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[5] ? 
                                                            <div>
                                                            {virtualRoom.VRoomDetailList[5].Is_Visible_For_OtherUsers ? <div  data-for="vrMember5" data-tip={virtualRoom.VRoomDetailList[5].Name_User}><ReactTooltip id="vrMember5" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[5].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                            </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div> 
                                                        </div>
                                                        <img class="desk-tbl" src="images/four-lg-desk.png" alt="" />
                                                        <div class="d-flex desk-row desk-down"> 
                                                        <div>
                                                            { virtualRoom.VRoomDetailList[1] ? 
                                                            <div>
                                                            {virtualRoom.VRoomDetailList[1].Is_Visible_For_OtherUsers ? <div data-for="vrMember1" data-tip={virtualRoom.VRoomDetailList[1].Name_User}><ReactTooltip id="vrMember1" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[1].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                            </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div>   
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[3] ? 
                                                            <div>
                                                            {virtualRoom.VRoomDetailList[3].Is_Visible_For_OtherUsers ? <div data-for="vrMember3" data-tip={virtualRoom.VRoomDetailList[3].Name_User}><ReactTooltip id="vrMember3" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[3].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                            </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div>
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[4] ? 
                                                            <div>
                                                            {virtualRoom.VRoomDetailList[4].Is_Visible_For_OtherUsers ? <div data-for="vrMember4" data-tip={virtualRoom.VRoomDetailList[4].Name_User}><ReactTooltip id="vrMember4" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[4].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                            </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div> 
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[6] ? 
                                                            <div>
                                                            { virtualRoom.VRoomDetailList[6].Is_Visible_For_OtherUsers ? <div data-for="vrMember6" data-tip={virtualRoom.VRoomDetailList[6].Name_User}><ReactTooltip id="vrMember6" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>
                                                                    <Avatar 
                                                                        avatarStyle='Transparent'
                                                                        topType={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["topType"]}
                                                                        accessoriesType={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["accessoriesType"]}
                                                                        hairColor={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["hairColor"]}
                                                                        facialHairType={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["facialHairType"]}
                                                                        clotheType={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["clotheType"]}
                                                                        clotheColor={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["clotheColor"]}
                                                                        eyeType={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["eyeType"]}
                                                                        eyebrowType={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["eyebrowType"]}
                                                                        mouthType={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["mouthType"]}
                                                                        skinColor={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["skinColor"]}
                                                                        graphicType={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["graphicType"]}
                                                                        hatColor={JSON.parse(virtualRoom.VRoomDetailList[6].User_Profile_Pic)["hatColor"]}
                                                                    />
                                                                    </div>
                                                                    : <Avatar className="avt-opacity" topType="ShortHairTheCaesar" />}
                                                            </div>
                                                                    : 
                                                                    <img className="desk-chr" src="images/empty-chair.png" alt="" />
                                                                }
                                                            </div>
                                                        </div> 
                                                    </div> 
                
                                                </div>            
                                            </div>
                                    )}

                                })}

                            </div>

                        </div>
                    </div>
                </div>
            : null}

                <div className="o-auto-content">
                <div className="col-xl-12">
                {selectedUsers.length > 0 &&
                    <ContextMenuTrigger id="meetingshedule"  >
                        <div className="common-slider office-cabins" onMouseEnter={(e)=>{this.onLeaveUserCard()}} >
                        <div className="cabin-main-head">
                                                <h3 className="cabin-title">Group Chat/Schedule Meeting</h3>
                                            </div>
                            <i class="fas fa-times" style={{ position: "absolute", top: "20px", cursor: "pointer", right: "20px", zIndex: "10" }} onClick={this.clearSelectedUsers}></i>
                            <SelectedUsers selectedUsersForMeeting={selectedUsers} numberOfCard={this.state.leftSideExpand ? 5 : 6} selectUserRemove={this.selectUserRemove} onEnterUserCard={this.onEnterUserCard} onLeaveUserCard={this.onLeaveUserCard} handleAddChat={this.props.handleAddChat}></SelectedUsers>
                        </div>
                        <div onMouseEnter={(e)=>{e.stopPropagation()}} >
                        <ContextMenu id="meetingshedule" >
                            <MenuItem onClick={this.sheduleMeeting}>
                                Schedule Meeting
                           </MenuItem>
                           <MenuItem onClick={this.saveVirtualRoom}>
                                Create Virtual Conference
                           </MenuItem>
                           {selectedUsers.length > 1?
                           <MenuItem onClick={this.chatOpen}>
                               Group chat
                           </MenuItem>:
                           null}
                           {this.state.userCardToRemove && this.state.userCardToRemove!==0?
                           <MenuItem onClick={()=>{this.selectUserRemove(this.state.userCardToRemove)}}>
                              Remove user from group
                           </MenuItem>:null}
                        </ContextMenu>
                        </div>
                    </ContextMenuTrigger>
                }
                {sheduleModal === true ?
                    <ScheduleMeeting from={'dashcalander'} token={token} MeetingId={MeetingId} selectedOption={selectedOption} sheduleModal={sheduleModal} title={title} meetingFlagSet={this.meetingFlagSet} startDate={startDate} endDate={endDate}
                    /> :
                    <div onMouseEnter={(e)=>{e.stopPropagation()}} >
                            <ContextMenu id="createDepartment" >
                                <MenuItem onClick={this.onAddDepartment}>
                                <i class="fa fa-plus-square" ></i>  <strong>Add Department</strong>
                            </MenuItem>
                            </ContextMenu>
                            </div>
                    }
                    </div>
                    </div>
                <div className="o-auto-content" id="dept-main">

                    <div className="highlight-modal" id="highlight4" >
                        <div className="h-body">
                            <div className="h-content">
                                <p>You can drag and drop the departments as per your preference..</p>
                                <p>On cabin right click you can chat</p>
                            </div>
                            <div className="h-actions">
                                <span></span>
                                <button className="btn btn-primary" id="nexth1" onClick={this.highlight5} >Let's start</button>
                            </div>
                        </div>
                    </div>


                    {this.props.timeFilter > 0 ?
                        <>
                            {departmentListwithhours && departmentListwithhours.map((department, index) => {
                                var deptClass = "";
                                var deptSize = 0;
                                if (department.Department_Size_Type === 1) {
                                    deptClass = "col-item-1";
                                    deptSize = 1;
                                }
                                if (department.Department_Size_Type === 2) {
                                    deptClass = "col-item-2";
                                    deptSize = 2;
                                }
                                if (department.Department_Size_Type === 3) {
                                    deptClass = "col-item-3";
                                    deptSize = 3;
                                }
                                if (department.Department_Size_Type === 4) {
                                    deptClass = "col-item-3";
                                    deptSize = 4;
                                }
                                if (department.Department_Size_Type === 5) {
                                    deptClass = "col-item-4";
                                    deptSize = 4;
                                }
                                if (department.Department_Size_Type === 6) {
                                    deptClass = "col-item-6";
                                    deptSize = 6;
                                }
                                if (department.Department_Size_Type === 7) {
                                    deptClass = "col-item-7";
                                    deptSize = 7;
                                }
                                if (department.Department_Size_Type >= 8) {
                                    deptClass = "col-item-8";
                                    deptSize = 8;
                                }
                                //console.log(departmentListwithhours[index - 1])
                                if (department.Department_Size_Type === 1 && (!departmentListwithhours[index - 1] || departmentListwithhours[index - 1].Department_Size_Type === 3) && departmentListwithhours[index + 1] && departmentListwithhours[index + 1].Department_Size_Type === 3) {
                                    let departmentListwithhours = this.state.departmentListwithhours;
                                    departmentListwithhours[index + 1].Department_Size_Type = 4;
                                    //this.setState({ departmentListwithhours })
                                }
                                return (

                                    <div className={`${deptClass} draggeable-div`} key={department.Id_Department} id={"scroll" + department.Id_Department}
                                        draggable="true"
                                        onDragStart={this.handleDragStart({ id: department.Id_Department })}
                                        onDragOver={this.handleDragOver({ id: department.Id_Department })}
                                        onDrop={this.handleDrop({ id: department.Id_Department })}
                                    >
                                        <ContextMenuTrigger id={department.Id_Department}>
                                        <div className="common-slider office-cabins">

                                            <div className="cabin-main-head">
                                                <h3 className="cabin-title">{department.Department_Name}</h3>
                                            </div>
                                            <Slider getUserListOfDepartment={this.getUserListOfDepartment} setDeptArea={this.setDeptArea} checkDeptAvailability={this.checkDeptAvailability} userValuesChanges={this.props.userValuesChanges} handleAddChat={this.props.handleAddChat} numberOfCard={deptSize} timeFilter={this.props.timeFilter} departmentId={department.Id_Department} SearchbarUserId={SearchbarUserId} busyMessage={this.busyMessage} getUserFromList={this.getUserFromList} isdoorOpen={this.props.isdoorOpen} userListWithHrs={this.state.userListWithHrs} />
                                        </div>
                                            <div onMouseEnter={(e)=>{e.stopPropagation()}} >
                                            <ContextMenu id={department.Id_Department}>
                                                <MenuItem onClick={()=>{this.props.createDepartmentChat(department.Id_Department)}}>
                                                Group Chat(with Department)
                                                </MenuItem>
                                            </ContextMenu>
                                            </div>
                                    </ContextMenuTrigger>
                                    </div>
                                )
                            })}
                        </>

                        :
                        <>
                            {departmentList && departmentList.map((department, index) => {
                                var deptClass = "";
                                var deptSize = 0;
                                if ((department.TotalUsers) === 1) {
                                    deptClass = "col-item-1";
                                    deptSize = 1;
                                }
                                if ((department.TotalUsers) === 2) {
                                    deptClass = "col-item-2";
                                    deptSize = 2;
                                }
                                if ((department.TotalUsers) === 3) {
                                    deptClass = "col-item-3";
                                    deptSize = 3;
                                }
                                if ((department.TotalUsers) === 4) {
                                    deptClass = "col-item-4";
                                    deptSize = 4;
                                }
                                if ((department.TotalUsers) === 5) {
                                    deptClass = "col-item-5";
                                    deptSize = 5;
                                }
                                if ((department.TotalUsers) === 6) {
                                    deptClass = "col-item-6";
                                    deptSize = 6;
                                }
                                if ((department.TotalUsers) === 7) {
                                    deptClass = "col-item-7";
                                    deptSize = 7;
                                }
                                if ((department.TotalUsers) >= 8) {
                                    deptClass = "col-item-8";
                                    deptSize = 8;
                                }
                                
                                if ((department.OtherContent).slice(-1) === "1" && (!departmentList[index - 1] || departmentList[index - 1].OtherContent.slice(-1) === "3") && departmentList[index + 1] && departmentList[index + 1].OtherContent.slice(-1) === "3") {
                                    let departmentList = this.state.departmentList;
                                    departmentList[index + 1].OtherContent = departmentList[index + 1].OtherContent.slice(0, -1) + "4";
                                    //this.setState({ departmentList })
                                }

                                return (

                                    <div className={`${deptClass} draggeable-div`} key={department.Id} id={"scroll" + department.Id}
                                        draggable="true"
                                        onDragStart={this.handleDragStart({ id: department.Id })}
                                        onDragOver={this.handleDragOver({ id: department.Id })}
                                        onDrop={this.handleDrop({ id: department.Id })}
                                    >
                                        <ContextMenuTrigger id={department.Id}>
                                        <div className="common-slider office-cabins">
                                        {JSON.parse(userData).Is_Admin ? (<ReactTooltip id="editDept" place="right" type="dark" effect="solid" multiline={true} backgroundColor="#2c69d1" textColor="white" border="true"/>) : ""}
                                            <div className="cabin-main-head">
                                                <div className="dept-title" data-for="editDept" data-tip={editDeptTip}>

                                                {JSON.parse(userData).Is_Admin ? (
                                                    // <h3 className="edit-cabin-title">
                                                    <div class="title-wrapper">
                                                        <EdiText
                                                            hideIcons={true}
                                                            saveButtonClassName="custom-save-button"
                                                            editButtonClassName="custom-edit-button"
                                                            cancelButtonClassName="custom-cancel-button"
                                                            type='text'
                                                            value={department.Name}
                                                            onSave={value => { this.editDeptName(department.Id, value) }}
                                                            className="edit-cabin-title"
                                                            submitOnEnter={value => { this.editDeptName(department.Id, value) }}
                                                            editOnViewClick={true}
                                                            cancelOnEscape={true}
                                                            startEditingOnFocus={true}
                                                        />
                                                        <small className="member-count">{department.TotalUsers}</small>
                                                    
                                                    </div>
                                                ) : (
                                                    <div class="title-wrapper">
                                                        <h3 className="cabin-title">
                                                            {department.Name}
                                                        </h3>
                                                    <small className="member-count">{department.TotalUsers}</small>
                                                    </div>
                                                    )}
                                                    </div>

                                            </div>
                                            <Slider getUserListOfDepartment={this.getUserListOfDepartment} userList={this.state.userList} setDeptArea={this.setDeptArea} checkDeptAvailability={this.checkDeptAvailability} userValuesChanges={this.props.userValuesChanges} handleAddChat={this.props.handleAddChat} numberOfCard={deptSize} timeFilter={this.props.timeFilter} departmentId={department.Id} SearchbarUserId={SearchbarUserId} busyMessage={this.busyMessage} getUserFromList={this.getUserFromList} isdoorOpen={this.props.isdoorOpen}></Slider>
                                        </div>

                                        {/* {JSON.parse(userData).Slack_Access_Token !== "" &&  */}
                                            <div id="createDept" onMouseEnter={(e)=>{e.stopPropagation()}} >
                                                <ContextMenu id={department.Id}>
                                                    <MenuItem onClick={()=>{this.props.createDepartmentChat(department.Id)}}>
                                                        Create Department Group Chat
                                                    </MenuItem>
                                                    <MenuItem onClick={()=>{this.props.addnewMember(department.Id)}}>
                                                        Assign New Member
                                                    </MenuItem>
                                                </ContextMenu>
                                                </div>
                                        {/* } */}
                                        </ContextMenuTrigger>
                                    </div>
                                )
                            })}
                        </>}
                </div>
            </div >
            </ContextMenuTrigger>
               {this.state.onAddSelected ? (
                <AddEditDepartment isAddDeptModalOpen={this.state.onAddSelected} toggleCloseAddDept={this.onAddDepartmentPopupClose} getDepartments={this.getDepartments} dept_Id={0} fromOfficeLayout={true} createDepartmentPopup ={this.createDepartmentPopup}/>
              ) : ""}
              {this.state.isCreateDepartmentPopup &&
            <CreateDepartmentPopup isCreateDepartmentPopup={this.state.isCreateDepartmentPopup} createdDepartmentPopup={this.createDepartmentPopup} closeCreateDepartmentPopup={this.closeCreateDepartmentPopup}/>  
            }
        </>
        );
    }
}
// Prop types of props.
OfficeLayout.propTypes = {
    API_HOST: PropTypes.string,
    getVirtualRoom: PropTypes.func,
    virtualRoomResponse: PropTypes.any
};

// Set default props.
OfficeLayout.defaultProps = {
    getVirtualRoom: () => { },
    virtualRoomResponse: [],
};


const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    departmentListFilterWiseResponse: state.mainOfficeReducer.departmentListFilterWiseResponse,
    editDeptNameResponse: state.mainOfficeReducer.editDeptNameResponse,
    userListDepartmenttWithSelectedHrsFilterResponse: state.mainOfficeReducer.userListDepartmenttWithSelectedHrsFilterResponse,
    virtualRoomResponse: state.virtualRoomReducer.virtualRoomResponse,
    getUserDetailResponse: state.dashboardReducer.getUserDetailResponse.userDetailResponse
})


export default connect(mapStateToProps, { getUserListOfDepartmentWithCallback, getUserDetail, getDepartmentFilterWise, updateDepartmentOrder, editDeptName, getUserListDepartmentWithSelectedHrsFilter, getUpdatedDepartments, getUserListOfDepartment, fetchSlackTeam, saveVirtualRoom, getVirtualRoom, deleteVirtualRoom, doorClose, setUserCustomStatus })(OfficeLayout)