import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from "../../utils/apiUrls";
import { getUserAvailabilityList } from "../../actions/WorkingHoursActions";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import WorkingHours from './WorkingHours'
import LoadingOverlay from 'react-loading-overlay';

let events = [];
const localizer = momentLocalizer(moment)

function ToolTipFun({ event }) {

    let popoverClickRootClose = (
        <Popover id="popover-trigger-click-root-close" style={{ zIndex: 99999 }}>
            <Popover.Title as="h3">{event.title}</Popover.Title>
            <Popover.Content>
                Time : {moment(event.start).format('LT') + ' - ' + moment(event.end).format('LT')}
            </Popover.Content>
        </Popover >
    );

    return (
        <div>
            <div>
                <OverlayTrigger trigger="click" id="help" rootClose container={this} placement="bottom" overlay={popoverClickRootClose} >
                    <div>{event.title}</div>
                </OverlayTrigger>
            </div>
        </div>
    );
}



class GoogleCalendar extends Component {
    constructor(props) {
        super(props)
        moment.tz(new Date(), this.props.userDetails.TZ)
        this.state = {
            isModal: false,
            workingHourModal: false,
            eventCard: [],
            x: 0,
            y: 0,
            startDate: new Date(),
            endDate: new Date(),
            workingHoursList: null,
            currentInstance: this,
            loading: false
        }
    }

    componentDidMount() {
        this.getUserAvailabilityList();
        if (this.props.isEditWorkingHours) {
            this.setState({
                workingHourModal: true,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.getUserAvailabilityListResponse !== this.props.getUserAvailabilityListResponse) {
            this.setCalendarSchedule(this.props.getUserAvailabilityListResponse)
            return {
                workingHoursList: this.props.getUserAvailabilityListResponse
            }
        }
    }

    setCalendarSchedule = (data) => {
        events = [];
        for (let i = 0; i < data.length; i++) {
            let Title = data[i].Is_Holiday ? "On leave" : "On Duty"
            const sd = new Date(data[i].Start_Date);
            const ed = new Date(data[i].End_Date);
            events.push({
                title: Title, //moment(new Date(sd.setHours(0, data[i].Start_Time, 0, 0))).format('LT') + ' - ' + moment(new Date(ed.setHours(0, data[i].End_Time, 0, 0))).format('LT'),
                start: new Date(sd.setHours(0, data[i].Start_Time, 0, 0)),
                end: new Date(ed.setHours(0, data[i].End_Time, 0, 0)),
                email: 'xyz@yahoo.in',
                name: 'xyz',
                link: 'https://momentjs.com/timezone/docs'
            })
        }
    }


    getUserAvailabilityList = async () => {
        const { API_HOST, getUserAvailabilityList } = this.props;
        this.setState({
            loading: true
        })
        await getUserAvailabilityList(API_HOST, (res) => {
            this.setState({
                loading: false
            })
        });
    }

    // detailTask = (event, e) => {
    //     var offset = $("#myprofile").offset();
    //     this.setState({
    //         isModal: true,
    //         eventCard: event,
    //         x: e.clientX - offset.left + 110,
    //         y: e.clientY - offset.top + 51
    //     })
    // }

    toggle = () => {
        this.setState({
            isModal: false
        })
    }

    selectSlot = (slotInfo) => {
        if (slotInfo.action === 'doubleClick' || slotInfo.action === 'select') {
            this.setState({
                workingHourModal: true,
                startDate: slotInfo.start,
                endDate: slotInfo.end
            })
        }

        this.toggle();
    }

    workingHourFlagSet = () => {
        this.setState({
            workingHourModal: false
        })
    }

    tooltipAccessor = (event) =>{
       return null
    }

    render() {
        const { workingHourModal, startDate, endDate,loading } = this.state;
        return (
            <>
                {workingHourModal === true && <WorkingHours userValuesChanges={this.props.userValuesChanges} workingHourModal={workingHourModal} workingHourFlagSet={this.workingHourFlagSet} startDate={startDate} userDetails={this.props.userDetails} endDate={endDate} isEditWorkingHours={this.props.isEditWorkingHours ? this.props.isEditWorkingHours : false} getUserAvailabilityList={this.getUserAvailabilityList} />}
                <LoadingOverlay
                    active={loading}
                    spinner
                    text='Loading...'
                >
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500, marginTop: '2%' }}
                        //onSelectEvent={this.detailTask}
                        onNavigate={this.toggle}
                        onView={this.toggle}
                        onSelectSlot={this.selectSlot}
                        selectable={true}
                        defaultDate={moment().toDate()}
                        tooltipAccessor={this.tooltipAccessor}
                        components={{
                            event: ToolTipFun
                        }}
                        views={['month', 'week', 'work_week', 'day']}
                    />
                </LoadingOverlay>
            </>
        );
    }
}


GoogleCalendar.defaultProps = {
    API_HOST: PropTypes.string,
    getUserAvailabilityList: PropTypes.func,
    getUserAvailabilityListResponse: PropTypes.array
};

// Set default props.
GoogleCalendar.defaultProps = {
    getUserAvailabilityList: () => { },
    getUserAvailabilityListResponse: []
};

const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    getUserAvailabilityListResponse: state.workingHoursReducer.getUserAvailabilityListResponse
})


export default connect(mapStateToProps, { getUserAvailabilityList })(GoogleCalendar)


