import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';

class ResetPasswordSuccess extends Component {
  state = {
    fields: {},
    error: {},
    tenantDetails: {},
    src: '',
  };

  onClose = () => {
    this.props.toggle();
    // return <Redirect to='/login' />
    // this.props.history.push({ pathname: '/login' });
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} backdrop={true}>
        <ModalHeader toggle={this.onClose}></ModalHeader>
        <ModalBody>Password has been Reset successfully</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onClose}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ResetPasswordSuccess.defaultProps = {
  isOpen: false,
  toggle: () => {},
};

ResetPasswordSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
};

export default hot(module)(ResetPasswordSuccess);
