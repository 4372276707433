import React from 'react';
import { subscribeEmail, getUnsubscribeEmailId } from "../../../actions/subscribeActions";
import { connect } from 'react-redux';
import { getApiHost } from "../../../utils/apiUrls";
import PropTypes from "prop-types";
import { getEncryptedValue} from "../../../utils/utility";
import { toast } from "react-toastify";
import logo from "../../../assets/images/logo.png";

class Subscribe extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      emailId: ""
    };
  }

  componentDidMount() {
    this.getUnsubscribeEmailId(this.props.match.params.emailIdKey);
  }

  getUnsubscribeEmailId = async(emailkey) => {
    const { API_HOST, getUnsubscribeEmailId } = this.props;
      await getUnsubscribeEmailId(API_HOST, emailkey, (res) => {
        if (res){
          this.setState({ emailId: res.EmailId });
        } 
      });
  }

  subscribeEmail = async() => {
    const { API_HOST, subscribeEmail } = this.props;
        let reqBody = {
         // "EmailId":"OqJSIQ7vdpt91On8uuzY9KP2HjvXaf+OtkKRtHqpeRmaWaf4DLa47t8fUXwGvPOJ",
          "EmailId": getEncryptedValue(this.state.emailId),
        }
      await subscribeEmail(API_HOST, reqBody, (res) => {
        if (res){
          toast.success("Subscribed successfully..!!");
        }
      });
  }


  render() {
    
    return (
      <div className="email-tem-content">

      <div className="logo-wrap"><img src={logo} className="img-fluid" alt="" /></div>

      <div className="container">

        <div className="card mx-auto">

          {/* <h3>Complete Your ##TenantName## Registration</h3> */}

          <div className="email-content mb-3 mt-3">
          <p>You have successfully unsubscribed</p>
          <p>Hopefully you will come back some day.</p>
          <p>Having doubts?</p>
          <p>Please click <b style={{ cursor: 'pointer'}} onClick={this.subscribeEmail}>here</b> in order to subscribe again.</p>
          </div>

          </div>

        </div>
      </div>

    );
  }
}

// Prop types of props.
Subscribe.propTypes = {
  API_HOST: PropTypes.string,
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),
})

export default connect(mapStateToProps, { subscribeEmail, getUnsubscribeEmailId })(Subscribe);
