import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getApiHost, socket, slack_socket } from '../../../utils/apiUrls';
import $ from 'jquery';
import { AvatarImage } from '../../../components/Avatar/AvatarImage';
import { InputText } from './InputText';
import {
  getEncryptedValue,
  getDecryptedValue,
} from '../../../utils/utility';
import { getUserDetail } from '../../../actions/dashboardActions';
import Emoji from 'react-emoji-render';
import 'emoji-mart/css/emoji-mart.css';
import { addSearchTag, getSearchTags } from '../../../actions/tagActions';
import { getTag } from '../../../utils/utility';
import KeywordTag from './KeywordTag';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

const axios = require('axios');
const qs = require('qs');

class OneToOneChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMinimize: false,
      message: '',
      messageList: [],
      conversationHistory: [],
      //teamId:0
      teamId: this.props.teamId,
      channelId: '',
      userLoggedInData: JSON.parse(localStorage.getItem('userdetail')),
      userDetails: this.props.userDetails,
      timeStamp: 0,
      isTyping: '',
      isEmoji: false,
      selectedEmoji: '',
      isGroupChatWindow: false,
      tags: [],
      isTag: true,
      selection: '',
    };
  }

  componentDidMount() {
    socket.on('UserDetail', (data) => {
      if (
        data.Id_Tenant ===
        JSON.parse(localStorage.getItem('userdetail')).Id_Tenant
      ) {
        this.updateLogedUserDetail(data);
      } else {
        this.getUserDetail(data.Id_User);
      }
    });
    this.getTags();
    this.fetchChannel(this.props.userDetails.Id_SlackUser);
    $(`#chatWindow${getDecryptedValue(this.props.userDetails.Id_SlackUser)}`)
      .addClass('show')
      .css('display', 'block');
    $(`#chatWindow${getDecryptedValue(this.props.userDetails.Id_SlackUser)}`)
      .addClass('minimizeChat')
      .css('display', 'block');

    slack_socket.on('updateOneToOneChatHistory', (event) => {
      // console.log("Event called: ", event);
      this.props.markAsReadOnChannelOpen(this.state.channelId);
      this.fetchHistory(this.state.timeStamp);
    });
    slack_socket.on('display', (data) => {
      this.isTyping(data);
    });
  }

  // componentWillUnmount() {
  //     alert("closed One to One Chat");
  //     this.setState({ isGroupChatWindow: true})
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.userDetails !== this.props.userDetails) {
      this.setState({
        timeStamp: 0,
        userDetails: this.props.userDetails,
      });
      this.fetchChannel(this.props.userDetails.Id_SlackUser);
    }

    if (prevProps.getUserDetailResponse !== this.props.getUserDetailResponse) {
      this.updateLogedUserDetail(this.props.getUserDetailResponse);
    }
  }

  isTyping = (data) => {
    var Id_SlackUser = JSON.parse(localStorage.getItem('userdetail'))
      .Id_SlackUser;
    if (
      data.channelType === 'single' &&
      data.typing === true &&
      data.user.Id_SlackUser === getDecryptedValue(Id_SlackUser) &&
      data.Id_User === this.state.userDetails.Id_User
    ) {
      this.setState({ isTyping: 'typing...' });
    } else {
      this.setState({ isTyping: '' });
    }
  };

  getUserDetail = async (id) => {
    const { API_HOST, getUserDetail } = this.props;
    await getUserDetail(API_HOST, id);
  };

  updateLogedUserDetail(data) {
    let userData = JSON.parse(localStorage.getItem('userdetail'));
    if (userData.Id_User === data.Id_User) {
      userData.ProfilePic = data.ProfilePic;
      userData.Display_Name = data.Display_Name;
      userData.Real_Name = data.Real_Name;
      localStorage.setItem('userdetail', JSON.stringify(userData));
      console.log('one to one chat set local Storage');
    }
    let newData = this.state.userDetails;

    if (newData.Id_User === data.Id_User) {
      newData.Display_Name = data.Display_Name;
      newData.ProfilePic = data.ProfilePic;
      newData.Real_Name = data.Real_Name;
    }

    this.setState(
      Object.assign({}, { userLoggedInData: userData, userDetails: newData })
    );
  }

  closePopup = () => {
    $(`#chatWindow${getDecryptedValue(this.props.userDetails.Id_SlackUser)}`)
      .removeClass('show')
      .css('display', 'none');
    $(`#chatWindow${getDecryptedValue(this.props.userDetails.Id_SlackUser)}`)
      .removeClass('minimizeChat')
      .css('display', 'none');
    this.props.closeChatWindows(this.props.userDetails);
    this.props.markAsReadOnChannelOpen(this.state.channelId);
    this.setState({ conversationHistory: [], channelId: '' });
  };

  onMinimize = () => {
    // this.setState({isMinimize:true});
    $(
      `#chatWindow${getDecryptedValue(this.props.userDetails.Id_SlackUser)}`
    ).toggleClass('onminitoggle');
  };
  // onCloseAddToSlack = () => {
  //   this.props.toggleCloseAddToSlack();
  // };
  // onMaximize = () =>{
  //     this.setState({isMinimize:false})
  // }

  // To fetch chat history

  fetchChannel = async (teamId) => {
    var userData = JSON.parse(localStorage.getItem('userdetail'));
    var userSlackToken = userData.Slack_Access_Token;

    //const { WebClient } = require('@slack/web-api');
    //const token = "xoxp-1245264436822-1252205688995-1284716424961-eeec00019de4539c03b4d8e6b47691e0" //general token
    //const token = "xoxp-1245264436822-1251999086562-1341421140417-f723cacb643d3c8437da065e51947a68" //tracey token
    const token = getDecryptedValue(userSlackToken);
    //const web = new WebClient(token);

    try {
      let message = {
        token: token,
        users: getDecryptedValue(teamId),
      };
      const channel = await axios.post(
        `https://slack.com/api/conversations.open`,
        qs.stringify(message)
      );

      await this.setState({ channelId: channel.data.channel.id });
      this.fetchHistory(this.state.timeStamp);
    } catch (error) {
      console.error(error);
      this.setState({ conversationHistory: [], channelId: '' });
    }
  };

  fetchHistory = async (updatedTime) => {
    if (this.state.channelId !== '') {
      var userData = JSON.parse(localStorage.getItem('userdetail'));
      var userSlackToken = userData.Slack_Access_Token;

      //const { WebClient } = require('@slack/web-api');
      //const token = "xoxp-1245264436822-1252205688995-1284716424961-eeec00019de4539c03b4d8e6b47691e0" //general token
      //const token = "xoxp-1245264436822-1251999086562-1341421140417-f723cacb643d3c8437da065e51947a68" //tracey token
      const token = getDecryptedValue(userSlackToken);
      // const web = new WebClient(token);

      let conversationHistory;
      try {
        let message = {
          token: token,
          channel: this.state.channelId,
          //limit: 30,
          inclusive: true,
          // oldest: "1604407209"
          oldest: updatedTime,
        };
        const result = await axios.post(
          `https://slack.com/api/conversations.history`,
          qs.stringify(message)
        );
        // const result = await web.conversations.history({
        //     token: token,
        //     channel: this.state.channelId,
        //     limit: 30,
        //     //as_user: true
        //     headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Credentials": true,
        //     "Access-Control-Allow-Headers": "*",
        //     "Access-Control-Allow-Headers": "Accept",
        //     "Access-Control-Allow-Methods": ["POST", "GET", "OPTIONS", "DELETE"],
        //     "Authorization": "Bearer" + token
        //     }
        // });
        // console.log(result)
        conversationHistory = result.data.messages;
        if (this.state.timeStamp === 0) {
          conversationHistory = result.data.messages;
          this.setState({
            conversationHistory: conversationHistory,
            timeStamp: result.data.messages[0].ts,
          });

          // console.log("NEW TIMESTAMP: ", this.state.timeStamp);
        } else {
          let convosHistory = await this.state.conversationHistory;
          await convosHistory.splice(convosHistory.length - 1, 1);
          let newconvosList = await convosHistory.concat(conversationHistory);
          this.setState({
            conversationHistory: newconvosList,
            timeStamp: result.data.messages[0].ts,
          });
          //console.log("UPDATED TIMESTAMP : ", this.state.timeStamp);
        }
        let targetElement = document.getElementById(
          `mainchat${getDecryptedValue(this.props.userDetails.Id_SlackUser)}`
        );
        targetElement.scrollTop = targetElement.scrollHeight;
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Send Message
  sendMsg = async (msg) => {
    var userData = JSON.parse(localStorage.getItem('userdetail'));
    var userSlackToken = userData.Slack_Access_Token;
    //const { WebClient } = require('@slack/web-api');

    const token = getDecryptedValue(userSlackToken); //userwise slack token

    if (msg !== '' && msg.trim() !== '') {
      let message = {
        token: token,
        text: msg,
        channel: this.state.channelId,
        as_user: true,
      };
      const result = await axios.post(
        `https://slack.com/api/chat.postMessage`,
        qs.stringify(message)
      );

      // let result = await web.chat.postMessage({
      //     token: token,
      //     text: this.state.message,
      //     channel: this.state.channelId,
      //     //username: userName,
      //     //as_user: false
      //     as_user: true,
      //     // headers: {
      //     // "Content-Type": "application/json",
      //     // "Access-Control-Allow-Origin": "*",
      //     // "Access-Control-Allow-Credentials": true,
      //     // "Access-Control-Allow-Headers": "*",
      //     // "Access-Control-Allow-Headers": "Accept",
      //     // "Access-Control-Allow-Methods": ["POST", "GET", "OPTIONS", "DELETE"]
      //     // "Authorization": "Bearer" + token
      //     // }
      // });

      this.fetchHistory(this.state.timeStamp);
      slack_socket.emit('message.im', result.data);

      this.setState({
        message: '',
        // conversationHistory: [...this.state.conversationHistory, conHistory]
      });

      let targetElement = document.getElementById(
        `mainchat${getDecryptedValue(this.props.userDetails.Id_SlackUser)}`
      );
      targetElement.scrollTop = targetElement.scrollHeight;
    }
  };
  //Send message on press of Enter Key
  // onKeyDownHandler = e => {

  //     if (e.key === 'Enter') {
  //         e.preventDefault();
  //         this.sendMsg();
  //     }
  // };

  openEmoji = () => {
    this.setState({ isEmoji: !this.state.isEmoji });
  };

  selectEmoji = (e) => {
    console.log('Emoji:  ', e);
    this.setState({ selectedEmoji: e.colons });
    // this.sendMsg(e.colons);
  };

  getTags = () => {
    const { API_HOST, getSearchTags } = this.props;
    let userData = JSON.parse(localStorage.getItem('userdetail'));
    getSearchTags(API_HOST, userData.Id_User, (res) => {
      let tags = [];
      if (res) {
        res.map((obj) => {
          tags.push({ key: obj.Id, label: obj.Tags });
          return tags;
        });
      }
      this.setState({
        tags: tags,
      });
    });
  };

  addTag = async () => {
    let text = this.state.selection;
    if (text) {
      const { API_HOST, addSearchTag } = this.props;
      let reqBody = {
        Tags: getTag(text, 15),
      };
      await addSearchTag(API_HOST, reqBody, (res) => {
        this.getTags();
      });
    }
  };

  handleSelect = async (e) => {
    e.preventDefault();
    //console.log(`Selected text: ${window.getSelection().toString()}`);
    var text = window.getSelection().toString();
    await this.setState({
      selection: text,
    });
  };

  render() {
    const {
      message,
      conversationHistory,
      userLoggedInData,
      userDetails,
      isTyping,
      tags,
      isTag,
    } = this.state;
    //let userLoggedInData = JSON.parse(localStorage.getItem('userdetail'))
    let myAvatar = JSON.parse(userLoggedInData.ProfilePic);
    var moment = require('moment-timezone');
    const otherProfilePic = JSON.parse(userDetails.ProfilePic);
    return (
      <div
        class={`modal chatWindowModal show minimizeChat minChat${getDecryptedValue(
          this.props.userDetails.Id_SlackUser
        )}`}
        id={`chatWindow${getDecryptedValue(
          this.props.userDetails.Id_SlackUser
        )}`}
        tabindex="0"
        role="dialog"
        aria-labelledby={`chatWindow${this.props.userDetails.Id_SlackUser}`}
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="chatBox">
                <div className="chat-area">
                  <div className="chat-area-header">
                    <div className="chat-area-title">
                      <strong>{userDetails.Real_Name}</strong>
                      <div style={{ fontSize: '13px', fontWeight: 'normal' }}>
                        {isTyping}
                      </div>
                    </div>
                    {tags.length > 0 && isTag === true && (
                      <div className="d-flex" style={{ marginRight: '1%' }}>
                        <KeywordTag
                          tags={tags}
                          getTags={this.getTags}
                        ></KeywordTag>
                      </div>
                    )}
                    <div className="d-flex align-items-center">
                      <div class="chat-area-actions">
                        <div className="add-chat-user">
                          <i class="fas fa-user-plus"></i>
                        </div>
                        {/* <div className="chat-info-btn"><i class="fas fa-info"></i></div> */}
                        <div
                          className="chat-info-btn one-chat-min"
                          onClick={this.onMinimize}
                        >
                          <i class="fas fa-chevron-down"></i>
                        </div>
                        <div
                          className="chat-info-btn chat-closed"
                          data-dismiss="modal"
                          onClick={this.closePopup}
                          label="Close"
                        >
                          <i class="fas fa-times"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="chat-area-main myscroll"
                    id={`mainchat${getDecryptedValue(
                      this.props.userDetails.Id_SlackUser
                    )}`}
                  >
                    {conversationHistory &&
                      conversationHistory
                        .sort((a, b) => a.ts - b.ts)
                        .map((convo) =>
                          convo.user ===
                          getDecryptedValue(userLoggedInData.Id_SlackUser) ? (
                            <div class="chat-msg owner">
                              <div class="chat-msg-profile">
                                {/* <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png" alt="" /> */}
                                <AvatarImage
                                  profilePic={myAvatar}
                                  className=""
                                />

                                <div class="chat-msg-date">
                                  {' '}
                                  {moment.unix(convo.ts).format('LLL')}
                                </div>
                              </div>
                              <ContextMenuTrigger id="selecttagoneotone">
                                <div class="chat-msg-content">
                                  {/* <div class="chat-msg-text">{convo.text}</div> */}
                                  <Emoji
                                    onContextMenu={this.handleSelect}
                                    class="chat-msg-text"
                                    text={convo.text}
                                  />
                                </div>
                              </ContextMenuTrigger>
                            </div>
                          ) : (
                            <div class="chat-msg">
                              <div class="chat-msg-profile">
                                {/* <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%283%29+%281%29.png" alt="" /> */}
                                {/* {memberListResponse && memberListResponse.map((slackuser) => (
                                                                        slackuser.Real_Name === this.findUserDetail(convo.user) ?  */}
                                <AvatarImage
                                  profilePic={otherProfilePic}
                                  className=""
                                />
                                {/* : ""))
                                                                    } */}
                                <div class="chat-msg-date">
                                  {moment.unix(convo.ts).format('LLL')}
                                </div>
                              </div>
                              <ContextMenuTrigger id="selecttagoneotone">
                                <div class="chat-msg-content">
                                  {/* <div class="chat-msg-text">{convo.text}</div> */}
                                  <Emoji
                                    onContextMenu={this.handleSelect}
                                    class="chat-msg-text"
                                    text={convo.text}
                                  />
                                </div>
                              </ContextMenuTrigger>
                            </div>
                          )
                        )}

                    {/* Context menu */}
                    <ContextMenu id="selecttagoneotone">
                      <MenuItem onClick={() => this.addTag()}>
                        Add To Tag
                      </MenuItem>
                    </ContextMenu>

                    {/* <div class="chat-msg">
                                    <div class="chat-msg-profile">
                                        <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%283%29+%281%29.png" alt="" />
                                        <div class="chat-msg-date"><span>Jayesh Watson</span> | 1.22pm</div>
                                    </div>
                                    <div class="chat-msg-content">
                                        <div class="chat-msg-text">Luctus et ultrices posuere cubilia curae.</div>
                                        <div class="chat-msg-text">
                                            <img src="https://media0.giphy.com/media/yYSSBtDgbbRzq/giphy.gif?cid=ecf05e47344fb5d835f832a976d1007c241548cc4eea4e7e&amp;rid=giphy.gif" />
                                        </div>
                                        <div class="chat-msg-text">Neque gravida in fermentum et sollicitudin ac orci phasellus egestas. Pretium lectus quam id leo.</div>
                                    </div>
                                </div>
                                <div class="chat-msg owner">
                                    <div class="chat-msg-profile">
                                        <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png" alt="" />
                                        <div class="chat-msg-date"> 1.22pm</div>
                                    </div>
                                    <div class="chat-msg-content">
                                        <div class="chat-msg-text">Sit amet risus nullam eget felis eget. Dolor sed viverra ipsum😂😂😂</div>
                                        <div class="chat-msg-text">Cras mollis nec arcu malesuada tincidunt.</div>
                                    </div>
                                </div>
                                <div class="chat-msg">
                                    <div class="chat-msg-profile">
                                        <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%282%29.png" alt="" />
                                        <div class="chat-msg-date"><span>Jayesh Watson</span> | 2.45pm</div>
                                    </div>
                                    <div class="chat-msg-content">
                                        <div class="chat-msg-text">Aenean tristique maximus tortor non tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae😊</div>
                                        <div class="chat-msg-text">Ut faucibus pulvinar elementum integer enim neque volutpat.</div>
                                    </div>
                                </div>
                                <div class="chat-msg owner">
                                    <div class="chat-msg-profile">
                                        <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png" alt="" />
                                        <div class="chat-msg-date"> 2.50pm</div>
                                    </div>
                                    <div class="chat-msg-content">
                                        <div class="chat-msg-text">posuere eget augue sodales, aliquet posuere eros.</div>
                                        <div class="chat-msg-text">Cras mollis nec arcu malesuada tincidunt.</div>
                                    </div>
                                </div>
                                <div class="chat-msg">
                                    <div class="chat-msg-profile">
                                        <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%2812%29.png" alt="" />
                                        <div class="chat-msg-date"><span>Jayesh Watson</span> | 3.16pm</div>
                                    </div>
                                    <div class="chat-msg-content">
                                        <div class="chat-msg-text">Egestas tellus rutrum tellus pellentesque</div>
                                    </div>
                                </div>
                                <div class="chat-msg">
                                    <div class="chat-msg-profile">
                                        <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%283%29+%281%29.png" alt="" />
                                        <div class="chat-msg-date"><span>Jayesh Watson</span> | 3.16pm</div>
                                    </div>
                                    <div class="chat-msg-content">
                                        <div class="chat-msg-text">Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et.</div>
                                    </div>
                                </div>
                                <div className="chat-msg-unread"></div>
                                <div class="chat-msg owner">
                                    <div class="chat-msg-profile">
                                        <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png" alt="" />
                                        <div class="chat-msg-date">2.50pm</div>
                                    </div>
                                    <div class="chat-msg-content">
                                        <div class="chat-msg-text">Tincidunt arcu non sodales😂</div>
                                    </div>
                                </div>
                                <div class="chat-msg">
                                    <div class="chat-msg-profile">
                                        <img class="chat-msg-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%282%29.png" alt="" />
                                        <div class="chat-msg-date"><span>Jayesh Watson</span> | 3.16pm</div>
                                    </div>
                                    <div class="chat-msg-content">
                                        <div class="chat-msg-text">Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et🥰</div>
                                        
                                        <div class="text typing">
                                            <div class="wave">
                                                <span class="dot"></span>
                                                <span class="dot"></span>
                                                <span class="dot"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                                    
                                 

                                <div class="chat-area-group">
                                    <img class="chat-area-profile" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%283%29+%281%29.png" alt="" />
                                    <img class="chat-area-profile" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%282%29.png" alt="" />
                                    <img class="chat-area-profile" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%2812%29.png" alt="" />
                                    <span>+4</span>
                                </div> */}
                  </div>

                  <InputText
                    isGroupChatWindow={this.state.isGroupChatWindow}
                    selectedEmoji={this.state.selectedEmoji}
                    isEmoji={this.state.isEmoji}
                    selectEmoji={this.selectEmoji}
                    openEmoji={this.openEmoji}
                    sendMsg={this.sendMsg}
                    ref={(textarea) => {
                      this.textInput = textarea;
                    }}
                    userDetails={userDetails}
                    name="message"
                    message={message}
                    label="Label Sample"
                    defaultValue={this.state.message}
                  />

                  {/* <div class="chat-area-footer">
                                        <div className="chat-control-area">
                                            <div className="inout-group">
                                                <textarea onKeyDown={this.onKeyDownHandler} autoFocus placeholder="Message" rows="1" value={message} onChange={(e) => this.setState({ message: e.target.value })} id="message" name='message'></textarea>
                                                <div className="chat-emoji"><i class="far fa-smile"></i></div>
                                            </div>
                                            <button className="chat-send-btn" disabled={this.state.message === ""} onClick={this.sendMsg}><i class="far fa-paper-plane"></i></button>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Prop types of props.
OneToOneChat.propTypes = {
  API_HOST: PropTypes.string,
  memberListResponse: PropTypes.any,
};

// Set default props.
OneToOneChat.defaultProps = {
  memberListResponse: null,
};

const mapStateToProps = (state) => ({
  API_HOST: getApiHost(),
  getUserDetailResponse:
    state.dashboardReducer.getUserDetailResponse.userDetailResponse,
});

export default connect(mapStateToProps, {
  getUserDetail,
  addSearchTag,
  getSearchTags,
})(OneToOneChat);
