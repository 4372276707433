import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MultipleEmailCTX from "./MultipleEmailCTX";
import { Link } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import "./MultipleEmail.css";


class MultipleEmail extends React.Component {
  state = {
    fields: {},
    errors: {},
    selectedWorkspace: 'null'
  };

  componentDidMount = () => {
    document.title = this.props.title; // Set title of page.
    const userEmail = localStorage.getItem("userEmail");
    //const password = localStorage.getItem("password");
    this.fetchMultipleEmail(userEmail);
  
  };
  

  fetchMultipleEmail = async (email_id) => {
    //await this.props.fetchMultipleEmail(this.props.API_HOST);
    const { API_HOST, fetchMultipleEmail } = this.props;
    await fetchMultipleEmail(API_HOST, email_id);
  };
  

  fetchMultipleWorkspace = async (tenantId) => {
    const userEmail = localStorage.getItem("userEmail");
    const password = localStorage.getItem("password");
    
    const { API_HOST, fetchMultipleWorkspace } = this.props;
    if(tenantId !== ""){
        const multipleWorkspaceRequest = {
          EmailId: userEmail,
          Password: password,
          Id_Tenant: tenantId.Id
        };
        await fetchMultipleWorkspace(API_HOST, multipleWorkspaceRequest);

        //Redirect to Dashboard
            if (this.props.multipleWorkspaceResponse) { 
              this.props.history.push("/dashboard");
            }

      }
      
      // else{
      //   toast.error("Please select your Workspace");
      // }
    
  };

  
  handleChange = (data) => {
    data.preventDefault();
    this.setState({ selectedWorkspace: data.target.value });
    this.fetchMultipleWorkspace(data.target.value);
    
  };



  // onSubmitButton = (event) => {
  //   event.preventDefault();
  //   this.fetchMultipleWorkspace();
  // };


  render() {

    const {
      multipleEmailResponse
    } = this.props;

    const {
      selectedWorkspace,
    } = this.state;
    

    return (
      
      <div className="wrapper">
        <div className="login-panel">
          <div className="login-content">
            <img className="img" alt="logo.png" src="images/logo.png" />

            <div className="login-card card shadow">
              <div className="title">Select your workspace:</div>

              <form onSubmit={this.onSubmitLogin} noValidate>
                <div className="form-group">
                  
                  <FormControl variant="outlined" className="formControl" style={{width:'100%'}}>
                    <InputLabel id="demo-simple-select-outlined-label">Workspace</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedWorkspace}
                      onChange={this.handleChange}
                      label="Workspace"
                    >
                      <MenuItem value='null' disabled>
                        <em>Select Workspace</em>
                      </MenuItem>
                      
                          {multipleEmailResponse.map((mul_email) => {
                        return (
                          <MenuItem key={mul_email.Id} value={mul_email} >
                            {mul_email.Name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>                 
                </div>
               
                {/* <button type="submit" className="btn btn-primary" onClick={this.onSubmitButton}>
                  Go to Dashboard
                </button> */}
                
                
              </form>
            </div>

            <div className="policy-link">
              <span>By signin up, you agree to our</span>
              <Link to={"/termsandconditions"} className="have-acc">
                Terms and Conditions
              </Link>
              {/* <a href="/#" className="have-acc">
                Terms and Conditions
              </a> */}{" "}
              &{" "}
              <Link to={"/privacypolicy"} className="have-acc">
                Privacy Policy
              </Link>
              {/* <a href="/#" className="have-acc">
                Privacy Policy
              </a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Prop types of props.
MultipleEmail.propTypes = {
  API_HOST: PropTypes.string,

  multipleEmailLoading: PropTypes.bool,
  fetchMultipleEmail: PropTypes.func,
  multipleEmailResponse: PropTypes.array,

  multipleWorkspaceLoading: PropTypes.bool,
  fetchMultipleWorkspace: PropTypes.func,
  multipleWorkspaceResponse: PropTypes.any
};

// Set default props.
MultipleEmail.defaultProps = {
  fetchMultipleEmail: () => {},
  multipleEmailLoading: false,
  multipleEmailResponse: [],

  fetchMultipleWorkspace: () => {},
  multipleWorkspaceLoading: false,
  multipleWorkspaceResponse: [],
  
};

export default compose(MultipleEmailCTX, withRouter)(MultipleEmail);
