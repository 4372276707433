import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectTimezone, { getTimezoneProps } from "@capaj/react-select-timezone";
import moment from 'moment';
import { socket } from "../../../utils/apiUrls";
import ClockLoader from "react-spinners/ClockLoader";
import { getUserListDepartmentUpdateOfficeTime } from "../../../actions/mainOfficeActions";
import { getApiHost } from "../../../utils/apiUrls";
import { updateUserTimezone } from "../../../actions/dashboardActions"

class ChangeTimeZone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {},
      error: {},
      userDetails: this.props.userDetails,
      src: "",
      isTimeZoneChange: false
    };
  }
  componentDidMount() {
    this.setState({ userDetails: this.props.userDetails })
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.userDetails !== this.props.userDetails) {
  //     this.setState({ userDetails: nextProps.userDetails });
  //   }
  // }

  handleChangeTimezone = (tz, label, offset) => {
    let userDetails = this.state.userDetails;
    userDetails["TZ"] = tz;
    userDetails["TZ_Label"] = label;
    userDetails["TZ_Offset"] = offset * 60;
    this.setState({ userDetails });
  }

  updateUserTimezone = (e, userDetails) => {
    socket.emit("UserDetail", userDetails);
    //this.props.updateUserTimezone(e,userDetails)
    this.updateUserTimezoneFun(userDetails)
  }

  updateUserTimezoneFun = (userDetailData) => {
    const { API_HOST, updateUserTimezone } = this.props;
    const updateUserRequest = {
      "TZ": userDetailData.TZ,
      "TZ_Label": userDetailData.TZ_Label,
      "TZ_Offset": userDetailData.TZ_Offset
    };
    this.setState({ isTimeZoneChange: true })
    updateUserTimezone(API_HOST, updateUserRequest, (res) => {
      if (res) {
        var userData = JSON.parse(localStorage.getItem('userdetail'));
        userData.TZ = userDetailData.TZ;
        userData.TZ_Label = userDetailData.TZ_Label;
        userData.TZ_Offset = userDetailData.TZ_Offset;
        localStorage.setItem('userdetail', JSON.stringify(userData));
        this.setState({ isTimeZoneChange: false })
        this.props.userValuesChanges(userData);
        this.getUserListDepartmentUpdateOfficeTime()
        this.props.fetchSearchUser(0)
      }
    })
  }

  getUserListDepartmentUpdateOfficeTime = async () => {
    const { API_HOST, getUserListDepartmentUpdateOfficeTime } = this.props;
    const requestBody = {
      // "Id_User" : 0,
      "Id_Department": JSON.parse(localStorage.getItem('userdetail')).Id_Department
    }

    // const requestBody = {
    //   IsPagging: true,
    //   PageNo: 1,
    //   PageSize: 10,
    //   SortingParameters: [
    //     {
    //       Property: "",
    //       Direction: 0
    //     }
    //   ],
    //   SearchCriteria: {
    //     Id_User: 0,
    //     "Id_Department": JSON.parse(localStorage.getItem('userdetail')).Id_Department
    //   }
    // };

    await getUserListDepartmentUpdateOfficeTime(API_HOST, requestBody);
  }

  render() {
    const {
      userDetails, isTimeZoneChange
    } = this.state;
    //console.log( this.state.userDetails)
    return (
      <div className="container-fluid pt-4 pb-4">
        <form className="form-horizontal">
          <div className="form-group">
            <div className="row">
              <label className=" control-label col-sm-2" style={{ marginTop: '1%' }}>TimeZone</label>
              <SelectTimezone
                className="col-sm-4 inputButton timezone-edit"
                value={userDetails && userDetails.TZ ? userDetails.TZ : "Asia/Kolkata"}
                //value={userDetails.TZ || ''}
                //value={userDetails ? userDetails.ZipCode : ""}
                clearable
                guess
                placeholder="Select time zone"
                onChange={value => {
                  const timezoneProps = getTimezoneProps(value)
                  this.handleChangeTimezone(timezoneProps.value, moment.tz(timezoneProps.value).format('zz'), timezoneProps.offset)

                }}
              />
              <Button color="primary" style={{ marginLeft: '2%' }} disabled={(JSON.parse(localStorage.getItem('userdetail')).TZ === userDetails.TZ)} onClick={(e) => this.updateUserTimezone(e, userDetails)}><span>Update</span>
                <span style={{ marginLeft: '5px' }}>
                  <ClockLoader
                    size={16}
                    color={"#fff"}
                    loading={isTimeZoneChange}
                  />
                </span>
              </Button>
            </div>
          </div>

        </form>
      </div>
    );
  }
}

ChangeTimeZone.defaultProps = {
  myAvatar: {},
  onselected: () => { },
  toggle: () => { },
  userDetails: {},
  updateUser: () => { },
};

ChangeTimeZone.propTypes = {
  myAvatar: PropTypes.object,
  onselected: PropTypes.func,
  toggle: PropTypes.func,
  userDetails: PropTypes.object,
  updateUser: PropTypes.func,
  API_HOST: PropTypes.string,
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  //   getDepartmentsResponse: state.getDepartmentReducer.getDepartmentsResponse
})

export default connect(mapStateToProps, { getUserListDepartmentUpdateOfficeTime, updateUserTimezone })(ChangeTimeZone);