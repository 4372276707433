import { types as T } from '../actions/action';
import { defaultStore } from '../store';

const slackconfigReducer = (state = defaultStore, action) => {
	switch (action.type) {

		case T.RECEIVE_SLACK_CONFIG:
			return {...state, 
				slackconfigResponse: {
					...state.slackconfigReducer,
					loading: false,
					slackconfigError: false,
					slackconfigErrorMessage: null,
					slackconfigResponse: action.slackconfigResponse
				},
			};

		default:
			return state
	}
}

export default slackconfigReducer;