import React from 'react';
import { Modal, ModalBody, ModalHeader, FormGroup, ModalFooter, Button } from 'reactstrap';
import { Table } from 'reactstrap';
import { saveFeedback } from "../../../actions/contactFeedbackActions";
import { connect } from 'react-redux';
import { getApiHost } from "../../../utils/apiUrls";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import ClockLoader from "react-spinners/ClockLoader";

class FeedBack extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: 'true',
      description: '',
      disabled: false
    };
  }

  closeFeedBack = () => {
    this.props.toggleFeedBack();
  }

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value
    });
  }


  saveFeedback = (e) => {
    e.preventDefault();
    this.setState({
      disabled: true
    })
    const { API_HOST, saveFeedback } = this.props;
    const feedbackRequest = {
      "IsRecommended": this.state.selectedOption,
      "Remarks": this.state.description
    };
    saveFeedback(API_HOST, feedbackRequest, (res) => {
      toast.success("Feedback saved successfully!");
      this.closeFeedBack()
    });
  }

  render() {
    const { selectedOption, disabled } = this.state;
    return (
 
      <div
          className="model-right-bar to_do_modal rightbar_sm"
          id="userloginmodel"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"  
      >

      <div className="modal-dialog jira-task-popup" role="document">

      <div className="modal-content">

        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
            Please fill in your feedback :   
            </h5>
            <div
            className="close b-left"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.closeFeedBack}
            style={{ cursor: "pointer" }}
            >
            <i className="fa fa-close" />
            </div>
        </div>

        <div className=" modal-body feedback-class">
        <form method="post" id="feedback">
        <FormGroup>
              <label class="form-label" >
                1. Would you recommend Precis to a friend?
                  {/* <span class="form-required">
                    *
                  </span> */}
              </label>

              <div className="table-responsive">
                <Table className="rating-tbl" style={{ width: "30px" }}>
                  <tbody>
                    <tr>
                      <td><input type="radio" value='true' checked={selectedOption === 'true'} onChange={this.handleOptionChange} /></td>
                      <td>Yes</td>

                      <td><input type="radio" value='false' checked={selectedOption === 'false'} onChange={this.handleOptionChange} /></td>
                      <td>No</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </FormGroup>

            <FormGroup>
              <label class="form-label" >
                2.  What more you would like to add to Precis?
                      {/* <span class="form-required">
                        *
                      </span> */}
              </label>
              <textarea className="form-control" rows="7" placeholder="Your Message..." required onChange={(e) => { this.setState({ description: e.target.value }) }}></textarea>
            </FormGroup>
            </form>
       </div>
      
        

        <div className="modal-footer justify-content-center">
        <Button onClick={this.saveFeedback} color="primary" type="submit" disabled={disabled}><span>Send</span>
              <span style={{ marginLeft: '5px' }}><ClockLoader
                size={16}
                color={"#fff"}
                loading={disabled}
              /></span>
            </Button>
        </div>
        

      </div>
        
      </div>

    </div>



     
    );
  }
}
// Prop types of props.
FeedBack.propTypes = {
  API_HOST: PropTypes.string,
};


const mapStateToProps = state => ({
  API_HOST: getApiHost(),
})

export default connect(mapStateToProps, { saveFeedback })(FeedBack)
