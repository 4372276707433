import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getApiHost } from "../../utils/apiUrls";
import { zoomUrlSplit } from "../../utils/utility";
import { getReminderList } from "../../actions/todoActions";
import { getUserMeetingbyId, deleteMeeting, meetingAttendOrNot, updateusergooglerefreshtoken, setmeeting_statuswithid_event, deleteMeetingInPrecisWithEventId, getGoogleConfig } from "../../actions/googleCalendarMeetingActions";
import { doorClose, setUserCustomStatus } from "../../actions/mainOfficeActions";
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import ScheduleMeeting from './ScheduleMeeting'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import { toast } from 'react-toastify';
import ZoomJoin from '../../layout/dashboard/components/ZoomJoin';
import $ from 'jquery';
//import { CustomLoadingSpiner } from '../loader/customLoader';
import { Scrollbars } from 'react-custom-scrollbars';
import { zoomMeetingJoinLeave } from "../../actions/zoomActions";
import LoadingOverlay from 'react-loading-overlay';
import { getEncryptedValue, getDecryptedValue } from "../../utils/utility";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import googleicon from "../../assets/images/google-ic.svg";

let events = [];
let localizer = momentLocalizer(moment);
let calendarView = 'day';
let date = new Date();

function ToolTip(props) {
    const event = props.event.event;
    //console.log("Props : ", props);
    const attendees = event.attendees;
    let listItems = [];

    if (attendees) {
        listItems = attendees.map((obj, index) =>
            <li key={index}>{obj.Name_User ? obj.Name_User : obj.email}</li>
        );
    }
    function handleHide() {
        $('#popover-trigger-click-root-close').css('display', 'none')
    }

    const joinGmeet = (gMeetLink)=> {
        let currentDate = new Date();
        let userData = JSON.parse(localStorage.getItem('userdetail'));
        if (userData.Is_DoorOpen === true && currentDate > event.start && currentDate < event.end) {
             doorClosefunc(gMeetLink);
        }else{
            if(currentDate < event.start)
            {
               toast.info("Sorry meeting has not yet started.");
            }
            if(currentDate > event.end)
            {
               toast.info("Sorry meeting time has ended.");
            }
        }
        // window.location.href = gMeetLink;
    }

    const doorClosefunc = (gMeetLink)=> {
        
        const { API_HOST, doorClose } = props;
        let userDetails = JSON.parse(localStorage.getItem('userdetail'));
        userDetails["Is_DoorOpen"] = false;
        userDetails["UserStatus"] = "In a Meeting";
        props.userValuesChanges(userDetails);
         doorClose(API_HOST, JSON.parse(localStorage.getItem('userdetail')).Id_User, (res) => {
            if(res){
                
            }
        });

         setMeetingCustomStatus("In a Meeting", gMeetLink);
    }

    const setMeetingCustomStatus = async(value, gLink)=> {
        
        const { API_HOST, setUserCustomStatus } = props;
        let requestBody = {
            UserStatus: value
        }
        await setUserCustomStatus(API_HOST, requestBody, (res) => {
            if(res){
                
            }
        });

        window.open(gLink,'_blank');
    }

    let popoverClickRootClose = (

        <Popover id="popover-trigger-click-root-close" style={{ zIndex: 9999, overflow: 'hidden', height: '280px' }}>
            <Scrollbars style={{ width: '400px', height: '280px' }} autoHide>
                <Popover.Title as="h3">{event.title}{event.is_host === true && <i style={{ float: 'right', cursor: 'pointer', color: '#ff5c33', marginLeft: '10px' }} onClick={() => { event.deleteMeetingByHost(event.meetingDelId, event.eventId); handleHide(); }} class="fa fa-trash" aria-hidden="true"></i>}
                    <i class="fa fa-close" style={{ float: 'right', cursor: 'pointer', color: "#2c69d1" }} onClick={() => handleHide()}></i></Popover.Title>
                <Popover.Content>
                    <i class="fas fa-user" style={{ fontSize: '16px', color: '#809fff' }}></i>&nbsp;&nbsp;&nbsp;{event.creatorName}
                </Popover.Content>
                <Popover.Content>
                    <i class="fa fa-clock-o" style={{ fontSize: '16px', color: '#00b300' }} aria-hidden="true"></i> &nbsp;{moment(event.start).format('LT') + ' - ' + moment(event.end).format('LT')}
                </Popover.Content>
                {attendees && <Popover.Content>
                    <i class="fa fa-users" style={{ fontSize: '16px', color: '#ff8080' }} aria-hidden="true"></i>&nbsp;&nbsp;<b>Guests</b><ul>{listItems}</ul>
                </Popover.Content>}
                <Popover.Content>
                <div><i class="fa fa-sticky-note-o" style={{ fontSize: '16px', color: '#839192' }} aria-hidden="true"></i>&nbsp;&nbsp;<b>Description</b><br />{event.description}</div>
                </Popover.Content>
                {/* {event.meeting && event.meeting !== "" && */}
                    <Popover.Content>
                        {/* <div><i class="fa fa-sticky-note-o" style={{ fontSize: '16px', color: '#839192' }} aria-hidden="true"></i>&nbsp;&nbsp;<b>Meeting link</b><br /><span dangerouslySetInnerHTML={{ __html: event.meetingLink ? event.meetingLink.replace(/\n/g, "<br />") : event.meetingLink }} /></div> */}
                        <div className="mb-3"><i class="far fa-handshake" style={{ fontSize: '16px', color: '#839192' }} aria-hidden="true"></i>&nbsp;&nbsp;<b>Meeting link</b></div>
                        <div className="mb-3 d-flex align-items-center">
                            <img src={googleicon} style={{ height: '20px', width: '20px' }} />
                            {/* <a className="btn btn-primary ml-2" href={event.meetingLink} target="_blank">{event.meetingLink}</a> */}
                            <button className="btn btn-primary ml-2" onClick={() => joinGmeet(event.meetingLink)}>Join meeting</button>
                            <i style={{color: 'grey', width: '120px', cursor:'pointer'}} class="fas fa-copy" onClick={() => {navigator.clipboard.writeText(event.meetingLink)}}></i>
                        </div>
                    </Popover.Content>
                    {/* } */}
                {event.location && event.location !== "" ?
                    <Popover.Content>
                        <Button onClick={() => { handleHide(); event.zoomJoin(event.location, event.is_host) }} type="button" className=" btn btn-primary col-sm-12">
                            <i className="fas fa-video"></i>
                            {event.is_host ?
                                <span style={{ marginLeft: '10px' }}>Start Meeting</span>
                                : <span style={{ marginLeft: '10px' }}>Join Meeting</span>}
                        </Button>
                    </Popover.Content>
                    : null}
            </Scrollbars>
        </Popover>
    );

    return (
        <ContextMenuTrigger id={"meeting" + event.meetingId}>
            <OverlayTrigger trigger="click" id="help" rootClose container={this} placement="bottom" overlay={popoverClickRootClose} >
                {event.meeting_status === 2 ? <div><del>{event.title}</del></div> : <div>{event.title}</div>}
            </OverlayTrigger>
        </ContextMenuTrigger>
    );
}

const mapStateToPropsToolTip = state => ({
    API_HOST: getApiHost(),
    meetingListResponse: state.meetingReducer.meetingResponse
});

const ToolTipFun = connect(mapStateToPropsToolTip, { doorClose, setUserCustomStatus })(ToolTip);
 
class GoogleCalendarMetting extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isModal: false,
            sheduleModal: false,
            eventCard: [],
            x: 0,
            y: 0,
            startDate: new Date(date.getFullYear(), date.getMonth(), 1),
            endDate: new Date(date.getFullYear(), date.getMonth() + 1, 1),
            meetingList: null,
            currentInstance: this,
            title: '',
            selectedOption: null,
            MeetingId: 0,
            token: '',
            eventsList: [],
            googleId: "",
            calendarView: 'day',
            isNewToken: false,
            isNewRefreshTokenGenerate: false,
            googleEventsList: [],
            isZoomModal: false,
            zoomUrl: '',
            eventsOfPrecis: [],
            loading: true,
            isCustomStatus: false,
            reminderEvent: [],
            clientId: "",
            clientSecret: "",
            clientScope: ""
        }
    }

    async componentDidMount() {
        await this.getGoogleConfig()
        //await this.showLoader();

        await this.getUserMeetingbyId(this.state.startDate, this.state.endDate);

        await this.getUserReminderList(this.state.startDate, this.state.endDate);
        await this.setState({loading:false})
        // await this.reminder();
        //await this.closeLoader();

    }

    getGoogleConfig = async () => {
        const { API_HOST, getGoogleConfig } = this.props;
        await getGoogleConfig(API_HOST, (res) => {
            if (res[0]) {
                this.setState({
                    clientId: getDecryptedValue(res[0].GoogleClientId),
                    clientSecret: getDecryptedValue(res[0].GoogleClientSecretkey),
                    clientScope: res[0].Scope
                })
                if (JSON.parse(localStorage.getItem('userdetail')).Google_Refresh_Token !== '') {
                    this.getAccessTokenFromRefreshToken(JSON.parse(localStorage.getItem('userdetail')).Google_Refresh_Token);
                }
            }
        });
    }

    async componentDidUpdate(prevState) {
        if (this.props.meetingListResponse !== prevState.meetingListResponse) {
            //await this.showLoader()
            await this.setPrecisCalendarSchedule(this.props.meetingListResponse)
            // await this.closeLoader()
        }
    }

    getAccessTokenFromRefreshToken = async (refreshToken) => {
        if (this.state.clientId !== "") {
            try {
                let finalUrl = 'https://accounts.google.com/o/oauth2/token';
                let resource = {
                    "client_id": this.state.clientId,
                    "grant_type": "refresh_token",
                    "client_secret": this.state.clientSecret,
                    "refresh_token": getDecryptedValue(refreshToken),
                    "prompt":"consent"
                }

                let headers = {
                    "Content-Type": "application/json",
                };
                const data = await axios.post(finalUrl, resource, { headers });
                this.setState({ token: data.data.access_token })
                await this.getGoogleEvents(data.data.access_token, new Date(this.state.startDate), new Date(this.state.endDate))
                return data
            }
            catch (error) {
                toast.error(error)
            }
        }
        else {
            // toast.error("Your workspace is not connected with google calendar.Please inform your admin to connect with google")
        }
    }

    getGoogleEvents = async (token, startDate, endDate) => {
        this.setState({loading:true})
        try {
            let calendarId = 'primary';
            let sd = new Date(startDate)
            let ed = new Date(endDate)
            let minDate = moment().tz(this.props.userDetails.TZ).set({ 'year': sd.getFullYear(), 'month': sd.getMonth(), 'date': sd.getDate(), 'hour': 0, 'minute': 0, 'second': 0 }).toISOString();
            let maxDate = moment().tz(this.props.userDetails.TZ).set({ 'year': ed.getFullYear(), 'month': ed.getMonth(), 'date': ed.getDate(), 'hour': 23, 'minute': 59, 'second': 59 }).toISOString();
            let finalUrl = 'https://www.googleapis.com/calendar/v3/calendars/' + calendarId + '/events?singleEvents=true&maxResults=250&timeMin=' + minDate + '&timeMax=' + maxDate;
            let headers = {
                "Authorization": "Bearer " + token
            };

            const { data } = await axios.get(finalUrl, { headers });
            await this.setCalendarSchedule(data.items)
            this.setState({ startDate: new Date(minDate), endDate: new Date(maxDate) })
            return data;
        } catch (error) {
            toast.error(error)
            this.setState({loading:false})
        }
    }

    setCalendarSchedule = async (data) => {
        console.log("Events data : ", data);

        events = [];

        for (let i = 0; i < data.length; i++) {
            events.push({
                meetingId: data[i].id,
                meetingDelId: data[i].id,
                title: data[i].summary,
                start: new Date(moment(data[i].start.dateTime).tz(this.props.userDetails.TZ).format("MMMM DD, YYYY HH:mm:ss")),
                end: new Date(moment(data[i].end.dateTime).tz(this.props.userDetails.TZ).format("MMMM DD, YYYY HH:mm:ss")),
                creatorName: data[i].creator.email,
                creatorId: data[i].creator.email,
                is_host: data[i].creator.self ? data[i].creator.self : false,
                meeting_status: data[i].status,
                is_deleted: false,
                eventId: data[i].id,
                deleteMeetingByHost: this.deleteMeetingByHost,
                attendees: data[i].attendees ? data[i].attendees : null,
                description: data[i].description,
                extendedProperties: data[i].extendedProperties ? data[i].extendedProperties : "",
                location: data[i].location ? data[i].location : "",
                zoomJoin: this.zoomJoin,
                meetingLink: data[i].hangoutLink
            })

        }

        let newEventsList = await events.concat(this.state.meetingList)
        newEventsList = newEventsList.concat(this.state.reminderEvent)
        await this.setState({ eventsList: newEventsList, googleEventsList: events })
        await this.setState({loading:false})
    }

    getUserReminderList = (startDate, endDate) => {
        const { API_HOST, getReminderList } = this.props;
        var userData = JSON.parse(localStorage.getItem('userdetail'));
        const reminderRequest = {
            "Id_User": 0,
            "Start_Date": moment(startDate).format('YYYY-MM-DD'),
            "End_Date": moment(endDate).format('YYYY-MM-DD')
        };

        getReminderList(API_HOST, reminderRequest, (res) => {
            let events = []
            if (res.length > 0 && res[0] !== null) {
                for (let i = 0; i < res.length; i++) {
                    events.push({
                        title: "To Do Reminder",
                        description: res[i].description,
                        start: new Date(res[i].DueDate),
                        end: new Date(res[i].DueDate),
                        eventId: res[i].Id,
                        isReminder: res[i].Is_Reminder,
                        creatorName: userData.EmailId
                    })
                }
                let newEventsList = events.concat(this.state.meetingList)
                let temp = newEventsList.concat(this.state.googleEventsList)
                this.setState({ eventsList: temp, reminderEvent: events })
            }
        });
    }

    getUserMeetingbyId = async (startDate, endDate) => {
        const { API_HOST, getUserMeetingbyId } = this.props;
        // const userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;
        const reqbody = {
            "Id_User": 0,
            "Start_Date": moment(startDate).format('YYYY-MM-DD'),
            "End_Date": moment(endDate).format('YYYY-MM-DD')
        }
        this.setState({ startDate: startDate, endDate: endDate, loading: true })
        await getUserMeetingbyId(API_HOST, reqbody, (res) => {
            this.setState({ loading: false })
        });
        console.log("Events meeting response : ", this.props.meetingListResponse);
        await this.setPrecisCalendarSchedule(this.props.meetingListResponse)

    }

    setPrecisCalendarSchedule = (data) => {
        console.log("Events 123 :: ", data);
        events = [];
        for (let i = 0; i < data.length; i++) {
            let temp = data[i].UserMeetingDetailList;
            for (let j = 0; j < temp.length; j++) {
                const sd = moment(temp[j].Start_Date).tz(this.props.userDetails.TZ).toDate();
                const ed = moment(temp[j].End_Date).tz(this.props.userDetails.TZ).toDate();
                if (JSON.parse(localStorage.getItem('userdetail')).Id_User === temp[j].Id_User) {
                    events.push({
                        meetingId: temp[j].Id,
                        meetingDelId: temp[j].Id_UserMeeting,
                        title: temp[j].Title,
                        start: new Date(sd.getFullYear(), sd.getMonth(), sd.getDate(), 0, parseInt(temp[j].Start_Time), 0, 0),
                        end: new Date(ed.getFullYear(), ed.getMonth(), ed.getDate(), 0, parseInt(temp[j].End_Time), 0, 0),
                        creatorName: temp[j].Name_HostUser,
                        creatorId: temp[j].Id_HostUser,
                        is_host: temp[j].Is_Host,
                        meeting_status: temp[j].Meeting_Status,
                        is_deleted: temp[j].Is_Deleted,
                        eventId: temp[j].Id_Event,
                        deleteMeetingByHost: this.deleteMeetingByHost,
                        zoomJoin: this.zoomJoin,
                        attendees: temp,
                        description: temp[j].Description,
                        extendedProperties: data[i].extendedProperties ? data[i].extendedProperties : "",
                        location: data[i].MeetingNumber ? process.env.REACT_APP_ZOOM_URL + '/' + window.btoa(data[i].MeetingNumber) + '/' + window.btoa(data[i].MeetingPassword) : "",
                        meetingLink: data[i].MeetingNumber
                    })
                }
            }
        }

        if (JSON.parse(localStorage.getItem('userdetail')).Google_Refresh_Token === '') {
            let eventConcatList = events.concat(this.state.reminderEvent)
            this.setState({ eventsList: eventConcatList })
        }
        else {
            let eventsListWithoutEventId = events.filter((event) => event.eventId === null || event.eventId === "" || event.meeting_status === 2);
            let newEventsList = eventsListWithoutEventId.concat(this.state.googleEventsList)
            newEventsList = newEventsList.concat(this.state.reminderEvent)
            this.setState({ meetingList: eventsListWithoutEventId, eventsList: newEventsList, eventsOfPrecis: events })
        }
    }

    acceptRejectMeeting = async (e, data) => {
        //await this.showLoader();
        if (this.state.token === '') {
            await this.acceptRejectPrecisMeeting(data)
        }
        else {
            if (data.eventId === "" || data.eventId === null) {
                await this.acceptRejectPrecisMeeting(data)
            }
            else {

                await this.acceptRejectGooglecalendar(data.status, data.eventId)

                if (this.state.eventsOfPrecis.find((meeting) => meeting.eventId === data.eventId)) {
                    await this.acceptRejectPrecisMeetingWithEventId(data)
                }

            }
        }
        // await this.closeLoader()
    }


    acceptRejectGooglecalendar = async (statusCode, eventId) => {
        try {
            let calendarId = 'primary';
            let finalUrl = 'https://www.googleapis.com/calendar/v3/calendars/' + calendarId + '/events/' + eventId + '?sendNotifications=true&sendUpdates=all';
            let token = this.state.token;

            let status = "confirmed";
            if (statusCode === 2) {
                status = "cancelled";
            }
            let headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }

            let resource = {
                status: status
            }

            await axios.patch(finalUrl, resource, { headers });
            await this.getGoogleEvents(this.state.token, this.state.startDate, this.state.endDate);
        }
        catch (error) {
            toast.error(error)
        }
    }

    acceptRejectPrecisMeetingWithEventId = async (data) => {
        const { API_HOST, setmeeting_statuswithid_event } = this.props;
        let reqBody = {
            "Id_Event": data.eventId,
            "Meeting_Status": data.status
        }
        await setmeeting_statuswithid_event(API_HOST, reqBody, (res) => {
            this.getUserMeetingbyId(this.state.startDate, this.state.endDate);
        })
    }

    acceptRejectPrecisMeeting = async (data) => {
        const { API_HOST, meetingAttendOrNot } = this.props;
        let reqBody = {
            "Id": data.meetingId,
            "Meeting_Status": data.status
        }
        await meetingAttendOrNot(API_HOST, reqBody, (res) => {
            this.getUserMeetingbyId(this.state.startDate, this.state.endDate);
        })
    }


    deleteMeetingByHost = async (id, eventId) => {
        this.setState({ loading: true })
        if (this.state.token === '') {
            await this.deleteMeetingInPrecis(id);
        }
        else {
            if (eventId === "" || eventId === null) {
                await this.deleteMeetingInPrecis(id);
            }
            else {
                await this.googleDeleteEvent(eventId);
                if (this.state.eventsOfPrecis.find((meeting) => meeting.eventId === eventId)) {
                    await this.deleteMeetingInPrecisWithEventId(eventId);
                }
            }
        }
        //await this.closeLoader()
    }

    googleDeleteEvent = async (eventId) => {
        try {
            let calendarId = 'primary';
            let finalUrl = 'https://www.googleapis.com/calendar/v3/calendars/' + calendarId + '/events/' + eventId + '?sendNotifications=true&sendUpdates=all';
            let token = this.state.token;

            let headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            };
            await axios.delete(finalUrl, { headers });
            await this.getGoogleEvents(this.state.token, this.state.startDate, this.state.endDate);
            return
        } catch (error) {
            toast.error(error)
        }

    }

    deleteMeetingInPrecisWithEventId = async (eventId) => {
        const { API_HOST, deleteMeetingInPrecisWithEventId } = this.props;
        //const userId = JSON.parse(localStorage.getItem('userdetail')).Id_User;

        await deleteMeetingInPrecisWithEventId(API_HOST, eventId, (res) => {
            this.getUserMeetingbyId(this.state.startDate, this.state.endDate);
        })
        //await this.getUserMeetingbyId(this.state.startDate, this.state.endDate);
    }

    deleteMeetingInPrecis = async (id) => {
        const { API_HOST, deleteMeeting } = this.props;
        await deleteMeeting(API_HOST, id, (res) => {
            this.getUserMeetingbyId(this.state.startDate, this.state.endDate);
        })
    }

    newEventAdded = async () => {
        //await this.showLoader()
        this.getUserMeetingbyId(new Date(new Date(this.state.startDate).getFullYear(), new Date(this.state.startDate).getMonth(), 1), new Date(new Date(this.state.endDate).getFullYear(), new Date(this.state.endDate).getMonth() + 1, 1));
        if (this.state.token !== "") {
            await this.getGoogleEvents(this.state.token, new Date(new Date(this.state.startDate).getFullYear(), new Date(this.state.startDate).getMonth(), 1), new Date(new Date(this.state.endDate).getFullYear(), new Date(this.state.endDate).getMonth() + 1, 1))
        }
        // await this.closeLoader()
    }


    reminder = () => {
        setInterval(() => {
            var data = this.state.eventsList;
            for (var i = 0; i < data.length; i++) {
                var now = moment(new Date());
                var then = data[i].start;
                var diff = moment.duration(moment(then).diff(moment(now)));

                var days = parseInt(diff.asDays()); //84
                var hours = parseInt(diff.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.
                hours = hours - days * 24;  // 23 hours

                var minutes = parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.
                minutes = minutes - (days * 24 * 60 + hours * 60); //20 minutes.

                var twodaybefor = (days + ':' + hours + ':' + minutes).toString();
                var tenminbefor = (days + ':' + hours + ':' + minutes).toString();

                if (tenminbefor === '0:0:10') {
                    toast.info("Hey ! Only " + minutes + " min. remaining to start the meeting.", { autoClose: 60 * 1000 });
                }
                if (twodaybefor === "2:0:0") {
                    toast.info("Hey ! Only " + days + " days remaining to start the meeting.", { autoClose: 60 * 1000 });
                }
            }
        }, 60 * 1000);
    }

    responseGoogleRefresh = (response) => {
        this.setState({loading: true})
        if(response.code !== undefined){
            this.getAccessToken(response.code)
        }
        
    }

    getAccessToken = async (code) => {
        if (this.state.clientId !== "") {
            try {
                let finalUrl = 'https://accounts.google.com/o/oauth2/token';
                let resource = {
                    "client_id": this.state.clientId,
                    "code": code,
                    "grant_type": "authorization_code",
                    "client_secret": this.state.clientSecret,
                    "redirect_uri": `${window.location.protocol}//${window.location.host}`,
                }

                let headers = {
                    "Content-Type": "application/json",
                };
                const data = await axios.post(finalUrl, resource, { headers });
                //await this.updateUserRefreshToken(data.data.refresh_token);
                await this.getGoogleEvents(data.data.access_token, this.state.startDate, this.state.endDate)
                this.setState({
                    token: data.data.access_token,
                })

            }
            catch (error) {
                toast.error(error)
                this.setState({loading: false})
            }
        }
        else {
            //divya
            toast.info("Your workspace is not connected with google calendar.Please inform your admin to connect with google")
        }
    }

    updateUserRefreshToken = async (refreshToken) => {
        const { API_HOST, updateusergooglerefreshtoken } = this.props;
        await updateusergooglerefreshtoken(API_HOST, getEncryptedValue(refreshToken), (res) => {
            if (res) {
                let userData = JSON.parse(localStorage.getItem('userdetail'));
                userData.Google_Refresh_Token = getEncryptedValue(refreshToken);
                localStorage.setItem('userdetail', JSON.stringify(userData));

            }
        })
    }

    responseGoogle = async (response) => {
        this.getGoogleEvents(response.accessToken, this.state.startDate, this.state.endDate)
        this.setState({
            token: response.accessToken,
        })

    }

    toggle = () => {
        this.setState({
            isModal: false
        })
    }

    meetingFlagSet = () => {
        this.setState({
            sheduleModal: false
        })
    }

    tooltipAccessor = (event) => {
        return null
    }

    selectSlot = (slotInfo) => {
        if (slotInfo.action === 'doubleClick' || slotInfo.action === 'select') {
            this.setState({
                sheduleModal: true,
                startDate: moment(slotInfo.start).format("YYYY-MM-DD"),
                endDate: moment(slotInfo.end).format("YYYY-MM-DD"),
                title: '',
                selectedOption: null,
                MeetingId: 0
            })
        }
        this.toggle();
    }

    onCalendarViewChange = (e) => {
        this.setState({ calendarView: e });
        calendarView = e
    }

    onDateRangeChange = (e) => {
        if (calendarView === 'week') {
            this.getUserMeetingbyId(new Date(e[0]), new Date(e[e.length - 1]));
            this.getUserReminderList(new Date(e[0]), new Date(e[e.length - 1]));

            if (this.state.token !== "") {
                this.getGoogleEvents(this.state.token, new Date(e[0]), new Date(e[e.length - 1]))

            }
            this.setState({ startDate: new Date(e[0]), endDate: new Date(e[e.length - 1]) })
        }
        else if (calendarView === 'work_week') {

            this.getUserMeetingbyId(new Date(e[0]), new Date(e[e.length - 1]));
            this.getUserReminderList(new Date(e[0]), new Date(e[e.length - 1]));

            if (this.state.token !== "") {
                this.getGoogleEvents(this.state.token, new Date(e[0]), new Date(e[e.length - 1]))
            }
            this.setState({ startDate: new Date(e[0]), endDate: new Date(e[e.length - 1]) })
        }
        else if (calendarView === 'day') {
            if (e[0]) {
                this.getUserMeetingbyId(new Date(e[0]), new Date(e[0]));
                this.getUserReminderList(new Date(e[0]), new Date(e[0]));

                if (this.state.token !== "") {
                    this.getGoogleEvents(this.state.token, new Date(e[0]), new Date(e[0]))
                }
                this.setState({ startDate: new Date(e[0]), endDate: new Date(e[0]) })
            }
        }
        else {
            if (calendarView === 'month') {

                this.getUserMeetingbyId(new Date(e.start), new Date(e.end));
                this.getUserReminderList(new Date(e.start), new Date(e.end));

                if (this.state.token !== "") {
                    this.getGoogleEvents(this.state.token, new Date(e.start), new Date(e.end))
                }
                this.setState({ startDate: new Date(e.start), endDate: new Date(e.end) })
            }
        }
    }

    onShowMore = (e) => {
        this.getUserMeetingbyId(new Date(e[0].start), new Date(e[0].end));
        this.getUserReminderList(new Date(e[0].start), new Date(e[0].end));

        if (this.state.token !== "") {
            this.getGoogleEvents(this.state.token, new Date(e[0].start), new Date(e[0].end))
        }
        this.setState({ startDate: new Date(e[0].start), endDate: new Date(e[0].start) })

    }


    eventStyleGetter = (event, start, end, isSelected) => {
        var style;
        if (event.meeting_status === 2) {
            style = {
                backgroundColor: '#FF0000',
                backgroundImage: 'linear-gradient(270deg, rgb(243 50 50) 0%, rgb(214 8 8) 74%)',
                borderRadius: '5px',
                opacity: 0.8,
                display: 'block'
            };
        }

        if (event.isReminder) {
            style = {
                backgroundColor: '#10b759',
                backgroundImage: ' linear-gradient(270deg, #4ad888eb 0%, #1e864c 74%)',
                borderRadius: '5px',
                opacity: 0.8,
                display: 'block'
            };
        }
        return {
            style: style
        };

    }

    // Custom Loader Call
    // showLoader = () => {
    //     this.setState({
    //         loading: true
    //     })
    // }

    // closeLoader = () => {
    //     this.setState({
    //         loading: false
    //     })
    // }

    zoomJoin = async (zoomUrl, isHost) => {

        let getMeetNum = zoomUrlSplit(zoomUrl)
        await this.zoomMeetingJoinLeave(getMeetNum);

        let userData = JSON.parse(localStorage.getItem('userdetail'));
        let userName = userData.Display_Name;
        if (userData.Is_DoorOpen === true) {
            await this.doorClosefunc();
        }
        let temp = '';
        if (isHost === true) {
            temp = zoomUrl + '/' + 1 + '/' + userName;
        } else {
            temp = zoomUrl + '/' + 0 + '/' + userName;
        }
        this.setState({
            isZoomModal: !this.state.isZoomModal,
            zoomUrl: temp
        })

        setTimeout(() => {
            window.location.href = temp;
        }, 500);
    }

    doorClosefunc = async () => {
        const { API_HOST, doorClose } = this.props;
        let userDetails = JSON.parse(localStorage.getItem('userdetail'));
        userDetails["Is_DoorOpen"] = false;
        userDetails["UserStatus"] = "In a Meeting";
        this.props.userValuesChanges(userDetails);
        await doorClose(API_HOST, JSON.parse(localStorage.getItem('userdetail')).Id_User, (res) => {
            //if (res) {
            // //this.getUserListDepartmentFilter();
            // let userDetails = JSON.parse(localStorage.getItem('userdetail'));
            // userDetails["Is_DoorOpen"] = false;
            // userDetails["UserStatus"] = "In a Meeting";
            // this.props.userValuesChanges(userDetails);
            //$('.door-action').removeClass("d-closed");
            //}
        });

        await this.setUserCustomStatus("In a Meeting")
    }

    setUserCustomStatus = async (value) => {
        const { API_HOST, setUserCustomStatus } = this.props;
        let requestBody = {
            UserStatus: value
        }
        await setUserCustomStatus(API_HOST, requestBody, (res) => {

        });
    }

    zoomMeetingJoinLeave = (value) => {
        const { API_HOST, zoomMeetingJoinLeave } = this.props;
        let requestBody = {
            Meeting_Number: window.atob(value),
            IsJoining: true
        }
        zoomMeetingJoinLeave(API_HOST, requestBody, (res) => {

        });
    }

    setNewRefreshtoken = () => {
        if (this.state.clientId !== "") {
            //this.setState({ isNewRefreshTokenGenerate: true })
            window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${this.state.clientScope}&access_type=offline&include_granted_scopes=true&response_type=code&state=hello there&redirect_uri=${window.location.protocol}//${window.location.host}/googleconfig&client_id=${this.state.clientId}`;
        }
        else {
            toast.error(JSON.parse(localStorage.getItem('userdetail')).Is_Admin ? "You have to Add Google client configuration first" : "Your workspace is not connected with google calendar.Please inform your admin to connect with google")
        }
    }


    render() {
        events = this.state.eventsList;
        const menuItems = this.state.eventsList.map((event,index) => {
            var menu = '';
            if (event !== null && event.end > new Date()) {
                menu = (
                    <ContextMenu id={"meeting" + event.meetingId} key={index}>
                        < MenuItem data={{ status: 1, meetingId: event.meetingId, eventId: event.eventId }} onClick={this.acceptRejectMeeting}>
                            Accept
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem data={{ status: 2, meetingId: event.meetingId, eventId: event.eventId }} onClick={this.acceptRejectMeeting} >
                            Reject
                        </MenuItem>
                    </ContextMenu>
                )
            }
            return menu;
        });
        const { sheduleModal, startDate, endDate, selectedOption, title, MeetingId, token, isNewRefreshTokenGenerate, zoomUrl, isZoomModal, loading } = this.state;
        return (
            <>

        <Modal
            isOpen={this.props.isGoogleCalendarMeeting}
            toggle={this.props.onClickGoogleCalendarMeeting}
            size="xl"
            backdrop={false}
         >
        <ModalHeader toggle={this.props.onClickGoogleCalendarMeeting}>Meeting</ModalHeader>
        <ModalBody>


                {/* {this.state.loading && <CustomLoadingSpiner InProgress={this.state.loading} />} */}
                {JSON.parse(localStorage.getItem('userdetail')).Google_Refresh_Token === "" && this.state.clientId !== "" ?
                    <Button className=" col-sm-3 btn btn-primary mt-3" onClick={() => { this.setNewRefreshtoken() }} disabled={isNewRefreshTokenGenerate}>Connect To google</Button>
                    : null}
                {isNewRefreshTokenGenerate ?
                    <GoogleLogin
                        // clientId='396060105786-qt9n1d57ocqvm468dh86ivr0r5kg4kit.apps.googleusercontent.com'
                        // scope='https://www.googleapis.com/auth/calendar'
                        clientId={this.state.clientId}
                        scope={this.state.clientScope !== "" ? this.state.clientScope : 'https://www.googleapis.com/auth/calendar'}
                        // scope='https://www.googleapis.com/auth/calendar.acls'
                        onSuccess={this.responseGoogleRefresh}
                        accessType="offline"
                        responseType='code'
                        // approvalPrompt="force"
                        prompt='consent'
                        autoLoad={true}
                        isSignedIn={true}
                        cookiePolicy={'single_host_origin'}
                        render={renderProps => (
                            <button style={{ display: 'none' }} onClick={renderProps.onClick} disabled={renderProps.disabled}>Google</button>
                        )}
                        onFailure={() => {this.setState({ isNewRefreshTokenGenerate: false });toast.error("Unable to generate Google Token")}}
                    />
                    : null}
                {/* {isNewToken?
                <GoogleLogin
                    clientId='396060105786-qt9n1d57ocqvm468dh86ivr0r5kg4kit.apps.googleusercontent.com'
                    scope='https://www.googleapis.com/auth/calendar'
                    // scope='https://www.googleapis.com/auth/calendar.acls'
                    onSuccess={this.responseGoogle}
                    accessType="offline"
                    responseType='token'
                    // approvalPrompt="force"
                    // prompt='consent'
                    autoLoad={true}
                    isSignedIn={true}
                    cookiePolicy={'single_host_origin'}
                    
                    render={renderProps => (
                        <button style={{ display: 'none' }} onClick={renderProps.onClick} disabled={renderProps.disabled}>Google</button>
                    )}
                    onFailure={this.responseGoogle}
                />:null} */}

                {sheduleModal === true &&
                    <ScheduleMeeting token={token} MeetingId={MeetingId} selectedOption={selectedOption} sheduleModal={sheduleModal} title={title} meetingFlagSet={this.meetingFlagSet} startDate={startDate} endDate={endDate}
                        newEventAdded={this.newEventAdded} from={'gcalander'}
                    //newEventAddedInPrecis={this.newEventAddedInPrecis}
                    />}

                {this.state.isZoomModal === true &&
                    <ZoomJoin zoomUrl={zoomUrl} isZoomModal={isZoomModal} zoomModalJoin={this.zoomJoin} />
                }
                <LoadingOverlay
                    active={loading}
                    spinner
                    text='Loading...'
                >
                    <Calendar
                        localizer={localizer}
                        events={this.state.eventsList}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500, marginTop: '2%' }}
                        // onSelectEvent={this.updateShedule}
                        // onNavigate={this.toggle}
                        onShowMore={(e) => { this.onShowMore(e) }}
                        onView={(e) => { this.onCalendarViewChange(e) }}
                        onRangeChange={(e) => { this.onDateRangeChange(e) }}
                        onSelectSlot={this.selectSlot}
                        selectable={true}
                        defaultDate={moment().toDate()}
                        tooltipAccessor={this.tooltipAccessor}
                        // onDoubleClickEvent={this.updateShedule}
                        // components={{
                        //     event: ToolTipFun
                        // }}

                        //components={{ event: () => <ToolTipFun userValuesChanges={this.props.userValuesChanges} /> }}

                        // components={{
                        //     event: (event) => ToolTip(event, this.props.userValuesChanges),
                        //   }}

                        //components={{event: ToolTipFun(this.props.userValuesChanges)}}

                        components={{ event: (event) => <ToolTipFun userValuesChanges={this.props.userValuesChanges} event={event} /> }}
                        views={['month', 'week', 'work_week', 'day']}
                        view={this.state.calendarView}
                        eventPropGetter={(this.eventStyleGetter)}
                    />
                </LoadingOverlay>
                {menuItems}
                </ModalBody>
                </Modal>
            </>
        );
    }
}


GoogleCalendarMetting.defaultProps = {
    API_HOST: PropTypes.string,
    getUserMeetingbyId: PropTypes.func,
    deleteMeeting: PropTypes.func,
    meetingListResponse: PropTypes.array
};

// Set default props.
GoogleCalendarMetting.defaultProps = {
    getUserMeetingbyId: () => { },
    deleteMeeting: () => { },
    meetingAttendOrNot: () => { },
    getReminderList: () => { },
    setmeeting_statuswithid_event: () => { },
    meetingListResponse: []
};

const mapStateToProps = state => ({
    API_HOST: getApiHost(),
    meetingListResponse: state.meetingReducer.meetingResponse
})


export default connect(mapStateToProps, { zoomMeetingJoinLeave, getReminderList, getUserMeetingbyId, deleteMeeting, meetingAttendOrNot, updateusergooglerefreshtoken, setmeeting_statuswithid_event, deleteMeetingInPrecisWithEventId, doorClose, setUserCustomStatus, getGoogleConfig })(GoogleCalendarMetting)