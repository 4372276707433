import axios from "axios";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";


export const addWaterCoolerGroup = (url, reqBody, callback) => (dispatch) => {
    const finalUrl = `${url}Channel/setwatercooler`;
    axios.post(finalUrl, reqBody, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};


export const updateUserTour = (url, callback) => (dispatch) => {
    const finalUrl = `${url}usertenant/updateusertourcompleted`;
    axios.post(finalUrl, {}, getConfig())
        .then((response) => handleResponse(response))
        .then((result) => {
            if (!!callback) {
                callback(result)
            }
        })
        .catch((error) => {
            handleError(error, "error")
        })
};
