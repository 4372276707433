import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { types as T } from "./action";
import {
    handleError,
    handleResponse,
    getConfig,
} from "../utils/utility";

//Get Slack User Details
export const receiveSlackUserDetails = (slackUserDetailsResponse) => ({
  type: T.RECEIVE_SLACK_USER_DETAILS,
  slackUserDetailsResponse,
});

/**
 * Get Slack User Details.
 *
 * @param {url} URL is API host domain.
*/

export const slackUserDetails = (url, reqBody) => dispatch => {
  const finalUrl = `${url}usertenant/getuserbyslackid`;
  return trackPromise(
    axios
      .post(finalUrl, reqBody, getConfig())
      .then(response => handleResponse(response))
      .then(result => {
        dispatch(receiveSlackUserDetails(result));
      })
      .catch(error =>
      handleError(error, 'Failed to get Slack User Details ')
    )
  );
}


//Get Channel wise Slack User Details
export const receiveChannelWiseSlackUserDetails = (channelWiseSlackUserDetailsResponse) => ({
  type: T.RECEIVE_CHANNEL_WISE_SLACK_USER_DETAILS,
  channelWiseSlackUserDetailsResponse,
});

/**
 * Get Channel wise Slack User Details.
 *
 * @param {url} URL is API host domain.
*/

export const channelWiseSlackUserDetails = (url, channelId) => dispatch => {
  const finalUrl = `${url}ChannelUser/getchanneluserlistbyslackchannelid/${channelId}`; 
  return trackPromise(
    axios
      .post(finalUrl, {}, getConfig())
      .then(response => handleResponse(response))
      .then(result => {
        dispatch(receiveChannelWiseSlackUserDetails(result));
      })
      .catch(error =>
      handleError(error, 'Failed to get Channel wise Slack User Details ')
    )
  );
}

export const editTitle = (url,reqBody, callback) => (dispatch) => {
  const finalUrl = `${url}Channel/updatechannelname`;
  axios.post(finalUrl, reqBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch((error) => {
          handleError(error, "error")
      })
};


// get users list not present in a particular chat group
export const getOtherUsersList = (url, channelId, callback) => (dispatch) => {
  const finalUrl = `${url}UserTenant/getuserlisttoinviteuserforslackchannel/${channelId}`;
  axios.post(finalUrl, {}, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch((error) => {
          handleError(error, "error")
      })
};


// Add Users to existing group
export const addUserstoChatGroup = (url, reqBody, callback) => (dispatch) => {
  const finalUrl = `${url}slack/inviteusersforslackchannel`;
  axios.post(finalUrl, reqBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch((error) => {
          handleError(error, "error")
      })
};


// Send Request to Admin to Add in Slack Workspace
export const sendRequestForAddInWorkspace = (url, reqBody, callback) => (dispatch) => {
  const finalUrl = `${url}Slack/SendRequestForAddInWorkspace`;
  axios.post(finalUrl, reqBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch((error) => {
          handleError(error, "error")
      })
};

// Check User Request For Add In Workspace
export const checkUserRequestForAddInWorkspace = (url, callback) => (dispatch) => {
  const finalUrl = `${url}Slack/checkUserRequestForAddInWorkspace`;
  axios.get(finalUrl, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch((error) => {
          handleError(error, "error")
      })
};

// Get request list for add in workspace.
export const getRequestListForAddInWorkspace = (url, callback) => (dispatch) => {
  const finalUrl = `${url}Slack/getRequestListForAddInWorkspace`;
  axios.get(finalUrl, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch((error) => {
          handleError(error, "error")
      })
};

// Add User in Slack Workspace
export const inviteUserInSlackWorkspace = (url, reqBody, callback) => (dispatch) => {
  const finalUrl = `${url}Slack/inviteUsersForSlack`;
  axios.post(finalUrl, reqBody, getConfig())
      .then((response) => handleResponse(response))
      .then((result) => {
          if (!!callback) {
              callback(result)
          }
      })
      .catch((error) => {
          handleError(error, "error")
      })
};