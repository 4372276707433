import React from 'react';
import Chip from '@material-ui/core/Chip';
import SlackSearchBox from './SlackSearchBox';
import { connect } from 'react-redux';
import { getApiHost } from "../../../utils/apiUrls";
import PropTypes from "prop-types";
import { deleteSearchTag } from "../../../actions/tagActions";

let root = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: '4px'
}

class KeywordTag extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: this.props.tags,
            isSearch: false,
            searchKey: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tags !== this.props.tags) {
            this.setState({
                tags: this.props.tags
            })
        }
    }

    handleDelete = (chipToDelete) => () => {
        let tags = this.state.tags;
        tags = tags.filter((chip) => chip.key !== chipToDelete.key);
        this.setState({ tags })
        const { API_HOST, deleteSearchTag } = this.props;

        deleteSearchTag(API_HOST, chipToDelete.key, (res) => {
            this.props.getTags();
        });
    };

    searchChat = (data) => {
        this.setState({
            isSearch: true,
            searchKey: data.label
        })
    }

    toggleSlackSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }



    // onAddition(tag) {
    //     const tags = [].concat(this.state.tags, tag)
    //     this.setState({ tags })
    // }

    render() {
        const { tags, isSearch, searchKey } = this.state;
        return (
            <>

                {tags.sort((a, b) => a.key < b.key ? 1 : -1).slice(0, 4).map((data) => {
                    let deleteIcon = <i className="fa fa-trash" style={{ paddingTop: '4px' }}></i>
                    return (
                        <div key={data.key} style={root} onClick={() => this.searchChat(data)}>
                            <Chip
                                label={data.label}
                                onDelete={this.handleDelete(data)}
                                deleteIcon={deleteIcon}
                                clickable={true}
                                variant="outlined"
                            />
                        </div>
                    );
                })}

                {isSearch === true && <SlackSearchBox isSearch={isSearch} tags={tags} toggleSlackSearch={this.toggleSlackSearch} searchKey={searchKey}></SlackSearchBox>}
            </>
        );
    }
}

// Prop types of props.
KeywordTag.propTypes = {
    API_HOST: PropTypes.string
};

KeywordTag.defaultProps = {
    deleteSearchTag: () => { }
}

const mapStateToProps = state => ({
    API_HOST: getApiHost()
})

export default connect(mapStateToProps, { deleteSearchTag })(KeywordTag)
