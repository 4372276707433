import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { addOffice, fetchOffice } from "../../../actions/officeActions";
import SelectTimezone, { getTimezoneProps } from "@capaj/react-select-timezone";
import './Office.css';
import 'moment-timezone';
//import Select from 'react-select';
import { getApiHost } from "../../../utils/apiUrls";
import { Redirect } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';


// const employeeOption = [
//   { value: '1-5', label: '1-5' },
//   { value: '5-15', label: '5-15' },
//   { value: '15-25', label: '15-25' },
//   { value: '25+', label: '25+' },
// ];

// const cabinOption = [
//   { value: '2+', label: '2+' },
//   { value: '5+', label: '5+' },
//   { value: '8+', label: '8+' },
// ]

class Office extends React.Component {
  state = {
    fields: {},
    errors: {},
    isChecked: true,
    selectedEmployeeOption: null,
    selectedCabinOption: null,
    isFormSubmitted:false,
    officeDetails: {},
    startTime: new Date().setHours(9,30),
    endTime: new Date().setHours(19,0),
  };
  
  componentDidMount = () => {
    document.title = this.props.title;
    //
    this.fetchOffice();
  };

  // componentWillReceiveProps = (nextProps) => {
  //    if (nextProps.officeResponse !== this.props.officeResponse) {

  //      this.props.history.push('/slackconfig');
  //    }
    
  // };
  
  // Handles validation for required fields.sdfs
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // Office name
    if (!fields['officename']) {
      formIsValid = false;
      errors['officename'] = 'OfficeName  is required';
      errors['officenamelass'] = 'is-invalid';
    } else {
      errors['officenamelass'] = 'is-valid';
    }
    if (!this.state.isChecked) {
      formIsValid = false;
      errors['ischecked'] = 'is-invalid';
    }
    this.setState({ errors: errors });

    return formIsValid;
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  };

  // Function calls while type user name or password and set their values.
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  handleAddOffice = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  };

  //Functions calls when timezone is changing
  handleChangeTimezone = (tz, label, offset) =>{
    let officeDetails = {};
    officeDetails["TZ"] = tz;
    officeDetails["TZ_Label"]=label;
    officeDetails["TZ_Offset"] = offset * 60;
    this.setState({ officeDetails : officeDetails });
  }


  //Function calls when Number of Employees selected
  handleEmployeeChange = selectedEmployeeOption => {
    this.setState(
      { selectedEmployeeOption }
    );
  };

  //Function call when Cabin Selected
  handleCabinChange = selectedCabinOption => {
    this.setState(
      { selectedCabinOption }
    );
  };

  // Function handles validation and if success then calls login method.
  onSubmitOfficeCreate = (event) => {
    event.preventDefault();
      this.onOfficeCreate();
  };

  // Calls Create Office  API to get logged in.
  onOfficeCreate = async () => {
    const { API_HOST, addOffice } = this.props;

    const officeCreateRequest = {
      // "Id" : 0,
      // "Name" :  this.state.fields['officename'],
      // "Address" : this.state.Address,
      // "Phone" : this.state.Phone,
      // "Zipcode" : this.state.Zipcode,
      // "Is_Active" : true,
      // "Is_Deleted": false,
      // "No_of_Employee" : this.state.selectedEmployeeOption.value,
      // "No_of_Department" : this.state.selectedCabinOption.value,
      // "Start_Time" : (this.state.startTime.getHours()) * 60 + (this.state.startTime.getMinutes()),
      // "End_Time" : (this.state.endTime.getHours()) * 60 + (this.state.endTime.getMinutes()),
      // "TZ": this.state.officeDetails["TZ"],
      // "TZ_Label": this.state.officeDetails["TZ_Label"],
      // "TZ_Offset": this.state.officeDetails["TZ_Offset"]

      "Id" : 0,
      "Name" :  this.state.fields['officename'],
      "Address" : "",
      "Phone" : "",
      "Zipcode" : "",
      "Is_Active" : true,
      "Is_Deleted": false,
      "No_of_Employee" : "25+",
      "No_of_Department" : "8+",
      "Start_Time" : (new Date(this.state.startTime).getHours()) * 60 + (new Date(this.state.startTime).getMinutes()),
      "End_Time" : (new Date(this.state.endTime).getHours()) * 60 + (new Date(this.state.endTime).getMinutes()),
      "TZ": this.state.officeDetails["TZ"],
      "TZ_Label": this.state.officeDetails["TZ_Label"],
      "TZ_Offset": this.state.officeDetails["TZ_Offset"]
    };
    addOffice(API_HOST, officeCreateRequest, (res) => {
      this.setState({isFormSubmitted :true})
    });

    // setTimeout(() => {
    //   if (this.props.officeResponse) {
    //     this.setState({isFormSubmitted :true})
    //   }
    // }, 50)

    if (localStorage.getItem('userdetail')) {
      const userData = JSON.parse(localStorage.getItem('userdetail'));
      userData.UserStartTime_EndTimeVM.Availibility_Time = moment(this.state.startTime).format('HH:mm') +" to "+ moment(this.state.endTime).format('HH:mm');
      userData.UserStartTime_EndTimeVM.Availibility_Status = "Available";
      localStorage.setItem('userdetail', JSON.stringify(userData));
    }
  };

  // Fetch Tenant Offices
  fetchOffice = async () => {
    const { API_HOST, fetchOffice } = this.props;
    await fetchOffice(API_HOST);
  };

  render() {
   // console.log("OFFICE: ",this.props.getOfficesResponse);

    if(this.state.isFormSubmitted){
      return <Redirect to='/dashboard' />
    }

    // if(this.props.officeResponse && this.props.fetchOfficeResponse.fetchOfficeResponse.length > 0 && this.state.isFormSubmitted){
    //   return <Redirect to='/dashboard' />
    // }

    return (
      <div className="wrapper">
        <div className="login-panel h-auto">
          <div className="login-content">
            <img alt="" src="images/logo.png" />

            <div className="login-card card shadow">
              <div className="title">Create your office</div>
                  <div className="admin-txt-info">You are the current Office Administrator, you can also pass this role to someone else in future.</div>

              <form onSubmit={this.onSubmitOfficeCreate} method="post" id="officeCreate">
                <div className="form-group">
                  <label htmlFor="nameInput">Enter office name</label>

                  <input
                    className="form-control"
                    name="officename"
                    type="officename"
                    id="id_officename"
                    autoFocus=""
                    placeholder="Enter office name"
                    value={this.state.fields['officename'] || ''}
                    onChange={(e) => this.handleChange('officename', e)}
                    required
                  />
                </div>

                {/* <div className="form-group">
                  <label>Enter Office Address: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    //placeholder={this.state.officeId === 0 ? 'Enter office address ' : ''}
                    value={this.state.Address}
                    name="Address"
                    onChange={(e) => this.handleAddOffice(e)}
                    required
                  />
                </div> */}

                {/* <div className="form-group">
                  <label>Enter Phone Number: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    //placeholder={this.state.officeId === 0 ? 'Enter phone number ' : ''}
                    value={this.state.Phone}
                    name="Phone"
                    onChange={(e) => this.handleAddOffice(e)}
                    required
                  />
                </div> */}

                {/* <div className="form-group">
                  <label>Enter Zipcode: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    //placeholder={this.state.officeId === 0 ? 'Enter zipcode ' : ''}
                    value={this.state.Zipcode}
                    name="Zipcode"
                    onChange={(e) => this.handleAddOffice(e)}
                    required
                  />
                </div> */}

                <div className="form-group">
                  <label htmlFor="nameInput">Select time zone</label>

                  <div>
                  <SelectTimezone
                      value={this.state.officeDetails["TZ"]}
                      clearable
                      guess
                      placeholder="Select time zone"
                      onChange={(value) => {
                        const timezoneProps = getTimezoneProps(value)
                        this.handleChangeTimezone(timezoneProps.value, moment.tz(timezoneProps.value).format('zz'), timezoneProps.offset)
                      }}
                    />
                  </div>
                </div>

                {/* <div className="form-group">
                  <label>Number of Employees: </label>
                  <Select
                    value={this.state.selectedEmployeeOption}
                    //placeholder={this.state.officeId === 0 ? 'Select number Employees ' : ''}
                    onChange={this.handleEmployeeChange}
                    name="employee_number"
                    options={employeeOption}
                    // isDisabled={this.state.officeId === 0 ? false : true }
                  />
                </div> */}

                 {/* <div className="form-group">
                  <label>Cabins required: </label>
                  <Select
                    value={this.state.selectedCabinOption}
                    //placeholder={this.state.officeId === 0 ? 'Select cabins required ' : ''}
                    onChange={this.handleCabinChange}
                    name="cabin_number"
                    options={cabinOption}
                    // isDisabled={this.state.officeId === 0 ? false : true }
                  />
                </div> */}

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                    <label>Start Time: </label>
                    <DatePicker
                        selected={this.state.startTime}
                        onChange={date => this.setState({ startTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control"
                      />
                  </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                    <label>End Time: </label>
                    <DatePicker
                        selected={this.state.endTime}
                        onChange={date => this.setState({ endTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control"
                      />
                  </div>
                  </div>
                </div> 
                <div className="form-group check-forgot-div">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className={`custom-control-input ${this.state.errors &&
                        this.state.errors['ischecked']
                        ? this.state.errors['ischecked']
                        : ''}`}
                      defaultChecked={this.state.isChecked}
                      id="customCheck"
                      name="example1"
                      onChange={this.toggleChange}
                    />
                    <label className="custom-control-label" htmlFor="customCheck">
                      Private office cannot be joined without approval
										</label>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary">
                  Done! Take me to my office
								</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Prop types of props.
Office.propTypes = {
  API_HOST: PropTypes.string,
  office: PropTypes.func,
  officeErrorMessage: PropTypes.string,
  loading: PropTypes.bool,
  officeResponse: PropTypes.object,
  officeError: PropTypes.bool,

  fetchOffice: PropTypes.func,
  fetchOfficeLoading: PropTypes.bool,
  fetchOfficeResponse: PropTypes.any,
  //
  getOfficesResponse: PropTypes.array,
};

// Set default props..
Office.defaultProps = {
  office: () => { },
  officeErrorMessage: '',
  loading: false,
  officeError: false,
  officeResponse: [],

  fetchOffice:() => { },
  fetchOfficeLoading: false,
  fetchOfficeResponse: [],
  //
  getOfficesResponse: []
};

const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  loading:state.officeReducer.loading,
  officeError: state.officeReducer.officeError,
  officeErrorMessage: state.officeReducer.officeErrorMessage,
  officeResponse: state.officeReducer.officeResponse,

  fetchOfficeResponse: state.officeReducer.fetchOfficeResponse,
  fetchOfficeLoading: state.officeReducer.fetchOfficeLoading,
  //
  getOfficesResponse: state.officeReducer.fetchOfficeResponse.fetchOfficeResponse

})

export default connect(mapStateToProps,{ addOffice, fetchOffice })(Office);

