
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getDepartments, deleteDepartment } from "../../../actions/departmentActions";
import { getApiHost } from "../../../utils/apiUrls";
import AddEditDepartment from './AddEditDepartment';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';


class DepartmentDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      onAddSelected: false,
      onEditSelected: false,
      onDeleteSelected: false,
      data: [], //dept data
      dept_Id: 0
    };
  }
  componentDidMount() {
    this.getDepartments();
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.getDepartmentsResponse !== prevProps.getDepartmentsResponse) {
      this.setState({
        data: nextProps.getDepartmentsResponse
      })
    }

  }


  onAddDepartmentPopup = () => {
    this.setState({
      dept_Id: 0,
      onAddSelected: true
    })
  };

  onEditDepartmentPopup = (deptId) => {

    this.setState({
      onEditSelected: true,
      onAddSelected: true,
      dept_Id: deptId
    })

  };

  onDeleteDepartmentPopup = (deptId) => {

    this.setState({
      onDeleteSelected: true,
      dept_Id: deptId
    })

  };


  onCloseDelete = () => {

    this.setState({
      dept_Id: 0,
      onDeleteSelected: false

    })

  };

  onAddDepartmentPopupClose = () => {
    this.setState({
      onAddSelected: false
    })
  }

  //Close Department List Modal
  onCloseDept = () => {
    this.props.toggleCloseDept();
  };


  //Get Departments
  getDepartments = async () => {
    const { API_HOST, getDepartments } = this.props;
    await getDepartments(API_HOST);

  };

  //Delete Department
  deleteDepartment = () => {
    const { API_HOST, deleteDepartment } = this.props;

    deleteDepartment(API_HOST, this.state.dept_Id, (res) => {

      this.getDepartments();
    })


    this.onCloseDelete();

  };



  render() {

    const columns = [
      {
        Header: <strong className="my_custom_class">Department Name</strong>,
        accessor: 'Name',
        //filterable: true,
        //sortable: true,
      },
      {
        Header: <strong className="my_custom_class">Description</strong>,
        accessor: 'Description',
        //filterable: true,
        //sortable: true,
      },
      {
        Header: <strong className="my_custom_class">Actions</strong>,
        accessor: 'Id',
        minWidth: 50,
        //filterable: true,
        //sortable: true,
        Cell: row => {
          return (
            <>
              <i style={{ cursor: 'pointer' }} onClick={() => this.onEditDepartmentPopup(row.original.Id)} class="fa fa-pencil-square-o dept-edit-button" aria-hidden="true"></i>
              <i style={{ cursor: 'pointer' }} onClick={() => this.onDeleteDepartmentPopup(row.original.Id)} class="fa fa-trash dept-delete-button" aria-hidden="true"></i>

            </>
          )
        }
      }
    ];


    return (
      <React.Fragment>
        {/* <Modal isOpen={isDeptModalOpen} backdrop={true} size={'lg'} >
          <ModalHeader toggle={() => this.onCloseDept()} className="dept-header">
            Department Details
          </ModalHeader>
          <ModalBody className="dept-class"> */}
            <span className="dept-add" onClick={this.onAddDepartmentPopup} aria-hidden="true">
              <i class="fa fa-plus-square" ></i>  <strong>Add Department</strong>
            </span>
            <ReactTable
              data={this.state.data}
              columns={columns}
              defaultPageSize={10}
              //pagination={true}
              className="-striped -highlight"
             //pageSizeOptions={[5, 10, 15]}
              showPageSizeOptions={false}
              showPageJump={false}
              showPagination={true}
              style={{
                height: "calc(100vh - 186px)" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            />
          {/* </ModalBody>
        </Modal> */}

        {this.state.onAddSelected ? (
          <AddEditDepartment isAddDeptModalOpen={this.state.onAddSelected} onEditSelected={this.state.onEditSelected} dept_Id={this.state.onEditSelected ? this.state.dept_Id : 0} toggleCloseAddDept={this.onAddDepartmentPopupClose} getDepartments={this.getDepartments} fromOfficeLayout={false} />
        ) : ""}

        {this.state.onDeleteSelected ? (
          <Modal isOpen={this.state.onDeleteSelected} backdrop={true} className="dept-class">
            <ModalHeader toggle={() => this.onCloseDelete()} className="dept-header">
              Delete Department
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete this department?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.deleteDepartment}>Yes</Button>
              <Button color="secondary" onClick={this.onCloseDelete}>No</Button>
            </ModalFooter>
          </Modal>
        ) : ""}

      </React.Fragment>

    );
  }
}


// Prop types of props.
DepartmentDetail.propTypes = {
  API_HOST: PropTypes.string,

  getDepartments: PropTypes.func,
  getDepartmentsResponse: PropTypes.array,

  deleteDepartment: PropTypes.any
};

// Set default props.
DepartmentDetail.defaultProps = {
  getDepartments: () => { },
  getDepartmentsResponse: [],

  deleteDepartment: false
};


const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  getDepartmentsResponse: state.getDepartmentReducer.getDepartmentsResponse
})

export default connect(mapStateToProps, { getDepartments, deleteDepartment })(DepartmentDetail)