import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { deleteOffice, fetchOffice } from "../../../actions/officeActions";
import { getApiHost } from "../../../utils/apiUrls";
import AddEditOffice from './AddEditOffice';
import 'react-table-6/react-table.css';
//import moment from 'moment';


class OfficeDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      onAddSelected: true,
      onEditSelected: true,
      onDeleteSelected: false,
      data: [], //office data
      office_Id: 0
    };
  }
  async componentDidMount() {
  //  await this.props.fetchOffice();
  //   if(this.props.getOfficesResponse.length > 0) {
  //     this.onEditOfficePopup(this.props.getOfficesResponse[0].Id)
  //   }
  }

  onAddOfficePopup = () => {
    this.setState({
      office_Id: 0,
      onAddSelected: true
    })
  };

  onEditOfficePopup = (officeId) => {

    this.setState({
      onEditSelected: true,
      onAddSelected: true,
      office_Id: officeId
    })

  };

  onDeleteOfficePopup = (officeId) => {

    this.setState({
      onDeleteSelected: true,
      office_Id: officeId
    })

  };


  onCloseDelete = () => {

    this.setState({
      office_Id: 0,
      onDeleteSelected: false

    })

  };

  onAddOfficePopupClose = () => {
    this.setState({
      onAddSelected: false
    })
    this.onCloseOffice()
  }

  //Close Office List Modal
  onCloseOffice = () => {
    this.props.toggleCloseOffice(); 
  };


  //Delete Office
  deleteOffice = () => {
    const { API_HOST, deleteOffice } = this.props;

    deleteOffice(API_HOST, this.state.office_Id, (res) => {
      this.props.fetchOffice();
    })

    this.onCloseDelete();

  };



  render() {

    // const {isOfficeModalOpen} = this.props;
      
    // const columns = [
    //   {
    //     Header: <strong className="my_custom_class">Office Name</strong>,
    //     accessor: 'Name',
    //   },
    //   {
    //     Header: <strong className="my_custom_class">Address</strong>,
    //     accessor: 'Address',
    //   },
    //   {
    //     Header: <strong className="my_custom_class">Phone</strong>,
    //     accessor: 'Phone',
    //   },
    //   // {
    //   //   Header: <strong className="my_custom_class">Zipcode</strong>,
    //   //   accessor: 'Zipcode',
    //   // },
    //   {
    //     Header: <strong className="my_custom_class">Time Zone</strong>,
    //     accessor: 'TZ',
    //   },
    //   {
    //     id: "Start_Time",
    //     Header: <strong className="my_custom_class">Start Time</strong>,
    //     minWidth: 50,
    //     accessor: d => {
    //           return moment.utc(moment.duration((d.Start_Time * 60), "seconds").asMilliseconds()).format("hh:mm a")
    //         },
    //   },
    //   {
    //     id: "End_Time" ,
    //     Header: <strong className="my_custom_class">End Time</strong>,
    //     minWidth: 50,
    //     accessor: d => {
    //       return moment.utc(moment.duration((d.End_Time * 60), "seconds").asMilliseconds()).format("hh:mm a")
    //     },
    //   },
    //   // {
    //   //   Header: <strong className="my_custom_class">No. of Employees</strong>,
    //   //   accessor: 'No_of_Employee',
    //   //   minWidth: 70,
    //   // },
    //   // {
    //   //   Header: <strong className="my_custom_class">No. of Cabins</strong>,
    //   //   accessor: 'No_of_Department',
    //   //   minWidth: 60,
    //   // },
    //   {
    //     Header: <strong className="my_custom_class">Actions</strong>,
    //     accessor: 'Id',
    //     minWidth: 50,
    //     Cell: row => {
    //       return (
    //         <>
    //           <i style={{ cursor: 'pointer' }} onClick={() => this.onEditOfficePopup(row.original.Id)} class="fa fa-pencil-square-o dept-edit-button" aria-hidden="true"></i>
    //           <i style={{ cursor: 'pointer' }} onClick={() => this.onDeleteOfficePopup(row.original.Id)} class="fa fa-trash dept-delete-button" aria-hidden="true"></i>

    //         </>
    //       )
    //     }
    //   }
    // ];


    return (
      <React.Fragment>
        {/* <Modal isOpen={isOfficeModalOpen} backdrop={true} size={'xl'} >
          <ModalHeader toggle={() => this.onCloseOffice()} className="dept-header">
            Office Details
          </ModalHeader>
          <ModalBody className="dept-class">
            <div className="dept-add"  onClick={this.onAddOfficePopup} aria-hidden="true">
              <i class="fa fa-plus-square"></i>  <strong>Create Office</strong>
            </div>
            <ReactTable
              //data={this.state.data}
              data={this.props.getOfficesResponse}
              columns={columns}
              defaultPageSize={5}
              pagination={true}
              className="-striped -highlight"
              pageSizeOptions={[5, 10, 15]}
            />
          </ModalBody>
        </Modal>

        {this.state.onAddSelected ? (
          <AddEditOffice isAddOfficeModalOpen={this.state.onAddSelected} onEditSelected={this.state.onEditSelected} office_Id={this.state.onEditSelected ? this.state.office_Id : 0} toggleCloseAddOffice={this.onAddOfficePopupClose} fetchOffice={this.props.fetchOffice} />
        ) : ""}

        {this.state.onDeleteSelected ? (
          <Modal isOpen={this.state.onDeleteSelected} backdrop={true} className="dept-class">
            <ModalHeader toggle={() => this.onCloseDelete()} className="dept-header">
              Delete Office
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete this Office?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.deleteOffice}>Yes</Button>
              <Button color="secondary" onClick={this.onCloseDelete}>No</Button>
            </ModalFooter>
          </Modal>
        ) : ""} */}
{this.state.onAddSelected ? (
          <AddEditOffice getUserDetail={this.props.getUserDetail} userDetailResponse={this.props.userDetailResponse} isAddOfficeModalOpen={this.state.onAddSelected} onEditSelected={this.state.onEditSelected} office_Id={this.state.office_Id} toggleCloseAddOffice={this.onAddOfficePopupClose} fetchOffice={this.props.fetchOffice} />
        ) : ""}
      </React.Fragment>

    );
  }
}


// Prop types of props.
OfficeDetail.propTypes = {
  API_HOST: PropTypes.string,

  getOffices: PropTypes.func,
  getOfficesResponse: PropTypes.array,

  deleteOffice: PropTypes.any
};

// Set default props.
OfficeDetail.defaultProps = {
  getOffices: () => { },
  getOfficesResponse: [],

  deleteOffice: false
};


const mapStateToProps = state => ({
  API_HOST: getApiHost(),
  //getOfficesResponse: state.getOfficeReducer.getOfficesResponse
  getOfficesResponse: state.officeReducer.fetchOfficeResponse.fetchOfficeResponse
})

export default connect(mapStateToProps, { deleteOffice, fetchOffice })(OfficeDetail)
