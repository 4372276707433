import React from 'react';

export const CustomLoadingSpiner = (props) => {
    const InProgress = props.InProgress;
  return (
    <>
    {
      (InProgress === true) ?
      <div className="overlay-loader">
        <div className="loader-wrap">
          <div className="loader" id="loader-1"></div>
          {/* Added Text */}
          <div class="hm-spinner"></div>
            <div className="load-text">Loading, Please wait.......</div>
        </div>
      </div>
      :
        null
    }
  </>
  )
};