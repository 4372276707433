import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import Avatar from 'avataaars';
import PropTypes from 'prop-types';
import { TabContent, TabPane, NavLink } from 'reactstrap';
import classnames from 'classnames';

class AvtarProfile extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      error: {},
      userDetails: {},
      src: "",
      myAvatar: {},
      topType: ['NoHair', 'Eyepatch', 'Hat', 'Hijab', 'Turban', 'WinterHat1', 'WinterHat2', 'WinterHat3', 'WinterHat4', 'LongHairBigHair', 'LongHairBun', 'LongHairCurly', 'LongHairCurvy', 'LongHairDreads', 'LongHairFrida', 'LongHairFro', 'LongHairFroBand', 'LongHairNotTooLong',
        'LongHairShavedSides', 'LongHairMiaWallace', 'LongHairStraight', 'LongHairStraight2', 'LongHairStraightStrand', 'ShortHairDreads01', 'ShortHairDreads02', 'ShortHairFrizzle', 'ShortHairShaggyMullet',
        'ShortHairShortCurly', 'ShortHairShortFlat', 'ShortHairShortRound', 'ShortHairShortWaved', 'ShortHairSides', ' ShortHairTheCaesar', 'ShortHairTheCaesarSidePart'],
      accessoriesType: ['Blank', 'Kurt', 'Prescription01', 'Prescription02', 'Round', 'Sunglasses', 'Wayfarers'],
      hairColor: ['Auburn', 'Black', 'Blonde', 'BlondeGolden', 'Brown', 'BrownDark', 'PastelPink', 'Platinum', 'Red', 'SilverGray'],
      facialHairType: ['Blank', 'BeardMedium', 'BeardLight', 'BeardMagestic', 'MoustacheFancy', 'MoustacheMagnum'],
      clotheType: ['BlazerShirt', 'BlazerSweater', 'CollarSweater', 'GraphicShirt', 'Hoodie', 'Overall', 'ShirtCrewNeck', 'ShirtScoopNeck', 'ShirtVNeck'],
      clotheColor: ['Black', 'Blue01', 'Blue02', 'Blue03', 'Gray01', 'Gray02', 'Heather', 'PastelBlue', 'PastelGreen', 'PastelOrange', 'PastelRed', 'PastelYellow', 'Pink', 'Red', 'White'],
      eyeType: ['Close', 'Cry', 'Default', 'Dizzy', 'Happy', 'Side', 'Squint', 'Surprised', 'Wink', 'WinkWacky', 'EyeRoll', 'Hearts'],
      eyebrowType: ['Angry', 'AngryNatural', 'Default', 'DefaultNatural', 'FlatNatural', 'RaisedExcited', 'RaisedExcitedNatural', 'SadConcerned', 'SadConcernedNatural', 'UnibrowNatural', 'UpDown', 'UpDownNatural'],
      mouthType: ['Concerned', 'Default', 'Disbelief', 'Eating', 'Grimace', 'Sad', 'ScreamOpen', 'Serious', 'Smile', 'Tongue', 'Twinkle', 'Vomit'],
      skinColor: ['Tanned', 'Yellow', 'Pale', 'Light', 'Brown', 'DarkBrown', 'Black'],
      facialHairColor: ['Auburn', 'Black', 'Blonde', 'BlondeGolden', 'Brown', 'BrownDark', 'PastelPink', 'Platinum', 'Red', 'SilverGray'],
      graphicType: ['Bat', 'Cumbia', 'Deer', 'Diamond', 'Hola', 'Pizza', 'Resist', 'Selena', 'Bear', 'SkullOutline', 'Skull'],
      hatColor: ['Black', 'Blue01', 'Blue02', 'Blue03', 'Gray01', 'Gray02', 'Heather', 'PastelBlue', 'PastelGreen', 'PastelOrange', 'PastelRed', 'PastelYellow', 'Pink', 'Red', 'White'],
      circleColor: [],//color hex (default: #6fb8e0),
      activeTab: '2',
    }
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.myAvatar !== this.props.myAvatar) {
      this.setState({ myAvatar: nextProps.myAvatar });
    }
  }

  componentDidMount() {
    this.setState({ myAvatar: this.props.myAvatar })
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="main-tab main-vertical-tab">
        <div className="d-flex">
          <div className="col-2 col-md-3 px-0">
            <div className="nav flex-column nav-pills pt-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>Top <span role="img" aria-label="hat">👒</span></NavLink>
              {this.state.myAvatar["topType"] !== 'Eyepatch' ?
                <span>
                  <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>Accessories <span role="img" aria-label="googles">🥽</span></NavLink>
                  {['Hijab', 'Turban', 'WinterHat1', 'WinterHat2', 'WinterHat3', 'WinterHat4'].includes(this.state.myAvatar["topType"]) ?
                    <NavLink className={classnames({ active: this.state.activeTab === '14' })} onClick={() => { this.toggle('14'); }}>{this.state.myAvatar["topType"]} Color <span role="img" aria-label="colourful">🌈</span></NavLink>
                    : null
                  }
                  {['LongHairBigHair', 'LongHairBun', 'LongHairCurly', 'LongHairCurvy', 'LongHairDreads', 'LongHairFro', 'LongHairFroBand', 'LongHairNotTooLong',
                    'LongHairShavedSides', 'LongHairMiaWallace', 'LongHairStraight', 'LongHairStraight2', 'LongHairStraightStrand', 'ShortHairDreads01', 'ShortHairDreads02', 'ShortHairFrizzle', 'ShortHairShaggyMullet',
                    'ShortHairShortCurly', 'ShortHairShortFlat', 'ShortHairShortRound', 'ShortHairShortWaved', 'ShortHairSides', ' ShortHairTheCaesar', 'ShortHairTheCaesarSidePart'].includes(this.state.myAvatar["topType"]) ?
                    <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>Hair Color  <span role="img" aria-label="colour palette">🎨</span> </NavLink>
                    : null
                  }
                </span>
                : null
              }
              {this.state.myAvatar["topType"] !== 'Hijab' ?
                <span>
                  <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>Facial Hair <span role="img" aria-label="beard boy">🧔</span></NavLink>
                  {this.state.myAvatar["facialHairType"] !== 'Blank' ?
                    <NavLink className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>Facial Hair Color  <span role="img" aria-label="colour palette">🎨</span> </NavLink>
                    : null
                  } </span> : null}
              <NavLink className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggle('7'); }}>Clothes <span role="img" aria-label="dress">👚</span></NavLink>
              {['CollarSweater', 'GraphicShirt', 'Hoodie', 'Overall', 'ShirtCrewNeck', 'ShirtScoopNeck', 'ShirtVNeck'].includes(this.state.myAvatar["clotheType"]) ?
                <NavLink className={classnames({ active: this.state.activeTab === '8' })} onClick={() => { this.toggle('8'); }}>Fabric Color <span role="img" aria-label="colourful">🌈</span></NavLink>
                : null}
              {this.state.myAvatar["clotheType"] === 'GraphicShirt' ?
                <NavLink className={classnames({ active: this.state.activeTab === '9' })} onClick={() => { this.toggle('9'); }}>Graphic <span role="img" aria-label="skull">💀</span></NavLink>
                : null}
              <NavLink className={classnames({ active: this.state.activeTab === '10' })} onClick={() => { this.toggle('10'); }}>Eye <span role="img" aria-label="eyes">👀</span></NavLink>
              <NavLink className={classnames({ active: this.state.activeTab === '11' })} onClick={() => { this.toggle('11'); }}>Eyebrow <span role="img" aria-label="eyebrow">🤨</span></NavLink>
              <NavLink className={classnames({ active: this.state.activeTab === '12' })} onClick={() => { this.toggle('12'); }}>Mouth <span role="img" aria-label="mouth">👄</span></NavLink>
              <NavLink className={classnames({ active: this.state.activeTab === '13' })} onClick={() => { this.toggle('13'); }}>Skin Color <span role="img" aria-label="skintone">🧑🏿🧑🏽🧑🏻</span> </NavLink>
            </div>
          </div>
          <div className="col-10 col-md-9 px-0">
            <TabContent activeTab={this.state.activeTab} className="tab-content bg-light" id="v-pills-tabContent">
              {activeTab === '2' && <TabPane tabId="2" className="tab-pane" id="v-pills-tab-con1" role="tabpanel" aria-labelledby="v-pills-home-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.topType.map((topType) => {
                          return (
                            <div key={topType} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("topType", topType) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={topType}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '3' && <TabPane tabId="3" >
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.accessoriesType.map((accessoriesType) => {
                          return (
                            <div key={accessoriesType} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("accessoriesType", accessoriesType) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={accessoriesType}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '4' && <TabPane tabId="4" className="tab-pane" id="v-pills-tab-con3" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.hairColor.map((hairColor) => {
                          return (
                            <div key={hairColor} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("hairColor", hairColor) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={hairColor}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '5' && <TabPane tabId="5" className="tab-pane" id="v-pills-tab-con4" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.facialHairType.map((facialHairType) => {
                          return (
                            <div key={facialHairType} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("facialHairType", facialHairType) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={facialHairType}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '6' && <TabPane tabId="6" className="tab-pane" id="v-pills-tab-con5" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.facialHairColor.map((facialHairColor) => {
                          return (
                            <div key={facialHairColor} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("facialHairColor", facialHairColor) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={facialHairColor}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '7' && <TabPane tabId="7" className="tab-pane" id="v-pills-tab-con5" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.clotheType.map((clotheType) => {
                          return (
                            <div key={clotheType} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("clotheType", clotheType) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={clotheType}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '8' && <TabPane tabId="8" className="tab-pane" id="v-pills-tab-con6" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.clotheColor.map((clotheColor) => {
                          return (
                            <div key={clotheColor} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("clotheColor", clotheColor) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={clotheColor}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '9' && <TabPane tabId="9" className="tab-pane" id="v-pills-tab-con7" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.graphicType.map((graphicType) => {
                          return (
                            <div key={graphicType} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("graphicType", graphicType) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                graphicType={graphicType}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '10' && <TabPane tabId="10" className="tab-pane" id="v-pills-tab-con7" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.eyeType.map((eyeType) => {
                          return (
                            <div key={eyeType} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("eyeType", eyeType) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={eyeType}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '11' && <TabPane tabId="11" className="tab-pane" id="v-pills-tab-con8" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.eyebrowType.map((eyebrowType) => {
                          return (
                            <div key={eyebrowType} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("eyebrowType", eyebrowType) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={eyebrowType}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '12' && <TabPane tabId="12" className="tab-pane" id="v-pills-tab-con9" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.mouthType.map((mouthType) => {
                          return (
                            <div key={mouthType} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("mouthType", mouthType) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={mouthType}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '13' && <TabPane tabId="13" className="tab-pane" id="v-pills-tab-con10" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.skinColor.map((skinColor) => {
                          return (
                            <div key={skinColor} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("skinColor", skinColor) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={skinColor}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={this.state.myAvatar["hatColor"]}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
              {activeTab === '14' && <TabPane tabId="14" className="tab-pane" id="v-pills-tab-con8" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="d-flex avtar-tabpan">
                  <div className="col-6">
                    <div className="col-10 px-0">
                      <div className="card">
                        <strong>{JSON.parse(localStorage.getItem('userdetail')).Display_Name}</strong>
                        <Avatar className="myAvatar"
                          style={{ width: '180px', height: '180px' }}
                          avatarStyle='Transparent'
                          topType={this.state.myAvatar["topType"]}
                          accessoriesType={this.state.myAvatar["accessoriesType"]}
                          hairColor={this.state.myAvatar["hairColor"]}
                          facialHairType={this.state.myAvatar["facialHairType"]}
                          clotheType={this.state.myAvatar["clotheType"]}
                          clotheColor={this.state.myAvatar["clotheColor"]}
                          eyeType={this.state.myAvatar["eyeType"]}
                          eyebrowType={this.state.myAvatar["eyebrowType"]}
                          mouthType={this.state.myAvatar["mouthType"]}
                          skinColor={this.state.myAvatar["skinColor"]}
                          facialHairColor={this.state.myAvatar["facialHairColor"]}
                          graphicType={this.state.myAvatar["graphicType"]}
                          hatColor={this.state.myAvatar["hatColor"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div className="select-avtar-div myscroll">
                      <div className="d-flex flex-wrap">
                        {this.state.hatColor.map((hatColor) => {
                          return (
                            <div key={hatColor} className="col-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => { this.props.onselected("hatColor", hatColor) }}>
                              <Avatar
                                style={{ width: '135px', height: '135px' }}
                                avatarStyle='Transparent'
                                topType={this.state.myAvatar["topType"]}
                                accessoriesType={this.state.myAvatar["accessoriesType"]}
                                hairColor={this.state.myAvatar["hairColor"]}
                                facialHairType={this.state.myAvatar["facialHairType"]}
                                clotheType={this.state.myAvatar["clotheType"]}
                                clotheColor={this.state.myAvatar["clotheColor"]}
                                eyeType={this.state.myAvatar["eyeType"]}
                                eyebrowType={this.state.myAvatar["eyebrowType"]}
                                mouthType={this.state.myAvatar["mouthType"]}
                                skinColor={this.state.myAvatar["skinColor"]}
                                facialHairColor={this.state.myAvatar["facialHairColor"]}
                                graphicType={this.state.myAvatar["graphicType"]}
                                hatColor={hatColor}
                              />
                            </div>);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>}
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}

AvtarProfile.defaultProps = {
  myAvatar: {},
  onselected: () => { },
};

AvtarProfile.propTypes = {
  myAvatar: PropTypes.object,
  onselected: PropTypes.func
};

export default hot(module)(AvtarProfile);