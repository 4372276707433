import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { toast } from 'react-toastify';

class ResetPasswordLogin extends Component {
  state = {
    fields: {},
    error: {},
    userDetails: null,
    src: '',
    isSuccess: false,
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.changePasswordResponse !== this.props.changePasswordResponse
    ) {
      this.setState({ isSuccess: true });
    }
  }
  onSubmitPassword = (event) => {
    event.preventDefault();

    if (this.state.fields['useroldpassword'] !== undefined) {
      if (
        this.state.fields['userpassword'] ===
        this.state.fields['userconfirmpassword']
      ) {
        this.onSubmit();
      } else {
        toast.error('New Password and Confirm Password does not match.', {
          autoClose: 3000,
        });
      }
    } else {
      toast.error('Please enter your old password.');
    }
  };

  // Calls Login API to get logged in.
  onSubmit = async () => {
    this.props.changePassword(
      this.state.fields['useroldpassword'],
      this.state.fields['userpassword']
    );
    //Open popup of isSuccess
    if (this.props.changePasswordResponse) {
      this.setState({ isSuccess: true });
    }
  };

  // Function calls while type user name or password and set their values.
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  toggleClose = () => {
    this.setState({ isSuccess: false, fields: {}, errors: {} });
  };

  validateOldPassword = (e) => {
    const oldPasswordText = e.target.value;
    if (oldPasswordText === '') {
      toast.error('Please enter your old password.');
    }
  };

  validatePassword = (e) => {
    const passwordText = e.target.value;
    var pattern = /^[a-zA-Z0-9!@#$%^&*]{8,10}$/;
    if (e.target.name === 'userpassword' && !passwordText.match(pattern)) {
      toast.error(
        'Please enter a valid password. It should contain one Capital letter, one Number, one Special Character, and length should be upto 10 characters',
        { autoClose: 4000 }
      );
    }
  };

  validateConfirmPassword = (e) => {
    const confirmPasswordText = e.target.value;
    if (
      e.target.name === 'userconfirmpassword' &&
      !confirmPasswordText.match(this.state.fields['userpassword'])
    ) {
      toast.error('New Password and Confirm Password does not match.', {
        autoClose: 3000,
      });
    }
  };

  render() {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Old Password</label>
          <input
            style={{ width: '400px' }}
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Old Password"
            value={this.state.fields['useroldpassword'] || ''}
            name="useroldpassword"
            onChange={(e) => this.handleChange('useroldpassword', e)}
            required
            onBlur={(e) => this.validateOldPassword(e)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword2">New Password</label>
          <input
            style={{ width: '400px' }}
            type="password"
            className="form-control"
            id="exampleInputPassword2"
            placeholder="Password"
            value={this.state.fields['userpassword'] || ''}
            name="userpassword"
            onChange={(e) => this.handleChange('userpassword', e)}
            required
            onBlur={(e) => this.validatePassword(e)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputPassword3">Confirm New Password</label>
          <input
            style={{ width: '400px' }}
            type="password"
            className="form-control"
            id="exampleInputPassword3"
            placeholder="Confirm Password"
            value={this.state.fields['userconfirmpassword'] || ''}
            name="userconfirmpassword"
            onChange={(e) => this.handleChange('userconfirmpassword', e)}
            required
            onBlur={(e) => this.validateConfirmPassword(e)}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={this.onSubmitPassword}
        >
          Reset my Password
        </button>

        <ResetPasswordSuccess
          isOpen={this.state.isSuccess}
          toggle={this.toggleClose}
        />
      </div>
    );
  }
}

ResetPasswordLogin.defaultProps = {
  changePassword: () => {},
  changePasswordResponse: false,
};

ResetPasswordLogin.propTypes = {
  changePassword: PropTypes.func,
  changePasswordResponse: PropTypes.bool,
};

export default hot(module)(ResetPasswordLogin);
