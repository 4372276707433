import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getApiHost } from '../../../utils/apiUrls';
import 'react-table-6/react-table.css';
import { toast } from 'react-toastify';
import { getJiraDomain } from '../../../actions/jiraActions';
import { getJiraLog, downloadExcelFile } from '../../../actions/logActions';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import { getJiraConfig } from '../../../actions/jiraActions';
import ReactTable from 'react-table-6';
import { getDecryptedValue } from '../../../utils/utility';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from 'react-datepicker';
import { getUsersList } from '../../../actions/departmentActions';
import ClockLoader from 'react-spinners/ClockLoader';
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
} from 'reactstrap';
import moment from 'moment';
import { saveAs } from 'file-saver';

class JiraLogReport extends React.Component {
  state = {
    loading: false,
    isActive: false,
    userList: [],
    userSelected: {},
    statusCategory: [],
    enabled: false,
    taskDate: new Date(),
    //taskEndDate: moment(new Date()).add(1, 'M').toDate(),
    taskEndDate: new Date(),
    data: [],
    selectedUsersList: [],
    isDayWise: true,
    isMonthWise: false,
    totalWorkedHours: 0,
    allUsersId: [],
  };

  async componentDidMount() {
    // await this.setState({
    //   isActive: true
    // })
    //await this.getJIRAConfig();
    await this.getUsersList(0);
    //  await this.jiraStatusCategory();

    let userselected = {
      Id_User: 0,
      Real_Name: 'All Users',
      label: <div>{'     All Users'}</div>,
      value: '',
    };

    await this.setState({ userSelected: userselected });
  }

  getJiraDomain = async () => {
    const { API_HOST, getJiraDomain } = this.props;
    getJiraDomain(API_HOST, (res) => {
      if (res[0]) {
        let newValue = res.map(function (obj) {
          return {
            id: getDecryptedValue(obj.CloudId),
            CloudId: getDecryptedValue(obj.CloudId),
            Domain_Name: obj.Domain_Name,
            key: obj.Domain_Name,
            url: obj.url,
            Jira_Scope_Detail: obj.Jira_Scope_Detail,
            label: obj.Domain_Name,
            value: getDecryptedValue(obj.CloudId),
          };
        });
        this.setState({
          jiraDomain: newValue,
          jiraDomainSelected: newValue[0],
          cloudId: newValue[0].CloudId,
        });
      }
    });
  };

  // Get userslist
  getUsersList = (deptId) => {
    const { API_HOST, getUsersList } = this.props;
    getUsersList(API_HOST, deptId, (res) => {
      if (res) {
        this.setState({
          userList: res,
        });

        const result = [];
        for (var i = 0; i < res.length; ++i) {
          result.push(res[i]['Id_User']);
        }

        console.log('userRESULT: ', result);

        this.setState({ allUsersId: result });
        console.log('allUsersId: ', this.state.allUsersId);
      }
    });
  };

  //Users Selection
  onSelectUsers = async (newValue) => {
    console.log('New Value: ', newValue);
    this.setState({ userSelected: newValue });

    newValue &&
      newValue.length > 1 &&
      newValue.map((obj) => {
        if (obj.Id_User === 0) {
          newValue.splice(obj, 1);
        }
        this.setState({ userSelected: newValue });
      });

    if (newValue !== null) {
      const result = [];
      for (var i = 0; i < newValue.length; ++i) {
        result.push(newValue[i]['Id_User']);
      }

      console.log('RESULT: ', result);

      await this.setState({ selectedUsersList: result });
      console.log('selectedUsersList: ', this.state.selectedUsersList);
    }
  };

  //Set Date
  setDate = (date) => {
    this.setState({ taskDate: date });
  };

  setEndDate = (date) => {
    this.setState({ taskEndDate: date });
  };

  //Get Jira log list based on selection
  getJiraLogData = async () => {
    const { API_HOST, getJiraLog } = this.props;
    if (this.state.selectedUsersList.includes(0)) {
      const index = this.state.selectedUsersList.indexOf(0);
      this.state.selectedUsersList.splice(index, 1);
      console.log('after remove 0 :: ', this.state.selectedUsersList);
    }

    this.setState({ loading: true });

    let reqObj = {
      Id_User:
        this.state.selectedUsersList.length === 0
          ? this.state.allUsersId
          : this.state.selectedUsersList,
      Jira_Task_Key: '',
      StartLogDateTime: this.state.taskDate,
      EndLogDateTime: this.state.taskEndDate,
      TaskStatus: '',
    };

    await getJiraLog(API_HOST, reqObj, (res) => {
      if (res !== null) {
        this.setState({
          data: res.splice(0, res.length - 1),
          loading: false,
          totalWorkedHours: res[res.length - 1].WorkedHours,
        });
        console.log('After splice:  ', this.state.data);
        console.log('TW: ', this.state.totalWorkedHours);
      } else {
        toast.info('No Data available for selected values.');
        this.setState({ loading: false });
      }
    });
  };

  //download excel file
  downloadExcelFile = async () => {
    const { API_HOST, downloadExcelFile } = this.props;
    const finalUrl = `${API_HOST}Jira/JiraTaskLogReportExportToExcel`;
    this.setState({ enabled: true });

    // var endDate = "";
    // var startDate = this.state.taskDate;
    // if(this.state.isMonthWise){
    //   endDate = this.state.taskEndDate;
    //   startDate = moment().startOf('month');
    // }

    let reqObj = {
      Id_User: this.state.selectedUsersList,
      Jira_Task_Keys: '',
      StartLogDateTime: this.state.taskDate,
      EndLogDateTime: this.state.taskEndDate,
      TaskStatus: '',
    };

    await downloadExcelFile(finalUrl, reqObj, (res) => {
      this.downloadFile(res.Data.FileContent, res.Data.FileName);
      this.setState({ enabled: false });
    });
  };

  // Method to convert byte array to Uint8Array which can be further used to create blob
  base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  // Method to save byte array as file using FileSaver library
  downloadFile = (byteArray, fileNameWithExtension) => {
    let result = this.base64ToArrayBuffer(byteArray);
    let blob = new Blob([result], { type: 'application/octet-stream' });
    saveAs(blob, fileNameWithExtension);
  };

  changeReportDayWise = () => {
    this.setState({
      isDayWise: true,
      isMonthWise: false,
      taskDate: new Date(),
      taskEndDate: new Date(),
    });
  };

  changeReportMonthWise = () => {
    this.setState({
      isMonthWise: true,
      isDayWise: false,
      taskDate: moment().startOf('month').toDate(),
      taskEndDate: new Date(),
    });
    console.log('Startchange:: ', this.state.taskDate);
    console.log('Endchange:: ', this.state.taskEndDate);
  };

  jiraStatusCategory = async () => {
    try {
      //  const finalUrl = this.props.jiraIssue.self
      const finalUrl = `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/${this.state.jiraIssue.key}/transitions?sortByOpsBarAndStatus=true`;

      let headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.props.accessToken,
      };
      const { data } = await axios.get(finalUrl, { headers });
      this.setState({ statusCategory: data.transitions });
      console.log('Transitions:: ', this.state.statusCategory);
    } catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken });
        this.jiraStatusCategory();
      }
      toast.error(error);
    }
  };

  setStatusforJira = async (newValue) => {
    this.setState({ statusSelected: newValue });
    try {
      const finalUrl =
        `https://api.atlassian.com/ex/jira/${this.props.cloudId}/rest/api/2/issue/` +
        this.state.jiraIssue.key +
        '/transitions';

      let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.state.accessToken,
      };
      let resource = {
        transition: { id: newValue.id },
      };
      const { data } = await axios.post(finalUrl, resource, { headers });
      await this.getIssueDetails();
      this.props.getJiraYourTaskList(this.state.accessToken);
      if (this.props.isBacklogIssues) {
        this.props.jiraBacklogIssue(this.state.project);
      }
    } catch (error) {
      if (error.response.status === 401) {
        let gettoken = await this.props.getAccessTokenAgain();
        this.setState({ accessToken: gettoken });
        this.setStatusforJira(newValue);
      }
      toast.error(error);
    }
  };

  render() {
    //console.log("8888: ",this.state.userList);
    console.log('DATAAA :: ', this.state.data);

    let userOptions = [];
    userOptions.push({
      Id_User: 0,
      Real_Name: 'All Users',
      label: <div>{'     All Users'}</div>,
      value: '',
    });

    this.state.userList[0] &&
      this.state.userList.map((obj) => {
        userOptions.push({
          Id_User: obj.Id_User,
          Real_Name: obj.Real_Name,
          label: obj.Real_Name,
          value: obj.Real_Name,
        });
        return userOptions;
      });

    let statusCategoryOptions = [];
    this.state.statusCategory[0] &&
      this.state.statusCategory.map((obj) => {
        statusCategoryOptions.push({
          description: obj.description,
          id: obj.id,
          name: obj.name,
          value: obj.id,
          self: obj.self,
          statusCategory: obj.statusCategory,
          label: obj.name,
        });
        return statusCategoryOptions;
      });

    const { data, totalWorkedHours } = this.state;

    const timezone = JSON.parse(localStorage.getItem('userdetail')).TZ
      ? JSON.parse(localStorage.getItem('userdetail')).TZ
      : 'Asia/Kolkata';
    // var totalWorkedHours = data && data.length > 0 ? data[data.length - 1].WorkedHours : 0;
    var h = Math.floor(totalWorkedHours / 60);
    var m = totalWorkedHours % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    var totalWorkedHoursDisplay = h + ' : ' + m;
    const columns = [
      {
        Header: <strong className="my_custom_class">User</strong>,
        accessor: 'User_RealName',
        //minWidth: 40,
        width: 160,
        Cell: (row) => {
          return <div onClick="">{row.original.User_RealName}</div>;
        },
      },
      {
        Header: <strong className="my_custom_class">Task Key</strong>,
        accessor: 'Jira_Task_Key',
        width: 90,
        Cell: (row) => {
          return <div onClick="">{row.original.Jira_Task_Key}</div>;
        },
      },
      {
        Header: <strong className="my_custom_class">Description</strong>,
        accessor: 'Description',
        // width: 250,
        Cell: (row) => {
          return <div onClick="">{row.original.Description}</div>;
        },
      },
      {
        Header: <strong className="my_custom_class">Jira Domain Name</strong>,
        accessor: 'Description',
        // width: 250,
        Cell: (row) => {
          return <div onClick="">{row.original.Jira_Domain_Name}</div>;
        },
      },
      {
        Header: <strong className="my_custom_class">Date</strong>,
        accessor: 'LogDateTime',
        //minWidth: 20,
        width: 90,
        Footer: <div>Total Hours</div>,
        Cell: (row) => {
          return (
            <div className="d-block text-right" onClick="">
              {moment(row.original.LogDateTime)
                .tz(timezone)
                .format('MM/DD/YYYY')}
            </div>
          );
        },
      },
      {
        Header: (
          <strong style={{ alignContent: 'right' }} className="my_custom_class">
            Hrs:Mins
          </strong>
        ),
        accessor: 'WorkedHours',
        Footer: <div>{totalWorkedHoursDisplay}</div>,
        width: 80,
        Cell: (row) => {
          var h = Math.floor(row.original.WorkedHours / 60);
          var m = row.original.WorkedHours % 60;
          h = h < 10 ? '0' + h : h;
          m = m < 10 ? '0' + m : m;

          return (
            <div className="d-block text-right" onClick="">
              {h}:{m}
            </div>
          );
        },
      },
    ];

    return (
      <>
        <Modal isOpen={this.props.isJiraLogReport} backdrop={true} size={'xl'}>
          <ModalHeader toggle={() => this.props.closeJiraLogReport()}>
            JIRA Log Report
          </ModalHeader>
          <LoadingOverlay
            active={this.state.isActive}
            spinner
            text="Loading..."
          >
            <ModalBody className="">
              <FormGroup>
                {/* <h6>Task Status</h6> */}
                <Row style={{ justifyContent: 'space-between' }}>
                  {/* <Col xs="5" >
                <Select
                  components={makeAnimated}
                  onChange={(newValue) => { this.setStatusforJira(newValue) }}
                //  options={statusCategoryOptions}
                  value={this.state.statusSelected}
                />
                </Col> */}

                  <Col xs="5">
                    <Select
                      components={makeAnimated}
                      isMulti
                      onChange={(newValue) => {
                        this.onSelectUsers(newValue);
                      }}
                      options={userOptions}
                      value={this.state.userSelected}
                    />
                  </Col>

                  <Col xs="1">
                    <Button
                      type="button"
                      className="btn-icon btn-2 btn-filter-icon"
                      color="primary"
                      onClick={this.getJiraLogData}
                    >
                      <i
                        className="fa fa-search"
                        style={{ fontSize: '18px', color: 'white' }}
                      ></i>
                    </Button>
                  </Col>
                  <Col xs="1">
                    <Button
                      type="button"
                      className="btn-icon btn-2 btn-filter-icon"
                      color="secondary"
                      onClick={this.downloadExcelFile}
                    >
                      {this.state.enabled === false ? (
                        <i
                          className="fa fa-download"
                          style={{ fontSize: '18px', color: 'white' }}
                        ></i>
                      ) : (
                        <ClockLoader
                          size={20}
                          color={'#64B988'}
                          loading={this.state.enabled}
                        />
                      )}
                    </Button>
                  </Col>
                </Row>

                <Row className="mt-3 align-items-center">
                  <Col xs="4">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline1"
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="option1"
                        checked={this.state.isDayWise}
                        onClick={this.changeReportDayWise}
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline1"
                      >
                        Daywise Report
                      </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline2"
                        name="customRadioInline2"
                        class="custom-control-input"
                        value="option2"
                        checked={this.state.isMonthWise}
                        onClick={this.changeReportMonthWise}
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline2"
                      >
                        Monthwise Report
                      </label>
                    </div>

                    {/* <div className="radio">
                      <label>
                        <input type="radio" value="option1" checked={this.state.isDayWise} onClick={this.changeReportDayWise} />
                          Daywise Report
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" value="option2" checked={this.state.isMonthWise} onClick={this.changeReportMonthWise} />
                        Monthwise Report
                      </label>
                    </div> */}
                  </Col>

                  <Col xs="2">
                    <DatePicker
                      className="form-control w-100"
                      selected={this.state.taskDate}
                      onChange={(date) => this.setDate(date)}
                      id="taskdate"
                      label="Date"
                      placeholderText="Select Date"
                    />
                  </Col>

                  {this.state.isMonthWise ? (
                    <Col xs="2">
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.taskEndDate}
                        onChange={(date) => this.setEndDate(date)}
                        id="taskEnddate"
                        label="Date"
                        placeholderText="Select Date"
                      />
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </FormGroup>
              <FormGroup>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Col>
                    <ReactTable
                      manual
                      data={this.state.data}
                      columns={columns}
                      showPageSizeOptions={false}
                      //defaultPageSize={5}
                      //pagination={true}
                      showPagination={false}
                      className="-striped -highlight"
                      loading={this.state.loading}
                      style={{ height: '400px' }}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </ModalBody>
          </LoadingOverlay>
        </Modal>
      </>
    );
  }
}

// Prop types of props
JiraLogReport.propTypes = {
  API_HOST: PropTypes.string,
};

// Set default props.
JiraLogReport.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    API_HOST: getApiHost(),
  };
};
export default connect(mapStateToProps, {
  downloadExcelFile,
  getJiraLog,
  getUsersList,
  getJiraConfig,
  getJiraDomain,
})(JiraLogReport);
